import React from 'react'
import ViewResource from '../../../siteSrc/components/site/fileupload/viewCoursecommon'

const ViewResources=()=>{
  return(
    <>
    <ViewResource type="Resource" plural="resources" sponsorStudies={true}/>
    </>
  )
}
export default ViewResources