import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../formElements';
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core'
import BackIcon from '../../../img/back1.svg'
import { checkPermission } from '../../common/utils';
import { loginApi, apiCall } from '../../common/apiconfig';
import { data } from "jquery";

const Profile = (propsobj) => {
  console.log(propsobj.formValues, "profile")
  const [data,setData]=useState([])
  useEffect(()=>{
    const callUserAPi = async () => {
      let requestBody = `
      query {
         getTobeActivatedAccountUser {
                   sponsorAccountUserId
                     sponsorAccountId
                     SponsorAccountName
                     firstName
                     middleName
                     lastName
                     email
                     phone
                     position
                     role
                     language
                     portalAccessInd
                     activated
                     primaryContactInd
             }
         }
     `;
  
      requestBody = JSON.stringify({ query: requestBody });
      setProgress(true);
  
      let res = await apiCall('getTobeActivatedAccountUser', requestBody);
      setProgress(false);
  
      //if token not validated allow user to get new token by showing link expired, click now to activate again
      if (!res) {
        console.log('Error')
      } else {
        let data = res.data.getTobeActivatedAccountUser;
        setData(data)
        propsobj.UpdateFormField({ name: "userfname", value: data.firstName })
        propsobj.UpdateFormField({ name: "userlname", value: data.lastName })
        propsobj.UpdateFormField({ name: "usermname", value: data.middleName })
        propsobj.UpdateFormField({ name: "userlang", value: data.language })
        propsobj.UpdateFormField({ name: "userphone", value: data.phone })
        propsobj.UpdateFormField({ name: "useremail", value: data.email })
        propsobj.UpdateFormField({ name: "userposition", value: data.position })
        propsobj.UpdateFormField({ name: "useraccountName", value: data.SponsorAccountName })
        propsobj.UpdateFormField({ name: "usersponsorAccountId", value: data.sponsorAccountUserId })
      }
  
  
    }
    callUserAPi()
  },[])
  let isEditIE = checkPermission('IE_Criteria_Edit', 'Update');
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);

  const handleClick = async (event) => {
    event.preventDefault();
    propsobj.history.push('/account/updateprofile');
  }
  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.push('/account/studyDetails/IEcriteria')
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="row">
        {/* <div className="col-md-3">

        </div> */}
        <div className="col-md-6">
          <div className="d-flex">

            {/* <div class="secondary-header mr-auto">Profile</div> */}
            <h1 className="mr-auto primary-header">Profile</h1>
            <div className="">
              <button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
                <i className="fas fa-pencil-alt"></i>&nbsp; EDIT</button>
            </div>


          </div>
          {/* <div className="col-md-12"> */}
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  {/* <div className="card-page-header"><span>Profile</span><span style={{ float: "right" }}><button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
                    <i className="fas fa-pencil-alt"></i>&nbsp; EDIT</button></span></div> */}
                  <form className=" h-form-1">
                    <div className="inputBox">
                      <ViewRow text="First Name" textvalue={data.firstName} />
                      <ViewRow text="Middle Name" textvalue={data.middleName} />
                      <ViewRow text="Last Name" textvalue={data.lastName} />
                      {/* <ViewRow text="Accountname" textvalue={useraccountName} />    */}
                      <ViewRow text="Language" textvalue={data.language} />
                      <ViewRow text="Position" textvalue={data.position}/>
                      {/* <ViewRow text="Role" textvalue={userrole} /> */}
                      <ViewRow text="Phone" textvalue={data.phone} />
                      <ViewRow text="Email" textvalue={data.email} />
                     
                    </div>
                  </form>
                {/* </div> */}
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));



