import React, { useState, useEffect } from 'react';

const PasswordStrength = ({ chkpassword }) => {
  const [password, setPassword] = useState([])
  const [passwordLength, setPasswordLength] = useState(false)
  const [containsNumbers, setContainsNumbers] = useState(false)
  const [isUpperCase, setIsUpperCase] = useState(false)
  const [containsSymbols, setContainsSymbols] = useState(false)

  useEffect(() => {
    let targetValue = chkpassword.replace(/\s/g, '');
    checkForNumbers(targetValue);
    checkForUpperCase(targetValue);
    checkForSymbols(targetValue);
    setPassword(targetValue);
    setPasswordLength(targetValue.length > 7 ? true : false)
  }, [chkpassword])

  //check to see if there is any number
  const checkForNumbers = (string) => {
    var matches = string.match(/\d+/g);
    setContainsNumbers(matches != null ? true : false)
  }

  // check for upper case
  const checkForUpperCase = (string) => {
    var matches = string.match(/[A-Z]/);
    setIsUpperCase(matches != null ? true : false)
  }

  // check for symbols
  const checkForSymbols = (string) => {
    var symbols = RegExp(/[^A-Z a-z0-9]/);
    setContainsSymbols(symbols.test(string) ? true : false)
  }

  // handle password


  // submit form
  // const submitForm = (e) => {
  //   e.preventDefault();
  //   alert('Form submitted!');
  // }
  let btnStatus = passwordLength && containsNumbers && isUpperCase && containsSymbols ? false : true;
  return (

    <>
      {/* <div className="title card">
        Pasword Checker
      </div>
      <div className="content card p-2">
        <form> */}
      {/* <input type="text" onChange={handleChange('password')} value={password} placeholder="Enter Password" /> */}
      <ul className="pwdstrength">
        <li className={passwordLength ? 'green' : null}>Should be More than 8 characters</li>
        <li className={containsNumbers ? 'green' : null}>Include atleast one number</li>
        <li className={isUpperCase ? 'green' : null}>Include atleast one uppercase</li>
        <li className={containsSymbols ? 'green' : null}>Include atleast one symbol</li>
      </ul>
      {/* <button className="Submit" disabled={btnStatus} onClick={submitForm}>Submit</button> */}
      {/* </form>
      </div> */}
    </>
  )
}
export default PasswordStrength