// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import matchSorter from 'match-sorter';
import SearchIcon from '@material-ui/icons/Search';
import BgImg from '../img/opensquarebg.jpg'
import $ from 'jquery'
const NCTIDs = (propsobj) => {
  const [loading, setLoading] = React.useState(false)

  const filterOptions = (options, { inputValue }) => {
    // let keys = propsobj.keys;
    return matchSorter(options, inputValue, { keys: ["value"] })
  }
  const renderOption = option => {

    return (
      <>
        {option["value"]}
      </>
    )
  }
  const getOptionLabel = option => {
    // e.g value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    if (option && option.inputValue) {
      return option.inputValue;
    }
    return option && option["value"]

  }

  return (
    <>
      <div className="opensqData ">
        <h2 style={{ color: "#23416E", marginBottom: "0px" }}>{propsobj.mainHeader}</h2>
        <p style={{ color: "#666" }}>{propsobj.subHeader}</p>
        <div className="autodropdown" >
          <form onSubmit={propsobj.onSubmit} className='card' style={{ padding: "20px" }}>
            {!propsobj.showtextfield ? <Autocomplete
              id="asynchronous-demo"
              style={{ width: 400, background: "white" }}
              className="opensquaredropdown"
              // open={nctid.value.length > 2}
              value={propsobj.nctid}
              // fullWidth
              // onOpen={() => {
              //   setOpen(true);
              // }}
              // onClose={() => {
              //   setOpen(false);
              // }}
              getOptionSelected={(option) => option.value}
              getOptionLabel={getOptionLabel}
              options={propsobj.options}
              clearOnEscape={true}
              autoHighlight={true}
              debug={true}
              disableClearable
              onChange={propsobj.autoHandleChange}
              filterOptions={filterOptions}
              // loading={loading}
              renderOption={renderOption}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Clinical Trail ID"
                  variant="outlined"
                  className="inputfield"
                  placeholder={propsobj.placeholder}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {propsobj.loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            /> :
              <TextField
                // error={error}
                id="outlined-error-helper-text"
                placeholder="Enter NCT ID"
                value={propsobj.nctvalue}
                onChange={e => propsobj.onchange(e, e.target.value)}
                fullWidth
                size='small'
                style={{ width: 400, background: "white" }}
                // helperText={error ? "Required" : ""}
                variant="outlined"
              />}
            {propsobj.threshold && <Autocomplete
              id="asynchronous-demo"
              options={[{ name: "Low", value: "low" }, { name: "Medium", value: "medium" }, { name: "High", value: "high" }]}
              getOptionLabel={option => option.value ? option.value : ""}
              value=""
              fullWidth
              clearOnEscape={true}
              filterSelectedOptions
              autoHighlight={true}
              debug={true}
              size="small"
              disableClearable
              onChange={(event, newValue) => propsobj.handleThreshold(newValue)}
              renderInput={(params) => <TextField variant="outlined" {...params} margin="none" fullWidth className="inputfield mt-3"
                style={{ background: "white", width: "100%", float: "left" }}
                placeholder="Threshold value" />}
            />}
          </form>
          <span className="text fl mt-3" style={{ color: "#244271",fontWeight:"800", fontSize: "16px" }} onClick={propsobj.openPir}>{propsobj.subtext}</span>
          <button type="submit" onClick={propsobj.onSubmit} className="btn btn-primary-blue btn-rounded btn-small fr mt-3" >Submit</button>
        </div>
        </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NCTIDs));