import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { oldgenericApi } from '../../../../common/apiconfig';
import FormHoc from '../../../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew } from '../../../../globalcomponents/formElements';
// import { Dropdowns, Causality, AE_Outcome } from '../../../common/constants';
import { connect } from 'react-redux';

const PricingInfo = (propsobj) => {
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [totalPrice, setTotalPrice] = useState('');

  useEffect(() => {
    var data = propsobj.formValues.nonRStudyInfo
    if (propsobj.formValues.nonRStudyInfo.leadStatus ==="Complete" || propsobj.formValues.nonRStudyInfo.leadStatus ==="Update") {
      propsobj.UpdateFormField({ name: "req_pricingtype", value: data.priceType })
      setTotalPrice(data.totalPrice)
      if (data.priceType === "flat") {
        propsobj.UpdateFormField({ name: "req_price", value: data.totalPrice })
      } else if (data.priceType === "per patient") {
        propsobj.UpdateFormField({ name: "req_price", value: (data.totalPrice / data.patientCount) })
      }
    } else {
      propsobj.UpdateFormField({ value: "", name: "req_price" })
      propsobj.UpdateFormField({ value: "", name: "req_pricingtype" })
      setTotalPrice('')
    }
    propsobj.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      propsobj.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, [])

  useEffect(() => {
    var data = propsobj.formValues;
    var price = data.req_price
    var pricingType = data.req_pricingtype
    if (pricingType === "flat") {
      setTotalPrice(price)
    } else if (pricingType === "per patient") {
      setTotalPrice(data.patientscount * price)
    }

  }, [propsobj.formValues.req_pricingtype,propsobj.formValues.req_price]);

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsToprice = {
    default: null,
    'options': [{ name: "Per patient", value: "per patient" }, { name: "Flat", value: "flat" }],
    freeSolo: false,
    propsobj,
    keys: ['value']
  }


  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('addPricing', event);
    if (setGo) {
      setProgress(true);
      let form = propsobj.formValues;
      let requestBody = `
      mutation 
      {
        openSquareLeadResponse(requestId:"${form.nonRStudyInfo.requestId}",
        status:"${(form.nonRStudyInfo.leadStatus === "New" || form.nonRStudyInfo.leadStatus ==="Update") ? "Submitted" : "Update"}",patientCount:"${form.patientscount}",price:"${form.req_price}",priceType:"${form.req_pricingtype}", totalPrice:"${totalPrice}") {
          code
          type
          message
          attributes
        }  
      }
          `;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await oldgenericApi('openSquareLeadResponse', requestBody, form);
      setProgress(false);
      if (res && res.data.openSquareLeadResponse.code === '200') {
        propsobj.history.push('/site/trailmatch/nonRegisteredstudies')
        propsobj.UpdateFormField({ name: "nonRegisteredStudiesData", value:"" })
      } else {
        console.log('Error!')
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    propsobj.history.goBack();
  }

  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.goBack()
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex justify-content-end mt-4">
        <div className="secondary-header mr-auto">Pricing Information</div>
      </div>
      <div className="">
        <form noValidate autoComplete="off" id="addPricing" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card mb-0">
                <div className="card-body">
                  {/* <div className="card-page-header">NAME INFORMATION</div> */}
                  <div className="inputBox">
                    <div>Sponsor Name: <span className="hekmacolor">{propsobj.formValues.nonRStudyInfo.accountName}</span></div>
                    <div>Request No: <span className="hekmacolor">{"NR"+propsobj.formValues.nonRStudyInfo.studyNum}</span></div>
                    <div>Patients Count: <span className="hekmacolor">{propsobj.formValues.patientscount}</span></div>
                    <InputField {...textProps} name="req_price" label="Price($)" must={'true'} type="text" />
                    <AutoOptions {...autoPropsToprice} name="req_pricingtype" label="Pricing Type" keyName='value' must={'true'} />
                    <div>Total Price($):<span>{totalPrice}</span></div>
                  </div>

                </div>

              </div>
            </div>
            <div className="buttonContainer">
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Next</button>
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
            </div>
          </div>
        </form>
      </div>    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PricingInfo));



