import React from 'react'
import StudyGrid from './studyGrid'

const GlobalSearch = () => {
  return (
    <>
      <StudyGrid />
    </>
  )
}
export default GlobalSearch