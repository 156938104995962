import React from 'react'
import AddResource from './fileupload/addcoursecommon'

const AddResources=()=>{
  return(
    <>
    <AddResource type="Resource" plural="resources" form="addResource"/>
    </>
  )
}
export default AddResources


