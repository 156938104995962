import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { checkPermission } from '../../../common/utils';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Elements, useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from './checkOutForm'
import Ach from './ach'
import {appConfig} from '../../../../common/constants'

const stripePromise = loadStripe(appConfig.stripe_publishable_key)

function Billing(props) {
  // let isAddStudy = checkPermission('Study_Create', 'Create');
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    props.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, []);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: 'pi_1DpdY82eZvKYlo2CePW1Fz31_secret_lZBO3DAxGFEdqLGPNhT530sQL',
  };

  return (
    <>
      {/* {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>} */}

      <div className="listing-wrap hekmaGrid mt-4">
        <div className="card col-md-6" style={{background:"white"}}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            segmented
            style={{borderBottom:"1px solid #d5d0d0"}}

          >
            <Tab label="ACH Bank" />
            {/* <Tab label="Card" /> */}
            <Tab label="Paypal" />
          </Tabs>
          {value === 0 && <div className="achCss"><Ach/></div>}
          {/* {value === 1 && <>
            <Elements stripe={stripePromise} >
              <CheckoutForm />
            </Elements>
          </>} */}
          {value === 1 && <>Paypal</>}
        </div>

      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Billing));


