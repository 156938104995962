import React, { useEffect, useState, Component } from "react";
import Loadable from 'react-loadable';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
// import { funcDropdown, roles } from '../../common/constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import Scriptstart from './scriptstart';
import HospitalFlow from "./hospitalflow";
const AdminHome = (props) => {

  useEffect(()=>{
    // funcDropdown()
    // roles()
  },[])


  return (
    <>
      {/* <!-- Content Wrapper --> */}
      <div id="content-wrapper" className="d-flex flex-column">
        {/* <!-- Main Content --> */}
        <div id="content" >
          {/* <!-- Begin Page Content --> */}
          <div className="container-fluid">
            <div className="mt-3 mb-3">
              <Switch>
                <Route path="/admin/dashboard" component={Scriptstart} />
                <Route path="/admin/hospitalflow" component={HospitalFlow} />
                <Redirect to="/admin/dashboard" />
              </Switch>
            </div>
          </div>
        </div>
        {/* <!-- End of Main Content --> */}
      </div>
      {/* <!-- End of Content Wrapper --> */}
    </>
  )
}

export default withRouter(AdminHome);


