import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../../../common/apiconfig';
import FormHoc from '../../../hoc/formHoc';
import { InputField, AutoOptions } from '../../../formElements';
import { connect } from 'react-redux';
import moveicon from '../../../../../img/move.png'
import clear from '../../../../../img/clear.svg'
import tick from '../../../../../img/Tick.svg'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
// import Sortable from '../../main'
import AddIcon from '../../../../../img/addPlus.svg'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const useStyles = makeStyles((theme) => ({
  radio: {
    '&$checked': {
      color: '#214B7D'
    }
  },
  checked: {},
  checkboxStyle: {
    margin: 0,
    padding: 0,
    cursor: "pointer"
  },
}));
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const Events = (propsobj) => {
  const classes = useStyles();
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [selected, setSelected] = useState([])
  let [assessments, setAssessments] = useState([])
  let [selectedList, setSelectedList] = useState([])
  let [addEvent, setAddEvent] = useState(false);
  let [multiselected, setMultiselected] = useState([])
  //////////////////////////////////////////////

  const getListStyle = isDraggingOver => ({
    borderStyle: 'dashed',
    borderColor: "#C3C3C3",
    borderRadius: "10px"
  });

  useEffect(() => {
    fetchData()
    // setAssessments(propsobj.formValues.allAssessments)
  }, []);

  const fetchData = async () => {
    var data = await propsobj.formValues
    var selectedIds = []
    var selectedListData = []
    if (data.scheduleEventList != undefined && data.scheduleEventList.length > 0) {
      for (var i = 0; i < data.scheduleEventList.length; i++) {
        selectedIds.push(data.scheduleEventList[i].eventKey)
        var obj = {
          id: data.scheduleEventList[i].eventKey,
          assessmentName: data.scheduleEventList[i].eventName
        }
        selectedListData.push(obj)
      }
    }

    var assessmentsData = await callAssessmentsApi()

    for (var i = 0, len1 = assessmentsData.length; i < len1; i++) {
      for (var j = 0; j < selectedListData.length; j++) {
        if (assessmentsData[i].id === selectedListData[j].id) {
          assessmentsData.splice(i, 1);
          len1 = assessmentsData.length;
        }
      }
    }
    setAssessments(assessmentsData)
    setSelected(selectedIds)
    setSelectedList(selectedListData)
  }
  const callAssessmentsApi = async () => {
    var assessmentsData = []
    let requestBody1 = {
      query: ` query {
      getAllAssessments(searchStr:"*") {
        id
        assessmentName
          }
    }`
    };
    requestBody1 = JSON.stringify(requestBody1);
    setProgress(true);
    let res1 = await apiCall('getAllAssessments', requestBody1);
    setProgress(false);
    if (!res1) {
      // Error handling
      console.log('Error!')

    } else {
      if (res1.data.getAllAssessments.length) {
        assessmentsData = res1.data.getAllAssessments
        propsobj.UpdateEntireForm({ "allAssessments": res1.data.getAllAssessments })
        return assessmentsData
      } else {
        alert('No Assessments create new')
      }
    }
  }
  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const selectedAssements = () => {

    var selectedList = []
    // var groupRight = []

    for (var i = 0; i < selected.length; i++) {
      for (var j = 0; j < propsobj.formValues.allAssessments.length; j++) {
        if (propsobj.formValues.allAssessments[j].id == selected[i]) {
          selectedList.push(propsobj.formValues.allAssessments[j])
          // groupRight.push(propsobj.formValues.allAssessments[j].assessmentName)
        }
      }
    }
    setSelectedList(selectedList)
  }
  const deleteEvent = (id) => {
    var arr = [...selectedList]
    var arr1 = [...selected]
    for (var j = 0; j < arr.length; j++) {
      if (arr[j].id == id) {
        arr.splice(j, 1)
      }
    }
    arr1 = arr1.filter(e => e !== id)
    setSelected(arr1)
    setSelectedList(arr)
  }

  const removeSegment = (e, i) => {
    e.preventDefault()

  }
  //drag n drop 

  const id2List = {
    droppable: assessments,
    droppable2: selectedList
  };

  const getList = id => id2List[id];

  const onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      if (source.droppableId === 'droppable2') {
        setSelectedList(items);
      } else {
        setAssessments(items)
      }


    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      setAssessments(result.droppable)
      setSelectedList(result.droppable2);
    }
  };

  const selectCheckbox = (e, item) => {
    e.preventDefault()
    var data = [...multiselected]
    if (e.target.checked) {
      data.push(item)
    } else {
      data = data.filter(function (obj) {
        return obj.id !== item.id;
      });
    }
    setMultiselected(data)
  }

  const movemultiitems = () => {
    var assessmentsdata = [...assessments]
    for (var i = 0; i < assessmentsdata.length; i++) {
      for (var j = 0; j < multiselected.length; j++) {
        if (assessmentsdata[i].id === multiselected[j].id) {
          assessmentsdata.splice(i, 1)
        }
      }
    }
    var selectedData = [...selectedList, ...multiselected]
    setAssessments(assessmentsdata)
    setSelectedList(selectedData)
    setMultiselected([])
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let selectedListArr = '';
    selectedList.map((item, i) => {
      selectedListArr += `{ eventType: "row",eventName:"${item.assessmentName}", eventOrder: "${i + 1}",eventKey:"${item.id}"}`;
    });
    let StudyScheduleEvent = selectedListArr.replace(/}\s*{/g, '},{');

    let segmentsArr = ''
    propsobj.formValues.segments.map((item, j) => {
      segmentsArr += `{ segmentOrder: "${j + 1}",segmentName:"${item.segmentName}", start: "${item.start}",end:"${item.end}",varience:"${item.varience}"}`;
    })
    let StudyScheduleSegment = segmentsArr.replace(/}\s*{/g, '},{');
    let form = propsobj.formValues;
    let requestBody = `
      mutation {
        createSchedule(studyId:"${form.studyId}",studyScheduleEventRelation:[${StudyScheduleEvent}],studyScheduleSegmentRelation:[${StudyScheduleSegment}]) {
          code
          type
          message
          attributes
        }
      }
          `;
    requestBody = JSON.stringify({ query: requestBody });
    let res = await apiCall('createSchedule', requestBody, form);
    setProgress(false);

    if (res && res.data.createSchedule.code === '200') {
      console.log('added');
      propsobj.UpdateFormField({ name: "tabvalue", value: 2 })
      await propsobj.callScheduleApi()
    } else {
      console.log('Error on response add account file!')
    }

  }

  //to show new event input field
  const newEvent = (e) => {
    setAddEvent(true)
  }
  //api call to add event
  const formSubmit = async (e) => {
    e.preventDefault()
    let setGo = await hocObj.handleSubmit('addEvent', e);
    if (setGo) {
      setProgress(true);
      let form = propsobj.formValues;
      let requestBody = `
      mutation{
        putAssessment(assessmentName:"${form.newEvent}") {
          code
          type
          message
          attributes
        }  
      }`;
      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('putAssessment', requestBody, form);
      setProgress(false);

      if (res && res.data.putAssessment.code === '200') {
        console.log('added');
        // await propsobj.callEvents()
        let obj = {
          name: 'newEvent',
          flag: false,
          text: ''
        }
        propsobj.updateErrorObj(obj);
        setAddEvent(false)
        var data = await callAssessmentsApi()
        var assData = [...assessments]
        for (var i = 0; i < data.length; i++) {
          if (data[i].assessmentName == propsobj.formValues.newEvent) {
            assData.push(data[i])
          }
        }
        setAssessments(assData)
        propsobj.UpdateFormField({ name: "newEvent", value: "" })
      } else if (res.data.putAssessment.code === '400') {
        let obj = {
          name: "newEvent",
          flag: true,
          text: "Already Exists"
        }
        propsobj.updateErrorObj(obj);
      } else {
        console.log('Error!')
      }
    }
  }
  const cancelNewEvent = (e) => {
    e.preventDefault()
    setAddEvent(false)
    propsobj.UpdateFormField({ name: "newEvent", value: "" })
  }
  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.goBack()
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="">
        <div className="panel-primary-wrap">
          <div className="row">

            <DragDropContext onDragEnd={onDragEnd}>
              <div className="col-md-5" >
                <div style={{ cursor: "pointer" }} >
                  <Tooltip title="Add New Assessment" >
                    <img src={AddIcon} alt="ADD" onClick={newEvent} />
                  </Tooltip>
                  <b>ALL EVENTS</b>
                </div>

                {addEvent &&
                  <div className="card" style={{ marginBottom: "3px" }}>
                    <div className="card-body" style={{ padding: "1rem" }}>
                      <form noValidate autoComplete="off" id="addEvent" className="row" onSubmit={formSubmit}>

                        <div className="col-md-8">
                          <InputField {...textProps} name="newEvent" label="Add Event" must={'true'} type="text" />
                        </div>
                        <div className="col-md-4 newEventCss">
                          <Tooltip title="Save" >
                            <img src={tick} alt="Submit" onClick={formSubmit} /></Tooltip> &nbsp;&nbsp;
                          <Tooltip title="Cancel" >
                            <img src={clear} alt="Cancel" onClick={cancelNewEvent} /></Tooltip>
                        </div>
                      </form >
                    </div>
                  </div>
                }

                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      // style={getListStyle(snapshot.isDraggingOver)}
                      className="fixedWidth"
                    >
                      {assessments.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            // onClick={onClick}
                            // onKeyDown={(event) =>
                            //   onKeyDown(event, snapshot)
                            // }
                            // style={getItemStyle(
                            //   snapshot.isDragging,
                            //   provided.draggableProps.style
                            // )}
                            > <div className="card" style={{ marginBottom: "3px" }}>
                                <div className="card-body" style={{ padding: "1rem" ,cursor:"move"}}>
                                  <Checkbox
                                    // checked={isSelect}
                                    classes={{ root: classes.radio, checked: classes.checked }}
                                    className={classes.checkboxStyle}
                                    onChange={(e) => { selectCheckbox(e, item) }}
                                  /> &nbsp;
                                  {item.assessmentName}
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div className="col-md-2 arrowMark"  >
                <Tooltip title="Click to move to active list">
                  <img src={moveicon} alt="clicktomove" style={{ cursor: "pointer" }} onClick={movemultiitems}/>
                </Tooltip>
              </div>
              <div className="col-md-5">
                <div className="activeEvents">
                  <b>ACTIVE EVENTS</b>
                </div>
                <Droppable droppableId="droppable2">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      className="fixedWidth"
                    >
                      {selectedList.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            // style={getItemStyle(
                            //   snapshot.isDragging,
                            //   provided.draggableProps.style
                            // )}
                            >
                              <div className="card" style={{ marginBottom: "3px" }}>
                                <div className="card-body" style={{ padding: "1rem" ,cursor:"move"}}>
                                  {item.assessmentName}
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
            <div className="col-md-12">
              <div className="buttonContainer" style={{ float: "right", marginTop: "2rem" }}>
                <button type="submit" className="btn btn-primary-blue btn-rounded btn-small" onClick={handleSubmit}>Save</button>
                {/* <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button> */}
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Events));
