import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { apiCall, getFiles, fileUpload } from '../../common/apiconfig';

const FileUpdate = ({ text, dataToAccept, sendMessage, loading }) => {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: dataToAccept,
    noKeyboard: true, onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }, multiple: false

  });
  const download = async (file) => {
    // e.preventDefault()
    let res = await getFiles('fileupload', file, "GET");
    if (res.code == 200) {
      var data = JSON.parse(res.attributes)
      // window.open(data.URL)
      sendMessage({ url: data.URL, name: file.detail2, type: file.type })
    }
  }
  const callapi = async () => {
    //console.log(item, "item")
    var fdata
    let requestBody = { "filename": files[0].name }
    requestBody = JSON.stringify(requestBody);
    // setProgress(true)
    loading(true)
    let res = await fileUpload('fileupload', requestBody);
    if (res.code == 200) {
      var data = JSON.parse(res.attributes)
      fdata = {
        id: data.Id,
        fileName: files[0].name,
        type: files[0].type
      }
      // docsInfo.push(fdata)
      let blob = new Blob([files[0]]);
      const response = await fetch(data.URL, {
        method: "PUT",
        headers: {
          'Content-Type': files[0].type,
        },
        body: blob,
      });
      const status = await response.status;
      if (status == 200) {
        download({ detail1: fdata.id, detail2: fdata.fileName, type: fdata.type })
        loading(false)
      }
    }
  }

  if (files.length > 0) {
    callapi()
    setFiles([])
  }
  return (
    <>
      <span {...getRootProps()}>
        <input {...getInputProps()} />
        <span>{text}</span>
      </span>
    </>
  )
}

export default FileUpdate