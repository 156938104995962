import React from 'react'
import { Route, Link, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import MainPage from './components/mainPage';


const Routing = () => (
  <Router>
    <Switch>
      <Route path="/patient" component={MainPage} />
    </Switch>
  </Router>
);
export default Routing;