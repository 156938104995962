import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EntityChip from './entitychip';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function SimpleAccordion(props) {
    const classes = useStyles();
    const onEnd = (evt, source) => {
        let target = evt.to.closest(`[data-targetid]`).getAttribute('data-targetid');
        // //console.log('source::', source)
        // //console.log('target::', target)
        // //console.log('data::', evt.to)
        // //console.log('clone::', evt.clone)
        // //console.log('dragged::', evt.item)

        if (source == target) {
            return;
        } else {
            let cardItem = evt.clone.getAttribute('data-chipdata');
            let cardItemObj = JSON.parse(cardItem);
            modifyData(source, target, cardItemObj)
        }
    }

    const modifyData = (source, targetLaneId, cardItem) => {
        // remove from old target Item

        //cssClass refers inclusion, exclusion 
        let AllData = Object.assign({}, props.allData);
        let oldtargetedItem = AllData[cardItem.ent_type]['combinedItems'];
        oldtargetedItem.splice(cardItem.inx, 1);
        // Adding dragged item in a new target
        cardItem.ent_type = targetLaneId;

        if (AllData[targetLaneId]) {
            if (AllData[targetLaneId]['combinedItems']) {
                let newtargetedItem = AllData[targetLaneId]['combinedItems'];
                newtargetedItem.push(cardItem);
            } else {
                AllData[targetLaneId]['combinedItems'] = [];
                AllData[targetLaneId]['combinedItems'].push(cardItem);
            }
        } else {
            AllData[targetLaneId] = {};
            AllData[targetLaneId]['combinedItems'] = [];
            AllData[targetLaneId]['combinedItems'].push(cardItem);
        }

        props.updateEntityData(AllData)
    }

    const getEntityChips = () => {
        return props.values['combinedItems'];
    }

    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                >
                    <Typography className={classes.heading}>{props.source}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div className={`sortableentities`} data-targetId={props.source}>
                            {getEntityChips().length >= 1 && <LoadReactSortableElements
                                list={getEntityChips()}
                                target={props.source}
                                onEnd={onEnd}
                                updateEntityData={props.updateEntityData}
                                updateEntityDataByEdit={props.updateEntityDataByEdit}
                                open={props.open}
                            />}

                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}


const LoadReactSortableElements = (props) => {
    const [state, setState] = useState(props.list);
    useEffect(() => {
        setState([...props.list])
    }, [props])

    // useEffect(() => {
    //     return () => {
    //         setState([])
    //         //dialogclose(false);
    //     };
    // });

    return (
        <>
            {/* <ReactSortable
                list={state}
                setList={setState}
                group={'shared'}
                onEnd={(item) => props.onEnd(item, props.target)}
            >

                {
                    state.map((obj, inx) => (
                        <EntityChip
                            {...obj}
                            id={`${props.target}_${obj.cssClass}_${inx}`}
                            target={props.target}
                            display_term={obj.term}
                            originalItem={obj}
                            cssClass={obj.cssClass}
                            ent_type={props.target}
                            updateEntityData={props.updateEntityData}
                            updateEntityDataByEdit={props.updateEntityDataByEdit}
                            inx={inx}
                            key={`${props.target}_${obj.cssClass}_${inx}`}
                            open={props.open}
                        />
                    ))
                }
            </ReactSortable> */}
            <div>
                {
                    state.map((obj, inx) => (
                        <EntityChip
                            {...obj}
                            id={`${props.target}_${obj.cssClass}_${inx}`}
                            target={props.target}
                            display_term={obj.term}
                            originalItem={obj}
                            cssClass={obj.cssClass}
                            ent_type={props.target}
                            updateEntityData={props.updateEntityData}
                            updateEntityDataByEdit={props.updateEntityDataByEdit}
                            inx={inx}
                            key={`${props.target}_${obj.cssClass}_${inx}`}
                            open={props.open}
                        />
                    ))
                }
            </div>
        </>
    )
}