import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import MHSearchField from '../mhSearchField';
import Medicard from '../mediCard';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { appConfig } from "../../../common/constants";
function Observations(props) {
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  useEffect(() => {
    setProgress(true);
    fetch(`${appConfig.BaseUrl}patientdetail?patientId=${props.data.subjectId}&eventType=Observation&from=0&size=1000&patientId=${props.data.subjectId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {

        setProgress(false);
        if (responseData.data.length > 0) {
          //console.log(responseData, "medications")
          var finaldata = []
          responseData.data.map((item, i) => {
            if (item.valueQuantity) {
              //console.log(i)
              var obj = {
                // "instruction": item.dosageInstruction[0].doseQuantity.value + '/' + item.dosageInstruction[0].timing.repeat.periodUnits,
                "title": process.env.REACT_APP_ENVTYPE==="dev" ? item?.code?.text : item?.code?.coding[0].display,
                "category": item.category.text,
                "value": parseFloat(item.valueQuantity.value).toFixed(2) + "(" + item.valueQuantity.unit + ")"
              }
              finaldata.push(obj)
            }
            if (item.valueCodeableConcept || item.valueString) {
              //console.log(i)
              var obj = {
                // "instruction": item.dosageInstruction[0].doseQuantity.value + '/' + item.dosageInstruction[0].timing.repeat.periodUnits,
                "title": item.code.text,
                "category": item.category.text,
                "value": item.valueCodeableConcept.text || item.valueString
              }
              finaldata.push(obj)
            }

          })
          setData(finaldata)
        } else {
          //console.log('no data found')
        }
      })
  }, [])
  //console.log(data,"Data")
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {
        data.length > 0 ? <>
          {/* <section>
            <MHSearchField
              title="Medication"
              label="Search Medication"
              btnName="ADD MEDICINE"
            />
          </section> */}

          <section className="row">
            {data.map((item, i) => (
              <div className="col-12 col-sm-4">
                {/* <Medicard
                  instruction={item.instruction}
                  title={item.title}
                  sd={item.startDate}
                  ed={item.endDate}
                  status={item.status}
                /> */}
                <section className="mediCard">
                  <div className="top d-flex justify-content-between mb-3">
                    <div className="flex-grow-1 d-flex align-items-center">
                      <span className="roundedIcon">
                        <i className="fas fa-pills"></i>
                      </span>
                      {/* <span className="ml-2 rx">
                        <i className="fas fa-prescription"></i>
                      </span> */}
                      <span className="ml-2">{item.title}</span>
                    </div>
                    {/* <span className="roundedIcon">
                        <i className="far fa-trash-alt fa-lg"></i>
                    </span> */}
                  </div>
                  <div className="daterow d-flex justify-content-between">
                    <div className="dates d-flex flex-column">
                      <span className="label">
                        Category
                      </span>
                      <span className="mt-2">
                        {item.category}
                      </span>
                    </div>
                    <div className="dates d-flex flex-column">
                      <span className="label">
                        Value
                      </span>
                      <span className="mt-2">
                        {item.value}
                      </span>
                    </div>
                  </div>
                </section>
              </div>
            ))}

          </section>
        </>
          : "No observations found"
      }
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}

export default withRouter(connect(mapStateToProps, null)(Observations));
