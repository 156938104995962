import React, { useEffect } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
const Chart = ({ options, highcharts }) => {
  useEffect(() => {
    Highcharts.chart('labdatagraph', {
      chart: {
        type: 'line',
        style: {
          fontFamily: 'Roboto, sans-serif'
      }
      },
      title: {
        text: 'Number of Subjects Actual/Target'
      },

      // subtitle: {
      //   text: 'Source: thesolarfoundation.com'
      // },

      yAxis: {
        title: {
          text: 'Count'
        }
      },

      xAxis: {
        accessibility: {
          rangeDescription: 'Month'
        },
        title: {
          text: 'Month'
        },
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },

      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },

      // plotOptions: {
      //   series: {
      //     label: {
      //       connectorAllowed: false
      //     },
      //     pointStart: January
      //   }
      // },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Planned',
        data: [30, 40, 40, 60, 70, 80, 90]
      }
      ],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    });
  }, [])
  return (
    <div id="labdatagraph" ></div>
    // <Highcharts
    //   highcharts={Highcharts}
    //   // constructorType={'chart'}
    //   options={chartOptions}
    // />
  )
}


export default Chart