import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../../formElements';
import { connect } from 'react-redux';
import { apiCall } from '../../../common/apiconfig';
import { checkPermission } from '../../../common/utils';

const UserDetails = (propsobj) => {
  let isEditUser = checkPermission('User_Details_Edit', 'Update');
  let isEditStudy = checkPermission('Study_Details_Edit', 'Update');
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  useEffect(() => {
    async function fetchData() {

      let requestBody = {
        query: `query {
                    getsponsorAccountUser(sponsorAccountUserId: "${propsobj.formValues.sponsorAccountUserId}") {
                        sponsorAccountUserId
                        sponsorAccountId
                        SponsorAccountName
                        firstName
                        lastName
                        middleName
                        email
                        phone
                        role
                        position
                        language
                        invitedBy
                        invitedDate
                        activated
                        primaryContactInd
                        createdBy
                        createdTs
                        modifiedBy
                        modifiedTs
                }
                getAccountsOfUser(sponsorAccountUserId:"${propsobj.formValues.sponsorAccountUserId}") {
                  accountType
          } 
            }`

      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getsponsorAccountUser', requestBody);
      console.log(res, "rescontcT")
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error!')

      } else {
        if (typeof res.data.getsponsorAccountUser === 'object' && res.data.getsponsorAccountUser !== null) {
          propsobj.UpdateEntireForm(res.data.getsponsorAccountUser)
          propsobj.UpdateFormField({name:"accountType",value:res.data.getAccountsOfUser[0].accountType})
        }
      }
    }
    fetchData();
  }, []);
  const handleClick = async (event) => {
    event.preventDefault();
    propsobj.history.push('/account/users/update');
  }

  const { sponsorAccountUserId = '', middleName = '', firstName = '', lastName = '', SponsorAccountName = '', role = '', email = '', phone = '', language = '', position = '' } = propsobj.formValues;
  const handleResetPwd = () => {
    console.log('work under progress')
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}


      <div className="d-flex">
        <div class="secondary-header mr-auto">User Details</div>
        <div className="">
        {
            isEditStudy === "1" ?
          <button style={{ marginRight: "6px" }} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
            Resend Invitation</button>: ""
          }
          {
            isEditStudy === "1" ? <button onClick={handleResetPwd} style={{ marginRight: "6px" }} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
              Reset Password</button> : ""
          }
          {isEditUser === "1" ?
            <button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
              <i className="fas fa-pencil-alt"></i>&nbsp; EDIT</button> : ""}
        </div>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="addAccountForm" className="row h-form-1">
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">NAME INFORMATION</div>
                  <div className="inputBox">
                    <ViewRow text="First Name" textvalue={firstName} />
                    <ViewRow text="Middle Name" textvalue={middleName == "" ? "- - -" : middleName} />
                    <ViewRow text="Last Name" textvalue={lastName} />
                    <ViewRow text="Account Name" textvalue={SponsorAccountName} />
                    <ViewRow text="Position" textvalue={position} />
                    <ViewRow text="Role" textvalue={role == ("" || null) ? "- - -" : role} />
                    <ViewRow text="Language" textvalue={language} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">CONTACT INFORMATION</div>
                  <div className="inputBox">
                    <ViewRow text="Phone" textvalue={phone} />
                    <ViewRow text="Email" textvalue={email} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetails));



