import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall } from '../../../common/apiconfig';
import FormHoc from '../../hoc/formHoc';
import { InputField, AutoOptions,AutoOptionsMultiple } from '../../formElements';
import { Dropdowns } from '../../../common/constants';
import { connect } from 'react-redux';
import BackIcon from '../../../../img/back1.svg'

const AddPag = (propsobj) => {
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [options, setOptions] = useState([])
  let [fullSite, setfullSite] = useState("")
  let [imp, setimp] = useState(false)
  let [pagId, setPagId] = useState("")

  useEffect(() => {
    async function fetchData() {
      let requestBody = {
        query: `query {
          getAccountTypeSearch(parentAccountId:"*",searchStr:"*",type:"B",accountType:"PAG") {
            sponsorAccountId
            accountName
            parentAccountId
            accountType
            status
            address1
            address2
            countryCode
            country
            state
            city
            zip
            email
            phone
            website
            duns

          }
        }
        `
      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getAccountTypeSearch', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getAccountTypeSearch.length) {

          var data = res.data.getAccountTypeSearch
          //to remove already added pags
          if (propsobj.formValues.pagRecords.length > 0) {
            var data1 = propsobj.formValues.pagRecords
            console.log(data, data1, "addpag")
            data = data.filter(function (item) {
              for (var i = 0, len = data1.length; i < len; i++) {
                if (data1[i].pagId === item.sponsorAccountId) {
                  return false;
                }
              }
              return true;
            });
          }
          setOptions(data)
        }
      }
    }
    fetchData()

    propsobj.UpdateFormField({ name: "addpag_status", value: "" })


  }, []);

  const hocObj = FormHoc(propsobj);

  const autoPropstostatus = {
    'options': Dropdowns.Study_Site_Onboard_Status,
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }
  const autoPropstoaccount = {
    'options': options,
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['accountName']
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('addIECriteria', event);
    console.log(setGo)
    if (setGo) {
      setProgress(true);
      let form = propsobj.formValues;
      console.log(form)
      let requestBody = `
        mutation 
        {
          addStudyPag(studyId: "${form.studyId}", pagId: "${pagId}" ,status:"${form.addpag_status}") {
            code
            type
            message
            attributes
          }  
        }
            `;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('addStudyPag', requestBody, form);
      setProgress(false);

      if (res && res.data.addStudyPag.code === '200') {
        propsobj.history.push('/account/studyDetails/pag');
      } else {
        console.log('Error on response add account file!')
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
    propsobj.history.goBack();
  }

  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.goBack()
  }
  const handleImpChange = () => {
    setimp(!imp)
  }
  const getPag = (value) => {
    console.log(value,"pag")
    setPagId(value.selected.sponsorAccountId)
    setfullSite(value.selected)
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* 
      <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO PAG LIST</div><br/> */}
      <div className="d-flex mt-4 justify-content-end">
        <div className="mr-auto secondary-header">Add PAG Information</div>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="addIECriteria" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="inputBox">
                    {/* <Autocomplete
                      id="asynchronous-demo"
                      disableClearable
                      getOptionLabel={option => option.accountName == undefined ? "" : option.accountName + " (" + option.address1 + ' ' + option.address2 + ' ' + option.city + ' ' + option.state + ' ' + option.country + ' ' + option.zip + ' )'}
                      options={options}
                      onChange={getPag}
                      value={fullSite == "" ? "" : { "accountName": fullSite.accountName, "address1": fullSite.address1, "address2": fullSite.address2, "city": fullSite.city, "state": fullSite.state, "country": fullSite.country, "zip": fullSite.zip }}
                      // loading={this.props.loading}
                      disableClearable="true"
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Pag Name"
                          fullWidth
                          error={errMsg || false}
                          helperText={errMsgText || ''}
                          multiline={true}
                          rows={1}
                          rowsMax={4}
                          // variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            datamust: true
                          }}
                          InputLabelProps={{
                            className: 'must',
                          }}
                        />
                      )}
                    /> */}
                   
                    <AutoOptionsMultiple {...autoPropstoaccount} autoValue={fullSite.accountName} name="pagName" label="Pag Name" keyName='accountName' must="true" callback={getPag} />
                    <AutoOptions {...autoPropstostatus} name="addpag_status" label="Status" keyName='value' must={'true'} />

                  </div>
                </div>
              </div>
            </div>
            <div className="buttonContainer">
              
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPag));



