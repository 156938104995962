import React, { useEffect, useState } from "react";
import Materialtable from '../../../globalcomponents/materialtable';
import { oldgenericApi } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkPermission } from '../../../common/utilis';
import * as R_Items from '../recentItems';

function ResourcesGrid(props) {
  let isAddCourse = checkPermission('S_Courses_Create', 'Create');

  // let [isEditCourse, setisEditCourse] = useState(checkPermission('S_Courses_Update', 'Update'));
  //console.log(isAddCourse, "isAddCourse")
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);

  useEffect(() => {
    let isEditCourse = checkPermission('S_Courses_Update', 'Update');

    if (localStorage.getItem("siterole") === "Business User") {
      isEditCourse = "1"
    }
    fetchData(isEditCourse)
    props.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, [])

  async function fetchData(isEditCourse) {

    let requestBody = {
      query: `query {
          getCourseResourceWithSupportAllStatus(studyId:"${props.formValues.autosite.sponsorAccountId}", crs_res_ind:"R",status:"${isEditCourse === "1" ? "*" : "Y"}") {
            cr {
              ID
              studyId
              name
              crs_res_ind
              summary
              type
              category
              language
              version
              createdBy
              createdTs
              modifiedBy
              modifiedTs
            }
             link {
               ID
               buArea
               buid
               type
               status
               detail1
               detail2
               detail3
               detail4
               addedById
               addedByName
               addedTs
             }
             document {
               ID
               buArea
               buid
               type
               status
               detail1
               detail2
               detail3
               detail4
               addedById
               addedByName
               addedTs
             }
             video {
               ID
               buArea
               buid
               type
               status
               detail1
               detail2
               detail3
               detail4
               addedById
               addedByName
               addedTs
             }
          }
          }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await oldgenericApi('getCourseResourceWithSupportAllStatus', requestBody);
    //console.log(res)
    setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error on response material grid!')

    } else {
      if (res.data?.getCourseResourceWithSupportAllStatus.length) {
        var data = res.data.getCourseResourceWithSupportAllStatus
        var newdata = data.map(v => ({ ...v, ...v.cr }))
        setData(newdata)
      }
    }
  }


  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    if(localStorage.getItem('whichApp')==="Site"){
      props.history.push('/site/resources/add');
    } else {
      props.history.push('/app/resources/add');
    }
  }

  const handleRowClick = async (event, rowData) => {
    await props.updateCourseData(rowData);

    console.log('rowdata:::', rowData.cr.ID);

    let dataToR_Items = {
      id: rowData.cr.ID,
      name: rowData.cr.name,
      ...rowData,
      // studyId: rowData.cr.studyId
    }

    setProgress(true);
    let response = await R_Items.updateRecentItems(dataToR_Items, R_Items.rI_resources);
    if (response) {
      let actItems = await R_Items.getRecentSearchOrItems(R_Items.rI_resources);
      if (actItems) {
        let obj = {
          obj: R_Items.rI_resources,
          value: actItems
        }
        props.updateRecentItems(obj);
      }
    }
    setProgress(false);

    if(localStorage.getItem('whichApp')==="Site"){
      props.history.push('/site/resources/View');
    } else {
      props.history.push('/app/resources/view');
    }
    
  }
  String.prototype.limit = function (length) {
    return this.length > length ? (this.substring(0, length) + '...') : this;
  }
  const Columns = [
    {
      title: 'Resource Name', field: 'name',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
      render: rowData => rowData.name.limit(40)
    },
    {
      title: 'Type', field: 'type',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: "15%"
      },
      render: rowData => rowData.type
    },
    {
      title: 'Category', field: 'category',
      cellStyle: {
        width: "15%"
      },
      render: rowData => rowData.category
    },
    { title: 'Summary', field: 'summary', render: data => data.summary.length ? data.summary.limit(50) : '---' },
    // {
    //   title: 'Links', field: 'pContact',
    //   cellStyle: { wordBreak: "break-all" },
    //   render: rowdata => {
    //     let output = [];
    //     rowdata.link.map(link => {
    //       output.push(link.detail1);
    //     });
    //     return output.join(', ');
    //   }
    // },


  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">Resources</h1>
        {
          isAddCourse === "1" ?
            <div className="">
              <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
                <i className="fa fa-plus-circle mr-1"></i> ADD RESOURCES</button>
            </div>
            : ""}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card mb-0">
          <div className="">
            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    updateCourseData: (obj) => {
      return dispatch(
        {
          type: 'updateCourseData',
          value: obj,
        }
      )
    },
    updateRecentItems: (obj) => {
      return dispatch(
        {
          type: 'updateRecentItems',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResourcesGrid));


