import React, { useEffect, useState } from 'react';
import { apiCall, getFiles, fileUpload } from '../../../common/apiconfig';
import Download from '../../../../img/download.png'
import DotsIcon from '../../../../img/dots2.svg'
import Delete from '../../../../img/delete.svg'
import CommonModal from './comonmodal'
import { useConfirm } from "material-ui-confirm";
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip';

const FileHistory = ({ open, onClose, header, filedata }) => {
  let [data, setData] = useState([])
  const confirm = useConfirm();
  let hoverElement = null;
  const hoverEnter = (event, rowData) => {
    hoverElement = event.currentTarget;
    hoverElement.classList.add('showPopover')
  }

  const hoverLeave = (event, rowData) => {
    try {
      hoverElement.classList.remove('showPopover')
    } catch (error) {

    }
  }

  const tpopoverEnter = (event, rowData) => {
  }

  const tpopoverLeave = (event, rowData) => {
  }

  useEffect(() => {
    callApi(filedata)
  }, [open])
  const callApi = async (filedata) => {
    let requestBody = `
    query {
      getSupportDetailsById(id: "${filedata.ID}") {
        ID
        buArea
        buid
        type
        status
        detail1
        detail2
        detail3
        detail4
        addedById
        addedByName
        addedTs
        } 
        }`;

    requestBody = JSON.stringify({ query: requestBody });
    // setProgress(true);

    let res = await apiCall('getSupportDetails', requestBody);
    // setProgress(false);
    if (!res) {
      console.log('Error!')
    } else {
      if (res.data.getSupportDetailsById != null) {
        setData(res.data.getSupportDetailsById)
      } else {
        setData([])
      }
    }
  }
  const download = async (e, file) => {
    e.preventDefault()
    let res = await getFiles('fileupload', file, "GET");
    if (res.code == 200) {
      var data = JSON.parse(res.attributes)
      window.open(data.URL)
    }
  }

  return (

    <CommonModal header={header} open={open} onClose={onClose}>
      <table className="viewCourseLinks">
        {/* {header === "File History" && */}
        <thead>
          <tr>
            <th>Version</th>
            <th style={{ paddingLeft: "7px", width: "40%" }}>File Name</th>
            <th style={{ paddingLeft: "7px", width: "15%" }}>Owner</th>
            <th style={{ paddingLeft: "7px", width: "25%" }}>Created </th>
            <th style={{ paddingLeft: "7px", width: "10%" }}>Download</th>
          </tr>
        </thead>
        {/* } */}
        <tbody>
          {
            data.length > 0 ? data.map((item, i) => (
              <tr>
                <td className="center">
                  {data.length - i}
                </td>
                <td>
                  {item.detail2}
                </td>
                {/* {header === "File Versions" &&
                  <td className="filedetails">
                    <div onClick={(e) => { download(e, item) }} style={{ cursor: "pointer" }}>
                      <Tooltip title="Click to download" arrow><img src={Download} alt="" /></Tooltip></div>
                  </td>
                } */}
                {/* {header === "File History" &&  */}
                <td>
                  {item.addedByName}
                </td>
                {/* } */}
                {/* {header === "File History" &&  */}
                <td>
                  {moment(item.addedTs).format('LLL')}
                </td>
                {/* } */}
                <td className="filedetails center">
                  <div onClick={(e) => { download(e, item) }} style={{ cursor: "pointer" }}>
                    <Tooltip title="Click to download" arrow><img src={Download} alt="" /></Tooltip></div>
                </td>
              </tr>

            )) :
              <tr>
                <td colSpan="4">
                  No data found</td></tr>
          }
        </tbody>
      </table>
    </CommonModal>
  );
}

export default FileHistory