import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../../common/apiconfig';
import FormHoc from '../../hoc/formHoc';
import { InputField, AutoOptions, DatePickerField } from '../../formElements';
import { Dropdowns } from '../../../common/constants';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import HekmaDatePicker from '../../HekmaDatePicker';
import moment from 'moment';
import AuditTrailFunc from '../../../common/AuditTrailFunc'

const UpdateStudy = (propsobj) => {
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [oldData, setOldData] = useState({});
  useEffect(() => {
    setOldData(propsobj.formValues)
  }, [oldData])
  useEffect(() => {
    // propsobj.clearForm();
    // console.log(Dropdowns)
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"studySponsor"})
  }, []);

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsToStatus = {
    default: {
      value: 'Active',
      label: 'Active',
    },

    'options': Dropdowns.Account_Status,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropsToDevPhase = {
    ...autoPropsToStatus,
    'options': Dropdowns.Study_Status,
    default: null,
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('addStudyForm', event);
    if (setGo) {
      setProgress(true);
      let form = propsobj.formValues;
      let requestBody = `
      mutation {
        updateStudy(
          studyId:"${form.studyId}"
        studyNumber: "${form.studyNumber}",
        parentAccountId: "${form.parentAccountId}",
        studyTitle: ${JSON.stringify(form.studyTitle)},
        nameofIMP: "${form.nameofIMP}",
        devPhase: "${form.devPhase}",
        status: "${form.status}",
        primaryEndPoint: ${JSON.stringify(form.primaryEndPoint)},
        secondaryEndPoint: ${JSON.stringify(form.secondaryEndPoint)},
        expEndPoint: ${JSON.stringify(form.expEndPoint)},
        startDt: "${form.startDt}",
        endDt: "${form.endDt}",
        studyDuration: "${form.studyDuration}",
        stoppingCriteria: ${JSON.stringify(form.stoppingCriteria)},
        numberOfSitesPlanned: "${form.numberOfSitesPlanned}",
        numberOfSubPlanned: "${form.numberOfSubPlanned}",
        route: "${form.route}",
        regiman: "${form.regiman}",
        dosage: "${form.dosage}",
        statMethod: ${JSON.stringify(form.statMethod)},
        protocolSynopsisActual:"${form.protocolSynopsisActual}",
        protocolFinalActual:"${form.protocolFinalActual}",
        fdaSubmissionActual:"${form.fdaSubmissionActual}",
        firstSite_OpenActual:"${form.firstSite_OpenActual}",
        fpiActualActual:"${form.fpiActualActual}",
        lpoActualActual:"${form.lpoActualActual}",
        dbLockActual:"${form.dbLockActual}",
        csrActualActual:"${form.csrActualActual}",
        protocolSynopsisReceived:"${form.protocolSynopsisReceived}",
        protocolFinalReceived:"${form.protocolFinalReceived}",
        fdaSubmissionReceived:"${form.fdaSubmissionReceived}",
        firstSite_OpenReceived:"${form.firstSite_OpenReceived}",
        fpiReceivedReceived:"${form.fpiReceivedReceived}",
        lpoReceivedReceived:"${form.lpoReceivedReceived}",
        dbLockReceived:"${form.dbLockReceived}",
        csrReceivedReceived:"${form.csrReceivedReceived}",
        interimAnalysis: ${JSON.stringify(form.interimAnalysis)},
        studyFlow: ${JSON.stringify(form.studyFlow)}) {
          code
          type
          message
          attributes
        }
      }
    `;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('updateStudy', requestBody, form);
      setProgress(false);

      if (res && res.data.updateStudy.code == 200) {
        // alert('contact added! you will receive mail. check it to activate the account');
        AuditTrailFunc("Study", propsobj.formValues.studyId, "Study Update", "", oldData, propsobj.formValues)
        propsobj.history.push('/account/studyDetails');
        // propsobj.clearForm();
        //logOut();
      } else {
        console.log('Error')
        //props.history.push('/account/updateContact'); // temp enabling to check update sponsor
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    propsobj.history.goBack();
  }
  const studySponsorSearch = (event) => {
    event.preventDefault();
    propsobj.history.push('/account/search');
  };

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}

        />
      </div>}

      <div className="d-flex justify-content-end">
        <div className="mr-auto secondary-header">Update Study</div>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="addStudyForm" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">PROJECT DETAILS</div>
                      <div className="inputBox">
                        <InputField {...textProps} name="studyNumber" label="Protocol Number" must={'true'} type="text" />
                        <InputField {...textProps} name="studyTitle" label="Protocol Title" must={'true'} type="text" />
                        <InputField {...textProps} name="parentAccountName" label="Sponsor" must={'false'} type="text" disabled={true} />
                        {/* <div className="searchButtonWidth">
                          <IconButton aria-label="Search" className="searchicon"
                            onClick={accountSearch}>
                            <SearchIcon />
                          </IconButton>
                        </div>  */}
                        <AutoOptions {...autoPropsToDevPhase} name="devPhase" label="Development Phase" keyName='value' must={'true'} type="text" />
                        <AutoOptions {...autoPropsToStatus} name="status" label="Status" keyName='value' must={'true'} />
                        <InputField {...textProps} name="numberOfSitesPlanned" label="Number of Sites Planned" must={'true'} type="number" />

                        <InputField {...textProps} name="numberOfSubPlanned" label="Number of Subjects Planned" must={'true'} type="number" />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">IP DETAILS</div>
                      <div className="inputBox">
                        <InputField {...textProps} name="nameofIMP" label="Investigation Product" must={'true'} type="text" />
                        <InputField {...textProps} name="route" label="Route of Administration" must={'true'} type="text" />


                        <InputField {...textProps} name="dosage" label="Dosage" must={'false'} type="text" />
                        <InputField {...textProps} name="regiman" label="Regimen" must={'false'} type="text" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">STUDY ANALYSIS</div>
                      <div className="inputBox">
                        <InputField {...textProps} name="primaryEndPoint" label="Primary End Points" must={'true'} type="text" />
                        <InputField {...textProps} name="secondaryEndPoint" label="Secondary End Points" must={'false'} type="text" />


                        <InputField {...textProps} name="expEndPoint" label="Exploratory End Points" must={'false'} type="text" />
                        <InputField {...textProps} name="statMethod" label="Statistical Methods" must={'false'} type="text" />

                        <InputField {...textProps} name="interimAnalysis" label="Interim Analysis" must={'false'} type="text" />
                        <InputField {...textProps} name="stoppingCriteria" label="Stopping Criteria" must={'false'} type="text" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">PROTOCOL TIMELINE</div>
                      <div className="inputBox">
                        <div className="row">
                          <div className="col-md-6 col-lg-6 col-xl-5 mb-2">
                            <HekmaDatePicker name="startDt" label="Start Date" must={'true'} />
                          </div>
                          <div className="col-md-6 col-lg-6 col-xl-5 mb-2">
                            <HekmaDatePicker name="endDt" label="End Date" minDate={propsobj.formValues.startDt} must={'true'} />
                          </div>
                          <div className="col-md-6 col-lg-6 col-xl-2">
                            <InputField {...textProps} name="studyDuration" label="Duration" must={'false'} type="text" disabled={true} />
                          </div>
                          <div className="col-md-6 col-lg-6 col-xl-12">
                            <InputField {...textProps} name="studyFlow" label="Study Flow" must={'false'} type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">PROTOCOL STARTUP MILESTONES</div>
                      <div className="inputBox">
                        <div className="row">
                          <div className="col-md-12">Protocol Synopsis (Final)</div>
                          <div className="col-md-6">

                            <HekmaDatePicker name="protocolSynopsisActual" label="Planned Date" must={'true'} minDate={propsobj.formValues.startDt} maxDate={propsobj.formValues.endDt} />
                          </div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="protocolSynopsisReceived" label="Recieved Date" must={'true'} minDate={propsobj.formValues.startDt} maxDate={propsobj.formValues.endDt} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">Protocol Final</div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="protocolFinalActual" label="Planned Date" must={'true'} minDate={propsobj.formValues.startDt} maxDate={propsobj.formValues.endDt} />
                          </div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="protocolFinalReceived" label="Recieved Date" must={'true'} minDate={propsobj.formValues.protocolSynopsisReceived} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">FDA Submission</div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="fdaSubmissionActual" label="Planned Date" must={'true'} minDate={propsobj.formValues.startDt} maxDate={propsobj.formValues.endDt} />
                          </div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="fdaSubmissionReceived" label="Recieved Date" must={'true'} minDate={propsobj.formValues.protocolFinalReceived} />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">PROTOCOL MILESTONES</div>
                      <div className="inputBox">
                        <div className="row">
                          <div className="col-md-12">First Site Initiation Visit</div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="firstSite_OpenActual" label="Planned Date" must={'false'} minDate={propsobj.formValues.startDt} maxDate={propsobj.formValues.endDt} />
                          </div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="firstSite_OpenReceived" label="Actual Date" must={'false'} minDate={propsobj.formValues.fdaSubmissionReceived} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">First Patient In</div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="fpiActualActual" label="Planned Date" must={'false'} minDate={propsobj.formValues.startDt} maxDate={propsobj.formValues.endDt} />
                          </div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="fpiReceivedReceived" label="Actual Date" must={'false'} minDate={propsobj.formValues.firstSite_OpenReceived} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">Last Patient Out</div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="lpoActualActual" label="Planned Date" must={'false'} minDate={propsobj.formValues.startDt} maxDate={propsobj.formValues.endDt} />
                          </div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="lpoReceivedReceived" label="Actual Date" must={'false'} minDate={propsobj.formValues.fpiReceivedReceived} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">DB Lock</div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="dbLockActual" label="Planned Date" must={'false'} minDate={propsobj.formValues.startDt} maxDate={propsobj.formValues.endDt} />
                          </div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="dbLockReceived" label="Received Date" must={'false'} minDate={propsobj.formValues.lpoReceivedReceived} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">Clinical Study Report</div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="csrActualActual" label="Planned Date" must={'false'} minDate={propsobj.formValues.startDt} maxDate={propsobj.formValues.endDt} />
                          </div>
                          <div className="col-md-6">
                            <HekmaDatePicker name="csrReceivedReceived" label="Received Date" must={'false'} minDate={propsobj.formValues.dbLockReceived} />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="buttonContainer mr-auto">
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
            </div>
          </div>


        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateStudy));