import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormHoc from '../../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew } from '../../../globalcomponents/formElements';
import HekmaDatePicker from '../HekmaDatePicker'
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { genericApi } from '../../../common/apiconfig';
import SuccessTick from '../../../img/tick.png'
import Calendar2 from '../../../img/cal1.svg'
import Calendar3 from '../../../img/cal2.svg'
import Calendar4 from '../../../img/outlook.svg'
import moment from 'moment'
import { tickFormat } from 'd3';
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function AddApointment(props) {
  const hocObj = FormHoc(props);
  let [progress, setProgress] = useState(false);
  let [dialog, setDialog] = useState(true)
  let [successmsg, setSuccessmsg] = useState(false)
  const textProps = {
    ...hocObj,
    propsobj: props
  }
  useEffect(() => {
    props.UpdateFormField({ name: "appTitle", value: "" })
    props.UpdateFormField({ name: "appStartDate", value: "" })
    props.UpdateFormField({ name: "appEndDate", value: "" })
  },[])
  const handleClose = () => {
    props.dialogclose();
  };
  const formSubmit = async (e) => {
    let setGo = await hocObj.handleSubmit('addAppointment', e);
    if (setGo) {
      setProgress(true);
      setSuccessmsg(true);
      setDialog(false);
      let form = props.formValues;
      let a = moment(form.appStartDate);
      let b = moment(form.appEndDate);
      let variance = b.diff(a, 'days') + 1;
      let requestBody = `
      mutation {
        addPatientAdhocSchedule(scheduleID:"${props.scheduleId}",patientId:"${form.patientdata.patientId}",siteId:"${form.patientdata.siteId}",eventName:"${form.appTitle}",startDate:"${form.appStartDate}",endDate:"${form.appEndDate}", segmentVariance:"${variance}"){
          code
          type
          message
          attributes
        }}`;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await genericApi('addPatientAdhocSchedule', requestBody, form);
      setProgress(false);
      if (res && res.data.addPatientAdhocSchedule.code === "200") {
        //console.log('Appointment added successfully');
        // props.dialogclose();ss
        props.callApi(props.formValues.patientdata)
        // props.UpdateFormField({ name: "appTitle", value: "" })
        // props.UpdateFormField({ name: "appStartDate", value: "" })
        // props.UpdateFormField({ name: "appEndDate", value: "" })
      } else {
        //console.log('Error!')
        //props.history.push('/account/updateContact'); // temp enabling to check update sponsor
      }
    }

  }
  const open = props.open;
  return (
    <div>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}

        />
      </div>}


      <Dialog
        onClose={handleClose}
        disableBackdropClick={true}
        aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" style={{  backgroundColor: "#1c3b62",
         color: "white",
         padding: "10px"
         }} onClose={handleClose}>
          <h1 className="mr-auto primary-header" style={{color: "white"}}> {props.title}</h1>
        </DialogTitle>
        <DialogContent dividers>
          {dialog &&
            <form noValidate autoComplete="off" id="addAppointment" className="row" onSubmit={formSubmit}>
              <div className="col-12">
                <InputField {...textProps} name="appTitle" label="Title" must={'true'} type="text" />
                <HekmaDatePicker name="appStartDate" label="Start Date" must={'true'} minDate={new Date()} />
                <HekmaDatePicker name="appEndDate" label="End Date" must={'true'} minDate={props.formValues.appStartDate} />
              </div>
              <div className="buttonContainer">
                
                <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleClose}>CANCEL</button>
                <button type="submit" className="btn btn-primary-blue btn-rounded btn-small">Save</button>
              </div>
            </form>
          }
          {successmsg &&
            <div className="appointmentSucess">
              <img src={SuccessTick} alt="Appointment Added" />
              <div className="appointmenttext">Appointment Added Successfully</div>
              <div className="datedisplay">{moment(props.formValues.appStartDate).format('llll')} to {moment(props.formValues.appEndDate).format('llll')} </div>
              <div className="calendaricons">
                <div className="item">
                  <img src={Calendar2} /> &nbsp;
                  <a href="#">Add to ical</a></div>
                <div className="item">
                  <img src={Calendar3} />&nbsp;
                  <a href="#">Add to Google Calendar</a></div>
                <div className="item">
                  <img src={Calendar4} />&nbsp;
                  <a href="#">Add to Outlook</a></div>
              </div>
            </div>
          }
        </DialogContent>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddApointment)