import React, { useEffect, useState } from "react";
import Title from './Title';
import EntityMutation from './entitymutations';
import { connect, useSelector, useDispatch } from 'react-redux';
import { ddlist } from '../../../../common/constants';
import SortableEntities from './sortableEntity';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import { appConfig } from '../../../../common/constants'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

function Entity(props) {
    let [open, setOpen] = useState(false)
    let [showEntity, setShowEntity] = useState(false)
    let reduxstateobj = useSelector(state => state);
    //let [data, setData] = useState(props.data || []);
    let [entityData, setEntitydata] = useState([]);
    const [progress, setProgress] = useState(false);

    const reduxdispatch = useDispatch();

    React.useEffect(() => {
        if (props.data) {
            setEntitydata(props.data);
        }
    }, [props.data]);

    const updateEntityData = (newData) => {// drag and drop
        setEntitydata({ ...entityData, ...newData });
    }


    // flag - true -> delete and save, flag - false-> only save
    const updateEntityDataByEdit = (newData, flag, oldEntType, newEntType, isDeleteOnly) => {

        let oldObj = { ...entityData };

        if (isDeleteOnly) {// deleting chip only
            oldObj[newData.ent_type]['combinedItems'].splice(newData.inx, 1);
            dialogclose(false);
            setEntitydata({ ...entityData, ...oldObj });
            return;
        }

        if (flag) {
            //delete in old
            let oldtargetedItem = oldObj[oldEntType]['combinedItems']
            oldtargetedItem.splice(newData.inx, 1);

            // save in new
            //oldObj[newEntType]['combinedItems'].push(newData);

            try {
                if (oldObj[newEntType] && oldObj[newEntType]['combinedItems']) {
                    oldObj[newEntType]['combinedItems'].push(newData);
                } else {
                    oldObj[newEntType] = {}
                    oldObj[newEntType]['combinedItems'] = [];
                    oldObj[newEntType]['combinedItems'].push(newData);
                }

            } catch (error) {
                alert('error in update function')
            }

        } else {// only save on old
            oldObj[newData.ent_type]['combinedItems'][newData.inx] = newData;
        }
        dialogclose(false);
        setEntitydata({ ...entityData, ...oldObj });
    }


    useEffect(() => {
        if (props.showentity) {
            setShowEntity(true);
        } else {
            setShowEntity(false)
        }
    }, [props.showentity])

    const dialogclose = (flag) => {
        setOpen(flag);
    }

    const handleClick = () => {
        setOpen(true);
    }

    const handleChange = (event) => {
        //setState({ ...state, [event.target.name]: event.target.checked });
        props.updateShowAll(event.target.checked);
    };

    const handleSubmit = () => {

        let reqObj = {}
        reqObj.response = {};
        reqObj.response.exclude_cond = [];
        reqObj.response.include_cond = [];

        Object.keys(entityData).map((item, inx, arr) => {
            entityData[item].combinedItems.map((obj, inx) => {
                if (obj.cssClass == 'inclusion') {
                    reqObj.response.include_cond.push(obj)
                } else {// exclusion
                    reqObj.response.exclude_cond.push(obj)
                }
            })
        });

        //console.log('reqobj::', reqObj);


        try {
            setProgress(true)
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(reqObj)
            };
            fetch(appConfig.AIPatients, requestOptions)
                .then(response => response.json())
                .then((data) => {
                    setProgress(false)
                    reduxdispatch({ type: 'entitypatientdata', value: data })
                    props.UpdateFormField({ name: "AIPatientsData", value: data })
                    props.history.push('/site/study/pir/searchresult');
                })

        } catch (error) {
            //console.log('Error on dnd submit::', error)
        }

        //props.history.push('/site/study/pir/searchresult');

    }

    return (
        <>

            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}

            <div className="entities">
                <section className="d-flex justify-content-between">
                    <Title title="Entities" />
                </section>
            </div>

            {(showEntity && Object.keys(entityData).length > 0) &&
                <div className="buttonContainer text-right d-flex align-items-center justify-content-end" style={{ marginTop: '-25px' }}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.showall}
                                    onChange={handleChange}
                                    name="showall"
                                    color="primary"
                                />
                            }
                            label="Show All"
                            className="m-0 mr-5"
                        />
                    </FormGroup>
                    {/* <button
                        type="submit"
                        className="btn btn-primary-blue btn-rounded btn-small"
                        onClick={handleClick}
                    >
                        Add
                    </button> */}
                </div>
            }


            {showEntity && <div className="row entityContainer mt2rem">

                <div className="col-sm-6">
                    {/* <SortableEntities state={state} setState={setState} source="lane1" /> */}
                    {/* <SortableEntities state={state} setState={setState} /> */}

                    {Object.keys(entityData).map((item, inx, arr) => {
                        //console.log('202->>>', entityData[item])
                        if (inx % 2 == 0 && entityData[item].combinedItems.length > 0) {
                            return <SortableEntities
                                values={entityData[item]}
                                source={item}
                                updateEntityData={updateEntityData}
                                updateEntityDataByEdit={updateEntityDataByEdit}
                                allData={entityData}
                                open={open}
                            //{...props}
                            />
                        }
                    })}


                </div>
                <div className="col-sm-6">
                    {Object.keys(entityData).map((item, inx, arr) => {
                        if (inx % 2 != 0 && entityData[item].combinedItems.length > 0) {
                            return <SortableEntities
                                values={entityData[item]}
                                source={item}
                                updateEntityData={updateEntityData}
                                updateEntityDataByEdit={updateEntityDataByEdit}
                                allData={entityData}
                                open={open}
                            //{...props}
                            />
                        }
                    })}
                </div>
            </div>

            }

            {/* {(showEntity && Object.keys(entityData).length > 0) &&
                <div className="buttonContainer mt2rem text-right">
                    <button
                        type="submit"
                        className="btn btn-primary-blue btn-rounded btn-small"
                        onClick={handleSubmit}
                    >
                        Find Patients
                    </button>
                </div>
            } */}

            {open && <EntityMutation
                //inclusion={true}
                open={open}
                dialogclose={dialogclose}
                title={'Add Entity Type'}
                addform={true}
                ddlist={ddlist}
                //ddlist={Object.keys(entityData)}
                allData={entityData}
                updateEntityData={updateEntityData}
                updateEntityDataByEdit={updateEntityDataByEdit}
            >
            </EntityMutation>}

        </>
    )

}

const mapStateToProps = (state) => {
    return {}
}
const mapDispatchToProps = (dispatch) => {
    return {
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Entity));
