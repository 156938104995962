import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavMenu } from '../navMenu';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import DropdownConfig from './dropdownConfig'
import EmailTemplates from './emailtemplates/home'

const menus = [
  {
    to: "/account/adminfeatures",
    text: "Dropdowns Config"
  },
  // {
  //   to: "/account/adminfeatures/emailtemplates",
  //   text: "Email Templates"
  // }
]
const AdminFeaturesHome = (props) => {
  return (
    <>
      <NavMenu menus={menus} />
      <div className="">
        <Route exact path="/account/adminfeatures" component={DropdownConfig} />
        {/* <Route path="/account/adminfeatures/emailtemplates" component={EmailTemplates} /> */}
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminFeaturesHome));




