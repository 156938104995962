import React from 'react'

const Messages = () => {
  return (
    <div className="card mt2rem">
      <div className="card-body">
        No Messages
    </div>
    </div>
  )
}
export default Messages