import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction' // needed for dayClick
import BackIcon from '../../../img/back1.svg'
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { oldgenericApi } from '../../../common/apiconfig';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Calendar from '../../../img/calendar.svg'
import AddAppointmentForm from './addAppointment'

const CalendarApp = (props) => {
  //console.log(props, "Cale")
  let [progress, setProgress] = useState(false);
  let [events, setEvents] = useState([]);
  let [open, setOpen] = useState(false);
  let [scheduleId, setScheduleId] = useState("")
  useEffect(() => {
    PatientStudyInfo()
  }, [props.formValues.patientdata])

  const PatientStudyInfo = async () =>{
    let requestBody = {
      query: `query {        
         getStudyProgress(patientId:"${props.formValues.patientdata.patientId}") {
        studyID
        accountID
        siteID
        scheduleID
      }
   }`
    };
    requestBody = JSON.stringify(requestBody);
    let res = await oldgenericApi('getStudyProgress', requestBody);
    if (!res) {
      // Error handling
      //console.log('Error on getting patient data!')
  
    } else {
      if (res.data && res.data.getStudyProgress && res.data.getStudyProgress.length > 0) {
        console.log(res.data.getStudyProgress, "res.data.getStudyProgress")
        //{"data":{"getStudyProgress":[{"studyID":"5000181","accountID":"600359","siteID":"600374","scheduleID":"202"}]}}
        var data = res.data.getStudyProgress
        for(var i = 0; i<data.length;i++){
          callApi(data[i])
        }
      }
    }
  }

  const callApi = async (data) => {
    setProgress(true)
    let requestBody = {
      query: `
          query {
            getScheduleDetailsPatient(studyId:"${data.studyID}",patientId:"${props.formValues.patientdata.patientId}",siteId:"${data.siteID}") {
              schedule {
                scheduleID
                studyId
                scheduleDsc
                effectiveDate
                createdBy
                createdTs
                modifiedBy
                modifiedTs
              }
              scheduleEventList {
                scheduleID
                eventID
                eventType
                eventOrder
                eventName
                eventKey
              }
              scheduleSegmentList {
                scheduleID
                segmentOrder
                segmentName
                start
                end
                varience
              }
              linkRowColumnPatient {
                patSchedulePk
                patientID
                siteId
                scheduleID
                rowID
                columnID
                startDate
                endDate
                segmentVariance
                actualDate
                expectedDate
                statusInd
              }
          }
      }`
    };
    requestBody = JSON.stringify(requestBody);
    setProgress(true)
    let res = await oldgenericApi('getScheduleDetailsPatient', requestBody);
    if (!res) {
      // Error handling
      //console.log('Error')

    } else {
      //console.log(res)
      setProgress(false)
      if (res.data.getScheduleDetailsPatient != null ) {
        var eventsName = res.data.getScheduleDetailsPatient.scheduleEventList
        var datesData = res.data.getScheduleDetailsPatient.linkRowColumnPatient
        var eventsData = []
        for (var i = 0; i < eventsName.length; i++) {
          for (var j = 0; j < datesData.length; j++) {
            if (eventsName[i].eventOrder === datesData[j].rowID) {
              var obj = {
                id: i,
                title: eventsName[i].eventName,
                start: new Date(datesData[j].startDate),
                end: new Date(datesData[j].startDate),
                allDay: true
                // color: "violet"
              }
              events.push(obj)
            }
          }
        }
        setProgress(false)
        setEvents([...events,...eventsData])
        // setScheduleId(res.data.getScheduleDetailsPatient.schedule.scheduleID)
        // this.setState({ progress: false, events, scheduleId: res.data.getScheduleDetailsPatient.schedule.scheduleID, patientId, siteId, studyId })
      }
    }

  }
  const goBack = () => {
    props.history.goBack()
  }
  const handleClick = () => {
    setOpen(true)
  }
  const dialogclose = () => {
    setOpen(false)
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* <div className="d-flex mb-3 justify-content-end">
        <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
          <i className="fa fa-plus-circle mr-1"></i>ADD APPOINTMENT</button>
      </div> */}
      <div className="mb-3 mt-3">
        <FullCalendar
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'prevYear,prev,next,nextYear today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
          }}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          // ref={calendarComponentRef}
          droppable={true}
          editable={true}
          weekends={true}
          events={events}
          contentHeight={"auto"}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
        // dateClick={this.handleDateClick}
        />
        {/* {
          open && <AddAppointmentForm open={open} dialogclose={dialogclose} title="Add Appointment" scheduleId={scheduleId} callApi={callApi} />
        } */}
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CalendarApp));