import React from 'react';

const ScriptStart = () => {
  const handleDev = (e, a, b) => {
    e.preventDefault();
    fetch(`https://jenkins.hekma.ai/buildByToken/buildWithParameters?job=Dev-Env-Lifecycle-Manager/dev-ctms-start-stop&token=hekma&BEHAVIOR=${a}&Action=${b}`, {
      mode: 'no-cors',
      method: 'GET',
      
    }).then(response => response.json())
      .then(responseJson => {
        console.log(responseJson);
        alert('success')
      })
      .catch((error) => {
        alert('failed')
      })
  }
  const handleNeo = (e, a, b) => {
    e.preventDefault();
    fetch(`https://jenkins.hekma.ai/buildByToken/buildWithParameters?job=Neo4J-Lifecycle/job/Neo4j-Start-Stop/&token=hekma&BEHAVIOR=${a}&Action=${b}`, {
      mode: 'no-cors',
      method: 'GET',
      
    }).then(response => response.json())
      .then(responseJson => {
        console.log(responseJson);
        alert('success')
      })
      .catch((error) => {
        alert('failed')
      })
  }
  const handlePir = (e, a, b) => {
    e.preventDefault();
    fetch(`https://jenkins.hekma.ai/buildByToken/buildWithParameters?job=PIR/job/prod-pir-start-stop/&token=hekma&BEHAVIOR=${a}&Action=${b}`, {
      mode: 'no-cors',
      method: 'GET',
      
    }).then(response => response.json())
      .then(responseJson => {
        console.log(responseJson);
        alert('success')
      })
      .catch((error) => {
        alert('failed')
      })
  }
   

  const handleDataMapping = (e, a, b) => {
    e.preventDefault();
    fetch(`https://jenkins.hekma.ai/buildByToken/buildWithParameters?job=/Datamapping/datamapping-start-stop/&token=hekma&BEHAVIOR=${a}&Action=${b}`, {
      mode: 'no-cors',
      method: 'GET',
      
    }).then(responseJson => {
        console.log(responseJson);
        alert('success')
      })
      .catch((error) => {
        alert('failed')
      })
  }
  return (
    <>
    <div className="buttonContainer">
    Dev&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <button type="button" className="btn btn-primary-blue btn-rounded btn-small mr-1" onClick={e => handleDev(e, "start", "true")}>START</button>&nbsp;&nbsp;
      <button type="button" className="btn btn-primary-grey btn-rounded btn-small mr-1" onClick={e => handleDev(e, "stop", "false")}>STOP</button>
    </div>
    <div className="buttonContainer">
    Neo4J&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button type="button" className="btn btn-primary-blue btn-rounded btn-small mr-1" onClick={e => handleNeo(e, "start", "true")}>START</button>&nbsp;&nbsp;
      <button type="button" className="btn btn-primary-grey btn-rounded btn-small mr-1" onClick={e => handleNeo(e, "stop", "false")}>STOP</button>
    </div>
    <div className="buttonContainer">
    PIR&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button type="button" className="btn btn-primary-blue btn-rounded btn-small mr-1" onClick={e => handlePir(e, "start", "true")}>START</button>&nbsp;&nbsp;
      <button type="button" className="btn btn-primary-grey btn-rounded btn-small mr-1" onClick={e => handlePir(e, "stop", "false")}>STOP</button>
    </div>
    <div className="buttonContainer">
    DATA MAPPING &nbsp;<button type="button" className="btn btn-primary-blue btn-rounded btn-small mr-1" onClick={e => handleDataMapping(e, "start", "true")}>START</button>&nbsp;&nbsp;
      <button type="button" className="btn btn-primary-grey btn-rounded btn-small mr-1" onClick={e => handleDataMapping(e, "stop", "true")}>STOP</button>
    </div>
    </>
    
    // https://jenkins.hekma.ai/buildByToken/buildWithParameters?job=/Datamapping/datamapping-start-stop&token=hekma&BEHAVIOR=stop&Action=true
  )
}
export default ScriptStart