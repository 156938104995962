import React, { useEffect, useState } from "react";
import pdArrowLeft from '../../img/dashboard_icons/Left-arrow.png'
import pdArrowRight from '../../img/dashboard_icons/right-arrow.png'
function Slider(props) {
    // console.log(props.data,"id")
    //let [lilist, setLiList] = useState(props.lilist);
    let a_innerDiv = '', liElement = '', listyle = '', limarginLeft = '', limarginRight = '', liWidth = '', liCount = '', ulElement = '', totalNoOfLi = '';
    const id = props.id;

    useEffect(() => {
        a_innerDiv = document.querySelector(`#${id} .s_innerWidth`);
        ulElement = document.querySelector(`#${id} .s_innerWidth ul`);
        liElement = document.querySelector(`#${id} .s_innerWidth li`);
        //console.log(liElement,"li")
        totalNoOfLi = document.querySelectorAll(`#${id} .s_innerWidth li`).length;
        listyle = getComputedStyle(liElement);
        limarginLeft = parseInt(listyle.marginLeft);
        limarginRight = parseInt(listyle.marginRight);
        liWidth = liElement.offsetWidth + limarginLeft + limarginRight;
        liCount = Math.floor(a_innerDiv.offsetWidth / liWidth);
        a_innerDiv.style.width = (liCount * liWidth) + 'px';

        // let tobeActiveLiElement = document.querySelector(`.liimgList li:nth-child(1)`);
        // tobeActiveLiElement.classList.add("liActive");

    })

    const rightHandler = (e) => {
        let uistyle = getComputedStyle(ulElement);
        let uiCurrentML = parseInt(uistyle.marginLeft);
        let howmanymoved = uiCurrentML < 0 ? (-uiCurrentML) / liWidth : 0;
        let howManyCanMove = totalNoOfLi - (liCount + howmanymoved);
        howManyCanMove = (howManyCanMove < liCount) ? howManyCanMove : liCount;
        ////console.log('howmanymoved::',howmanymoved,'howManyCanMove::',howManyCanMove,'uiCurrentML::',uiCurrentML)
        let howmuchToMove = howManyCanMove * liWidth;
        let mltoMove = uiCurrentML - howmuchToMove;
        ulElement.style.marginLeft = mltoMove + 'px';

        //checkWhichLiInDisplay(howmanymoved, 'right');
    }

    const leftHandler = (e) => {
        let uistyle = getComputedStyle(ulElement);
        let uiCurrentML = parseInt(uistyle.marginLeft);
        let howmanymoved = uiCurrentML < 0 ? (-uiCurrentML) / liWidth : 0;
        ////console.log('out howmanymoved::', howmanymoved);
        if (howmanymoved) {
            let howmanyCanMove = howmanymoved > liCount ? liCount : howmanymoved;
            //howManyCanMove = (howManyCanMove<liCount)? howManyCanMove : liCount;
            ////console.log('howmanymoved::', howmanymoved, 'howManyCanMove::', howmanyCanMove, 'uiCurrentML::', uiCurrentML)
            let howmuchToMove = howmanyCanMove * liWidth;
            let mltoMove = uiCurrentML + howmuchToMove;
            ulElement.style.marginLeft = mltoMove + 'px';
        }
        // checkWhichLiInDisplay(howmanymoved, 'left');
    }

    const checkWhichLiInDisplay = (howmanymoved, direction) => {
        ////console.log('howmanymoved;',howmanymoved);
        let allLiEls = document.querySelectorAll('.liimgList li');
        for (let i = 0; i < allLiEls.length; i++) {
            allLiEls[i].classList.remove("liActive");
        }
        if (direction === 'left') {
            try {
                let tobeActiveLiElement = document.querySelector(`.liimgList li:nth-child(${howmanymoved})`);
                tobeActiveLiElement.classList.add("liActive");

            } catch {
                let tobeActiveLiElement = document.querySelector(`.liimgList li:nth-child(1)`);
                tobeActiveLiElement.classList.add("liActive");
            }
        } else {
            try {
                let tobeActiveLiElement = document.querySelector(`.liimgList li:nth-child(${howmanymoved + 2})`);
                tobeActiveLiElement.classList.add("liActive");

            } catch {
                let tobeActiveLiElement = document.querySelector(`.liimgList li:nth-child(${allLiEls.length})`);
                tobeActiveLiElement.classList.add("liActive");
            }
        }

    }

    const moveLiByIndex = (e, inx) => {
        //console.log('inx', inx)

        let allLiEls = document.querySelectorAll('.liimgList li');
        for (let i = 0; i < allLiEls.length; i++) {
            allLiEls[i].classList.remove("liActive");
        }

        let tobeActiveLiElement = document.querySelector(`.liimgList li:nth-child(${inx + 1})`);
        tobeActiveLiElement.classList.add("liActive");

        let ulElement = document.querySelector(`#${id} .s_innerWidth ul`);
        let uiCurrentML = 0;
        let howmuchToMove = parseInt(inx) * liWidth;
        let mltoMove = uiCurrentML - howmuchToMove;
        ulElement.style.marginLeft = mltoMove + 'px';

    }

    return (
        <>
            {/* {props.id === "diseaseSlider" && props?.data.length > 3 && */}
            <section className="s_outerWidth" id={props.id}>
                {props?.data.length > 3 &&
                    <span className="left" onClick={leftHandler}>
                        <img src={pdArrowLeft} alt="arrow" />
                        {/* <i className="fas fa-arrow-circle-left"></i> */}
                    </span>
                }
                <div className="s_innerWidth">
                    <ul className="">
                        {props.children}
                    </ul>
                </div>
                {props?.data.length > 3 &&
                    <span className="right" onClick={rightHandler}>
                        <img src={pdArrowRight} alt="arrow" />
                        {/* <i className="fas fa-arrow-circle-right"></i> */}
                    </span>
                }
            </section>
            {/* } */}
        </>
    )
}

export default React.memo(Slider);
