import React, { useEffect, useState, useContext } from "react";
import * as R_Items from '../recentItems';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as jq from 'jquery';
import QuickLinks from './quickLinks';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { checkPermission } from '../../../common/utilis';
import Accounts from '../../../img/dashboard_icons/Accounts.svg'
import Trailmatch from '../../../img/dashboard_icons/trial-match.png'

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: "#1a3154",
        paddingLeft: "-10px"
    },
    tooltip: {
        backgroundColor: "#1A3154",
    },
}));


function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} placement="right" />;
}


const Sidebar = (props) => {
    let [patients, setPatients] = useState([]);
    let [resources, setResources] = useState([]);
    let [studies, setStudies] = useState([]);
    let [activeel, setActiveEl] = useState(props.state.sidebarActEl);
    let roleInfo = JSON.parse(localStorage.getItem('roles'))
    let [menus, setMenus] = useState([
        {
            to: "/site/dashboard",
            text: "Dashboard",
            name: "dashboard",
            clsNames: "fa fa-tachometer-alt"
        },
        {
            to: "/site/trailmatch",
            text: "Trial Match",
            clsNames: "far fa-fw fa-user-circle",
            name: 'trailmatch',
            icon: Accounts
        },
        {
            to: "/site/account",
            text: "Accounts",
            clsNames: "far fa-fw fa-user-circle",
            name: 'accounts',
            icon: Accounts
        },
        {
            to: "/site/patients",
            text: "Patients",
            name: "patients",
            clsNames: "fa fa-fw fa-users"
        },
        {
            to: "/site/resources",
            text: "Resources",
            name: "resources",
            clsNames: "far fa-fw fa-user-circle"
        },
        {
            to: "/site/study",
            text: "Studies",
            name: "studies",
            clsNames: "fa fa-fw fa-address-book"
        },
        {
            to: "/site/newpir",
            text: "Yekka",
            name: "pir",
            clsNames: "fas fa-diagnoses"
        },
        {
            to: "/site/opensquare",
            text: "PIR",
            name: "pir",
            clsNames: "fas fa-diagnoses"
        },
        {
            to: "/site/users",
            text: "User Management",
            name: "users",
            clsNames: "fa fa-fw fa-users"
        },
        // {
        //     to: "/site/:channel/video",
        //     text: "Video Chat",
        //     name: "video",
        //     clsNames: "fas fa-video"
        // },
    ])

    useEffect(() => {

        let newmenu = []
        if (roleInfo.role === "CliniOps_TM_SysAdmin") {
            if (checkPermission('MENU_Trial_Match', 'Read')==="1") {
                newmenu.push({
                    to: "/site/trailmatch",
                    text: "Trial Match",
                    clsNames: "far fa-fw fa-user-circle",
                    name: 'trailmatch',
                    icon: Accounts
                })
            }
            if (checkPermission('MENU_PIR', 'Read')==="1") {
                newmenu.push({
                    to: "/site/newpir",
                    text: "Yekka",
                    name: "pir",
                    clsNames: "fas fa-diagnoses"
                })
            }
            newmenu.push( {
                to: "/site/users",
                text: "User Management",
                name: "users",
                clsNames: "fa fa-fw fa-users"
            })
            setMenus(newmenu)
        }
      
    }, [])

    useEffect(() => {
        try {
            jq("#sidebarToggle, #sidebarToggleTop").on('click', function (e) {
                jq("body").toggleClass("sidebar-toggled");
                jq(".sidebar").toggleClass("toggled");
                // if (jq(".sidebar").hasClass("toggled")) {
                //     jq('.sidebar .collapse').collapse('hide');
                // };
            });
        } catch (error) {

        }
        let opensquare = checkPermission('S_Open_Square', 'Read');
        console.log(opensquare, "opensquare")
        if (opensquare === "1") {
            setMenus([{
                to: "/site/opensquare",
                text: "Open Square",
                name: "opensquare",
                clsNames: "fa fa-fw fa-address-book",
                icon: Trailmatch
            }])
        }

    }, []);

    useEffect(() => {
        fetchData();
    }, [props.state.patientsI, props.state.resourcesI, props.state.sitestudiesI]);

    useEffect(() => {
        setActiveEl(props.state.sidebarActEl);
    }, [props.state.sidebarActEl]);

    async function fetchData() {
        let contactItems = await R_Items.getRecentSearchOrItems(R_Items.rI_patients);//patients
        if (contactItems) {
            setPatients(contactItems);
        }

        let actItems = await R_Items.getRecentSearchOrItems(R_Items.rI_resources);//resources
        if (actItems) {
            setResources(actItems);
        }

        let studyItems = await R_Items.getRecentSearchOrItems(R_Items.rI_sitestudies);//studies
        if (studyItems) {
            setStudies(studyItems);
        }
    }
    const loadData = (arrObj, type, whatToShow) => {
        let lists = arrObj.map((item, i) => {
            return <>{(item[whatToShow])?.length > 15 ? <BootstrapTooltip title={item[whatToShow]} placement="right"><a className="collapse-item cur" key={i} onClick={() => {
                RedirectToPage(item, type)
            }}> {item[whatToShow].limit(15)}</a></BootstrapTooltip> : <a className="collapse-item" key={i} onClick={() => {
                RedirectToPage(item, type)
            }}> <span className="recentdata cur">{item[whatToShow]}</span></a>
            }
            </>
        })
        return lists;
    }
    String.prototype.limit = function (length) {
        return this.length > length ? (this.substring(0, length) + '...') : this;
    }
    const RedirectToPage = (item, type) => {
        props.sidebarActiveEl(type);
        console.log(item, type)
        if (type == 'patients') {//patients
            props.history.push('/site/patient', [item]);
            props.UpdateFormField({ name: "patientdata", value: item })
        }

        if (type == 'studies') {//studies
            props.history.push('/site/study/studydetails', [item]);
            props.UpdateFormField(item)
        }

        if (type == 'resources') {//resources
            props.history.push('/site/resources/View', [item]);
            props.updateCourseData(item);
        }
    }
    const linkHandler = (e) => {
        let setActive = e.currentTarget;
        let selectedEl = setActive.getAttribute('name');
        console.log(selectedEl, "selected")
        props.sidebarActiveEl(selectedEl);
    }
    return (
        <>

            {/* <!-- Sidebar --> */}
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                {/* <!-- Sidebar - Brand -->
            <!-- Sidebar Toggler (Sidebar) --> */}
                <div className="sidebar-brand d-flex align-items-center d-none d-md-inline">
                    <button className="border-0" id="sidebarToggle"></button>
                </div>

                <QuickLinks setActive={activeel} clickHandler={linkHandler} menus={menus} />

                {/* <!-- Divider --> */}
                {roleInfo.role !== "CliniOps_TM_SysAdmin" ? <><hr className="sidebar-divider" />
                    {/* <!-- Nav Item - Pages Collapse Menu --> */}
                    <li className="nav-item">
                        <div className="nav-link recentitems mb-0" href="#" data-toggle="collapse" data-target="#collapsePages" aria-expanded="true" aria-controls="collapsePages" style={{ cursor: "default" }}>
                            <BootstrapTooltip title="Recent Items"><i className="fa fa-clipboard-list"></i></BootstrapTooltip>
                            <span>Recent Items</span>
                        </div>

                        <div id="collapsePages" className="collapse show h-collapse recentItems" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                            <div className=" collapse-inner h-parent">





                                {resources.length > 0 && <>
                                    <div className="btn btn-link btn-link-txt btn-block" role="button" aria-expanded="false" aria-controls="collapseExample" style={{ cursor: "default" }}>
                                        Resources
                                    </div>
                                    <div className="collapse show" id="level-2">
                                        <div className="collapse-inner multi-level text-truncate">
                                            {loadData(resources, 'resources', 'name')}
                                        </div>
                                    </div>
                                </>}

                                {patients.length > 0 && <>
                                    <div className="btn btn-link btn-link-txt btn-block" role="button" aria-expanded="false" aria-controls="collapseExample" style={{ paddingTop: "15px", cursor: "default" }}>
                                        Patients
                                    </div>
                                    <div className="collapse show" id="level-2">
                                        <div className="collapse-inner multi-level text-truncate">
                                            {loadData(patients, 'patients', 'name')}
                                        </div>
                                    </div>
                                </>}



                                {studies.length > 0 && <>
                                    <div className="btn btn-link btn-link-txt btn-block" role="button" aria-expanded="false" aria-controls="collapseExample" style={{ paddingTop: "15px", cursor: "default" }}>
                                        Studies
                                    </div>
                                    <div className="collapse show" id="level-2">
                                        <div className="collapse-inner multi-level text-truncate">
                                            {loadData(studies, 'studies', 'studyNumber')}
                                        </div>
                                    </div>
                                </>}

                            </div>
                        </div>
                    </li></> : ""}
            </ul>
            {/* <!-- End of Sidebar --> */}
        </>

    )
}
//export default Sidebar;
const mapStateToProps = (state) => {
    return {
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        sidebarActiveEl: (value) => {
            return dispatch(
                {
                    type: 'sidebarActiveEl',
                    value: value,
                }
            )
        },
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        },
        updateCourseData: (obj) => {
            return dispatch(
                {
                    type: 'updateCourseData',
                    value: obj,
                }
            )
        },
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));


