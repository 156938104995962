// import React, { useEffect, useState } from "react";
// import MaterialTable from 'material-table';
// import { genericApi, apiCall } from '../../common/apiconfig';
// import { withRouter } from 'react-router-dom';
// import { connect } from 'react-redux';
// import { Paginationn } from '../../common/pagination'
// import { appConfig } from "../../common/apiconfig";
// import CircularProgress from '@material-ui/core/CircularProgress';
// // import { HekmaTextSearchField } from '../components/formElements';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import SearchIcon from '@material-ui/icons/Search';
// import {funcDropdown} from'../../common/constants'

// function DropdownConfig(props) {
//   const [offset, setoffset] = useState(0)
//   const [data, setData] = useState([])
//   let [progress, setProgress] = useState(false);
//   let [valSearch, setValSearch] = useState('');
//   let [dropdownTypes, setDropdownTypes] = useState([])

//   async function fetchData() {

//     let requestBody = {
//       query: ` query {
//           getAllLovs(searchStr:"*") {
//             id:lovKey
//             lovType
//             value:value1
//             name:value1
//             sequence:value3
//               }
//         }`
//     };

//     requestBody = JSON.stringify(requestBody);
//     setProgress(true);
//     let res = await apiCall('getAllLovs', requestBody);
//     setProgress(false);
//     if (!res) {
//       // Error handling
//       //console.log('Error on response material grid!')

//     } else {
//       if (res.data.getAllLovs.length) {
//         setData(res.data.getAllLovs)
//         var dataa = res.data.getAllLovs
//         //console.log(dataa)
//         dataa = dataa.reduce((r, i) =>
//           !r.some(j => JSON.stringify(i.lovType) === JSON.stringify(j.lovType)) ? [...r, i] : r
//           , [])

//         const dropdownTypes = {}
//         dataa.map((item, i) => {
//           if (item.lovType != "") {
//             Object.assign(dropdownTypes, { [item.lovType]: item.lovType });
//           }
//         })
//         //console.log(dataa, "hh")
//         //console.log(dropdownTypes)
//         setDropdownTypes(dropdownTypes)
//         funcDropdown()
//       } else {
//         setData([])
//       }

//     }
//   }


//   useEffect(() => {
//     fetchData()
//     // props.clearForm()
//   }, []);

//   const handlePageClick = (offset) => {
//     setoffset(offset)
//   }

//   const handleClick = async (event) => {
//     event.preventDefault();
//     props.history.push('/account/AddAccount');
//   }

//   const handleRowClick = async (event, rowData) => {
//     // save clicked rowdata in redux, make sure the logic to empty back
//     alert('under progress')
//   }


//   const Columns = [
//     {
//       title: 'Type', field: 'lovType',
//       cellStyle: {
//         color: '#244271',
//         fontWeight: 500,
//         width: '20%'
//       },
//       lookup: dropdownTypes,
//     },
//     {
//       title: 'Name', field: 'name'
//     },

//     { title: "Value", field: 'value' },
//     { title: 'Sequence', field: 'sequence' }
//   ]
//   const calladdApi = async (newData) => {
//     //console.log(newData)
//     setProgress(true)
//     let requestBody = `
//     mutation {
//      putLov(lovType:"${newData.lovType}",value1:"${newData.value}",value3:"${newData.sequence}",comment:""){
//       code
//       type
//       message
//       attributes
//     }
//     }`;

//     requestBody = JSON.stringify({ query: requestBody });
//     let res = await apiCall('putLov', requestBody);
//     setProgress(false);

//     if (res && res.data.putLov) {
//       fetchData()
//       //console.log(res, "Addres")
//     }
//   }
//   const calleditApi = async (newData) => {
//     //console.log(newData)
//     setProgress(true)
//     let requestBody = `
//     mutation {
//      updateLov(lovKey:"${newData.id}",value1:"${newData.value}",value3:"${newData.sequence}",comment:""){
//       code
//       type
//       message
//       attributes
//     }
//     }`;
//     requestBody = JSON.stringify({ query: requestBody });
//     let res = await apiCall('updateLov', requestBody);

//     if (res && res.data.updateLov) {
//       setProgress(false)
//       fetchData()
//       //console.log(res, "editres")
//     }
//   }

//   const callDeleteApi = async (id) => {
//     let requestBody = `
//     mutation {
//       deleteLov(lovKey:"${id}"){
//       code
//       type
//       message
//       attributes
//     }
//     }`;

//     requestBody = JSON.stringify({ query: requestBody });
//     let res = await apiCall('deleteLov', requestBody);
//     setProgress(false);

//     if (res && res.data.deleteLov) {
//       //console.log(res, "delete lov")
//       setProgress(false)
//       fetchData()
//     }
//   }

//   return (
//     <>
//       {progress && <div className="fullScreen">
//         <CircularProgress
//           disableShrink
//           className="progressCircle"
//           value={90}
//           size={100}
//           thickness={1.5}
//         />
//       </div>}
//       <div className="d-flex mb-3 justify-content-end">
//         <h1 className="mr-auto primary-header">Dropdown Configuration</h1>
//       </div>
//       <div className="listing-wrap hekmaGrid">
//         <div className="card">
//           <div className="hekmaGridBox">

//             <MaterialTable
//               style={{ boxShadow: "none" }}
//               columns={Columns}
//               data={data}

//               // actions={[
//               //   rowData => ({
//               //     icon: 'delete',
//               //     tooltip: 'Delete Account',
//               //     // onClick: (event, rowData) => Window.confirm("You want to delete " + rowData.accountName)

//               //   })
//               // ]}
//               // onRowClick={(event, rowData) => {
//               //   event.preventDefault();
//               //   handleRowClick(event, rowData);
//               // }}
//               options={{
//                 actionsColumnIndex: -1,
//                 searchFieldAlignment: 'left',
//                 showTitle: false,
//                 paging: true,
//                 headerStyle: {
//                   textAlign: 'left',
//                   flexDirection: 'row'
//                 },
//                 cellStyle: {
//                   textAlign: 'left',
//                   flexDirection: 'row'
//                 },
//                 padding: 'dense',
//                 draggable: false,
//                 search: true

//               }}
//               // localization={{
//               //   header: {
//               //     actions: 'Actions'
//               //   }
//               // }}
//               // components={{
//               //   Action: props => (
//               //     <div
//               //       className="fullwidth text-center delIconColor"
//               //       onClick={(event) => handleDelete(event, props.data)}
//               //     >
//               //       <i className="far fa-trash-alt fa-lg"></i>
//               //     </div>
//               //   ),
//               // }}
//               editable={{
//                 onRowAdd: newData =>
//                   new Promise((resolve, reject) => {
//                     calladdApi(newData)
//                     setTimeout(() => {
//                       calladdApi(newData)

//                       resolve();
//                     }, 1000)
//                   }),
//                 onRowUpdate: (newData, oldData) =>
//                   new Promise((resolve, reject) => {
//                     setTimeout(() => {
//                       calleditApi(newData)
//                       resolve();
//                     }, 1000)
//                   }),
//                 onRowDelete: newData =>
//                   new Promise((resolve, reject) => {
//                     setTimeout(() => {
//                       callDeleteApi(newData.id)
//                       resolve()
//                     }, 1000)
//                   }),
//               }}
//             />
//             {/* {data.length > appConfig.paginationLimit &&
//               <Paginationn handlePageClick={handlePageClick} data={data.length} offset={offset} />
//             } */}
//           </div>
//         </div>
//       </div>
//     </>
//   )

// }

// const mapStateToProps = (state) => {
//   return {
//     formValues: state.form,
//     state
//   }
// }
// const mapDispatchToProps = (dispatch) => {
//   return {
//     UpdateEntireForm: (obj) => {
//       return dispatch(
//         {
//           type: 'UpdateEntireForm',
//           value: obj,
//         }
//       )
//     },
//     UpdateFormField: (obj) => {
//       return dispatch(
//         {
//           type: 'UpdateFormField',
//           value: obj.value,
//           name: obj.name
//         }
//       )
//     },
//     updateHeaderSearchValue: (obj) => {
//       return dispatch(
//         {
//           type: 'headerSearch',
//           value: obj.value,
//         }
//       )
//     },
//     clearForm: () => {
//       return dispatch(
//         {
//           type: 'ClearForm',
//         }
//       )
//     },
//     updateRecentItems: (obj) => {
//       return dispatch(
//         {
//           type: 'updateRecentItems',
//           value: obj,
//         }
//       )
//     },
//     setSidebarActiveEl: (name) => {
//       return dispatch(
//         {
//           type: 'sidebarActiveEl',
//           value: name,
//         }
//       )
//     },
//   }
// }

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DropdownConfig));
import React from 'react'
const DropdownConfig = () => {
  return (
    <div className="m-4">
      No data found
    </div>
  )
}
export default DropdownConfig