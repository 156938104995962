import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { oldgenericApi, logOut } from '../../../common/apiconfig';
import FormHoc from '../../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew } from '../../../globalcomponents/formElements';
import { Dropdowns, regExpData } from '../../../common/constants';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

let roleObj = {
  value: '',
  label: '',
  error: false,
  helperText: '',
}

let userObjFields = {
  user_firstName: '',
  user_lastName: '',
  user_position: '',
  user_roleName: '',
  user_phone: '',
  user_email: ''
}

const AddUser = (propsobj) => {
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [accnameShow, showAccName] = useState(false);
  let [portal, setPortal] = useState(false)
  let [phoneCheck, setPhoneError] = useState('')
  let [roleField, setRoleField] = useState(roleObj);

  useEffect(() => {

    propsobj.UpdateFormField({ value: "", name: "user_firstName" })
    propsobj.UpdateFormField({ value: "", name: "user_mname" })
    propsobj.UpdateFormField({ value: "", name: "user_lastName" })
    propsobj.UpdateFormField({ value: "", name: "user_accountName" })
    propsobj.UpdateFormField({ value: "", name: "user_roles" })
    propsobj.UpdateFormField({ value: "", name: "user_position" })
    propsobj.UpdateFormField({ value: "", name: "user_lang" })
    propsobj.UpdateFormField({ value: "", name: "user_email" })
    propsobj.UpdateFormField({ value: "", name: "user_phone" })
    // propsobj.UpdateFormField({ value: false, name: "contactaddformclear" })
    if(localStorage.getItem('whichApp') === "Site"){
      propsobj.UpdateFormField({ value: propsobj.formValues.autosite.accountName, name: 'user_accountName' })
    }else{
      propsobj.UpdateFormField({ value: propsobj.formValues.accountName, name: 'user_accountName' })
    }
    
    if (propsobj.formValues.portaltoken) {
      setPortal(true)
    } else {
      setPortal(false)
    }
    propsobj.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      propsobj.UpdateFormField({ name: "hidestudytab", value: true })

      let errorFieldsObj = {};
      let errorTextObj = {};
      for (var i in userObjFields) {
        errorFieldsObj[i] = false;
        errorTextObj[i] = ""
      }
      propsobj.updateEntireErrorObj(errorFieldsObj, errorTextObj);

    }
  }, []);

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsToLang = {
    default: {
      value: 'English (US)',
      label: 'English (US)',
    },
    'options': Dropdowns.Languages,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropstoposition = {
    ...autoPropsToLang,
    'options': Dropdowns.Study_Site_Primary_Contact_Role,
    default: null,
  }
  const autoPropstorole = {
    'options': Dropdowns.Roles,
    propsobj,
    keys: ['roleName'],
    error: roleField.error,
    helperText: roleField.helperText
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('addContactForm', event);
    let phoneCheck = regExpData.phoneReg.test(propsobj.formValues.user_phone)
    if (propsobj.formValues.user_phone != "" && phoneCheck == false) {
      setPhoneError("Invalid number")
    } else {
      setPhoneError("")
      phoneCheck = true
    }
    if (setGo && phoneCheck) {
      setProgress(true);
      let form = propsobj.formValues;
      let requestBody = `
            mutation {
                createSponsorAccountUser(sponsorAccountId: "${propsobj.formValues.autosite.sponsorAccountId}", 
                    firstName: "${form.user_firstName}", 
                    middleName:"${form.user_mname}", 
                    lastName :"${form.user_lastName}",
                    email :"${form.user_email}",
                    roleId:"${form.user_roleId}",
                    phone:"${form.user_phone}",
                    position:"${form.user_position}",
                    language:"${form.user_lang == "" ? "English (US)" : form.user_lang}",
                    portalAccessInd:"${portal == true ? "Y" : "N"}",
                    invitedBy:"admin") {
                  code
                  type
                  message
                  attributes
              }
            }
          `;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await oldgenericApi('createSponsorAccountUser', requestBody, form);
      setProgress(false);

      if (res && res.data.createSponsorAccountUser.code === '200') {
        // propsobj.UpdateFormField({ value: false, name: "addContactFromContactSearch" })
        propsobj.history.goBack()
      } else {
        //console.log('Error!')
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    propsobj.history.goBack();
  }

  const accountSearch = (event) => {
    event.preventDefault();
    propsobj.history.push('/site/sponsorsearch')
  }

  const dateHandleChange = (momObj, eleName) => {
  }

  const handlePortalChange = () => {
    setPortal(!portal)
  }
  const handleRole = async (obj) => {
    let roleObj = {
      roleName: '',
      roleId: '',
      error: false,
      helperText: '',
    }
    setRoleField(roleObj);
    if (obj) {
      let roleObj = {
        roleName: obj.value,
        roleId: obj.selected.roleId,
        error: obj.flag,
        helperText: obj.text,
      }
      setRoleField(roleObj);
      propsobj.UpdateFormField({ name: "user_roleId", value: obj.selected.roleId })
      propsobj.UpdateFormField({ name: "user_roleName", value: obj.value })
    }
  }
 return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">ADD USER</h1>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="addContactForm" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">NAME INFORMATION</div>
                  <div className="inputBox">

                    <InputField {...textProps} name="user_firstName" label="First Name" must={'true'} type="text" />

                    <InputField {...textProps} name="user_mname" label="Middle Name" must={'false'} type="text" />

                    <InputField {...textProps} name="user_lastName" label="Last Name" must={'true'} type="text" />

                    <InputField {...textProps} name="user_accountName" label="Account Name" must={'true'} type="text" disabled={!accnameShow} />
                    {accnameShow &&
                      <div className="searchButtonWidth">
                        <IconButton aria-label="Search" className="searchicon"
                          onClick={accountSearch}>
                          <SearchIcon />
                        </IconButton>
                      </div>}
                    <AutoOptions {...autoPropstoposition} name="user_position" label="Position" keyName='value' must={'true'} />

                    <AutoOptions {...autoPropsToLang} name="user_lang" label="Language" keyName='value' must={'true'} />

                    <div className="row">
                      <div className="col-md-5">
                        <FormGroup style={{ paddingTop: "15px" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={portal}
                                disabled={propsobj.formValues.portaltoken}
                                onChange={(e) => { handlePortalChange(e) }}
                                value="true"
                                name="user_portalaccess"
                                color="primary"
                              />
                            }
                            label="Portal access"
                          />
                        </FormGroup>
                      </div>
                      {portal &&
                        <div className="col-md-7">
                          <AutoOptionsNew {...autoPropstorole} autoValue={roleField} name="user_roleName" label="Access level" keyName='roleName' must={portal.toString()} callback={handleRole}  {...propsobj.state} />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">CONTACT INFORMATION</div>
                  <div className="inputBox">
                    <div style={{ position: "relative" }}>
                      <InputField {...textProps} name="user_phone" label="Phone" must={'true'} type="text" />
                      {phoneCheck.length > 0 && <span className="phoneerr">{phoneCheck}</span>}
                    </div>
                    <InputField {...textProps} name="user_email" label="Email" must={'true'} type="email" />
                  </div>

                </div>
              </div>
            </div>
            <div className="buttonContainer">
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUser));



