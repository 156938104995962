import React from 'react';
import Profile from '../../siteSrc/components/profile'

const ProfilePage =()=>{
  return(
    <>
    <Profile appName={localStorage.getItem('whichApp')}/>
    </>
  )
}
export default ProfilePage