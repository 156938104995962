import { initialFormState } from './constants';

export let ErrorLables = {}
for (let i in initialFormState) {
  ErrorLables[i] = 'This field is required';  // default messages to all the fields, overwriting at below
}

ErrorLables = {
    ...ErrorLables,
    username: 'Please enter username!',
    password:'Please enter password!',
    email:"This field is required and enter valid email",
    fromtext:'Enter valid mail',
    profile_email: 'Please enter valid email!',
    forgot_email: 'Please enter valid email!',
    cpassword: 'Password missmatch!',
    required: 'Please enter this field',
    profile_password: 'Please enter password!',
    profile_cpassword: 'Please enter confirm password!',
    invalidPassword: 'Please enter valid password!',
    useremail: "This field is required and enter valid email",
    user_email: "This field is required and enter valid email",
}
