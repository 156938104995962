import React, { useEffect, useState } from "react";
import Materialtable from '../../../globalcomponents/materialtable';
import { genericApi } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

function StudyMaterial(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);

  useEffect(() => {
    async function getApiData() {
      await fetchData()
    }
    getApiData();
  }, []);

  useEffect(() => {
    async function getApiData() {
      await fetchData()
    }
    getApiData();
  }, [props.formValues.autostudy.studyId]);
  async function fetchData() {

    let requestBody = {
      query: `query {
          getCourseResourceWithSupportAllStatus(studyId:"${props.formValues.autostudy.studyId}", crs_res_ind:"*",status:"Y") {
            cr {
              ID
              studyId
              name
              crs_res_ind
              summary
              type
              category
              language
              version
              createdBy
              createdTs
              modifiedBy
              modifiedTs
            }
             link {
               ID
               buArea
               buid
               type
               status
               detail1
               detail2
               detail3
               detail4
               addedById
               addedByName
               addedTs
             }
             document {
               ID
               buArea
               buid
               type
               status
               detail1
               detail2
               detail3
               detail4
               addedById
               addedByName
               addedTs
             }
             video {
               ID
               buArea
               buid
               type
               status
               detail1
               detail2
               detail3
               detail4
               addedById
               addedByName
               addedTs
             }
          }
          }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getCourseResourceWithSupportAllStatus', requestBody);
    //console.log(res)
    setProgress(false);
    if (!res) {
      // Error handling
      alert('Error on response material grid!')

    } else {
      if (res.data.getCourseResourceWithSupportAllStatus.length) {
        var data = res.data.getCourseResourceWithSupportAllStatus
        var newdata = data.map(v => ({ ...v, ...v.cr }))
        setData(newdata)
        // setData(res.data.getCourseResourceWithSupportAllStatus)
      }
    }
  }


  const handlePageClick = (offset) => {
    setoffset(offset)
  }


  const handleRowClick = async (event, rowData) => {
    await props.updateCourseData(rowData);
    props.history.push('/site/study/studymaterial/view');
  }
  String.prototype.limit = function (length) {
    return this.length > length ? (this.substring(0, length) + '...') : this;
  }
  const Columns = [
    {
      title: 'Resource Name', field: 'name',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
      render: rowData => rowData.name.limit(30)
    },
    {
      title: 'Type', field: 'type',
      cellStyle: {
        color: '#244271',
        fontWeight: 500
      },
      render: rowData => rowData.type
    },
    {
      title: 'Category', field: 'category',
      render: rowData => rowData.category
    },
    { title: 'Summary', field: 'summary', render: data => data.summary.length ? data.summary.limit(50) : '---' },
    // {
    //   title: 'Links', field: 'pContact',
    //   cellStyle: { wordBreak: "break-all" },
    //   render: rowdata => {
    //     let output = [];
    //     rowdata.link.map(link => {
    //       output.push(link.detail1);
    //     });
    //     return output.join(', ');
    //   }
    // },


  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* <div className="d-flex justify-content-end"> */}
      {/* <div className="mr-auto secondary-header">Resources</div> */}
      {/* <div className="">
          <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
            <i className="fa fa-plus-circle mr-1"></i> ADD RESOURCES</button>
        </div> */}
      {/* </div> */}
      <div className="listing-wrap hekmaGrid mt2rem">
        <div className="card">
          <div className="">

            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    updateCourseData: (obj) => {
      return dispatch(
        {
          type: 'updateCourseData',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudyMaterial));



