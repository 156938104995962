import React, { useEffect, useState } from "react";
import LoginForm from '../login/loginForm';
import SmallLogo from '../img/Hekma-Logo-New1.svg';

const HomePage = () => {
    return (
        // <>
        //     <div className="login-wrapper d-flex align-items-center">
        //         <div className="container-fluid">
        //             <div className="row">
        //                 <div className="col-lg-8 col-md-6 col-sm-6">
        //                     <div className="d-flex align-items-center bg-login-left"></div>
        //                 </div>
        //                 <div className="col-lg-4 col-md-6 col-sm-6">
        //                     <LoginForm />
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </>
        <>
        <div className="login-wrapper d-flex align-items-center">
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 ">
                      <div className="logo-primary logo-container">
                         <img src={SmallLogo} alt="logo-small"  width="220px" height="60px" background-color="#e5edf8"/>
                      </div>
                        <div className="d-flex align-items-center bg-login-left"></div>

                        <div className="text-start mb-0"style={{fontSize:"25px"}}>Connecting <b>Underrepresented Populations</b><br></br>to Clinical Trials...</div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 align-items-center p-7rem border border-white login-container">
                        <LoginForm />
                    </div>
                </div>
            </div>
        </div>
     </>


    )
}
export default HomePage;


