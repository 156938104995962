import React, { useEffect, useState } from "react";
import { appConfig } from '../../common/constants'
import PIRTextaria from './PIRTextaria';
import CircularProgress from '@material-ui/core/CircularProgress';
import { genericApi } from '../../common/apiconfig';
import { connect, useSelector, useDispatch, ReactReduxContext } from 'react-redux';
import InclusionIcon from '../../img/Inclusion.svg'
import ExclusionIcon from '../../img/Exclusion.svg'
import AddCriteriaModal from './addcriteriamodal';
import * as jq from 'jquery';
import { withRouter } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SimpleModal from '../simplemodal'
import Loader from "../../common/loader";
import PM from '../../img/Patient-Summary.svg'
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    // [`&.${tableCellClasses.head}`]: {
    //     backgroundColor: theme.palette.common.black,
    //     color: theme.palette.common.white,
    // },
    // [`&.${tableCellClasses.body}`]: {
    //     fontSize: 14,
    // },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(nctid, patienid) {
    return { nctid, patienid };
}

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];
const initialForm = {
    inclusion: [],
    exclusion: []
}
let responseData = '';

function SearchCriteria(props) {
    console.log(props, "props")
    let [form, setForm] = useState(initialForm);
    const [entityData, setEntityData] = useState(null);
    const [progress, setProgress] = useState(false)
    let [showentity, setshowentity] = useState(false);
    let [isAddCriteria, setAddCriteria] = useState(false);
    let [criteriaType, setCriteriaType] = useState('inclusion');
    let [criteriaValue, setcriteriaValue] = useState('');
    let [isEdit, setEdit] = useState(false);
    let [arrayInx, setarrayInx] = useState();
    let [showall, setshowall] = useState(false);
    let [data, setData] = useState([])

    let [patientIDs, setPatientIDs] = useState(props.patientids ? JSON.parse(props.patientids) : [])

    let [sitePopup, setSitePopup] = useState(false);


    const reduxdispatch = useDispatch();

    useEffect(() => {
        let pirobj = {
            search: true,
            result: false,
            patient: false
        }
        reduxdispatch({ type: 'pirmenu', value: pirobj })
        props.UpdateFormField({ name: "hidestudytab", value: false })
        return () => {
            props.UpdateFormField({ name: "hidestudytab", value: true })
        }
        //verifyopenApi();
    }, []);

    async function getApiData() {
        setshowentity(false);
        setshowall(false);
        if (props.sponsor) {
            setForm({
                ...form,
                'inclusion': props.Inclusion,
                'exclusion': props.Exclusion
            })
            if (props.sponsor && props.Pir != undefined && props.Pir != "") {
                collapseLeftPanel();
                setProgress(false)
                setshowentity(true)
                responseData = props.Pir;
                formateResponseData(props.Pir);

            }
            function formateResponseData(data) {
                setEntityData(data);
            }
        } else {
            let eventType = "I"
            let requestBody = {
                query: `query {
                  getStudyEvent(studyId: "${props.formValues.autostudy.studyId}", eventType: "B") {
                    studyId
                    eventId
                    eventType
                    type
                    description
                    effectiveDate
                    status
              }
          }`
            };
            requestBody = JSON.stringify(requestBody);
            setProgress(true);
            let res = await genericApi('getStudyEvent', requestBody);
            setProgress(false);
            if (!res) {
                // Error handling
                //console.log('Error in study details!')

            } else {
                if (res.data.getStudyEvent.length) {
                    buildIEdata(res.data.getStudyEvent);
                } else {
                    setForm({
                        ...form,
                        'inclusion': [],
                        'exclusion': []
                    })
                }
            }
        }
    }
    useEffect(() => {
        getApiData()
    }, [props.formValues.autostudy.studyId])

    function buildIEdata(iedata) {
        let EData = [];
        let IData = [];
        iedata.map((item, inx) => {
            if (item.eventType == 'E' && item.status == 'Active') {
                EData.push(item.description)
            } else if (item.eventType == 'I' && item.status == 'Active') {
                IData.push(item.description)
            } else {
                return ''
            }
        })

        setForm({
            ...form,
            'inclusion': IData,
            'exclusion': EData
        })

    }



    const submitHandler = (e, showallFlag) => {

        // console.log("array stirn::", form);

        // let inclusionData = formatIEData(form.inclusion).join('');
        // let exclusionData = formatIEData(form.exclusion).join('');
        // let reqParam = {};
        // reqParam.inc = inclusionData;
        // reqParam.exc = exclusionData;
        if (form.inclusion.length == 0 && form.exclusion.length == 0) {
            alert('Please Enter anyone of the IE criteria')

        } else {

            props.history.push(props.nextPageUrl)

        }
    }

    const expandLeftPanel = () => {
        try {
            jq("#pirleftpanel").animate({ width: "30%" }, 300, () => {
                jq("#pirleftcontent").animate({ width: "100%" }, 300, () => {
                    jq("#pirleftpanel").addClass("col-12 col-sm-4");
                    jq("#pirrightpanel").addClass('col-sm-8');
                    jq("#addBtnRightPanel").hide();

                    jq("#expandClmn").removeClass("showexpand");
                    jq("#pirleftpanel").show();
                })
            });
        } catch (error) {

        }
    }

    const collapseLeftPanel = () => {
        try {
            jq("#pirleftpanel").animate({ width: "0px" }, 300, () => {
                jq("#pirleftcontent").animate({ width: "0px" }, 300, () => {
                    jq("#pirleftpanel").removeClass("col-12 col-sm-4");
                    jq("#pirrightpanel").removeClass('col-sm-8');
                    jq("#addBtnRightPanel").show();

                    jq("#expandClmn").addClass("showexpand");
                    jq("#pirleftpanel").hide();
                })
            });
        } catch (error) {

        }
    }
    const showPatientsMatched = (e) => {
        e.preventDefault();
        props.history.push('/account/pir/results')
    }

    // const openSiteSelectionPoup = () => {
    //     // e.preventDefault();
    //     // alert('open')
    //     setSitePopup(true)
    // }
    const onClose = () => {
        setSitePopup(false)
    }

    const checkLineBreak = data => {
        var match = /\r|\n/.exec(data);
        if (match) { return true } else { return false }
    }

    const storeIEcriteria = async (inc, exc) => {
        var accountId = ""
        if (window.location.hostname === appConfig.host1) {
            accountId = "600298"
        } else if (window.location.hostname === appConfig.host2) {
            accountId = "600359"
        } else {
            accountId = "600298"
        }
        let testinc = await checkLineBreak(inc)
        let testexc = await checkLineBreak(exc)
        var incl = inc
        var excl = exc
        if (testinc) {
            var incc = inc[0].replace(/\n/g, '')
            incl = []
            incl.push(incc)
        }
        if (testexc) {
            var excc = exc[0].replace(/\n/g, '')
            excl = []
            excl.push(excc)
        }

        var resdata = JSON.stringify(responseData).replace(/\\/g, "\\\\").replace(/"/g, '\\"')
        let requestBody = `
        mutation {
            createOpenSquareNonrStudy(accountId:"${accountId}",inclusion:"${(incl.join(' || '))}",exclusion:"${(excl.join(' || '))}",reference:${JSON.stringify(resdata)}) {
             code
              type
              message
              attributes
            }
          }
      `;

        requestBody = JSON.stringify({ query: requestBody });
        let res = await genericApi('createOpenSquareNonrStudy', requestBody, form);
        setProgress(false);

        if (res && res.data.createOpenSquareNonrStudy.code === '200') {
            props.history.push('/account/pir/siteselection')
            localStorage.setItem("nonRegStudyId", res.data.createOpenSquareNonrStudy.attributes)
        }
    }
    const callPatinetData = (e, patientid) => {
        e.preventDefault()

        setProgress(true)
        setData([])
        fetch(process.env.REACT_APP_NEWPIR + 'patient_summary/', {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                number: props.nctid,
                threshold: props.thresholdValue,
                "patient_id": patientid
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response, "2nd api");
                let newdata = []
                setProgress(false);
                newdata.push(response)
                setData(newdata)
                setSitePopup(true);
            })
            .catch(error => { setProgress(false); console.error(error) });
    }

    const openPatient360 = (e, patientID) => {
        e.preventDefault()
        props.UpdateFormField({ name: "patientdata", value: { "patientId": patientID, "ehrPatientId": patientID, "patient360": "Y" } })
        props.UpdateFormField({ name: "pirPatientsToken", value: true })
        if (props.app === "site") {
            props.history.push('/site/patient');
        }
        if (props.app === "app") {
            props.history.push('/app/patient');
        }


    }
    return (
        <>
            <Loader progress={progress} />

            <div className="dashboardContainer mb-3">
                <section className="row searchcriteriacontainer align-items-stretch" style={{ background: "white", borderRadius: "15px", boxShadow: "1px 1px solid black" }}>
                    {/* <SimpleModal header="Patient Info" open={sitePopup} onClose={onClose} width={"100%"}> */}
                    {sitePopup ? <><div className="col-md-12 mb-3 mt-3" > <button type="button" className="btn btn-primary-blue btn-rounded btn-small mr-1" style={{ float: "right" }} onClick={() => onClose()}>Back</button></div> <div className="col-md-12 mb-3">
                        <div><b>Overall Acuracy:</b>{data[0].overall_accuracy}%</div>
                        <div><b>Patient Summary</b></div>
                        <div style={{ textAlign: "justify" }}>{data[0].patient_summary}</div><br />
                        <div><b>Overall Conclusion</b></div>
                        <div style={{ textAlign: "justify" }}><b>Inclusion Match:</b><br /><span>{data[0].inclusion_match}</span><br />
                            <b>Exclusion Match:</b><br /><span> {data[0].exclusion_match}</span></div>

                    </div></> : <>
                        {/* </SimpleModal> */}
                        <section className="col-12 col-sm-6 mt-3">
                            <div className="pircontent" id="pirleftcontent">
                                <section className="d-flex justify-content-between">
                                    <div className="iconPlace">
                                        <img src={InclusionIcon} />&nbsp;
                                        <div className="pirtitle">{"Inclusion Criteria"}</div>
                                    </div>
                                </section>
                                <section className="searchCriteriaPanel mt-3">
                                    <ul>
                                        <PIRTextaria
                                            textvalue={form['inclusion']}
                                            name="inclusion"
                                           
                                            hideDelete={true}
                                            hideEdit={true}
                                        />
                                    </ul>
                                </section>

                                <section className="d-flex justify-content-between mt-4">
                                    <div className="iconPlace">
                                        <img src={ExclusionIcon} />&nbsp;
                                        <div className="pirtitle">{"Exclusion Criteria"}</div>
                                    </div>
                                </section>
                                <section className="searchCriteriaPanel mt-3">
                                    <PIRTextaria
                                        textvalue={form['exclusion']}
                                        name="inclusion"
                                        hideDelete={true}
                                        hideEdit={true}
                                    />
                                </section>
                            </div>

                        </section>
                        <section className="middle-clmn-to-expand" id="expandClmn">
                            <div className="expandleft-collapse">
                                <i className="far fa-file-alt"></i>
                                <p className="mt-1">Criteria</p>
                            </div>
                            <div className="ex-collapse to-right" id="expand-collapse" onClick={() => expandLeftPanel()} style={{ backgroundColor: "#234170" }}>
                                <i className="fas fa-chevron-right" style={{ color: "white" }}></i>
                            </div>
                        </section>
                        <section className="col-12 col-sm-6 mt-3">
                            <section className="pircontent">
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h5>Patients Matched for {props.nctid}</h5>
                                    <div><b>Patient Count:</b> {patientIDs.length}</div>
                                </div>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>S No</StyledTableCell>
                                                <StyledTableCell>Patient ID</StyledTableCell>
                                                <StyledTableCell style={{ textAlign: "center" }}>Summary</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {patientIDs && patientIDs.map((patienid, index) => (
                                                <TableRow key={index}>
                                                    <StyledTableCell>{index + 1}</StyledTableCell>
                                                    <StyledTableCell ><a href="#" onClick={(e) => openPatient360(e, patienid)}>{patienid}</a></StyledTableCell>
                                                    <StyledTableCell style={{ textAlign: "center" }}>
                                                        <img src={PM} alt="icon" onClick={(e) => callPatinetData(e, patienid)} style={{ cursor: "pointer" }} height={20} width={20} />
                                                    </StyledTableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </section>
                        </section>

                    </>}
                </section>

            </div>
        </>
    )

}


const mapStateToProps = (state) => {
    return {
        formValues: state.form,
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        },
        updateErrorObj: (obj) => {
            return dispatch(
                {
                    type: 'updateErrorObj',
                    flag: obj.flag,
                    name: obj.name,
                    text: obj.text
                }
            )
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchCriteria));



