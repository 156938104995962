import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut, fileUpload } from '../../../../common/apiconfig';
import FormHoc from '../../../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions } from '../../../../globalcomponents/formElements';
import { Dropdowns, regExpData } from '../../../../common/constants';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip';
import BackIcon from '../../../../img/back1.svg'
import { FileUpload } from './fileupload'
import Img from '../../../../img/Resources2.png'

function TabContainer(props) {
  return (
    <Typography component="div" >
      {props.children}
    </Typography>
  );
}
const AddCourses = (propsobj) => {
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  const [value, setValue] = React.useState(0);
  let [links, setLinks] = useState([]);
  let [docFiles, setDocFiles] = useState([])
  let [videoFiles, setVideoFiles] = useState([])
  let [docsData, setDocsData] = useState([]);
  let [videosData, setVideosData] = useState([]);
  let [urlErr, setUrlErr] = useState('')
  let [docErr, setDocErr] = useState('')
  let [videoErr, setVideoErr] = useState('')

  useEffect(() => {
    propsobj.UpdateFormField({ value: "", name: "name" })
    propsobj.UpdateFormField({ value: "", name: "summary" })
    propsobj.UpdateFormField({ value: "", name: "category" })
    propsobj.UpdateFormField({ value: "", name: "type" })
    propsobj.UpdateFormField({ value: "", name: "language" })
    propsobj.UpdateFormField({ value: "", name: "version" })
    setLinks([])
    propsobj.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      propsobj.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, [])

  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsTotype = {
    default: null,
    'options': Dropdowns.Type,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropstocategory = {
    ...autoPropsTotype,
    'options': Dropdowns.Category,
    default: null,
  }
  const autoPropstoLanguages = {
    ...autoPropstocategory,
    'options': Dropdowns.Languages,
    default: null,
  }


  const formSubmit = async (event) => {
    event.preventDefault();
    let linksArr = '';
    var linkClearErr = links.length > 0 ? false : true
    links.map(link => {
      if (link.url == "") {
        setUrlErr("Enter URL")
        linkClearErr = false
      }
      else if (regExpData.urlReg.test(link.url)) {
        setUrlErr('')
        linksArr += `{ buArea: "${propsobj.type}",type:"Link", detail1: "${link.url}",detail2:${JSON.stringify(link.description)}}`;
        linkClearErr = true
      } else {
        setUrlErr("Invalid URL")
        linkClearErr = false
      }
    });
    let linkss = linksArr.replace(/}\s*{/g, '},{');
    let docss
    if (docFiles.length == docsData.length) {
      let docsArr = ''
      setDocErr('')
      docsData.map(doc => {
        docsArr += `{buArea: "${propsobj.type}",type:"Document", detail1: "${doc.id}",detail2:"${doc.fileName}",detail3:"${doc.type}"}`;
      })
      docss = docsArr.replace(/}\s*{/g, '},{');
    } else {
      setDocErr("Upload docs to save")
    }
    let videoss
    if (videoFiles.length == videosData.length) {
      let videosArr = ''
      setVideoErr('')
      videosData.map(video => {
        videosArr += `{buArea: "${propsobj.type}",type:"Video", detail1: "${video.id}",detail2:"${video.fileName}",detail3:"${video.type}"}`;
      })
      videoss = videosArr.replace(/}\s*{/g, '},{');
    } else {
      setVideoErr("Upload videos to save")
    }
    var files = []
    // if (linkss.length > 0) {
    files.push(linkss)
    // }
    // if (docss.length > 0) {
    files.push(docss)
    // }
    // if (videoss.length > 0) {
    files.push(videoss)
    // }

    let setGo = await hocObj.handleSubmit(propsobj.form, event);
    if (setGo && linkClearErr && (docFiles.length == docsData.length) && (videoFiles.length == videosData.length)) {
      setProgress(true);
      let form = propsobj.formValues;
      let requestBody = `
      mutation {
          putCourseResourceWithSupport(studyId:"${form.autosite.sponsorAccountId}",name:${JSON.stringify(form.name)},crs_res_ind:"${propsobj.type.charAt(0)}",summary:${JSON.stringify(form.summary)},type:"${form.type}",category:"${form.category}",language:"${form.language}",version:"${form.version}",supportDetails:[${files}]){
          code
          type
          message
          attributes
        }
      }`
        ;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('putCourseResourceWithSupport', requestBody, form);
      setProgress(false);

      if (res && res.data.putCourseResourceWithSupport.code === '200') {
        if(localStorage.getItem('whichApp')==="Site"){
          propsobj.history.push('/site/' + propsobj.plural);
        }else{
          propsobj.history.push('/app/' + propsobj.plural);
        }
       
      } else {
        //console.log('Error!')
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
    propsobj.history.goBack();
  }

  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.goBack()
  }
  const handleChange = (event, index) => {
    var products = links.slice() //or [...links];
    var newUris = products.map((item, i) => {
      if (i == index) {
        item[event.target.name] = event.target.value
      }
      return item
    })
    setLinks(newUris)

  }
  const addNew = () => {
    if (links.length >= 1) {
      if (links[links.length - 1].url == "") {
        setUrlErr("Enter URL")
      }
      else if (!regExpData.urlReg.test(links[links.length - 1].url)) {
        setUrlErr("Invalid URL")
      } else {
        setUrlErr('')
        var newlinks = [...links, { url: "", description: "" }]
        setLinks(newlinks)
      }
    } else {
      var newlinks = [...links, { url: "", description: "" }]
      setLinks(newlinks)
    }
  }
  const removeLink = (e, index) => {
    var arr = [...links]
    // if (index != 0) {
    arr.splice(index, 1);
    // }
    setLinks(arr)
    if (arr.length == 0) {
      setUrlErr('')
    }
  }

  //uploaded docs or videos to save
  const fileSave = async (files) => {
    if (value == 1) {
      var docsInfo = []
      for (var i = 0; i < files.length; i++) {
        let requestBody = { "filename": files[i].name }
        requestBody = JSON.stringify(requestBody);
        setProgress(true)
        let res = await fileUpload('fileupload', requestBody);
        if (res.code == 200) {
          var data = JSON.parse(res.attributes)
          var fdata = {
            id: data.Id,
            fileName: files[i].name,
            type: files[i].type
          }
          docsInfo.push(fdata)
          let blob = new Blob([files[i]]);
          const response = await fetch(data.URL, {
            method: "PUT",
            headers: {
              'Content-Type': files[i].type,
            },
            body: blob,
          });
          const status = await response.status;
          if (status == 200) {
            setProgress(false)
          }

        } else {
          alert('failed to upload')
        }
      }
      setDocsData(docsInfo)
    }
    if (value == 2) {
      var videosInfo = []
      setProgress(true)
      for (var i = 0; i < files.length; i++) {
        let requestBody = { "filename": files[i].name }
        requestBody = JSON.stringify(requestBody);
        setProgress(true)
        let res = await fileUpload('fileupload', requestBody);
        if (res.code == 200) {
          var data = JSON.parse(res.attributes)
          var vdata = {
            id: data.Id,
            fileName: files[i].name,
            type: files[i].type
          }
          videosInfo.push(vdata)
          let blob = new Blob([files[i]]);
          const response = await fetch(data.URL, {
            method: "PUT",
            headers: {
              'Content-Type': files[i].type,
            },
            body: blob,
          });
          const status = await response.status;
          if (status == 200) {
            setProgress(false)
          }
        } else {
          alert('failed to upload')
        }
      }
      setVideosData(videosInfo)
    }

  }

  const onChangeFile = (files) => {
    var filteredArray = [];
    var existingdata = (value == 1) ? docFiles : videoFiles
    if (files.length) {
      for (var index = 0; index < files.length; index++) {
        let exists = false;
        for (var i = 0; i < existingdata.length; i++) {
          if (existingdata[i].name === files[index].name) {
            exists = true;
            break;
          }
        }
        if (!exists) {
          filteredArray.push(files[index]);
        }
      }
    }
    var data = filteredArray.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))
    if (value == 1) {
      setDocFiles([...docFiles, ...data])
    }
    if (value == 2) {
      setVideoFiles([...videoFiles, ...data])
    }
  }
  const removeFile = (e, name) => {
    if (value == 1) {
      var filesData = [...docFiles]
      docFiles.map(file => (file.name === name && filesData.splice(filesData.indexOf(file), 1)))
      var newDocsData = [...docsData]
      docsData.map(file => (file.fileName === name && newDocsData.splice(newDocsData.indexOf(file), 1)))
      setDocFiles(filesData)
      setDocsData(newDocsData)
      //console.log(filesData, newDocsData, "filesData")
    }
    if (value == 2) {
      var filesData = [...videoFiles]
      videoFiles.map(file => (file.name === name && filesData.splice(filesData.indexOf(file), 1)))
      setVideoFiles(filesData)
      var newVideosData = [...videosData]
      videosData.map(file => (file.fileName === name && newVideosData.splice(newVideosData.indexOf(file), 1)))
      setVideosData(newVideosData)
    }
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
        {/* <Loader/> */}
      </div>}

      <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO {propsobj.plural} LIST</div><br />
      <div className="d-flex justify-content-end">
        <div className="mr-auto secondary-header">Add {propsobj.type}</div>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id={propsobj.form} className="row" onSubmit={formSubmit}>
          <div className="col-md-6">

            <div className="panel-primary-wrap">
              {/* <div className="card">
                <div className="card-body"> */}
              {/* <div className="row">
                <div className="col-md-12"> */}
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">{propsobj.type} INFORMATION</div>
                  <div className="inputBox">
                    {/* <div className="row"> */}
                    {/* <div className="col-md-4"> */}
                    <InputField {...textProps} name="name" label="Name" must={'true'} type="text" />
                    {/* </div> */}
                    {/* <div className="col-md-4"> */}
                    <AutoOptions {...autoPropstocategory} name="category" label="Category" keyName='value' must={'true'} />
                    {/* </div> */}
                    {/* <div className="col-md-4"> */}
                    <InputField {...textProps} multiline={true} row={1} rowsMax={4} name="summary" label="Summary" must={'false'} type="text" />
                    {/* </div> */}
                    {/* <div className="col-md-4"> */}
                    <AutoOptions {...autoPropstoLanguages} name="language" label="Select Language" keyName='value' must={'true'} />
                    {/* </div> */}
                    {/* <div className="col-md-4"> */}
                    <AutoOptions {...autoPropsTotype} name="type" label="Type" keyName='value' must={'true'} />
                    {/* </div> */}
                    {/* <div className="col-md-4"> */}
                    <InputField {...textProps} name="version" label="Version" must={'true'} type="text" />
                    {/* </div> */}
                    {/* </div> */}
                    {/* </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6" style={{ display: "flex", alignItems: "center" }}>
            <img src={Img} alt="Image" />
          </div>
          <div className="col-md-12">

            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">{propsobj.type} FILES</div>
                  <div className="inputBox">
                    <Tabs
                      value={value}
                      onChange={handleTabsChange}
                      indicatorColor="primary"
                      textColor="primary"

                    >
                      <Tab label="Links" />
                      <Tab label="Documents" />
                      <Tab label="Videos" />
                      <div style={{ display: "inline-flex" }}>
                        <div className="errMsg">{urlErr}</div>
                        <div className="errMsg">{docErr}</div>
                        <div className="errMsg">{videoErr}</div>
                      </div>
                      {value === 0 &&
                        <div className="" >
                          <button onClick={addNew} type="button" className="btn btn-primary-blue btn-rounded" style={{ width: "92%" }}>
                            <i className="fa fa-plus-circle mr-1"></i> Add New Url</button>
                        </div>
                      }
                    </Tabs>
                    {value === 0 && <TabContainer>

                      <>
                        <div className="row">
                          {
                            links.length > 0 &&
                            <div className="col-md-12">

                              <div className="table-responsive-md">
                                <table className="table table_courses">
                                  <thead className="thead_color">
                                    <tr>
                                      <td>
                                        S.NO
                                      </td>
                                      <td>Link/URL</td>
                                      <td>Description</td>
                                      <td>Actions</td>
                                    </tr>
                                  </thead>
                                  <tbody>

                                    {
                                      links.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                              <TextField
                                                value={links[i].url}
                                                // label="Add URL"
                                                type="text"
                                                name="url"
                                                onChange={(e) => handleChange(e, i)}
                                                style={{ width: "100%" }}
                                              />
                                            </td>
                                            <td>
                                              <TextField
                                                multiline={true}
                                                rows={1}
                                                rowsMax={3}
                                                value={links[i].description}
                                                // label="Description"
                                                type="text"
                                                name="description"
                                                onChange={(e) => handleChange(e, i)}
                                                style={{ width: "100%" }}
                                              />

                                            </td>
                                            <td className="td_action">
                                              {/* <div className="action_div"  >
                                          <Tooltip title="Submit"><Icon color="primary" onSubmit={(e) => onSubmit(e, i)}
                                          >check_circle</Icon></Tooltip>
                                        </div> */}
                                              {/* {i >= 0 && */}
                                              <div className="action_div"  >
                                                <Tooltip title="Remove"><Icon color="error" onClick={(e) => removeLink(e, i)} > remove_circle</Icon></Tooltip>
                                              </div>
                                              {/* } */}
                                              {/* {links.length == i + 1 && ( */}

                                              {/* )} */}

                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                  </tbody>
                                </table>

                              </div>
                            </div>
                          }

                          <div className="col-md-2">

                          </div>
                        </div>
                        {/* <div className="errMsg">{urlErr}</div> */}


                      </>


                    </TabContainer>}
                    {value === 1 && <TabContainer><FileUpload dataToAccept='image/*,.pdf,.xlsx,.txt,.xls,.ppt,.pptx,.doc,.docx' fileSave={fileSave} onChangeFile={onChangeFile} allFiles={docFiles} removeFile={removeFile} disabled={docFiles.length == docsData.length} /> <br /> </TabContainer>}
                    {value === 2 && <TabContainer>
                      <FileUpload dataToAccept='video/*' fileSave={fileSave} onChangeFile={onChangeFile} allFiles={videoFiles} removeFile={removeFile} disabled={videoFiles.length == videosData.length} /><br />
                      {/* <VideoUpload/> */}
                      {/* <div className="errMsg">{videoErr}</div> */}
                    </TabContainer>}

                  </div>
                </div>
              </div>
            </div>
            <div className="buttonContainer mb-3">
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
            </div>
          </div>
        </form >
      </div >
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddCourses));



