import React, { useEffect, useState } from "react";
import Materialtable from '../../../../globalcomponents/materialtable';
import { apiCall } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkPermission } from '../../../common/utils';
import Img from '../../../../img/PAG.svg'
function PagGrid(props) {
  let isAddPag = checkPermission('PAG_Users_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);


  useEffect(() => {

    async function fetchData() {

      let requestBody = {
        query: `query {
          getStudyPag(studyId:"${props.formValues.studyId}") {
            studyId
            pagId
            pagAccountName
          status
            createdBy
            createdTs
            modifiedBy
            modifiedTs
          }
          }`
      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getStudyPag', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getStudyPag.length) {
          setData(res.data.getStudyPag)
        }
      }
    }
    fetchData();
  }, []);

  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    props.UpdateFormField({ name: "pagRecords", value: data })
    props.history.push('/account/studyDetails/pag/add');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    await props.UpdateEntireForm(rowData);
    props.history.push('/account/studyDetails/pag/pagDetails')
  }

  const Columns = [
    {
      title: 'PAG Name', field: 'pagAccountName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left',
        fontSize: "14px"
      },
    },
    {
      title: 'Status', field: 'status',
    }
  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="row mt-4">
        <div className="col-md-6">
          <div className="d-flex justify-content-end">
            <div className="mr-auto secondary-header">PAGs</div>
            {isAddPag === "1" ? <div className="">
              <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
                <i className="fa fa-plus-circle mr-1"></i> ADD PAG</button>
            </div> : ""}

          </div>
          <div className="listing-wrap hekmaGrid">

            <div className="card">
              <div className="">
                <Materialtable
                  columns={Columns}
                  data={data}
                  handleRowClick={handleRowClick}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6" style={{ display: "flex", alignItems: "center" }}>
            <img src={Img} alt="Image"/>
          </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PagGrid));


