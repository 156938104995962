import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
//import { apiCall, logOut, getCountry, getState } from '../../common/apiconfig';
import FormHoc from '../../../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew } from '../../../../globalcomponents/formElements';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Title from './Title';

const EntityForm = (propsobj) => {
    //console.log('fileName::', __filename);
    let [apiError, setApiError] = useState(false);
    let [progress, setProgress] = useState(false);

    //This useeffect is for componentwillunmount and it clears the form data including errors 
    useEffect(() => {
        return (() => {
            propsobj.clearForm();
        })
    }, [])

    const hocObj = FormHoc(propsobj);
    const textProps = {
        ...hocObj,
        propsobj
    }

    const formSubmit = async (event) => {
        event.preventDefault();
        let setGo = await hocObj.handleSubmit('entityForm', event);
        if (setGo) {
            setProgress(true);
            let form = propsobj.formValues;
            //   let requestBody = `
            //   mutation {
            //     createsponsorAccount(accountName:"${form.accountName}",parentAccountId:"${form.parentAccountId}",accountType:"${form.accountType}",status:"${form.status == "" ? "Active" : form.status}",address1:"${form.address1}"
            //     ,address2:"${form.address2}",countryCode:"${form.countryCode}",city:"${form.city}",state:"${form.state}",zip:"${form.zip}",email:"${form.email}",phone:"${form.phone}",website:"${form.website}",duns:"${form.duns}")
            //   }`;

            //   requestBody = JSON.stringify({ query: requestBody });
            //   let res = await apiCall('createsponsorAccount', requestBody, form);
            //   setProgress(false);

            //   if (res && res.data.createsponsorAccount) {
            //     // alert('contact added! you will receive mail. check it to activate the account');
            //     if (propsobj.formValues.studyaccountseacrh) {
            //       propsobj.history.push('/account/AddStudy')
            //       propsobj.UpdateFormField({ name: "studyaccountseacrh", value: false })
            //     } else {
            //       propsobj.history.push('/account/accountDetails');
            //       propsobj.UpdateFormField({ value: res.data.createsponsorAccount, name: "sponsorAccountId" })
            //       propsobj.UpdateFormField({ name: "accountTypeSearch", value: "*" })
            //     }
            //   } else {
            //     alert('Error on response add account file!')
            //     //props.history.push('/account/updateContact'); // temp enabling to check update sponsor
            //   }
        }
    }

    const handleCancel = (event) => {
        event.preventDefault();
    }

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}

                />
            </div>}

            <section className="">
                    <Title title="Form" />
            </section>

            <div className="">
                <form noValidate autoComplete="off" id="entityForm" className="" onSubmit={formSubmit}>
                    <div className="inputBox">
                        <InputField {...textProps} name="etype" label="Type" must={'true'} type="text" />
                        <InputField {...textProps} name="ename" label="Name" must={'true'} type="text" />

                        <div className="buttonContainer mr-auto">
                            <button type="submit" className="btn btn-primary-blue btn-rounded btn-small">Save</button>
                            {/* <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button> */}
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        formValues: state.form,
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        },
        clearForm: () => {
            return dispatch(
                {
                    type: 'ClearForm',
                }
            )
        },
        updateErrorObj: (obj) => {
            return dispatch(
                {
                    type: 'updateErrorObj',
                    flag: obj.flag,
                    name: obj.name,
                    text: obj.text
                }
            )
        },
        updateEntireErrorObj: (errobj, errtextobj) => {
            return dispatch(
                {
                    type: 'updateEntireErrorObj',
                    errobj: errobj,
                    errtextobj: errtextobj,
                }
            )
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EntityForm));