import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../../../common/apiconfig';
import FormHoc from '../../../hoc/formHoc';
import { InputField, AutoOptions } from '../../../formElements';
import { connect } from 'react-redux';
import BackIcon from '../../../../../img/back1.svg'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '../../../../../img/addPlus.svg'
import Close from '../../../../../img/eventsClose.svg'

const Segments = (propsobj) => {
  console.log(propsobj)
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);


  useEffect(() => {

  }, [propsobj.formValues.segments]);

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const handleChange = (event, index) => {
    console.log(event.target.name, event.target.value, index)
    var products = propsobj.formValues.segments.slice() //or [...links];
    var newSegments = products.map((item, i) => {
      if (i == index) {
        item[event.target.name] = event.target.value
      }
      return item
    })
    propsobj.UpdateEntireForm({ "segments": newSegments })

  }
  const addSegment = (e) => {
    e.preventDefault()
    var obj = {
      segmentName: "", start: "", end: "", varience: ""
    }
    var addSegment = [...propsobj.formValues.segments]
    addSegment.push(obj)
    console.log(addSegment)
    propsobj.UpdateEntireForm({ "segments": addSegment })
  }

  const removeSegment = (e, i) => {
    e.preventDefault()
    var arr = [...propsobj.formValues.segments]
    arr.splice(i, 1);
    propsobj.UpdateEntireForm({ "segments": arr })
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    propsobj.UpdateFormField({ name: "tabvalue", value: 1 })
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="">
        <form noValidate autoComplete="off" id="addSegments" onSubmit={formSubmit}>

          <div className="panel-primary-wrap">
            <div className="row">
              <div className="col-md-12 d-flex">
                {/* <div className="mr-auto segmenttext"> */}
                  <div className="secondary-header mr-auto">Segments</div>
                {/* </div> */}
                <div style={{ cursor: "pointer"}}>
                  <Tooltip title="Add New Segment" >
                    <img src={AddIcon} alt="ADD" onClick={addSegment} />
                  </Tooltip>
                </div>

              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {/* <div className="card-page-header">NAME INFORMATION</div> */}
                    <div className="inputBox ">
                      {
                        propsobj.formValues.segments.length > 0 &&
                        <div class="table-responsive-md">
                          <table class="table table_courses">
                            <thead className="thead_color">
                              <tr>
                                <td>
                                  SEGMENTS</td>
                                {/* <td>VALUE</td> */}
                                <td>START</td>
                                <td>END</td>
                                <td>VARIANCE</td>
                                <td>Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                propsobj.formValues.segments.map((item, i) => (
                                  <tr>
                                    <td><TextField
                                      value={item.segmentName}
                                      // label="Add URL"
                                      type="text"
                                      name="segmentName"
                                      onChange={(e) => handleChange(e, i)}
                                      style={{ width: "100%" }}
                                    /></td>
                                    <td><TextField
                                      value={item.start}
                                      // label="Add URL"
                                      type="number"
                                      name="start"
                                      onChange={(e) => handleChange(e, i)}
                                      style={{ width: "100%" }} /></td>
                                    <td><TextField
                                      value={item.end}
                                      // label="Add URL"
                                      type="number"
                                      name="end"
                                      onChange={(e) => handleChange(e, i)}
                                      style={{ width: "100%" }} /></td>
                                    <td><TextField
                                      value={item.varience}
                                      // label="Add URL"
                                      type="number"
                                      name="varience"
                                      onChange={(e) => handleChange(e, i)}
                                      style={{ width: "100%" }} /></td>
                                    <td>

                                      <div className="segmentsX_icon"  >
                                        <Tooltip title="Remove">
                                          <img src={Close} alt="Remove" onClick={(e) => removeSegment(e, i)} /></Tooltip>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              }

                            </tbody>
                          </table>
                        </div>
                      }
                    </div>

                  </div>
                </div>
                {/* <div className="col-md-12"> */}
                <div className="buttonContainer">
                  <button type="submit" className="btn btn-primary-blue btn-rounded btn-small">Next</button>
                  {/* <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button> */}
                  {/* </div> */}
                </div>
              </div>


            </div>

          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Segments));



