import React, { useEffect, useState, useReducer, useContext } from "react";
import { genericApi } from '../../../common/apiconfig';
import MaterialTable from 'material-table';
import { Paginationn } from '../../../common/pagination'
import { appConfig } from "../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Move_Icon from '../../../img/move_to.svg'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Calender from './precalendar'
import { checkPermission } from '../../../common/utilis';
import Calendar from '../../../img/calendar.svg'

function Enroll(props) {
  let [isMovePatients, setisMovePatients] = useState(checkPermission('S_moveto_patients', 'Read'))
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');
  let [show, setShow] = useState(false)
  let [studId, setStudyId] = useState('');
  let [patientId, setPatientId] = useState('');
  let [siteId, setSiteId] = useState('');
  let hoverElement = null;
  useEffect(() => {
    const data = props.data.map(v => ({ ...v, permission: true }))
    if (localStorage.getItem("siterole") === "Business User") {
      var newdata = [...data]
      newdata.map((item, i) => {
        if (item.createdBy != localStorage.getItem('loginUserId')) {
          newdata[i]["permission"] = false
        }
      })
      setisMovePatients("1")
      setData(newdata)
    } else {
      setData(data)
    }
  }, [props.data])
  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    // props.history.push('/account/AddAccount');
    //console.log('under progress')
  }


  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = (event) => {
    event.preventDefault();
    if (valSearch.length) {
      props.searchfunc("On-Study", valSearch);
      setoffset(0)
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    updateApiCall();
    setValSearch("")
  }


  const Columns = [
    {
      title: 'Subject ID', field: 'patientId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: '20%'
      },
      render: data => data.patientId == "" || data.patientId == null ? '---' : data.patientId
    },
    {
      title: 'Name', field: 'firstName',
      render: data => data.firstName + ' ' + data.lastName
    },

    { title: "Email", field: 'email' },
    { title: 'Phone', field: 'phone' },
    { title: 'City', field: 'city' },
    { title: 'Country', field: 'country' },
  ]
  const hoverEnter = (event, rowData) => {

    hoverElement = event.currentTarget;
    hoverElement.classList.add('showPopover')
  }

  const hoverLeave = (event, rowData) => {
    //hoverElement = event.currentTarget;
    hoverElement.classList.remove('showPopover')
  }

  const tpopoverEnter = (event, rowData) => {

    //hoverElement = event.currentTarget;
    //hoverElement.classList.add('showPopover')
  }

  const tpopoverLeave = (event, rowData) => {
    //hoverElement = event.currentTarget;
    //hoverElement.classList.remove('showPopover')
  }



  const navigateRoute = async (e, rowData, type) => {
    e.stopPropagation();
    setProgress(true);
    let requestBody = `
    mutation
    {
      updateStudySitePatientOStatus(studyId: "${props.formValues.autostudy.studyId}", siteId: "${props.formValues.autosite.sponsorAccountId}",patientId:"${rowData.patientId}",onboardStatus:"${type}") {
        code
        type
        message
        attributes
      }
    }
          `;
    requestBody = JSON.stringify({ query: requestBody });
    let res = await genericApi('updateStudySitePatientOStatus', requestBody);
    setProgress(false);

    if (res && res.data.updateStudySitePatientOStatus.code === '200') {
      //console.log('added');
      updateApiCall()
    } else {
      //console.log('Error on response on study!')
    }
  }
  const updateApiCall = () => {
    props.callApi()
  }
  const handleCalendarClick = async (event, rowData) => {
    event.stopPropagation();
    //console.log(rowData,"cale")
    await props.UpdateFormField({ name: "patientdata", value: rowData })
    // setShow(true)
    props.history.push('/site/patientdosage')
  }
  const handleRowClick=(event,data)=>{
    event.stopPropagation();
    props.UpdateFormField({ name: "patientdata", value: data })
    props.history.push('/site/patient');
  }
  const handleIconClick=(event)=>{
    event.stopPropagation();
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}



      <div className="d-flex mt-4 justify-content-end">
        <div class="secondary-header mr-auto">On-Study</div>
        {/* <div className="">
          <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
            <i className="fa fa-plus-circle mr-1"></i> ADD PATIENT</button>
        </div> */}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
            <div className="col-xs-12 col-sm-12 col-md-5">
              <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                <HekmaTextSearchField
                  name="accountgridsearch"// this name you need not to add in constant file unless if we use redux
                  // label="Search Account"
                  placeholder="Search Subjects"
                  value={valSearch}
                  handleChange={searchHandle}
                  className="gridSearchField"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                        {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                        X
                        {/* <i className="fa fa-times" aria-hidden="true"></i> */}
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div>
            <div className="listing-wrap hekmaGrid">
              <div className="card">
                <div className="mb-3">
                  <MaterialTable
                    style={{ boxShadow: "none" }}
                    columns={Columns}
                    data={data}
                    // data={data.slice(offset, appConfig.paginationLimit+ offset)}
                    onRowClick={(event, rowData) => {
                      event.preventDefault();
                      handleRowClick(event, rowData);
                    }}
                    actions={[
                      rowData => ({
                        icon: 'delete',
                        tooltip: 'Delete User',
                        onClick: (event, rowData) => Window.confirm("You want to delete "),
                      })
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      searchFieldAlignment: 'left',
                      showTitle: props.showTitle || false,
                      paging: props.data.length > 5 ? true : false,
                      sorting: props.data.length > 2 ? true : false,
                      paginationType: "stepped",
                      headerStyle: {
                        textAlign: 'left',
                        flexDirection: 'row'
                      },
                      cellStyle: {
                        textAlign: 'left',
                        flexDirection: 'row'
                      },
                      padding: 'dense',
                      draggable: false,
                      search: false,
                    }}

                    localization={{
                      header: {
                        actions: 'Actions'
                      }
                    }}
                    components={{
                      Action: props => (
                        <>
                          {(isMovePatients === "1" && props.data.permission) ?
                            <div
                              className="fullwidth text-center posrelative"
                              onMouseEnter={(event) => hoverEnter(event, props.data)}
                              onMouseLeave={(event) => hoverLeave(event, props.data)}
                              onClick={(event) =>handleIconClick(event)}
                            >
                              <img src={Move_Icon} alt="Move_to" />
                              <div className="tpopover"
                                onMouseEnter={(event) => tpopoverEnter(event, props.data)}
                                onMouseLeave={(event) => tpopoverLeave(event, props.data)}
                              >
                                <p>MOVE TO</p>
                                <p onClick={(e) => navigateRoute(e, props.data, 'Pre-Screening')}>Pre-Screening</p>
                                <p onClick={(e) => navigateRoute(e, props.data, 'Screening')}>Screening</p>
                              </div>
                            </div> : ""}
                          {/* <i className="fa fa-calendar mr-1" onClick={(e) => { handleRowClick(e, props.data) }} style={{ cursor: "pointer", color: "#22416E", fontSize: "19px" }}></i> */}
                          <img src={Calendar} alt="Calendar" onClick={(e) => { handleCalendarClick(e, props.data) }} height="50%" width="34%" style={{ cursor: "pointer", fontSize: "17px" }} />
                        </>
                      ),
                    }}
                  />
                  {/* {props.data.length > appConfig.paginationLimit &&
                    <Paginationn
                      handlePageClick={handlePageClick}
                      data={props.data.length}
                      offset={offset}
                    />
                  } */}
                </div>
              </div>
            </div>
          </div>
          {
            show &&
            <Calender />
          }
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Enroll));



