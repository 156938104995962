import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

function DateDetail(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}
            <div className="dateDetail d-flex align-items-center">
                <div className="dateColumn">
                    <section className="year">{props.year}</section>
                    <section className="date">{props.mdate}</section>
                </div>
                <div className="iconcontainer">
                    <span className="roundedIcon roundedIcon50">
                        <i className={props.icon} style={{color:props.icolor}}></i>
                    </span>
                    <span className="dash"></span>
                    <span className="vdash"></span>
                </div>
            </div>

        </>
    )

}

export default DateDetail;


