import React from 'react'
import PIRTextaria from '../pir/pirtextaria';
import Title from '../pir/Title';
import InclusionIcon from '../../../../img/Inclusion.svg'
import ExclusionIcon from '../../../../img/Exclusion.svg'

const Criteria = (props) => {
  console.log(props.data, "incexl")
  const deleteHandler=(e)=>{
    e.preventDefault()
  }
  return (
    <div className="dashboardContainer mb-3">
      <div className="pircontent" id="pirleftcontent">
        <section className="d-flex justify-content-between">
          <div className="iconPlace">
            <img src={InclusionIcon} />&nbsp;
            <Title title="Inclusion Criteria" />
          </div>
        </section>

        <section className="searchCriteriaPanel mt-3" style={{ maxHeight: "10rem", minHeight: "10rem" }}>
          <PIRTextaria
            textvalue={props.data != (undefined || null || '') ? props.data.inclusion : ["No Data Found"]}
            name="inclusion"
          // clickHandler={edit}
          hideDelete={true}
          deleteHandler={deleteHandler}
          />
        </section>


        <section className="d-flex justify-content-between mt-4">
          <div className="iconPlace">
            <img src={ExclusionIcon} />&nbsp;
            <Title title="Exclusion Criteria" />
          </div>
        </section>

        <section className="searchCriteriaPanel mt-3" style={{ maxHeight: "10rem", minHeight: "10rem" }}>
          <PIRTextaria
            textvalue={ props.data != (undefined || null || '') ? props.data.exclusion : ["No Data Found"] }
            name="exclusion"
          // clickHandler={edit}
          hideDelete={true}
          deleteHandler={deleteHandler}
          />
        </section>
      </div>
    </div>
  )
}

export default Criteria