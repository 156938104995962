// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appConfig } from '../../../common/constants';
import matchSorter from 'match-sorter';
import SearchIcon from '@material-ui/icons/Search';
import BgImg from '../../../img/BG2.jpg'
import $ from 'jquery'
import NCTIDs from '../../../globalcomponents/nctidDropdowns'

const Quicktrial = (propsobj) => {
  const [data, setData] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [nctid, setNctId] = React.useState({ label: "", value: "", info: "" })
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(true)
    var sponsor = "biogen"
    let hostName = window.location.hostname;

    if (hostName == appConfig.host1) {
      sponsor = "biogen"
    }
    if (hostName == appConfig.host2) {
      sponsor = "Pfizer"
    }
    // + '&from=100'
    (async () => {
      fetch(appConfig.marketplaceBaseApi + '?sponsor=' + sponsor+'&pir=Y',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(res => {
          if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed!');
          }
          return res.json();
        })
        .then(responseData => {
          let nctids = responseData.data.map((item, i) => {
            let obj = {};
            obj['value'] = item.FullStudy.Study.ProtocolSection.IdentificationModule.NCTId;
            obj['label'] = item.FullStudy.Study.ProtocolSection.IdentificationModule.NCTId;
            // obj['info'] = item.FullStudy.Study.ProtocolSection.EligibilityModule;
            // obj['pir'] = item.FullStudy?.PIR;
            // obj['Inclusion'] = item.FullStudy?.RefinedCriteria?.inclusion
            // obj['Exclusion'] = item.FullStudy?.RefinedCriteria?.exclusion
            return obj;
          })
          setLoading(false)
          setOptions(nctids)
          setData(responseData.data)
        })
    })();

  }, []);

  const autoHandleChange = async (event, newValue) => {
    event.preventDefault();
    console.log(newValue, "valu sele")
   
    setLoading(true)
    fetch(appConfig.marketplaceBaseApi + '?id='+newValue.value,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(res => {
      if (res.status !== 200 && res.status !== 201) {
        throw new Error('Failed!');
      }
      return res.json();
    })
    .then(responseData => {
      // let nctids = responseData.data.map((item, i) => {
        let obj = {};
        obj['value'] = responseData.data[0].FullStudy.Study.ProtocolSection.IdentificationModule.NCTId;
        obj['label'] = responseData.data[0].FullStudy.Study.ProtocolSection.IdentificationModule.NCTId;
        obj['info'] = responseData.data[0].FullStudy.Study.ProtocolSection.EligibilityModule;
        obj['pir'] = responseData.data[0].FullStudy?.PIR;
        obj['Inclusion'] = responseData.data[0].FullStudy?.RefinedCriteria?.inclusion
        obj['Exclusion'] = responseData.data[0].FullStudy?.RefinedCriteria?.exclusion
      //   return obj;
      // })
      console.log(obj)
      setLoading(false)
      setNctId(obj)
    })

  }
  const openPir = () => {
    propsobj.UpdateFormField({ name: "Inclusion", value: [] })
    propsobj.UpdateFormField({ name: "Exclusion", value: [] })
    propsobj.UpdateFormField({ name: "PIRdata", value: "" })
    propsobj.UpdateFormField({ name: "openSqNCTid", value: "" })
    propsobj.UpdateFormField({name:"advancedsearch",value:true})
    propsobj.history.push('/account/pir')
  }
  const onSubmit = (e) => {
    e.preventDefault()
    if (nctid.value.length > 0) {
      if (nctid.pir) {
        propsobj.UpdateFormField({ name: "Inclusion", value: nctid.Inclusion })
        propsobj.UpdateFormField({ name: "Exclusion", value: nctid.Exclusion })
        propsobj.UpdateFormField({ name: "PIRdata", value: nctid.pir })
      } else {
        var str = nctid.info.EligibilityCriteria
        if (str.length > 0) {
          var data = str.replace(/\n\n/g, '')
          var newdata = data.replace(/\n/g, '?')
          var inc
          var exl
          if (str.includes("Inclusion Criteria:") && str.includes("Exclusion Criteria")) {
            inc = newdata.substring(
              newdata.indexOf("Inclusion Criteria:") + 19,
              newdata.lastIndexOf("Exclusion Criteria:")
            );

            exl = newdata.substring(
              newdata.indexOf("Exclusion Criteria:") + 19
            );
          } else if (str.includes("Inclusion Criteria:") && !str.includes("Exclusion Criteria")) {
            inc = newdata.substring(
              newdata.indexOf("Inclusion Criteria:") + 19,
            );

            exl = ''
          } else if (!str.includes("Inclusion Criteria:") && str.includes("Exclusion Criteria")) {
            inc = ''
            exl = newdata.substring(
              newdata.indexOf("Exclusion Criteria:") + 19
            );
          } else {
            inc = str
            exl = ''
          }
          var inclusion = []
          var exclusion = []
          if (inc.length > 0) {
            inclusion = inc.split("?")
          }
          if (exl.length > 0) {
            exclusion = exl.split("?")
          }
          delete nctid.info.EligibilityCriteria
           // var keys = Object.keys(nctid.info)
          // for (var i = 0; i < keys.length; i++) {
          //   if (typeof nctid.info[keys[i]] === 'string') {
          //     console.log(nctid.info[keys[i]], keys[i])
          //     inclusion.push(keys[i] + ' ' + nctid.info[keys[i]])
          //   }
          // }
        }
        propsobj.UpdateFormField({ name: "Inclusion", value: inclusion })
        propsobj.UpdateFormField({ name: "Exclusion", value: exclusion })
        propsobj.UpdateFormField({ name: "PIRdata", value: "" })
        propsobj.UpdateFormField({ name: "openSqNCTid", value: nctid.value })
      }
      propsobj.UpdateFormField({ name: "openSqNCTid", value: nctid.value })
      propsobj.UpdateFormField({name:"advancedsearch",value:false})
      propsobj.history.push('/account/pir')
    }
  }
  return (
    <>
      <NCTIDs mainHeader="Clinical Open Square" subHeader="Connect Patients to Cure" onSubmit={onSubmit} options={options} value={nctid} placeholder="Enter clinical Trial ID (Eg: NCT00000XXX)" openPir={openPir} autoHandleChange={autoHandleChange} loading={loading} subtext="Search by I/E Criteria"/>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Quicktrial));