import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../common/loader';
import Dashboard from '../marketplace/dashboard';
import FunnelOrder from '../../../globalcomponents/funnelorderpage';
import { genericApi } from '../../../common/apiconfig';

const OrderDetails = (props) => {
    let reduxdispatch=useDispatch()
    let [data, setData] = useState("")
    let [totalPrice, setTotalPrice] = useState("")
    let [progress, setProgress] = useState(false)
    let reduxstateobj = useSelector(state => state);
    //for mrtrics and dashboard
    let [header, setHeader] = useState("")
    let [show, setShow] = useState(false)
    let [dashboardData, setDashboardData] = useState("")
    let [insightsData, setInsightsData] = useState("")
    let [showFunnel, setShowFunnel] = useState(false)
    let [order, setOrder] = useState("*")
    const [funnelData, setFunnelData] = useState("")
    const [funnelCount, setFunnelCount] = useState("")

    useEffect(() => {
        setData(reduxstateobj.form.orderdetails)
        var totalprice = reduxstateobj.form.orderdetails.orderDetails.reduce(function (total, currentValue) {
            return total + parseInt(currentValue.totalPrice);
        }, 0)
        setTotalPrice(totalprice)      
    }, [])
    const goBack = (event) => {
        event.preventDefault();
        props.history.goBack();
    }

    const showDashboard = (e, rowData) => {
        e.preventDefault();
        var data = { ...rowData, studyId: rowData.nctId }
        setDashboardData(data)
        setShow(true)
        // setOpenModal(true)
        setHeader("Metrics")
    }

    const showInsights = async (e, rowData) => {
        e.preventDefault();
        var data = { ...rowData, studyId: rowData.nctId }
        callFunnelApi(data, "*")
        // setOpenModal(true)
        setHeader("Insights")
    }

    const callFunnelApi = async (rowData, order) => {
        let requestBody = {
            query: `query{
                getComposeOrderFunnelDetails(orderId:"${reduxstateobj.form.orderdetails.orderId}", siteId:"${rowData.siteId}", order:"${order}")
              {
                   nctId
                    criteriaOrder
                    criteriaText
                    criteriaBaseOrder
                    ieType
                    criteriaIndex
                    funnelCount
                    matchCount
                    totalPatientCount
              }
            }`
        }
        requestBody = JSON.stringify(requestBody);
        setProgress(true);
        let res = await genericApi('getComposeOrderFunnelDetails', requestBody);
        setProgress(false);
        if (res) {
            if (res.data.getComposeOrderFunnelDetails.length > 0) {
                var data = res.data.getComposeOrderFunnelDetails
                var data1 = data.map((item, i) => { return [item.criteriaText + '(<b>' + item.ieType.toUpperCase() + '</b>)', parseInt(item.funnelCount), "#ff0000", item.criteriaBaseOrder, item.ieType.toUpperCase()] })
                // data1.unshift(['<b>Total Patients</b>', parseInt(data[0].totalPatientCount), "#ff0000", "0"])
                setFunnelData(data1)
                var count = data.reduce((acc, cv) => {
                    return acc + parseInt(cv.funnelCount);
                }, 0)
                setFunnelCount(data[0].totalPatientCount)
                setInsightsData(rowData)
                setShowFunnel(true)
            }else{
                alert('No Data found')
            }
        }
    }
    const handleSubmitFunnelOrder = (e, orderdata) => {
        var order = orderdata.map((item) => { return item[3] })
        // order.shift()
        setOrder(order.toString())
        callFunnelApi(insightsData, order)
        setFunnelData("")
        setFunnelCount("")
    }
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    return (
        <>
                       
                {/* <SimpleModal header={header} open={openModal} onClose={onClose} update={false} width={"800px"}> */}
                <Loader progress={progress} />

                {/* </SimpleModal> */}
                {(show || showFunnel) ? "" : <div className="row"><div className="col-md-8 mb-2 justify-content-end" >
                    <h1 className="mr-auto primary-header" style={{ float: "left" }}>Order ID: {reduxstateobj.form.orderdetails.orderDisplayId}</h1>
                    <button className="btn btn-small btn-primary-blue btn-shadow btn-rounded" onClick={goBack} style={{ float: "right", marginTop: "5px" }}>
                        BACK</button>
                </div>
                    <div className="panel-primary-wrap col-md-8">
                        <div className="card">
                            <div className="card-body ">
                                <div className="card-page-header">ORDER INFORMATION {" "}</div>

                                {data && data.orderDetails.map((item, i) => (
                                    <div className="inputBox row mt-2" style={{ borderBottom: data.orderDetails.length > 1 ? "2px solid #EAEAEA" : "" }}>
                                        <>
                                            <div className='col-md-3'>{item.siteName}</div>
                                            <div className='col-md-6'>
                                                <div className='row'>
                                                    <div className='col-md-4 orderText'>Order Date</div>
                                                    <div className='col-md-8'>: {data.orderDate}</div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-4 orderText'>NCT ID/Study ID</div>
                                                    <div className='col-md-8'>: {data.nctId}</div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-4 orderText'>Country</div>
                                                    <div className='col-md-8'>: {item.country}</div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-4 orderText'>City</div>
                                                    <div className='col-md-8'>: {item.city}</div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-4 orderText'>Patient Count</div>
                                                    <div className='col-md-8'>: {item.patientCount}</div>
                                                </div>

                                            </div>
                                            <div className='col-md-3'>
                                                <div className='row'>
                                                    <div className='col-md-6 orderText'>Metrics</div>
                                                    <div className='col-md-6'>
                                                        : {" "}
                                                     <i className="fa fa-solid fa-chart-line" style={{ color: "#23416F", paddingLeft: "6px" }} alt="Dashboard"
                                                        onClick={(e) => showDashboard(e, item)}
                                                    /></div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6 orderText'>Insights</div>
                                                    <div className='col-md-6'>
                                                        :{" "}
                                                         <i className="fa fa-solid fa-filter" style={{ color: "#23416F", paddingLeft: "6px" }} alt="Insights"
                                                        onClick={(e) => showInsights(e, item)}
                                                    /></div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'><b>Price($)</b></div>
                                                    <div className='col-md-6'>
                                                        :{" "}
                                                        <b>{numberWithCommas(parseInt(item.totalPrice))}</b></div>
                                                </div>

                                            </div>
                                        </>
                                    </div>
                                ))}
               <div>
                    <span style={{ fontWeight:'bolder',float: "right", marginTop: "5%" }}>
                      Total Price($) : {numberWithCommas(parseInt(totalPrice))}
                   </span>
                  </div>


                            </div>
                        </div>
                    </div>
                    </div>
                }
                <section className="problemDetailTable mt-2" >
                    {show &&
                        <div>
                            <div className="row mt-0">
                                <div className="col-md-12 mb-2 justify-content-end" >
                                    <h1 className="mr-auto primary-header" style={{ float: "left" }}>{"Patients Count:" + dashboardData.patientCount}</h1>

                                    <button className="btn btn-small btn-primary-blue btn-shadow btn-rounded" onClick={() => setShow(false)} style={{ float: "right", marginTop: "5px" }}>
                                        BACK</button>
                                </div>
                            </div>
                            <Dashboard data={dashboardData} app="Site" info="Sponsor Order"/>
                        </div>
                    }
                    {showFunnel && <>
                        <FunnelOrder funnelCount={funnelCount} progress={progress} funnelData={funnelData} handleSubmitFunnelOrder={handleSubmitFunnelOrder} setShowFunnel={setShowFunnel} setOrder={setOrder} setInsightsData={setInsightsData} />
                    </>}
                </section>
            
        </>
    )
}
export default OrderDetails