import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import EntityMutation from './entitymutations';
import { connect, useSelector, useDispatch } from 'react-redux';
import { ddlist } from '../../../../common/constants';
import Popper from '@material-ui/core/Popper';
import uniqid from 'uniqid';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  paper: {
    marginTop: '3px',
    padding: theme.spacing(.5),
    backgroundColor: theme.palette.background.paper,
    fontSize: '12px',
    borderRadius: '4px',
    boxShadow: '1px 1px 2px 0px #ff0000'
  },
}));

export default function EntityChip(props) {
  let [open, setOpen] = useState(false)
  let [modaldata, setModalData] = useState('')
  let [chipData, setChipData] = useState(props);
  let [chipItem, setChipItem] = useState('');
  let [entityValue, setentityValue] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);

  const dialogclose = (flag, propObj) => {
    if (propObj) {
      //props = { ...propObj };
      //setChipData({ ...chipData, ...propObj })
      //let newData = { ...chipData, ...propObj };
      ////console.log('newdatas at dialog::',newData);
      //props.updateEntityData(newData);
    }
    setOpen(flag);
  }



  const classes = useStyles();

  const handleDelete = (item) => {
    props.updateEntityDataByEdit(item, '', '', '', true);
  };

  const handleClick = (item) => {
    console.info('Entity.', item);
    // setChipItem(item)
    // setOpen(true);
    // setModalData(item)
  };

  const getValue = (item) => {
    if (item.values) {
      if (item.values.length) {
        try {
          return item.values.join(',')
        } catch (error) {
          return item.values
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  const handleHover = (e, item) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    setentityValue(getValue(item))
  }
  const handleLeave = (e, item) => {
    setAnchorEl(null)
    setentityValue([])
  }

  useEffect(() => {
    setChipData({ ...chipData, ...props })
  }, [props])

  const popopen = Boolean(anchorEl);
  const id = popopen ? uniqid() : undefined;


  return (
    <>
      <div className={classes.root} id={id} data-chipdata={JSON.stringify(chipData)} >
        <div
          aria-describedby={id}
          className={chipData.cssClass}
          onMouseEnter={(e) => handleHover(e, chipData)}
          onMouseLeave={(e) => handleLeave(e, chipData)}
        >
          <Chip
            label={chipData.display_term}
            onDelete={() => handleDelete(chipData)}
            onClick={() => handleClick(chipData)}
          />

        </div>

        {entityValue.length > 0 && <Popper id={id} open={popopen} anchorEl={anchorEl}>
          <div className={classes.paper}>
            <div className="hoverEl">
              Value: <span>{entityValue}</span>
            </div>
          </div>
        </Popper>
        }

      </div>


      {open && <EntityMutation
        dialogclose={dialogclose}
        title={'Edit Entity Type'}
        {...props}
        chipData={chipData}
        ddlist={ddlist}
        updateEntityData={props.updateEntityData}
        updateEntityDataByEdit={props.updateEntityDataByEdit}
        open={open}
        chipItem={chipItem} >
      </EntityMutation>}

    </>
  );
}
