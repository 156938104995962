import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavSubMenu } from '../../../navMenu';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import Details from './details'
import Users from './users'
import Queries from './queries'
import Tasks from './tasks'
import Emails from './emails'
import Notes from './notes'
import Activity from './activity'
import Subjects from './subjects'
import AdverseEvents from './adverseEvents'
import tasks from "./tasks";
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core'
import BackIcon from '../../../../../img/back1.svg'
import AddUsers from './addUsers'
import ViewContact from '../../viewContactDetail';
import UpdateContact from '../../updateContact'
// import AddQuery from './addQuery'


const menus = [
  {
    to: "/account/studyDetails/site/siteDetails",
    text: "Site Details"
  },
  {
    to: "/account/studyDetails/site/siteDetails/users",
    text: "Users"
  },
  {
    to: "/account/studyDetails/site/siteDetails/subjects",
    text: "Subjects"
  },
  {
    to: "/account/studyDetails/site/siteDetails/adverseEvents",
    text: "Adverse Events"
  },
  {
    to: "/account/studyDetails/site/siteDetails/queries",
    text: "Queries"
  },
  {
    to: "/account/studyDetails/site/siteDetails/tasks",
    text: "Tasks"
  },
  {
    to: "/account/studyDetails/site/siteDetails/emails",
    text: "Emails"
  },
  {
    to: "/account/studyDetails/site/siteDetails/notes",
    text: "Notes"
  },
  {
    to: "/account/studyDetails/site/siteDetails/activity",
    text: "Activity"
  },

]

const SiteDetails = (props) => {

  const { siteName } = props.formValues;
  const goBack = (event) => {
    event.preventDefault();
    props.history.push('/account/studyDetails/site')
  }
  return (
    <>
      {/* <div> */}
        {/* <a href="/account/studyDetails/IEcriteria"><Icon>navigate_before</Icon>BACK TO SITE LIST</a> */}
        {/* <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO SITE LIST</div><br />
      </div>
      <div className="d-flex mb-3 justify-content-end">
        {siteName &&
          <div class="secondary-header mr-auto">{siteName}</div>
        }
      </div> */}

      <NavSubMenu menus={menus} />
      {/* <br /> */}
      <div className="mt-4">
        <Route exact path="/account/studyDetails/site/siteDetails" component={Details} />
        <Route exact path="/account/studyDetails/site/siteDetails/users" component={Users} />
        <Route path="/account/studyDetails/site/siteDetails/users/view" component={ViewContact} />
        <Route path="/account/studyDetails/site/siteDetails/users/update" component={UpdateContact} />
        <Route path="/account/studyDetails/site/siteDetails/queries" component={Queries} />
        <Route path="/account/studyDetails/site/siteDetails/tasks" component={Tasks} />
        <Route path="/account/studyDetails/site/siteDetails/emails" component={Emails} />
        <Route path="/account/studyDetails/site/siteDetails/notes" component={Notes} />
        <Route path="/account/studyDetails/site/siteDetails/activity" component={Activity} />
        <Route path="/account/studyDetails/site/siteDetails/subjects" component={Subjects} />
        <Route path="/account/studyDetails/site/siteDetails/adverseEvents" component={AdverseEvents} />
        <Route path="/account/studyDetails/site/siteDetails/addUsers" component={AddUsers} />
        {/* <Route path="/account/studyDetails/site/siteDetails/addQuery" component={AddQuery} /> */}

      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteDetails));




