import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dashboard from './dashboard';
import { connect } from 'react-redux';
import { oldgenericApi } from '../../common/apiconfig';
import { appConfig } from "../../common/constants";
import { withRouter } from 'react-router-dom';
import IN from '../../img/dashboard_icons/Icon1.png'
import OB from '../../img/dashboard_icons/Icon2.png'
import EA from '../../img/dashboard_icons/Icon3.png'
import EmailInfo from '../../img/opensquare/email info.svg'
import Connect from '../../img/opensquare/connect.svg'
import Progress from '../../img/Progress.jpg'
import Slider from '../slider/slider';
import Tooltip from '@material-ui/core/Tooltip';
import { CustomizedProgressBars } from './roundedProgress';
import SimpleModal from '../simplemodal'
import Materialtable from '../materialtable'
import Loader from '../../common/loader'

function ProfileData(props) {
  const { app, data } = props
  let [progress, setProgress] = useState(false);
  let [studydata, setStudyData] = useState([])
  let [pendingData, setPendingData] = useState('')
  let [lfmatch, setLfmatch] = useState('')
  let [gfmatch, setGfmatch] = useState('')
  let [gsmatch, setGsmatch] = useState('')
  let [showTrailMatchData, setShowTrailMatchData] = useState(false)
  let [trailHeader, setTrailHeader] = useState('')
  let [popupdata, setPopupdata] = useState([])
  let [localPatientId, setLocalPatientId] = useState("")
  let [IEData, setIEData] = useState([])
  let [IEPopup, setIEPopup] = useState(false)
  useEffect(() => {
    if (data?.patient360 === "Y") {
      callTrailmatch(data.ehrPatientId)
      setLocalPatientId(data.ehrPatientId)
    }else if(data.subjectId && data.subjectId!= "null" && data.subjectId != null && data.subjectId != undefined){
      callTrailmatch(data.subjectId)
      setLocalPatientId(data.subjectId)
      callGetStudyProgress()
    } else if (data.patientId != "" || data.patientId != null || data.patientId != undefined) {
      callGetStudyProgress()
      if (data.patientId == "" && (data.subjectId != "null" && data.subjectId != null && data.subjectId != undefined)) {
        callTrailmatch(data.subjectId)
        setLocalPatientId(data.subjectId)
      } else {
        callTrailmatch(data.patientId)
        setLocalPatientId(data.patientId)
      }
    } else {
      callTrailmatch(data.subjectId)
      setLocalPatientId(data.subjectId)
    }
  }, [])
  const callGetStudyProgress = async () => {
    let requestBody = {
      query: `query {        
         getStudyProgress(patientId:"${data.patientId}") {
        studyID
        accountID
        studyNumber
        siteID
        scheduleID
        studyTitle
        totalVisit
        actualVisits
        percentageCompletion
        studyProgressVisits {
            patSchedulePk
            startDate
            endDate
            actualDate
        }
      }
   }`
    };
    requestBody = JSON.stringify(requestBody);
    let res = await oldgenericApi('getStudyProgress', requestBody);
    if (!res) {
      // Error handling
      //console.log('Error on getting patient data!')

    } else {
      if (res.data && res.data.getStudyProgress && res.data.getStudyProgress.length > 0) {
        setStudyData(res.data.getStudyProgress)
        props.UpdateFormField({ name: "studyprogressdata", value: res.data.getStudyProgress })
        var dataa = res.data.getStudyProgress[0].studyProgressVisits
        var dat = dataa.filter((item, i) => item.actualDate == null)
        setPendingData(dat)
      } else {
        setStudyData([])
      }
    }
  }

  const callTrailmatch = async (patientid) => {
    await fetch(appConfig.BaseUrl + 'compose?patientId=' + patientid,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {
        if (responseData && responseData.length > 0) {
          var lfmatch = responseData.filter(i => (parseInt(i.percentage_match) >= 50 && parseInt(i.percentage_match) <= 80))
          var gfmatch = responseData.filter(i => (parseInt(i.percentage_match) > 80 && parseInt(i.percentage_match) <= 99))
          var gsmatch = responseData.filter(i => parseInt(i.percentage_match) == 100)
          setLfmatch(lfmatch)
          setGfmatch(gfmatch)
          setGsmatch(gsmatch)
        } else {
          setLfmatch([])
          setGfmatch([])
          setGsmatch([])
        }
      })
  }

  const openPopup = (e, data, header) => {
    e.preventDefault()
    if (data.length > 0) {
      setShowTrailMatchData(true)
      setTrailHeader(header)
      setPopupdata(data)
    }
  }

  const onClose = () => {
    setShowTrailMatchData(false)
  }
  const handleRowClick = async (event, rowData) => { event.preventDefault() }
  const Columns = [
    { title: 'NCT ID', field: 'nct_id', render: rowData => <div onClick={(e) => { e.preventDefault(); window.open(`https://classic.clinicaltrials.gov/ct2/show/${rowData.nct_id}`, '_blank') }} style={{    color: '#244271', cursor: "pointer" }}>{rowData.nct_id}</div> },
    { title: '% Match', field: 'percentage_match', render: rowData => <div onClick={(e) => getCriteria(e, rowData.nct_id)} style={{    color: '#244271', cursor: "pointer",paddingLeft:"22%" }}>{rowData.percentage_match} </div> },
    {
      title: "Actions", field: '', cellStyle: {
        paddingLeft: "20px"
      }, render: rowData =><>
      {/* <Tooltip title="Click for more info" arrow><i className="fa fa-solid fa-info" style={{ color: "#23416F" }} alt="Insights" onClick={(e) => alert('mail sent')} /></Tooltip> */}
      <Tooltip title="Click for more info" arrow><img src={EmailInfo} alt="Mail Info" onClick={(e) => alert('mail sent')} height="20px"/></Tooltip> &nbsp;&nbsp;
      {/* <i className="fa fa-solid fa-info" style={{ color: "#23416F",paddingTop:"7px" }} alt="Insights" onClick={(e) => alert('mail sent')} /> */}
      {/* <button type="button" onClick={(e) => alert('mail sent')} className="btn btn-primary-blue btn-rounded btn-small fr mb-2" >More Info</button> */}
      {/* <button type="button" onClick={(e) => alert('check mail')} className="btn btn-primary-blue btn-rounded btn-small fr" >SignUp</button></>  */}
      <Tooltip title="Connect" arrow><img src={Connect} alt="Mail Info" onClick={(e) => alert('check mail')} height="15px"/></Tooltip></>
    }]

  const getCriteria = async (e, id) => {
    setProgress(true)
    let requestBody = {
      query: `query {
          getComposePredictionPatient(nctId:"${id}",patientId:"${localPatientId}")
          {
               claim_id
                nct_id
                criteria_text
                cri_base_index
                ie
                model_pred
        
          }
    }`
    }
    requestBody = JSON.stringify(requestBody);
    // setProgress(true);
    let res1 = await oldgenericApi('getComposePredictionPatient', requestBody);
    setProgress(false);
    if (res1) {
      if (res1.data.getComposePredictionPatient.length > 0) {
        var responseData = res1.data.getComposePredictionPatient
        setIEData(responseData)
        setIEPopup(true)
      }
      else {
        setIEData([])
      }
    }
  }

  return (
    <>
      <Loader progress={progress} />
      <div className="mt-3">

        <SimpleModal header={trailHeader} open={showTrailMatchData} onClose={onClose} update={false} width="450px">
          <Loader progress={progress} />
          <section className="problemDetailTable mt-2">
            <div className="col-md-12">
              <Materialtable
                columns={Columns}
                search={true}
                data={popupdata.length > 0 ? popupdata : []}
                handleRowClick={handleRowClick}
              />
              {/* <table className="viewCourseLinks mhDashboard">
              <thead>
                <tr>
                  <th>NCT ID</th>
                  <th>Percentage Match</th>
                </tr>
              </thead>
              <tbody>
                {popupdata.map((item, i) => (
                  <tr>
                    <td>
                      {item.nct_id}
                    </td>
                    <td>
                      {item.percentage_match}
                    </td>
                  </tr>

                ))
                }
              </tbody>
            </table> */}
            </div>
          </section>
        </SimpleModal>
        <SimpleModal header="Criteria" open={IEPopup} onClose={(e) => { setIEPopup(false); setIEData([]) }} update={false} width="600px">

          <div className="pircontent m-3" id="pirleftcontent" style={{maxHeight:"450px",overflow:"auto"}}>

            <section className="d-flex justify-content-between mb-3">
            <div className="inputBox table-responsive-md" >

<table className="viewCourseLinks table">
  <thead>
    <tr>
      <th rowSpan="2" className="eventText">Inclusion Criteria</th>
      <th className="eventText">Matched</th>
    </tr>
  </thead>
  <tbody>
  {
       IEData && IEData.filter(obj=>obj.ie==="i").length>0 ? IEData.filter(obj=>obj.ie==="i").map((item, i) => {
        return (
          <tr
            key={i}
            className="hover-change"
            id={i + "row"}
          >
            <td>{item.criteria_text}</td>
            <td style={{textAlign:"center"}}>{ item.model_pred === "1" ? <i className="fa fa-check" aria-hidden="true" style={{color:"green"}}></i> : <i className="fa fa-times" aria-hidden="true" style={{color:"red"}}></i>}</td>
           
          </tr> 
        )
      }): <tr><td colSpan={2} style={{textAlign:"center"}}>{"No Data Found"}</td></tr>
    }
  </tbody>
</table>
<table className="viewCourseLinks table">
  <thead>
    <tr>
      <th rowSpan="2" className="eventText">Exclusion Criteria</th>
      <th className="eventText">Matched</th>
    </tr>
  </thead>
  <tbody>
    {
       IEData && IEData.filter(obj=>obj.ie==="e").length>0 ? IEData.filter(obj=>obj.ie==="e").map((item, i) => {
        return (
          <tr
            key={i}
            className="hover-change"
            id={i + "row"}
          >
            <td>{item.criteria_text}</td>
            <td style={{textAlign:"center"}}>{ item.model_pred === "1" ? <i className="fa fa-check" aria-hidden="true" style={{color:"green"}}></i> : <i className="fa fa-times" aria-hidden="true" style={{color:"red"}}></i>}</td>
           
          </tr> 
        )
      }): <tr><td colSpan={2} style={{textAlign:"center"}}>{"No Data Found"}</td></tr>
    }
  </tbody>
</table>
</div>
              {/* <div className="iconPlace">
                <span className="pirtitle">Inclusion Criteria</span>
                <ol>
                  {
                    IEData && IEData.map((item, i) => {
                      console.log(item, "item")
                      if (item.ie === "i") {
                        return <li style={{color:item.model_pred === "1" ? "green" : "red",padding:"5px"}}>{item.criteria_text}
                        </li>
                      }
                    })
                  }
                </ol>
              </div> */}

            </section>
            {/* <section className="d-flex justify-content-between">
              <div className="iconPlace">
                <span className="pirtitle">Exclusion Criteria</span>
                <ol>
                  {
                    IEData && IEData.map((item, i) => {
                      console.log(item, "item")
                      if (item.ie === "e") {
                        return <li style={{color:item.model_pred === "1" ? "green" : "red",padding:"5px"}}>{item.criteria_text}
                        </li>
                      }

                    })
                  }
                </ol>
              </div>
            </section> */}
          </div>
        </SimpleModal>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 mb-4">
            <section className="card" style={{ borderRadius: "0.75rem" }}>
              <div className="card-body detailsblock" style={{ marginBottom: "0px" }}>
                <h6 style={{ color: "#203f6a" }}>Demographics</h6>
                <div className="row mb-3">

                  <div className="col-md-12">
                    <div className="row" style={{ marginBottom: "0.35rem" }}>
                      <div className="col-md-4 plabel pr-5">Name</div>
                      <div className="col-md-8">{data.firstName ? data.firstName + ' ' + data.lastName : data.patientFirstName + ' ' + data.patientLastName}</div>
                    </div>
                    <div className="row" style={{ marginBottom: "0.35rem" }}>
                      <div className="col-md-4 plabel pr-5">Patient Id</div>
                      <div className="col-md-8">{data.patientId}</div>
                    </div>
                    <div className="row" style={{ marginBottom: "0.35rem" }}>
                      <div className="col-md-4 plabel pr-5">Email</div>
                      <div className="col-md-8">{data.email ? data.email : data.patientEmail}</div>
                    </div>
                    <div className="row" style={{ marginBottom: "0.35rem" }}>
                      <div className="col-md-4 plabel pr-5">Phone</div>
                      <div className="col-md-8">{data.phone}</div>
                    </div>
                    <div className="row" style={{ marginBottom: "0.35rem" }}>
                      <div className="col-md-4 plabel pr-5">City & Country</div>
                      <div className="col-md-8">{data.city ? data.city : data.patientCity + ' ' + data.country ? data.country : data.patientCountry}</div>
                    </div>

                  </div>

                  {/* <div className="col-md-6 col-sm-12 mb-2">
                    <div className="row">
                      <div className="col-md-5 plabel pr-5">Name</div>
                      <div className="col-md-7 plr-5">{data.firstName ? data.firstName + ' ' + data.lastName : data.patientFirstName + ' ' + data.patientLastName}</div>
                    </div>

                    <div className="row">
                      <div className="col-md-5 plabel pr-5">Country</div>
                      <div className="col-md-7 plr-5">{data.country ? data.country : data.patientCountry}</div>
                    </div>

                  </div> */}


                  {/* <div className="col-md-6 col-sm-12 mb-2">
                    <div className="row">
                      <div className="col-md-4 plabel plr-5">Phone</div>
                      <div className="col-md-8 pl-5">{data.phone}</div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 plabel plr-5">City</div>
                      <div className="col-md-8 pl-5">{data.city ? data.city : data.patientCity}</div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="plabel pr-5 mb-2">Patient Id &nbsp;&nbsp;<span className="col-md-7 plr-5" style={{color:"black"}}>{data.patientId}</span></div>
                    <div className="plabel pr-5 mb-3">Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="plr-5" style={{color:"black",fontSize:"14px"}}>{data.email ? data.email : data.patientEmail}</span></div>
                  </div>*/}
                </div>
              </div>
            </section>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8 mb-4">
            <div className="card" style={{ borderRadius: "0.75rem", height: '100% ' }}>
              <div className="card-body detailsblock">
                <div className="row borderbottom">
                  <div className="col-md-6">
                    <div className="borderright inline">
                      <div className="trail"><b>Clinical Studies Matched</b></div>
                      <img src={IN} alt="img" style={{ marginLeft: "25px" }} />
                      <div className="traildata" style={{ marginLeft: "11px" }}> 100% Match<br /><div className="trailcount" style={{ cursor: gsmatch.length > 0 ? "pointer" : "", color: "#3ad531" }} onClick={e => openPopup(e, gsmatch, "100% Match")}>{gsmatch.length}</div></div>
                    </div>
                  </div>
                  <div className="col-md-3"> <div className="borderright inline"> <img src={OB} alt="img" />
                    <div className="traildata">(81 - 99)% Match<div className="trailcount" style={{ cursor: gfmatch.length > 0 ? "pointer" : "", color: "#ffae52" }} onClick={e => openPopup(e, gfmatch, "(81 - 99)% Match")}>{gfmatch.length}</div></div></div>
                  </div>
                  <div className="col-md-3"><div className="inline"> <img src={EA} alt="img" />
                    <div className="traildata">(50 - 80)% match<br /><div className="trailcount" style={{ cursor: lfmatch.length > 0 ? "pointer" : "", color: "#ff7376" }} onClick={e => openPopup(e, lfmatch, "(50 - 80)% Match")}>{lfmatch.length}</div></div></div></div>
                </div>
                <div className="row" style={{ marginTop: "8px" }}>
                  {studydata.length > 0 ? <>
                    <div className="col-md-5">
                      <div className="borderright inline">
                        <div className="trail"><b>Study Progress</b></div>
                        {/* <img src={Progress} alt="img" /> */}
                        {/* <CustomizedProgressBars size={50} value={(100 * (studydata[0].studyProgressVisits.length - pendingData.length)) / studydata[0].studyProgressVisits.length} color="#7F3BE6"
                          display={Math.round((100 * (studydata[0].studyProgressVisits.length - pendingData.length)) / studydata[0].studyProgressVisits.length) + '%'} /> */}
                        <div className="traildata"><span style={{ color: "#9a9a9a" }}>Ongoing study</span><br /><Tooltip title={studydata[0].studyTitle} arrow><div className="trailcount" style={{ fontSize: "14px" }}>{studydata[0].studyNumber}</div></Tooltip></div>
                      </div>
                    </div>
                    <div className="col-md-3"> <div className="borderright inline">
                      {/* <img src={Ongoing} alt="img" /> */}
                      <CustomizedProgressBars size={50} value={(100 * (studydata[0].studyProgressVisits.length - pendingData.length)) / studydata[0].studyProgressVisits.length} color="#318BE1"
                        display={Math.round((100 * (studydata[0].studyProgressVisits.length - pendingData.length)) / studydata[0].studyProgressVisits.length) + '%'} />
                      {/* response.data.filter(x => x.criticality === "CRITL" || "CRITU" || "CRITH").length */}
                      <div className="traildata"><span style={{ color: "#9a9a9a" }}>Progress</span><br /><div className="trailcount">{(studydata[0].studyProgressVisits.length - pendingData.length) + "/" + studydata[0].studyProgressVisits.length}</div></div></div>
                    </div>
                    <div className="col-md-4"><div className="">
                      <div className="traildata mb-1">Visits</div>
                      <Slider id={"d_study_progress"} data="">
                        {studydata[0].studyProgressVisits.map((item, i) => (
                          <>
                            <li>

                              <div className="numberdiv">
                                <p></p>
                                <Tooltip title={item.actualDate != null ? "Visited" : "Pending"} className={item.actualDate != null ? "Visited" : "Pending"} arrow>
                                  <span>{i + 1}</span>
                                </Tooltip>
                              </div>

                            </li>

                          </>
                        ))}
                      </Slider>
                    </div>
                    </div>
                  </>
                    : <div className="col-md-12"><b>Not assigned to any study yet</b></div>}
                </div>

              </div>
            </div>
          </div>
          {/* <NavMenuPatient menus={menus} /> */}
        </div>
        {typeof data === 'object' && data !== null &&
          <Dashboard data={data} app={app} />
        }
      </div>
    </>
  )

}


const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileData));
