import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../../formElements';
import { connect } from 'react-redux';
import { apiCall } from '../../../common/apiconfig';
import * as moment from 'moment'
import { checkPermission } from '../../../common/utils';

const ViewStudyDetails = (propsobj) => {
  let isEditStudy = checkPermission('Study_Details_Edit', 'Update');
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [studyId, setstudyId] = useState('');


  useEffect(() => {
    let studyid = '';
    console.log('propsobj.location::', propsobj.location)
    try {
      studyid = propsobj.location.state[0].id;
    } catch {
      studyid = propsobj.formValues.studyId;
    }
    // console.log('studyId::', studyid)
    setstudyId(studyid);
  })
  useEffect(() => {
    async function fetchData() {
      if (studyId != "") {
        let requestBody = {
          query: `
            query {
              getStudy(studyId: "${studyId}") {
                        studyId
                  studyNumber
                  parentAccountId
                  parentAccountName
                  studyTitle
                  nameofIMP
                  devPhase
                  status
                  primaryEndPoint
                  secondaryEndPoint
                  expEndPoint
                  startDt
                  endDt
                  studyDuration
                  stoppingCriteria
                  numberOfSitesPlanned
                  numberOfSubPlanned
                  route
                  regiman
                  dosage
                  statMethod
                  interimAnalysis
                  studyFlow
                  protocolSynopsisActual
                  protocolFinalActual
                  fdaSubmissionActual
                  firstSite_OpenActual
                  fpiActualActual
                  lpoActualActual
                  dbLockActual
                  csrActualActual
                  protocolSynopsisReceived
                  protocolFinalReceived
                  fdaSubmissionReceived
                  firstSite_OpenReceived
                  fpiReceivedReceived
                  lpoReceivedReceived
                  dbLockReceived
                  csrReceivedReceived
                  createdBy
                  createdTs
                  modifiedBy
                  modifiedTs
            }
        }`

        };

        requestBody = JSON.stringify(requestBody);
        setProgress(true);
        let res = await apiCall('getStudy', requestBody);
        setProgress(false);
        if (!res) {
          // Error handling
          console.log('Error on response material grid!')

        } else {
          if (res.data.getStudy) {
            propsobj.UpdateEntireForm(res.data.getStudy)
          }
        }
      }
    }
    fetchData();
  }, [studyId]);


  const handleClick = async (event) => {
    event.preventDefault();
    propsobj.history.push('/account/updateStudy');
  }

  const { studyNumber = "",
    parentAccountId = "",
    parentAccountName = "",
    studyTitle = "",
    nameofIMP = "",
    devPhase = "",
    status = '',
    numberOfSitesPlanned = "",
    numberOfSubPlanned = "",
    IP = "",
    route = "",
    dosage = "",
    regiman = "",
    primaryEndPoint = "",
    secondaryEndPoint = "",
    expEndPoint = "",
    statMethod = "",
    interimAnalysis = "",
    stoppingCriteria = "",
    startDt = "",
    endDt = "",
    studyDuration = "",
    studyFlow = "",
    protocolSynopsisActual = "",
    protocolFinalActual = "",
    fdaSubmissionActual = "",
    firstSite_OpenActual = "",
    fpiActualActual = "",
    lpoActualActual = "",
    dbLockActual = "",
    csrActualActual = "",
    protocolSynopsisReceived = "",
    protocolFinalReceived = "",
    fdaSubmissionReceived = "",
    firstSite_OpenReceived = "",
    fpiReceivedReceived = "",
    lpoReceivedReceived = "",
    dbLockReceived = "",
    csrReceivedReceived = "",
    createdBy = "",
    createdTs = "",
    modifiedBy = "",
    modifiedTs = "" } = propsobj.formValues;
  console.log(propsobj.formValues, "study")

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="d-flex mt-4">
        <div class="secondary-header mr-auto">Study Details</div>
        {isEditStudy === "1" ? <div className="">
          <button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
            <i className="fas fa-pencil-alt"></i>&nbsp; EDIT</button>
        </div> : ""}

      </div>

      <div className="">
        <form noValidate autoComplete="off" id="addAccountForm" className="row h-form-1">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">PROJECT DETAILS</div>
                      <div className="inputBox">
                        <ViewRow textvalue={studyNumber} text="Protocol Number" />
                        <ViewRow textvalue={studyTitle} text="Protocol Title" />
                        <ViewRow textvalue={parentAccountName} text="Sponsor" />

                        <ViewRow textvalue={devPhase} text="Development Phase" />
                        <ViewRow textvalue={status} text="Status" />
                        <ViewRow textvalue={numberOfSitesPlanned} text="Number of Sites Planned" />

                        <ViewRow textvalue={numberOfSubPlanned} text="Number of Subjects Planned" />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">IP DETAILS</div>
                      <div className="inputBox">
                        <ViewRow textvalue={nameofIMP} text="Investigation Product" />
                        <ViewRow textvalue={route} text="Route of Administration" />


                        <ViewRow textvalue={dosage == "" ? "- - -" : dosage} text="Dosage" />
                        <ViewRow textvalue={regiman == "" ? "- - -" : regiman} text="Regimen" />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">STUDY ANALYSIS</div>
                      <div className="inputBox">
                        <ViewRow textvalue={primaryEndPoint} text="Primary End Points" />
                        <ViewRow textvalue={secondaryEndPoint == "" ? "- - -" : secondaryEndPoint} text="Secondary End Points" />


                        <ViewRow textvalue={expEndPoint == "" ? "- - -" : expEndPoint} text="Exploratory End Points" />
                        <ViewRow textvalue={statMethod == "" ? "- - -" : statMethod} text="Statistical Methods" />

                        <ViewRow textvalue={interimAnalysis == "" ? "- - -" : interimAnalysis} text="Interim Analysis" />
                        <ViewRow textvalue={stoppingCriteria == "" ? "- - -" : stoppingCriteria} text="Stopping Criteria" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">PROTOCOL TIMELINE</div>
                      <div className="inputBox">
                        <div className="row">
                          <div className="col-md-6 col-lg-6 col-xl-4">
                            <ViewRow textvalue={startDt} text="Start Date" />
                          </div>
                          <div className="col-md-6 col-lg-6 col-xl-4">
                            <ViewRow textvalue={endDt} text="End Date" />
                          </div>
                        {/* </div>
                        <div className="row"> */}
                          <div className="col-md-6 col-lg-6 col-xl-4">
                            <ViewRow textvalue={studyDuration} text="Study Duration" />
                          </div>
                          <div className="col-md-6 col-lg-6 col-xl-12">
                            <ViewRow textvalue={studyFlow == "" ? "- - -" : studyFlow} text="Study Flow" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">PROTOCOL STARTUP MILESTONES</div>
                      <div className="inputBox">
                        <div className="row">
                          <div className="col-md-12 headerfontcss"><label>Protocol Synopsis (Final)</label></div>
                          <div className="col-md-6">
                            <ViewRow textvalue={protocolSynopsisActual} text="Planned Date" />
                          </div>
                          <div className="col-md-6">
                            <ViewRow textvalue={protocolSynopsisReceived} text="Recieved Date" />
                          </div>
                          <div className="col-md-12 headerfontcss"><label>Protocol Final</label></div>
                          <div className="col-md-6">
                            <ViewRow textvalue={protocolFinalActual} text="Planned Date" />
                          </div>
                          <div className="col-md-6">
                            <ViewRow textvalue={protocolFinalReceived} text="Recieved Date" />
                          </div>
                          <div className="col-md-12 headerfontcss"><label>FDA Submission</label></div>
                          <div className="col-md-6">
                            <ViewRow textvalue={fdaSubmissionActual} text="Planned Date" />
                          </div>
                          <div className="col-md-6">
                            <ViewRow textvalue={fdaSubmissionReceived} text="Recieved Date" />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">PROTOCOL MILESTONES</div>
                      <div className="inputBox">
                        <div className="row">
                          <div className="col-md-12 headerfontcss"><label>First Site Initiation Visit</label></div>
                          <div className="col-md-6">
                            <ViewRow textvalue={firstSite_OpenActual == "" ? "- - -" : firstSite_OpenActual} text="Planned Date" />
                          </div>
                          <div className="col-md-6">
                            <ViewRow textvalue={firstSite_OpenReceived == "" ? "- - -" : firstSite_OpenReceived} text="Actual Date" />
                          </div>
                          <div className="col-md-12 headerfontcss"><label>First Patient In</label></div>
                          <div className="col-md-6">
                            <ViewRow textvalue={fpiActualActual == "" ? "- - -" : fpiActualActual} text="Planned Date" />
                          </div>
                          <div className="col-md-6">
                            <ViewRow textvalue={fpiReceivedReceived == "" ? "- - -" : fpiReceivedReceived} text="Actual Date" />
                          </div>
                          <div className="col-md-12 headerfontcss"><label>Last Patient Out</label></div>
                          <div className="col-md-6">
                            <ViewRow textvalue={lpoActualActual == "" ? "- - -" : lpoActualActual} text="Planned Date" />
                          </div>
                          <div className="col-md-6">
                            <ViewRow textvalue={lpoReceivedReceived == "" ? "- - -" : lpoReceivedReceived} text="Actual Date" />
                          </div>
                          <div className="col-md-12 headerfontcss"><label>DB Lock</label></div>
                          <div className="col-md-6">
                            <ViewRow textvalue={dbLockActual == "" ? "- - -" : dbLockActual} text="Planned Date" />
                          </div>
                          <div className="col-md-6">
                            <ViewRow textvalue={dbLockReceived == "" ? "- - -" : dbLockReceived} text="Received Date" />
                          </div>
                          <div className="col-md-12 headerfontcss"><label>Clinical Study Report</label></div>
                          <div className="col-md-6">
                            <ViewRow textvalue={csrActualActual == "" ? "- - -" : csrActualActual} text="Planned Date" />
                          </div>
                          <div className="col-md-6">
                            <ViewRow textvalue={csrReceivedReceived == "" ? "- - -" : csrReceivedReceived} text="Received Date" />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            {/* <div className="buttonContainer col-12 mr-auto">
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small">Save</button>
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
            </div> */}
          </div>

        </form>
        <hr />
        <div className="row mb-5">
          <div className="col-md-2 mr-auto" style={{ fontSize: "15px", color: "#9D9DA8", textAlign: "right" }}>
            Created by
            </div>
          <div className="col-md-4">
            <span style={{ color: "#1C3A61", fontWeight: "500", fontSize: "14px" }}>{createdBy}</span> <span style={{ color: "#9D9DA8" }}>on</span> <span style={{ color: "gray", fontSize: "14px" }}>{moment(createdTs).format('LLL')}</span>
          </div>
          <div className="col-md-2 mr-auto" style={{ fontSize: "15px", color: "#9D9DA8", textAlign: "right" }}>
            Modified by
            </div>
          <div className="col-md-4">
            <span style={{ color: "#1C3A61", fontWeight: "500", fontSize: "14px" }}>{modifiedBy}</span> <span style={{ color: "#9D9DA8" }}>on</span> <span style={{ color: "gray", fontSize: "14px" }}>{moment(modifiedTs).format('LLL')}</span>
          </div>
        </div>
      </div>

    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewStudyDetails));



