import React, { Fragment } from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker } from "@material-ui/pickers";
import AlarmIcon from "@material-ui/icons/AddAlarm";
import { IconButton, InputAdornment } from "@material-ui/core";
import { connect } from 'react-redux';
import { ErrorLables } from '../common/lables';

const Datetimepicker = (props) => {

return (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Fragment>
      <DateTimePicker
        label={props.label}
        value={props.value}
        disablePast
        clearable
        minDate={props.mindate}
        maxDate={props.maxdate}
        fullWidth
        must={props.must}
        error={props.error}
        onError={console.log()}
        helperText={props.errortext}
        onChange={(momObj) => { props.handleChange(momObj, props.name) }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <i class="far fa-calendar-check"></i>
            </InputAdornment>
          ),
        }}
      />
    </Fragment>
  </MuiPickersUtilsProvider>
)
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Datetimepicker);