// import React, { useEffect, useState } from "react";
// import { withRouter } from 'react-router-dom';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import { genericApi } from '../../../../common/apiconfig';
// import FormHoc from '../../../../globalcomponents/hoc/formHoc';
// import { InputField, AutoOptions, AutoOptionsNew } from '../../../../globalcomponents/formElements';
// // import { Dropdowns, Causality, AE_Outcome } from '../../../common/constants';
// import { connect } from 'react-redux';

// const ExecutedStudies = (propsobj) => {
//   let [apiError, setApiError] = useState(false);
//   let [progress, setProgress] = useState(false);
//   let [totalPrice, setTotalPrice] = useState('');
//   useEffect(() => {
//     var data = propsobj.formValues;
//     var price=data.req_price
//     var pricingType=data.req_pricingtype
//     console.log(pricingType,price)
//     if(pricingType==="flat"){
//       setTotalPrice(price)
//     }else if(pricingType==="per patient"){
//       setTotalPrice(155*price)
//     }
    
//     // propsobj.UpdateFormField({ value: "", name: "AE_subjectid" })
//     // propsobj.UpdateFormField({ value: "", name: "AE_term" })
//     // propsobj.UpdateFormField({ value: "", name: "AE_severity" })
//     // propsobj.UpdateFormField({ value: "", name: "AE_causality" })
//     // propsobj.UpdateFormField({ value: "", name: "AE_outcome" })
//     // propsobj.UpdateFormField({ value: "", name: "AE_startDate" })
//     // propsobj.UpdateFormField({ value: "", name: "AE_stopdate" })
//   }, [propsobj.formValues]);

//   const hocObj = FormHoc(propsobj);

//   const textProps = {
//     ...hocObj,
//     propsobj
//   }

//   const autoPropsToprice = {
//     default: null,
//     'options': [{ name: "Per patient", value: "per patient" }, { name: "Flat", value: "flat" }],
//     freeSolo: false,
//     propsobj,
//     keys: ['value']
//   }


//   const formSubmit = async (event) => {
//     event.preventDefault();
//     let setGo = await hocObj.handleSubmit('addAE', event);

//     if (setGo) {
//       setProgress(true);
//       let form = propsobj.formValues;
//       console.log(form)
//      let requestBody = `
//       mutation {
//         openSquareLeadResponse(requestId:"${form.requestId}",
//         status:"Complete",patientCount:"${form.patientscount}",priceType:"${form.pricingType}", totalPrice:"${totalPrice}"
//        {
//          code
//           type
//           message
//           attributes
//         }
//       }
//     `;
//       requestBody = JSON.stringify({ query: requestBody });
//       let res = await genericApi('openSquareLeadResponse', requestBody, form);
//       setProgress(false);

//       if (res && res.data.openSquareLeadResponse.code === '200') {
//         console.log('added');
//         propsobj.history.push('/site/accountDetails/fees/nonRegisteredstudies"');
//       } else {
//         console.log('Error!')
//       }
//     }
//   }

//   const handleCancel = (event) => {
//     event.preventDefault();
//     propsobj.history.goBack();
//   }

//   const goBack = (event) => {
//     event.preventDefault();
//     propsobj.history.goBack()
//   }

//   return (
//     <>
//       {progress && <div className="fullScreen">
//         <CircularProgress
//           disableShrink
//           className="progressCircle"
//           value={90}
//           size={100}
//           thickness={1.5}
//         />
//       </div>}
//       <div className="d-flex justify-content-end mt-4">
//         <div className="secondary-header mr-auto">Add Pricing Info</div>
//       </div>
//       <div className="">
//         <form noValidate autoComplete="off" id="addAE" className="row" onSubmit={formSubmit}>
//           <div className="col-md-6">
//             <div className="panel-primary-wrap">
//               <div className="card">
//                 <div className="card-body">
//                   {/* <div className="card-page-header">NAME INFORMATION</div> */}
//                   <div className="inputBox">
//                     <div>Sponsor Name:<span>XXXXXX</span></div>
//                     <div>Request No:<span>XXXXXX</span></div>
//                     <div>Patient Count:<span>155</span></div>
//                     {/* <InputField {...textProps} name="req_sponsorname" label="Sponsor Name" must={'false'} type="text" disabled/>
//                     <InputField {...textProps} name="req_no" label="Request NO." must={'true'} type="text" /> */}
//                     <InputField {...textProps} name="req_price" label="Price($)" must={'true'} type="text" />
//                     <AutoOptions {...autoPropsToprice} name="req_pricingtype" label="Pricing Type" keyName='value' must={'true'} />
//                     {/* <InputField {...textProps} name="req_totalprice" label="Total Price" must={'true'} type="text" /> */}
//                     <div>Total Price($):<span>{totalPrice}</span></div>
//                   </div>

//                 </div>

//               </div>
//             </div>
//             <div className="buttonContainer">
//               <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
//               <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
//             </div>
//           </div>
//         </form>
//       </div>    </>
//   )
// }

// const mapStateToProps = (state) => {
//   return {
//     formValues: state.form,
//     state
//   }
// }
// const mapDispatchToProps = (dispatch) => {
//   return {
//     UpdateFormField: (obj) => {
//       return dispatch(
//         {
//           type: 'UpdateFormField',
//           value: obj.value,
//           name: obj.name
//         }
//       )
//     },
//     clearForm: () => {
//       return dispatch(
//         {
//           type: 'ClearForm',
//         }
//       )
//     },
//     updateErrorObj: (obj) => {
//       return dispatch(
//         {
//           type: 'updateErrorObj',
//           flag: obj.flag,
//           name: obj.name,
//           text: obj.text
//         }
//       )
//     },
//     updateEntireErrorObj: (errobj, errtextobj) => {
//       return dispatch(
//         {
//           type: 'updateEntireErrorObj',
//           errobj: errobj,
//           errtextobj: errtextobj,
//         }
//       )
//     },
//   }
// }
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExecutedStudies));

import React from 'react'
import PricingInfo from './pricingInfo'

const ExecutedStudies=()=>{
  return(
    <PricingInfo/>
  )
}

export default ExecutedStudies