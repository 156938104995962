import React, { useEffect, useState, useReducer } from "react";
import Header from './header/header';
import Sidebar from './sidebar/sidebar';
import AccountPage from './contentBody/accountPage';
import { connect, useSelector, useDispatch } from 'react-redux';

const MainPage = () => {
    let reduxstateobj = useSelector(state => state);
    return (
        <>
            <div id="wrapper">
                {reduxstateobj.showSidebar && <Sidebar />}
                <div className="contentBody">
                    <Header />
                    <AccountPage />
                </div>
            </div>
        </>
    )
}
export default MainPage;


