import React, { Component, useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { genericApi } from '../../../common/apiconfig';
import DosageDetail from './dosageDetail';
// import CalendarApp from "./precalendar"
import { connect } from 'react-redux';
import CommonModal from '../site/fileupload/commonmodal'
import BackIcon from '../../../img/back1.svg'
import Calendar from '../../../img/calendar.svg'

const DosageTable = (props) => {
  let [progress, setProgress] = useState(false)
  let [schedule, setSchedule] = useState({})
  let [showModal, setShowModal] = useState(true)
  let [dosageDetail, setDosageDetail] = useState([])
  let [subId, setSubId] = useState('')
  let [dosageMethod, setDosageMethod] = useState('')
  let [dosageDuration, setDosageDuration] = useState('')
  let [cols, setCols] = useState([])
  let [rows, setRows] = useState([])
  let [scheduleEventList, setScheduleEventList] = useState('')
  let [open, setOpen] = useState(false)
  let [rowData, setRowData] = useState([])
  let [linkRowColData, setLinkRowColData]=useState()
  let [isCalendar, setIsCalendar] = useState(true)
  // const calendar=React.createRef();

  useEffect(() => {
    callApi(props.formValues.patientdata)
    props.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, [])

  useEffect(() => {
    if (cols.length > 0) {
      ths()
    }
  }, [cols])
  const ths = () => {
    let ths = "";
    ths = cols.map(function (item, i) {
      return <TableCell key={i} data-inx={i}> {item} </TableCell>;
    });

    let tempRowObj = rows[0];

    if (tempRowObj) {
      if (cols.length == tempRowObj.length) {
        buildDosageTds();
      }
    }
    return ths
  }
  const buildDosageTds = () => {
    let tds = rows.map(function (key, i, rows) {
      let rowItem = rows[i];
      return <TableRow>
        <TableCell>{rowItem[0]}</TableCell>
        {
          rowItem.map((obj, colInx, item) => {
            return <TableCell onClick={(e) => showDetail(e,item)} data-statusInd={item[colInx + 1]} data-colId={colInx + 1} data-rowId={colInx}>
              {item[colInx + 1] == 1 && <label className="completed"><i className="fa fa-check-circle"></i></label>}
              {item[colInx + 1] == 2 && <label className="notCompleted"><i className="fa fa-exclamation-circle"></i></label>}
              {item[colInx + 1] == 3 && <label className="latelyCompleted"><i className="fa fa-check-circle"></i></label>}
              {item[colInx + 1] == 4 && <label className="notStarted"><i className="fa fa-minus-circle"></i></label>}

              {/* {!item[colInx + 1] && <label className="notStarted"><i className="fa fa-minus-circle"></i></label>} */}

            </TableCell>

          })
        }
      </TableRow>
    });
    return tds;
  }
  const callApi = async (data) => {
    setProgress(true)
    let requestBody = {
      query: `
          query {
            getScheduleDetailsPatient(studyId:"${data.studyId}",patientId:"${data.patientId}",siteId:"${data.siteId}") {
              schedule {
                scheduleID
                studyId
                scheduleDsc
                effectiveDate
                createdBy
                createdTs
                modifiedBy
                modifiedTs
              }
              scheduleEventList {
                scheduleID
                eventID
                eventType
                eventOrder
                eventName
                eventKey
              }
              scheduleSegmentList {
                scheduleID
                segmentOrder
                segmentName
                start
                end
                varience
              }
              linkRowColumnPatient {
                patSchedulePk
                patientID
                siteId
                scheduleID
                rowID
                columnID
                startDate
                endDate
                segmentVariance
                actualDate
                expectedDate
                statusInd
              }
          }
      }`
    };
    requestBody = JSON.stringify(requestBody);
    setProgress(true)
    let res = await genericApi('getScheduleDetailsPatient', requestBody);
    if (!res) {
      // Error handling
      //console.log('Error')

    } else {
      //console.log(res)
      setProgress(false)
      let newData = res.data.getScheduleDetailsPatient;
      console.log('newData::', newData);
      buildColumns(newData.scheduleSegmentList);
      buildRows(newData);
      setSchedule(newData.schedule)
    }
  }
  const buildRows = (rowObj) => {
    let row = rowObj.scheduleEventList.map((item, inx) => {
      return buildSingleRow(item.eventName, item.eventOrder, rowObj);
    });

    // this.setState({
    //   rows: this.globalData.rowData
    // });
    setRows(rowData)
    setRowData([])
    // this.globalData.rowData = [];//reset the array for next patient
  }

  const buildSingleRow = (event, id, linkObj) => {
    let row = [];
    console.log(linkObj,"$$$$")
    let otherData = linkObj.linkRowColumnPatient.filter(item => item.rowID == id);
console.log(cols,otherData,"cols")
    let columnLength = cols.length;
    row.length = columnLength;
    linkObj.scheduleSegmentList.map((item,i)=>row.push(""))
    console.log(row,"Empty  ")
    row[0] = event;
    if (otherData.length) {
      for (let rowid = 0; rowid < otherData.length; rowid++) {
        let rowObj = otherData[rowid];
        console.log(otherData[rowid],"-----------")
        // row["patSchedulePk"]=rowObj.patSchedulePk
        row[Number(rowObj['columnID']) + 1] = rowObj['statusInd'];
      }
    }
    console.log(row,"$$$$")
    rowData.push(row)
    // this.globalData['rowData'].push(row);
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const printPageArea = () => {
    window.print();
  }

  const showCalendar = (e) => {
    // setIsCalendar(!isCalendar)
    const anchor = (e.target.ownerDocument || document).querySelector('#calendarshow');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  const buildColumns = (obj) => {
    let scheduleSegmentList = obj;
    let defaultCols = ['Events'];
    let dynamicCols = scheduleSegmentList.map(function (item, i) {
      return item.segmentName;
    });

    let cols = [...defaultCols, ...dynamicCols];
    setCols(cols)
  }

  const showDetail = (e,data) => {
    e.preventDefault()
    console.log(data,"====")
    // setLinkRowColData(data)
    setOpen(true)
  }
  const goback = () => {
    props.history.goBack()
  }
  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <section className="dosageTablePage bg-white mt2rem">
        <div className="dosageTable">
          <section className="d-flex">
            <div className='d-flex flex-column align-items-start col30'>
              <label className="font14"><strong>Subject Id :</strong> {props.formValues.patientdata.patientId} </label>
              <label className="font14"><b>Dosage Method :</b> {schedule.frequency} </label>
              <label className="font14"><b>Dosage Duration :</b> {schedule.noOfOccurance} </label>
              {/* <img src={Calendar} alt="Calendar" onClick={(e) => { showCalendar(e) }}  style={{ cursor: "pointer", fontSize: "17px" }} /> */}
            </div>
            <div className='d-flex flex-column align-items-end col70'>
              <div className='d-flex flex-row-reverse printEmail'>
                <Button variant="outline-secondary" size="sm" onClick={() => printPageArea()}><i className="fa fa-print"></i> &nbsp;Print</Button>
                <Button variant="outline-secondary" size="sm"> <i className="fa fa-envelope"></i>  &nbsp;Email</Button>
                <span style={{ marginTop: "8px" }}>
                  <label className="completed font14 mr-3">Completed (On Time) : <i className="fa fa-check-circle"> </i> </label>
                  <label className="latelyCompleted font14 mr-3">Completed (Late): <i className="fa fa-check-circle"> </i></label>
                  <label className="notCompleted font14 mr-3">Failed to Complete : <i className="fa fa-exclamation-circle"></i> </label>
                  <label className="notStarted font14 mr-3">Not Started : <i className="fa fa-minus-circle"></i> </label>
                </span>
              </div>
            </div>
          </section>
        </div>


        <section className="metricsTable">
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {ths()}
              </TableRow>
            </TableHead>
            <TableBody>
              {buildDosageTds()}
            </TableBody>

          </Table>
        </section>



        {/* {isCalendar && <div className="calApp" id="calendarshow">
          <CalendarApp />
        </div>
        } */}
        {/* <div onClick={goback} className="backToOldScr subNavText">
          <img src={BackIcon} alt="goback" />  BACK TO PATIENTS LIST</div> */}
      </section>


      {/* <DosageDetail open={true} onClose={onClose}/>  */}
      <CommonModal header="Subject Detail" open={open} onClose={onClose}>
        <DosageDetail onClose={onClose} scheduleData={linkRowColData} />
      </CommonModal>


    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DosageTable));