import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../common/apiconfig';
import FormHoc from '../hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew, AutoOptionsMultiple } from '../formElements';
import { Dropdowns, regExpData } from '../../common/constants';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
let roleObj = {
  roleName: '',
  roleId: '',
  error: false,
  helperText: '',
}

const AddContact = (propsobj) => {
  let accountObj = {
    accountName: propsobj.formValues.accountName || "",
    sponsorAccountId: propsobj.formValues.sponsorAccountId || "",
    error: false,
    helperText: '',
  }

  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [accnameShow, showAccName] = useState(false);
  let [portal, setPortal] = useState(false)
  let [phoneCheck, setPhoneError] = useState('')
  let [roleField, setRoleField] = useState(roleObj);
  let [options, setOptions] = useState([])
  let [accInfo, setAccInfo] = useState(accountObj)
  console.log(Dropdowns.Contact_Role,propsobj.formValues.accountType, Dropdowns.Study_Site_Primary_Contact_Role, propsobj.formValues.position, "Roles")
  useEffect(() => {
    propsobj.UpdateFormField({ value: "", name: "firstName" })
    propsobj.UpdateFormField({ value: "", name: "mname" })
    propsobj.UpdateFormField({ value: "", name: "position" })
    propsobj.UpdateFormField({ value: "", name: "lastName" })
    propsobj.UpdateFormField({ value: "", name: "roles" })
    propsobj.UpdateFormField({ value: "", name: "lang" })
    propsobj.UpdateFormField({ value: "", name: "email" })
    propsobj.UpdateFormField({ value: "", name: "phone" })

    if (propsobj.formValues.contactaddformclear) {
      propsobj.UpdateFormField({ value: "", name: "accountName" })
      propsobj.UpdateFormField({ value: "", name: "sponsorAccountId" })
      propsobj.UpdateFormField({ value: false, name: "contactaddformclear" })
    }
    if (propsobj.formValues.addContactFromContactSearch) {
      showAccName(true)
    } else {
      propsobj.UpdateFormField({ value: propsobj.formValues.accountName, name: "accountName" })
      propsobj.UpdateFormField({ value: propsobj.formValues.sponsorAccountId, name: "sponsorAccountId" })
    }
    return () => {
      let errorFieldsObj = {
        firstName: false,
        lastName: false,
        contactAccountName: false,
        position: false,
        lang: false,
        roleName: false,
        phone: false,
        email: false
      }
      let errorTextObj = {
        firstName: "",
        lastName: "",
        contactAccountName: "",
        position: "",
        lang: "",
        roleName: "",
        phone: "",
        email: ""
      }
      propsobj.updateEntireErrorObj(errorFieldsObj, errorTextObj);
    };
  }, []);
  useEffect(() => {
    fetchAccountsforParent()
  }, [propsobj.formValues.accountType])

  const fetchAccountsforParent = async () => {
    const getAllAccounts = `
    sponsorAccountId
    accountName
    parentAccountId
    accountType
    status
    address1
     address2
    city
    state
    country
    zip
    email
    phone
  `;
    const parentAccount = "*";
    const type = 'B';
    const accountType = propsobj.formValues.addContactFromContactSearch ? "*" : propsobj.formValues.accountType || "*"
    let requestBody = {
      query: `query {
          getAccountTypeSearch(parentAccountId: "${parentAccount}",searchStr: "*", type:"${type}",accountType: "${accountType}"){
                    ${getAllAccounts}
              }
          }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await apiCall('getAccountTypeSearch', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error!')

    } else {
      if (res.data.getAccountTypeSearch.length) {
        setOptions(res.data.getAccountTypeSearch)
      } else {
        setOptions([])
      }
    }
  }
  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsToLang = {
    default: {
      value: 'English (US)',
      label: 'English (US)',
    },
    'options': Dropdowns.Languages,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }
  const autoPropstoposition = {
    ...autoPropsToLang,
    'options': propsobj.formValues.accountType == "Sponsor" ? Dropdowns.Contact_Role : Dropdowns.Study_Site_Primary_Contact_Role,
    default: null,
  }
  const autoPropstorole = {
    'options': Dropdowns.Roles,
    propsobj,
    keys: ['roleName'],
    error: roleField.error,
    helperText: roleField.helperText
  }

  const autoPropstoaccount = {
    'options': options,
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['accountName']
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('addContactForm', event);
    // if (setGo) {
    let phoneCheck = regExpData.phoneReg.test(propsobj.formValues.phone)
    if (propsobj.formValues.phone != "" && phoneCheck == false) {
      setPhoneError("Invalid number")
    } else {
      setPhoneError("")
      phoneCheck = true
    }
    // }
    if (setGo && phoneCheck) {
      setProgress(true);
      let form = propsobj.formValues;
      let requestBody = `
            mutation {
                createSponsorAccountUser(sponsorAccountId: "${form.sponsorAccountId}", 
                    firstName: "${form.firstName}", 
                    middleName:"${form.mname}", 
                    lastName :${JSON.stringify(form.lastName)},
                    email :"${form.email}",
                    roleId:"${portal ? form.roleId : ""}",
                    phone:"${form.phone}",
                    position:"${form.position}",
                    language:"${form.lang == "" ? "English (US)" : form.lang}",
                    portalAccessInd:"${portal ? "Y" : "N"}",
                    invitedBy:"admin") {
                  code
                  type
                  message
                  attributes
              }
            }
          `;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('createSponsorAccountUser', requestBody, form);
      setProgress(false);

      if (res && res.data.createSponsorAccountUser.code === '200') {
        propsobj.UpdateFormField({ value: false, name: "addContactFromContactSearch" })
        //propsobj.clearForm();
        if (propsobj.formValues.primaryContactSearch == true) {
          // console.log(res.data.createSponsorAccountUser.attributes,"addcontact")
          // alert('hh')
          propsobj.UpdateFormField({ value: propsobj.formValues.firstName + ' ' + propsobj.formValues.lastName, name: "primaryContact" })
          propsobj.UpdateFormField({ value: res.data.createSponsorAccountUser.attributes, name: "sponsorAccountUserId" })
          propsobj.UpdateFormField({ name: "primaryContactSearch", value: false })
          propsobj.history.push('/account/updateAccount')
        } else if (propsobj.formValues.addContactFromContactSearch) {
          propsobj.UpdateFormField({ value: res.data.createSponsorAccountUser.attributes, name: "sponsorAccountUserId" })
          propsobj.history.push('/account/contact/view')
        } else {
          // propsobj.history.push('/account/accountDetails/contacts');
          propsobj.history.goBack()
        }
        // logOut();
      } else {
        console.log('Error on response add account file!')
        //props.history.push('/account/updateContact'); // temp enabling to check update sponsor
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
    propsobj.history.goBack();
  }

  const accountSearch = (event) => {
    event.preventDefault();
    propsobj.history.push('/account/search')
  }

  const dateHandleChange = (momObj, eleName) => {
    console.log('momentObj--', momObj, '---name---', eleName);
  }

  const handlePortalChange = () => {
    setPortal(!portal)
    let errobj = {
      name: "roleName",
      flag: false,
      text: "",
      // change: true
    }
    propsobj.updateErrorObj(errobj);

  }

  const handleRole = async (obj) => {
    console.log(obj, "obj")
    let roleObj = {
      roleName: '',
      roleId: '',
      error: false,
      helperText: '',
    }
    setRoleField(roleObj);
    if (obj) {
      let roleObj = {
        roleName: obj.value,
        roleId: obj.selected.roleId,
        error: obj.flag,
        helperText: obj.text,
      }
      setRoleField(roleObj);
      propsobj.UpdateFormField({ name: "roleId", value: obj.selected.roleId })
      propsobj.UpdateFormField({ name: "roleName", value: obj.value })
    }
  }

  const getAccountInfo = (data) => {
    console.log(data, "Data")
    propsobj.UpdateFormField({ name: 'position',value:{}})
    propsobj.UpdateFormField({ name: "sponsorAccountId", value: data.selected.sponsorAccountId })
    setAccInfo(data.selected)
    propsobj.UpdateFormField({ name: 'accountType', value: data.selected.accountType })
   
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">ADD CONTACT</h1>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="addContactForm" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">NAME INFORMATION</div>
                  <div className="inputBox">
                    <InputField {...textProps} name="firstName" label="First Name" must={'true'} type="text" />

                    <InputField {...textProps} name="mname" label="Middle Name" must={'false'} type="text" />

                    <InputField {...textProps} name="lastName" label="Last Name" must={'true'} type="text" />
                    {/* 
                    <InputField {...textProps} name="accountName" label="Account Name" must={'true'} type="text" disabled={!accnameShow} />
                    {accnameShow &&
                      <div className="searchButtonWidth">
                        <IconButton aria-label="Search" className="searchicon"
                          onClick={accountSearch}>
                          <SearchIcon />
                        </IconButton>
                      </div>} */}
                    <AutoOptionsMultiple {...autoPropstoaccount} autoValue={accInfo} name="contactAccountName" label="Account Name" keyName='accountName' must={'true'} callback={getAccountInfo} disabled={!accnameShow} />

                    <AutoOptions {...autoPropstoposition} name="position" label="Position" keyName='value' must={'true'} />

                    <AutoOptions {...autoPropsToLang} name="lang" label="Language" keyName='value' must={'true'} />
                    <div className="row">
                      <div className="col-md-5">
                        <FormGroup style={{ paddingTop: "15px" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={portal}
                                onChange={(e) => { handlePortalChange(e) }}
                                value="true"
                                name="portalaccess"
                                color="primary"
                              />
                            }
                            label="Portal access"
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-7">
                        {
                          portal &&
                          <AutoOptionsNew {...autoPropstorole} autoValue={roleField} name="roleName" label="Access level" keyName='roleName' must={portal.toString()} callback={handleRole} />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">CONTACT INFORMATION</div>
                  <div className="inputBox">
                    <div style={{ position: "relative" }}>
                      <InputField {...textProps} name="phone" label="Phone" must={'true'} type="text" />
                      {phoneCheck.length > 0 && <span className="phoneerr">{phoneCheck}</span>}
                    </div>
                    <InputField {...textProps} name="email" label="Email" must={'true'} type="email" />
                  </div>

                </div>
              </div>
            </div>
            <div className="buttonContainer">
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddContact));



