import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { genericApi } from '../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Materialtable from '../../globalcomponents/materialtable'
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
// import * as R_Items from '../recentItems';
// import { checkPermission } from '../../common/utils';

function StudySearchGrid(props) {
  // let isAddStudy = checkPermission('Study_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');


  useEffect(() => {
    getStudyList()
    props.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, [])
  async function getStudyList() {
    let requestBody = {
      query: `query {
                getStudysOfSite(siteId:"${props.formValues.autosite.sponsorAccountId}"){
                  studyId
                  studyNumber
                  parentAccountId
                  parentAccountName
                  studyTitle
                  nameofIMP
                  devPhase
                  status
                  primaryEndPoint
                  secondaryEndPoint
                  expEndPoint
                  startDt
                  endDt
                  studyDuration
                  stoppingCriteria
                  numberOfSitesPlanned
                  numberOfSubPlanned
                  route
                  regiman
                  dosage
                  statMethod
                  interimAnalysis
                  studyFlow
                  protocolSynopsisActual
                  protocolFinalActual
                  fdaSubmissionActual
                  firstSite_OpenActual
                  fpiActualActual
                  lpoActualActual
                  dbLockActual
                  csrActualActual
                  protocolSynopsisReceived
                  protocolFinalReceived
                  fdaSubmissionReceived
                  firstSite_OpenReceived
                  fpiReceivedReceived
                  lpoReceivedReceived
                  dbLockReceived
                  csrReceivedReceived
                  createdBy
                  createdTs
                  modifiedBy
                  modifiedTs
                }
            }`
    };


    //setProgress(true);
    requestBody = JSON.stringify(requestBody);
    let res = await genericApi('getStudysOfSite', requestBody);
    //setProgress(false);
    if (!res) {
    } else {
      setData(res.data.getStudysOfSite);
      
    }
  }


  const handlePageClick = (offset) => {
    setoffset(offset)
  }


  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back

    // await props.setSidebarActiveEl('studies');
    // let dataToR_Items = {
    //   id: rowData.studyId,
    //   studyNumber: rowData.studyNumber,
    //   studyTitle: rowData.studyTitle
    // }

    // setProgress(true);
    // let response = await R_Items.updateRecentItems(dataToR_Items, R_Items.rI_studies);
    // if (response) {
    //   let studyItems = await R_Items.getRecentSearchOrItems(R_Items.rI_studies);
    //   if (studyItems) {
    //     let obj = {
    //       obj: R_Items.rI_studies,
    //       value: studyItems
    //     }
    //     props.updateRecentItems(obj);
    //   }
    // }
    // setProgress(false);

    // await props.UpdateEntireForm(rowData);
    props.history.push('/site/study/studydetails');
  }
  // const searchHandle = (event) => {
  //   event.preventDefault();
  //   let value = event.target.value;
  //   setValSearch(value);
  // }
  // const searchSubmit = async (event) => {
  //   event.preventDefault();
  //   if (valSearch.length) {
  //     fetchData(valSearch);
  //     await props.UpdateFormField({ name: "studySearchText", value: valSearch })
  //     // await props.updateHeaderSearchValue({ value: "" })
  //   }
  // }
  // const clearSearch = (event) => {
  //   event.preventDefault()
  //   fetchData('');
  //   setValSearch("")
  // }
  String.prototype.limit = function (length) {
    return this.length > length ? (this.substring(0, length) + '...') : this;
  }
  const Columns = [
    {
      title: 'Protocol Number', field: 'studyNumber',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Protocol Title', field: 'studyTitle',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: '25%'
      },
      render: rowData => rowData.studyTitle.limit(30)
    },
    {
      title: 'Phase', field: 'devPhase'
    },

    { title: "IP", field: 'nameofIMP', render: rowData => rowData.nameofIMP.limit(30) },
    { title: 'Status', field: 'status' },
    // { title: 'Progress', field: 'city' },
  ]
  //console.log(data, "study")
  return (

    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">STUDIES</h1>
        {/* {isAddStudy === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
              <i className="fa fa-plus-circle mr-1"></i> ADD STUDY</button>
          </div> : ""} */}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
            {/* <form noValidate autoComplete="off" onSubmit={searchSubmit}>
              <HekmaTextSearchField
                name="accountgridsearch"// this name you need not to add in constant file unless if we use redux
                // label="Search Account"
                placeholder="Search Studies"
                value={valSearch}
                handleChange={searchHandle}
                className="gridSearchField"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" onClick={searchSubmit} style={{cursor:"pointer"}}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                      X
                    </InputAdornment>
                  ),
                }}
              />
            </form> */}
            
            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
             />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    // updateHeaderSearchValue: (obj) => {
    //   return dispatch(
    //     {
    //       type: 'headerSearch',
    //       value: obj.value,
    //     }
    //   )
    // },
    // updateRecentItems: (obj) => {
    //   return dispatch(
    //     {
    //       type: 'updateRecentItems',
    //       value: obj,
    //     }
    //   )
    // },
    // setSidebarActiveEl: (name) => {
    //   return dispatch(
    //     {
    //       type: 'sidebarActiveEl',
    //       value: name,
    //     }
    //   )
    // },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudySearchGrid));


