import React from 'react'
import { Route, Link, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import HomePage from "./globalcomponents/homePage";
import SiteRouter from './siteSrc/site-router';
import PatientRouter from './patientSrc/patient-router';
import SponsorHome from './sponsorApp/components/homePage';
//import SponsorRouter from "./sponsorApp/sponosor-router";
import SponsorMainPage from "./sponsorApp/components/mainPage";
// import PagAccount from './pagApp/signupAccount'
import PagRouter from './pagApp/pag-router'
import AdminRouter from "./adminapp/adminrouter"
import {appConfig} from './common/constants'
const Routing = () => {

    let isSponsorApp = false;

    let hostName = window.location.hostname;

    if (hostName == appConfig.host1 || hostName == appConfig.host2) {
        isSponsorApp = true;
    }

    return (
        <Switch>
            <Route path="/login" exact>
                {
                    isSponsorApp ? <SponsorHome /> : <HomePage />
                }
            </Route>
            <Route path='/securelogin' component={SponsorHome}/>
            {/* <Route path='/signuppag' component={PagAccount}/> */}
            <Route path="/account" component={SponsorMainPage} />
            <Route path="/searchresults" component={SponsorMainPage} />
            <Route path="/addContact" component={SponsorMainPage} />
            <Route path="/activateContact" component={SponsorMainPage} />
            <Route path="/resetPassword" component={SponsorHome} />

            <Route path="/site" exact>
                <SiteRouter />
            </Route>
            <Route path="/site/*" exact>
                <SiteRouter />
            </Route>

            <Route path="/patient" exact>
                <PatientRouter />
            </Route>
            <Route path="/patient/*" exact>
                <PatientRouter />
            </Route>
            <Route path="/app/*" exact>
                <PagRouter />
            </Route>
            <Route path="/app" exact>
                <PagRouter />
            </Route>
            <Route path="/admin/*" exact>
                <AdminRouter />
            </Route>
            <Route path="/admin" exact>
                <AdminRouter />
            </Route>
          
            <Redirect to="/login" />
        </Switch>
    )
}

export default Routing;