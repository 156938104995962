import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { genericApi, apiCall,oldgenericApi } from '../../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
// import { checkPermission } from '../../../../common/utils';
import Materialtable from '../../../../globalcomponents/materialtable'

function AccountsGrid(props) {
  console.log(props,"Account")
  // let isAddAccount = checkPermission('Account_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');



  useEffect(() => {
    callSponsorsApi(props.formValues.autosite.sponsorAccountId)
    props.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, []);
  const callSponsorsApi = async (id) => {
    let requestBody = {
      query: `query {
    getSponsorOfStudysOfSite(siteId:"${id}") {
      sponsorAccountId
      accountName
      parentAccountId
      accountType
      address1
      address2
      country
      state
      city
      zip
      email
      phone
      website
      duns
    }
  }`
    }
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await oldgenericApi('getSponsorOfStudysOfSite', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error')

    } else {
      if (res.data.getSponsorOfStudysOfSite.length) {
        //console.log(res.data, "sponsors")
        setData(res.data.getSponsorOfStudysOfSite)
      }else{
        setData([])
      }
    }
  }
  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  // const handleClick = async (event) => {
  //   event.preventDefault();
  //   props.history.push('/account/addAccount');
  // }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    await props.setSidebarActiveEl('accounts');
    props.UpdateEntireForm(rowData);
    props.history.push('/site/accountDetails');
  }

  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = async (event) => {
    event.preventDefault();
    if (valSearch.length) {
      // fetchData(valSearch);
      await props.UpdateFormField({ name: "accountSearchText", value: valSearch })
      await props.updateHeaderSearchValue({ value: "" })
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    // fetchData();
    setValSearch("")
  }

  const Columns = [

    {
      title: 'Name', field: 'accountName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: '20%'
      },
    },
    {
      title: 'Type', field: 'accountType'
    },

    // { title: "Parent ID", field: 'parentAccountId' },
    { title: 'Address', field: 'address1' },
    { title: 'City', field: 'city' },
    { title: 'Country', field: 'country' },
    // {title:"Status", field:'status'}
  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
        {/* <Loader/> */}
      </div>}
      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">ACCOUNTS</h1>
        {/* {isAddAccount === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
              <i className="fa fa-plus-circle mr-1"></i> ADD ACCOUNT</button>
          </div> : ""} */}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
            <div className="col-xs-12 col-sm-12 col-md-5">
              {/* <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                <HekmaTextSearchField
                  name="accountgridsearch"// this name you need not to add in constant file unless if we use redux
                  // label="Search Account"
                  placeholder="Search Accounts"
                  value={valSearch}
                  handleChange={searchHandle}
                  className="gridSearchField"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                       
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                        X
                       
                      </InputAdornment>
                    ),
                  }}
                />
              </form> */}
            </div>
            <Materialtable
              columns={Columns}
              data={data.length > 0 ? data : []}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateHeaderSearchValue: (obj) => {
      return dispatch(
        {
          type: 'headerSearch',
          value: obj.value,
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateRecentItems: (obj) => {
      return dispatch(
        {
          type: 'updateRecentItems',
          value: obj,
        }
      )
    },
    setSidebarActiveEl: (name) => {
      return dispatch(
        {
          type: 'sidebarActiveEl',
          value: name,
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountsGrid));