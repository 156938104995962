import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { propTypes } from 'react-bootstrap/esm/Image';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';



import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}style={{
      backgroundColor: "#1c3b62",
      color: "white",
      padding: "10px",
    }} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);



export default function EditModalPopup(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showform, setshowForm] = React.useState(true);
  const [editItem, setEditItem] = useState(props.clickedItem);
  const [editInx, setEditInx] = useState();
  let [linkobj, setLinkobj] = useState(null);
  let [entitiyList, setEntityList] = useState(props.entities);
  let [linkedItem, setLinkedItem] = useState();


  useEffect(() => {
    if (props.entities && props.entities.length > 0) {
      let indexedEntities = props.entities.map((item, i) => {
        item.item_index = i;
        return item;
      });
      setEntityList(indexedEntities);
    } else {
      setEntityList(props.entities);
    }
  }, [props.entities])


  useEffect(() => {


    if (props.clickedItem.attribute && props.clickedItem.attribute.length) {
      //let termid = props.clickedItem.link.to_term_id;
      //console.log('entitiyList:::', props.entities, '------------------', termid);
      try {
        // let linktedItem = props.entities.filter((item, i) => {
        //   if (item.termid) {
        //     return item.termid == termid;
        //   }
        // })
        // console.log('filtered item::', linktedItem);

        setLinkedItem(props.clickedItem.attribute[0]);

      } catch (error) {

      }
    }
    setEditItem({ ...props.clickedItem });
  }, [props.clickedItem])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setshowForm(false)
    props.closePopover();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const defaultProps = {
    options: props.categories,
  };

  const getOptionLabel = option => {
    // e.g value selected with enter, right from the input
    try {
      if (props.getOptionLabel) {
        return props.getOptionLabel(option)
      } else {
        if (typeof option === 'string') {
          return option;
        }
        if (option && option.inputValue) {
          return option.inputValue;
        }
        return option && option[props.keyName]
      }
    } catch (error) {

    }
  }

  const categoryChange = (event, value) => {
    event.preventDefault();
    let entities = entitiyList;
    let modifiedEditItem = { ...editItem, 'categorey': value };
    entities[editItem.item_index] = modifiedEditItem;
    setEditItem(modifiedEditItem)
    setEntityList([...entities]);

  }


  const EditEntities = (e) => {
    e.preventDefault();
    let entities = entitiyList;
    let refKeys = props.refkeys;

    let allData = props.allData;
    let { type, rowInx, sentInx, terminx } = refKeys;
    if (type == 'inclusion') {
      allData['eligibility criteria']['inclusion_criteria'][rowInx].sents[sentInx].terms = entities;
    } else {
      allData['eligibility criteria']['exclusion_criteria'][rowInx].sents[sentInx].terms = entities;
    }
    props.updateEditedData(allData)
  }

  const getOptionEntities = option => {

    try {
      if (props.getOptionLabel) {
        return props.getOptionLabel(option)
      } else {
        if (typeof option === 'string') {
          return option;
        }
        if (option && option.inputValue) {
          return option.inputValue;
        }
        return option && option.text
      }
    } catch (error) {

    }


  }


  const linkEntityChange = (event, value) => {
    event.preventDefault();

    console.log('value:::', value);


    let selectedItem = { ...value };
    let entitityListbakup = entitiyList;

    if (value.text == 'none') {
      let obj = { ...editItem };
      obj.attribute = null;
      entitityListbakup[obj.item_index] = obj;
      setEditItem({ ...editItem, ...obj })
      setLinkedItem({});
      setEntityList([...entitityListbakup]);
    } else {
      let obj = { ...editItem };
      selectedItem.categorey = 'Value';
      obj.attribute = [];
      obj.attribute.push(selectedItem)
      entitityListbakup[obj.item_index] = obj;
      setEditItem({ ...editItem, ...obj })
      setLinkedItem(value);
      // entitityListbakup.splice(value.item_index, 1);
      setEntityList([...entitityListbakup]);
    }
  }

  const unlinkfn = (e) => {
    e.preventDefault();
    let entitityListbakup = entitiyList;
    let modifiedEditItem = { ...editItem };
    modifiedEditItem.attribute = null;
    entitityListbakup[modifiedEditItem.item_index] = modifiedEditItem;
    setEntityList([...entitityListbakup]);
    EditEntities(e);
  }

  const renderLinkField = () => {

    // console.log('entitiyList:::', entitiyList);
    // console.log('edititem render::', editItem)

    /*

    if (editItem.attribute && editItem.attribute.length && linkedItem && linkedItem.text) {
      let newList = [...entitiyList];

      newList.splice(editItem.item_index, 1);

      newList.unshift(unlinkobj);
      let defaultEntities = {
        options: newList,
      };

      return <Autocomplete
        {...defaultEntities}
        selectOnFocus
        value={linkedItem.text}
        getOptionLabel={getOptionEntities}
        onChange={(event, val) => { linkEntityChange(event, val) }}
        renderInput={(params) => <TextField {...params} label="Link Entity" margin="normal" />}
      />
    } else {

      let newList = [...entitiyList];
      newList.splice(editItem.item_index, 1);

      const defaultEntities = {
        options: newList,
      };

      if (newList.length) {
        return <Autocomplete
          {...defaultEntities}
          selectOnFocus
          value={""}
          getOptionLabel={getOptionEntities}
          onChange={(event, val) => { linkEntityChange(event, val) }}
          renderInput={(params) => <TextField {...params} label="Link Entity" margin="normal" />}
        />
      }


    }
    */



    let newList = [...entitiyList];
    newList.splice(editItem.item_index, 1);

    const defaultEntities = {
      options: newList,
    };

    let ddValue = (editItem.attribute) ? editItem.attribute[0].text : '';

    //console.log('newList.length::', newList.length)


    if (newList.length == 0) {
      return <Autocomplete
        {...defaultEntities}
        selectOnFocus
        debug={true}
        //disabled={true}
        value={ddValue}
        getOptionLabel={getOptionEntities}
        onChange={(event, val) => { linkEntityChange(event, val) }}
        renderInput={(params) => <TextField {...params} label="Link Entity" margin="normal" />}
      />
    } else {
      if (newList.length) {
        return <Autocomplete
          {...defaultEntities}
          selectOnFocus
          debug={true}
          value={ddValue}
          getOptionLabel={getOptionEntities}
          onChange={(event, val) => { linkEntityChange(event, val) }}
          renderInput={(params) => <TextField {...params} label="Link Entity" margin="normal" />}
        />
      }
    }



  }

  // console.log('editItem::', editItem);
  // console.log('entitylist::', entitiyList);


  return (
    <div>
      {/* <Popover
        id={id}
        open={props.openpopover}
        anchorEl={props.anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography className={classes.typography}>
          {showform && <section className="mt-3">
            <form className={""} noValidate autoComplete="off">
              <TextField
                name="entity"
                label="term"
                value={editItem.term}
                disabled
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />

              {editItem.category && <Autocomplete
                {...defaultProps}
                selectOnFocus
                value={editItem.category}
                getOptionLabel={getOptionLabel}
                onChange={(event, val) => { categoryChange(event, val) }}
                renderInput={(params) => <TextField {...params} label="select category" margin="normal" />}
              />}

              {renderLinkField()}

              <div className="text-right mt-1">
                <button className="btn btn-primary-blue btn-rounded" onClick={(e) => EditEntities(e)}>SUBMIT</button>
              </div>
            </form>
          </section>}
        </Typography>
      </Popover> */}





      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.openpopover}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          EDIT ENTITY
        </DialogTitle>
        <DialogContent dividers>

          {showform && <section className="mt-3" style={{ position: 'relative' }}>
            {editItem.attribute && editItem.attribute.length && <div className="unlinkentity" onClick={(e) => unlinkfn(e)}>Unlink</div>}
            <form className={""} noValidate autoComplete="off">
              <TextField
                name="entity"
                label="term"
                value={editItem.text}
                disabled
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />

              {editItem.categorey && <Autocomplete
                {...defaultProps}
                selectOnFocus
                debug={true}
                value={editItem.categorey}
                getOptionLabel={getOptionLabel}
                onChange={(event, val) => { categoryChange(event, val) }}
                renderInput={(params) => <TextField {...params} label="select category" margin="normal" />}
              />}

              {renderLinkField()}

              <div className="text-right mt-1">
                <button className="btn btn-primary-blue btn-rounded" onClick={(e) => EditEntities(e)}>SUBMIT</button>
              </div>
            </form>
          </section>}


        </DialogContent>
      </Dialog>

    </div>
  );
}