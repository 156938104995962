import React, { useEffect, useState, useCallback, useContext, useMemo } from "react";
import Materialtable from '../../../globalcomponents/materialtable';
import { apiCall } from '../../../common/apiconfig';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import { oldgenericApi } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { checkPermission } from '../../../common/utilis';

function SiteUsers(props) {
  let isAddUser = checkPermission('S_Site_Users_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');

  useEffect(() => {
    getApiData(valSearch);
    props.UpdateFormField({ name: "hidestudytab", value: false })
    props.UpdateFormField({ name: "portaltoken", value: true })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, []);
  useEffect(() => {
    getApiData(valSearch);
  }, [props.formValues.autostudy.studyId]);
  async function getApiData(searchKey) {
    //console.log(searchKey, "search")
    let requestBody = {
      query: `query {
        getSponsorAccountUserSearch(sponsorAccountId: "${props.formValues.autosite.sponsorAccountId}", searchStr:"${searchKey}") {
          sponsorAccountUserId
          sponsorAccountId
          SponsorAccountName
          firstName
          middleName
          lastName
          email
          phone
          role
          position
          language
          invitedBy
          invitedDate
          activated
          primaryContactInd  
          createdBy
          createdTs
          modifiedBy
          modifiedTs
                }
              }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await oldgenericApi('getSponsorAccountUserSearch', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error site users fetch!')

    } else {
      if (res.data.getSponsorAccountUserSearch.length) {
        setData(res.data.getSponsorAccountUserSearch)
      } else {
        setData([])
      }
    }
  }
  const handlePageClick = (offset) => {
    setoffset(offset)
  }
  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = (event) => {
    event.preventDefault();
    if (valSearch.length) {
      getApiData(valSearch);
      setoffset(0)
    } else {
      getApiData('');
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    getApiData('');
    setValSearch("")
  }
  const handleRowClick = async (event, rowData) => {
    event.preventDefault()
    await props.UpdateEntireForm(rowData);
    if(localStorage.getItem('whichApp')==="Site"){
      props.history.push('/site/users/details')
    }else{
      props.history.push('/app/users/details')
    }
   
  }
  const handleClick = async (event) => {
    event.preventDefault();
    if(localStorage.getItem('whichApp')==="Site"){
      props.history.push('/site/users/add')
    }else{
      props.history.push('/app/users/add')
    }
  }
  const Columns = [
    {
      title: 'ID', field: 'sponsorAccountUserId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Name', field: 'firstName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500
      },
      render: rowData => rowData.firstName + ' ' + rowData.lastName
    },
    { title: 'Role', field: 'role' },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' },
  ]
  //console.log(data, "data")
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">Users</h1>
        {isAddUser === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
              <i className="fa fa-plus-circle mr-1"></i> ADD USER</button>
          </div> : ""}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card mb-0">
          <div className="hekmaGridBox">
            <div className="col-xs-12 col-sm-12 col-md-5">
              <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                <HekmaTextSearchField
                  name="accountgridsearch"
                  // label="Search Account"
                  placeholder="Search Users"
                  value={valSearch}
                  handleChange={searchHandle}
                  className="gridSearchField"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                        X
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div>
            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteUsers));


