import React, { useEffect, useState } from "react";
import Materialtable from '../../../globalcomponents/materialtable';
import { apiCall } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paginationn } from '../../../common/pagination'
import { appConfig, Dropdowns } from "../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { genericApi } from '../../../common/apiconfig';
import { HekmaTextSearchField } from '../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import { checkPermission } from '../../../common/utilis';

function AddUsers(props) {
  //console.log(props, "props")
  let isAddContact = checkPermission('S_Site_Users_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [selectedUsersobj, setSelectedUsersObj] = useState({})
  let [currentpage, setCurrentPage] = useState(1);
  let [valSearch, setValSearch] = useState('');
  let [emptyObjErr, setEmptyObjErr] = useState("")

  async function fetchData(searchKey) {
    let requestBody = {
      query: `query {
        getSponsorAccountUserSearch(sponsorAccountId: "${props.formValues.autosite.sponsorAccountId}", searchStr:"${searchKey}") {
          sponsorAccountUserId
          sponsorAccountId
          SponsorAccountName
          firstName
          lastName
          email
          phone
          role
          position
          language
          invitedBy
          invitedDate
          activated
          primaryContactInd  
          createdBy
          createdTs
          modifiedBy
          modifiedTs
                }
              }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getSponsorAccountUserSearch', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error site users fetch!')

    } else {
      if (res.data.getSponsorAccountUserSearch.length) {
        //console.log(res.data.getSponsorAccountUserSearch, props.formValues.studyusers, "users")
        if (props.formValues.studyusers.length == 0) {
          const newFilteredContactArr = res.data.getSponsorAccountUserSearch.map(v => ({ ...v, role: "" }))
          setData(newFilteredContactArr)
        } else {
          var contactsData = props.formValues.studyusers
          var contacts = res.data.getSponsorAccountUserSearch
          contacts = contacts.filter(function (item) {
            for (var i = 0, len = contactsData.length; i < len; i++) {
              if (contactsData[i].contactId == item.sponsorAccountUserId) {
                return false;
              }
            }
            return true;
          });
          const newFilteredContactArr = contacts.map(v => ({ ...v, role: "" }))
          setData(newFilteredContactArr)
        }
      } else {
        setData([])
      }
    }
  }
  useEffect(() => {
    fetchData(valSearch);
  }, []);


  const handlePageClick = (offset, pageNum) => {
    setCurrentPage(pageNum);
    setoffset(offset)
  }
  const handleCancel = (e) => {
    e.preventDefault()
    props.history.goBack()
  }
  const handleClick = async (event) => {
    event.preventDefault();
    props.history.push('/site/users/add')
    props.UpdateFormField({ name: "portaltoken", value: false })
    // props.UpdateFormField({ name: "contactaddformclear", value: true })
    // props.UpdateFormField({ name: "sponsorAccountId", value: props.formValues.siteId })
    // props.UpdateFormField({ name: "accountName", value: props.formValues.siteName })
    // props.history.push('/account/AddContact');
  }

  const handleRowClick = async (event, rowData) => {
    event.preventDefault()
    // await props.UpdateEntireForm(rowData);
    // props.history.push('/account/contact/view');
  }
  const rowsSelected = (rows) => {
    setSelectedUsersObj({ ...selectedUsersobj, [currentpage]: rows });
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
      const chkObj = o => Object.values(o).every(a => a.length === 0)
      if (chkObj(selectedUsersobj)) {
        setEmptyObjErr('Select atleast one user')
      } else if (Object.keys(selectedUsersobj).length == 0 && selectedUsersobj.constructor === Object) {
        setEmptyObjErr('Select atleast one user')
      } else {
        setEmptyObjErr("")
      //this to get selected users arr from different pages obj
      let selectedUsers = []
      for (let i in selectedUsersobj) {
        if (selectedUsersobj[i].length > 0) {
          for (let j of selectedUsersobj[i]) {
            selectedUsers = [...selectedUsers, j]
          }
        }
      }
      //console.log(selectedUsers, "selectedUsers")
      //this is to get updated data from data
      var newSelected = []
      for (var i = 0; i < selectedUsers.length; i++) {
        for (var j = 0; j < data.length; j++) {
          if (selectedUsers[i].sponsorAccountUserId == data[j].sponsorAccountUserId) {
            newSelected.push(data[j])
          }
        }
      }
      //console.log(data, newSelected, "new")
      //to check role is empty or not
      let obj = newSelected.find(item => item.role === "");
      if (obj != undefined) {
        setEmptyObjErr("Enter role for " + obj.firstName + ' ' + obj.lastName)
      } else {
        setEmptyObjErr('')
        let selectedUserss = '';
        newSelected.map(contact => {
          selectedUserss += `{ studyId: "${props.formValues.autostudy.studyId}",siteId:"${contact.sponsorAccountId}", contactId: "${contact.sponsorAccountUserId}",role:"${contact.role.name == undefined ? "" : contact.role.name}",primaryContactInd:"N"}`;
        });
        let usersSelected = selectedUserss.replace(/}\s*{/g, '},{');

        let requestBody = {
          query: `mutation {
      addStudySiteMultiContact(studySiteContactList: [${usersSelected}]) {
        code
        type
        message
        attributes
      }
    }`
        };
        requestBody = JSON.stringify(requestBody);
        setProgress(true);
        let res = await genericApi('addStudySiteMultiContact', requestBody);
        setProgress(false);
        if (!res) {
          // Error handling
          //console.log('Error on response material grid!')

        } else {
          //console.log('added')
          props.history.goBack()
        }
      }
    }
  }
  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = (event) => {
    event.preventDefault();
    if (valSearch.length) {
      fetchData(valSearch);
      setoffset(0)
    } else {
      fetchData('');
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    fetchData('');
    setValSearch("")
  }
  const Columns = [
    {
      title: 'Name', field: 'firstName',
      // cellStyle: {
      //   color: '#244271',
      //   fontWeight: 500
      // },
      render: rowData => rowData.firstName + ' ' + rowData.lastName
    },
    { title: 'Position', field: 'position' },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' },
    {
      title: 'Role', field: 'role', render: rowData => <Autocomplete
        id="controlled-demo"
        disableClearable
        options={Dropdowns.Study_Site_Primary_Contact_Role}
        getOptionLabel={option => option.name ? option.name : ""}
        value={rowData.role}
        onChange={(e, value) => selectRole(e, value, rowData, data)}
        renderInput={(params) => <TextField {...params} margin="none" fullWidth />}
      />
    },
  ]
  //console.log(data)
  const selectRole = (e, value, rowData, data) => {
    e.preventDefault()
    setData(data.map(item => item.sponsorAccountUserId === rowData.sponsorAccountUserId ? { ...item, role: value } : item))
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex justify-content-end mt-4">
        <div className="secondary-header mr-auto">Add Users</div>
        {isAddContact === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
              <i className="fa fa-plus-circle mr-1"></i> ADD NEW USER</button>
          </div> : ""}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
            <div className="col-xs-12 col-sm-12 col-md-5">
              <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                <HekmaTextSearchField
                  name="accountgridsearch"
                  // label="Search Account"
                  placeholder="Search Users"
                  value={valSearch}
                  handleChange={searchHandle}
                  className="gridSearchField"

                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" onClick={searchSubmit} style={{cursor:"pointer"}}>
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                        X
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div>
             <Materialtable
              columns={Columns}
              data={data}
             handleRowClick={handleRowClick}
              rowsSelected={rowsSelected}
              selection={true}
            />
          </div>
          {<span className="multiselecterrmsg">{emptyObjErr}</span>}
          {data.length > 0 &&
            <div className="buttonContainer ml-auto">
              <button className="btn btn-primary-blue btn-rounded btn-small mr-1" onClick={handleSubmit}>Save</button>&nbsp;
            <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
            </div>
          }
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUsers));


