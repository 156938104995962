import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavSubMenu } from '../../navMenu';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import CreateTemplate from './createtemplate'
import Themes from './themes'
import SavedTemplates from './savedtemplates'
import SingleLayout from './singleLayout'

const menus = [
  {
    to: "/site/adminfeatures/emailtemplates",
    text: "Create Template"
  },
  {
    to: "/site/adminfeatures/emailtemplates/savedtemplates",
    text: "Saved Templates"
  }
]
const EmailTemplates = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <NavSubMenu menus={menus} />
        </div>
      </div>
      <div className="">
        <Route exact path="/site/adminfeatures/emailtemplates" component={CreateTemplate} />
        {/* <Route exact path="/site/adminfeatures/emailtemplates/layout" component={SingleLayout} /> */}
        <Route path='/site/adminfeatures/emailtemplates/savedtemplates' component={SavedTemplates} />

      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailTemplates));




