import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut, getCountry, getState } from '../../../common/apiconfig';
import FormHoc from '../../../sponsorApp/components/hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew, AutoOptionsMultiple } from '../../..//sponsorApp/components/formElements';
import { Dropdowns, regExpData } from '../../../common/constants';
import { connect } from 'react-redux';


let accountObjFields = {
  accountName: '',
  accountType: '',
  status: '',
  phone: '',
  email: '',
  address1: '',
  city: '',
  country: '',
  state: '',
  zip: ''
}


const AddHospital = (propsobj) => {
  console.log('fileName::', propsobj.formValues);
  let countryObj = {
    value: 'United States',
    label: 'United States',
    error: false,
    helperText: '',
  }
  let stateObj = {
    value: '',
    label: '',
    error: false,
    helperText: '',
  }
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [country, setCountry] = useState([]);
  let [state, setState] = useState([])
  let [countryField, setCountryField] = useState(countryObj);
  let [stateField, setStateField] = useState(stateObj);
  let [phoneCheck, setPhoneError] = useState('')
  let [options, setOptions] = useState([])
  let [parentAccount, setParentAccount] = useState('')


  useEffect(() => {
    {
      propsobj.formValues.parentAccountSelection == false &&
        propsobj.clearForm();
    }
    loadCountry();
    loadStates('US');// US country code
    propsobj.UpdateFormField({ value: 'US', name: "countryCode" })



    return () => {
      let errorFieldsObj = {};
      let errorTextObj = {};
      for (var i in accountObjFields) {
        errorFieldsObj[i] = false;
        errorTextObj[i] = ""
      }
      propsobj.updateEntireErrorObj(errorFieldsObj, errorTextObj);
    };

    
  }, []);

  useEffect(() => {
    fetchAccountsforParent()
  }, [propsobj.formValues.accountType])

  const fetchAccountsforParent =async () => {
    const getAllAccounts = `
    sponsorAccountId
    accountName
    parentAccountId
    accountType
    status
    address1
     address2
    city
    state
    country
    zip
    email
    phone
  `;
    const parentAccount = "*";
    const type = 'B';
    const accountType = propsobj.formValues.accountType || "*"
    let requestBody = {
      query: `query {
          getAccountTypeSearch(parentAccountId: "${parentAccount}",searchStr: "*", type:"${type}",accountType: "${accountType}"){
                    ${getAllAccounts}
              }
          }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await apiCall('getAccountTypeSearch', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error!')

    } else {
      if (res.data.getAccountTypeSearch.length) {
        setOptions(res.data.getAccountTypeSearch)
      } else {
        setOptions([])
      }
    }
  }

  const autoPropstoCountry = {
    'options': country,
    propsobj,
    keys: ['value'],
    error: countryField.error,
    helperText: countryField.helperText
  }

  const autoPropstoState = {
    'options': state,
    propsobj,
    keys: ['value'],
    error: stateField.error,
    helperText: stateField.helperText
  }

  const autoPropstoaccount = {
    'options': options,
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['accountName']
  }

  async function loadCountry() {
    setProgress(true);
    let countries = await getCountry();
    setProgress(false);
    setCountry(countries);
  }

  async function loadStates(id) {
    setProgress(true);
    let states = await getState(id);
    setProgress(false);
    setState(states);
  }

  const handleCountry = async (obj) => {
    let stateObj = {
      value: '',
      label: '',
      error: false,
      helperText: '',
    }
    setStateField(stateObj);


    if (obj === null) {
      let countryObj = {
        value: '',
        label: '',
        error: false,
        helperText: '',
      }
      setCountryField(countryObj);
      propsobj.UpdateFormField({ value: "", name: "countryCode" })
      return;
    }

    if (obj) {
      if (!obj.selected) {
        let countryObj = {
          value: '',
          label: '',
          error: obj.flag,
          helperText: obj.text,
        }
        setCountryField(countryObj);
        propsobj.UpdateFormField({ value: '', name: obj.name })
        propsobj.UpdateFormField({ value: "", name: "countryCode" })
        return;
      } else {
        let countryObj = {
          value: obj.value,
          label: obj.label,
          error: obj.flag,
          helperText: obj.text,
        }
        setCountryField(countryObj);
        propsobj.UpdateFormField({ value: obj.selected.id, name: "countryCode" })
        propsobj.UpdateFormField({ value: obj.value, name: obj.name })
        setProgress(true);
        let states = await getState(obj.selected.id);
        setProgress(false);
        setState(states);
      }
    }
  }

  const handleState = async (obj) => {
    if (obj === null) {
      let stateObj = {
        value: '',
        label: '',
        error: false,
        helperText: '',
      }
      setStateField(stateObj);
      return;
    }

    if (obj) {
      if (!obj.selected) {
        let stateObj = {
          value: '',
          label: '',
          error: obj.flag,
          helperText: obj.text,
        }
        setStateField(stateObj);
        propsobj.UpdateFormField({ value: '', name: obj.name })
        return;
      } else {
        let stateObj = {
          value: obj.value,
          label: obj.label,
          error: obj.flag,
          helperText: obj.text,
        }
        setStateField(stateObj);
        propsobj.UpdateFormField({ value: obj.value, name: obj.name })
      }
    }
  }

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsToStatus = {
    default: {
      value: 'Active',
      label: 'Active',
    },
    'options': Dropdowns.Account_Status,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropstoaccType = {
    ...autoPropsToStatus,
    'options': [ {
        label: "Site",
        value: "Site"
    },],
    default: null,
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('addAccountForm', event);
    let phoneCheck = regExpData.phoneReg.test(propsobj.formValues.phone)
    if (propsobj.formValues.phone != "" && phoneCheck == false) {
      setPhoneError("Invalid number")
    } else {
      setPhoneError("")
      phoneCheck = true
    }
    if (setGo && phoneCheck) {
      setProgress(true);
      let form = propsobj.formValues;
      let requestBody = `
      mutation {
        createsponsorAccount(accountName:"${form.accountName}",parentAccountId:"${form.parentAccountId}",accountType:"${form.accountType}",status:"${form.status == "" ? "Active" : form.status}",address1:"${form.address1}"
        ,address2:"${form.address2}",countryCode:"${form.countryCode}",city:"${form.city}",state:"${form.state}",zip:"${form.zip}",email:"${form.email}",phone:"${form.phone}",website:"${form.website}",duns:"${form.duns}")
      }`;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('createsponsorAccount', requestBody, form);
      setProgress(false);

      if (res && res.data.createsponsorAccount) {
        // alert('contact added! you will receive mail. check it to activate the account');
        // if (propsobj.formValues.studyaccountseacrh) {
        //   propsobj.history.push('/account/addStudy')
        //   propsobj.UpdateFormField({ name: "studyaccountseacrh", value: false })
        // } else {
        //   propsobj.history.push('/account/accountDetails');
        //   propsobj.UpdateFormField({ value: res.data.createsponsorAccount, name: "sponsorAccountId" })
        //   propsobj.UpdateFormField({ name: "accountTypeSearch", value: "*" })
        //   propsobj.UpdateFormField({ value: false, name: "parentAccountSelection" })
        // }
        propsobj.showHospital()
      } else {
        console.log('Error on response add account file!')
        //props.history.push('/account/updateContact'); // temp enabling to check update sponsor
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    propsobj.history.goBack();
    propsobj.UpdateFormField({ name: "accountTypeSearch", value: "*" })
  }
  // const parentAccountSearch = (event) => {
  //   event.preventDefault();
  //   propsobj.UpdateFormField({ value: true, name: "parentAccountSelection" })
  //   propsobj.UpdateFormField({ name: "accountTypeSearch", value: propsobj.formValues.accountType })
  //   propsobj.history.push('/account/search');
  // };

  const getParentAccount = (data) => {
    console.log(data, "parentacc")
    propsobj.UpdateFormField({name:"parentAccountId",value:data.selected.sponsorAccountId})
    setParentAccount(data.selected)
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}

        />
      </div>}

      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">ADD ACCOUNT</h1>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="addAccountForm" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <div className="panel-primary-wrap">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-page-header">ACCOUNT INFORMATION</div>
                      <div className="inputBox">
                        <InputField {...textProps} name="accountName" label="Account Name" must={'true'} type="text" />

                        <AutoOptions {...autoPropstoaccType} name="accountType" label="Account Type" keyName='value' must={'true'} />

                        {/* <InputField {...textProps} name="parentAccountId" label="Parent Account" must={propsobj.formValues.accountType.length && propsobj.formValues.accountType === "Sponsor" ? "true" : "false"} type="text" />

                        <div className="searchButtonWidth">
                          <IconButton aria-label="Search" className="searchicon"
                            onClick={parentAccountSearch}>
                            <SearchIcon />
                          </IconButton>
                        </div> */}
                        <AutoOptionsMultiple {...autoPropstoaccount} autoValue={parentAccount} name="parentAccount" label="Parent Account" keyName='accountName' must={propsobj.formValues.accountType.length && propsobj.formValues.accountType === "Sponsor" ? "true" : "false"} callback={getParentAccount} />
                        <AutoOptions {...autoPropsToStatus} name="status" label="Status" keyName='value' must={'true'} />


                        <InputField {...textProps} name="duns" label="DUNS#" must={'false'} type="text" />
                        <InputField {...textProps} name="website" label="Website" must={'false'} type="text" />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">ADDRESS INFORMATION</div>
                  <div className="inputBox">
                    <div style={{ position: "relative" }}>
                      <InputField {...textProps} name="phone" label="Phone" must={'false'} type="text" />
                      {phoneCheck.length > 0 && <span className="phoneerr">{phoneCheck}</span>}
                    </div>
                    <InputField {...textProps} name="email" label="Email" must={'true'} type="email" />
                    <InputField {...textProps} name="address1" label="Address Line 1" must={'true'} type="text" />
                    <InputField {...textProps} name="address2" label="Address Line 2" must={'false'} type="text" />
                    <InputField {...textProps} name="city" label="City" must={'true'} type="text" />

                    <AutoOptionsNew {...autoPropstoCountry} autoValue={countryField} name="country" label="Country" keyName='value' must={'true'} callback={handleCountry} />

                    <AutoOptionsNew {...autoPropstoState} autoValue={stateField} name="state" label="State" keyName='value' must={'true'} callback={handleState} />

                    <InputField {...textProps} name="zip" label="Zipcode" must={'true'} type="text" />
                  </div>
                </div>
              </div>
            </div>
            <div className="buttonContainer mr-auto mb-5">
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
            </div>
          </div>


        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddHospital));