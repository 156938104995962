// for delete cookie we need to call, setcookie method only but exdays should be 0
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function checkCookie(cname) {
    var user = getCookie(cname);
    if (user != "") {
        //alert("Welcome again " + user);
        //cookie is there
    } else {
        //cookie is not there
        // user = prompt("Please enter your name:", "");
        // if (user != "" && user != null) {
        // }
    }
}

export const jwt= {
    setCookie,
    getCookie,
    checkCookie
}