import React, { useEffect, useState, useCallback, useContext, useMemo } from "react";
import MaterialTable from 'material-table';
import { apiCall } from '../../../common/apiconfig';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Paginationn } from '../../../common/pagination'
import { appConfig } from "../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import Loadable from 'react-loadable';

function Vendors(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');

  useEffect(() => {
    async function getApiData() {
    }
    getApiData();
  }, []);


  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">Vendors</h1>
      </div>
    </>
  )

}
export default Vendors;


