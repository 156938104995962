import React from 'react'
import Tunnel from '../siteSrc/components/site/pir/dashboard/3dTunnel'
import DragandDrop from './draganddrop';
import Loader from '../common/loader'

const FunnelOrder=(props)=>{
  let {funnelCount,progress,funnelData,handleSubmitFunnelOrder,setShowFunnel,setOrder,setInsightsData}=props
  return(
    <>
     <div className="row mt-0">
          <div className="col-md-12 mb-2 justify-content-end" >
            <h1 className="mr-auto primary-header" style={{ float: "left" }}>{"Patients Count:" + funnelCount}</h1>

            <button className="btn btn-small btn-primary-blue btn-shadow btn-rounded" onClick={(e) => { e.preventDefault(); setShowFunnel(false); setOrder("*");   setInsightsData("") }} style={{ float: "right", marginTop: "5px" }}>
              BACK</button>
          </div>
          <div className="col-md-12">
            {progress ? <Loader progress={progress} /> :
              <div className="row">
                <div className="col-md-6">
                  {funnelData && <DragandDrop data={funnelData} header={"Criteria"} subheader={"(Reorder the criteria by drag and drop)"} handleSubmit={handleSubmitFunnelOrder}  colors={ ['#87c7e3','#cce6f2','#faafdb','#c8a0d2','#ffe384','#89c5e5','#a1d9eb','#68d1c2','#ffa279','#abe87b','#87c7e3','#cce6f2','#faafdb','#c8a0d2','#ffe384','#89c5e5','#a1d9eb','#68d1c2','#ffa279','#abe87b','#87c7e3','#cce6f2','#faafdb','#c8a0d2','#ffe384','#89c5e5','#a1d9eb','#68d1c2','#ffa279','#abe87b']}/>}

                </div>
                <div className="col-md-6">
                  <div class="secondary-header mr-auto">
                    Trial Match
                  </div>
                  <Tunnel header={`<b>Total Patients </b> ` + `${funnelCount}`} data={funnelData} onClickFunnel={(e) => e.preventDefault()} labels={false}/>
                </div>
              </div>
            }
          </div>
        </div>
    </>
  )
}
export default FunnelOrder