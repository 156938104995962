import React from 'react';
import Users from '../../siteSrc/components/site/siteusers';

const UsersPage =()=>{
  return(
    <>
    <Users appName={localStorage.getItem('whichApp')}/>
    </>
  )
}
export default UsersPage