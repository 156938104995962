import React from "react";
import {
  ElementsConsumer, CardElement, useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { stripeApi, genericApi } from '../../common/apiconfig';
import CardSection from "./cardsection";
import { useSelector,useDispatch } from 'react-redux'
import Alert from '../../../globalcomponents/basicAlert'
import moment from 'moment'
import Logo from '../../../img/Hekmalogo.svg'
import Loader from '../../../common/loader'
const CheckoutForm = (props) => {
  const [clientSecret, setClientSecret] = React.useState('');
  const [error, setError] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  let [orderId,setOrderId]=React.useState("")
  let [progress, setProgress] = React.useState(false)
  let reduxstateobj = useSelector(state => state);
  const reduxdispatch = useDispatch()

  React.useEffect(async () => {
    var data = await stripeApi("createStripePaymentIntent", { "amount": reduxstateobj.totalprice }, "USD")
    var clientsecret = JSON.parse(data.message)
    setClientSecret(clientsecret.clientSecret)
  }, []);
  const createOrder = async () => {
    let cartdata = reduxstateobj.cartdata
    //result is a javascript array containing the groups grouped by nctId. 
    let result = {};
 

    //javascript array has a method foreach that enumerates keyvalue pairs. 
    cartdata.forEach(
      r => {
        //if an array index by the value of nctId is not found, instantiate it. 
        if (!result[r.nctId]) {
          //result gets a new index of the value at nctId. 
          result[r.nctId] = [];
        }
        //push that whole object from api_array into that list 
        result[r.nctId].push(r);
      }
    );
    let finalresult=[...Object.values(result)]
  for (let i = 0; i < finalresult.length; i++) {
      let data = ''
      finalresult[i].map(cart => {
        data += `{ siteId: "${cart.siteId}", siteName: "${cart.siteName}", city:"${cart.siteCity}", country:"${cart.siteCountry}",patientCount:"${cart.patientCount}",totalPrice:"${cart.totalPrice}",metrics:"${cart.metrics}",insights:"${cart.insights}",cartId:"${cart.cartId}" }`;
      });
      let siteOrderMetric = data.replace(/}\s*{/g, '},{');
      let requestBody = `
    mutation 
    {
      createOrder(accountId:"${localStorage.getItem('loginUserId')}", orderDate:"${moment(new Date()).format('YYYY-MM-DD')}",nctId:"${finalresult[i][0]["nctId"]}",siteOrderMetric:[${siteOrderMetric}]) {
        code
        type
        message
        attributes
      }  
    }
        `;

      requestBody = JSON.stringify({ query: requestBody });
      setProgress(true);
      let res = await genericApi('createOrder', requestBody);
      setProgress(false);
      if (res && res.data.createOrder.code === '200') {
        // alert('success')
        setOrderId(res.data.createOrder.attributes)
        reduxdispatch({ type: 'cartcount',  value: 0 }) 
        setOpen(true);   
      }
    }

  }
  const handleSubmit = async event => {
    event.preventDefault();
    const { stripe, elements } = props;
    if (!stripe || !elements) {
      return;
    }
    setProgress(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });
    setProgress(false);
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      // setProcessing(false);
    } else {
      setError(false);
      // setProcessing(false);
    
      createOrder()
      // props.history.push('/account/dashboard')
      // setSucceeded(true);
    }




    // const card = elements.getElement(CardElement);
    // const result = await stripe.createToken(card);
    // if (result.error) {
    //   console.log(result.error.message);
    // } else {
    //   console.log(result.token);
    // }

    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: card
    // })
    // if (error) {
    //   console.log(error, "error")
    // } else {
    //   console.log(paymentMethod, "pm")
    // }
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    props.goToDashboard()
  };
  return (
    <>
      <Loader progress={progress} />
      <Alert open={open} header="Order Confirmation" setOpen={setOpen} handleClose={handleClose} hideButtons={true}>
        <div className="row">
          <div className="col-md-3" style={{ textAlign: "end" }}><img src={Logo} alt="" /></div>
          <div className="col-md-9" style={{ fontSize: "21px" }}>
            <div style={{ color: "#d8469a", textAlign: "center", marginTop: "15px" }}><b>Thank You, Payment Successful with Order ID:{orderId}</b></div>
            <div style={{ fontSize: "12px", textAlign: "center", color: "#1e3b63" }}> confirmation will be send to your mail</div>
          </div>
        </div>

      </Alert>
      <form onSubmit={handleSubmit}>

        <CardSection />
        <button disabled={!props.stripe} className="btn-pay">
          PAY
        </button>
        {error && (
          <div className="card-error m-3" style={{ color: 'red' }} role="alert">
            {error}
          </div>
        )}
      </form>
    </>
  )
}



export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} goToDashboard={props.goToDashboard} />
      )}
    </ElementsConsumer>
  );
}
