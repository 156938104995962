/* eslint-disable no-use-before-define */

import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { ErrorLables } from '../common/lables';
import matchSorter from 'match-sorter';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags(props) {
  const [selectedFilm, setSelectedFilm] = useState([]);
  let autoValue = props.autoValue || null;
  const autoHandleChange = async (event, newValue) => {
      if (event) event.preventDefault();
      newValue = newValue === undefined ? value : newValue;
      if (newValue && newValue.inputValue) {// freesolo -> true case
          return {
              [props.keyName]: newValue.inputValue,
          };
      }

      let name = props.name;
      let value = '';
      let flag = false;
      let text = '';

      try {
          if (newValue === null) {// when you select nothing
              value = '';
              flag = true;
              text = ErrorLables[name]
          } else {
              value = newValue[props.keyName] === undefined ? newValue : newValue[props.keyName];
              flag = false;
              text = '';
          }
      } catch {
          value = '';
      }

      let obj = {
          name,
          value,
          selected: newValue,
          flag,
          text
      }

      // let errobj = {
      //     name,
      //     flag,
      //     text
      // }

      let isItMust = props.must;
      isItMust = (isItMust === 'true') ? true : false;
      if (isItMust) {
          props.propsobj.updateErrorObj(obj);
          props.callback(obj);
      } else {
          obj = {
              name,
              value,
              selected: newValue,
              flag: false,
              text: ''
          }
          props.callback(obj);
      }
  }

  const getOptionLabel = option => {
    // e.g value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    if (option && option.inputValue) {
      return option.inputValue;
    }
    return option && option[props.keyName]
  }
  const filterOptions = (options, { inputValue }) => {
    let keys = props.keys;
    return matchSorter(options, inputValue, { keys: keys })
  }
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={props.options}
      disableCloseOnSelect
      filterOptions={filterOptions}
      // onChange={autoHandleChange}
      onChange={(e, film) => {
        setSelectedFilm(film);
        props.callback(film);
        // props.propsobj.updateErrorObj({ name: "patientMultilist",flag:false,text:""})
      }}
      disableClearable
      debug={true}
      getOptionLabel={getOptionLabel}
      renderOption={(option, state) => {
        const selectFilmIndex = selectedFilm.findIndex(
          film => (film.firstName).toLowerCase() === "all"
        );
        if (selectFilmIndex > -1) {
          state.selected = true;
        }
        return (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={state.selected}
            />
            {option.firstName+' '+option.lastName}
          </React.Fragment>
        );
      }}
      // style={{ width: 500 }}
      renderInput={params => (
        <TextField
          {...params}
          // variant="outlined"
          error={props.propsobj.state.formerrobj[props.name]}
          helperText={props.propsobj.state.formerrtextObj[props.name]}
          name={props.name}
          label={props.label}
          inputProps={{
            ...params.inputProps,
            datamust: props.must
          }}
          InputLabelProps={{
            className: (props.must === 'true') ? 'must' : '',
            //shrink: true
          }}
        />
      )}
    />
  );
}
