import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

//not  in use
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    borderRadius: "5px"
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: "96%",
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
}));

const CommonModal = ({ open, onClose, header, children }) => {
  //console.log(children)
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const handleClose = () => {
    // onClose()
  };
  open = true

  const minimize=e=>{
    e.preventDefault();
    alert('min')
  }
  const maximize=e=>{
    e.preventDefault();
    alert('max')
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h6 id="modal-title" className="filehistorycss">
            Header
            <div className="fr">
            <span onClick={(e)=>minimize(e)}>minimize</span><span  onClick={(e)=>maximize(e)}>Max</span>  
          </div>
          </h6>
         
          <div id="modal-description">
            <div>body</div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CommonModal