import React, { useEffect, useState, useCallback, useContext, useMemo } from "react";
import Materialtable from '../../../globalcomponents/materialtable';
import { apiCall } from '../../../common/apiconfig';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import { genericApi } from '../../../common/apiconfig';
import Radio from '@material-ui/core/Radio';
import { withRouter } from 'react-router-dom';
import { checkPermission } from '../../../common/utilis';

function SiteUsers(props) {
  let isAddContact = checkPermission('S_Study_Site_Users_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');

  useEffect(() => {
    getApiData();
  }, []);
  useEffect(() => {
    getApiData();
  }, [props.formValues.autostudy.studyId]);
  async function getApiData() {
    let requestBody = {
      query: `query {
        getStudySiteContact(studyId: "${props.formValues.autostudy.studyId}", siteId: "${props.formValues.autosite.sponsorAccountId}") {
          studyId
          siteId
          contactId
          contactName
          role
          primaryContactInd
          email
          phone
          status
          createdBy
          createdTs
          modifiedBy
          modifiedTs
                }
              }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getStudySiteContact', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error site users fetch!')

    } else {
      if (res.data.getStudySiteContact.length) {
        setData(res.data.getStudySiteContact)
        props.UpdateFormField({ name: "studyusers", value: res.data.getStudySiteContact })
      } else {
        setData([])
        props.UpdateFormField({ name: "studyusers", value: [] })
      }
    }
  }
  const handlePageClick = (offset) => {
    setoffset(offset)
  }
  const handleClick = async (event) => {
    event.preventDefault();
    props.history.push('/site/study/sitestudy/addusers');
  }
  const Columns = [

    {
      title: 'User Name', field: 'contactName',
      // cellStyle: {
      //   color: '#244271',
      //   fontWeight: 500
      // },
    },
    { title: 'Role', field: 'role' },
    {
      title: 'Primary Contact', field: 'primaryContactInd',
      render: data => data.primaryContactInd == "Y" ? <Radio
        checked={true}
        // onClick={(e) => { updatePrimaryContact(e, rowData) }}
        value="Y"
        color="primary"
        name="radio-button-demo"
        style={{ textAlign: "center" }}
      /> : "---"
    },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' }
  ]
  const handleRowClick = async (event, rowData) => {
    //console.log(rowData)

  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}


      <div className="d-flex mt-4">
        <div class="secondary-header mr-auto">Site Users</div>
        {isAddContact === "1" ?
          <div className="userdetailscss">
            <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
              <i className="fa fa-plus-circle mr-1"></i> ADD USERS</button>
          </div> : ""}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox studyper">
            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteUsers));


