import React, { useEffect, useState } from "react";
import Materialtable from '../../../../globalcomponents/materialtable';
import { apiCall } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Paginationn } from '../../../common/pagination'
import { appConfig } from "../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkPermission } from '../../../common/utils';

function IECriteriaGrid(props) {
  let isAddIE = checkPermission('IE_Criteria_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);


  useEffect(() => {
    fetchData("B");
  }, []);
  async function fetchData(type) {

    let requestBody = {
      query: `query {
        getStudyEvent(studyId:"${props.formValues.studyId}", eventType:"${type}") {
        studyId
        eventId
        eventType
        type
        description
        status
        effectiveDate
        createdBy
        createdTs
        modifiedBy
        modifiedTs
        }
        }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await apiCall('getStudyEvent', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error on response material grid!')

    } else {
      if (res.data.getStudyEvent.length) {
        setData(res.data.getStudyEvent)
      }
    }
  }
  const getIE = (e, type) => {
    e.preventDefault()
    fetchData(type)
    // setData(data.filter((item,i)=>item.eventType==type))
  }
  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    props.history.push('/account/studyDetails/IEcriteria/Add');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    var data = { ...rowData, "IE_type": rowData.type, "IE_description": rowData.description, "IE_status": rowData.status, "IE_effectiveDate": rowData.effectiveDate }
    await props.UpdateEntireForm(data);
    props.history.push('/account/studyDetails/IEcriteria/View');
  }
  String.prototype.limit = function (length) {
    return this.length > length ? (this.substring(0, length) + '...') : this;
  }
  const Columns = [
    {
      title: 'Type', field: 'type',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Description', field: 'description',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        display: "block",
        whiteSpace: "nowrap",
        // width: "28em",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      render: rowData => rowData.description == ("" || null || undefined) ? '---' :rowData.description.limit(50)
    },
    { title: 'Status', field: 'status' },
    { title: 'Effective Date', field: 'effectiveDate' },
  ]


  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex mt-4 justify-content-end">
        <div className="mr-auto secondary-header">Inclusion/Exclusion Criteria</div>
        {isAddIE === "1" ? <div className="">
          <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
            <i className="fa fa-plus-circle mr-1"></i> ADD IE CRITERIA</button>
        </div> : ""}

      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="">
            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IECriteriaGrid));


