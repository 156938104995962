import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';

function Title(props) {
    return (
        <>
            <div className="pirtitle">{props.title}</div>
        </>
    )

}

const mapStateToProps = (state) => {
    return {
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(Title);


