import React, { useEffect, useState, useReducer, useContext } from "react";
import Header from './header/header';
import axios from 'axios'
import { Dummydata } from './dummyjson'
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textAlign: "center"
  },
  '& > *': {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  panel: {
    display: "flex",
    padding: "8px 24px 10px"
  },
  exclusion: {
    color: "red",
    border: "1px solid red"
  },
  inclusion: {
    color: "green",
    border: "1px solid green"
  }
}));

const IEApicall = (props) => {
  const [inclusion, setInclusion] = useState('')
  const [exclusion, setExclusion] = useState('')
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = useState({})
  const [show, setShow] = useState(false)
  const [uniq_ent_type_data, setuniq_ent_type_data] = useState([])
  const [ex_data, setex_data] = useState([])
  const [in_data, setin_data] = useState([])
  const [progress,setProgress]=useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClick = (e) => {
    e.preventDefault()
    setShow(true)
    // const requestOptions = {
    //   method: 'POST',
    //   // mode: "cors", // or without this line
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ inclusion, exclusion })
    // };
    // axios.post(`http://ec2-54-193-40-169.us-west-1.compute.amazonaws.com:8125/api`, requestOptions)
    //   .then(response => response.json())
    //   .then(data => //console.log(data, "IE"));

    //using fetch
    setProgress(true)
    const requestOptions1 = {
      method: 'POST',
      // mode: "cors", // or without this line
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ inclusion, exclusion })
    };
    fetch('http://ec2-54-215-198-140.us-west-1.compute.amazonaws.com:8125/api', requestOptions1)
      .then(response => response.json())
      .then((data) => {
        setProgress(false)
        setex_data(data.response.exclude_cond.result)
        setin_data(data.response.include_cond.result)

        var ex_ent_type = data.response.exclude_cond.result.map(item => item.ent_type)
        var in_ent_type = data.response.include_cond.result.map(item => item.ent_type)
        var ent_type_data = [...ex_ent_type, ...in_ent_type]
        setuniq_ent_type_data([...new Set(ent_type_data)]);
      }
      );

  }
  //response



  const handleSelect = (e, data) => {
    e.preventDefault()
    setData(data)
  }

  return (
    <>
     {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div id="">
        <div className="contentBody" style={{ width: "98%" }}>
          {/* <Header /> */}
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-10" style={{ fontSize: "20px", textAlign: "center", color: "teal" }}>
                  <h2>Patient Identification</h2>
                </div><div className="col-md-2" style={{ textAlign: "right",paddingTop:"15px" }}>
                  {/* <button className="btn btn-primary-blue btn-rounded"> */}
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSc6LdSbM7dQBFsFmVc4yBQywcD_X1eSBEpmT86HnJvAJgfUdg/viewform?vc=0&c=0&w=1" target="_blank"><i class="fa fa-info mr-1" aria-hidden="true"></i> Feedback</a>
                </div></div><hr /></div>
            <div className="col-md-5" style={{ marginLeft: "15px" }}>
              <div className="row">
                <div className="col-md-12">
                  <label>Inclusion Criteria</label>
                  <textarea class="form-control" style={{ height: "200px" }} name="Inclusion" value={inclusion} onChange={(e) => { setInclusion(e.target.value) }} />
                </div>
                <div className="col-md-12">
                  <label>Exclusion Criteria</label>
                  <textarea class="form-control" style={{ height: "200px" }} name="Exclusion" value={exclusion} onChange={(e) => { setExclusion(e.target.value) }} /><br />
                </div><br />
              </div>
              <div className="buttonContainer col-12" >
                <button className="btn btn-primary-blue btn-rounded btn-small" style={{ float: "right" }} onClick={handleClick}>Submit</button><br />
              </div>
            </div>
            {show &&
              <>
                <div className="col-md-3">

                  <>
                    <div className="col-md-12" style={{ textAlign: "center", color: "lightblue" }}>
                      <h4>Entities</h4><hr />
                    </div>

                    <div className={classes.root}>
                      {
                        uniq_ent_type_data.map((item, i) => (
                          <ExpansionPanel expanded={expanded === item} onChange={handleChange(item)}>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Typography className={classes.heading}>{item}</Typography>
                            </ExpansionPanelSummary>
                            {ex_data.map(ex => ex.ent_type === item && <>
                              <ExpansionPanelDetails className={classes.panel}>
                                <Button variant="outlined" color="secondary" className={classes.exclusion} onClick={(e) => handleSelect(e, ex)}>
                                  {ex.name}
                                </Button>

                              </ExpansionPanelDetails>
                            </>)}
                            {in_data.map(inc => inc.ent_type === item && <>
                              <ExpansionPanelDetails className={classes.panel}>
                                <Button variant="outlined" color="primary" className={classes.inclusion} onClick={(e) => handleSelect(e, inc)}>
                                  {inc.name}
                                </Button>
                                {/* <pre>{JSON.stringify(inc,null, 2)}</pre> */}
                              </ExpansionPanelDetails>
                            </>)}
                          </ExpansionPanel>
                        ))
                      }
                    </div>
                  </>

                </div>

                <div className="col-md-3" style={{ paddingTop: "15px" }}>
                  <pre>{JSON.stringify(data, null, 2)}</pre>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default IEApicall;

