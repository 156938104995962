import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { AutoOptionsNew } from '../../../../globalcomponents/formElements'

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(0),
        top: theme.spacing(0),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} style={{
            backgroundColor: "#1c3b62",
            color: "white",
            padding: "10px",
          }}{...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
    const [open, setOpen] = React.useState(false);
    let [textobj, setTextobj] = useState(props.textobj);
    let [linkobj, setLinkobj] = useState(null);
    let [entitiyList, setEntityList] = useState(props.entities);

    useEffect(() => {
        if (props.entities && props.entities.length > 0) {
            let indexedEntities = props.entities.map((item, i) => {
                item.item_index = i;
                return item;
            });
            setEntityList(indexedEntities);
        } else {
            setEntityList(props.entities);
        }
    }, [props.entities])



    useEffect(() => {
        setTextobj(props.textobj)
    }, [props.textobj])

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        //setOpen(false);
        props.closePopover();
    };

    console.log('props.categoriesprops.categories::',props.categories)

    const defaultProps = {
        'options': Object.keys(props.categories),
        freeSolo: false,
    }

    const getOptionLabel = option => {
        try {
            if (props.getOptionLabel) {
                return props.getOptionLabel(option)
            } else {
                if (typeof option === 'string') {
                    return option;
                }
                if (option && option.inputValue) {
                    return option.inputValue;
                }
                return option && option[props.keyName]
            }
        } catch (error) {

        }
    }

    const categoryChange = (event, value) => {
        event.preventDefault();
        setTextobj({ ...textobj, 'categorey': value })
    }




    const defaultEntities = {
        options: entitiyList,
    };

    const getOptionEntities = option => {
        try {
            return option.term || '';
        } catch (error) {

        }
    }

    const linkEntityChange = (event, value) => {
        event.preventDefault();

        let n = Date.now();
        let obj = { ...textobj };
        obj.link = {
            "to_term_id": n,
        }
        value.category = 'Value';
        value["termid"] = n;
        setTextobj({ ...textobj, ...obj })
        setLinkobj({ ...value })
    }

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.openpopover}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    ADD ENTITY
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <form className={""} noValidate autoComplete="off">
                            <TextField
                                name="entity"
                                label="Entity"
                                defaultValue={props.textobj.text}
                                disabled
                                fullWidth
                            />

                            <Autocomplete
                                {...defaultProps}
                                selectOnFocus
                                debug={true}
                                getOptionLabel={getOptionLabel}
                                onChange={(event, val) => { categoryChange(event, val) }}
                                renderInput={(params) => <TextField {...params} label="select category" margin="normal" />}
                            />

                            {/* <Autocomplete
                                {...defaultEntities}
                                selectOnFocus
                                getOptionLabel={getOptionEntities}
                                onChange={(event, val) => { linkEntityChange(event, val) }}
                                renderInput={(params) => <TextField {...params} label="Link Entity" margin="normal" />}
                            /> */}
                        </form>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {/* <Button autoFocus onClick={(e) => props.updateContent(textobj)} color="primary">
                        ADD
                    </Button> */}


                    <div className="text-right">
                        <button className="btn btn-primary-blue btn-rounded" onClick={(e) => props.updateContent(textobj, linkobj)}>ADD</button>
                    </div>

                </DialogActions>
            </Dialog>
        </div>
    );
}
