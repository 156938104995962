import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle(width) {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    borderRadius: "5px",
     width: width || "35%"
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    // width: 500,
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
}));

const CommonModal = ({ open, onClose, header, children, update,editAppointment,deleteAppointment,width }) => {
  console.log(width, "model")
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle(width));
  // const handleClose = () => {
    const handleClose = (event, reason) => {
      if (reason !== 'backdropClick') {
        onClose()
      }
    }
    
  // };
  const editform = () => {
    editAppointment()
  }

  const deleteform = () => {
    deleteAppointment()
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        backdropClick={true}
      >
        <div style={modalStyle} className={classes.paper}>
          <div >
            <h6 id="modal-title" className="filehistorycss" style={{fontSize:"0.9rem"}}>
              {header}
              <span onClick={handleClose} style={{ float: "right", cursor: "pointer", fontSize: "12px" }}> X</span>
              {update &&
                <span className="iconsmodal fr">
                  {/* <i className="fas fa-pencil-alt " onClick={editform}></i> */}
                   <i className="fas fa-trash" onClick={deleteform}></i>
                </span>
              }
            </h6>

          </div>
          <div id="modal-description">
            {children}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CommonModal