import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../../../formElements';
import { connect } from 'react-redux';
import { checkPermission } from '../../../../common/utils';

const PagDetails = (propsobj) => {
  let isEditPag = checkPermission('PAG_Details_Edit', 'Update');
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);

  const handleClick = async (event) => {
    event.preventDefault();
    propsobj.history.push('/account/studyDetails/pag/update');
  }

  const { pagAccountName = '', status = '' } = propsobj.formValues;

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}


      <div className="col-md-6 pl-0">
        <div className="d-flex justify-content-end">

          <div class="secondary-header mr-auto">Details</div>
          {isEditPag === "1" ?
            <div className="">
              <button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
                <i className="fas fa-pencil-alt"></i>&nbsp; EDIT</button>
            </div> : ""
          }
        </div>
      </div>
      <div className="">
        <form noValidate autoComplete="off" id="addAccountForm" className="row h-form-1">
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="inputBox">
                    <ViewRow text="PAG Name" textvalue={pagAccountName} />
                    <ViewRow text="Status" textvalue={status} />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PagDetails));



