import React, { useEffect, useState } from "react";
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavMenu } from '../navMenu';
import DosageTable from "./dosageTable";
import CalendarApp from "./precalendar";
//import PreScreening from './prescreening'

const menus = [
  {
    to: "/site/patientdosage/assessments",
    text: "Assessments"
  },
  {
    to: "/site/patientdosage/calendar",
    text: "Appointments"
  },
]


function PatientAssessment(props) {
  useEffect(() => {
    props.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, [])
  const goBack = (event) => {
    event.preventDefault()
    props.history.push('/site/study/subject/enroll')
  }
  return (
    <>

      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">{props.formValues.patientdata.firstName + ' ' + props.formValues.patientdata.lastName}</h1>
        <button type="button" className="btn btn-primary-blue btn-rounded btn-small" onClick={goBack}
        >BACK</button>
      </div>

      <section className="mb-3">
        <NavMenu menus={menus} />
      </section>

      <div className="">
        <Switch>
          <Route path="/site/patientdosage/assessments" component={DosageTable}>
            {/* <LoadCom fileName="dosageTable" /> */}

          </Route>
          <Route path="/site/patientdosage/calendar" component={CalendarApp}>
            {/* <LoadCom fileName="precalendar" /> */}
          </Route>
          <Redirect to="/site/patientdosage/assessments" />
        </Switch>
      </div>
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientAssessment));



