import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Route, Switch, Link, BrowserRouter as Router, Redirect, NavLink } from 'react-router-dom';
import Disease from './disease';
import Medications from './medications';
import LabData from './labData';
import PatientImmunizations from './patientimmunizations';
import PatientAllergies from './patientAllergies';
import Procedures from './procedures'
import Vitalsigns from './vitalsigns'
import { Menus, NavSubMenu } from '../../navMenu';
import AddDisease from '../../../../globalcomponents/patient360/medicalhistory/addMC'
import AddMedication from '../../../../globalcomponents/patient360/medicalhistory/addMedication'
import AddLabdata from '../../../../globalcomponents/patient360/medicalhistory/addLabdata'
import AddImmunization from '../../../../globalcomponents/patient360/medicalhistory/addImmunization'
import AddAllergy from '../../../../globalcomponents/patient360/medicalhistory/addAllergy'


const smenus = [
    {
        to: "/patient/medicalhistory/disease",
        text: "Medical Conditions",
    },
    {
        to: "/patient/medicalhistory/medications",
        text: "Medications",
    },
    {
        to: "/patient/medicalhistory/labdata",
        text: "Lab Reports",
    },
    {
        to: "/patient/medicalhistory/vitalsigns",
        text: "Vital Signs",
    },
    {
        to: "/patient/medicalhistory/immunizations",
        text: "Immunizations",
    },
    {
        to: "/patient/medicalhistory/allergies",
        text: "Allergies",
    },
    {
        to: "/patient/medicalhistory/procedure",
        text: "Procedures",
    },
]



function MHistoryHome(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}

            <section className="mb-3">
                <NavSubMenu menus={smenus} />
            </section>

            <Switch>
                <Route exact path="/patient/medicalhistory/disease">
                    <Disease />
                </Route>
                <Route path="/patient/medicalhistory/disease/add">
                    <AddDisease />
                </Route>
                <Route exact path="/patient/medicalhistory/medications">
                    <Medications />
                </Route>
                <Route path="/patient/medicalhistory/medications/add">
                    <AddMedication />
                </Route>
                <Route exact path="/patient/medicalhistory/labdata">
                    <LabData />
                </Route>
                <Route exact path="/patient/medicalhistory/vitalsigns">
                    <Vitalsigns />
                </Route>
                <Route path="/patient/medicalhistory/labdata/add">
                    <AddLabdata />
                </Route>
                <Route exact path="/patient/medicalhistory/immunizations">
                    <PatientImmunizations />
                </Route>
                <Route path="/patient/medicalhistory/immunizations/add">
                    <AddImmunization />
                </Route>
                <Route exact path="/patient/medicalhistory/allergies">
                    <PatientAllergies />
                </Route>
                <Route path="/patient/medicalhistory/allergies/add">
                    <AddAllergy/>
                </Route>
                <Route exact path="/patient/medicalhistory/procedure">
                    <Procedures />
                </Route>
                <Redirect to="/patient/medicalhistory/disease" />
            </Switch>
        </>
    )

}

export default MHistoryHome;


