import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavMenu } from '../../navMenu';
import Accounts from './relatedAccounts'
import Studies from './relatedStudies'
import Details from '../viewContactDetail'
import Contacts from '../contactgrid'
import Audittrail from './audittrail'
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
const menus = [
  { to: "/account/contact/view", text: "Contact Details" },
  { to: "/account/contact/view/accounts", text: "Related Accounts" },
  { to: "/account/contact/view/studies", text: "Related Studies" },
  // { to: "/account/contact/audittrail", text: "Audit Trail" },
]
const ContactDetailsHome = (props) => {

  return (
    <>
      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">{props.formValues.firstName} {props.formValues.lastName}</h1>
      </div>

      <NavMenu menus={menus} />
      <div className="">
        <Route path="/account/contact/view/Accounts" component={Accounts} />
        <Route path="/account/contact/view/studies" component={Studies} />
        <Route exact path="/account/contact/view" component={Details} />
        {/* <Route path="/account/contact/audittrail" component={Audittrail} /> */}
      </div>

    </>
  );
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactDetailsHome));
