import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall } from '../../../common/apiconfig';
import FormHoc from '../../hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsMultiple } from '../../formElements';
import { ArmDrugUnits, ArmType } from '../../../common/constants';
import { connect } from 'react-redux';
import BackIcon from '../../../../img/back1.svg'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AddArm = (propsobj) => {
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [options, setOptions] = useState([])
  let [fullSite, setfullSite] = useState("")
  let [drugs, setDrugs] = useState([{ armDrugName: "", armDosage: "", armStrength: "", armUnit: "", armRoute: "", armFrequency: "" }])

  useEffect(() => {
    async function fetchData() {
      let requestBody = {
        query: `query {
          getAccountTypeSearch(parentAccountId:"*",searchStr:"*",type:"B",accountType:"PAG") {
            sponsorAccountId
            accountName
            parentAccountId
            accountType
            status
            address1
            address2
            countryCode
            country
            state
            city
            zip
            email
            phone
            website
            duns

          }
        }
        `
      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getAccountTypeSearch', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getAccountTypeSearch.length) {

          var data = res.data.getAccountTypeSearch
          //to remove already added pags
          if (propsobj.formValues.pagRecords.length > 0) {
            var data1 = propsobj.formValues.pagRecords
            console.log(data, data1, "addArm")
            data = data.filter(function (item) {
              for (var i = 0, len = data1.length; i < len; i++) {
                if (data1[i].pagId === item.sponsorAccountId) {
                  return false;
                }
              }
              return true;
            });
          }
          setOptions(data)
        }
      }
    }
    // fetchData()
    propsobj.UpdateFormField({ name: "armName", value: "" })
    propsobj.UpdateFormField({ name: "armType", value: "" })
    propsobj.UpdateFormField({ name: "armDescription", value: "" })
    propsobj.UpdateFormField({ name: "armStatus", value: "Active" })

  }, []);

  const hocObj = FormHoc(propsobj);
  const textProps = {
    ...hocObj,
    propsobj
  }
  const autoPropstoType = {
    'options': ArmType,
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }
  const autoPropsToStatus = {
    default: {
      value: 'Active',
      label: 'Active',
    },
    'options': [{
      value: 'Active',
      label: 'Active'
    }, {
      value: 'InActive',
      label: 'InActive',
    }],
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('addArm', event);
    console.log(setGo)
    if (setGo) {
      // setProgress(true);
      let form = propsobj.formValues;
      console.log(form, drugs)
      let drugslist = '';
      //here to add all selected row from redux
      drugs.map(drug => {
        drugslist += `{ dosageName: "${drug.armDrugName}",dosage: "${drug.armDosage}",strength:"${drug.armStrength}", units: "${drug.armUnit.value}",route:"${drug.armRoute}",frequency:"${drug.armFrequency}",description:"" }`;
      });
      let drugss = drugslist.replace(/}\s*{/g, '},{');
      let requestBody = `
          mutation {
            addStudyArm(armName:"${form.armName}",studyId:"${form.studyId}",type:"${form.armType}",description:"${form.armDescription}",status:"${form.armStatus}",armDetails:[${drugss}]) {
              code
              type
              message
              attributes
            }
          }
    `;
      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('addStudyArm', requestBody, form);
      setProgress(false);

      if (res && res.data != null && res.data.addStudyArm.code === '200') {
        console.log('added');
        propsobj.history.push('/account/studyDetails/arm');
      } else {
        console.log('Error!')
      }

    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
    propsobj.history.goBack();
  }

  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.goBack()
  }
  const handleChange = (event, index) => {
    console.log(index, "i")
    var products = drugs.slice() //or [...links];
    var newDrugs = products.map((item, i) => {
      if (i == index) {
        if (event.target.name === "armStrength") {
          if (!Number(event.target.value)) {
            event.preventDefault();
          } else {
            item[event.target.name] = event.target.value
          }
        } else {
          item[event.target.name] = event.target.value
        }
      }
      return item
    })
    setDrugs(newDrugs)

  }
  const getUnits = (e, value, i) => {
    console.log(e, value, i)
    var arrDrugs = [...drugs]
    arrDrugs[i]['armUnit'] = value
    setDrugs(arrDrugs)
  }
  const removeDrug = (e, index) => {
    var arr = [...drugs]
    arr.splice(index, 1);
    setDrugs(arr)
  }
  const addNew = () => { setDrugs([...drugs, { armDrugName: "", armDosage: "", armStrength: "", armUnit: "", armRoute: "", armFrequency: "" }]) }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* 
      <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO PAG LIST</div><br/> */}
      <div className="d-flex mt-4 justify-content-end">
        <div className="mr-auto secondary-header">Add Arm</div>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="addArm" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="inputBox">
                    <InputField {...textProps} name="armName" label="Arm Name" keyName='value' must={'true'} />
                    <AutoOptions {...autoPropstoType} name="armType" label="Arm Type" keyName='value' must={'true'} />
                    <InputField {...textProps} multiline={true} rows={3} rowsMax={3} name="armDescription" label="Description" must={'true'} type="text" />
                    <AutoOptions {...autoPropsToStatus} name="armStatus" label="Status" keyName='value' must={'false'} />

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="card-page-header col-md-8" style={{ marginTop: "11px" }}>{propsobj.type} Arm Details</div> <div className="col-md-4 card-page-header fr"><span className="action_div fr" >
                      <button onClick={addNew} type="button" className="btn btn-primary-blue btn-rounded">
                        <i className="fa fa-plus-circle mr-1"></i> Add New Drug</button>
                    </span></div>
                  </div>
                  {/* <div className="inputBox"> */}

                  {/* <InputField {...textProps} name="armDosage" label="Dosage" keyName='value' must={'true'} />
                    <InputField {...textProps} name="armStrength" label="Strength" keyName='value' must={'true'} />                   
                     <AutoOptions {...autoPropstoUnit} name="armUnit" label="Unit" keyName='value' must={'true'} />
                    <InputField {...textProps} name="armRoute" label="Route" keyName='value' must={'true'} />
                    <InputField {...textProps} name="armFrequency" label="Frequency" keyName='value' must={'true'} /> */}
                  {drugs.length > 0 &&
                    <div class="inputBox">
                      <table class="viewCourseLinks">
                        <thead className="">
                          <tr>
                            <th> S.NO</th>
                            <th>Drug Name</th>
                            <th>Dosage</th>
                            <th>Strength</th>
                            <th>Unit</th>
                            <th>Route</th>
                            <th>Frequency</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            drugs.length > 0 &&
                            drugs.map((item, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>
                                    <TextField
                                      value={drugs[i].armDrugName}
                                      type="text"
                                      name="armDrugName"
                                      onChange={(e) => handleChange(e, i)}
                                      style={{ width: "100%" }}
                                    />
                                  </td>
                                  <td>
                                    <TextField
                                      value={drugs[i].armDosage}
                                      type="text"
                                      name="armDosage"
                                      onChange={(e) => handleChange(e, i)}
                                      style={{ width: "100%" }}
                                    />
                                  </td>
                                  <td>
                                    <TextField
                                      // multiline={true}
                                      // rows={1}
                                      // rowsMax={4}
                                      value={drugs[i].armStrength}
                                      type="text"
                                      name="armStrength"
                                      onChange={(e) => handleChange(e, i)}
                                      style={{ width: "100%" }}
                                    />

                                  </td>
                                  <td style={{ width: "15%" }}>
                                    <Autocomplete
                                      id="controlled-demo"
                                      options={ArmDrugUnits}
                                      getOptionLabel={option => option.name ? option.value : ""}
                                      value={drugs[i].armUnit}
                                      disableClearable
                                      // onChange={(e) => handleChange(e, i)}
                                      onChange={(e, value) => getUnits(e, value, i)}
                                      renderInput={(params) => <TextField {...params} margin="none" fullWidth />}
                                    />

                                  </td>
                                  <td>
                                    <TextField
                                      value={drugs[i].armRoute}
                                      type="text"
                                      name="armRoute"
                                      onChange={(e) => handleChange(e, i)}
                                      style={{ width: "100%" }}
                                    />
                                  </td>
                                  <td>
                                    <TextField
                                      value={drugs[i].armFrequency}
                                      type="text"
                                      name="armFrequency"
                                      onChange={(e) => handleChange(e, i)}
                                      style={{ width: "100%" }}
                                    />
                                  </td>
                                  <td className="td_action">
                                    <div className="action_div"  >
                                      <Tooltip title="Remove"><Icon color="error" onClick={(e) => removeDrug(e, i)} > remove_circle</Icon></Tooltip>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  }
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className="buttonContainer">
             
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddArm));



