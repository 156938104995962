import React, { useEffect, useState } from "react";
import Materialtable from '../../../../globalcomponents/materialtable';
import { apiCall } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

function AdverseEventsGrid(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);


  useEffect(() => {

    async function fetchData() {

      let requestBody = {
        query: `
        query {
          getStudyAdverseEvents(studyId:"${props.formValues.studyId}") {
                adverseEventId
            studyId
            siteId
            patientId
            aeTerm
            severity
            causality
            outcome
            startDate
            stopDate
            createdBy
            createdTs
            modifiedBy
            modifiedTs
          }
        }
       `
      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getStudyAdverseEvents', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getStudyAdverseEvents.length) {
          setData(res.data.getStudyAdverseEvents)
        }
      }
    }
    fetchData();
  }, []);

  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    props.history.push('/account/studyDetails/adverseEvents/Add');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    await props.UpdateEntireForm(rowData);
    props.history.push('/account/studyDetails/adverseEvents/View');
  }

  const Columns = [
    {
      title: 'Site ID', field: 'siteId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Patient ID', field: 'patientId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'AE Term', field: 'aeTerm',
      cellStyle: {
        color: '#244271',
        fontWeight: 500
      },
    },
    { title: 'Severity', field: 'severity' },
    { title: 'Causality', field: 'causality' },
    { title: 'Start Date', field: 'startDate' },
    { title: 'Stop Date', field: 'stopDate' },
  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex mt-4 justify-content-end">
        <div className="mr-auto secondary-header">Adverse Events</div>
        {/* <div className="">
          <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
            <i className="fa fa-plus-circle mr-1"></i> ADD ADVERSE EVENT</button>
        </div> */}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="">

            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdverseEventsGrid));


