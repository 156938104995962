import React, { useEffect, useState } from "react";
import BackIcon from '../../../img/back.svg';
import { noAuthApi, logOut } from '../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { jwt } from '../../common/jwtAuthentication';
import FormHoc from '../hoc/formHoc';
import { InputField } from '../formElements';
import { connect } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';

const ForgotPwdForm = (propsobj) => {
    let [progress, setProgress] = useState(false);
    let [apiError, setApiError] = useState(false);

    let hocObj = FormHoc(propsobj);

    const formForgotPwd = async (e) => {
        e.preventDefault()
        let setGo = await hocObj.handleSubmit('forGotPwdForm', e);

        if (setGo) {
            setProgress(true);
            let emailObj = {
                email: propsobj.formValues.forgot_email
            }
            let res = await noAuthApi('forgetpassword', emailObj);
            setProgress(false);
            if (!res) {
                setApiError(true);
                setTimeout(function () { setApiError(false) }, 1000 * 30);
            } else {
                propsobj.clearForm();
                alert('update password link has been sent to your mail');
                logOut();
            }
        }
    }

    const back = (e) => {
        e.preventDefault()
        propsobj.back();
    }



    let textProps = {
        ...hocObj,
        propsobj
    }

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}
            <div className="h-tabs-1">
                {apiError && <>
                    <div className="hekError">
                        <span className='icon'>
                            <ErrorIcon />
                        </span>
                        <span className='texts'>
                            Something went wrong!
                                    </span>
                    </div>
                </>}
                <img src={BackIcon} alt="Back" onClick={back} />
                <span>Forgot Password</span>
                <form noValidate autoComplete="off" id="forGotPwdForm" className="loginForm"
                    onSubmit={formForgotPwd}>
                    <InputField {...textProps} name="forgot_email" label="Email Address" must={'true'} type="text" />
                    <div className="form-group"><br />
                        <button type="submit" className="btn btn-primary-blue btn-rounded btn-large btn-block">Reset Password </button>
                    </div>
                </form>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        formValues: state.form,
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        },
        clearForm: () => {
            return dispatch(
                {
                    type: 'ClearForm',
                }
            )
        },
        updateErrorObj: (obj) => {
            return dispatch(
                {
                    type: 'updateErrorObj',
                    flag: obj.flag,
                    name: obj.name,
                    text: obj.text
                }
            )
        },
        updateEntireErrorObj: (errobj, errtextobj) => {
            return dispatch(
                {
                    type: 'updateEntireErrorObj',
                    errobj: errobj,
                    errtextobj: errtextobj,
                }
            )
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPwdForm));



