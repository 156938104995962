import React, { useEffect, useState } from "react";
import Materialtable from '../../../globalcomponents/materialtable';
import { genericApi } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

function ArmsGrid(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);


  useEffect(() => {
    fetchData();
  }, [props.formValues.autostudy.studyId]);

  async function fetchData() {

    let requestBody = {
      query: `
      query {
        getStudyArms(studyId: "${props.formValues.autostudy.studyId}") {
              armNum
                armName
        studyId
        type
        description
        status
        createdBy
        createdTs
        modifiedBy
        modifiedTs
          
        }
      }
      
      
     `
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getStudyArms', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error!')

    } else {
      if (res && res.data!=null && res.data.getStudyArms.length) {
        setData(res.data.getStudyArms)
      }else{
        setData([])
      }
    }
  }

  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    props.history.push('/account/studyDetails/arm/Add');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    await props.UpdateEntireForm(rowData);
    props.history.push('/site/study/arm/viewARM');
  }

  const Columns = [
    {
      title: 'Name', field: 'armName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Type', field: 'type',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Description', field: 'description',
      cellStyle: {
        color: '#244271',
        fontWeight: 500
      },
    },
    { title: 'Status', field: 'status' },
    // { title: 'Dosage', field: 'causality' },
    // { title: 'Strength', field: 'startDate' },
    // { title: 'Unit', field: 'stopDate' },
  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* <div className="d-flex mt-4 justify-content-end">
        <div className="mr-auto secondary-header">Arm List</div>
        <div className="">
          <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
            <i className="fa fa-plus-circle mr-1"></i> ADD ARM</button>
        </div>
      </div> */}
      <div className="listing-wrap hekmaGrid mt2rem">
        <div className="card">
          <div className="">

            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ArmsGrid));

