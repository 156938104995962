import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall } from '../../../../common/apiconfig';
import FormHoc from '../../../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew, AutoOptionsMultiple } from '../../../../globalcomponents/formElements';
import { connect } from 'react-redux';

const Ach = (propsobj) => {
  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }
  const formSubmit= async (event) => {
    event.preventDefault();
  }

  return (
    <>
      <form noValidate autoComplete="off" id="addAccountForm" className="row" onSubmit={formSubmit}>
      <div className="inputBox m-3">
        <InputField {...textProps} name="ach_routing" label="ABA/Routing Number" must={'true'} type="text" />
        <InputField {...textProps} name="ach_accountnum" label="Bank Account Number" must={'true'} type="text" />
        <InputField {...textProps} name="ach_accounttype" label="Account Type" must={'true'} type="text" />
        <InputField {...textProps} name="ach_bankname" label="Bank Name" must={'true'} type="text" />
        <InputField {...textProps} name="ach_name" label="Account Holder Name" must={'true'} type="text" />
        </div>
       
      </form>
      <button type="submit" className="btn btn-primary-blue btn-rounded btn-small fr" >save</button>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ach));