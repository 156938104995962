import React, { useState, useEffect } from 'react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CardPayment from './cardpayment'
import '../../../siteSrc/components/site/accounts/billing.scss'
import { Elements, useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Ach from './ach'
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip';
import Img from '../../../img/opensquare/Payment01.png'
import { appConfig } from '../../../common/constants';

// const stripePromise = loadStripe("pk_test_51Jwza1Emk0zCaAlpjUR1A5yw49UcPhRzQWsx7D6yiJdcUoTjfh8G6cVipPt5QAz7zltRuYglwWYVyPkDdmRZyuph00WC4dSz7D");
const stripePromise = loadStripe(appConfig.stripe_publishable_key)

const PaymentPage = (propsobj) => {
  let reduxstateobj = useSelector(state => state);
  const reduxdispatch = useDispatch()
  const [paymentmethod, setPaymentMethod] = useState('ach')
  const radioHandle = (e) => {
    e.preventDefault();
    setPaymentMethod(e.target.value)
  }

  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.goBack()
  }
  const goToDashboard = () => {
    reduxdispatch({ type: 'UpdateFormField', name: "cart", value: [] })
    propsobj.history.push('/account/opensquare')
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header bg-w" style={{ display: "inline-block" }}>
              <h1 className="mr-auto primary-header fl">Select Payment Method</h1>
              <span className="fr" style={{ marginTop: "6px", fontWeight: "bold", color: "green" }}>Paying: ${numberWithCommas(reduxstateobj.totalprice / 100)}</span>
            </div>
            <div className="card-body">
              <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                  <FormControlLabel control={<Radio color="primary" name="ach" value='ach' checked={paymentmethod === 'ach'} onChange={(e) => { radioHandle(e) }} />} label="ACH Bank" />
                  <FormControlLabel control={<Radio color="primary" name="card" value="card" checked={paymentmethod === "card"} onChange={(e) => { radioHandle(e) }} />} label="Card Payment" />
                  <FormControlLabel control={<Radio color="primary" name="paypal" value="paypal" checked={paymentmethod === "paypal"} onChange={(e) => { radioHandle(e) }} />} label="Paypal" />
                </RadioGroup>
              </FormControl>
              {paymentmethod === "card" ?
                <div style={{ display: "flex", minHeight: "53vh", alignItems: "center", justifyContent: "center" }}>
                  <div style={{ width: "100%" }}>
                    <Elements stripe={stripePromise} ><CardPayment totalprice={reduxstateobj.totalprice} goToDashboard={goToDashboard}></CardPayment> </Elements>
                  </div>
                </div>
                : paymentmethod === "ach" ? <Ach />
                  : paymentmethod === "paypal" ? <div style={{color:"red"}}>Build In Progress</div>: ""}
            </div>
          </div>
        </div>
        <div className="col-md-6" style={{
          alignItems: "center", textAlign: "center", marginTop: "40px"
        }}>
          <img src={Img} alt="Image" />
        </div>
      </div>
    </>
  )
}
export default withRouter((PaymentPage))