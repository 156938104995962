import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

function VaccineCard(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}

            <section className="mediCard vaccineCard">
                <div className="top d-flex justify-content-between mb-3">
                    <div className="flex-grow-1 d-flex align-items-center">
                        <span className="roundedIcon">
                            <i className="fas fa-syringe"></i>
                        </span>
                        <span className="ml-2">{props.title}</span>
                    </div>
                </div>
                <div className="daterow d-flex justify-content-between">
                    <div className="dates d-flex">
                        <span className="label">
                            Date
                        </span>
                        <span className="ml-3">
                            {props.sd}
                        </span>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VaccineCard;


