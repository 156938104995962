import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../../../common/apiconfig';
import FormHoc from '../../../hoc/formHoc';
import { InputField, AutoOptions } from '../../../formElements';
import { connect } from 'react-redux';
import BackIcon from '../../../../../img/back1.svg'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip';
import $ from 'jquery'
import './_checkbox.scss'
import { checkPermission } from '../../../../common/utils';
const ScheduleMatrix = (props) => {
  console.log(props.formValues,"scmatrix")
  let isEditEvents = checkPermission('Events_Edit', 'Update');
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [schedule, setSchedule] = useState([]);
  let [scheduleSegmentList, setScheduleSegmentList] = useState([]);
  let [scheduleEventList, setScheduleEventList] = useState([]);
  let [linkRowColumn, setLinkRowColumn] = useState([]);
  let [rows, setRows] = useState([]);
  let [cols, setCols] = useState([]);
  let [edit, setEdit] = useState(false)


  useEffect(() => {
    fetchData()
  }, [props.formValues.scheduleSegmentList]);


  const fetchData = async () => {
    var rowss = []
    var colss = []

    for (var i = 0; i < props.formValues.linkRowColumn.length; i++) {
      var data = [parseInt(props.formValues.linkRowColumn[i].rowId), parseInt(props.formValues.linkRowColumn[i].columnId)]
      rowss.push(JSON.stringify(data))
    }
    for (var i = 0; i < props.formValues.linkRowColumn.length; i++) {
      colss.push(parseInt(props.formValues.linkRowColumn[i].columnId))
    }
    setSchedule(props.formValues.schedule)
    setScheduleSegmentList(props.formValues.scheduleSegmentList)
    setScheduleEventList(props.formValues.scheduleEventList)
    setLinkRowColumn(props.formValues.linkRowColumn)
    setRows(rowss)
    setCols(colss)
  }

  const checkAll = (e) => {
    // e.preventDefault()
    var data = e.target.id + "row"
    var idd = e.target.id
    if (data != "row") {
      if ($('#' + idd).is(':checked')) {
        $("#" + data + " input[name=" + data + "]").each(function () {
          $(this).prop("checked", true);
        })
      } else {
        $("#" + data + " input[name=" + data + "]").each(function () {
          $(this).prop("checked", false);
        });
      }

    }

  }
  const unCheck = (e) => {
    // e.preventDefault()

    var sepComma = e.target.value.split(',');
    console.log(linkRowColumn, rows, sepComma[0], sepComma[1])
    var data = [...linkRowColumn]
    var rowData = [...rows]
    for (var i = 0; i < data.length; i++) {
      if (data[i].rowId === sepComma[0] && data[i].columnId === sepComma[1]) {
        data.splice(i, 1)
        rowData.splice(i, 1)
      }
    }
    console.log(data)
    setLinkRowColumn(data)
    setRows(rowData)
  }
  const handleSubmit = async () => {
    var matrix = [];
    $.each($("input[type=checkbox]:checked"), function () {
      matrix.push($(this).val());
    });
    var ScheduleEventRowColumn
    let obj = ''

    for (var i = 0; i < matrix.length; i++) {
      if (matrix[i].length > 1) {
        if (matrix[i] != "on") {
          var sepComma = matrix[i].split(',');
          obj += `{ rowId: "${sepComma[0]}",columnId:"${sepComma[1]}"}`;

        }
      }
    }
    ScheduleEventRowColumn = obj.replace(/}\s*{/g, '},{');
    let requestBody = `
      mutation {
        createScheduleEvent(studyId:"${props.formValues.studyId}",scheduleId:"-1",rowColumns:[${ScheduleEventRowColumn}]) {
          code
          type
          message
          attributes
        }
      }
          `;
    requestBody = JSON.stringify({ query: requestBody });
    let res = await apiCall('createScheduleEvent', requestBody);
    setProgress(false);

    if (res && res.data.createScheduleEvent.code === '200') {
      console.log('added');
      props.history.push('/account/studyDetails')

    } else {
      console.log('Error on response schedule matrix!')
    }
  }
  const handleClick = () => {
    setEdit(true)
  }
  const goBack = (event) => {
    event.preventDefault();
    props.history.goBack()
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="d-flex mb-3" >

        <div class="mr-auto"><b>SCHEDULE MATRIX</b></div>
        {isEditEvents === "1" ? linkRowColumn.length>0 && <div className="">
          <button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
            <i className="fas fa-pencil-alt"></i>&nbsp; EDIT</button>
        </div> : ""}

      </div>
      <div className="">
        <div className="panel-primary-wrap">
          <div className="card">
            <div className="card-body">

              <div className="inputBox table-responsive-md" >

                <table className="viewScheduleMatrix table">
                  <thead>
                    <tr>
                      <th rowSpan="2" className="eventText"><b>EVENTS</b></th>
                      <th colSpan={scheduleSegmentList.length + 1} className="eventText">SEGMENTS OF STUDY</th>
                    </tr>
                    <tr className="eventMatrixth">
                      {scheduleSegmentList.map((item, i) => {
                        return (
                          <th>
                            {item.segmentName}<br />
                            +/- ({item.end - item.start} D)
                          </th>
                        )
                      })}

                      <th>SelectAll</th>

                    </tr>
                  </thead>
                  <tbody>
                    {
                      scheduleEventList.map((item, i) => {
                        return (
                          <tr
                            key={item.eventKey}
                            className="hover-change"
                            id={i + "row"}
                          >
                            <td>{item.eventName}</td>
                            {scheduleSegmentList.map((row, j) => {
                              if (linkRowColumn.length > 0) {
                                if (rows.indexOf("[" + i + "," + j + "]") > -1) {
                                  return (
                                    <td>
                                      <label class="custom-control material-checkbox">
                                        <input type="checkbox" class="material-control-input" value={i + "," + j}
                                          name={i.toString() + "row"} onClick={(e) => unCheck(e)} id={i.toString() + "row" + j.toString()} checked={rows.indexOf("[" + i + "," + j + "]") > -1} disabled={!edit}/>
                                        <span class="material-control-indicator"></span>

                                      </label>
                                    </td>
                                  )
                                } else {
                                  return (
                                    <td>
                                      {edit && (
                                        <label class="custom-control material-checkbox">
                                          <input type="checkbox" class="material-control-input" value={i + "," + j}
                                            name={i.toString() + "row"} />
                                          <span class="material-control-indicator"></span>

                                        </label>
                                      )}
                                    </td>
                                  )
                                }
                              } else {
                                return (
                                  <td>
                                    <label class="custom-control material-checkbox">
                                      <input type="checkbox" class="material-control-input" value={i + "," + j}
                                        name={i.toString() + "row"} />
                                      <span class="material-control-indicator"></span>
                                    </label>
                                  </td>

                                )
                              }
                            })}


                            <td>
                              <label class="custom-control material-checkbox">
                                <input type="checkbox" class="material-control-input" id={i.toString()}
                                  onClick={checkAll} />
                                <span class="material-control-indicator"></span>
                              </label>
                            </td>


                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          { isEditEvents=== "1" ? <div className="buttonContainer" style={{ float: "right" }}>
            <button type="submit" className="btn btn-primary-blue btn-rounded btn-small" onClick={handleSubmit}>Save</button>
            {/* <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button> */}
          </div> : ""}

        </div>
      </div>

    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleMatrix));



