import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../../formElements';
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core'
import BackIcon from '../../../../img/back1.svg'
import { checkPermission } from '../../../common/utils';

// let isEditStudy = checkPermission('Study_Details_Edit', 'Update');
const ViewAE = (propsobj) => {
  let isEditAE = checkPermission('AE_Edit', 'Update');
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);

  const handleClick = async (event) => {
    event.preventDefault();
    propsobj.history.push('/account/studyDetails/adverseEvents/Update');
  }

  const { patientId = '',
    aeTerm = '',
    severity = '',
    causality = '',
    outcome = '',
    startDate = '',
    stopDate = '', } = propsobj.formValues;
  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.goBack()
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO ADVERSE EVENTS LIST</div><br /> */}

      <div className="d-flex mt-4 justify-content-end">

        <div class="secondary-header mr-auto">Adverse Event Details</div>
        {isEditAE === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
              <i className="fas fa-pencil-alt"></i>&nbsp; EDIT</button>
          </div> : ""}

      </div>
      <div className="">
        <form noValidate autoComplete="off" id="addAccountForm" className="row h-form-1">
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">

                <div className="inputBox">
                    <ViewRow text="Patient ID" textvalue={patientId} />
                    <ViewRow text="AE Term" textvalue={aeTerm} />
                    <ViewRow text="Severity" textvalue={severity} />
                    <ViewRow text="Causality" textvalue={causality} />
                    <ViewRow text="Outcome" textvalue={outcome} />
                    <ViewRow text="StartDate" textvalue={startDate} />
                    <ViewRow text="StopDate" textvalue={stopDate} />

                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">

                  <div className="inputBox">

                    <ViewRow text="Outcome" textvalue={outcome} />
                    <ViewRow text="StartDate" textvalue={startDate} />
                    <ViewRow text="StopDate" textvalue={stopDate} />

                  </div>
                </div>
              </div>
            </div>
          </div> */}

        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAE));



