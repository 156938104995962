import React from 'react'
import { Route, Link, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import MainPage from './mainPage';
import SiteSelection from './siteselectionpage'
// import './sass/siteAppStyles.scss';

// import PatientSignup from './components/activatePatientProfile'


const Routing = () => (

  <Router basename="/app">
    <Switch>
      <Route path="/app" component={MainPage} />
      {/* <Route path="/activatePatient" component={MainPage} /> */}
      <Route path="/siteselection" component={SiteSelection} />
      {/* <Route path="/IEApicall" component={IEApicall} />
      <Route path="/activateContact" component={MainPage} /> */}
    </Switch>
  </Router>

);
export default Routing;