


import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useHistory } from "react-router-dom";

import { withRouter } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../common/apiconfig';
import FormHoc from '../hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew } from '../formElements';
import { Dropdowns } from '../../common/constants';
// import { useData } from "./DataContext";
import Typography from "@material-ui/core/Typography";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { PrimaryButton } from "../commoncomponents/PrimaryButton";
import { MainContainer } from "../commoncomponents/MainContainer";
import { Form } from "../commoncomponents/Form";
import { Input } from "../commoncomponents/Input";
import { useSelector } from 'react-redux'
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { addContactSchema } from '../../common/constants'
const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  { code: "AE", label: "United Arab Emirates", phone: "971" },
  { code: "AF", label: "Afghanistan", phone: "93" },
  { code: "AG", label: "Antigua and Barbuda", phone: "1-268" },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  { code: "AU", label: "Australia", phone: "61", suggested: true },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" }]
let roleObj = {
  roleName: '',
  roleId: '',
  error: false,
  helperText: '',
}

const HookForm = () => {
  // const { setValues, data } = useData();
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [accnameShow, showAccName] = useState(true);
  let [portal, setPortal] = useState(false)
  let [roleField, setRoleField] = useState(roleObj);
  let reduxstateobj = useSelector(state => state)
  console.log(reduxstateobj, "reduxstateobj")
  const history = useHistory();
  useEffect(() => {
    if (reduxstateobj.form.addContactFromContactSearch) {
      showAccName(true)
    } else {
      showAccName(false)
    }
    // function ip2int(ip) {
    //   return ip.split('.').reduce(function (ipInt, octet) { return (ipInt << 8) + parseInt(octet, 10) }, 0) >>> 0;
    // }
    // fetch('http://api.ipify.org/?format=json').then(result => result.json()).then(
    //   data => console.log(data.ip, ip2int(data.ip)))
  }, [])
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: { firstName: "", lastName: "", accnameshow: accnameShow, accountName: reduxstateobj.form.addContactFromContactSearch ? "" : reduxstateobj.form.accountName },
    mode: "onBlur",
    resolver: yupResolver(addContactSchema),
  });

  const onSubmit = (data) => {
    console.log(data)
    // history.push("./step2");
    // setValues(data);
  };
  const countryToFlag = (isoCode) => {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
        .toUpperCase()
        .replace(/./g, char =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
      : isoCode;
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">REACT HOOK FORM</h1>
      </div>

      <div className="">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  {/* <div className="card-page-header">NAME INFORMATION</div> */}
                  <div className="inputBox">
                    <Input
                      ref={register}
                      id="firstName"
                      type="text"
                      label="First Name"
                      name="firstName"
                      error={!!errors.firstName}
                      helperText={errors?.firstName?.message}
                    />
                    <Input
                      ref={register}
                      id="mName"
                      type="text"
                      label="Middle Name"
                      name="mName"
                      error={!!errors.mName}
                      helperText={errors?.mName?.message}
                    />
                    <Input
                      ref={register}
                      id="lastName"
                      type="text"
                      label="Last Name"
                      name="lastName"
                      error={!!errors.lastName}
                      helperText={errors?.lastName?.message}
                    />
                    <Input
                      ref={register}
                      id="accountName"
                      type="text"
                      label="Account Name"
                      name="accountName"
                      error={accnameShow && !!errors.accountName}
                      helperText={accnameShow && errors?.accountName?.message}
                      disabled={!accnameShow}
                    />
                    {accnameShow &&
                      <div className="searchButtonWidth">
                        <IconButton aria-label="Search" className="searchicon"
                        // onClick={accountSearch}
                        >
                          <SearchIcon />
                        </IconButton>
                      </div>}
                    {/* <AutoOptions {...autoPropstoposition} name="position" label="Position" keyName='value' must={'true'} /> */}
                    <Controller
                      as={
                        <Autocomplete
                          options={countries}
                          getOptionLabel={option => option.label}
                          disableClearable
                          renderOption={option => (
                            <span>
                              {/* {countryToFlag(option.code)} */}
                              {option.label}
                            </span>
                          )}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Choose a country"
                            // variant="outlined"
                            />
                          )}
                        />
                      }
                      onChange={([, data]) => console.log(data)}
                      name="country"
                      control={control}
                      // defaultValue={{ code: "AF", label: "Afghanistan", phone: "93" }}
                    />
                    <div className="buttonContainer col-12">
                      <PrimaryButton>SAVE</PrimaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};
export default HookForm