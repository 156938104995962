import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import LineGraph from './linearWrapper';
import moment from 'moment';
function TestDetailCard(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}
            {props.data.min != undefined &&
                <section className="mediCard vaccineCard mb-3" style={{ margin: '0px' }}>
                    <div className="top d-flex justify-content-between mb-3">
                        <div className="flex-grow-1 d-flex align-items-center">
                            <span className="mr-2">{props.title}</span>
                            {/* <span className="ml-2 ml-auto" style={{ flexShrink: 0 }}>
                            <span><i className="ml-2 far fa-clock"></i></span>
                            <span className="ml-2">{moment(props.data.date).format('MMM Do YYYY')}</span>
                        </span> */}
                        </div>
                    </div>
                    <div className="labResults" style={{ marginRight: "5px" }}>
                        <LineGraph data={props.data.data} data1={props.data.data1} xaxisdata={props.data.xaxis} min={props.data.min} max={props.data.max} name={props.data.testTitle} unit={props.data.unit} />
                    </div>

                    {/* <div className="summary">
                    <p className="text-muted">{props.data.testTitle}</p>
                    <p>{props.data.desc}</p>
                </div>

                <div className="">
                    <p className="text-muted">{props.data.p2title}</p>
                    <p>{props.data.p2desc}</p>
                </div>


                <div className="">
                    <p className="text-muted">{props.data.p3title}</p>
                    <p>{props.data.p3desc}</p>
                </div> */}
                </section>
            }
        </>
    )
}

export default TestDetailCard;


