import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import SmallLogo from '../../../img/hekma-logo.svg';
//import UserProfile from '../../../img/user-profile.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import UserMenu from './userMenu';
import ReqAppIcon from '../../../img/Request-Appointment.png'
import SimpleModal from '../../../globalcomponents/simplemodal'
import AppointmentForm from './requestappointmentform'
import { oldgenericApi } from '../../../common/apiconfig';
import $ from 'jquery'
import Calendar from '../../../img/calendar.svg'
import Alerts from './alerts'
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment'

const Header = (props) => {
  console.log(props.formValues, "pp")
  let [open, setOpen] = useState(false)
  let [appointments, setAppointments] = useState('')
  let [activeStatus, setActiveStatus] = useState([])
  let [activeApp, setActiveApp] = useState([])
  let [data, setData] = useState('')
  const [offsetReq, setoffsetReq] = useState(0)
  const [offsetApp, setoffsetApp] = useState(0)

  useEffect(() => {
    //fetchData();
    $("#appointment").on('click', function (e) {
      setOpen(true)
    });
  }, [open]);

  useEffect(() => {
    fetchAlerts()
    fetchAppointments()
    const interval = setInterval(() => {
      fetchAlerts()
      fetchAppointments()
    }, 300000);

    return () => clearInterval(interval);
  }, [open, props.formValues.patientdata])

  const callViewedApis = async () => {
    for (var i = 0; i < activeApp.length; i++) {
      let requestBody = `
  mutation 
  {
    updateAppointment(appointmentId:"${activeApp[i].appointmentId}",note:"${activeApp[i].note}",status:"Viewed")
    {
      code
      type
      message
      attributes
    }  
  }
      `;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await oldgenericApi('updateAppointment', requestBody);
      // setProgress(false);

      if (res && res.data.updateAppointment.code === '200') {
        // alert('updated')
      }
    }

  }


  const fetchAlerts = async () => {
    let requestBody = {
      query: `query
      {
        searchRequest(fromKey:"${localStorage.getItem('loginUserId')}",fromType:"patient",          toKey:"*",toType:"*",          refKey1:"*",refType1:"*",          refKey2:"",refType2:"*",          refKey3:"*",refType3:"*",          type:"Doctor Appointment"  
        )
       {
            requestId
            fromKey
            fromType
            toKey
            toType
            refKey1
            refType1
            refKey2
            refType2
            refKey3
            refType3
            type
            requestTimestamp
            requestDescription
            requestStatus
            requestResponse
            requestUpdateTimestamp
            createdBy
            createdTs
          }
        }`
    };


    requestBody = JSON.stringify(requestBody);
    // setProgress(true);
    let res = await oldgenericApi('searchRequest', requestBody);
    // setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error!')

    } else {
      if (res.data.searchRequest.length > 0) {
        var data = res.data.searchRequest
        setData(res.data.searchRequest)
        var activeData = data.filter(x => x.requestStatus === "Created")
        setActiveStatus(activeData)


      } else {
        setData([])
      }
    }
  }
  const fetchAppointments = async () => {
    let requestBody = {
      query: `
    query
    {
      searchAppointment(fromKey:"*",fromType:"*",toKey:"${localStorage.getItem('loginUserId')}",toType:"patient",status:"*",startDateTime:"*",endDateTime:"*"
      
      ) {
      appointmentId
      fromKey
      fromType
      fromName
      fromMail
      toKey
      toType
      toName
      toMail
      startDateTime
      endDateTime
      note
      mailText
      reference
      status
      createdBy
      createdTs
      }
    }`
    };
    requestBody = JSON.stringify(requestBody);
    // setProgress(true)
    let res = await oldgenericApi('searchAppointment', requestBody);
    if (!res) {
      // Error handling
      //console.log('Error')

    } else {
      // setProgress(false)
      if (res.data.searchAppointment != null && res.data.searchAppointment.length > 0) {
        var data = res.data.searchAppointment
        var newdata = data.map(i => ({
          ...i, "startDateTime":  moment.utc(i.startDateTime).local().format('YYYY-MM-DD HH:mm'), "endDateTime":moment.utc(i.endDateTime).local().format('YYYY-MM-DD HH:mm')
        }))
        setAppointments(newdata)
        var activeData = newdata.filter(x => x.status === "Created")
        setActiveApp(activeData)
      } else {
        setAppointments([])
      }
    }
  }
  const searchSubmit = (event) => {
    event.preventDefault();
    let value = document.querySelector("input[name='headerSearch']").value;
    if (value.length) {
      props.updateHeaderSearchValue({ value });
      props.history.push('/searchresults');
    }
  }
  const handleClick = (e) => {
    e.preventDefault()
    props.history.push('/patient')
  }
  const requestAppointment = (e) => {
    e.preventDefault()
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  const openCalendar = (e) => {
    e.preventDefault()
    props.history.push('/patient/doctorappointments')
  }
  const handlePageClickReq = (e, offset) => {
    e.stopPropagation()
    setoffsetReq(offset)
  }
  const handlePageClickApp = (e, offset) => {
    e.stopPropagation()
    setoffsetApp(offset)
  }
  console.log(activeStatus.length, "activeStatus", activeApp, activeApp.length)
  return (
    <>

      {/* <!-- Content Wrapper --> */}
      <div id="content-wrapper" className="d-flex flex-column">
        {/* <!-- Main Content --> */}
        <div id="content">
          {/* <!-- Topbar --> */}
          <nav className="navbar navbar-expand navbar-light bg-header-shadow topbar static-top">
            {/* <!-- Sidebar Toggle (Topbar) --> */}
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-2">
              <i className="fa fa-bars"></i>
            </button>
            <a href="#" className="ml-xs-1 ml-md-3 mr-xs-2 mr-md-5" onClick={handleClick}><img src={SmallLogo} alt="logo-small" /></a>
            {/* <!-- Topbar Search --> */}
            {/* <form noValidate autoComplete="off" onSubmit={searchSubmit} className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
              <div className="input-group globalsearch">
                <input type="text" className="form-control" placeholder="Search Accounts, Contacts & Studies" aria-label="Search Accounts, Contacts & Studies" aria-describedby="button-addon2" name="headerSearch" />
                <div className="input-group-append">
                  <button className="btn btn-primary-blue" type="submit" id="button-addon2"><i className="fa fa-search fa-sm"></i> Search</button>
                </div>
              </div>
            </form> */}
            <SimpleModal header="Request Appointment" open={open} onClose={onClose}>
              <div className="col-md-12">
                <AppointmentForm onClose={onClose} />
              </div>
            </SimpleModal>
            {/* <!-- Topbar Navbar --> */}
            <ul className="navbar-nav ml-auto">
              {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
              <li className="nav-item dropdown no-arrow d-sm-none">
                <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fa fa-search fa-fw"></i>
                </a>
                {/* <!-- Dropdown - Messages --> */}
                <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                  <form className="form-inline mr-auto w-100 navbar-search">
                    <div className="input-group">
                      <input type="text" className="form-control bg-light border-0 small" placeholder="Search Accounts, Contacts & Studies" aria-label="Search" aria-describedby="basic-addon2" />
                      <div className="input-group-append">
                        <button className="btn btn-primary-blue" type="button">
                          <i className="fa fa-search fa-sm"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </li>
              <li className="nav-item dropdown no-arrow cr" >
                <a className="nav-link dropdown-toggle" id="appointment" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <Tooltip title="Request Appointment" arrow><img src={ReqAppIcon} alt="Request Appointment" /></Tooltip>
                </a>
              </li>
              <li className="nav-item dropdown no-arrow" >
                <a className="nav-link" href="#" onClick={(e) => openCalendar(e)}>
                  <Tooltip title="Calendar" arrow><img src={Calendar} alt="calendar" width="20px" /></Tooltip>
                  {/* <span className="badge badge-danger badge-counter">10</span> */}
                </a>
              </li>
              {/* <!-- Nav Item - Alerts --> */}
              <li className="nav-item dropdown no-arrow">
                <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                // onClick={(e) => fetchAlerts(e)}
                >
                  <Tooltip title="Alerts" arrow><i className="far fa-bell fa-fw fa-lg"></i></Tooltip>
                  <span className="badge badge-danger badge-counter">
                    {/* {data.length > 0 ? data.filter(x => x.requestStatus === "Created").length > 0 ? parseInt(data.filter(x => x.requestStatus === "Created").length) : '' : ''} + {appointments.length > 0 ? parseInt(appointments.filter(x => x.status === "Created").length) > 0 ? appointments.filter(x => x.status === "Created").length : '' : '' } */}
                    {(activeStatus.length > 0 || activeApp.length > 0) ? (activeStatus.length + activeApp.length) == 0 ? "" : activeStatus.length + activeApp.length : ""}
                  </span>
                </a>

                <form id="form" className="dropdown-menu dropdown-menu-right shadow animated--grow-in alertCss" aria-labelledby="alertsDropdown">
                  <Alerts data={data} appointments={appointments} activeApp={activeApp} activeStatus={activeStatus} handlePageClickReq={handlePageClickReq} handlePageClickApp={handlePageClickApp} offsetReq={offsetReq} offsetApp={offsetApp} callView={callViewedApis} openCalendar={openCalendar}/>
                </form>
              </li>

              {/* <!-- Nav Item - User Information --> */}
              <li className="nav-item dropdown">
                <UserMenu />
              </li>
            </ul>
          </nav>
          {/* <!-- End of Topbar --> */}
        </div>
        {/* <!-- End of Main Content --> */}

      </div>
      {/* <!-- End of Content Wrapper --> */}

    </>

  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateHeaderSearchValue: (obj) => {
      return dispatch(
        {
          type: 'headerSearch',
          value: obj.value,
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

