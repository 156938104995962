import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHoc from '../../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew } from '../../../globalcomponents/formElements';
import { connect } from 'react-redux';
import HekmaDatePicker from '../HekmaDatePicker'
import { oldgenericApi, genericApi } from '../../../common/apiconfig'
import Multidropdown from '../../../globalcomponents/multidropdown'
import { timeInfo } from '../../../common/constants';
import StudyList from '../changeStudy'
import { sha256 } from 'js-sha256'
import DateTimePicker from '../../../globalcomponents/datetimepicker'
import moment from 'moment'
import ChipInput from 'material-ui-chip-input'

const ReqAppointment = (propsobj) => {
  let startTime = {
    value: '',
    label: '',
    error: false,
    helperText: '',
  }
  let endTime = {
    value: '',
    label: '',
    error: false,
    helperText: '',
  }
  let [starttimeData, setStarttimeData] = useState([...timeInfo])
  let [endtimeData, setEndtimeData] = useState([...timeInfo])
  let [starttime, setStarttime] = useState(startTime);
  let [endtime, setEndtime] = useState(endTime);
  let [progress, setProgress] = useState(false);
  let [patients, setPatients] = useState('')
  let [selectedPatients, setSelectedPatients] = useState('')
  let [options, setOptions] = useState([])
  const [startDatetime, setStartDatetime] = useState(null);
  const [endDatetime, setEndDatetime] = useState(null);
  const [startDatetimeErr, setStartDatetimeErr] = useState(false)
  const [startDatetimeText, setStartDatetimeText] = useState('')
  const [endDatetimeErr, setEndDatetimeErr] = useState(false)
  const [endDatetimeText, setEndDatetimeText] = useState('')
  const [emailDataErr, setEmailDataErr] = useState("")
  let [study, setStudy] = useState({
    studyId: "", studyNumber: "", parentAccountId: "", studyTitle: ""
  })
  const [emailData, setEmailData] = useState([])
  let [text, setText] = useState('Add Appointment')
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (propsobj?.info && propsobj?.info.length > 0) {
      propsobj.UpdateFormField({ name: "app_date", value: propsobj.info[0].startDate })
      propsobj.UpdateFormField({ name: "app_starttime", value: propsobj.info[0].startTime })
      propsobj.UpdateFormField({ name: "app_endtime", value: propsobj.info[0].endTime })
      propsobj.UpdateFormField({ name: "app_comments", value: propsobj.info[0].note })
      propsobj.UpdateFormField({ name: "app_subject", value: propsobj.info[0].subject })
      setText("Update Appointment")
    } else {
      propsobj.UpdateFormField({ name: "app_date", value: "" })
      propsobj.UpdateFormField({ name: "app_starttime", value: "" })
      propsobj.UpdateFormField({ name: "app_endtime", value: "" })
      propsobj.UpdateFormField({ name: "app_comments", value: "" })
      propsobj.UpdateFormField({ name: "app_subject", value: "" })
      setText("Add Appointment")
    }

  }, [propsobj.info, propsobj.edit, propsobj.delete]);

  // useEffect(() => {
  //   if (propsobj?.datefilter.length > 0) {
  //     var data = [...starttimeData]
  //     var data1 = [...endtimeData]
  //     var newstartData = data.filter(function (o1) {
  //       return propsobj.datefilter.every(function (o2) {
  //         return o1.value != o2.startTime.slice(0, (o2.startTime.length - 3))
  //       });
  //     });
  //     var newendData = data1.filter(function (o1) {
  //       return propsobj.datefilter.every(function (o2) {
  //         return o1.value != o2.endTime.slice(0, (o2.endTime.length - 3))
  //       });
  //     });
  //     setStarttimeData(newstartData);
  //     setEndtimeData(newendData);
  //   }
  // }, [propsobj?.datefilter])
  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }
  const autoPropstoStudies = {
    'options': propsobj.formValues.studylist,
    propsobj,
    keys: ['studyNumber', 'studyTitle'],
    error: '',
    helperText: ''
  }
  const autoPropsToStarttime = {
    'options': starttimeData,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropsToEndtime = {
    'options': endtimeData,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropstoaccount = {
    'options': patients || []
    // [{ firstName: "All", lastName: '' }, ...patients]
    // [{ title: "All", year: 0 },
    // { title: "The Shawshank Redemption", year: 1994 },
    // { title: "The Godfather", year: 1972 },
    // { title: "The Godfather: Part II", year: 1974 },
    // ]
    ,
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['firstName', 'lastName']
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('reqApp', event);
    if (startDatetime == null || "") {
      setStartDatetimeErr(true)
      setStartDatetimeText("This field is required")
    }
    if (endDatetime == null || "") {
      setEndDatetimeErr(true)
      setEndDatetimeText("This field is required")
    }
    if (startDatetime != null || "" && endDatetime != null || "") {
      if (startDatetime.getTime() >= endDatetime.getTime()) {
        setEndDatetimeErr(true)
        setEndDatetimeText("This should be greater than start date & time")
      }
    }
    var newemails = []
    if (emailDataErr.length > 0) {
      return
    } else {
      newemails = emailData.length > 0 ? emailData.map((item, i) => {
        return { patientId: "381", email: item, firstName: "", lastName: "",newuser:true }
      }) : []
    }
    let allSelectedPatients = [...selectedPatients, ...newemails]
    if (setGo && startDatetime != (null || "") && endDatetime != (null || "")) {
      if (startDatetime.getTime() < endDatetime.getTime()) {
        let form = propsobj.formValues;
        setProgress(true);
        for (var i = 0; i < allSelectedPatients.length; i++) {
          callCreateAppointment(allSelectedPatients[i], form)
        }
      }
    }
  }
  const callCreateAppointment = async (patientinfo, form) => {
    let requestBody = `
    mutation{
    createAppointment(fromKey:"${localStorage.getItem('loginUserId')}",fromType:"doctor",fromName:"${localStorage.getItem("loggedInUser")}",
      fromMail:"${localStorage.getItem("loggedUserEmail")}",
      toKey:"${patientinfo.patientId}",toType:"patient",toName:"${patientinfo.firstName + ' ' + patientinfo.lastName}",toMail:"${patientinfo.email}",
    startDateTime:"${moment.utc(startDatetime).format('YYYY-MM-DD HH:mm:ss')}",
      endDateTime:"${moment.utc(endDatetime).format('YYYY-MM-DD HH:mm:ss')}",
      note:${JSON.stringify(form.app_comments)},
    mailSubject:"${form.app_subject}",
      mailText:"/patient/${sha256(localStorage.getItem("loggedInUser") + form.app_date.toString() + form.app_starttime.toString())}/videocall",
    docMailText:"/site/${sha256(localStorage.getItem("loggedInUser") + form.app_date.toString() + form.app_starttime.toString())}/video",
      reference:""
      
      ) {
        code
        type
        message
        attributes
      }
    }`;

    requestBody = JSON.stringify({ query: requestBody });
    let res = await oldgenericApi('  createAppointment', requestBody, form);
    setProgress(false);

    if (res && res.data.createAppointment.code === '200') {
      propsobj.onClose()
      propsobj.callAppointmentsInfo()
      propsobj.UpdateFormField({ name: "app_date", value: "" })
      propsobj.UpdateFormField({ name: "app_starttime", value: "" })
      propsobj.UpdateFormField({ name: "app_endtime", value: "" })
      propsobj.UpdateFormField({ name: "app_comments", value: "" })
      setStartDatetime(null)
      setEndDatetime(null)
    } else {
      console.log('Error')
    }
  }
  const handleCancel = (event) => {
    event.preventDefault();
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
    propsobj.history.goBack();
  }
  const handleStarttime = (data) => {
    console.log(data, "st")
    propsobj.UpdateFormField({ name: "app_endtime", value: '' })
    setEndtime('')
  }
  const handleEndtime = (data) => {
    // propsobj.UpdateFormField({ name: "app_endtime", value: data.selected.value })
  }
  const getPatients = (value) => {
    console.log(value, "ckkk")
    setSelectedPatients(value)
  }
  const handleStudies = async (obj) => {
    let requestBody = {
      query: `query {
              getStudySitePatientFetch(studyId: "${obj.selected.studyId}", siteId: "${propsobj.formValues.autosite.sponsorAccountId}") {
                  studyId
                  siteId
                  patientId
                  subjectStatus
                  onboardStatus
                  subjectId
                  alternateId
                  firstName
                  middleName
                  lastName
                  email
                  phone
                  address1
                  address2
                  city
                  state
                  zip
                  country
                  language

          }
      }`
    };
    requestBody = JSON.stringify(requestBody);
    let res = await genericApi('getStudySitePatientFetch', requestBody);
    setProgress(false)
    if (!res) {
      // Error handling
      //console.log('Error!')

    } else {
      if (res.data.getStudySitePatientFetch.length) {
        setStudy(obj.selected);
        setPatients(res.data.getStudySitePatientFetch)
      } else {
        setPatients([])
      }
    }


  }

  const renderOption = option => {
    return (
      <>
        {option['studyNumber'] + ' - ' + option['studyTitle']}
      </>
    )
  }

  const getOptionLabel = option => {
    // e.g value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    if (option && option.inputValue) {
      return option.inputValue;
    }

    if (option && option['studyNumber']) {
      return option && (option['studyNumber'] + '  -  ' + option['studyTitle'])
    } else {
      return '';
    }

  }
  const handleStartDatetimeChange = (data) => {
    setStartDatetime(data)
    setStartDatetimeErr(false)
    setStartDatetimeText("")
  }
  const handleEndDatetimeChange = (data) => {
    setEndDatetime(data)
    setEndDatetimeErr(false)
    setEndDatetimeText("")
  }
  const handleAddChip = (chip) => {
    var data = [...emailData]
    data.push(chip)
    setEmailData(data)
    var emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (!emailReg.test(chip)) {
      setEmailDataErr("Invalid Email Address")
    } else {
      setEmailDataErr("")
    }

  }
  const handleDeleteChip = (chip, index) => {
    var chipps = [...emailData]
    chipps.splice(index, 1)
    //console.log(chipps)
    setEmailData(chipps)
    setEmailDataErr("")
  }
  const otherEmails = (e) => {
    e.preventDefault()
    setShow(!show)
    setEmailData([])
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* 
      <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO SITE LIST</div><br/> */}


      <div className="">
        <form noValidate autoComplete="off" id="reqApp" className="row" onSubmit={formSubmit}>
          <div className="inputBox col-md-12">
            <div className="row">
              <div className="col-md-12 mb-3"> <AutoOptionsNew
                {...autoPropstoStudies}
                autoValue={study}
                name="autostudy"
                label="Select Study"
                keyName='studyTitle'
                renderOption={renderOption}
                getOptionLabel={getOptionLabel}
                must={'true'}
                {...propsobj.state}
                // dispatch={dispatch}
                callback={handleStudies} /></div>
              <div className="col-md-12 mb-3"><Multidropdown {...autoPropstoaccount} name="patientMultilist" label="Select Patients" keyName='firstName' must="false" callback={getPatients} />
                <span className="fr cr" style={{
                  fontSize: "12px", color: "#23416F", marginTop: "5px",
                  fontWeight: "500"
                }} onClick={e => otherEmails(e)}>{show ? "Hide/Clear Others" : "Add Others"}</span></div>
              {show &&
                <div className="col-md-12 mb-3">
                  <ChipInput
                    value={emailData}
                    onAdd={(chip) => handleAddChip(chip)}
                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                    variant="standard"
                    label="Emails"
                    // error={emailDataErr.length>0 ?true :false}
                    // errortext={emailDataErr}
                    style={{ width: "100%", fontSize: "14px", marginTop: "0px", minHeight: "15px" }}
                  />
                  {emailDataErr.length > 0 && <span className="phoneerr">{emailDataErr}</span>}
                </div>
              }
              <div className="col-md-12 mb-3">
                <InputField {...textProps} name="app_subject" label="Subject" must={'true'} type="text" />
              </div>

              {/* <div className="col-md-4 mb-3"> 
              
              <HekmaDatePicker name="app_date" label="Select Date" must={'true'} minDate={new Date()} disabled /></div>
              <div className="col-md-4 ">
                <AutoOptions {...autoPropsToStarttime} autoValue={starttime} name="app_starttime" label="Start Time" keyName='value' must={'true'} callback={handleStarttime} />
              </div>
              <div className="col-md-4 ">
                <AutoOptions {...autoPropsToEndtime} autoValue={endtime} name="app_endtime" label="End Time" keyName='value' must={'true'} callback={handleEndtime} />
              </div> */}
              <div className="col-md-6">
                {/* <HekmaDatePicker name="app_date" label="Select Date" must={'true'} minDate={new Date()} /> */}
                <DateTimePicker label="Start Date & Time" value={startDatetime} handleChange={handleStartDatetimeChange} must={'true'} name="startDatetime" error={startDatetimeErr} errortext={startDatetimeText} />
              </div>
              <div className="col-md-6">
                {/* <HekmaDatePicker name="app_date" label="Select Date" must={'true'} minDate={new Date()} /> */}
                <DateTimePicker label="End Date & Time" value={endDatetime} handleChange={handleEndDatetimeChange} mindate={startDatetime} must={'true'} name="endDatetime" error={endDatetimeErr} errortext={endDatetimeText} />
              </div>
            </div>

            <InputField {...textProps} multiline={true} rows={3} rowsMax={3} variant="outlined" name="app_comments" placeholder="Description" must={'true'} type="text" />
          </div>
          <div className="buttonContainer col-md-12 mb-3 mt-3">
            <button type="submit" className="btn btn-primary-blue btn-rounded btn-small " >{text}</button>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReqAppointment));



