import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../../../../globalcomponents/formElements';
import { connect } from 'react-redux';
import BackIcon from '../../../../img/back1.svg'
import { checkPermission } from '../../../../common/utilis';
import { getFiles, apiCall, fileUpload } from '../../../../common/apiconfig';
import Tooltip from '@material-ui/core/Tooltip';
import VideoPlayer from 'simple-react-video-thumbnail'
import FileViewModal from './fileviewmodal'
import FileHistory from './filehistory'
import PdfPreview from './pdf-preview'
import DotsIcon from '../../../../img/dots2.svg'
import Update from '../../../../img/update.png'
import User from '../../../../img/user.png'
import Download from '../../../../img/download.png'
import History from '../../../../img/history.png'
import Status from '../../../../img/status2.png'
import Img from '../../../../img/Resources2.png'
import FileUpdate from './fileupdate'
import OwnerDetails from './ownerDetails'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Paginationn } from '../../../../common/pagination'
import { appConfig } from "../../../../common/constants";

function TabContainer(props) {
  return (
    <Typography component="div" >
      {props.children}
    </Typography>
  );
}
const ViewCourses = (props) => {
  let [isEditCourse, setisEditCourse] = useState(checkPermission('S_Courses_Update', 'Update'));
  const [value, setValue] = React.useState(0);
  const [offset, setoffset] = useState(0)
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [links, setLinks] = useState(props.state.courseData.link)
  let [documents, setDocuments] = useState([])
  let [videos, setVideos] = useState([])
  let [pdfUrl, setPdfUrl] = useState("")
  let [imgUrl, setImgUrl] = useState("")
  const [open, setOpen] = useState(false)
  const [fileData, setFileData] = useState("")
  const [openHistory, setOpenHistory] = useState(false)
  const [openOwner, setOwner] = useState(false)
  const [header, setHeader] = useState('')
  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };
  const statusChange = async (event, item, index, type) => {
    event.preventDefault()
    // setProgress(true)
    let requestBody = `
                  mutation {
                    switchStatusSupportDetails(id:"${item.ID}"){
                      code
                      type
                      message
                      attributes
                    }
                  }`
      ;

    requestBody = JSON.stringify({ query: requestBody });
    let res = await apiCall('switchStatusSupportDetails', requestBody);

    if (res && res.data.switchStatusSupportDetails.code === '200') {
      // if (type === "vidoes") {

      // }
      if (type === "documents") {
        let data = [...documents]
        for (var i = 0; i < data.length; i++) {
          if (i == index) {
            data[index]["status"] = (data[i]["status"] == "Y" ? "N" : "Y")
          }
        }
        setDocuments(data)
      } else if (type === "link") {
        let data = [...links]
        for (var i = 0; i < data.length; i++) {
          if (i == index) {
            data[index]["status"] = (data[i]["status"] == "Y" ? "N" : "Y")
          }
        }
        setLinks(data)
      } else {
        let data = [...videos]
        for (var i = 0; i < data.length; i++) {
          if (i == index) {
            data[index]["status"] = (data[i]["status"] == "Y" ? "N" : "Y")
          }
        }
        setVideos(data)
      }
      setProgress(false);
    } else {
      //console.log('Error!')
    }


  };
  useEffect(() => {
    props.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, [])
  const handleClick = async (event) => {
    event.preventDefault();
    await props.UpdateEntireForm(props.state.courseData.cr)
    if(localStorage.getItem('whichApp')==="Site"){
      props.history.push('/site/' + props.plural + '/update');
    }else{
      props.history.push('/app/' + props.plural + '/update');
    }
   
  }

  const { ID = "", name = '', type = '', category = '', summary = "", language = "", version = "" } = props.state.courseData.cr;

  const goBack = (event) => {
    event.preventDefault();
    props.history.goBack()
  }
  const download = async (e, file) => {
    e.preventDefault()
    let res = await getFiles('fileupload', file, "GET");
    if (res.code == 200) {
      var data = JSON.parse(res.attributes)
      window.open(data.URL)
    }
  }
  useEffect(() => {
    if (props.state.courseData.document.length > 0) {
      callDocs(props.state.courseData.document)
    } else {
      props.UpdateFormField({ name: "docUrls", value: [] })
    }
    if (props.state.courseData.video.length > 0) {
      callVideos(props.state.courseData.video)
    } else {
      props.UpdateFormField({ name: "videoUrls", value: [] })
    }

    if (localStorage.getItem("siterole") === "Business User") {
      let data = [...props.state.courseData.link, ...props.state.courseData.document, ...props.state.courseData.video]
      let obj = data.find(o => o.addedById === localStorage.getItem('loginUserId'));
      //console.log(obj, "obj")
      if (obj != undefined) {
        //console.log(obj, "objinside")
        setisEditCourse("1")
      }

    }
  }, [props.state.courseData.document, props.state.courseData.video])


  const callDocs = async (data) => {
    setProgress(true)
    var urldata = data.map(v => ({ ...v, url: "" }))
    for (var i = 0; i < data.length; i++) {
      let res = await getFiles('fileupload', data[i], "GET");
      if (res.code === 200) {
        let data = JSON.parse(res.attributes)
        urldata[i]["url"] = data.URL
      }

    }
    setDocuments(urldata)
    props.UpdateFormField({ name: "docUrls", value: urldata })
    setProgress(false)
  }
  const callVideos = async (data) => {
    setProgress(true)
    var urldata = data.map(v => ({ ...v, url: "" }))
    for (var i = 0; i < data.length; i++) {
      let res = await getFiles('fileupload', data[i], "GET");
      if (res.code === 200) {
        let data = JSON.parse(res.attributes)
        urldata[i]["url"] = data.URL
      }
    }
    setVideos(urldata)
    props.UpdateFormField({ name: "videoUrls", value: urldata })
    setProgress(false)
  }
  const image = (url) => {
    return <img src={url} alt="image" height="75" width="75" />
  }



  const onClose = () => {
    setOpen(false)
    setOpenHistory(false)
    setOwner(false)
  }
  const pdfClick = (e, url) => {
    e.preventDefault()
    setOpen(true)
    setPdfUrl(url)
    setImgUrl("")
  }
  const imgClick = (e, url) => {
    e.preventDefault()
    setOpen(true)
    setImgUrl(url)
    setPdfUrl("")
  }
  let hoverElement = null;
  const hoverEnter = (event, rowData) => {

    hoverElement = event.currentTarget;
    hoverElement.classList.add('showPopover')
  }

  const hoverLeave = (event, rowData) => {
    //hoverElement = event.currentTarget;

    try {
      hoverElement.classList.remove('showPopover')
    } catch (error) {

    }
  }

  const tpopoverEnter = (event, rowData) => {

    //hoverElement = event.currentTarget;
    //hoverElement.classList.add('showPopover')
  }

  const tpopoverLeave = (event, rowData) => {
    //hoverElement = event.currentTarget;
    // hoverElement.classList.remove('showPopover')
  }
  const callUpdateApi = (type, data) => {
    var newdata = props.state.courseData
    newdata[type] = data
    props.updateCourseData(newdata)
    try {
      hoverElement.classList.remove('showPopover')
    } catch (error) {

    }
  }
  const loading = (status) => {
    setProgress(status)
  }
  const fileversion = (e, data, header) => {
    e.preventDefault()
    setOpenHistory(true)
    setHeader(header)
    setFileData(data)
  }
  const ownerDetailsClick = (e, data) => {
    e.preventDefault()
    setOwner(true)
    setFileData(data)
  }
  String.prototype.limit = function (length) {
    return this.length > length ? (this.substring(0, length) + '...') : this;
  }
  const handlePageClick = (e,offset) => {
    setoffset(offset)
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      {/* <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO {props.plural} LIST</div><br /> */}

      <div className="mt-4">
        <FileViewModal open={open} pdfUrl={pdfUrl} imgUrl={imgUrl} onClose={onClose} />
        <FileHistory open={openHistory} onClose={onClose} filedata={fileData} header={header} />
        <OwnerDetails open={openOwner} onClose={onClose} filedata={fileData} header="Owner Details" />
        <form noValidate autoComplete="off" id="addAccountForm" className="row h-form-1">
          <div className="col-md-6">
            <div className="d-flex justify-content-end">
              <div class="secondary-header mr-auto">{props.type} Details</div>
              {
                !props.sponsorStudies && isEditCourse === "1" ?

                  <div className="mt-6">
                    <button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
                      <i className="fas fa-pencil-alt"></i>&nbsp; EDIT</button>
                  </div>
                  : ""}
            </div>
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">{props.type} INFORMATION</div>
                  <div className="inputBox">
                    <ViewRow text="Name" textvalue={name} />
                    <ViewRow text="Category" textvalue={category} />
                    <ViewRow text="Summary" textvalue={summary} />
                    <ViewRow text="Type" textvalue={type} />
                    <ViewRow text="Language" textvalue={language} />
                    <ViewRow text="Version" textvalue={version} />

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6" style={{ display: "flex", alignItems: "center" }}>
            <img src={Img} alt="Image" />
          </div>
          <div className="col-md-12">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">{props.type} FILES</div>
                  <div className="inputBox">
                    <Tabs
                      value={value}
                      onChange={handleTabsChange}
                      indicatorColor="primary"
                      textColor="primary"

                    >
                      <Tab label="Links" />
                      <Tab label="Documents" />
                      <Tab label="Videos" />
                    </Tabs>
                    {value === 0 && <TabContainer>

                      <>
                        {/* <div style={{ fontSize: "14px", fontWeight: "700", color: "#626262", marginLeft: "5px" }}>Links</div> */}
                        {/* <div className="inputBox"> */}
                        {props.state.courseData.link.length > 0 ?
                          <table className="viewCourseLinks">
                            <thead>
                              <tr>
                                <th style={{ width: "7%" }}>S. NO</th>
                                <th style={{ width: "35%" }}>LINK/URL</th>
                                <th style={{ width: "40%" }}>DESCRIPTION</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                links.slice(offset, appConfig.paginationLimit + offset).map((item, i) => (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td><a href={item.detail1} target="_blank">{item.detail1.limit(40)}</a></td>
                                    <td>{item.detail2 == ("" || null || undefined) ? '---' :item.detail2.limit(40)}</td>
                                    <td className="filestatus">{item.status == "Y" ? "Active" : "Inactive"}{
                                      !props.sponsorStudies && isEditCourse === "1" ?
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={item.status === "Y" ? true : false}
                                              onChange={(e) => statusChange(e, item, i, "link")}
                                              name="checkedB"
                                              color="primary"
                                              size="small"
                                            />
                                          }
                                          // label="Status"
                                          labelPlacement="start"
                                        />
                                        : ""}</td>
                                  </tr>

                                ))
                              }
                              <tr>
                                <td colSpan="5">
                                  {links.length > appConfig.paginationLimit &&
                                    <Paginationn handlePageClick={handlePageClick} data={links.length} offset={offset} />
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table> : <div className="nodata">No Data Found</div>}
                      </>
                    </TabContainer>
                    }
                    {value === 1 && <TabContainer>

                      <>
                        {/* <div style={{ fontSize: "14px", fontWeight: "700", color: "#626262", marginLeft: "5px" }}>Documents</div> */}
                        {/* <div className="inputBox"> */}
                        {documents.length > 0 ?
                          <table className="viewCourseLinks">
                            <thead>
                              <tr>
                                <th style={{ width: "7%" }}>S. NO</th>
                                <th style={{ width: "15%" }}>TYPE</th>
                                <th style={{ width: "28%" }}>NAME</th>
                                <th style={{ width: "20%" }}>PREVIEW</th>
                                <th>STATUS</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                documents.slice(offset, appConfig.paginationLimit + offset).map((item, i) => (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.detail3}</td>
                                    <td onClick={(e) => { download(e, item) }} style={{ cursor: "pointer" }}><Tooltip title="Click to download" arrow><a href="#">  {item.detail2}</a></Tooltip></td>
                                    <td className="previewpdf">{item.detail3.includes("image") ? <span onClick={(e) => imgClick(e, item.url)}>{image(item.url)}</span> : item.detail3.includes("pdf") && <span style={{ cursor: "pointer" }} onClick={(e) => pdfClick(e, item.url)}><PdfPreview url={item.url} show={false} width="75" />
                                    </span>}
                                    </td>
                                    <td className="filestatus">{item.status == "Y" ? "Active" : "Inactive"}
                                      {
                                        !props.sponsorStudies && isEditCourse === "1" ?
                                          <FormControlLabel
                                            control={
                                              <Switch
                                                checked={item.status === "Y" ? true : false}
                                                onChange={(e) => statusChange(e, item, i, "documents")}
                                                name="checkedB"
                                                color="primary"
                                                size="small"
                                              />
                                            }
                                            // label="Status"
                                            labelPlacement="start"
                                          />
                                          : ""}
                                    </td>
                                    <td className="filedetails">
                                      <div
                                        className="fullwidth text-center posrelative"
                                        onMouseEnter={(event) => hoverEnter(event, props.data)}
                                        onMouseLeave={(event) => hoverLeave(event, props.data)}
                                      >
                                        <img src={DotsIcon} alt="click to view" />
                                        <div className="tpopover"
                                          onMouseEnter={(event) => tpopoverEnter(event, props.data)}
                                          onMouseLeave={(event) => tpopoverLeave(event, props.data)}
                                        >
                                          <a onClick={(e) => { download(e, item) }}><img src={Download} alt="" />Download File</a>
                                          {
                                            !props.sponsorStudies && isEditCourse === "1" ?
                                              <a><img src={Update} alt="" />
                                                <FileUpdate text="File Update" dataToAccept='image/*,.pdf,.xlsx,.txt,.xls,.ppt,.pptx,.doc,.docx' item={item} type="Document" buArea="Resource" buId={ID} callUpdateApi={callUpdateApi} loading={loading} />
                                              </a> : ""}
                                          {/* <a onClick={(e) => fileversion(e, item, "File Versions")} ><img src={History} alt="" />File Versions</a> */}
                                          <a onClick={(e) => ownerDetailsClick(e, item)} ><img src={User} alt="" />Owner Details</a>
                                          <a onClick={(e) => fileversion(e, item, "File History")} ><img src={History} alt="" />File History</a>



                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              }
                              <tr>
                                <td colSpan="6">
                                  {documents.length > appConfig.paginationLimit &&
                                    <Paginationn handlePageClick={handlePageClick} data={documents.length} offset={offset} />
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table> : <div className="nodata">No Data Found</div>}

                      </>
                    </TabContainer>
                    }
                    {value === 2 && <TabContainer>

                      <>
                        {/* <div style={{ fontSize: "14px", fontWeight: "700", color: "#626262", marginLeft: "5px" }}>Videos</div> */}
                        {/* <div className="inputBox"> */}
                        {videos.length > 0 ?
                          <table className="viewCourseLinks">
                            <thead>
                              <tr>
                                <th style={{ width: "7%" }}>S. NO</th>
                                <th style={{ width: "10%" }}>TYPE</th>
                                <th style={{ width: "38%" }}>NAME</th>
                                <th style={{ width: "20%" }}>PREVIEW</th>
                                <th style={{ width: "15%" }}>STATUS</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                videos.slice(offset, appConfig.paginationLimit + offset).map((item, i) => (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.detail3}</td>
                                    <td onClick={(e) => { download(e, item) }} style={{ cursor: "pointer" }}><Tooltip title="Click to download" arrow><a href="#">  {item.detail2}</a></Tooltip></td>
                                    <td className="center">
                                      <React.Fragment key={item.url}>
                                        <VideoPlayer videoUrl={item.url} snapshotAt={2} />
                                      </React.Fragment>

                                    </td>
                                    <td className="filestatus">{item.status == "Y" ? "Active" : "Inactive"}
                                      {
                                        !props.sponsorStudies && isEditCourse === "1" ?

                                          <FormControlLabel
                                            control={
                                              <Switch
                                                checked={item.status === "Y" ? true : false}
                                                onChange={(e) => statusChange(e, item, i, "videos")}
                                                name="checkedB"
                                                color="primary"
                                                size="small"
                                              />
                                            }
                                            // label="Status"
                                            labelPlacement="start"
                                          />
                                          : ""}
                                    </td>
                                    <td className="filedetails">
                                      <div
                                        className="fullwidth text-center posrelative"
                                        onMouseEnter={(event) => hoverEnter(event, props.data)}
                                        onMouseLeave={(event) => hoverLeave(event, props.data)}
                                      >
                                        <img src={DotsIcon} alt="click to view" />
                                        <div className="tpopover"
                                          onMouseEnter={(event) => tpopoverEnter(event, props.data)}
                                          onMouseLeave={(event) => tpopoverLeave(event, props.data)}
                                        >
                                          <a onClick={(e) => { download(e, item) }}><img src={Download} alt="" />Download File</a>
                                          {
                                            !props.sponsorStudies && isEditCourse === "1" ?
                                              <a><img src={Update} alt="" />
                                                <FileUpdate text="File Update" dataToAccept='video/*' item={item} type="Video" buArea="Resource" buId={ID} callUpdateApi={callUpdateApi} loading={loading} />
                                              </a> : ""}
                                          {/* <a onClick={(e) => fileversion(e, item, "File Versions")} ><img src={History} alt="" />File Versions</a> */}
                                          <a onClick={(e) => ownerDetailsClick(e, item)} ><img src={User} alt="" />Owner Details</a>
                                          <a onClick={(e) => fileversion(e, item, "File History")} ><img src={History} alt="" />File History</a>

                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              }
                              <tr>
                                <td colSpan="6">
                                  {videos.length > appConfig.paginationLimit &&
                                    <Paginationn handlePageClick={handlePageClick} data={videos.length} offset={offset} />
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table> : <div className="nodata">No Data Found</div>}
                      </>
                    </TabContainer>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateCourseData: (obj) => {
      return dispatch(
        {
          type: 'updateCourseData',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewCourses));



