import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction' // needed for dayClick
// import BackIcon from '../../../img/back1.svg'
import moment from 'moment';
import Button from '@material-ui/core/Button';
import SimpleModal from '../../../globalcomponents/simplemodal'
import { oldgenericApi, genericApi, getFileUrl } from '../../../common/apiconfig';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
// import Calendar from '../../../img/calendar.svg'
import Popper from '../../../globalcomponents/popper'
import { PatientStudyInfo } from '../common/createSessionPatient'
import RecordingsChats from '../../../siteSrc/components/header/RecordingsChats'
const PatientAppointments = (props) => {
  let [progress, setProgress] = useState(false);
  let [events, setEvents] = useState([]);
  let [oldEvents, setOldEvents] = useState([]);
  let [open, setOpen] = useState(false);
  let [scheduleId, setScheduleId] = useState("")
  const [recordChatData, setRecordChatData] = useState('')
  const [openRecording, setOpenRecording] = useState(false)

  useEffect(async() => {
    callApi()
    // callGetStudyProgress()
    var data = await PatientStudyInfo()
    if (data.length > 0) {
      callEventsApi(props.formValues.patientStudyInfo?.siteID, props.formValues.patientStudyInfo?.studyID, localStorage.getItem('loginUserId'))
    }
  }, [props.formValues.patientdata])

  // const callGetStudyProgress = async () => {
  //   setProgress(true)
  //   let requestBody = {
  //     query: `query {        
  //        getStudyProgress(patientId:"${localStorage.getItem('loginUserId')}") {
  //       studyID
  //       accountID
  //       siteID
  //       scheduleID
  //     }
  //  }`
  //   };
  //   requestBody = JSON.stringify(requestBody);
  //   let res = await oldgenericApi('getStudyProgress', requestBody);
  //   if (!res) {
  //     // Error handling
  //     //console.log('Error on getting patient data!')

  //   } else {
  //     if (res.data.getStudyProgress.length > 0) {
  //       console.log(res.data.getStudyProgress, "res.data.getStudyProgress")
  //       var data = res.data.getStudyProgress
  //       for (var i = 0; i < data.length; i++) {
  //         var sessioncreated = await CreateSession(data[i].accountID, data[i].siteID, data[i].studyID)
  //         if (sessioncreated) {
  //           callEventsApi(data[i].siteID, data[i].studyID, localStorage.getItem('loginUserId'))
  //         } else {
  //           alert('session not created')
  //         }
  //       }

  //     }
  //   }
  // }

  const callEventsApi = async (siteid, studyid, patientid) => {
    let requestBody = {
      query: `
          query {
            getScheduleDetailsPatient(studyId:"${studyid}",patientId:"${patientid}",siteId:"${siteid}") {
              schedule {
                scheduleID
                studyId
                scheduleDsc
                effectiveDate
                createdBy
                createdTs
                modifiedBy
                modifiedTs
              }
              scheduleEventList {
                scheduleID
                eventID
                eventType
                eventOrder
                eventName
                eventKey
              }
              scheduleSegmentList {
                scheduleID
                segmentOrder
                segmentName
                start
                end
                varience
              }
              linkRowColumnPatient {
                patSchedulePk
                patientID
                siteId
                scheduleID
                rowID
                columnID
                startDate
                endDate
                segmentVariance
                actualDate
                expectedDate
                statusInd
              }
          }
      }`
    };
    requestBody = JSON.stringify(requestBody);

    let res = await genericApi('getScheduleDetailsPatient', requestBody);
    if (!res) {
      // Error handling
      //console.log('Error')

    } else {
      //console.log(res)
      if (res.data.getScheduleDetailsPatient != null) {
        var eventsName = res.data.getScheduleDetailsPatient.scheduleEventList
        var datesData = res.data.getScheduleDetailsPatient.linkRowColumnPatient
        var events = []
        for (var i = 0; i < eventsName.length; i++) {
          for (var j = 0; j < datesData.length; j++) {
            if (eventsName[i].eventOrder === datesData[j].rowID) {
              var obj = {
                id: i,
                description: eventsName[i].eventName,
                start: moment(new Date(datesData[j].startDate)).format(),
                end: moment(new Date(datesData[j].startDate)).format(),
                // metadata: data[i]
                // allDay: true
              }
              events.push(obj)
            }
          }
        }
        setProgress(false)
        setOldEvents(events)
      }

    }
  }

  const callApi = async () => {
    setProgress(true)
    let requestBody = {
      query: `
      query
      {
        searchAppointment(fromKey:"*",fromType:"*",toKey:"${props.formValues.patientdata?.patientId}",toType:"patient", status:"*", startDateTime:"*",endDateTime:"*"
        
        ) {
            appointmentId
        fromKey
        fromType
        fromName
        fromMail
        toKey
        toType
        toName
        toMail
        startDateTime
        endDateTime
        note
        mailText
        reference
        status
        createdBy
        createdTs
        }
      }`
    };
    requestBody = JSON.stringify(requestBody);
    setProgress(true)
    let res = await oldgenericApi('searchAppointment', requestBody);
    if (!res) {
      // Error handling
      //console.log('Error')

    } else {
      console.log(res, "Calendar")
      setProgress(false)
      if (res.data.searchAppointment != null && res.data.searchAppointment.length > 0) {
        var data = res.data.searchAppointment
        var events = []
        for (var i = 0; i < data.length; i++) {
          var obj = {
            id: i,
            description: data[i].note,
            title: data[i].fromName,
            start: moment.utc(data[i].startDateTime).local().format('YYYY-MM-DD HH:mm'),
            end: moment.utc(data[i].endDateTime).local().format('YYYY-MM-DD HH:mm'),
            link: data[i].mailText,
            metadata: data[i]
            // allDay:true
            // color: "violet"
          }
          events.push(obj)
        }
        setEvents(events)
      }

    }

  }
  const goBack = () => {
    props.history.goBack()
  }
  const handleClick = () => {
    setOpen(true)
  }
  const dialogclose = () => {
    setOpen(false)
  }
  const handleDateClick = (arg) => {
    alert(arg.dateStr)
  }
  const eventClick = (info) => {
    var eventObj = info.event;
    // alert(eventObj.title)
  }
  console.log(events, oldEvents)
  var allEvents
  if (events.length > 0 && oldEvents.length > 0) {
    allEvents = [...events, ...oldEvents]
  } else if (oldEvents.length == 0 && events.length > 0) {
    allEvents = events
  } else if (oldEvents.length > 0 && events.length == 0) {
    allEvents = oldEvents
  } else {
    allEvents = []
  }
  const joincall = (e, url) => {
    e.preventDefault()
    props.history.push(url)
  }
  const clickRecording = (e, appointmentid) => {
    e.preventDefault()
    callRecordApi(appointmentid)
    setOpenRecording(true)
  }
  const onClose = () => {
    setOpenRecording(false)
  }
  const callRecordApi = async (id) => {
    let requestBody = {
      query: `query {
        getArtifactWithSupport(artifactoryId:"${id}") 
       {
        af {
          artifactoryId
          name
          summary
          type
          category
        }
         video {
           ID
           buArea
           buid
           type
           status
           detail1
           detail2
           detail3
           detail4
           addedById
           addedByName
           addedTs
         }
         
       }
     }
      `
    }
    requestBody = JSON.stringify(requestBody);
    let res = await oldgenericApi('getArtifactWithSupport', requestBody);
    if (!res) {
    } else {
      if (res?.data?.getArtifactWithSupport != null && res?.data?.getArtifactWithSupport.length > 0) {
        // if (res?.data?.getArtifactWithSupport[0].video.length > 0) {
        // let resurl = await getFileUrl('fileurl', res?.data?.getArtifactWithSupport[0]?.video[0], "GET");
        // var url = JSON.parse(resurl.attributes);
        // console.log(url, "videourl");
        // // }
        // setRecordChatData({ "chat": JSON.parse(res.data.getArtifactWithSupport[0].af.summary), "url": url.URL })
        var url = ''
        if (res?.data?.getArtifactWithSupport[0].video.length > 0) {
          let resurl = await getFileUrl('fileurl', res?.data?.getArtifactWithSupport[0].video[0], "GET");
          url = JSON.parse(resurl.attributes).URL;
          console.log(url, "videourl");
        }
        var chat = []
        if (JSON.parse(res.data.getArtifactWithSupport[0].af.summary.length > 0)) {
          chat = JSON.parse(res.data.getArtifactWithSupport[0].af.summary)
        }
        setRecordChatData({ "chat": chat, "url": url })
      } else {
        setRecordChatData({ "chat": [], "url": "" })
      }
    }
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* <div className="d-flex mb-3 justify-content-end">
        <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
          <i className="fa fa-plus-circle mr-1"></i>ADD APPOINTMENT</button>
      </div> */}
      <SimpleModal header="Recording & Chat" open={openRecording} onClose={onClose} update={false} width="80%">
        <div className="col-md-12">
          <RecordingsChats onClose={onClose} data={recordChatData} />
          {/* <AppointmentForm onClose={onClose} callAppointmentsInfo={callApi} /> */}
        </div>
      </SimpleModal>
      <div className="mt-3" style={{ marginBottom: "40px" }}>
        <FullCalendar
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'prevYear,prev,next,nextYear today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
          }}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          // ref={calendarComponentRef}
          droppable={false}
          editable={false}
          weekends={true}
          events={allEvents}
          eventClick={eventClick}
          contentHeight={"auto"}
          selectable={false}
          selectMirror={false}
          dayMaxEvents={false}
          dateClick={handleDateClick}
          eventContent={(info) =>
            <>
              {/* {console.log(info,"info")} */}
              <Popper info={info} linkclick={joincall} clickRecording={clickRecording} />
            </>
          }
        />
        {/* {
          open && <AddAppointmentForm open={open} dialogclose={dialogclose} title="Add Appointment" scheduleId={scheduleId} callApi={callApi}/>
        } */}
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientAppointments));

