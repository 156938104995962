import React, { useEffect, useState, useContext } from "react";
import Materialtable from '../../globalcomponents/materialtable';
// import { genericApi } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
// import { appConfig } from "../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
// import { HekmaTextSearchField } from '../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
// import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
// import { checkPermission } from '../../../common/utilis';
import picon from '../../img/360.svg'

function StudyPatient(props) {
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);


  useEffect(() => {
    console.log(props.formValues,"props.formValues")
    setData(props.formValues.studypatients)
    props.UpdateFormField({ name: "hidestudytab", value: false })
    props.UpdateFormField({name:"studyPatientsToken",value:false})
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, []);

  const handleClick = async (event) => {
    event.preventDefault();
    props.history.push('/app/trailmatch/registeredstudies');
  }

  const handleRowClick = async (event, rowData) => {

    props.UpdateFormField({ name: "patientdata", value: rowData })
    props.UpdateFormField({name:"studyPatientsToken",value:true})
    // await props.UpdateEntireForm(rowData);
    if(rowData.patient360==="Y"){
    props.history.push('/app/patient');
    }
    else{
      alert('patient360 not available')
    }
  }


  const Columns = [{
    title: "Patient ID", field: "patientId", cellStyle: {
      color: '#244271',
      fontWeight: 500,
      // textAlign: 'center'
    },
    render: data => <>{data.patientId +' '}{data.patient360==="Y" && <img src={picon} alt="360view" height="20px" width="20px"/>}</>
  },
  {
    title: "First Name", field: "patientFirstName",
    render: data => data.patientFirstName
  },
  {
    title: "Last Name", field: "patientLastName",
    render: data => data.patientLastName
  },
  {
    title: "Age", field: "patientAge",
    render: data => data.patientAge
  },
  {
    title: "Gender", field: "patientGender",
    render: data => data.patientGender
  },
  {
    title: "City", field: "patientCity",
    render: data => data.patientCity
  },
  {
    title: "State", field: "patientState",
    render: data => data.patientState
  },
  {
    title: "Country", field: "patientCountry",
    render: data => data.patientCountry
  },
  ]
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}



      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">{props.formValues.studypatientstext} </h1>
        <div>
          <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
            BACK</button>
        </div>
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card mb-0">
          <div className="hekmaGridBox">

            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
              search={true}
            />
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateRecentItems: (obj) => {
      return dispatch(
        {
          type: 'updateRecentItems',
          value: obj,
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudyPatient));


