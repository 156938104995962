import React, { useEffect } from 'react'
import Highcharts from 'highcharts/highstock'
import Highcharts3d from "highcharts/highcharts-3d.js";
import Cylinder from "highcharts/modules/cylinder.js";
import Funnel from "highcharts/modules/funnel3d";
import { data } from 'jquery';

Highcharts3d(Highcharts);
Cylinder(Highcharts);
Funnel(Highcharts);

const NewChart = (props) => {
  console.log(props, "tunnel")
  useEffect(() => {
    Highcharts.setOptions({
      colors: ['#87c7e3','#cce6f2','#faafdb','#c8a0d2','#ffe384','#89c5e5','#a1d9eb','#68d1c2','#ffa279','#abe87b','#87c7e3','#cce6f2','#faafdb','#c8a0d2','#ffe384','#89c5e5','#a1d9eb','#68d1c2','#ffa279','#abe87b','#87c7e3','#cce6f2','#faafdb','#c8a0d2','#ffe384','#89c5e5','#a1d9eb','#68d1c2','#ffa279','#abe87b']
      // '#058DC7', '#ffa279', '#ffafdb', '#c8a0d2', '#ffe384', '#89c5e5', '#FF9655', '#FFF263', '#6AF9C4']
  });
    Highcharts.chart('container786', {
      chart: {
        type: 'funnel3d',
        options3d: {
          enabled: true,
          alpha: 10,
          depth: 50,
          viewDistance: 50
        }
      },
      subtitle: {
        text: props.data ? "Patient Match  "+props.data[props.data.length-1][1] : "",
        verticalAlign: 'bottom',
        style: {
          color: '#1C3B62',
          fontWeight: 'bold',
          fontSize:"18px"
      }
      },
      title: {
        text: props.header,
        // verticalAlign: 'bottom',
      },
      accessibility: {
        screenReaderSection: {
          beforeChartFormat: '<{headingTagName}>{chartTitle}</{headingTagName}><div>{typeDescription}</div><div>{chartSubtitle}</div><div>{chartLongdesc}</div>'
        }
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: props.labels ? true : false,
            format: '({point.y})',
            allowOverlap: false,
            y: 10
          },
          events: {
            click: function (event){
              props.onClickFunnel(event)
            }
          },
          // center: ['40%', '50%'],
          neckWidth: '30%',
          neckHeight: '35%',
          width: '80%',
          height: '80%',
          tooltip: {
            headerFormat: '<span style="font-size: 10px"><b>{point.key}</b></span><br/>',
            pointFormat: 'Match: <b>{point.y:f}</b>  '
          }
        }
        
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Match',
        data: props.data ? props.data :[]
      }],
      responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                plotOptions: {
                    series: {
                        dataLabels: {
                            inside: true
                        },
                        center: ['50%', '50%'],
                        width: '100%'
                    }
                }
            }
        }]
    }
    });

  }, [props])
  return (
    <div id="container786" ></div>
  )
}


export default NewChart