import React, { useEffect, useState, useContext } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../../../globalcomponents/formElements';
import { genericApi } from '../../../common/apiconfig';
import { connect } from 'react-redux';
const StudyDetails = (propsobj) => {

  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [studyId, setstudyId] = useState('');


  // useEffect(() => {
  //   async function fetchData() {

  //     let requestBody = {
  //       query: `
  //           query {
  //             getStudy(studyId: "${reduxstate.form.autostudy.studyId}") {
  //                       studyId
  //                 studyNumber
  //                 parentAccountId
  //                 parentAccountName
  //                 studyTitle
  //                 nameofIMP
  //                 devPhase
  //                 status
  //                 primaryEndPoint
  //                 secondaryEndPoint
  //                 expEndPoint
  //                 startDt
  //                 endDt
  //                 studyDuration
  //                 stoppingCriteria
  //                 numberOfSitesPlanned
  //                 numberOfSubPlanned
  //                 route
  //                 regiman
  //                 dosage
  //                 statMethod
  //                 interimAnalysis
  //                 studyFlow
  //                 protocolSynopsisActual
  //                 protocolFinalActual
  //                 fdaSubmissionActual
  //                 firstSite_OpenActual
  //                 fpiActualActual
  //                 lpoActualActual
  //                 dbLockActual
  //                 csrActualActual
  //                 protocolSynopsisReceived
  //                 protocolFinalReceived
  //                 fdaSubmissionReceived
  //                 firstSite_OpenReceived
  //                 fpiReceivedReceived
  //                 lpoReceivedReceived
  //                 dbLockReceived
  //                 csrReceivedReceived
  //                 createdBy
  //                 createdTs
  //                 modifiedBy
  //                 modifiedTs
  //           }
  //       }`

  //     };

  //     requestBody = JSON.stringify(requestBody);
  //     setProgress(true);
  //     let res = await genericApi('getStudy', requestBody);
  //     setProgress(false);
  //     if (!res) {
  //       // Error handling
  //       alert('Error in study details!')

  //     } else {
  //       if (res.data.getStudy) {
  //         dispatch({ type: 'UpdateFormField', name: "autostudy", value:res.data.getStudy });
  //       }
  //     }
  //   }
  //   fetchData();
  // }, []);


  const handleClick = async (event) => {
    event.preventDefault();
    propsobj.history.push('/account/updateStudy');
  }

  const { studyNumber = "",
    parentAccountId = "",
    parentAccountName = "",
    studyTitle = "",
    nameofIMP = "",
    devPhase = "",
    status = '',
    numberOfSitesPlanned = "",
    numberOfSubPlanned = "",
    IP = "",
    route = "",
    dosage = "",
    regiman = "",
    primaryEndPoint = "",
    secondaryEndPoint = "",
    expEndPoint = "",
    statMethod = "",
    interimAnalysis = "",
    stoppingCriteria = "",
    startDt = "",
    endDt = "",
    studyDuration = "",
    studyFlow = "",
    protocolSynopsisActual = "",
    protocolFinalActual = "",
    fdaSubmissionActual = "",
    firstSite_OpenActual = "",
    fpiActualActual = "",
    lpoActualActual = "",
    dbLockActual = "",
    csrActualActual = "",
    protocolSynopsisReceived = "",
    protocolFinalReceived = "",
    fdaSubmissionReceived = "",
    firstSite_OpenReceived = "",
    fpiReceivedReceived = "",
    lpoReceivedReceived = "",
    dbLockReceived = "",
    csrReceivedReceived = "" } = propsobj.formValues.autostudy;

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {propsobj.formValues.autostudy &&
        <>


          {/* <div className="d-flex">
            <div class="secondary-header mr-auto">Study Details</div>
            <div className="">
            </div>
          </div> */}

          <div className="mt2rem">
            <form noValidate autoComplete="off" id="addAccountForm" className="row h-form-1">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel-primary-wrap">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-page-header">PROJECT DETAILS</div>
                          <div className="inputBox">
                            <ViewRow textvalue={studyNumber} text="Protocol Number" />
                            <ViewRow textvalue={studyTitle} text="Protocol Title" />
                            <ViewRow textvalue={parentAccountName} text="Sponsor" />

                            <ViewRow textvalue={devPhase} text="Development Phase" />
                            <ViewRow textvalue={status} text="Status" />
                            <ViewRow textvalue={numberOfSitesPlanned} text="Number of Sites Planned" />

                            <ViewRow textvalue={numberOfSubPlanned} text="Number of Subjects Planned" />

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="panel-primary-wrap">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-page-header">IP DETAILS</div>
                          <div className="inputBox">
                            <ViewRow textvalue={nameofIMP} text="Investigation Product" />
                            <ViewRow textvalue={route} text="Route of Administration" />


                            <ViewRow textvalue={dosage} text="Dosage" />
                            <ViewRow textvalue={regiman} text="Regimen" />

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="panel-primary-wrap">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-page-header">STUDY ANALYSIS</div>
                          <div className="inputBox">
                            <ViewRow textvalue={primaryEndPoint} text="Primary End Points" />
                            <ViewRow textvalue={secondaryEndPoint} text="Secondary End Points" />


                            <ViewRow textvalue={expEndPoint} text="Exploratory End Points" />
                            <ViewRow textvalue={statMethod} text="Statistical Methods" />

                            <ViewRow textvalue={interimAnalysis} text="Interim Analysis" />
                            <ViewRow textvalue={stoppingCriteria} text="Stopping Criteria" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel-primary-wrap">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-page-header">PROTOCOL TIMELINE</div>
                          <div className="inputBox">
                            <div className="row">
                              <div className="col-md-6">
                                <ViewRow textvalue={startDt} text="Start Date" />
                              </div>
                              <div className="col-md-6">
                                <ViewRow textvalue={endDt} text="End Date" />
                              </div>
                            </div>


                            <ViewRow textvalue={studyDuration} text="Study Duration" />
                            <ViewRow textvalue={studyFlow} text="Study Flow" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="panel-primary-wrap">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-page-header">PROTOCOL STARTUP MILESTONES</div>
                          <div className="inputBox">
                            <div className="row">
                              <div className="col-md-12 headerfontcss">Protocol Synopsis (Final)</div>
                              <div className="col-md-6">
                                <ViewRow textvalue={protocolSynopsisActual} text="Planned Date" />
                              </div>
                              <div className="col-md-6">
                                <ViewRow textvalue={protocolSynopsisReceived} text="Recieved Date" />
                              </div>
                              <div className="col-md-12 headerfontcss">Protocol Final</div>
                              <div className="col-md-6">
                                <ViewRow textvalue={protocolFinalActual} text="Planned Date" />
                              </div>
                              <div className="col-md-6">
                                <ViewRow textvalue={protocolFinalReceived} text="Recieved Date" />
                              </div>
                              <div className="col-md-12 headerfontcss">FDA Submission</div>
                              <div className="col-md-6">
                                <ViewRow textvalue={fdaSubmissionActual} text="Planned Date" />
                              </div>
                              <div className="col-md-6">
                                <ViewRow textvalue={fdaSubmissionReceived} text="Recieved Date" />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="panel-primary-wrap">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-page-header">PROTOCOL MILESTONES</div>
                          <div className="inputBox">
                            <div className="row">
                              <div className="col-md-12 headerfontcss">First Site Initiation Visit</div>
                              <div className="col-md-6">
                                <ViewRow textvalue={firstSite_OpenActual} text="Planned Date" />
                              </div>
                              <div className="col-md-6">
                                <ViewRow textvalue={firstSite_OpenReceived} text="Actual Date" />
                              </div>
                              <div className="col-md-12 headerfontcss">First Patient In</div>
                              <div className="col-md-6">
                                <ViewRow textvalue={fpiActualActual} text="Planned Date" />
                              </div>
                              <div className="col-md-6">
                                <ViewRow textvalue={fpiReceivedReceived} text="Actual Date" />
                              </div>
                              <div className="col-md-12 headerfontcss">Last Patient Out</div>
                              <div className="col-md-6">
                                <ViewRow textvalue={lpoActualActual} text="Planned Date" />
                              </div>
                              <div className="col-md-6">
                                <ViewRow textvalue={lpoReceivedReceived} text="Actual Date" />
                              </div>
                              <div className="col-md-12 headerfontcss">DB Lock</div>
                              <div className="col-md-6">
                                <ViewRow textvalue={dbLockActual} text="Planned Date" />
                              </div>
                              <div className="col-md-6">
                                <ViewRow textvalue={dbLockReceived} text="Received Date" />
                              </div>
                              <div className="col-md-12 headerfontcss">Clinical Study Report</div>
                              <div className="col-md-6">
                                <ViewRow textvalue={csrActualActual} text="Planned Date" />
                              </div>
                              <div className="col-md-6">
                                <ViewRow textvalue={csrReceivedReceived} text="Received Date" />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudyDetails));



