import React, { Fragment } from 'react'
import Pagination from "material-ui-flat-pagination";
import CssBaseline from "@material-ui/core/CssBaseline";
import { appConfig } from "./constants";
const cssStyle = {
  border: 0,
  borderRadius: "50%",
  margin: "0.15rem",
  padding: "0.10rem 0.4rem",
  fontSize: "0.75rem",
  fontWeight: "bold",
  backgroundSolor: "#244271",
  color: "white",
}
export const Paginationn = (props) => {
  return (
    <Fragment>
      <CssBaseline />
      <Pagination
        style={{ textAlign: "right" }}
        limit={appConfig.paginationLimit}
        offset={props.offset}
        total={props.data}
        onClick={(e, offset, pageNum) => props.handlePageClick(offset,pageNum)}
      />
      {/* <div className="MuiFlatPagination-root" style={{ textAlign: "left" }}>
        <p>showing</p>
      </div> */}
    </Fragment>
  )
}
