import React from 'react'
import AddCourse from '../fileupload/addcoursecommon'

const AddCourses=()=>{
  return(
    <>
    <AddCourse type="Course" plural="courses" form="addCourse"/>
    </>
  )
}
export default AddCourses