import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default function AgeGroup(props) {
    const classes = useStyles();
    const handleAge = (e, operator) => {
        e.preventDefault();
        try {
            document.querySelector('.entitybtngroup .ageSelected').classList.remove('ageSelected');
        } catch (error) {

        }
        e.target.closest('button').classList.add('ageSelected');
        props.handleAge(operator)
    }
    return (
        <div className={classes.root}>
            <ButtonGroup color="primary" aria-label="outlined primary button group" fullWidth className="entitybtngroup">
                <Button id="ageEqual" onClick={(e) => handleAge(e, '=')}><i className="fas fa-equals"></i></Button>
                <Button onClick={(e) => handleAge(e, '>')}><i className="fas fa-greater-than"></i></Button>
                <Button onClick={(e) => handleAge(e, '>=')}><i className="fas fa-greater-than-equal"></i></Button>
                <Button onClick={(e) => handleAge(e, '<')}><i className="fas fa-less-than"></i></Button>
                <Button onClick={(e) => handleAge(e, '<=')}><i className="fas fa-less-than-equal"></i></Button>
                <Button onClick={(e) => handleAge(e, 'btw')}>BTW</Button>
                <Button onClick={(e) => handleAge(e, '!=')}><i className="fas fa-not-equal"></i></Button>
            </ButtonGroup>
        </div>
    );
}
