import React, { useEffect, useState } from "react";
import Materialtable from '../../../../globalcomponents/materialtable';
import { apiCall } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';


function ContactRealtedAccountListGrid(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);


  useEffect(() => {

    async function fetchData() {
      const getAllAccountsofContact = `
      sponsorAccountId
      accountName
      parentAccountId
      accountType
      status
      address1
      address2
      country
      state
      city
      zip
      email
      phone
      website
      duns
      createdBy
      createdTs
      modifiedBy
      modifiedTs`;

      let requestBody = {
        query: `query {
          getAccountsOfUser(sponsorAccountUserId:"${props.formValue.sponsorAccountUserId}") {
                      ${getAllAccountsofContact}
                }
            }`
      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getAccountsOfUser', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getAccountsOfUser.length) {
          setData(res.data.getAccountsOfUser)
        }
      }
    }
    fetchData();
  }, []);

  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    props.history.push('/account/AddAccount');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    await props.UpdateEntireForm(rowData);
    props.history.push('/account/accountDetails');
  }

  const Columns = [
    {
      title: 'ID', field: 'sponsorAccountId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Name', field: 'accountName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: '25%'
      },
    },
    {
      title: 'Type', field: 'accountType'
    },

    { title: "Parent ID", field: 'parentAccountId' },
    { title: 'Address', field: 'address1' },
    { title: 'City', field: 'city' },
    { title: 'Country', field: 'country' },
  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex justify-content-end mt-4">
        <div className="mr-auto secondary-header">Related Accounts</div>
        {/* <div className="">
          <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
            <i className="fa fa-plus-circle mr-1"></i> ADD ACCOUNT</button>
        </div> */}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="">

            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValue: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactRealtedAccountListGrid));


