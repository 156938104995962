import React, { useEffect, useState } from "react";
import Materialtable from '../materialtable';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomizedProgressBars from './roundedProgress';

function ProblemDetailTable(props) {
    //console.log(props, "props.dataset")
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');
    const handlePageClick = (offset) => {
        setoffset(offset)
    }
    const handleRowClick = () => {
        props.navigateTo()
    }
    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}

            <section className="problemDetailTable mt-2">
                <Materialtable
                    columns={props.columns}
                    data={props.dataset}
                    handleRowClick={handleRowClick}
                    thColor={props.thColor}
                />
                {/* <hr/> */}

            </section>
        </>
    )

}


export default ProblemDetailTable;


