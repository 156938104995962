import React, { useEffect, useState } from "react";
import Materialtable from '../../../../globalcomponents/materialtable';
import { apiCall } from '../../../common/apiconfig';
import { removeDuplicates } from '../../../../common/constants'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Paginationn } from '../../../common/pagination'
import { appConfig } from "../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as R_Items from '../../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import { checkPermission } from '../../../common/utils';

function AllContactGrid(props) {
  let isAddContact = checkPermission('Contact_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');
  let [selectedContactsobj, setSelectedContactsobj] = useState({})
  let [currentpage, setCurrentPage] = useState(1);
  let [emptyObjErr, setEmptyObjErr] = useState("")

  async function fetchData(searchKey) {
    if (searchKey.length && searchKey != undefined) {
      const getContacts = `
        sponsorAccountUserId
        sponsorAccountId
        SponsorAccountName
        firstName
        lastName
        email
        phone
        role
        roleId
        position
        language
        invitedBy
        invitedDate
        activated
        primaryContactInd
        createdBy
        createdTs
        modifiedBy
        modifiedTs`;
      let requestBody = {
        query: `query {
          getAccountUserSearch(searchStr: "${searchKey}") {
                      ${getContacts}
                }
            }`
      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getAccountUserSearch', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getAccountUserSearch.length) {
          if (props.formValues.accountContacts == "") {
            setData(res.data.getAccountUserSearch)
          } else {
            var contactsData = JSON.parse(props.formValues.accountContacts)
            var contacts = res.data.getAccountUserSearch
            contacts = contacts.filter(function (item, j) {
              for (var i = 0, len = contactsData.length; i < len; i++) {
                if (item != null) {
                  if (contactsData[i].sponsorAccountUserId == item.sponsorAccountUserId) {
                    return false;
                  }
                }
              }
              return true;
            });
            // setData(contacts)
            var data = removeDuplicates(contacts, item => item.sponsorAccountUserId)
            setData(data)
          }


        } else {
          setData([])
        }
      }
    }
  }
  useEffect(() => {

    fetchData("Site")
  }, []);

  const handlePageClick = (offset, pageNum) => {
    setCurrentPage(pageNum);
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    props.UpdateFormField({ name: "addContactFromContactSearch", value: false })
    props.history.push('/account/addContact');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    await props.setSidebarActiveEl('contacts');
    let dataToR_Items = {
      id: rowData.sponsorAccountUserId,
      sponsorAccountId: rowData.sponsorAccountId,
      firstName: rowData.firstName
    }

    // setProgress(true);
    let response = await R_Items.updateRecentItems(dataToR_Items, R_Items.rI_contacts);
    if (response) {
      let contactItems = await R_Items.getRecentSearchOrItems(R_Items.rI_contacts);
      if (contactItems) {
        let obj = {
          obj: R_Items.rI_contacts,
          value: contactItems
        }
        props.updateRecentItems(obj);
      }
    }
    // setProgress(false);
    await props.UpdateEntireForm(rowData);
    // if (props.formValues.primaryContactSearch == true) {
    //   props.UpdateFormField({ value: rowData.firstName + ' ' + rowData.lastName, name: "primaryContact" })
    //   props.UpdateFormField({ name: "primaryContactSearch", value: false })
    //   props.history.goBack()
    // } else {
    await props.UpdateFormField({ name: "siteUserHeading", value: "" });
    props.history.push('/account/contact/view');
    // }
  }
  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = async (event) => {
    event.preventDefault();
    if (valSearch.length) {
      fetchData(valSearch);
      await props.UpdateFormField({ name: "contactSearchText", value: valSearch })
      // await props.updateHeaderSearchValue({ value: "" })
      setoffset(0)
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    fetchData('*');
    setValSearch("")
  }
  const rowsSelected = (rows) => {
    setSelectedContactsobj({ ...selectedContactsobj, [currentpage]: rows });
  }
  const Columns = [
    {
      title: 'ID', field: 'sponsorAccountUserId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Name', field: 'firstName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500
      },
      render: rowData => rowData.firstName + ' ' + rowData.lastName
    },
    { title: 'Role', field: 'role' },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' },
  ]
  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(selectedContactsobj, "console.log(selectedContactsobj)")
    //this to get selected users arr from different pages objs
    const chkObj = o => Object.values(o).every(a => a.length === 0)
    if (chkObj(selectedContactsobj)) {
      setEmptyObjErr('Select atleast one contact')
    } else if (Object.keys(selectedContactsobj).length == 0 && selectedContactsobj.constructor === Object) {
      setEmptyObjErr('Select atleast one contact')
    } else {
      setEmptyObjErr("")
      let selectedUsers = []
      for (let i in selectedContactsobj) {
        if (selectedContactsobj[i].length > 0) {
          for (let j of selectedContactsobj[i]) {
            selectedUsers = [...selectedUsers, j]
          }
        }
      }
      console.log(selectedUsers, props.formValues, "selectedUsers")

      let requestBody = {
        query: `mutation {
      createAccountContactRel(sponsorAccountId: "${props.formValues.sponsorAccountId}", sponsorAccountUserId: "${selectedUsers[0].sponsorAccountUserId}") {
        code
        type
        message
        attributes
      }
    }`
      };
      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('createAccountContactRel', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error!')

      } else {
        console.log('added')
        props.history.goBack()
      }
    }
  }
  const handleCancel = (e) => {
    e.preventDefault()
    props.history.goBack()
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex  justify-content-end">
        <div class="secondary-header mr-auto">Select Contacts</div>
        {isAddContact === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
              <i className="fa fa-plus-circle mr-1"></i> ADD CONTACT</button>
          </div> : ""}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
            <div className="col-xs-12 col-sm-12 col-md-5">
              <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                <HekmaTextSearchField
                  name="accountgridsearch"
                  // label="Search Account"
                  placeholder="Search Contacts"
                  value={valSearch}
                  handleChange={searchHandle}
                  className="gridSearchField"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                        X
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div>
            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
              rowsSelected={rowsSelected}
              selection={true}
            />
          </div>
          {<span className="multiselecterrmsg">{emptyObjErr}</span>}
          {data.length > 0 &&
            <div className="buttonContainer ml-auto">
              <button className="btn btn-primary-blue btn-rounded btn-small mr-1" onClick={handleSubmit}>Save</button>
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
            </div>
          }
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    updateHeaderSearchValue: (obj) => {
      return dispatch(
        {
          type: 'headerSearch',
          value: obj.value,
        }
      )
    },
    updateRecentItems: (obj) => {
      return dispatch(
        {
          type: 'updateRecentItems',
          value: obj,
        }
      )
    },
    setSidebarActiveEl: (name) => {
      return dispatch(
        {
          type: 'sidebarActiveEl',
          value: name,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllContactGrid));


