import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { genericApi, apiCall, oldgenericApi } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { HekmaTextSearchField } from '../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
// import findpatientIcon from '../../../img/Find-Patient.svg';
// import { checkPermission } from '../../../../common/utils';
import Materialtable from '../../../globalcomponents/materialtable'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SmartText from '../../common/smartText'
import Tooltip from '@material-ui/core/Tooltip';
import Circle from '../../../img/Circle03.svg'
import AddCartIcon from '../../../img/opensquare/Add-to-Cart.svg'
import BuyIcon from '../../../img/opensquare/Buy.svg'
import { useSelector, useDispatch } from 'react-redux';
import Dashboard from './dashboard'
 
function NRSDetailsPage(props) {
  // let isAddAccount = checkPermission('Account_Create', 'Create');
  let reduxstateobj = useSelector(state => state);
  const reduxdispatch = useDispatch()
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  const [newData, setNewData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');
  const [width, setWidth] = useState('')
  let [show, setShow] = useState(false)
  let [dashboardData, setDashboardData] = useState("")

  useEffect(() => {
    // var outerContainer = document.querySelector(`#tablediv table thead tr th:last-child`);
    // var width = outerContainer.offsetWidth
    // if (width >= 301) {
    //   setWidth(55)
    // } else if (width <= 300) {
    //   setWidth(40)
    // } else {
    //   setWidth(30)
    // }
    callSponsorsApi(props.formValues.NRSDetails)

  }, [props.formValues.NRSDetails]);
  const callSponsorsApi = async (info) => {
    let requestBody = {
      query: `query {
        getOpenSquareLeadResponses(accountId:"${info.accountId}",studyIds:["${info.studyId}"],studyStatus:"*",
  leadStatus:"*"){
    requestId
    requestNum
    accountId
    accountName
    inclusion
    exclusion
    reference
    studyId
    studyNum
    siteId
    siteName
    siteCity
    siteState
    siteCountry
    studyStatus
    leadStatus
    requestDateTime
    responseDateTime
    patientCount
    priceType
    totalPrice
    createdBy
    createdTs
    modifiedBy
    modifiedTs
    }
  }`
    }
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await oldgenericApi('getOpenSquareLeadResponses', requestBody);
    setProgress(false);
    if (!res) {

    } else {
      if (res.data.getOpenSquareLeadResponses.length) {
        var data = res.data.getOpenSquareLeadResponses
        var newData = data.map(v => ({ ...v, "addCart": false }))
        var olddata = props.formValues?.cart && props.formValues.cart.length > 0 && props.formValues.cart
        if (props.formValues?.cart && props.formValues.cart.length > 0) {
          let dupData = newData.filter(o1 => olddata.some(o2 => o1.requestId === o2.requestId))
          if (dupData.length > 0) {
            for (let i = 0; i < dupData.length; i++) {
              for (let j = 0; j < newData.length; j++) {
                if (dupData[i].requestId == newData[j].requestId) {
                  newData[j]["addCart"] = true
                }
              }
            }
          }
          setData(newData)
        } else {
          setData(newData)
        }
      } else {
        setData([])
      }
    }


  }
  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  // const handleClick = async (event) => {
  //   event.preventDefault();
  //   props.history.push('/account/addAccount');
  // }

  const handleRowClick = async (event, rowData) => {
    event.preventDefault()

  }

  // const searchHandle = (event) => {
  //   event.preventDefault();
  //   let value = event.target.value;
  //   setValSearch(value);
  // }
  // const searchSubmit = async (event) => {
  //   event.preventDefault();
  //   if (valSearch.length) {
  //     // fetchData(valSearch);
  //     await props.UpdateFormField({ name: "accountSearchText", value: valSearch })
  //     await props.updateHeaderSearchValue({ value: "" })
  //   }
  // }
  // const clearSearch = (event) => {
  //   event.preventDefault()
  //   fetchData();
  //   setValSearch("")
  // }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  const Columns = [
    {
      title: 'Site ID', field: 'siteId', render: rowData => rowData.leadStatus == "Paid" ? rowData.siteName + '(' + rowData.siteId + ')' : rowData.siteId
    },

    // { title: "Request No.", field: 'requestNum', render: rowData => "NR" + rowData.requestNum },
    // { title: 'Inclusion Criteria.', field: 'inclusion', render: rowData => <SmartText text={rowData.inclusion} length={width} /> },
    // { title: 'Exclusion Criteria', field: 'exclusion', render: rowData => <SmartText text={rowData.exclusion} length={width} /> },
    { title: "State", field: 'siteCity' },
    { title: "Country", field: 'siteCountry' },
    { title: "Status", field: 'leadStatus' },
    { title: "Count", field: 'patientCount' },
    // { title: "Pricing Type", field: 'priceType' },
    { title: "Price($)", field: 'totalPrice', render: rowData => rowData.totalPrice ? numberWithCommas(parseInt(rowData.totalPrice)) : '--' },
    {
      title: "Cart", field: "", render: rowData => rowData.leadStatus == "Submitted" ? rowData.addCart ? <img src={Circle} alt="added to cart" /> : <Tooltip title="Add to Cart" arrow><img src={AddCartIcon} alt="add to cart" onClick={(e) => addToCart(e, rowData)} /></Tooltip> : ""

    // },
    // {
    //   title: "Metrics", field: "", render: rowData => (rowData.patientCount != null || rowData.patientCount != "") && rowData.patientCount &&
    //   <Tooltip title="Dashboard" arrow><i className="fa fa-solid fa-chart-line" style={{color:"#23416F"}} alt="Dashboard" onClick={(e) => showDashboard(e, rowData)} /></Tooltip>
    }
  ]

  const showDashboard = (e, rowData) => {
    e.preventDefault();
    setDashboardData(rowData)
    setShow(true)
  }
  // const addToCart = (e, rowData) => {
  //   e.preventDefault();

  // }

  // const addToCart = (e, rowData) => {
  //   e.preventDefault();
  //   var newData = data.map(item => item.requestId === rowData.requestId ? { ...item, addCart: true } : item)
  //   var addedToCardList = newData.filter(v => (v.addCart == true))
  //   var cartdata = addedToCardList.map(item => ({
  //     siteName: item.siteName, state: item.siteState, country: item.siteCountry, pcount: item.patientCount, priceType: item.priceType, totalPrice: item.totalPrice, id: "NR" + item.studyNum, addCart: true, requestId: item.requestId
  //   }))
  //   var newcart
  //   if (props.formValues.cart.length > 0) {
  //     var updatedCart = [...props.formValues.cart, ...cartdata]
  //     newcart = updatedCart.filter((v, i, a) => a.findIndex(v2 => (v2.requestId === v.requestId)) === i)

  //   } else {
  //     newcart = cartdata
  //   }
  //   // console.log(props.formValues.cart, cartdata, "allcart")
  //   reduxdispatch({ type: 'UpdateFormField', name: "cart", value: newcart })
  //   setData(newData)
  // }
  const addToCart = async (e, rowData) => {
    e.preventDefault();
    var newData = data.map(item => item.siteName === rowData.siteName ? { ...item, addCart: true } : item)
     let requestBody = `
    mutation 
    {
      addCartDetails(accountId:"${localStorage.getItem('loginUserId')}",nctId:"${props.formValues.NRSDetails.studyId}",  siteId:"${rowData.siteId}", siteName: "${rowData.siteName}", city:"*",country:"*",patientCount:"${rowData.patientCount}",totalPrice:"${rowData.totalPrice}",metrics:"",insights:"ins",patientGender:"*", patientAgeMin:"*", patientAgeMax:"*",patientRace:"*",  patientCity:"*", patientState:"*", patientZipCode:"*", patientCountry:"*", patientLanguage:"*", patientEducation:"*", siteNameFilter:"*", siteCity:"${rowData.siteCity}", siteState:"${rowData.siteState}", siteZipcode:"*", siteCountry:"${rowData.siteCountry}",leadStatus:"*",highbelow:"*",highend:"*",midbelow:"*",midend:"*", lowbelow:"*", lowend:"*") {
        code
        type
        message
        attributes
      }  
    }
        `;

          requestBody = JSON.stringify({ query: requestBody });
          setProgress(true);
          let res = await genericApi('addCartDetails', requestBody);
          setProgress(false);
          if (res && res.data.addCartDetails.code === '200') {
            // alert('success')
            reduxdispatch({ type: 'cartcount',  value: reduxstateobj.cartcount+1 })
           
          }

    // var newcart
    // if (propsobj.formValues.cart.length > 0) {
    //   var updatedCart = [...propsobj.formValues.cart, ...cartdata]
    //   newcart = updatedCart.filter((v, i, a) => a.findIndex(v2 => (v2.requestId === v.requestId)) === i)

    // } else {
    //   newcart = cartdata
    // }
  
    setData(newData)
  }
  // {
  //   title: 'Actions', field: '', cellStyle: {
  //     // float: "right",
  //     // marginRight: "20px",
  //     cursor: "pointer",
  //     width: '10%'
  //   }, render: rowdata => <Tooltip title="Find Patients" arrow><img src={findpatientIcon} alt="find aptient" onClick={(e) => handleClick(e, rowdata)} /></Tooltip>
  // }
  const goBack = () => {
    props.history.goBack();
  }
  const onNext = () => {
    // var addedToCardList = data.filter(v => (v.addCart == true))
    // reduxdispatch({ type: 'UpdateFormField', name: "cart", value: addedToCardList })
    props.history.push('/account/shoppingcart')
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
        {/* <Loader/> */}
      </div>}
      {!show && <><div className="d-flex justify-content-end mb-2">
        <h1 className="mr-auto primary-header">{"NR" + props.formValues.NRSDetails.studyNum}</h1>
        <div className="">
          <button onClick={goBack} className="btn btn-primary-blue btn-rounded">
            BACK</button>
        </div>
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
            {/* <div className="col-xs-12 col-sm-12 col-md-5">
              <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                <HekmaTextSearchField
                  name="accountgridsearch"// this name you need not to add in constant file unless if we use redux
                  // label="Search Account"
                  placeholder="Search Accounts"
                  value={valSearch}
                  handleChange={searchHandle}
                  className="gridSearchField"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                       
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                        X
                       
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div> */}
            {/* <div id="tablediv" className="studyper"> */}
            <Materialtable
              columns={Columns}
              data={data.length > 0 ? data : []}
              handleRowClick={handleRowClick}
            />

            {/* </div> */}
            <button type="button" onClick={onNext} className="btn btn-primary-blue btn-rounded btn-small fr mt-3" >Next</button>
          </div>
        </div>
        
      </div></>}
      {show &&
        <div>
          <div className="row">
            <div className="col-md-12 mb-2 justify-content-end" >
              <h1 className="mr-auto primary-header" style={{ float: "left" }}>{"Patients Count:" +dashboardData.patientCount}</h1>

              <button className="btn btn-small btn-primary-blue btn-shadow btn-rounded" onClick={() => setShow(false)} style={{ float: "right", marginTop: "5px" }}>
                BACK</button>
            </div>
          </div>
          <Dashboard data={dashboardData}/>
        </div>
      }
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateHeaderSearchValue: (obj) => {
      return dispatch(
        {
          type: 'headerSearch',
          value: obj.value,
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateRecentItems: (obj) => {
      return dispatch(
        {
          type: 'updateRecentItems',
          value: obj,
        }
      )
    },
    setSidebarActiveEl: (name) => {
      return dispatch(
        {
          type: 'sidebarActiveEl',
          value: name,
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NRSDetailsPage));