import React, { useState, useEffect } from "react";
import { regxObj } from '../../common/regxPattern';
import { ErrorLables } from '../../common/lables';

function FormValidation(propsobj) {
    let errorTextObj = {...propsobj.state.formerrtextObj};
    let errorFieldsObj = {...propsobj.state.formerrobj}; 

    const handleChange = async(event) => {
        let name = event.target.name;
        let value = event.target.value;
        let obj = {
            name,
            value
        }
        propsobj.UpdateFormField(obj);
        // if(event.target.value==="username"){
        //     // propsobj.checkUserId(event.target.value) 
        // }
    };

    const handleBlur = event => {
        let name = event.target.name;
        let value = event.target.value;
        let isItMust = event.target.getAttribute('datamust') || false;
        isItMust = (isItMust === 'true') ? true : false;
        if (isItMust) {
            validateFieldOnblur(name, value);
        }
    }

    const validateFieldOnblur = async (name, value) => {
        let pattern = regxObj[name];
        if (!pattern.test(value)) {
            let obj = {
                name,
                flag:true,
                text:ErrorLables[name]
            }
            propsobj.updateErrorObj(obj);
        } else {
            let obj = {
                name,
                flag:false,
                text:''
            }
            propsobj.updateErrorObj(obj);
        }
    }

    const validateFieldOnSubmit = async (name, value) => {
        let pattern = regxObj[name];
        return pattern.test(value);
    }

    const handleSubmit = async (formId, event) => {
        event.preventDefault();
        var x = document.getElementById(formId);
        for (let i = 0; i < x.length; i++) {
            let element = x.elements[i];
            let name = element.name;
            let value = element.value;
            let isItMust = element.getAttribute('datamust') || false;
            isItMust = (isItMust === 'true') ? true : false;
            if (isItMust) {
                let isvalid = await validateFieldOnSubmit(name, value);
                if (isvalid) {
                    errorFieldsObj = { ...errorFieldsObj, [name]: false }
                    errorTextObj = { ...errorTextObj, [name]: '' }
                } else {
                    errorFieldsObj = { ...errorFieldsObj, [name]: true }
                    errorTextObj = { ...errorTextObj, [name]: ErrorLables[name] }
                }
            } else {
                continue;
            }
        }

        let errorValues = Object.values(errorFieldsObj);
        let isAnyError = errorValues.some(function (value) {
            return value === true;
        });
        propsobj.updateEntireErrorObj(errorFieldsObj,errorTextObj);
        if (isAnyError) {
            return false;
        } else {
            return true;
        }
    };

    return {
        handleChange,
        handleBlur,
        handleSubmit,
    }
}

export default FormValidation;