import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

function AuditTrail(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}

            <span>AuditTrail</span>
        </>
    )

}

export default AuditTrail;


