import React, { useEffect, useState } from "react";
import { SidebarMenus } from '../../components/navMenu';
import * as R_Items from '../recentItems';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Accounts from '../../../img/dashboard_icons/Accounts.svg'
import Contacts from '../../../img/dashboard_icons/contacts.svg'
import Studies from '../../../img/dashboard_icons/Studies.svg'
import Dashboard from '../../../img/dashboard_icons/dashboard.svg'
import Users from '../../../img/dashboard_icons/User-manaement2.svg'

import $ from 'jquery';
const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: "#1a3154",
        paddingLeft: "-10px"
    },
    tooltip: {
        backgroundColor: "#1A3154",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} placement="right" />;
}
const menus = [
    {
        to: "/account/dashboard",
        text: "Dashboard",
        clsNames: "fa fa-fw fa-tachometer-alt",
        name: 'dashboard',
        icon: Dashboard
    },
    {
        to: "/account/opensquare",
        text: "Open Square",
        clsNames: "far fa-fw fa-user-circle",
        name: 'opensquare',
        icon: Accounts
    },
    {
        to: "/account",
        text: "Accounts",
        clsNames: "far fa-fw fa-user-circle",
        name: 'accounts',
        icon: Accounts
    },
    {
        to: "/account/contact",
        text: "Contacts",
        clsNames: "fa fa-fw fa-address-book",
        name: 'contacts',
        icon: Contacts
    },
    {
        to: "/account/study",
        text: "Studies",
        clsNames: "fa fa-fw fa-book",
        name: 'studies',
        icon: Studies
    },
    {
        to: "/account/users",
        text: "User Management",
        clsNames: "fa fa-user",
        name: 'user',
        icon: Users
    }
]

const Sidebar = (props) => {
    let [contacts, setContacts] = useState([]);
    let [accounts, setAccounts] = useState([]);
    let [studies, setStudies] = useState([]);
    let [activeel, setActiveEl] = useState(props.state.sidebarActEl);

    useEffect(() => {
        $("#sidebarToggle, #sidebarToggleTop").on('click', function (e) {
            $("body").toggleClass("sidebar-toggled");
            $(".sidebar").toggleClass("toggled");
            // if ($(".sidebar").hasClass("toggled")) {
            //     $('.sidebar .collapse').collapse('hide');
            // };
        });
    }, []);

    useEffect(() => {
        fetchData();
    }, [props.state.contactsI, props.state.studiesI, props.state.accountsI]);

    useEffect(() => {
        setActiveEl(props.state.sidebarActEl);
    }, [props.state.sidebarActEl]);

    async function fetchData() {
        let contactItems = await R_Items.getRecentSearchOrItems(R_Items.rI_contacts);
        if (contactItems) {
            setContacts(contactItems);
        }

        let actItems = await R_Items.getRecentSearchOrItems(R_Items.rI_accounts);
        if (actItems) {
            setAccounts(actItems);
        }

        let studyItems = await R_Items.getRecentSearchOrItems(R_Items.rI_studies);
        if (studyItems) {
            setStudies(studyItems);
        }
    }

    const loadData = (arrObj, type, whatToShow) => {
        let lists = arrObj.map((item, i) => {
            return <>{item[whatToShow].length > 15 ? <BootstrapTooltip title={item[whatToShow]} placement="right"><a className="collapse-item cur" key={i} onClick={() => {
                RedirectToPage(item, type)
            }}> {item[whatToShow].limit(15)}</a></BootstrapTooltip> : <a className="collapse-item" key={i} onClick={() => {
                RedirectToPage(item, type)
            }}> <span className="recentdata cur">{item[whatToShow]}</span></a>
            }
            </>
        })
        return lists;
    }
    String.prototype.limit = function (length) {
        return this.length > length ? (this.substring(0, length) + '...') : this;
    }
    const RedirectToPage = (item, type) => {
        props.sidebarActiveEl(type);
        if (type == 'contacts') {
            props.history.push('/account/contact/view', [item]);
            props.UpdateFormField({ name: "siteUserHeading", value: "" })
        }

        if (type == 'studies') {
            props.history.push('/account/studyDetails', [item]);
        }

        if (type == 'accounts') {
            props.history.push('/account/accountDetails', [item]);
        }
    }

    const linkHandler = (e) => {
        let setActive = e.currentTarget;
        let selectedEl = setActive.getAttribute('name');
        props.sidebarActiveEl(selectedEl);
    }
    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();

        return <Tooltip arrow classes={classes} {...props} />;
    }

    return (
        <>

            {/* <!-- Sidebar --> */}
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                {/* <!-- Sidebar - Brand -->
            <!-- Sidebar Toggler (Sidebar) --> */}
                <div className="sidebar-brand d-flex align-items-center d-default d-md-inline">
                    <button className="border-0" id="sidebarToggle"></button>
                </div>

                <SidebarMenus menus={menus} clickHandler={linkHandler} setActive={activeel} />

                {/* <!-- Divider --> */}
                <hr className="sidebar-divider" />
                {/* <!-- Nav Item - Pages Collapse Menu --> */}
                <li className="nav-item">
                    <div className="nav-link recentitems mb-0" href="#" data-toggle="collapse" data-target="#collapsePages" aria-expanded="true" aria-controls="collapsePages" style={{ cursor: "default" }}>
                        <BootstrapTooltip title="Recent Items"><i className="fa fa-clipboard-list"></i></BootstrapTooltip>
                        <span>Recent Items</span>
                    </div>

                    <div id="collapsePages" className="collapse show h-collapse recentItems" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                        <div className=" collapse-inner h-parent">





                            {accounts.length > 0 && <>
                                <div className="btn btn-link btn-link-txt btn-block" role="button" aria-expanded="false" aria-controls="collapseExample" style={{ cursor: "default" }}>
                                    Accounts
                            </div>
                                <div className="collapse show" id="level-2">
                                    <div className="collapse-inner multi-level text-truncate">
                                        {loadData(accounts, 'accounts', 'accountName')}
                                    </div>
                                </div>
                            </>}

                            {contacts.length > 0 && <>
                                <div className="btn btn-link btn-link-txt btn-block" role="button" aria-expanded="false" aria-controls="collapseExample" style={{ paddingTop: "15px", cursor: "default" }}>
                                    Contacts
                            </div>
                                <div className="collapse show" id="level-2">
                                    <div className="collapse-inner multi-level text-truncate">
                                        {loadData(contacts, 'contacts', 'firstName')}
                                    </div>
                                </div>
                            </>}



                            {studies.length > 0 && <>
                                <div className="btn btn-link btn-link-txt btn-block" role="button" aria-expanded="false" aria-controls="collapseExample" style={{ paddingTop: "15px", cursor: "default" }}>
                                    Studies
                            </div>
                                <div className="collapse show" id="level-2">
                                    <div className="collapse-inner multi-level text-truncate">
                                        {loadData(studies, 'studies', 'studyNumber')}
                                    </div>
                                </div>
                            </>}

                        </div>
                    </div>
                </li>
            </ul>
            {/* <!-- End of Sidebar --> */}
        </>

    )
}
//export default Sidebar;
const mapStateToProps = (state) => {
    return {
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        sidebarActiveEl: (value) => {
            return dispatch(
                {
                    type: 'sidebarActiveEl',
                    value: value,
                }
            )
        },
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        },
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));


