import React from 'react'

const PatientAuditTrail = () => {
  return (
    <div className="card mt2rem">
      <div className="card-body">
        No AuditTrail
    </div>
    </div>
  )
}
export default PatientAuditTrail