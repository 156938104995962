// import React, { useState, useRef } from 'react'
// import Email1 from '../../../../img/emailtemplate/email1.jpg'
// import Email2 from '../../../../img/emailtemplate/email2.jpg'
// import Email3 from '../../../../img/emailtemplate/email3.jpg'
// import Mail from '../../../../img/emailtemplate/Mail.png'
// import { withRouter } from 'react-router-dom';

// const Layouts = (props) => {
//   const openTemplate = () => {
//     props.history.push('/account/adminfeatures/emailtemplates/layout')
//   }
//   return (
//     <>
//       <div className="row mt2rem emaillayout">
//         <div className="co1-md-3" onClick={openTemplate} > <img src={Email2} alt="Email Template 2" className="img-responsive" /></div >
//         <div className="co1-md-3"><img src={Email1} alt="Email Template 1" className="img-responsive" /></div>
//         <div className="co1-md-3"><img src={Email3} alt="Email Template 3" className="img-responsive" /></div>
//         <div className="co1-md-6 mailimg"><img src={Mail} /></div>
//       </div >
//     </>
//   )
// }
// export default withRouter(Layouts)

import React, { useState, useRef, useEffect, useMemo } from 'react'
import JoditEditor from 'jodit-react'
import { withRouter } from 'react-router-dom';
import FileUpload from './imageUpload'
import { apiCall, getFiles } from '../../common/apiconfig';
import { editorConfig } from '../../common/constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import { InputField } from '../../globalcomponents/formElements';
import FormHoc from '../../globalcomponents/hoc/formHoc';
import { connect } from 'react-redux';
import { image } from 'd3';
import Loader from '../../common/loader'

const CreateTemplateCommon = (propsobj) => {
  const [content, setContent] = useState('')
  const [show, setShow] = useState(true)
  const [libraryImgs, setLibraryImgs] = useState([])
  let [progress, setProgress] = useState(false);
  const editor = useRef(null)
  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  useEffect(() => {
    callGetData()
    propsobj.UpdateFormField({ name: "templateName", value: "" })
    if (propsobj.updateContent) {
      setContent(propsobj.updateContent.body_text)
      propsobj.UpdateFormField({ name: "templateName", value: propsobj.updateContent.name })
    } else {
      setContent("")
      // editor.current.value('')
      propsobj.UpdateFormField({ name: "templateName", value: "" })
    }
  }, [])

  const callGetData = async () => {
    let requestBody = `
        query {
          getSupportDetailsWithStatus(buArea: "MailTemplate", buid: "${propsobj.formValues.autosite.sponsorAccountId}", type: "mailtemplates", status: "*") {
            ID
            buArea
            buid
            type
            status
            detail1
            detail2
            detail3
            detail4
            addedById
            addedByName
            addedTs
          }
        } `;

    requestBody = JSON.stringify({ query: requestBody });
    setProgress(true);

    let res = await apiCall('getSupportDetailsWithStatus', requestBody);

    if (!res) {
      console.log('Error!')
    } else {
      console.log(res, "mail templates")
      // loading(false)
      var data = res.data.getSupportDetailsWithStatus
      var urldata = data.map(v => ({ ...v, url: "" }))
      for (var i = 0; i < data.length; i++) {
        let res = await getFiles('fileupload', data[i], "GET");
        if (res.code === 200) {
          let data = JSON.parse(res.attributes)
          urldata[i]["url"] = data.URL
        }

      }
      setLibraryImgs(urldata)
      setProgress(false);
    }
  }

  const loading = (status) => {
    setProgress(status)
  }
  const formSubmit = async (event, status) => {
    event.preventDefault()
    let setGo = await hocObj.handleSubmit('addTemplate', event);
    console.log(setGo, "SetGo")
    if (setGo) {

      if (propsobj.header === "Create Template") {
        setProgress(true);
        let requestBody = `
    mutation
          {
          createEmailTemplate(buArea: "MailTemplate", buId: "${propsobj.formValues.autosite.sponsorAccountId}", subArea: "Site Related", status: "${status}", name: "${propsobj.formValues.templateName}", subject_text: "welcome to pfizer", body_text: ${JSON.stringify(editor.current.value)}){
              code
              type
              message
              attributes
            }
          }`
        requestBody = JSON.stringify({ query: requestBody });
        let res = await apiCall('createEmailTemplate', requestBody);
        setProgress(false);

        if (res.data.createEmailTemplate.code === '200') {
          setContent('')
          // editor.current.value=""
          propsobj.UpdateFormField({ name: "templateName", value: "" })
          propsobj.history.push('/site/adminfeatures/emailtemplates/savedtemplates')
        } else {
          console.log('Error!')
        }
      } else {
        propsobj.updateTemplate({ "body_text": editor.current.value, "status": status })
      }
    }
  }
  const goBack = () => {
    propsobj.hideUpdate()
  }
  return (
    <>
      <Loader progress={progress} />
      
      <div className="row">
        <div className="col-md-12 mt-4 mb-2 justify-content-end" >
          <h1 className="mr-auto primary-header" style={{ float: "left" }}>{propsobj.header}</h1>
          {propsobj.header === "Update Template" &&
            <button className="btn btn-small btn-primary-blue btn-shadow btn-rounded" onClick={goBack} style={{ float: "right", marginTop: "5px" }}>
              BACK</button>}
        </div>
        <div className="col-md-6 ">

          <div className="card">
            <div className="">
              <form noValidate autoComplete="off" id="addTemplate" className="" onSubmit={(e) => formSubmit(e, "Active")}>
                <div className="m-3 mailBody">
                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={editorConfig}
                  // tabIndex={1} // tabIndex of textarea
                  //  onChange={newContent => setContent(newContent)}
                  //  onBlur={newContent => setContent(newContent)}
                  />
                  <InputField {...textProps} name="templateName" label="Enter template name" must={'true'} type="text" className="mt-2" />
                  <div className="buttonContainer mt2rem mb-3" style={{
                    float: "right"
                  }}>
                    <button type="submit" className="btn btn-primary-blue btn-rounded btn-small">SAVE TEMPLATE</button>
                    {/* <button type="button" className="btn btn-primary-blue btn-rounded btn-small" onClick={(e) => formSubmit(e, "Draft")}>SAVE AS DRAFT</button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-6">

          <div className="card h-100">
            <div className="m-3 bb">
              <h1 className="mr-auto primary-header" style={{ float: "left" }}>LIBRARY</h1>
              <span style={{ float: "right" }}>
                <FileUpload text="Upload Image" dataToAccept='image/*' callGetData={callGetData} loading={loading} />
              </span>
            </div>
            {libraryImgs.length > 0 ?
              <div className="imgrow">

                {libraryImgs.map((tile) => (
                  <div className="imggrid" key={tile.detail1} style={{ marginBottom: "10px" }}>
                    <img src={tile.url} alt={tile.detail2} height="150" width="150" />
                  </div>
                ))}
              </div> : <div className="m-3 center">No Images Uploaded</div>}


            {/* } */}
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateTemplateCommon));
