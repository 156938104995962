import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../../formElements';
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core'
import BackIcon from '../../../../img/back1.svg'
import { checkPermission } from '../../../common/utils';


const ViewIECriteria = (propsobj) => {
  let isEditIE = checkPermission('IE_Criteria_Edit', 'Update');
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);

  const handleClick = async (event) => {
    event.preventDefault();
    propsobj.history.push('/account/studyDetails/IEcriteria/Update');
  }

  const { IE_type = '', IE_description = '', IE_effectiveDate = '', IE_status } = propsobj.formValues;
  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.push('/account/studyDetails/IEcriteria')
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO I-E CRITERIA LIST</div><br /> */}
      <div className="col-md-6 mt-4 pl-0">
        <div className="d-flex">

          <div class="secondary-header mr-auto">IE Criteria Details</div>
          {
            isEditIE === "1" ? <div className="">
              <button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
                <i className="fas fa-pencil-alt"></i>&nbsp; EDIT</button>
            </div> : ""
          }

        </div>
      </div>
      <div className="">
        <form noValidate autoComplete="off" id="addAccountForm" className="row h-form-1">
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">

                  <div className="inputBox">
                    <ViewRow text="Type" textvalue={IE_type} />
                    <ViewRow text="Description" textvalue={IE_description} />
                    <ViewRow text="Status" textvalue={IE_status} />
                    <ViewRow text="Effective Date" textvalue={IE_effectiveDate} />

                  </div>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewIECriteria));



