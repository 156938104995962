import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import * as jq from 'jquery';

export default function PIRTextArea(props) {

    useEffect(() => {
        // document.querySelectorAll(".delete-edit").forEach(el => {
        //     el.style.display = "none";
        // });
    }, [props])

    const editContent = (e) => {
        try {
            e.target.closest(".lirow").classList.add("contentEdit");
        } catch (error) {

        }
    }
    const emitChange = (e, inx, name) => {
        if (!props.hideEdit) {
            try {
                let value = e.target.innerText;
                e.target.closest(".lirow").classList.remove("contentEdit");
                props.clickHandler(e, inx, name, value)
            } catch (error) {

            }
        }
    }

    const deleteContent = (e, inx, name) => {
        e.preventDefault();
        try {
            jq(e.target.closest(".lirow")).animate({ height: '0px' }, 200, () => {
                jq(e.target.closest(".lirow")).height('auto')
                e.target.closest(".lirow").classList.remove("contentEdit");
                props.deleteHandler(e, inx, name);
            })

        } catch (error) {

        }
    }


    return (
        <>
            {/* <TextField
                multiline
                rows={10}
                value={props.textvalue}
                variant="outlined"
                name={props.name}
                onChange={props.changeHandler}
                fullWidth
            /> */}
            <ul className="text-muted customStyle">
                {props.textvalue.map((item, inx) => {
                    return <li key={inx}

                        className="lirow"
                        title="Click to edit">
                        <span
                            className="ml-2"
                            contentEditable={false}
                            onBlur={(e) => emitChange(e, inx, props.name)}
                            onClick={editContent}
                        >
                            {item}
                        </span>
                        {!props.hideDelete &&
                            <span
                                className='delete-edit ml-2 pircross'
                                onClick={(e) => deleteContent(e, inx, props.name)}
                                title="Delete"
                            >
                                <i className="fas fa-times"></i>
                            </span>
                        }
                    </li>
                })}
            </ul>
        </>
    );
}


