import React, { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import NextBtn from '../../../../img/Next.svg'
import PrevBtn from '../../../../img/Previous.svg'
import LastBtn from '../../../../img/Next2.svg'
import FirstBtn from '../../../../img/Previous2.svg'

const PdfPreview = ({ url, show, pdfClick,width }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage(e) {
    e.preventDefault()
    if (pageNumber > 1) {
      changePage(-1);
    } else {
      setPageNumber(1)
    }

  }

  function nextPage(e) {
    e.preventDefault()
    if (pageNumber < numPages) {
      changePage(1);
    } else {
      setPageNumber(numPages)
    }
  }
  function firstPage(e) {
    e.preventDefault()
    setPageNumber(1)
  }
  function lastPage(e) {
    e.preventDefault()
    setPageNumber(numPages)
  }
  return (
    <div onClick={pdfClick}>
      <Document
        file={url}
        // options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}

      >
        <Page pageNumber={pageNumber} width={width}/>
      </Document>
      {/* {
        pageNumber > 1 && */}
      <div>
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        {show &&
          numPages != null && numPages > 1 &&
          <>
            <img src={FirstBtn} alt="Prev" disabled={pageNumber == 1} onClick={(e) => firstPage(e)} style={{ float: "left" }} />
            <img src={PrevBtn} alt="Prev" disabled={pageNumber <= 1} onClick={(e) => previousPage(e)} style={{ float: "left" }} />
            <span style={{ marginLeft: "30px" }}>
              <img src={NextBtn} alt="Next" disabled={pageNumber >= numPages}
                onClick={(e) => nextPage(e)} />
              <img src={LastBtn} alt="Prev" disabled={pageNumber == numPages} onClick={(e) => lastPage(e)} />
            </span>
            {/* <button type="button" disabled={pageNumber <= 1} onClick={(e) => previousPage(e)}>
              Previous
        </button> */}
            {/* <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={(e) => nextPage(e)}
            >
              Next
        </button> */}
          </>
        }

      </div>
      {/* } */}
    </div>
  )
}

export default PdfPreview