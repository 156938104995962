// export const oktaAuthConfig = {
//   // Note: If your app is configured to use the Implicit Flow
//   // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
//   // you will need to add `pkce: false`
//   issuer: 'https://dev-88398315.okta.com/oauth2/default',
//   clientId: '0oa4lay7sV2t7q1zT5d6',
//    redirectUri: window.location.origin + '/login/callback',
// };

// export const oktaSignInConfig = {
//   baseUrl: 'https://dev-88398315.okta.com',
//   clientId: '0oa4lay7sV2t7q1zT5d6',
//    redirectUri: window.location.origin + '/login/callback',
//   authParams: {
//     // If your app is configured to use the Implicit Flow
//     // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
//     // you will need to uncomment the below line
//     pkce: false
//   }
// };
import Biogen from '../../img/biogen-Logo.png'
import Pfizer from '../../img/Pfizer-Logo.png'
import { appConfig } from '../../common/constants';
export const oktaAuthConfig = {
  // Note: If your app is configured to use the Implicit Flow
  // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
  // you will need to add `pkce: false`
  issuer:`${process.env.REACT_APP_OKTAURL}/oauth2/default`,
  clientId: process.env.REACT_APP_OKTACLIENTID,
  redirectUri: window.location.origin + '/login/callback',
  //  logo:Logo
};

export const oktaSignInConfig = {
  baseUrl:process.env.REACT_APP_OKTAURL,
  clientId: process.env.REACT_APP_OKTACLIENTID,
  redirectUri: window.location.origin + '/login/callback',
  // logo: window.location.hostname === appConfig.host1 ? Biogen : window.location.hostname === appConfig.host2 ? Pfizer : Biogen,
  authParams: {
    // If your app is configured to use the Implicit Flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to uncomment the below line
    pkce: false
  }
};
