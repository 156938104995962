import React, { useEffect } from 'react'
import Highcharts from 'highcharts/highstock'
const Chart = (props) => {
  console.log(props,"donut")
  useEffect(() => {
    Highcharts.chart('container6', {
      chart: {
        type: 'pie',
        style: {
          fontFamily: "Roboto, Helvetica, Arial, sans-serif"
      },
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: props.header
      },
      // subtitle: {
      //   text: '3D donut in Highcharts'
      // },
      plotOptions: {
        pie: {
          innerSize: 100,
          depth: 45
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Count',
        data: props.data.length > 0 ? props.data : [
          ['Male', 8],
          ['Female', 3],
          ['Others', 1],

        ]
      }]
    });
  }, [props])
  return (
    <div id="container6" ></div>
  )
}


export default Chart