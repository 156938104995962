import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { apiCall, logOut } from '../../../common/apiconfig';
class Initialpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      obj: [],
      laoding: false,
      accessToken: ""
    }
  }
  // }

  async componentDidMount() {
    let requestBody = `
    mutation {
      generateAccessToken(
        hekmaPatientId: "45",ehrSytemIntegrator: "1uphealth",isBulkUseCase: false
          ) {
          code
          type
          message
          attributes
      }
    }
    `;
    this.setState({ loading: true })
    requestBody = JSON.stringify({ query: requestBody });
    let res = await apiCall('generateAccessToken', requestBody);
    console.log(res, "res")
    this.setState({ loading: false })
    if (!res) {
      console.log('Error on update profile');
      //setApiError(true);
      //setTimeout(function () { setApiError(false) }, 1000 * 30);
    } else {
      if (res.data.generateAccessToken.code == "200") {
        var data = JSON.parse(res.data.generateAccessToken.attributes)
        this.setState({ accessToken: data.access_token })
      }
      // const url = 'https://api.1up.health/user-management/v1/user/auth-code'
      // var data = [{
      //   "app_user_id": "hekma-quickconnect-app",
      //   "client_id": "bf970b6a3cf345edb7ffb23ac14092d6",
      //   "client_secret": "6K05tMg9rq6JFYRtCuP4qQ73EOMLAjcv"
      // }]
      // fetch(url,
      //   {
      //     method: 'POST',
      //     body: JSON.stringify(data),
      //     headers: {
      //       'Content-Type': 'application/json'
      //     }
      //   })
      //   .then(res => {
      //     if (res.status !== 200 && res.status !== 201) {
      //       throw new Error('Failed!');
      //     }
      //     return res.json();
      //   })
      //   .then(responseData => {
      //     console.log(responseData)
      //   })
    }
  }

  render() {

    const { loading } = this.state
    return (
      <>
        {
          loading && (
            <div>Loading</div>
          )
        }
        {
          !loading && (
            <>

              <iframe
                title="Search"
                style={{ border: '0px solid #fff' }}
                src={`https://system-search.1up.health/search/?access_token=${this.state.accessToken}`}
                height={500}
                width="100%"
              />
              <br />

            </>

          )
        }
      </>
    )
  }

}

export default Initialpage
