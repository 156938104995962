import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, getFiles, fileUpload } from '../../../../common/apiconfig';
import FormHoc from '../../../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions } from '../../../../globalcomponents/formElements';
import { Dropdowns, regExpData } from '../../../../common/constants';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip';
import BackIcon from '../../../../img/back1.svg'
import DeleteIcon from '../../../../img/delete.svg'
import { FileUpload, PdfViewer } from './fileupload'
import VideoPlayer from 'simple-react-video-thumbnail'
import PdfPreview from './pdf-preview'
import SimpleModal from './fileviewmodal'
import { useConfirm } from "material-ui-confirm";
import Img from '../../../../img/Resources2.png'

function TabContainer(props) {
  return (
    <Typography component="div" >
      {props.children}
    </Typography>
  );
}
const UpdateCourses = (propsobj) => {
  //console.log(propsobj)
  const confirm = useConfirm();
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  const [value, setValue] = React.useState(0);
  let [links, setLinks] = useState([]);
  let [docs, setDocs] = useState([]);
  let [videos, setVideos] = useState([]);
  let [newLinks, setNewLinks] = useState([]);
  let [docFiles, setDocFiles] = useState([])
  let [videoFiles, setVideoFiles] = useState([])
  let [docsData, setDocsData] = useState([]);
  let [videosData, setVideosData] = useState([]);
  let [oldData, setOldData] = useState({});
  let [pdfUrl, setPdfUrl] = useState("")
  let [imgUrl, setImgUrl] = useState("")
  const [open, setOpen] = useState(false)
  let [urlErr, setUrlErr] = useState('')
  let [docErr, setDocErr] = useState('')
  let [videoErr, setVideoErr] = useState('')

  useEffect(() => {
    setOldData(propsobj.formValues)
  }, [oldData])
  useEffect(() => {
    if (propsobj.state.courseData.link) {
      //console.log(propsobj.state.courseData, propsobj.formValues, "linksdocsvideos")
      var linksArr = []
      for (var i = 0; i < propsobj.state.courseData.link.length; i++) {
        var obj = {
          id: propsobj.state.courseData.link[i].ID,
          url: propsobj.state.courseData.link[i].detail1,
          description: propsobj.state.courseData.link[i].detail2,
          status: propsobj.state.courseData.link[i].status
        }
        linksArr.push(obj)
      }
      setLinks(linksArr)
      setDocs(propsobj.formValues.docUrls || [])
      setVideos(propsobj.formValues.videoUrls || [])
    }
    propsobj.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      propsobj.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, [])

  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsTotype = {
    default: null,
    'options': Dropdowns.Type,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropstocategory = {
    ...autoPropsTotype,
    'options': Dropdowns.Category,
    default: null,
  }
  const autoPropstoLanguages = {
    ...autoPropstocategory,
    'options': Dropdowns.Languages,
    default: null,
  }


  const formSubmit = async (event) => {
    event.preventDefault();
    let linksArr = '';
    var linkClearErr = newLinks.length > 0 ? false : true
    newLinks.map(link => {
      if (link.url == "") {
        setUrlErr("Enter URL")
        linkClearErr = false
      }
      else if (regExpData.urlReg.test(link.url)) {
        setUrlErr('')
        linksArr += `{ buArea: "${propsobj.type}", buid:"${propsobj.formValues.ID}",type:"Link", detail1: "${link.url}",detail2:${JSON.stringify(link.description)}}`;
        linkClearErr = true
      } else {
        setUrlErr("Invalid URL")
        linkClearErr = false
      }

    });
    let linkss = linksArr.replace(/}\s*{/g, '},{');
    let docss
    if (docFiles.length == docsData.length) {
      let docsArr = ''
      setDocErr('')
      docsData.map(doc => {
        docsArr += `{buArea: "${propsobj.type}", buid:"${propsobj.formValues.ID}",type:"Document", detail1: "${doc.id}",detail2:"${doc.fileName}",detail3:"${doc.type}"}`;
      })
      docss = docsArr.replace(/}\s*{/g, '},{');
    } else {
      setDocErr("Upload docs to save files")
    }
    let videoss
    if (videoFiles.length == videosData.length) {
      let videosArr = ''
      setVideoErr('')
      videosData.map(video => {
        videosArr += `{buArea: "${propsobj.type}", buid:"${propsobj.formValues.ID}",type:"Video", detail1: "${video.id}",detail2:"${video.fileName}",detail3:"${video.type}"}`;
      })
      videoss = videosArr.replace(/}\s*{/g, '},{');
    } else {
      setVideoErr("Upload videos to save files")
    }
    var files = []
    // if (linkss.length > 0) {
    files.push(linkss)
    // }
    // if (docss.length > 0) {
    files.push(docss)
    // }
    // if (videoss.length > 0) {
    files.push(videoss)
    // }
    //console.log(files, "Files")
    let setGo = await hocObj.handleSubmit(propsobj.form, event);

    if (setGo && linkClearErr && (docFiles.length == docsData.length) && (videoFiles.length == videosData.length)) {
      setProgress(true);
      let form = propsobj.formValues;
      //console.log(form)
      let requestBody = `
      mutation{
        updateCourseResource(ID:"${form.ID}",studyId:"${form.autosite.sponsorAccountId}",name:${JSON.stringify(form.name)},crs_res_ind:"${propsobj.type.charAt(0)}",summary:${JSON.stringify(form.summary)},type:"${form.type}",category:"${form.category}",language:"${form.language}",version:"${form.version}"){
          code
          type
          message
          attributes
        }
      }`
        ;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('updateCourseResource', requestBody, form);
      setProgress(false);

      let requestBody1 = `
      mutation{
        putSupportDetails(supportDetails:[${files}]){
          code
          type
          message
          attributes
        }
      }`
        ;

      requestBody1 = JSON.stringify({ query: requestBody1 });
      let res1 = await apiCall('putSupportDetails', requestBody1);
      setProgress(false);
      if (linkss == '') {
        if (res && res.data.updateCourseResource.code === '200') {
          // AuditTrailFunc("Study", propsobj.formValues.studyId, propsobj.plural+ "Update", "Course ID: " + propsobj.formValues.ID, oldData, propsobj.formValues)
         
          if(localStorage.getItem('whichApp')==="Site"){
            propsobj.history.push('/site/' + propsobj.plural);
          }else{
            propsobj.history.push('/app/' + propsobj.plural);
          }
        } else {
          //console.log('Error')
        }
      } else {
        if (res1 && res1.data.putSupportDetails.code === '200' && res && res.data.updateCourseResource.code === '200') {
          if(localStorage.getItem('whichApp')==="Site"){
            propsobj.history.push('/site/' + propsobj.plural);
          }else{
            propsobj.history.push('/app/' + propsobj.plural);
          }
       
        } else {
          //console.log('Error')
        }

      }
    }
  }

  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.push('/site/' + propsobj.plural)
  }
  const handleChange = (event, index) => {
    var products = newLinks.slice() //or [...links];
    var newUris = products.map((item, i) => {
      if (i == index) {
        item[event.target.name] = event.target.value
      }
      return item
    })
    setNewLinks(newUris)

  }
  const addNew = (e) => {
    e.preventDefault()
    if (newLinks.length > 0) {
      if (newLinks[newLinks.length - 1].url == "") {
        setUrlErr("Enter URL")
      }
      else if (!regExpData.urlReg.test(newLinks[newLinks.length - 1].url)) {
        setUrlErr("Invalid URL")
      } else {
        setUrlErr('')
        var addlinks = [...newLinks, { url: "", description: "" }]
        setNewLinks(addlinks)
      }
    } else {
      var addlinks = [...newLinks, { url: "", description: "" }]
      setNewLinks(addlinks)
    }

  }
  const removeLink = (e, index) => {
    var arr = [...newLinks]
    arr.splice(index, 1);
    setNewLinks(arr)
  }
  const deletelink = async (e, link, index) => {
    confirm({ description: `This will permanently delete ${link.url}.` })
      .then(() => deleteLinksApi(link, index))
      .catch(() => console.log("Deletion cancelled."));
  }
  const deleteLinksApi = async (link, index) => {
    setProgress(true);
    let res = await deletefunc(link.id)
    setProgress(false);

    if (res && res.data.deleteSupportDetails.code === '200') {
      //console.log('deleted')
      // var arr = links.filter(data => data.id != link.id);
      // setLinks(arr)
      let data = [...links]
      //console.log(data, "links")
      for (var i = 0; i < data.length; i++) {
        if (i == index) {
          data[index]["status"] = (data[i]["status"] === "Y" ? "N" : "Y")
        }
      }
      //console.log(data, "newlinks")
      setLinks(data)
    } else {
      //console.log('Error!')
    }
  }
  const deletefunc = async (id) => {
    let requestBody = `
    mutation{
      deleteSupportDetails(id:"${id}"){
        code
        type
        message
        attributes
      }
    }`
      ;

    requestBody = JSON.stringify({ query: requestBody });
    let res = await apiCall('deleteSupportDetails', requestBody);
    return res
  }
  //upload events starts from here
  //events for existing data
  const download = async (e, file) => {
    e.preventDefault()
    let res = await getFiles('fileupload', file, "GET");
    if (res.code == 200) {
      var data = JSON.parse(res.attributes)
      window.open(data.URL)
    }
  }

  const deleteFile = async (e, index, file, filetype) => {
    e.preventDefault()
    confirm({ description: `This will permanently delete ${file.detail2}.` })
      .then(() => deleteFilesApi(index, file, filetype))
      .catch(() => console.log("Deletion cancelled."));

  }
  const deleteFilesApi = async (index, file, filetype) => {
    let res = await deletefunc(file.ID)
    if (res && res.data.deleteSupportDetails.code === '200') {
      if (filetype === "docs") {
        // var arr = docs.filter(docs => docs.ID != file.ID);
        // 
        let data = [...docs]
        for (var i = 0; i < data.length; i++) {
          if (i == index) {
            data[index]["status"] = (data[i]["status"] === "Y" ? "N" : "Y")
          }
        }
        setDocs(data)
      } else {
        // var arr = videos.filter(videos => videos.ID != file.ID);
        let data = [...videos]
        //console.log(data, "Data")
        for (var i = 0; i < data.length; i++) {
          if (i == index) {
            data[index]["status"] = (data[i]["status"] == "Y" ? "N" : "Y")
          }
        }
        setVideos(data)
      }
    } else {
      //console.log('Error!')
    }
  }
  //events for new data
  //uploaded docs or videos to save
  const fileSave = async (files) => {
    if (value == 1) {
      var docsInfo = []
      for (var i = 0; i < files.length; i++) {
        let requestBody = { "filename": files[i].name }
        requestBody = JSON.stringify(requestBody);
        setProgress(true)
        let res = await fileUpload('fileupload', requestBody);
        if (res.code == 200) {
          var data = JSON.parse(res.attributes)
          var fdata = {
            id: data.Id,
            fileName: files[i].name,
            type: files[i].type
          }
          docsInfo.push(fdata)
          let blob = new Blob([files[i]]);
          const response = await fetch(data.URL, {
            method: "PUT",
            headers: {
              'Content-Type': files[i].type,
            },
            body: blob,
          });
          const status = await response.status;
          if (status == 200) {
            setProgress(false)
          }
        } else {
          alert('failed to upload')
        }
      }
      setDocsData(docsInfo)
    }
    if (value == 2) {
      var videosInfo = []
      setProgress(true)
      for (var i = 0; i < files.length; i++) {
        let requestBody = { "filename": files[i].name }
        requestBody = JSON.stringify(requestBody);
        setProgress(true)
        let res = await fileUpload('fileupload', requestBody);
        if (res.code == 200) {
          var data = JSON.parse(res.attributes)
          var vdata = {
            id: data.Id,
            fileName: files[i].name,
            type: files[i].type
          }
          videosInfo.push(vdata)
          let blob = new Blob([files[i]]);
          const response = await fetch(data.URL, {
            method: "PUT",
            headers: {
              'Content-Type': files[i].type,
            },
            body: blob,
          });
          const status = await response.status;
          if (status == 200) {
            setProgress(false)
          }
        } else {
          alert('failed to upload')
        }
      }
      setVideosData(videosInfo)
    }

  }

  const onChangeFile = (files) => {
    var filteredArray = [];
    var existingdata = (value == 1) ? docFiles : videoFiles
    if (files.length) {
      for (var index = 0; index < files.length; index++) {
        let exists = false;
        for (var i = 0; i < existingdata.length; i++) {
          if (existingdata[i].name === files[index].name) {
            exists = true;
            break;
          }
        }
        if (!exists) {
          filteredArray.push(files[index]);
        }
      }
    }
    var data = filteredArray.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))
    if (value == 1) {
      setDocFiles([...docFiles, ...data])
    }
    if (value == 2) {
      setVideoFiles([...videoFiles, ...data])
    }

  }
  const removeFile = (e, name) => {
    //console.log(e, name, "name")
    if (value == 1) {
      var filesData = [...docFiles]
      docFiles.map(file => (file.name === name && filesData.splice(filesData.indexOf(file), 1)))
      setDocFiles(filesData)
      var newDocsData = [...docsData]
      docsData.map(file => (file.fileName === name && newDocsData.splice(newDocsData.indexOf(file), 1)))
      setDocsData(newDocsData)
    }
    if (value == 2) {
      var filesData = [...videoFiles]
      videoFiles.map(file => (file.name === name && filesData.splice(filesData.indexOf(file), 1)))
      setVideoFiles(filesData)
      var newVideosData = [...videosData]
      videosData.map(file => (file.fileName === name && newVideosData.splice(newVideosData.indexOf(file), 1)))
      setVideosData(newVideosData)
    }
  }
  const image = (url) => {
    return <img src={url} alt="image" height="75" width="75" />
  }

  const onClose = () => {
    setOpen(false)
  }
  const pdfClick = (e, url) => {
    e.preventDefault()
    setOpen(true)
    setPdfUrl(url)
    setImgUrl("")
  }
  const imgClick = (e, url) => {
    e.preventDefault()
    setOpen(true)
    setImgUrl(url)
    setPdfUrl("")
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      {/* <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO {propsobj.plural} LIST</div><br /> */}
      <div className="d-flex justify-content-end">
        <div className="mr-auto secondary-header">Edit {propsobj.type}</div>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id={propsobj.form} className="row" onSubmit={formSubmit}>
          <SimpleModal open={open} pdfUrl={pdfUrl} imgUrl={imgUrl} onClose={onClose} />
          <div className="col-md-6">

            <div className="panel-primary-wrap">
              {/* <div className="row"> */}
              <div className="card">
                <div className="card-body">

                  <div className="card-page-header">{propsobj.type} INFORMATION</div>
                  <div className="inputBox">
                    {/* <div className="row"> */}
                    {/* <div className="col-md-4"> */}
                    <InputField {...textProps} name="name" label="Name" must={'true'} type="text" />
                    {/* </div> */}
                    {/* <div className="col-md-4"> */}
                    <AutoOptions {...autoPropstocategory} name="category" label="Category" keyName='value' must={'true'} />
                    {/* </div> */}
                    {/* <div className="col-md-4"> */}
                    <InputField {...textProps} multiline={true} row={1} rowsMax={4} name="summary" label="Summary" must={'false'} type="text" />
                    {/* </div> */}
                    {/* <div className="col-md-4"> */}
                    <AutoOptions {...autoPropstoLanguages} name="language" label="Select Language" keyName='value' must={'true'} />
                    {/* </div> */}
                    {/* <div className="col-md-4"> */}
                    <AutoOptions {...autoPropsTotype} name="type" label="Type" keyName='value' must={'true'} />
                    {/* </div> */}
                    {/* <div className="col-md-4"> */}
                    <InputField {...textProps} name="version" label="Version" must={'true'} type="text" />
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="col-md-6" style={{ display: "flex", alignItems: "center" }}>
            <img src={Img} alt="Image" />
          </div>
          <div className="col-md-12">

            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">{propsobj.type} FILES</div>
                  <div className="inputBox">
                    <Tabs
                      value={value}
                      onChange={handleTabsChange}
                      indicatorColor="primary"
                      textColor="primary"

                    >
                      <Tab label="Links" />
                      <Tab label="Documents" />
                      <Tab label="Videos" />
                      <div style={{ display: "inline-flex" }}>
                        <div className="errMsg">{urlErr}</div>
                        <div className="errMsg">{docErr}</div>
                        <div className="errMsg">{videoErr}</div>
                      </div>
                    </Tabs>
                    {value === 0 && <TabContainer>

                      <>
                        <div className="row">

                          <div className="col-md-10">
                            {links.length > 0 ?
                              <div class="inputBox">
                                <table class="viewCourseLinks">
                                  <thead className="">
                                    <tr>
                                      <th>
                                        S.NO
                                      </th>
                                      <th>Link/URL</th>
                                      <th>Description</th>
                                      <th>Status</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {links.map((item, i) => {
                                      return (
                                        <tr>
                                          <td style={{ width: "7%" }}>{i + 1}</td>
                                          <td className="linebreak" style={{ width: "35%" }}>{links[i].url}</td>
                                          <td className="linebreak" style={{ width: "40%" }}>{links[i].description}</td>
                                          <td className="filestatus">{links[i].status == "Y" ? "Active" : "Inactive"}</td>
                                          <td className="td_action">
                                            {item.status == "Y" ?
                                              <div className="action_div delIconColor" onClick={(e) => { deletelink(e, links[i], i) }}>
                                                <Tooltip title="Remove"><i className="far fa-trash-alt fa-lg"></i>
                                                </Tooltip>
                                              </div>
                                              : ""}
                                          </td>
                                        </tr>
                                      )
                                    }
                                    )
                                    }
                                  </tbody>
                                </table>
                              </div> : newLinks.length == 0 && "No records to display"}

                            {newLinks.length > 0 &&
                              <div class="inputBox">
                                <table class="viewCourseLinks">
                                  {links.length == 0 &&
                                    <thead className="">
                                      <tr>
                                        <th>
                                          S.NO
                                      </th>
                                        <th>Link/URL</th>
                                        <th>Description</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                  }
                                  <tbody>
                                    {
                                      newLinks.length > 0 &&
                                      newLinks.map((item, i) => {
                                        return (
                                          <tr>
                                            <td style={{ width: "7%" }}>{i + links.length + 1}</td>
                                            <td style={{ width: "35%" }}>
                                              <TextField
                                                value={newLinks[i].url}
                                                // label="Add URL"
                                                type="text"
                                                name="url"
                                                onChange={(e) => handleChange(e, i)}
                                                style={{ width: "100%" }}
                                              />
                                            </td>
                                            <td style={{ width: "40%" }}>
                                              <TextField
                                                multiline={true}
                                                rows={1}
                                                rowsMax={4}
                                                value={newLinks[i].description}
                                                // label="Description"
                                                type="text"
                                                name="description"
                                                onChange={(e) => handleChange(e, i)}
                                                style={{ width: "100%" }}
                                              />

                                            </td>
                                            {links.length > 0 &&
                                              <td style={{ width: "9%" }}></td>
                                            }
                                            <td className="td_action">
                                              <div className="action_div"  >
                                                <Tooltip title="Remove"><Icon color="error" onClick={(e) => removeLink(e, i)} > remove_circle</Icon></Tooltip>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                  </tbody>
                                </table>
                              </div>
                            }
                          </div>

                          <div className="col-md-2">
                            <div className="action_div" >
                              <button onClick={addNew} type="button" className="btn btn-primary-blue btn-rounded" style={{ width: "92%" }}>
                                <i className="fa fa-plus-circle mr-1"></i> Add New Url</button>
                            </div>
                          </div>
                        </div>
                        {/* <div className="errMsg">{urlErr}</div> */}
                      </>


                    </TabContainer>}
                    {value === 1 && <TabContainer>
                      <>
                        <div className="inputBox">
                          {docs.length > 0 &&
                            <table className="viewCourseLinks">
                              <thead>
                                <tr>
                                  {/* <th style={{ width: "10%" }}>S. NO</th> */}
                                  <th style={{ width: "15%" }}>TYPE</th>
                                  <th style={{ width: "35%" }}>NAME</th>
                                  <th style={{ width: "20%" }}>PREVIEW</th>
                                  <th>STATUS</th>
                                  <th style={{ width: "10%" }}>ACTION</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  docs.map((item, i) => (
                                    <tr>
                                      {/* <td>{i + 1}</td> */}
                                      <td>{item.detail3}</td>
                                      <td onClick={(e) => { download(e, item) }} style={{ cursor: "pointer" }}><Tooltip title="Click to download" arrow><a href="#">  {item.detail2}</a></Tooltip></td>
                                      <td className="previewpdf">{item.detail3.includes("image") ? <span onClick={(e) => imgClick(e, item.url)}>{image(item.url)}</span> : item.detail3.includes("pdf") && <span style={{ cursor: "pointer" }} onClick={(e) => pdfClick(e, item.url)}><PdfPreview url={item.url} show={false} width="75" />
                                      </span>}
                                      </td>
                                      <td>{item.status == "Y" ? <span style={{ color: "#203E69" }}>Active</span> : <span style={{ color: "red" }}>Inactive</span>}</td>
                                      {/* <td><img src={DeleteIcon} alt="Delete" onClick={(e) => deleteFile(e, item)} /></td> */}
                                      {item.status == "Y" ?
                                        <td className="action_div delIconColor" onClick={(e) => deleteFile(e, i, item, "docs")}>
                                          <Tooltip title="Delete"><i className="far fa-trash-alt fa-lg"></i></Tooltip></td> : ""}
                                    </tr>
                                  ))
                                }

                              </tbody>
                            </table>}
                        </div>
                        <FileUpload dataToAccept='image/*,.pdf,.xlsx,.txt,.xls,.ppt,.pptx,.doc,.docx' fileSave={fileSave} onChangeFile={onChangeFile} allFiles={docFiles} removeFile={removeFile} disabled={docFiles.length == docsData.length} /><br />
                        {/* <div className="errMsg">{docErr}</div> */}
                      </>
                    </TabContainer>}
                    {value === 2 && <TabContainer>
                      <>
                        <div className="inputBox">
                          {videos.length > 0 &&
                            <table className="viewCourseLinks">
                              <thead>
                                <tr>
                                  {/* <th style={{ width: "10%" }}>S. NO</th> */}
                                  <th style={{ width: "15%" }}>TYPE</th>
                                  <th style={{ width: "35%" }}>NAME</th>
                                  <th style={{ width: "20%" }}>PREVIEW</th>
                                  <th>STATUS</th>
                                  {/* {item.status == "Y" ? */}
                                  <th style={{ width: "10%" }}>ACTION</th>
                                  {/* :""} */}
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  videos.map((item, i) => (
                                    <tr>
                                      {/* <td>{i + 1}</td> */}
                                      <td>{item.detail3}</td>
                                      <td onClick={(e) => { download(e, item) }} style={{ cursor: "pointer" }}><Tooltip title="Click to download" arrow><a href="#">  {item.detail2}</a></Tooltip></td>
                                      <td>
                                        <VideoPlayer videoUrl={item.url} snapshotAt={2} />
                                      </td>
                                      <td>{item.status == "Y" ? <span style={{ color: "#203E69" }}>Active</span> : <span style={{ color: "red" }}>Inactive</span>}</td>
                                      {/* <td><img src={DeleteIcon} alt="Delete" onClick={(e) => deleteFile(e, item)} /></td> */}
                                      {item.status == "Y" ?
                                        <td className="action_div delIconColor" onClick={(e) => deleteFile(e, i, item, "videos")}>
                                          <Tooltip title="Delete"><i className="far fa-trash-alt fa-lg"></i></Tooltip></td> : ""}
                                    </tr>
                                  ))
                                }

                              </tbody>
                            </table>}
                        </div>
                        <FileUpload dataToAccept='video/*' fileSave={fileSave} onChangeFile={onChangeFile} allFiles={videoFiles} removeFile={removeFile} disabled={videoFiles.length == videosData.length} />
                      </><br />
                      {/* <div className="errMsg">{videoErr}</div> */}
                    </TabContainer>}

                  </div>

                </div>
              </div>
            </div>
            <div className="buttonContainer mb-3">
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={goBack}>CANCEL</button>
            </div>
          </div>
        </form >
      </div >
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateCourses));



