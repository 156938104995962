import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../common/apiconfig';
import FormHoc from '../hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew } from '../formElements';
import { Dropdowns, regExpData } from '../../common/constants';
import { connect } from 'react-redux';
import * as constObj from '../../common/constants';
import AuditTrailFunc from '../../common/AuditTrailFunc'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const UpdateContact = (propsobj) => {
  let roleObj = {
    roleName: propsobj.formValues.role || '',
    roleId: propsobj.formValues.roleId || '',
    error: false,
    helperText: '',
  }
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [oldData, setOldData] = useState({});
  let [portal, setPortal] = useState(false)
  let [phoneCheck, setPhoneError] = useState('')
  let [roleField, setRoleField] = useState(roleObj);
  let [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setOldData(propsobj.formValues)
  }, [oldData])

  useEffect(() => {
    if (propsobj.formValues.roleId != null) {
      setPortal(true)
    } else {
      setPortal(false)
    }
    if (localStorage.getItem("sponsorrole") === "Business User") {
      if (localStorage.getItem('loginUserId') === propsValues.formValues.sponsorAccountUserId) {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    }
  }, [])

  let propsValues = { ...propsobj };
  const hocObj = FormHoc(propsValues);

  const textProps = {
    ...hocObj,
    propsobj: propsValues
  }

  const autoPropsToLang = {
    default: null,
    'options': Dropdowns.Languages,
    freeSolo: false,
    propsobj: propsValues,
    keys: ['value']
  }
  const autoPropstoposition = {
    ...autoPropsToLang,
    'options': propsobj.formValues.accountType == "Sponsor" ? Dropdowns.Contact_Role : Dropdowns.Study_Site_Primary_Contact_Role,
    default: null,
  }
  const autoPropstorole = {
    'options': Dropdowns.Roles,
    propsobj,
    keys: ['roleName'],
    error: roleField.error,
    helperText: roleField.helperText
  }
  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('updateContactForm', event);
    let phoneCheck = regExpData.phoneReg.test(propsobj.formValues.phone)
    if (propsobj.formValues.phone != "" && phoneCheck == false) {
      setPhoneError("Invalid number")
    } else {
      setPhoneError("")
      phoneCheck = true
    }
    if (setGo && phoneCheck) {
      let form = propsValues.formValues;
      if (oldData.portalAccessInd !== "Y" && form.roleId != (null || "")) {
        let payload = `mutation {
            grantAuthToUser(accountUserId:"${form.sponsorAccountUserId}",roleId:"${form.roleId}")
            {
              code
              message
              type
              attributes
            }
        }`
        payload = JSON.stringify({ query: payload });
        let res = await apiCall('grantAuthToUser', payload);
        setProgress(false);
        if (!res) {
          console.log('Error on response update contact role')
        } else {
          console.log('contact role updated');
        }
      }
      let requestBody = `
            mutation {
                updateSponsorAccountUser(
                    sponsorAccountUserId: "${form.sponsorAccountUserId}"
                    firstName: "${form.firstName}" 
                    middleName:"${form.middleName}" 
                    lastName :"${form.lastName}"
                    email :"${form.email}"
                    roleId:"${form.roleId == null ? "" : form.roleId}"
                    phone:"${form.phone}"
                    position:"${form.position}"
                    language:"${form.language}"
                   ) {
                  code
                  type
                  message
                  attributes
              }
            }
          `;
      setProgress(true);
      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('updateSponsorAccountUser', requestBody);
      setProgress(false);
      if (!res) {
        console.log('Error on response update contact')
        //setApiError(true);
        //setTimeout(function () { setApiError(false) }, 1000 * 30);
      } else {
        console.log('form updated');
        // propsobj.clearForm();
        // propsobj.history.push('/account/accountDetails/Contacts');
        AuditTrailFunc("Account", propsobj.formValues.sponsorAccountId, "Contact Update", "Contact ID:" + propsobj.formValues.sponsorAccountUserId, oldData, propsobj.formValues)
        propsobj.history.goBack()
        propsobj.UpdateFormField({ value: propsobj.formValues.accountName, name: "accountName" })
        //logOut();
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    propsValues.history.goBack();
  }
  const handlePortalChange = () => {
    setPortal(!portal)
    let errobj = {
      name: "role",
      flag: false,
      text: "",
      // change: true
    }
    propsobj.updateErrorObj(errobj);
  }
  const handleRole = async (obj) => {
    console.log(obj, "obj")
    let roleObj = {
      roleName: '',
      roleId: '',
      error: false,
      helperText: '',
    }
    setRoleField(roleObj);
    if (obj) {
      let roleObj = {
        roleName: obj.value,
        roleId: obj.selected.roleId,
        error: obj.flag,
        helperText: obj.text,
      }
      setRoleField(roleObj);
      propsobj.UpdateFormField({ name: "roleId", value: obj.selected.roleId })
      propsobj.UpdateFormField({ name: "roleName", value: obj.value })
    }
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">{propsobj.formValues.siteUserHeading == "" ? "EDIT CONTACT" : "EDIT USER"}</h1>
      </div>
      <div>

        <form noValidate autoComplete="off" id="updateContactForm" className="inputForm row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">NAME INFORMATION</div>
                  <div className="inputBox">

                    <InputField {...textProps} name="firstName" label="First Name" must={'true'} type="text" />

                    <InputField {...textProps} name="middleName" label="Middle Name" must={'false'} type="text" />

                    <InputField {...textProps} name="lastName" label="Last Name" must={'true'} type="text" />

                    <InputField {...textProps} name="SponsorAccountName" label="Account Name" must={'true'} type="text" disabled={true} />

                    <AutoOptions {...autoPropstoposition} name="position" label="Position" keyName='value' must={'true'} />

                    <AutoOptions {...autoPropsToLang} name="language" label="Language" keyName='value' must={'true'} />

                    <div className="row">
                      <div className="col-md-5">
                        <FormGroup style={{ paddingTop: "15px" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={portal}
                                onChange={(e) => { handlePortalChange(e) }}
                                value="true"
                                name="portalaccess"
                                color="primary"
                                disabled={disabled}
                              />
                            }
                            label="Portal access"
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-7">
                        {
                          portal &&
                          <AutoOptionsNew {...autoPropstorole} autoValue={roleField} name="role" label="User Access" keyName='roleName' must={portal.toString()} callback={handleRole} disabled={disabled} />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">CONTACT INFORMATION</div>
                  <div className="inputBox">
                    <div style={{ position: "relative" }}>
                      <InputField {...textProps} name="phone" label="Phone" must={'true'} type="text" />
                      {phoneCheck.length > 0 && <span className="phoneerr">{phoneCheck}</span>}
                    </div>
                    {/* <InputField {...textProps} name="phone" label="Phone" must={'true'} type="text" /> */}
                    <InputField {...textProps} name="email" label="Email" must={'true'} type="email" />
                  </div>

                </div>
              </div>
            </div>
            <div className="buttonContainer">
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
            </div>
          </div>

        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    rowData: state.rowData,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj.value,
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateContact));



