import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { apiCall, getFiles, fileUpload } from '../../../../common/apiconfig';
import CircularProgress from '@material-ui/core/CircularProgress';

const FileUpdate = ({ text, dataToAccept,callGetData, loading,formValues }) => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: dataToAccept,
    noKeyboard: true, onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }, multiple: false

  });

  const callapi = async () => {
    var fdata
    let requestBody = { "filename": files[0].name }
    requestBody = JSON.stringify(requestBody);
    // setProgress(true)
    loading(true)
    let res = await fileUpload('fileupload', requestBody);
    if (res.code == 200) {
      var data = JSON.parse(res.attributes)
      fdata = {
        id: data.Id,
        fileName: files[0].name,
        type: files[0].type
      }
      // docsInfo.push(fdata)
      let blob = new Blob([files[0]]);
      const response = await fetch(data.URL, {
        method: "PUT",
        headers: {
          'Content-Type': files[0].type,
        },
        body: blob,
      });
      const status = await response.status;
      if (status == 200) {
        // setProgress(false)
        let requestBody = `
          mutation
                {
                  putSupportDetails(supportDetails:[{buArea:"MailTemplate",buid:"${formValues.autosite.sponsorAccountId}",type:"mailtemplates",detail1:"${fdata.id}",detail2:"${fdata.fileName}",detail3:"${fdata.type}"}]) {
                    code
                    type
                    message
                    attributes
                  }
                }`
        requestBody = JSON.stringify({ query: requestBody });
        let res = await apiCall('putSupportDetails', requestBody);
        // setProgress(false);
        // loading(false)
        if (res.data.putSupportDetails.code === '200') {
          callGetData()
        } else {
          console.log('Error!')
        }
      }

    } else {
      alert('failed to upload')
    }
  }
  // const callGetData = async () => {
  //   let requestBody = `
  //       query {
  //         getSupportDetailsWithStatus(buArea: "MailTemplate", buid: "1001", type: "mailtemplates", status: "*") {
  //           ID
  //           buArea
  //           buid
  //           type
  //           status
  //           detail1
  //           detail2
  //           detail3
  //           detail4
  //           addedById
  //           addedByName
  //           addedTs
  //         }
  //       } `;

  //   requestBody = JSON.stringify({ query: requestBody });
  //   // setProgress(true);

  //   let res = await apiCall('getSupportDetailsWithStatus', requestBody);
  //   // setProgress(false);
  //   if (!res) {
  //     console.log('Error!')
  //   } else {
  //     console.log(res, "mail templates")
  //     // loading(false)
  //     var data=res.data.getSupportDetailsWithStatus
  //     var urldata = data.map(v => ({ ...v, url: "" }))
  //     for (var i = 0; i < data.length; i++) {
  //       let res = await getFiles('fileupload', data[i], "GET");
  //       if (res.code === 200) {
  //         let data = JSON.parse(res.attributes)
  //         urldata[i]["url"] = data.URL
  //       }

  //     }
  //     librarydata(urldata)
  //   }
  // }
  if (files.length > 0) {
    callapi()
    setFiles([])
  }
  return (
    <>
 
      <span {...getRootProps()}>
        <input {...getInputProps()} />
        <span> <button className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
          {text}</button></span>

      </span>
    </>
  )
}

export default FileUpdate