import React from 'react'
import UpdateCourse from '../fileupload/updateCoursecommon'

const UpdateCourses=()=>{
  return(
    <>
    <UpdateCourse type="Course" plural="courses" form="updateCourse"/>
    </>
  )
}
export default UpdateCourses