// import React, { useEffect, useState } from "react";
// import { withRouter } from 'react-router-dom';
// import Icon from '@material-ui/core/Icon';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import { apiCall, logOut, fileUpload } from '../../../common/apiconfig';
// import FormHoc from '../../hoc/formHoc';
// import { InputField, AutoOptions } from '../../formElements';
// import { Dropdowns, regExpData } from '../../../common/constants';
// import { connect } from 'react-redux';
// import Typography from '@material-ui/core/Typography';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import TextField from '@material-ui/core/TextField'
// import Tooltip from '@material-ui/core/Tooltip';
// import BackIcon from '../../../../img/back1.svg'
// import { FileUpload } from '../fileupload/fileupload'
// function TabContainer(props) {
//   return (
//     <Typography component="div" >
//       {props.children}
//     </Typography>
//   );
// }
// const AddResources = (propsobj) => {
//   let [apiError, setApiError] = useState(false);
//   let [progress, setProgress] = useState(false);
//   const [value, setValue] = React.useState(0);
//   let [links, setLinks] = useState([{ url: "", description: "" }]);
//   let [docFiles, setDocFiles] = useState([])
//   let [videoFiles, setVideoFiles] = useState([])
//   let [docsData, setDocsData] = useState([]);
//   let [videosData, setVideosData] = useState([]);
//   let [urlErr, setUrlErr] = useState('')
//   let [docErr, setDocErr] = useState('')
//   let [videoErr, setVideoErr] = useState('')

//   useEffect(() => {
//     propsobj.UpdateFormField({ value: "", name: "name" })
//     propsobj.UpdateFormField({ value: "", name: "summary" })
//     propsobj.UpdateFormField({ value: "", name: "category" })
//     propsobj.UpdateFormField({ value: "", name: "type" })
//     propsobj.UpdateFormField({ value: "", name: "language" })
//     propsobj.UpdateFormField({ value: "", name: "version" })
//     setLinks([{ url: "", description: "" }])
//   }, [])
//   const handleTabsChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const hocObj = FormHoc(propsobj);

//   const textProps = {
//     ...hocObj,
//     propsobj
//   }

//   const autoPropsTotype = {
//     default: null,
//     'options': Dropdowns.Type,
//     freeSolo: false,
//     propsobj,
//     keys: ['value']
//   }

//   const autoPropstocategory = {
//     ...autoPropsTotype,
//     'options': Dropdowns.Category,
//     default: null,
//   }
//   const autoPropstoLanguages = {
//     ...autoPropstocategory,
//     'options': Dropdowns.Languages,
//     default: null,
//   }


//   const formSubmit = async (event) => {
//     event.preventDefault();
//     let linksArr = '';
//     var linkClearErr = links.length > 0 ? false : true
//     links.map(link => {
//       if (link.url == "") {
//         setUrlErr("Enter URL")
//         linkClearErr = false
//       }
//       else if (regExpData.urlReg.test(link.url)) {
//         setUrlErr('')
//         linksArr += `{ buArea: "Resource",type:"Link", detail1: "${link.url}",detail2:${JSON.stringify(link.description)}}`;
//         linkClearErr = true
//       } else {
//         setUrlErr("Invalid URL")
//         linkClearErr = false
//       }
//     });
//     let linkss = linksArr.replace(/}\s*{/g, '},{');
//     let docss
//     if (docFiles.length == docsData.length) {
//       let docsArr = ''
//       setDocErr('')
//       docsData.map(doc => {
//         docsArr += `{buArea: "Resource",type:"Document", detail1: "${doc.id}",detail2:"${doc.fileName}",detail3:"${doc.type}"}`;
//       })
//       docss = docsArr.replace(/}\s*{/g, '},{');
//     } else {
//       setDocErr("Upload docs to save")
//     }
//     let videoss
//     if (videoFiles.length == videosData.length) {
//       let videosArr = ''
//       setVideoErr('')
//       videosData.map(video => {
//         videosArr += `{buArea: "Resource",type:"Video", detail1: "${video.id}",detail2:"${video.fileName}",detail3:"${video.type}"}`;
//       })
//       videoss = videosArr.replace(/}\s*{/g, '},{');
//     } else {
//       setVideoErr("Upload videos to save")
//     }
//     var files = []
//     // if (linkss.length > 0) {
//     files.push(linkss)
//     // }
//     // if (docss.length > 0) {
//     files.push(docss)
//     // }
//     // if (videoss.length > 0) {
//     files.push(videoss)
//     // }
//     console.log(files, linkClearErr, "Files")
//     let setGo = await hocObj.handleSubmit('addResource', event);
//     console.log(linksArr)
//     if (setGo && linkClearErr && (docFiles.length == docsData.length) && (videoFiles.length == videosData.length)) {
//       setProgress(true);
//       let form = propsobj.formValues;
//       console.log(form)
//       let requestBody = `
//       mutation{
//         putCourseResourceWithSupport(studyId:"${form.studyId}",name:${JSON.stringify(form.name)},crs_res_ind:"R",summary:${JSON.stringify(form.summary)},type:"${form.type}",category:"${form.category}",language:"${form.language}",version:"${form.version}",supportDetails:[${files}]){
//           code
//           type
//           message
//           attributes
//         }
//       }`
//         ;

//       requestBody = JSON.stringify({ query: requestBody });
//       let res = await apiCall('putCourseResourceWithSupport', requestBody, form);
//       setProgress(false);

//       if (res && res.data.putCourseResourceWithSupport.code === '200') {
//         propsobj.history.push('/account/studyDetails/resources');
//       } else {
//         console.log('Error!')
//       }
//     }
//   }

//   const handleCancel = (event) => {
//     event.preventDefault();
//     propsobj.history.goBack();
//   }

//   const goBack = (event) => {
//     event.preventDefault();
//     propsobj.history.goBack()
//   }
//   const handleChange = (event, index) => {
//     var products = links.slice() //or [...links];
//     var newUris = products.map((item, i) => {
//       if (i == index) {
//         item[event.target.name] = event.target.value
//       }
//       return item
//     })
//     setLinks(newUris)

//   }
//   const addNew = () => {
//     if (links.length >= 1) {
//       if (links[links.length - 1].url == "") {
//         setUrlErr("Enter URL")
//       }
//       else if (!regExpData.urlReg.test(links[links.length - 1].url)) {
//         setUrlErr("Invalid URL")
//       } else {
//         setUrlErr('')
//         var newlinks = [...links, { url: "", description: "" }]
//         setLinks(newlinks)
//       }
//     } else {
//       var newlinks = [...links, { url: "", description: "" }]
//       setLinks(newlinks)
//     }
//   }
//   const removeLink = (e, index) => {
//     var arr = [...links]
//     // if (index != 0) {
//     arr.splice(index, 1);
//     // }
//     setLinks(arr)
//     if (arr.length == 0) {
//       setUrlErr('')
//     }
//   }
//   //uploaded docs or videos to save
//   const fileSave = async (files) => {
//     if (value == 1) {
//       var docsInfo = []
//       for (var i = 0; i < files.length; i++) {
//         let requestBody = { "filename": files[i].name }
//         requestBody = JSON.stringify(requestBody);
//         setProgress(true)
//         let res = await fileUpload('fileupload', requestBody);
//         if (res.code == 200) {
//           var data = JSON.parse(res.attributes)
//           var fdata = {
//             id: data.Id,
//             fileName: files[i].name,
//             type: files[i].type
//           }
//           docsInfo.push(fdata)
//           let blob = new Blob([files[i]]);
//           const response = await fetch(data.URL, {
//             method: "PUT",
//             body: blob,
//           });
//           const status = await response.status;
//           if (status == 200) {
//             setProgress(false)
//           }

//         } else {
//           alert('failed to upload')
//         }
//       }
//       setDocsData(docsInfo)
//     }
//     if (value == 2) {
//       var videosInfo = []
//       setProgress(true)
//       for (var i = 0; i < files.length; i++) {
//         let requestBody = { "filename": files[i].name }
//         requestBody = JSON.stringify(requestBody);
//         setProgress(true)
//         let res = await fileUpload('fileupload', requestBody);
//         if (res.code == 200) {
//           var data = JSON.parse(res.attributes)
//           var vdata = {
//             id: data.Id,
//             fileName: files[i].name,
//             type: files[i].type
//           }
//           videosInfo.push(vdata)
//           let blob = new Blob([files[i]]);
//           const response = await fetch(data.URL, {
//             method: "PUT",
//             body: blob,
//           });
//           const status = await response.status;
//           if (status == 200) {
//             setProgress(false)
//           }
//         } else {
//           alert('failed to upload')
//         }
//       }
//       setVideosData(videosInfo)
//     }

//   }

//   const onChangeFile = (files) => {
//     var data = files.map(file => Object.assign(file, {
//       preview: URL.createObjectURL(file)
//     }))
//     if (value == 1) {
//       setDocFiles([...docFiles, ...data])
//     }
//     if (value == 2) {
//       setVideoFiles([...videoFiles, ...data])
//     }
//   }
//   const removeFile = (e, name) => {
//     console.log(e, name, "name")
//     if (value == 1) {
//       var filesData = [...docFiles]
//       docFiles.map(file => (file.name === name && filesData.splice(filesData.indexOf(file), 1)))
//       setDocFiles(filesData)
//     }
//     if (value == 2) {
//       var filesData = [...videoFiles]
//       videoFiles.map(file => (file.name === name && filesData.splice(filesData.indexOf(file), 1)))
//       setVideoFiles(filesData)
//     }
//   }
//   return (
//     <>
//       {progress && <div className="fullScreen">
//         <CircularProgress
//           disableShrink
//           className="progressCircle"
//           value={90}
//           size={100}
//           thickness={1.5}
//         />
//       </div>}

//       <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO RESOUCES LIST</div><br />
//       <div className="d-flex justify-content-end">
//         <div className="mr-auto secondary-header">Add Resource</div>
//       </div>

//       <div className="">
//         <form noValidate autoComplete="off" id="addResource" className="row" onSubmit={formSubmit}>
//           <div className="col-md-12">

//             <div className="panel-primary-wrap">
//               {/* <div className="card">
//                 <div className="card-body"> */}
//               <div className="card">
//                 <div className="card-body">
//                   <div className="card-page-header">RESOURCE INFORMATION</div>
//                   <div className="inputBox">
//                     <div className="row">
//                       <div className="col-md-4">
//                         <InputField {...textProps} name="name" label="Name" must={'true'} type="text" />
//                       </div>
//                       <div className="col-md-4">
//                         <AutoOptions {...autoPropstocategory} name="category" label="Category" keyName='value' must={'true'} />
//                       </div>
//                       <div className="col-md-4">
//                         <InputField {...textProps} multiline={true} row={1} rowsMax={4} name="summary" label="Summary" must={'false'} type="text" />
//                       </div>
//                       <div className="col-md-4">
//                         <AutoOptions {...autoPropstoLanguages} name="language" label="Select Language" keyName='value' must={'true'} />
//                       </div>
//                       <div className="col-md-4">
//                         <AutoOptions {...autoPropsTotype} name="type" label="Type" keyName='value' must={'true'} />
//                       </div>
//                       <div className="col-md-4">
//                         <InputField {...textProps} name="version" label="version" must={'true'} type="text" />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-12">

//             <div className="panel-primary-wrap">
//               <div className="card">
//                 <div className="card-body">
//                   <div className="card-page-header">RESOURCE FILES</div>
//                   <div className="inputBox">
//                     <Tabs
//                       value={value}
//                       onChange={handleTabsChange}
//                       indicatorColor="primary"
//                       textColor="primary"

//                     >
//                       <Tab label="Links" />
//                       <Tab label="Documents" />
//                       <Tab label="Videos" />
//                       <div style={{ display: "inline-flex" }}>
//                         <div className="errMsg">{urlErr}</div>
//                         <div className="errMsg">{docErr}</div>
//                         <div className="errMsg">{videoErr}</div>
//                       </div>
//                     </Tabs>
//                     {value === 0 && <TabContainer>

//                       <>
//                         <div className="row">
//                           {
//                             links.length > 0 &&
//                             <div className="col-md-10">

//                               <div className="table-responsive-md">
//                                 <table className="table table_courses">
//                                   <thead className="thead_color">
//                                     <tr>
//                                       <td>
//                                         S.NO
//                                       </td>
//                                       <td>Link/URL</td>
//                                       <td>Description</td>
//                                       <td>Actions</td>
//                                     </tr>
//                                   </thead>
//                                   <tbody>

//                                     {
//                                       links.map((item, i) => {
//                                         console.log(links)
//                                         return (
//                                           <tr>
//                                             <td>{i + 1}</td>
//                                             <td>
//                                               <TextField
//                                                 value={links[i].url}
//                                                 // label="Add URL"
//                                                 type="text"
//                                                 name="url"
//                                                 onChange={(e) => handleChange(e, i)}
//                                                 style={{ width: "100%" }}
//                                               />
//                                             </td>
//                                             <td>
//                                               <TextField
//                                                 multiline={true}
//                                                 rows={1}
//                                                 rowsMax={4}
//                                                 value={links[i].description}
//                                                 // label="Description"
//                                                 type="text"
//                                                 name="description"
//                                                 onChange={(e) => handleChange(e, i)}
//                                                 style={{ width: "100%" }}
//                                               />

//                                             </td>
//                                             <td className="td_action">
//                                               {/* <div className="action_div"  >
//                                           <Tooltip title="Submit"><Icon color="primary" onSubmit={(e) => onSubmit(e, i)}
//                                           >check_circle</Icon></Tooltip>
//                                         </div> */}
//                                               {/* {i >= 0 && */}
//                                               <div className="action_div"  >
//                                                 <Tooltip title="Remove"><Icon color="error" onClick={(e) => removeLink(e, i)} > remove_circle</Icon></Tooltip>
//                                               </div>
//                                               {/* } */}
//                                               {/* {links.length == i + 1 && ( */}

//                                               {/* )} */}

//                                             </td>
//                                           </tr>
//                                         )
//                                       })
//                                     }

//                                   </tbody>
//                                 </table>
//                               </div>

//                             </div>
//                           }
//                           <div className="col-md-2">
//                             <div className="" >
//                               {/* <Tooltip title="Add New Url"><Icon color="primary" onClick={addNew}>add_circle</Icon></Tooltip> */}
//                               <button onClick={addNew} type="button" className="btn btn-primary-blue btn-rounded">
//                                 <i className="fa fa-plus-circle mr-1"></i> Add New Url</button>
//                             </div>
//                           </div>
//                         </div>
//                       </>


//                     </TabContainer>}
//                     {value === 1 && <TabContainer><FileUpload dataToAccept='image/*,.pdf' fileSave={fileSave} onChangeFile={onChangeFile} allFiles={docFiles} removeFile={removeFile} /> <br /> </TabContainer>}
//                     {value === 2 && <TabContainer>
//                       <FileUpload dataToAccept='video/*' fileSave={fileSave} onChangeFile={onChangeFile} allFiles={videoFiles} removeFile={removeFile} /><br />
//                     </TabContainer>}

//                   </div>

//                 </div>
//               </div>
//             </div>
//             <div className="buttonContainer">
//               <button type="submit" className="btn btn-primary-blue btn-rounded btn-small">Save</button>
//               <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
//             </div>
//           </div>
//         </form >
//       </div >
//     </>
//   )
// }

// const mapStateToProps = (state) => {
//   return {
//     formValues: state.form,
//     state
//   }
// }
// const mapDispatchToProps = (dispatch) => {
//   return {
//     UpdateFormField: (obj) => {
//       return dispatch(
//         {
//           type: 'UpdateFormField',
//           value: obj.value,
//           name: obj.name
//         }
//       )
//     },
//     clearForm: () => {
//       return dispatch(
//         {
//           type: 'ClearForm',
//         }
//       )
//     },
//     updateErrorObj: (obj) => {
//       return dispatch(
//         {
//           type: 'updateErrorObj',
//           flag: obj.flag,
//           name: obj.name,
//           text: obj.text
//         }
//       )
//     },
//     updateEntireErrorObj: (errobj, errtextobj) => {
//       return dispatch(
//         {
//           type: 'updateEntireErrorObj',
//           errobj: errobj,
//           errtextobj: errtextobj,
//         }
//       )
//     },
//   }
// }
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddResources));



import React from 'react'
import AddResource from '../fileupload/addcoursecommon'

const AddResources=()=>{
  return(
    <>
    <AddResource type="Resource" plural="resources" form="addResource"/>
    </>
  )
}
export default AddResources