import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { apiCall, logOut, fileUpload } from '../../../../common/apiconfig';
import CircularProgress from '@material-ui/core/CircularProgress';

const FileUpdate = ({ text, dataToAccept, item, type, buArea,buId,callUpdateApi,loading }) => {
  const [files, setFiles] = useState([]);
  let [progress, setProgress] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: dataToAccept,
    noKeyboard: true, onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }, multiple: false

  });

  const callapi = async () => {
    console.log(item, "item")
    var fdata
    let requestBody = { "filename": files[0].name }
    requestBody = JSON.stringify(requestBody);
    // setProgress(true)
     loading(true)
    let res = await fileUpload('fileupload', requestBody);
    if (res.code == 200) {
      var data = JSON.parse(res.attributes)
      fdata = {
        id: data.Id,
        fileName: files[0].name,
        type: files[0].type
      }
      // docsInfo.push(fdata)
      let blob = new Blob([files[0]]);
      const response = await fetch(data.URL, {
        method: "PUT",
        headers: {
          'Content-Type': files[0].type,
        },
        body: blob,
      });
      const status = await response.status;
      if (status == 200) {
        // setProgress(false)
        let requestBody = `
        mutation {
          updateSupportDetails(id:"${item.ID}",detail1:"${fdata.id}",detail2:"${fdata.fileName}",detail3:"${fdata.type}",detail4:""){
            code
            type
            message
            attributes
          }
        }`
          ;

        requestBody = JSON.stringify({ query: requestBody });
        let res = await apiCall('updateSupportDetails', requestBody);
        // setProgress(false);

        if (res.data.updateSupportDetails.code === '200') {
          callGetData()
        } else {
          console.log('Error!')
        }
      }

    } else {
      alert('failed to upload')
    }
  }
  const callGetData = async () => {
    let requestBody = `
                query {
                  getSupportDetailsWithStatus(buArea: "${buArea}", buid: "${buId}", type: "${type}",status:"*") {
                    ID
                    buArea
                    buid
                    type
                    status
                    detail1
                    detail2
                    detail3
                    detail4
                    addedById
                    addedByName
                    addedTs
                    } 
                    }`;

    requestBody = JSON.stringify({ query: requestBody });
    // setProgress(true);

    let res = await apiCall('getSupportDetailsWithStatus', requestBody);
    // setProgress(false);
    if (!res) {
      console.log('Error!')
    } else {
      console.log(res,"resapi")
      // loading(false)
      callUpdateApi(type.toLowerCase(),res.data.getSupportDetailsWithStatus)
    }
  }
  if (files.length > 0) {
    callapi()
    setFiles([])
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
        {/* <Loader/> */}
      </div>}
      <span {...getRootProps()}>
        <input {...getInputProps()} />
        <span>{text}</span>
      </span>
    </>
  )
}

export default FileUpdate