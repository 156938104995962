import React, { useEffect, useState, Component } from "react";
import Loadable from 'react-loadable';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { funcDropdown } from '../../../common/constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import Studymaterialview from './studymaterialview'
import PatientDosage from './dosageTable'
import StudyDetails from './studydetails'
import SiteGrid from './siteGrid'
import Assessment from './assessment'
import Vendors from './vendors'
import IEcriteria from './iecriteria'
import AEevents from './aevents'
import Actionitems from './actionitems'
import Visits from './visits'
import Studypersonal from './studypersonal'
import Sitestudyusers from './sitestudyusers'
import Siteusersmultiselect from './siteusersmultiselect'
import Studymaterial from './studymaterial'
import Queries from './queries'
import AddAE from './addAE'
import ViewAE from './viewAE'
import UpdateAE from './updateAE'
import PIRHome from './pir/PIRHome';
import Arm from './arm'
import ViewARM from './viewArm'
import { NavMenu } from '../navMenu';

const menus = [
    {
        to: "/site/study/studydetails",
        text: "Details"
    },
    {
        to: "/site/study/pir",
        text: "PIR"
    },
    {
        to: "/site/study/subject",
        text: "Subjects"
    },
    {
        to: "/site/study/assessment",
        text: "Assessment"
    },
    {
        to: "/site/study/arm",
        text: "Arm"
    },
    {
        to: "/site/study/iecriteria",
        text: "I-E Criteria"
    },
    {
        to: "/site/study/aevents",
        text: "Adverse Events "
    },
    // {
    //     to: "/site/study/actionitems",
    //     text: "Action Items"
    // },
    // {
    //     to: "/site/study/visits",
    //     text: "Schedule"
    // },
    {
        to: "/site/study/studypersonal",
        text: "Study Personnel"
    },
    {
        to: "/site/study/sitestudyusers",
        text: "Users"
    },
    {
        to: "/site/study/studymaterial",
        text: "Resources"
    },
    {
        to: "/site/study/queries",
        text: "Queries"
    },
    // {
    //     to: "/site/study/community",
    //     text: "Community"
    // }
]

const StudyHome = (props) => {
    useEffect(() => {
        funcDropdown()
    }, [])


    const LoadCom = (props) => {
        //console.log(props.fileName, "studyprops")
        let Component = Loadable({
            loader: () => import(`./${props.fileName}`),
            loading() {
                return <div className="fullScreen">
                    <CircularProgress
                        disableShrink
                        className="progressCircle"
                        value={90}
                        size={100}
                        thickness={1.5}
                    />
                    <span>UI Script loading</span>
                </div>
            },
        });
        return <Component {...props} />
    }


    return (
        <>
            <div className="d-flex mb-3 mt-3 justify-content-end" style={{paddingTop:"15px"}}>
                <h1 className="mr-auto primary-header">Studies</h1>
            </div>

            <section className="">
                <NavMenu menus={menus} />
            </section>

            <div className="">
                <Switch>
                    <Route path="/site/study/studydetails" component={StudyDetails}>
                        {/* <LoadCom fileName="studydetails" /> */}
                    </Route>
                    <Route path="/site/study/subject" component={SiteGrid}>
                        {/* <LoadCom fileName="siteGrid" /> */}
                    </Route>
                    <Route path="/site/study/assessment" component={Assessment}>
                        {/* <LoadCom fileName="assessment" /> */}
                    </Route>
                    <Route exact path="/site/study/arm" component={Arm}>
                        {/* <LoadCom fileName="vendors" /> */}
                    </Route>
                    <Route path="/site/study/arm/viewARM" component={ViewARM}>
                        {/* <LoadCom fileName="aevents" /> */}
                    </Route>
                    <Route path="/site/study/iecriteria" component={IEcriteria}>
                        {/* <LoadCom fileName="iecriteria" /> */}
                    </Route>
                    <Route exact path="/site/study/aevents" component={AEevents}>
                        {/* <LoadCom fileName="aevents" /> */}
                    </Route>
                    <Route path="/site/study/aevents/addAE" component={AddAE}>
                        {/* <LoadCom fileName="aevents" /> */}
                    </Route>
                    <Route path="/site/study/aevents/viewAE" component={ViewAE}>
                        {/* <LoadCom fileName="aevents" /> */}
                    </Route>
                    <Route path="/site/study/aevents/updateAE" component={UpdateAE}>
                        {/* <LoadCom fileName="aevents" /> */}
                    </Route>
                    <Route path="/site/study/actionitems" component={Actionitems}>
                        {/* <LoadCom fileName="actionitems" /> */}
                    </Route>
                    <Route path="/site/study/visits" component={Visits}>
                        {/* <LoadCom fileName="visits" /> */}
                    </Route>
                    <Route path="/site/study/studypersonal" component={Studypersonal}>
                        {/* <LoadCom fileName="studypersonal" /> */}
                    </Route>
                    <Route path="/site/study/sitestudyusers" component={Sitestudyusers}>
                        {/* <LoadCom fileName="sitestudyusers" /> */}
                    </Route>
                    <Route path="/site/study/sitestudy/addusers" component={Siteusersmultiselect}>
                        {/* <LoadCom fileName="siteusersmultiselect" /> */}
                    </Route>
                    <Route exact path="/site/study/studymaterial" component={Studymaterial}>
                        {/* <LoadCom fileName="studymaterial" /> */}
                    </Route>

                    {/* <Route path="/site/study/patientdosage" component={}>
                        // <LoadCom fileName="dosageTable" />
                    </Route> */}
                    <Route path="/site/study/queries" component={Queries}>
                        {/* <LoadCom fileName="community" /> */}
                    </Route>
                    {/* <Route path="/site/study/patients" component={}>
                        // <LoadCom fileName="selectPatients" />
                    </Route> */}

                    <Route path="/site/study/pir">
                        <PIRHome />
                    </Route>
                    <Route path="/site/study/studymaterial/view" component={Studymaterialview} />
                    {/* <Route path="/site/study/patient/dosage" component={PatientDosage} /> */}
                    <Redirect to="/site/study/studydetails" />
                </Switch>
            </div>
        </>
    )
}

export default StudyHome;


