import React, { useEffect, useState, useContext } from "react";
import MaterialTable from 'material-table';
//import { apiCall } from '../../../common/apiconfig';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//import { Paginationn } from '../../../../common/pagination'
//import { appConfig } from "../../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import Loadable from 'react-loadable';
import Prescreening from './prescreening';
import Screening from './screening';
import Enroll from './enroll';
import { genericApi } from '../../../common/apiconfig';
import { NavSubMenu } from '../navMenu';

const menus = [
  {
    to: "/site/study/subject/prescreening",
    text: "Pre-Screening"
  },
  {
    to: "/site/study/subject/screening",
    text: "Screening"
  },
  {
    to: "/site/study/subject/enroll",
    text: "On-Study"
  },
]


function SiteGrid(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  const [searchData, setSearchData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');

  // useEffect(() => {
  //   getApiData();
  // }, []);
  useEffect(() => {
    getApiData()
  }, [props.formValues.autostudy.studyId])
  async function getApiData() {
    setProgress(true)
    let requestBody = {
      query: `query {
              getStudySitePatientFetch(studyId: "${props.formValues.autostudy.studyId}", siteId: "${props.formValues.autosite.sponsorAccountId}") {
                  studyId
                  siteId
                  patientId
                  subjectStatus
                  onboardStatus
                  subjectId
                  alternateId
                  firstName
                  middleName
                  lastName
                  email
                  phone
                  address1
                  address2
                  city
                  state
                  zip
                  country
                  language
                  createdBy
                  createdTs
                  modifiedBy
                  modifiedTs

          }
      }`
    };
    requestBody = JSON.stringify(requestBody);
    let res = await genericApi('getStudySitePatientFetch', requestBody);
    setProgress(false)
    if (!res) {
      // Error handling
      //console.log('Error!')

    } else {
      if (res.data.getStudySitePatientFetch.length) {
        props.UpdateFormField({ name: "studypatients", value: res.data.getStudySitePatientFetch })
        setData(res.data.getStudySitePatientFetch)
      } else {
        props.UpdateFormField({ name: "studypatients", value: [] })
        setData([])
      }
    }

  }
  // const LoadCom = (props) => {
  //   //console.log(props.fileName)
  //   let Component = Loadable({
  //     loader: () => import(`./${props.fileName}`),
  //     loading() {
  //       return <div className="fullScreen">
  //         <CircularProgress
  //           disableShrink
  //           className="progressCircle"
  //           value={90}
  //           size={100}
  //           thickness={1.5}
  //         />
  //         <span>UI Script loading</span>
  //       </div>
  //     },
  //   });
  //   return <Component {...props} />
  // }

  const callApi = () => {
    getApiData();
  }
  //search func

  async function searchfunc(status, searchstr) {
    let requestBody = {
      query: `query {
      getStudySitePatientStatusSearch(studyId: "${props.formValues.autostudy.studyId}", siteId: "${props.formValues.autosite.sponsorAccountId}",onboardStatus:"${status}",subjectStatus:"*",searchStr:"${searchstr}") {
                studyId
                siteId
                patientId
                subjectStatus
                onboardStatus
                subjectId
                alternateId
                firstName
                middleName
                lastName
                email
                phone
                address1
                address2
                city
                state
                zip
                country
                language
        }
    }`
    };
    requestBody = JSON.stringify(requestBody);
    let res = await genericApi('getStudySitePatientStatusSearch', requestBody);
    if (!res) {
      // Error handling
      //console.log('Error on getting patient data!')

    } else {
      if (res.data.getStudySitePatientStatusSearch.length) {
        setData(res.data.getStudySitePatientStatusSearch)
      } else {
        setData([])
      }
    }
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex">
        {/* <div class="secondary-header mr-auto">Subjects</div> */}
      </div>
      <section className="mb-3">
        <NavSubMenu menus={menus} />
      </section>

      <div className="">
        <Switch>
          <Route path="/site/study/subject/prescreening">
            {/* <LoadCom fileName="prescreening" /> */}
            <Prescreening data={data.filter(item => item.onboardStatus === "Pre-Screening")} callApi={callApi} searchfunc={searchfunc} />
          </Route>
          <Route path="/site/study/subject/screening">
            {/* <LoadCom fileName="screening" /> */}
            <Screening data={data.filter(item => item.onboardStatus === "Screening")} callApi={callApi} searchfunc={searchfunc} />
          </Route>
          <Route path="/site/study/subject/enroll">
            {/* <LoadCom fileName="enroll" /> */}
            <Enroll data={data.filter(item => item.onboardStatus === "On-Study")} callApi={callApi} searchfunc={searchfunc} />
          </Route>
          <Redirect to="/site/study/subject/prescreening" />
        </Switch>
      </div>
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteGrid);

