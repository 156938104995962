import React, { useEffect, useState, useContext } from "react";
import { SidebarMenus } from '../../components/navMenu';


const QuickLinks = (props) => {
    return (
        <>
              <SidebarMenus menus={props.menus} clickHandler={props.clickHandler} setActive={props.setActive} />
        </>
    )
}

export default React.memo(QuickLinks);


