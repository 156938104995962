import React, { useEffect, useState, Component } from "react";
import Loadable from 'react-loadable';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import DashboardHome from './dashboardHome';
import Searchcriteria from './searchcriteria';
import PatientDetail from './patientDetail';
import MenuButton from './menuButton';
import NewSearchCriteria from './newsearchcriteria';

const PIRHome = (props) => {
    useEffect(() => {

    }, [])


    return (
        <>
            <div className="d-flex justify-content-center mt-4">
                <MenuButton activePage={''} />
            </div>
            <div className="mt2rem">
                <Switch>
                    <Route path="/site/study/pir/searchcriteria">
                        {/* <Searchcriteria /> */}
                        <NewSearchCriteria sponsor={false} sidebar={false}/>
                    </Route>
                    <Route path="/site/study/pir/searchresult">
                        <DashboardHome />
                    </Route>
                    <Route path="/site/study/pir/patientdetail">
                        <PatientDetail sidebar={false} />
                    </Route>
                    <Redirect to="/site/study/pir/searchcriteria" />
                </Switch>
            </div>
        </>
    )
}

export default PIRHome;


