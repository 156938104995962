import React, { useEffect, useState, Component } from "react";
import Loadable from 'react-loadable';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import DashboardHome from './dashboardHome';
import Searchcriteria from './searchcriteria';
import PatientDetail from './patientDetail';
import MenuButton from './menuButton';
import NewSearchCriteria from './newsearchcriteria';

const PIRSidebarHome = (props) => {

    useEffect(() => {
      props.UpdateFormField({name:"autostudy",value:{studyNumber:"",studyTitle:""}})
      props.UpdateFormField({ name: "hidestudytab", value: false })
      return () => {
        props.UpdateFormField({ name: "hidestudytab", value: true })
      }
    }, [])


    return (
        <>
            <div className="d-flex justify-content-center mt-4">
                <MenuButton activePage={''} />
            </div>
            <div className="mt2rem">
                <Switch>
                    <Route path="/site/pir/searchcriteria">
                        {/* <Searchcriteria /> */}
                        <NewSearchCriteria sponsor={true} sidebar={true} Inclusion={props.formValues.Inclusion} Exclusion={props.formValues.Exclusion} Pir={props.formValues.PIRdata}/>
                    </Route>
                    <Route path="/site/pir/searchresult">
                        <DashboardHome />
                    </Route>
                    <Route path="/site/pir/patientdetail">
                        <PatientDetail sidebar={true}/>
                    </Route>
                    <Redirect to="/site/pir/searchcriteria" />
                </Switch>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PIRSidebarHome);

