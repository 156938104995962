import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';

const LabData = (props) => {
  console.log(props, "ladbdata")
  return (
    <> {
      props.overallData[props.index].subData.length > 0 && props.overallData[props.index].subData.map((item, i) => {
        return (
          <>
            {item.addData.map((subitem, j) => (
              (
                <>
                  <div className="row">
                    <div className="col-md-3">
                      <Autocomplete
                        id="size-small-outlined-multi"
                        size="small"
                        value={props.overallData[props.index].subData[i].addData[j].parameter}
                        onChange={(event, newValue) => {
                          props.handlelabdatachange(event, newValue, "parameter", j, props.index);
                        }}
                        options={props.labdata.length > 0 ? props.labdata : []}
                        fullWidth
                        style={{ width: "100%" }}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Parameter" placeholder="Parameter" />
                        )}
                      />
                    </div>
                    <div className="col-md-2">
                      <Autocomplete
                        id="combo-box-demo"
                        value={props.overallData[props.index].subData[i].addData[j].gender}
                        onChange={(event, newValue) => {
                          props.handlelabdatachange(event, newValue, "gender", j, props.index);
                        }}
                        size='small'
                        options={props.genderOptions}
                        style={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} variant="outlined" label="Gender" placeholder="Gender" />} />
                    </div>
                    <div className="col-md-2">
                      <Autocomplete
                        id="combo-box-demo"
                        value={props.overallData[props.index].subData[i].addData[j].unit}
                        onChange={(event, newValue) => {
                          props.handlelabdatachange(event, newValue, "unit", j, props.index);
                        }}
                        size='small'
                        options={props.units}
                        style={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} variant="outlined" label="Units" placeholder="Units" />} />
                    </div>
                    <div className="col-md-2">
                      <TextField
                        // error={error}
                        id="outlined-error-helper-text"
                        label="Range From"
                        value={props.overallData[props.index].subData[i].addData[j].rangeFrom}
                        onChange={e => { props.handlelabdatachange(e, e.target.value, "rangeFrom", j, props.index); }}
                        fullWidth
                        size='small'
                        // helperText={error ? "Required" : ""}
                        variant="outlined"
                      />
                    </div>
                    <div className="col-md-2">
                    <TextField
                        // error={error}
                        id="outlined-error-helper-text"
                        label="Range To"
                        value={props.overallData[props.index].subData[i].addData[j].rangeTo}
                        onChange={e => { props.handlelabdatachange(e, e.target.value, "rangeTo", j, props.index); }}
                        fullWidth
                        size='small'
                        // helperText={error ? "Required" : ""}
                        variant="outlined"
                      />
                    </div>
                    {j === (props.overallData[props.index].subData[0]["addData"].length - 1) ? <div className="col-md-1">{props.overallData[props.index].subData[0]["addData"].length>1 && <span style={{ color: "red", fontSize: "20px" }} onClick={(e) => props.removeLabDataRow(e, props.index,i,j)}> <i className="fa fa-minus-circle mr-1"></i></span>}<span style={{ color: "#244271", fontSize: "25px" }} onClick={(e) => props.addLabDataRow(e, props.index,i,j)}> <i className="fa fa-plus-circle mr-1"></i></span></div> : <div className="col-md-1" style={{ color: "red", fontSize: "20px" }} onClick={(e) => props.removeLabDataRow(e, props.index,i,j)}> <i className="fa fa-minus-circle mr-1"></i></div>}
                  </div>
                  <br/>
                </>
              )
            ))
            }
            <div className="row">
              <div className="col-md-4">
                <TextField
                  // error={error}
                  id="outlined-error-helper-text"
                  label="Time"
                  value={props.overallData[props.index].subData[i].time}
                  onChange={e => { props.handleChange(e, e.target.value, "time", i, props.index); }}
                  fullWidth
                  size='small'
                  // helperText={error ? "Required" : ""}
                  variant="outlined"
                />
              </div>
              <div className="col-md-4">
                <Autocomplete
                  id="combo-box-demo"
                  value={props.overallData[props.index].subData[i].condition}
                  onChange={(event, newValue) => {
                    props.handleChange(event, newValue, "condition", i, props.index);
                  }}
                  size='small'
                  options={props.relationOptions}
                  style={{ width: "100%" }}
                  renderInput={(params) => <TextField {...params} label="Select Relation" variant="outlined" />} />
              </div>
            </div>

          </>
        )
      })
    }
    </>
  )
}
export default LabData