import React, { useEffect, useState, useRef, useContext } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChipInput from 'material-ui-chip-input'
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHoc from '../../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew } from '../../../globalcomponents/formElements';
import { oldgenericApi } from '../../../common/apiconfig';
import { connect } from 'react-redux';
import JoditEditor from "jodit-react";
import { editorConfig } from '../../../common/constants'

const InvitePatients = (propsobj) => {
  let [progress, setProgress] = useState(false);
  let [todata, setTodata] = useState([]);
  let [CCdata, setCCdata] = useState([]);
  let [BCCdata, setBCCdata] = useState([]);
  let [hideccbtn, setHideccbtn] = useState(true)
  let [showcc, setShowcc] = useState(false)
  let [hidebccbtn, setHidebccbtn] = useState(true)
  let [showbcc, setShowbcc] = useState(false)
  let [compose, setCompose] = useState("")
  const editor = useRef(null)
  const [content, setContent] = useState('')
  const [templateData, setTemplateData] = useState([]);
  const [templateId, settemplateId] = useState({});

  useEffect(() => {
    propsobj.UpdateFormField({ name: "fromtext", value: localStorage.getItem("loggedUserEmail") })
    propsobj.UpdateFormField({ name: "ipSubject", value: "Invitation for a Study" })
    callGetTemplatesApi()
    // if (propsobj.formValues.inviteviastudy) {
    //   //console.log(propsobj.formValues, "invite")
    //   setContent("welcome to " + propsobj.formValues.autostudy.studyTitle)
    // }
  }, [])

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }
  const callGetTemplatesApi = async () => {

    let requestBody = `  query {
        searchEmailTemplate(templateId: "*", buArea:"*", buid:"${propsobj.formValues.autosite.sponsorAccountId}", subArea:"*", status:"Active", name:"*") {
            templateId
            buArea
            buId
            subArea
            status
            name
            subject_text
            body_text
            createdBy
            createdTs
            modifiedBy
            modifiedTs    
        }
      }
        `;

    requestBody = JSON.stringify({ query: requestBody });
    setProgress(true);

    let res = await oldgenericApi('searchEmailTemplate', requestBody);
    setProgress(false);
    if (!res) {
      //console.log('Error!')
    } else {
      //console.log(res, "Res")
      if (res.data.searchEmailTemplate) {
        var newdata = res.data.searchEmailTemplate
        var data = []
        for (var i = 0; i < newdata.length; i++) {
          data.push({ label: newdata[i].name, value: newdata[i].name, body: newdata[i].body_text })
        }
        //console.log(data, "temdata")
        setTemplateData(data)
      }
    }
  }
  const autoPropstotemplates = {
    'options': templateData,
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }
  const handleToChip = (chip) => {
    var data = [...todata]
    data.push(chip)
    setTodata(data)
  }
  const handleCCChip = (chip) => {
    var data = [...CCdata]
    data.push(chip)
    setCCdata(data)
  }
  const handleBCCChip = (chip) => {
    var data = [...BCCdata]
    data.push(chip)
    setBCCdata(data)
  }
  const handleDeleteToChip = (chip, index) => {
    var chipps = [...todata]
    chipps.splice(index, 1)
    //console.log(chipps)
    setTodata(chipps)
  }
  const handleDeleteCCChip = (chip, index) => {
    var chipps = [...CCdata]
    chipps.splice(index, 1)
    setCCdata(chipps)
  }
  const handleDeleteBCCChip = (chip, index) => {
    var chipps = [...BCCdata]
    chipps.splice(index, 1)
    setBCCdata(chipps)
  }
  const handlecc = (event) => {
    event.preventDefault()
    setHideccbtn(false)
    setShowcc(true)
  }
  const handlebcc = (event) => {
    event.preventDefault()
    setHidebccbtn(false)
    setShowbcc(true)
  }
  const sendInvite = async (event) => {
    event.preventDefault()
    //console.log(propsobj.formValues.fromtext, todata, CCdata, BCCdata)
    let stringifyTodata = todata.map((item, inx) => {
      let aa = JSON.stringify(item);
      return aa;
    })
    let stringifyCCdata = CCdata.map((item, inx) => {
      let aa = JSON.stringify(item);
      return aa;
    })
    let stringifyBCCdata = BCCdata.map((item, inx) => {
      let aa = JSON.stringify(item);
      return aa;
    })
    //console.log(propsobj.formValues.fromtext, stringifyTodata, editor.current.value, "editor.current.value")
    if (propsobj.formValues.fromtext != "" && stringifyTodata.length > 0) {
      var apiname
      var requestBody
      if (!propsobj.formValues.inviteviastudy) {
        apiname = "invitePatientWithEmail"
        requestBody = {
          query: `query {
        invitePatientWithEmail(fromEmail:"${propsobj.formValues.fromtext}",toEmail:[${stringifyTodata}],ccEmail:[${stringifyCCdata}],bccEmail:[${stringifyBCCdata}],emailSubject:"${propsobj.formValues.ipSubject}",emailBody:${JSON.stringify(editor.current.value)},bulkMail:"N",siteId:"${propsobj.formValues.autosite.sponsorAccountId}") {
          code
          type
          message
          attributes
        }
      }`
        };
      } else {
        apiname = "invitePatientToStudyWithEmail"
        requestBody = {
          query: `query {
            invitePatientToStudyWithEmail(fromEmail:"${propsobj.formValues.fromtext}",toEmail:[${stringifyTodata}],ccEmail:[${stringifyCCdata}],bccEmail:[${stringifyBCCdata}],emailSubject:"${propsobj.formValues.ipSubject}",emailBody:${JSON.stringify(editor.current.value)},bulkMail:"N",siteId:"${propsobj.formValues.autosite.sponsorAccountId}",studyId:"${propsobj.formValues.autostudy.studyId}") {
          code
          type
          message
          attributes
        }
      }`
        };
      }

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await oldgenericApi(apiname, requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        //console.log('Error!')

      } else {

        // props.history.goBack()
        if (res.data[apiname].code == "200") {
          //console.log('mail sent')
          setTodata([]);
          setCCdata([]);
          setBCCdata([]);
          setHideccbtn(true)
          setShowcc(false)
          setContent("")
          editor.current.value = "";
          settemplateId({})
          propsobj.UpdateFormField({ name: "useTemplate", value: "" })
        }
        if (res.data == null) {
          //console.log('mail not sent')
        }
      }
    } else {
      alert('Please Enter From and To')
    }
  }
  const handleCancel = (event) => {
    event.preventDefault();
    propsobj.history.goBack();
  }
  const handleTemplates = (obj) => {

    settemplateId(obj.selected);
    var obj1 = {
      name: obj.name,
      value: obj.selected
    }
    propsobj.UpdateFormField(obj1)

    setContent(obj.selected.body)
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}



      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">INVITE PATIENTS</h1>
      </div>
      {/* <div className="listing-wrap hekmaGrid"> */}
      <div className="row">
        <div className=" col-md-7 " style={{ borderRadius: "0.5rem" }}>
          <form>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 emailcompose">
                    <InputField {...textProps} name="fromtext" must={'true'} type="text" InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ color: "#C0C0C0" }} className="font14">From</div>
                        </InputAdornment>
                      )
                    }} />
                    <ChipInput
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div style={{ fontSize: "14px", color: "#C0C0C0" }} >To</div>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {hideccbtn &&
                              <div style={{ fontSize: "14px", cursor: "pointer", display: "inline-block", marginRight: "5px", color: "#999" }} onClick={event=>handlecc(event)}>Cc</div>
                            } {hidebccbtn &&
                              <div style={{ fontSize: "14px", cursor: "pointer", display: "inline-block", color: "#999" }} onClick={event=>handlebcc(event)}> Bcc</div>
                            }
                          </InputAdornment>
                        )
                      }}
                      value={todata}
                      // label="To"
                      //placeholder="To"
                      style={{ width: "100%", fontSize: "14px", marginTop: "10px", }}
                      multiline={true}
                      className="font14 tocss"
                      onAdd={(chip) => handleToChip(chip)}
                      onDelete={(chip, index) => handleDeleteToChip(chip, index)}

                    />
                    {
                      showcc &&

                      <ChipInput
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">

                              <div style={{ fontSize: "14px", cursor: "pointer", color: "#C0C0C0" }}>CC</div>

                            </InputAdornment>
                          ),
                        }}
                        value={CCdata}
                        //placeholder="CC"
                        // label="CC"
                        style={{ width: "100%", fontSize: "14px", marginTop: "10px" }}
                        multiline={true}
                        className="font14"
                        onAdd={(chip) => handleCCChip(chip)}
                        onDelete={(chip, index) => handleDeleteCCChip(chip, index)}
                      />
                    }
                    {showbcc &&
                      <ChipInput
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">

                              <div style={{ fontSize: "14px", cursor: "pointer", color: "#C0C0C0" }}>BCC</div>

                            </InputAdornment>
                          ),
                        }}
                        value={BCCdata}
                        // placeholder="BCC"
                        // label="BCC"
                        className="font14"
                        style={{ width: "100%", fontSize: "14px", marginTop: "10px" }}
                        multiline={true}
                        onAdd={(chip) => handleBCCChip(chip)}
                        onDelete={(chip, index) => handleDeleteBCCChip(chip, index)}
                      />
                    }

                    <InputField {...textProps} name="ipSubject" must={'false'} type="text" InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">

                          <div style={{ color: "#C0C0C0" }} className="font14">Subject</div>

                        </InputAdornment>
                      )
                    }} style={{ marginTop: "10px" }} />
                    {/* <InputField {...textProps} className="font14" name="useTemplate" must={'false'} type="text" placeholder="Use Template" style={{ marginTop: "10px" }} /> */}
                    {/* <AutoOptions
                      {...autoPropstotemplates}
                      name="useTemplate"
                      label="Use Template"
                      keyName='label'
                      must={'false'}
                      callback={handleTemplates}
                    /> */}

                    <AutoOptionsNew
                      {...autoPropstotemplates}
                      autoValue={templateId}
                      name="useTemplate"
                      label="Use Template"
                      keyName='label'
                      must={'false'}
                      shrink={true}
                      {...propsobj.state}
                      callback={handleTemplates}
                    />

                    <br />
                    {/* <div className="h-tabs-1">
                <ul className="nav nav-tabs " id="login-register" role="tablist">
                  <li className="nav-item" >
                    <a className="nav-link active" id="login-tab" data-toggle="tab" href="#login" role="tab" aria-controls="login" aria-selected="true">Compose</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="signup-tab" data-toggle="tab" href="#signup" role="tab" aria-controls="signup" aria-selected="false" style={{ fontWeight: "100" }}>Use Template</a>
                  </li>
                </ul>
                <div className="tab-content pt-4" id="login-register-tab">
                  <div className="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab"> */}
                    <div>
                      {/* <textarea class="form-control" style={{ height: "200px" }} placeholder="text message goes here" name="compose" value={compose} onChange={(e) => { setCompose(e.target.value) }} /> */}
                      <JoditEditor
                        ref={editor}
                        value={content}
                        config={editorConfig}
                      // tabIndex={1} // tabIndex of textarea
                      // onChange={(e) => { newhandle(e) }}
                      // onBlur={newContent => setContent(newContent)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /> <div className="buttonContainer mr-auto" style={{
              display: "flex",
              justifyContent: "flex-end"
            }}>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1" onClick={sendInvite}>SEND INVITE</button>
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
            </div>
          </form>
        </div>
      </div >
      {/* </div> */} < br />

    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvitePatients));