import React, { useEffect, useState } from 'react'
import { InputField, AutoOptions, AutoOptionsMultiple } from '../../../globalcomponents/formElements';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FormHoc from '../../../globalcomponents/hoc/formHoc';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HospitalList from '../hospital/hospitalList';
import { appConfig } from "../../../common/constants";
import Loader from '../../../common/loader'
import { Radio, FormLabel, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core/';
import { BasicFileUpload } from '../../basicfileupload';
import Instance from '../../../img/instance.png'

const DataUpload = (propsobj) => {
  console.log(propsobj, "props")
  let [progress, setProgress] = useState(false)
  let [keyField, showKeyField] = useState(false)
  let [anon, setAnon] = useState("yes")
  const [format, setFormat] = useState('')
  const [files, setFiles] = useState("")

  useEffect(() => {
    propsobj.UpdateFormField({name:"hospitalData",value:""})
  }, [propsobj.formValues?.hospitalData])

  let propsValues = { ...propsobj };
  const hocObj = FormHoc(propsValues);

  const radioHandle = (e) => {
    e.preventDefault();
    setFormat(e.target.value)
  }
  const radioHandleAnon = (e) => {
    e.preventDefault();
    setAnon(e.target.value)
  }

  const onChangeFile = (files) => {
    console.log(files, "in")
    if (files.length > 0) {
      setFiles(files)
    }
  }


  const submit = async (e) => {
    e.preventDefault()
    //  await workflowApi(existingdata.hospitalData.sponsorAccountId)
    console.log(files, "upload submit")
    let existingdata = propsobj.formValues
    const formData = new FormData();
    files.map((file) => formData.append("files", new File([file], file.name)))
    formData.append("hospital_id", existingdata.hospitalData.sponsorAccountId);
    formData.append("bucket_name", existingdata.PT === "Prediction" ? "trial-match-prediction-fhir" : "trial-match-training-fhir");
    const options = {
      method: "POST",
      body: formData
    };
    setProgress(true)
    fetch(appConfig.AIApi + 'upload_json', options)
      .then(response => response.json())
      .then((data) => {
        setProgress(false)
        console.log(data, "data")
        callApis()
      })
  }

  const callApis = () => {
    let existingdata = propsobj.formValues
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ "hospital-id": propsobj.formValues?.hospitalData.sponsorAccountId, "operation": "training", "bucket-name": existingdata.PT === "Prediction" ? "trial-match-prediction-fhir" : "trial-match-training-fhir" })
    };
    // setProgress(true)
    fetch(appConfig.AIApi + 'data_anonymization', requestOptions)
      .then(response => response.json())
      .then((data) => {
        // setProgress(false)
        console.log(data, "data")
      })

    const requestOptions2 = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ "hospital-id": propsobj.formValues?.hospitalData.sponsorAccountId, "operation": "training" })
    };
    // setProgress(true)
    fetch(appConfig.AIApi + 'pii_to_csv', requestOptions2)
      .then(response => response.json())
      .then((data) => {
        // setProgress(false)
        console.log(data, "data")
      })
  }
  return (
    <>
      <Loader progress={progress} />

      <div className='row'>

        <div className='col-md-6'>
          <div className="d-flex mb-3 justify-content-end">
            <h1 className="mr-auto primary-header">Data Upload</h1>
          </div>
          <div className="panel-primary-wrap">
            <div className="card">
              <div className="card-body">
                <div className="card-page-header">{propsobj.formValues?.hospitalId + ':' + propsobj.formValues?.hospitalName}</div>
                <div className="inputBox">

                  <div><FormControl component="fieldset">
                    <FormLabel component="legend">Format</FormLabel>
                    <RadioGroup row aria-label="format" name="format" defaultValue="top">
                      <FormControlLabel control={<Radio color="primary" name="ccda" value='ccda' checked={format === 'ccda'} onChange={(e) => { radioHandle(e) }} />} label="C-CDA" />
                      <FormControlLabel control={<Radio color="primary" name="fhir" value="fhir" checked={format === "fhir"} onChange={(e) => { radioHandle(e) }} />} label="FHIR" />
                    </RadioGroup>
                  </FormControl></div>
                  <div><FormControl component="fieldset">
                    <FormLabel component="legend">Anonimazation</FormLabel>
                    <RadioGroup row aria-label="anon" name="anon" defaultValue="top">
                      <FormControlLabel control={<Radio color="primary" name="yes" value='yes' checked={anon === 'yes'} onChange={(e) => { radioHandleAnon(e) }} />} label="Yes" />
                      <FormControlLabel control={<Radio color="primary" name="no" value="no" checked={anon === "no"} onChange={(e) => { radioHandleAnon(e) }} />} label="No" />
                    </RadioGroup>
                  </FormControl></div>
                  <label style={{ color: "#0000008a" }}>Upload File</label>
                  <BasicFileUpload dataToAccept='.json' onChangeFile={onChangeFile} />
                </div>
                {/* <div>Data Anonimazation : <span>Yet to Start  {loadingAnon ? <CircularProgress color="inherit" size={20} /> : null}</span></div>
                <div>Pre Processing : <span>Yet to Start</span></div> */}
                {/* <input  directory="" webkitdirectory="" type="file" /> */}
              </div>
            </div>
          </div>
          <div className="buttonContainer mb-3 fr">
            <button className="btn btn-primary-blue btn-rounded btn-small mr-1" onClick={(e) => submit(e)}>Save</button>
            <button className="btn btn-primary-grey btn-rounded btn-small" >CANCEL</button>
          </div>
        </div>
        <div className='col-md-6' style={{ textAlign: "center" }}>
          <img src={Instance} height="80%" />
        </div>
      </div>

    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataUpload));
