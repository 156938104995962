import React, { useEffect, useState } from "react";
import CarePlan from '../../../../globalcomponents/patient360/medicalhistory/careplan'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
const PatientCarePlan = (props) => {
  return (
    <>
      <CarePlan data={props.formValues.patientdata} app="Site" />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}

export default withRouter(connect(mapStateToProps, null)(PatientCarePlan));

