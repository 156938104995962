import { gql } from 'apollo-boost';

export const createSponsorAccountUser=gql`
mutation createSponsorAccountUser($sponsorAccountId: String!, $firstName: String, $middleName: String, $lastName: String, $email: String, $role: String, $phone: String, $language: String, $portalAccessInd: String, $invitedBy: String ) {
  createSponsorAccountUser(
      sponsorAccountId: $sponsorAccountId, 
      firstName: $firstName, 
      middleName: $middleName, 
      lastName : $lastName,
      email :$email,
      role:$role,
      phone:$phone,
      language:$language,
      portalAccessInd:$portalAccessInd,
      invitedBy:$invitedBy ) {
        code
        type
        message
        attributes
      } 
}`