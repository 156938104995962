import React, { useState, useEffect } from "react";
import MaterialTable from 'material-table';
import { withRouter } from 'react-router-dom';
import { Paginationn } from '../common/pagination'
import { appConfig } from "../common/constants";

function CommonGrid(props) {
  const newdata = props.columns.map(v => (
    v["render"] == undefined ?
      {
        ...v, "render": data => data[v.field] == ("" || null || undefined) || data[v.field].length == 0 ? '---' :
          data[v.field]
      } : v))
  return (
    <div className={props.showPadding ? "" :"mb-3"}>

      <MaterialTable
        style={{ boxShadow: "none" }}
        columns={newdata}
        data={props.data}
        // actions={[
        //   rowData => ({
        //     icon: 'delete',
        //     tooltip: 'Delete User',
        //     onClick: (event, rowData) => Window.confirm("You want to delete " + rowData.name),
        //   })
        // ]}
        onRowClick={(event, rowData) => {
          event.preventDefault();
          props.handleRowClick(event, rowData);
        }}
        onSelectionChange={(rowData) => { props.rowsSelected(rowData) }}
        options={{
          selection: props.selection ? true : false,
          showTextRowsSelected: false,
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          showTitle: false,
          search: props.search ? true : false,
          // grouping:true,
          paging: props.data.length > 5 ? true : false,
          sorting: props.data.length > 2 ? true : false,
          paginationType: "stepped",
          pageSizeOptions: [5, props.data.length >= 10 && 10, props.data.length >= 20 ? 20 : props.data.length > 11 && props.data.length < 20 ? props.data.length : ""],
          // exportButton:true,
          // exportAllData:true,
          headerStyle: {
            backgroundColor: props.thColor || "#f0f5ff",
            textAlign: 'left',
            flexDirection: 'row'
          },
          cellStyle: {
            textAlign: 'left',
            flexDirection: 'row'
          },
          padding: 'dense',
          draggable: true

        }}
        localization={{
          header: {
            actions: 'Actions'
          }
        }}
      // components={{
      //   Action: props => (
      //     <div
      //       className="fullwidth text-center text-danger"
      //     >
      //       <i className="far fa-trash-alt fa-lg"></i>
      //     </div>
      //   ),
      // }}
      />
    </div>
  )

}


export default withRouter(CommonGrid);


