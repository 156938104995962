import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { apiCall } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Materialtable from '../../../../globalcomponents/materialtable'
import CircularProgress from '@material-ui/core/CircularProgress';
import * as moment from 'moment'
import SmartText from '../../../common/smartText'

function AuditTrail(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  const [width, setWidth] = useState('')
  let [progress, setProgress] = useState(false);


  useEffect(() => {
    var outerContainer = document.querySelector(`#tablediv table thead tr th:last-child`);
    console.log(outerContainer.offsetWidth, "width")
    var width = outerContainer.offsetWidth
    if (width >= 301) {
      setWidth(70)
    } else if (width <= 300) {
      setWidth(50)
    } else {
      setWidth(40)
    }
    async function fetchData() {
      const getAudit = `
      buArea
      buId
      type
      user
      date
      summary
      timestamp`;

      let requestBody = {
        query: `query {
          getAudit(buArea:"Account",buId:"${props.formValues.sponsorAccountId}"){
                ${getAudit}
                }
            }`
      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getAudit', requestBody);
      console.log(res, "study")
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getAudit.length) {
          setData(res.data.getAudit)
        }
      }
    }
    fetchData();
  }, []);

  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    // props.history.push('/account/AddStudy');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    console.log(rowData)
    await props.UpdateEntireForm(rowData);

    // props.history.push('/account/studyDetails');
  }

  // const Columns = [
  //   {
  //     title: 'Date', field: 'date',
  //     // headerStyle: {minWidth: '10%'}, cellStyle: {minWidth: '10%'},
  //     render: rowData => moment(rowData.date).format('LLL')

  //   },
  //   {
  //     title: 'User', field: 'user',
  //     cellStyle: {
  //       width: 15
  //     },
  //   },
  //   {
  //     title: 'Type', field: 'type',
  //     cellStyle: {
  //       width: 15
  //     },
  //   },
  //   {
  //     title: 'Summary', field: 'summary', 
  //     render: rowData =><SmartText text={rowData.summary}/>
  //   },
  // ]
  //   var utcDate = '2011-06-29T16:52:48.000Z';  // ISO-8601 formatted date returned from server
  //   var localDate = new Date(utcDate);
  // console.log(utcDate,localDate,"convertedDate")
  const Columns = [
    {
      title: 'Date', field: 'date',
      cellStyle: {
        width: "20%"
      },
      render: rowData => moment(rowData.date).format('LLL')
    },
    // {
    //   title: 'Date', field: 'date',
    //   cellStyle: {
    //     width:"20%"
    //   },
    //   render: rowData => moment(new Date(rowData.date)).format('LLL')
    // },
    {
      title: 'User', field: 'user',
      cellStyle: {
        width: '15%'
      },
    },
    {
      title: 'Type', field: 'type',
      cellStyle: {
        width: '15%'
      },

    },
    {
      title: 'Summary', field: 'summary', cellStyle: {
        width: "50%"
      },
      render: rowData => <SmartText text={rowData.summary} length={width} />
    },

  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex justify-content-end">
        <div className="mr-auto secondary-header">Audit Trail</div>

      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div id="tablediv">

            <Materialtable
              columns={Columns}
              data={data}
              // handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuditTrail));
