import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import PdfPreview from './pdf-preview'

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const SimpleModal = ({ open, pdfUrl, imgUrl, onClose }) => {
  //console.log(pdfUrl,imgUrl,"url")
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    onClose()
  };
  const image = (url) => {
    return <img src={url} alt="image" />
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          {
            imgUrl!="" &&  <div className="imgmodal">{image(imgUrl)}</div>
          }
          {
            pdfUrl!="" &&
            <div className="pdfmodal">
              <PdfPreview url={pdfUrl} show={true} width="380"/>
            </div>
          }
        </div>
      </Modal>
    </div>
  );
}

export default SimpleModal