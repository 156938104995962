import React, { useEffect, useState, useCallback, useContext, useMemo } from "react";
import Materialtable from '../../../globalcomponents/materialtable';
import { apiCall } from '../../../common/apiconfig';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import Loadable from 'react-loadable';
import { genericApi } from '../../../common/apiconfig';
import Tooltip from '@material-ui/core/Tooltip';

function IECriteria(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');

  useEffect(() => {
    getApiData();
  }, []);
  async function getApiData() {
    let eventType = "I"
    let requestBody = {
      query: `query {
              getStudyEvent(studyId: "${props.formValues.autostudy.studyId}", eventType: "B") {
                studyId
                eventId
                eventType
                type
                description
                effectiveDate
                status
          }
      }`
    };
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getStudyEvent', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error!')

    } else {
      if (res.data.getStudyEvent) {
        setData(res.data.getStudyEvent, "studydata")
      }
    }
  }
  useEffect(() => {
    getApiData()
  }, [props.formValues.autostudy.studyId])
  String.prototype.limit = function (length) {
    return this.length > length ? (this.substring(0, length) + '...') : this;
  }
  const handlePageClick = (offset) => {
    setoffset(offset)
  }
const handleRowClick = (e)=>{
  e.preventDefault()
}
  const Columns = [
    {
      title: 'Type', field: 'type',
      // cellStyle: {
      //   color: '#244271',
      //   fontWeight: 500,
      //   textAlign: 'left'
      // },
    },
    {
      title: 'Description', field: 'description',
      cellStyle: {
        // color: '#244271',
        // fontWeight: 500,
        // display: "block",
        // whiteSpace: "nowrap",
        width: "50%",
        textAlign:'justify'
        // overflow: "hidden",
        // textOverflow: "ellipsis",
      },
      render: rowData => rowData.description == ("" || null || undefined) ? '---' : rowData.description
    },
    { title: 'Status', field: 'status' },
    { title: 'Effective Date', field: 'effectiveDate' },
  ]
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      {/* <div className="d-flex mb-3 justify-content-end">
      <div class="secondary-header mr-auto">IE Criteria</div>
      </div> */}
      <div className="listing-wrap hekmaGrid mt2rem">
        <div className="card">
          <div className="hekmaGridBox  studyper">
            <Materialtable
              columns={Columns}
              data={data}
            handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IECriteria);


