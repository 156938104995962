import React, { useEffect } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
const VariablePiechart = ({ options, highcharts }) => {
  useEffect(() => {
    Highcharts.chart('container5', {
  chart: {
      type: 'variablepie',
      style: {
        fontFamily: 'Roboto, sans-serif'
    }
  },
  title: {
      text: 'Hekma Match(Subjects)',
      style:{
       
      }
  },
  tooltip: {
      headerFormat: '',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
          '<b>{point.y} Subjects </b> {point.name}<br/>' +
          '<br/>'
  },
  credits: {
    enabled: false
  },
  series: [{
      minPointSize: 10,
      innerSize: '20%',
      zMin: 0,
      name: 'subjects',
      data: [{
          name: 'Hekma Matches',
          y: 25,
          z: 25
      }, {
          name: 'Newly Signedup',
          y: 5,
          z: 5
      },{
          name: 'Newly Diagnoised',
          y: 8,
          z: 8
      }]
  }]
}) 
}, [])
return (
  <div id="container5" ></div>
  // <Highcharts
  //   highcharts={Highcharts}
  //   // constructorType={'chart'}
  //   options={chartOptions}
  // />
)
}


export default VariablePiechart