import React from 'react'
import SavedTemplateCommon from '../../../../globalcomponents/admincomponents/savedTemplateCommon'

const SaveTemplate = () => {
  return (
    <>
      <SavedTemplateCommon />
    </>
  )
}
export default SaveTemplate