import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { checkPermission } from '../../../common/utils';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { HekmaTextSearchField } from '../formElements';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moveicon from '../../../img/move.png'
import clear from '../../../img/clear.svg'
import Tooltip from '@material-ui/core/Tooltip';
import Loader from '../../../common/loader'
import Snackbar from '../../../common/snackbar'
import { apiCall, genericApi } from '../../../common/apiconfig';
import { setISOWeekYear } from "date-fns";
import DeleteIcon from '../../../img/clear.svg'
import SimpleModal from '../../../globalcomponents/simplemodal'

const useStyles = makeStyles((theme) => ({
  radio: {
    '&$checked': {
      color: '#214B7D'
    }
  },
  checked: {},
  checkboxStyle: {
    margin: 0,
    padding: "5px",
    cursor: "pointer"
  },
  root: {
    flexGrow: 1,
    width: '100%',

    "&.Mui-selected": {
      backgroundColor: "#203F6B",
    }
  },
}));

const SiteSelectionPage = (props) => {
  const classes = useStyles();
  // const [value, setValue] = React.useState(0);
  const [sites, setSites] = React.useState([]);
  const [selectedSites, setSelectedSites] = React.useState([])
  const [valSearch, setValSearch] = React.useState('')
  const [progress, setProgress] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [variant, setVariant] = React.useState("")
  const [allChecked, setAllChecked] = React.useState(false)

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  useEffect(() => {
    callGetSitesApi("*")
  }, [])

  // useEffect(() => {
  //   if (value == 0) {
  //     setSites(sites.map(v => ({ ...v, isChecked: true })))
  //   } else {
  //     setSites(sites.map(v => ({ ...v, isChecked: false })))
  //   }
  // }, [value]);

  const callGetSitesApi = async (searchstr, text) => {
    let requestBody = {
      query: `query {
          getAccountTypeSearch(parentAccountId: "*",searchStr: "${searchstr}", type:"B",accountType: "Site"){
            sponsorAccountId
            accountName
            parentAccountId
            accountType
            status
            address1
            address2
            city
            state
            country
            zip
            email
            phone
              }
          }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await apiCall('getAccountTypeSearch', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error!')

    } else {
      if (res.data.getAccountTypeSearch.length) {
        var data = res.data.getAccountTypeSearch
        var newdata = data.map((item, i) => ({ id: item.sponsorAccountId, siteName: item.accountName, isChecked: false }))
        if (data.length > 0) {
          if (text === "search" || text === "clear") {
            if (selectedSites.length > 0) {
              let removeDupData = newdata.filter(o1 => !selectedSites.some(o2 => o1.id === o2.id));
              setSites([...removeDupData])
            } else {
              // if (allChecked) {
              //   var checkedData = newdata.map(v => ({ ...v, isChecked: true }))
              //   setSites(checkedData)
              // } else {
              setSites(newdata)
              // }
            }
          } else {
            setSites(newdata)
          }
        } else {
          setSites([])
        }
      }
    }
  }

  const selectCheckbox = (e, item) => {
    e.preventDefault()
    console.log(e.target.checked, item, "iem")
    if (e.target.checked) {
      setSites(sites.map(v => v.id === item.id ? { ...v, isChecked: true } : v))
    } else {
      setSites(sites.map(v => v.id === item.id ? { ...v, isChecked: false } : v))
    }
    setAllChecked(false)

  }

  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }

  const searchSubmit = (e) => {
    e.preventDefault();
    callGetSitesApi(valSearch, "search")
  }

  const clearSearch = () => {
    callGetSitesApi("*", "clear")
    setValSearch('')
    setAllChecked(false)
  }
  const movemultiitems = () => {
    // if (allChecked) {
    //   var allSites = sites.map(v => ({ ...v, isChecked: true }))
    //   setSelectedSites(allSites)
    //   setSites([])

    // var uncheckeddata = sites.filter(v => v.isChecked === false)
    // if (uncheckeddata.length > 0) {
    //   let removeDupData = selectedSites.filter(o1 => !uncheckeddata.some(o2 => o1.id === o2.id));
    //   setSelectedSites([...removeDupData, ...data])
    // } else {
    //   setSelectedSites(data)
    // }
    // } else {
    var selected = sites.filter(v => v.isChecked === true)
    var unselected = sites.filter(v => v.isChecked === false)
    setSelectedSites([...selected, ...selectedSites])
    setSites(unselected)
    // }
  }

  const handleSubmit = () => {
    // if (allChecked) {
    //   callSubmitAPi("Y", "")
    // } else {
    var siteIds = selectedSites.map(v => v.id)
    if (siteIds.length > 0) {
      callSubmitAPi("N", '"' + siteIds.join('","') + '"')
    }
    // }
  }

  const callSubmitAPi = async (flag, siteIds) => {
    let requestBody = `
    mutation {
        openSquareLeadRequest(studyId:"${localStorage.getItem("nonRegStudyId")}",
        allFlag:"${flag}",siteIdList:[${siteIds}])
       {
         code
          type
          message
          attributes
        }
      }`

    requestBody = JSON.stringify({ query: requestBody });
    let res = await genericApi('openSquareLeadRequest', requestBody);
    setProgress(false);

    if (res && res.data.openSquareLeadRequest.code === '200') {
      setOpen(true)
      setVariant("success")

    }

  }

  // const handleClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setOpen(false);
  // };

  const selectAllCheckbox = (e) => {
    if (e.target.checked) {
      setAllChecked(true)
      setSites(sites.map(v => ({ ...v, isChecked: true })))
      // setSelectedSites([])
    } else {
      setAllChecked(false)
      setSites(sites.map(v => ({ ...v, isChecked: false })))
    }
  }

  const removeSelectedSite = (e, item) => {
    e.preventDefault()
    console.log(item, "item")
    var data = selectedSites.filter(v => v.id != item.id)
    var sitesInfo = [...sites]
    sitesInfo.unshift({ id: item.id, siteName: item.siteName, isChecked: false })
    console.log(sitesInfo, "sites")
    setSelectedSites(data)
    setSites(sitesInfo)
    if (allChecked) {
      setAllChecked(false)
    }
  }

  const clearAll = (e) => {
    e.preventDefault()
    var uncheckSelected = selectedSites.map(v => ({ ...v, isChecked: false }))
    var allsites = sites.map(v => ({ ...v, isChecked: false }))
    setSites([...uncheckSelected, ...allsites])
    setSelectedSites([])
    setAllChecked(false)
  }

  const onClose = () => {
    setOpen(false)
    // setTimeout(function() {
    //   setOpen(false)
    //   }, 5000);
    props.history.push('/account/dashboard')
  }

  return (
    <>
      <Loader progress={progress} />
      {/* <Snackbar open={open} variant={variant} message="Request has been submitted t0 the selected sites and will be notified once the process is complete" handleClose={handleClose} /> */}
      <SimpleModal header="Request" open={open} onClose={onClose} width="30%">
        <div className="col-md-12">
          Request has been submitted to the selected sites and will be notified once the process is complete <br /><br />
        </div>
      </SimpleModal>
      <div className="">
        <div className="listing-wrap">
          <div >

            <React.Fragment>
              <div style={{ backgroundColor: "#F5F5F5" }} className="row mb-3">
                <div className="col-md-5">
                  <div className="mb-2">
                    <b>SITE SELECTION</b>
                  </div>
                  {/* <div className="mb-3">
                    <FormControlLabel
                      style={{ marginBottom: "0px", marginLeft: "0px" }}
                      control={
                        <Checkbox
                          name="All Sites"
                          checked={allChecked}
                          disableRipple
                          size="small"
                          classes={{ root: classes.radio, checked: classes.checked }}
                          className={classes.checkboxStyle}
                          onChange={(e) => { selectAllCheckbox(e) }}
                        />
                      }
                      label="All Sites"
                    />
                  </div> */}
                  {/* <div className="card" style={{ marginBottom: "3px" }}>
                    <div className="card-body" style={{ padding: "0.5rem" }}> */}
                  <div class="row mb-2">
                    <div className="col-md-2">
                      <FormControlLabel
                        style={{ marginBottom: "0px", marginLeft: "0px" }}
                        control={
                          <Checkbox
                            name="All Sites"
                            checked={allChecked}
                            disableRipple
                            size="small"
                            classes={{ root: classes.radio, checked: classes.checked }}
                            className={classes.checkboxStyle}
                            onChange={(e) => { selectAllCheckbox(e) }}
                          />
                        }
                        label="All"
                      />
                    </div>
                    <div className="col-md-10">
                      <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                        <HekmaTextSearchField
                          name="accountgridsearch"
                          placeholder="Search Sites"
                          value={valSearch}
                          handleChange={searchHandle}
                          className="gridSearchField"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                                <SearchIcon />
                              </InputAdornment>
                            ),

                            endAdornment: (
                              <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                                X
                              </InputAdornment>
                            ),
                          }}
                        />
                      </form>
                    </div>
                    {/* </div>
                    </div> */}
                  </div>
                  <div className="" style={{
                    height: "300px", borderStyle: 'groove',
                    borderColor: "#C3C3C3", overflowY: "scroll",
                    borderRadius: "10px"
                  }}>
                    {sites.length > 0 ?
                      <FormGroup >
                        {sites.map((item, i) => (
                          <div className="card" style={{ marginBottom: "3px" }}>
                            <div className="card-body" style={{ padding: "0.5rem" }}>
                              <FormControlLabel
                                style={{ marginBottom: "0px", marginLeft: "0px" }}
                                control={
                                  <Checkbox
                                    name={item.siteName}
                                    checked={item.isChecked}
                                    disableRipple
                                    size="small"
                                    classes={{ root: classes.radio, checked: classes.checked }}
                                    className={classes.checkboxStyle}
                                    onChange={(e) => { selectCheckbox(e, item) }}
                                  />
                                }
                                label={item.siteName}
                              />
                            </div>
                          </div>
                        ))}
                      </FormGroup> : <div className="card" style={{ marginBottom: "3px" }}>
                        <div className="card-body" style={{ padding: "0.5rem" }}> No Data Found</div></div>}
                  </div>
                  {/* <button type="submit" className="btn btn-primary-blue btn-rounded btn-small fr mt-3" onClick={handleSubmit}>Submit</button> */}
                </div>
                {/* {selectedSites.length > 0 && */}
                <div className="col-md-2 arrowMark"  >
                  <Tooltip title="Click to move to selected list">
                    <img src={moveicon} alt="clicktomove" style={{ cursor: "pointer" }} onClick={movemultiitems} />
                  </Tooltip>
                </div>
                {/* } */}

                <div className="col-md-5">
                  <div className="mb-2">
                    <b>SELECTED SITES<small>&nbsp;{selectedSites.length > 0 ? ` (Total Count:${selectedSites.length})` : ""}</small></b>
                    <span className="fr cr" style={{
                      fontSize: "12px", color: "#23416F", marginTop: "10px",
                      fontWeight: "700"
                    }} onClick={e => clearAll(e)}>Clear All</span>
                  </div>
                  <div className="" style={{
                    height: "350px", borderStyle: 'dashed',
                    borderColor: "#C3C3C3", overflowY: "auto",
                    borderRadius: "10px"
                  }}>
                    {selectedSites.length > 0 ? selectedSites.map((item, i) => (
                      <div className="card" style={{ marginBottom: "3px" }}>
                        <div className="card-body" style={{ padding: "0.5rem" }}>
                          {item.siteName}

                          <span className="fr cr" onClick={(e) => removeSelectedSite(e, item)}>   <Tooltip title="Remove" arrow><img src={DeleteIcon} alt="remove" /></Tooltip></span>
                        </div>
                      </div>
                    )) : <div className="card" style={{ marginBottom: "3px" }}>
                      <div className="card-body" style={{ padding: "0.5rem" }}>No Sites Selected</div></div>}
                  </div>
                </div>

                {selectedSites.length > 0 &&
                  <div className="col-md-12">
                    <div className="buttonContainer fr mt-3">
                      <button type="submit" className="btn btn-primary-blue btn-rounded btn-small" onClick={handleSubmit}>Done</button>
                    </div>
                  </div>
                }
              </div>
            </React.Fragment>
            {/* } */}
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteSelectionPage));


