import React, { useEffect, useState, useContext } from "react";
import Materialtable from '../../../globalcomponents/materialtable';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { checkPermission } from '../../../common/utilis';
import ReqAppIcon from '../../../img/Request-Appointment.png'
import SimpleModal from '../../../globalcomponents/simplemodal'
import AppointmentForm from './appointmentform'
import { oldgenericApi } from '../../../common/apiconfig'

function Patient(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');
  let [open, setOpen] = useState(false)
  let [patientsData, setPatientsData] = useState('')
  let [appointmentsFixed, setAppointments] = useState('')
  console.log(patientsData, appointmentsFixed, "appointmentsFixed")
  useEffect(() => {
    callPatientsInfo()
    callAppointmentsInfo()
    //to clear form
    props.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, []);

  const callPatientsInfo = async () => {
    var data = props.formValues.patientrequests
    var patientsDataa = []
    if (data.length > 0) {
      for (var i = 0; i < data.length; i++) {

        let requestBody = {
          query: `query
    {
    getPatient(patientId:"${data[i].fromKey}") {
    patientId
    subjectId
    alternateId
    firstName
    middleName
    lastName
    email
    phone
    address1
    address2
    city
    state
    zip
    countryCode
    country
    language
    createdBy
    createdTs
    modifiedBy
    modifiedTs
    }
  }`
        };

        requestBody = JSON.stringify(requestBody);
        setProgress(true);
        let res = await oldgenericApi('getPatient', requestBody);
        setProgress(false);
        if (!res) {
          // Error handling
          console.log('Error!')

        } else {
          console.log(res, "res")
          if (res.data.getPatient) {
            // setPatientsData(res.data.getPatient)
            var pdata = { ...res.data.getPatient, ...data[i] }
            await patientsDataa.push(pdata)
            console.log(pdata, "pdata")
          } else {
            setPatientsData([])
          }
        }
      }
      setPatientsData(patientsDataa)
      console.log(patientsDataa, "patientsDataa")

    }
  }
  const callAppointmentsInfo = async () => {
    setProgress(true)
    let requestBody = {
      query: `
      query
      {
        searchAppointment(fromKey:"${localStorage.getItem('loginUserId')}",fromType:"doctor",          toKey:"*",toType:"*",  status:"*", startDateTime:"*",endDateTime:"*"  
        ) {
        appointmentId
        fromKey
        fromType
        fromName
        fromMail
        toKey
        toType
        toName
        toMail
        startDateTime
        endDateTime
        note
        mailText
        reference
        status
        createdBy
        createdTs
        }
      }`
    };
    requestBody = JSON.stringify(requestBody);
    setProgress(true)
    let res = await oldgenericApi('searchAppointment', requestBody);
    if (!res) {
      // Error handling
      //console.log('Error')

    } else {
      setProgress(false)
      if (res.data.searchAppointment!=null && res.data.searchAppointment.length > 0) {
        var data=res.data.searchAppointment
        var newdata = data.map(i => ({
          ...i, "startDateTime":moment.utc(i.startDateTime).local().format('YYYY-MM-DD HH:mm'), "endDateTime": moment.utc(i.endDateTime).local().format('YYYY-MM-DD HH:mm')
        }))
        console.log(data,newdata,"patientslists")
        setAppointments(newdata)
      }else{
        setAppointments([])
      }
    }

  }
  const showAppointmentForm = (e, data) => {
    e.preventDefault()
    setOpen(true)
    props.UpdateFormField({ name: "dataforAppointment", value: data })
  }
  const onClose = () => {
    setOpen(false)
  }

  const handlePageClick = (offset) => {
    setoffset(offset)
  }


  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = (event) => {
    event.preventDefault();
    if (valSearch.length) {
      // searchfunc(valSearch);
      setoffset(0)
    } else {
      // fetchData();
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    // fetchData();
    setValSearch("")
  }
  const handleRowClick = (event, data) => {
    ////console.log('delete click 1')
    event.preventDefault();
  }

  const Columns = [

    {
      title: 'Name', field: 'firstName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
      render: data => <div onClick={(e) => openPatient(e, data)}>{data.firstName + ' ' + data.lastName}</div>
    },
    {
      title: "Health Issues/Concerns/Queries", field: 'requestDescription',
      // cellStyle: {
      //   color: '#244271',
      //   fontWeight: 500,
      //   // width: "50%",
      //   textAlign: 'left'
      // },
    },
    { title: 'Email', field: 'email' },
    // { title: 'City', field: 'city' },
    // { title: 'Country', field: 'country' },
    {
      title: "Action", field: 'action',
      render: data => findApp(data)
    }
  ]
  const findApp = (info) => {
    var data = appointmentsFixed.length > 0 && appointmentsFixed.filter((item, i) => item.reference === info.requestId)
    if (data.length > 0) {
      return <><div><small style={{ color: '#1F3D67' }}>Appointment Booked <br />
      {/* {moment(data[0].startDateTime).format('MMMM Do YYYY')}: <b>{moment(data[0].startDateTime).format('hh:mm a')} - {moment(data[0].endDateTime).format('hh:mm a')}</b> */}
      {moment(data[0].startDateTime).format('YYYY-MM-DD hh:mm a')} to {moment(data[0].endDateTime).format('YYYY-MM-DD hh:mm a')}
      {/* {data[0].startDate}: <b>{data[0].startDateTime} - {data[0].endDateTime}</b> */}
      
      </small></div></>
    } else {
      return <img src={ReqAppIcon} style={{marginLeft:"5%"}} alt="schedule" onClick={(e) => showAppointmentForm(e, info)} />
    }
  }

  const openPatient = (event, data) => {
    event.preventDefault()
    props.UpdateFormField({ name: "patientdata", value: data })
    props.UpdateFormField({ name: "patientRequestToken", value: true })
    props.history.push('/site/patient');
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}



      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">Patients Requests</h1>
      </div>
      <div className="listing-wrap hekmaGrid">
        <SimpleModal header="Appointment Form" open={open} onClose={onClose}>
          <div className="col-md-12">
            <AppointmentForm onClose={onClose} callAppointmentsInfo={callAppointmentsInfo} />
          </div>
        </SimpleModal>
        <div className="card mb-0">
          <div className="hekmaGridBox">
            <div className="col-xs-12 col-sm-12 col-md-5">
              <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                <HekmaTextSearchField
                  name="accountgridsearch"// this name you need not to add in constant file unless if we use redux
                  // label="Search Account"
                  placeholder="Search Patients"
                  value={valSearch}
                  handleChange={searchHandle}
                  className="gridSearchField"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                        {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                        X
                        {/* <i className="fa fa-times" aria-hidden="true"></i> */}
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div>
            {/* {data.length > 5 && */}
            <Materialtable
              columns={Columns}
              data={patientsData.length > 0 ? patientsData : []}
              handleRowClick={handleRowClick}
              actions={true}
            />

            {/* } */}
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateRecentItems: (obj) => {
      return dispatch(
        {
          type: 'updateRecentItems',
          value: obj,
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Patient));