import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import { Paginationn } from '../../../sponsorApp/common/pagination'
import moment from 'moment'

function TabContainer(props) {
  return (
    <>
      {props.children}
    </>
  );
}
export default function CenteredTabs({ queries, openQueries, NRSData, openNRSItem,openQueryPage, activeApp, handlePageClick, handlePageClickReq, offset, offsetReq, activeNSR, queriesData }) {


  return (
    <React.Fragment className="">
    
        <form>
         
            {queries.length > 0 ? (queries.slice(offset, 5 + offset)).map((item, i) => (
              <a className="dropdown-item" id={item.id} ><div className={item.status === "Created" ? "createdcss" : "viewedcss"}>{item.queryNum}(studyID:{item.studyId},siteID:{item.siteId}) - {item.queryTitle} -{item.severity}&nbsp;
                <span><small>{moment(item.modifiedTs).format('YYYY-MM-DD')}</small></span>
              </div></a>
            )) : <div className="ml-3">No alerts found </div>}
            {queries.length > 5 &&
              <Paginationn
                handlePageClick={handlePageClick}
                data={queries.length}
                offset={offset}
              />
            }

          </form>

      {/* {value === 1 &&
        <form>
          <TabContainer>
            {NRSData.length > 0 ? (NRSData.slice(offsetReq, 5 + offsetReq)).map((item, i) => (
              <a className="dropdown-item" id={item.id} onClick={(e) => openNRSItem(e, item)} ><div className={item.leadStatus === "New" ? "createdcss" : "viewedcss"}>{"NR" + item.studyNum} - {item.
                accountName}({item.accountId}) - Requested a study  -
                <span><small>{ moment(item.requestDateTime).format('YYYY-MM-DD')}</small></span>
              </div></a>
            )) : <div className="ml-3">No alerts found </div>}
            {NRSData.length > 5 &&
              <Paginationn
                handlePageClick={handlePageClickReq}
                data={NRSData.length}
                offset={offsetReq}
              />
            }
          </TabContainer></form>} */}
          {/* {value === 2 &&
        <form>
          <TabContainer>
            {queriesData.length > 0 ? (queriesData.slice(offsetReq, 5 + offsetReq)).map((item, i) => (
              <a className="dropdown-item" id={item.id} onClick={(e) => openQueryPage(e, item)} ><div className="createdcss">{item.queryNum} - {item.
                queryTitle} - ({item.studyId})
                <span><small>&nbsp;&nbsp;{  moment(item.createdTs).format('YYYY-MM-DD')}</small></span>
              </div></a>
            )) : <div className="ml-3">No alerts found </div>}
            {queriesData.length > 5 &&
              <Paginationn
                handlePageClick={handlePageClickReq}
                data={queriesData.length}
                offset={offsetReq}
              />
            }
          </TabContainer></form>} */}
    </React.Fragment>
  );
}