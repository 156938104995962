import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PdfPreview from './pdf-preview'
import Tooltip from '@material-ui/core/Tooltip';
import VideoPlayer from 'simple-react-video-thumbnail'

const textRed = {
  color: "red",
  cursor: "pointer",
  marginLeft: "5px",
  zIndex: "9999999"
}


export const FileUpload = ({ dataToAccept, fileSave, onChangeFile, allFiles, removeFile, disabled, preview,disabledUpload }) => {
  const [files, setFiles] = useState([]);
  const [showpreview, setShowPreview] = useState(true)
  //   accept: 'image/*',
  const { getRootProps, getInputProps } = useDropzone({
    accept: dataToAccept,
    noKeyboard: true, onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));

    }, multiple: true
  });


  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    allFiles.forEach(file => URL.revokeObjectURL(file.preview));
    //console.log(allFiles, "use")
    onChangeFile(files);
    if (preview == false) {
      setShowPreview(false)
    }
  }, [files]);
  const image = (preview) => {
    return <img src={preview} alt="image" height="75" width="75" />
  }
  //console.log(allFiles, "allfilesss")
  return (
    <section className="">
      {!disabledUpload &&
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <span>Drag 'n' drop some files here, or <span className="fileuploadclick">click</span> to select files</span>
        </div>
      }
      <div className="row mt-2">
        <div className="inputBox col-md-12">
          {allFiles.length > 0 &&
            <table className="viewCourseLinks">
              <thead>
                <tr>
                  {/* <th style={{ width: "10%" }}>S. NO</th> */}
                  <th style={{ width: "15%" }}>TYPE</th>
                  <th style={{ width: "35%" }}>NAME</th>
                  {showpreview && <th style={{ width: "20%" }}>PREVIEW</th>}
                  <th style={{ width: "10%" }}>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {
                  allFiles.map((item, i) => (
                    <tr>
                      {/* <td>{i + 1}</td> */}
                      <td>{item.type}</td>
                      <td>{item.name}</td>
                      {showpreview && <td className="previewpdf">{item.type.includes("image") ? image(item.preview) : item.type.includes("pdf") ? <span style={{ cursor: "pointer" }}><PdfPreview url={item.preview} show={false} width="75" />
                      </span> : item.type.includes("video") && <VideoPlayer videoUrl={item.preview} snapshotAt={2} />}
                      </td>}
                      <td className="action_div delIconColor" onClick={(e) => removeFile(e, item.name)} >
                        {/* <i className="fa fa-trash" style={textRed} onClick={(e) => removeFile(e, item.name)}></i>
                      <div > */}
                        <Tooltip title="Remove"><i className="far fa-trash-alt fa-lg"></i></Tooltip>
                      </td>
                    </tr>
                  ))
                }

              </tbody>
            </table>}
        </div>
      </div>
      {allFiles.length > 0 &&
        <div className="buttonContainer">
          <button type="button" onClick={() => { fileSave(allFiles) }} className="btn btn-primary-blue btn-rounded btn-small" disabled={disabled}>Upload</button>
        </div>

      }
    </section>
  );
}



