     import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../../common/apiconfig';
import FormHoc from '../../hoc/formHoc';
import { InputField, AutoOptions,AutoOptionsMultiple } from '../../formElements';
import { Dropdowns } from '../../../common/constants';
import { connect } from 'react-redux';
import HekmaDatePicker from '../../HekmaDatePicker'
import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import BackIcon from '../../../../img/back1.svg'

const AddSite = (propsobj) => {
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [options, setOptions] = useState([])
  let [fullSite, setfullSite] = useState("")
  let [imp, setimp] = useState(false)
  let [siteId, setsiteId] = useState("")
  let [siteName, setsiteName] = useState("")

  useEffect(() => {
    async function fetchData() {
      let requestBody = {
        query: `query {
        getAccountOfTypeSearch(accountType:"Site") {
          sponsorAccountId
          accountName
          parentAccountId
          accountType
          status
          address1
          address2
          city
          state
          country
          zip
          email
          phone
          
        }
        }`
      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getAccountOfTypeSearch', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getAccountOfTypeSearch.length) {
          var data = res.data.getAccountOfTypeSearch
          //to remove already added sites
          if (propsobj.formValues.siteRecords.length > 0) {
            var data1 = propsobj.formValues.siteRecords
            data = data.filter(function (item) {
              for (var i = 0, len = data1.length; i < len; i++) {
                if (data1[i].siteId === item.sponsorAccountId) {
                  return false;
                }
              }
              return true;
            });
          }
          setOptions(data)
        }
      }
    }
    fetchData()

    propsobj.UpdateFormField({ name: "addsite_onboardStatus", value: "" })
    propsobj.UpdateFormField({ name: "addsite_limitization", value: "" })


  }, []);

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsTotype = {
    default: null,
    'options': Dropdowns.Protocol_Setup_IE_Criteria_Type,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropstostatus = {
    ...autoPropsTotype,
    'options': Dropdowns.Study_Site_Onboard_Status,
    default: null,
  }
  const autoPropstoaccount = {
    'options': options,
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['accountName']
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('addSite', event);
    if (setGo) {
      setProgress(true);
      let form = propsobj.formValues;
      console.log(form)
      let requestBody = `
        mutation 
        {
          addStudySite(studyId: "${form.studyId}", siteId: "${siteId}" ,siteName: "${siteName}",siteUserId: "5000002", pContact: "hhh",status: "Active", limitization:"${form.addsite_limitization}",onboardStatus:"${form.addsite_onboardStatus}",impReceived:"${imp}",placeholder:"",role:"hh") {
            code
            type
            message
            attributes
          }  
        }
            `;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('addStudySite', requestBody, form);
      setProgress(false);

      if (res && res.data.addStudySite.code === '200') {
        propsobj.history.push('/account/studyDetails/site');
      } else {
        console.log('Error on response add account file!')
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
    propsobj.history.goBack();
  }

  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.goBack()
  }
  const handleImpChange = () => {
    setimp(!imp)
  }
  const getSite = (value) => {
    setsiteId(value.selected.sponsorAccountId)
    setfullSite(value.selected)
    setsiteName(value.selected.accountName)
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* 
      <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO SITE LIST</div><br/> */}
      <div className="d-flex justify-content-end mt-4">
        <div className="mr-auto secondary-header">Add Site Information</div>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="addSite" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="inputBox">
                    <AutoOptionsMultiple {...autoPropstoaccount} autoValue={fullSite.accountName} name="siteName" label="Site Name" keyName='accountName' must="true" callback={getSite} />
                    <AutoOptions {...autoPropstostatus} name="addsite_onboardStatus" label="Onboard Status" keyName='value' must={'true'} />
                    <InputField {...textProps} name="addsite_limitization" label="Limitization" must={'true'} type="number" />
                    <FormGroup style={{ paddingTop: "15px", width: "max-content" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={imp}
                            onChange={(e) => { handleImpChange(e) }}
                            value="true"
                            name="imp"
                            color="primary"
                          />
                        }
                        label="Imp Received"
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="buttonContainer">
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddSite));



