import React from 'react';

const Card = ({img,text,info,color}) => {
  return (

    <div className="card" style={{borderRadius: "15px"}}>
      <div className="card-body">
        <div className="row">
        <div className="col-md-7 col-xs-7 p-0 tcenter" style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
            <div className="numbers">
              <div className="card-category" style={{fontSize: "13px"}}>{text}</div>
              <div style={{color:color}}><b>{info}</b></div>
            </div>
          </div>
          <div className="col-md-5 col-xs-5 p-0">
            <div className="icon-big text-center icon-warning">
             <img src={img} />
            </div>
          </div>
         
        </div>
      </div>
    </div>
  )
}
export default Card
