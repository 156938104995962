import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearWrapper from './linearWrapper';
import Slider from './slider';
import { CustomizedProgressBars } from './roundedProgress';

function TestDetailCard(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');

    const lilist = [{
        icon: "fas fa-check-circle",
        icolor: "#48E6AA",
        text: "visit 1",
    },
    {
        icon: "fas fa-check-circle",
        icolor: "#FDA86A",
        text: "visit 2",
    },
    {
        icon: "fas fa-check-circle",
        icolor: "#F08383",
        text: "visit 3",
    },
    {
        icon: "fas fa-times-circle",
        icolor: "#F05555",
        text: "visit 4",
    },
    {
        icon: "fas fa-check-circle",
        icolor: "#F05555",
        text: "visit 5",
    },
    ];

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}
            <div className="studyProgress">
                {/* <div className="d-flex justify-content-between">
                    <h6>Study Progress</h6>
                    <span><i className="fas fa-ellipsis-h"></i></span>
                </div>
                <div className="row pb-5 mb-5 bottomborder">
                    <div className="col-12 col-sm-6 d-flex align-items-center">
                        <span className="roundedIcon roundedIcon50">
                            <i className="fas fa-book-open" style={{color:'#4098E1'}}></i>
                        </span>
                        <div className="ml-3">
                            <div className="text-muted">ONGOING STUDY</div>
                            <div><b>BIO MARIN 24</b></div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 d-flex align-items-center">
                        <CustomizedProgressBars size={70} value={75} />
                        <div className="ml-3">
                            <div className="text-muted"><i className="fas fa-walking"></i> <span className="ml-1">PROGRESS</span></div>
                            <div className="ml-4"><b>02/04</b></div>
                        </div>
                    </div>
                </div> */}

                <section>
                    {/* <ul className="list-unstyled d-flex justify-content-between text-muted">
                        <li>PREVIOUS ASSESSMENT</li>
                        <li>PRE-SCREENING</li>
                        <li>NEXT ASSESSMENT</li>
                        <li>SCREENING</li>
                    </ul> */}
                    <div>
                        <Slider id="dstudySlider" data={lilist}>
                            {
                                lilist.map((item, inx) => {
                                    return <li data_key={`slider_${inx}`} key={`sl+${inx}`}>
                                        <div className="centerline">
                                            <p></p>
                                            <span><i className={item.icon} style={{color:item.icolor}}></i></span>
                                        </div>
                                        <div className="bottomText mt-2">
                                            {item.text}
                                        </div>
                                    </li>
                                })
                            }
                        </Slider>
                    </div>
                </section>
            </div>

        </>
    )
}

export default TestDetailCard;


