import React from 'react'
import ViewCourse from '../fileupload/viewCoursecommon'

const ViewCourses=()=>{
  return(
    <>
    <ViewCourse type="Course" plural="courses"/>
    </>
  )
}
export default ViewCourses