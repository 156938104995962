import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../common/apiconfig';
import FormHoc from '../hoc/formHoc';
import { InputField, AutoOptions } from '../formElements';
import { language, rolesOpt, status, accountType } from '../../common/constants';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { HekmaTextSearchField } from '../formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import { checkPermission } from '../../common/utils';
import Materialtable from '../../../globalcomponents/materialtable'
const AccountSearch = (propsobj) => {
  let isAddAccount = checkPermission('Account_Create', 'Create');
  const [accountsearch, setSearch] = useState([]);
  let [progress, setProgress] = useState(false);
  const [offset, setoffset] = useState(0)

  let [valSearch, setValSearch] = useState('');

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }
  useEffect(() => {

    if (propsobj.formValues.addContactFromContactSearch || propsobj.formValues.addStudyFromStudySearch) {
      fetchData(propsobj.formValues.accountName)
    } else {
      fetchData(propsobj.formValues.parentAccountId == null ? "" : propsobj.formValues.parentAccountId);
    }
  }, []);

  async function fetchData(searchStr) {
    const getAllAccounts = `
    sponsorAccountId
  accountName
  parentAccountId
  accountType
  status
  address1
  address2
  city
  state
  country
  zip
  email
  phone
  website
  duns
  createdBy
  createdTs
  modifiedBy
  modifiedTs`;
    const parentAccount = "*";
    // getAccountSearch(parentAccountId:"${parentAccount}", searchStr:"${searchStr}", type:"${type}") {
    const type = 'B';
    const accountType = propsobj.formValues.accountTypeSearch || "*"
    let requestBody = {
      query: `query {
          getAccountTypeSearch(parentAccountId: "${parentAccount}",searchStr: "${searchStr}", type:"${type}",accountType: "${accountType}"){
                    ${getAllAccounts}
              }
          }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await apiCall('getAccountTypeSearch', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error on response material grid!')

    } else {
      if (res.data.getAccountTypeSearch.length) {
        setSearch(res.data.getAccountTypeSearch)
      } else {
        setSearch([])
      }
    }
  }


  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = (event) => {
    event.preventDefault();
    if (valSearch.length) {
      fetchData(valSearch);
      setoffset(0)
    }
  }
  const Columns = [
    {
      title: 'ID', field: 'sponsorAccountId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Name', field: 'accountName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: '25%'
      },
    },
    {
      title: 'Type', field: 'accountType'
    },

    { title: "Parent ID", field: 'parentAccountId' },
    // { title: 'Address', field: 'address1' },
    { title: 'City', field: 'city' },
    { title: 'Country', field: 'country' },
  ]
  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    propsobj.history.push('/account/AddAccount');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    if (propsobj.formValues.studyaccountseacrh) {
      var obj = { value: rowData.sponsorAccountId, name: "parentAccountId" }
      await propsobj.UpdateFormField(obj);
      await propsobj.UpdateFormField({ value: rowData.accountName, name: "accountName" })
      await propsobj.UpdateFormField({ value: rowData.sponsorAccountId, name: "sponsorAccountId" })
    } else if (propsobj.formValues.addContactFromContactSearch || propsobj.formValues.addStudyFromStudySearch) {
      await propsobj.UpdateFormField({ value: rowData.accountName, name: "accountName" })
      await propsobj.UpdateFormField({ value: rowData.sponsorAccountId, name: "sponsorAccountId" })
      await propsobj.UpdateFormField({ value: rowData.accountType, name: "accountType" })
    } else {
      var obj = { value: rowData.sponsorAccountId, name: "parentAccountId" }
      await propsobj.UpdateFormField(obj);
      //this is to make disable sponsor in study when go to study and comeback to account
      await propsobj.UpdateFormField({ name: "addStudyFromStudySearch", value: false })
    }

    propsobj.history.goBack();
  }
  const handleCancel = async () => {
    var obj = { value: propsobj.formValues.parentAccountId, name: "parentAccountId" }
    await propsobj.UpdateFormField(obj);
    propsobj.history.goBack();
  }
  const clearSearch = (event) => {
    event.preventDefault()
    fetchData('');
    setValSearch("")
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div class="d-flex mb-3">
        <h1 class="mr-auto primary-header">ACCOUNT SEARCH</h1>
        {isAddAccount === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
              <i className="fa fa-plus-circle mr-1"></i> ADD ACCOUNT</button>
          </div> : ""
        }
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
            <div className="col-xs-12 col-sm-12 col-md-5">
              <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                <HekmaTextSearchField
                  name="accountgridsearch"
                  // label="Search Account"
                  placeholder="Search Accounts"
                  value={valSearch}
                  handleChange={searchHandle}
                  className="gridSearchField"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" onClick={searchSubmit} style={{cursor:"pointer"}}>
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                        X
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div>
            <Materialtable 
              columns={Columns}
              data={accountsearch}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>


    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }

  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSearch))