import React, { useEffect, useState } from "react";
import { genericApi, apiCall } from '../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Loader from '../../common/loaderComp'
import { checkPermission } from '../../common/utils';
import Tooltip from '@material-ui/core/Tooltip';
import Materialtable from '../../../globalcomponents/materialtable'
function OrderHistory(props) {
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');

  useEffect(() => {
    getOrders()
  }, []);

  const getOrders = async () => {
    let requestBody = {
      query: `query {
        getOrderDetails(nctId:"*",siteId:"*")
      {
        orderId
        orderDisplayId
        accountId
        orderDate
        nctId
        createdBy
        createdTs
        modifiedBy
        modifiedTs
          orderDetails {
              orderId
        nctId
        siteId
        siteName
        city
        country
        patientCount
        totalPrice
        metrics
        insights
        createdBy
        createdTs
         modifiedBy
          modifiedTs
      }
    }
      }`
    }
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getOrderDetails', requestBody);
    setProgress(false);
    if (res) {
      if (res.data && res.data.getOrderDetails.length > 0) {
        setData(res.data.getOrderDetails)
      } else {
        setData([])
      }

    }
  }


  const handleRowClick = async (event, rowData) => {
    props.history.push('/account/orderdetails')
    props.UpdateFormField({ name: "orderdetails", value: rowData })
    // save clicked rowdata in redux, make sure the logic to empty back
  }

  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = async (event) => {
    event.preventDefault();
    if (valSearch.length) {
      // fetchData(valSearch);
      // await props.UpdateFormField({ name: "accountSearchText", value: valSearch })
      // await props.updateHeaderSearchValue({ value: "" })
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    // fetchData();
    setValSearch("")
  }


  const Columns = [
    {
      title: 'Order Date', field: 'orderDate',
      // cellStyle: {
      //   color: '#244271',
      //   fontWeight: 500,
      //   textAlign: 'left'
      // },
    },
    {
      title: 'Order ID', field: 'orderDisplayId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: '20%'
      },
    },
    {
      title: 'NCT ID / Study ID', field: 'nctId'
    },
    // { title: 'Site Name', field: 'site' },
    // { title: 'State', field: 'state' },
    // { title: 'City', field: 'city' },
    // { title: 'Patient Count', field: 'patientcount' },
    {
      title: "Total Price($)",
       field: 'pricing',
       headerStyle: {
        backgroundColor: props.thColor || "#f0f5ff",
        textAlign:'center',
        flexDirection: 'row',
      },
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        // width: '20%',
        textAlign:'center',
      },
      render: rowData => numberWithCommas(parseInt(rowData.orderDetails.reduce(function (total, currentValue) {
        return total + parseInt(currentValue.totalPrice);
      }, 0)))
    },
    // { title: 'Metrics', field: 'metrics',render: rowData => <Tooltip title="Dashboard" arrow><i className="fa fa-solid fa-chart-line" style={{ color: "#23416F",marginLeft:"12%" }} alt="Dashboard" onClick={(e) => showDashboard(e, rowData)} 
    // /></Tooltip> },
    // { title: 'Insights', field: 'insights',render: rowData => <Tooltip title="Insights" arrow><i className="fa fa-solid fa-filter" style={{ color: "#23416F",marginLeft:"12%" }} alt="Insights" onClick={(e) => showInsights(e, rowData)} /></Tooltip> },
  ]

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const showDashboard = (e, rowData) => {
    e.preventDefault()
  }
  const showInsights = (e, rowData) => {
    e.preventDefault()
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
        {/* <Loader/> */}
      </div>}
      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">Order History</h1>
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
            {/* <div className="col-xs-12 col-sm-12 col-md-5">
           
            </div> */}
            <Materialtable
              columns={Columns}
              data={data.length > 0 ? data : []}
              handleRowClick={handleRowClick}
              search={true}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateHeaderSearchValue: (obj) => {
      return dispatch(
        {
          type: 'headerSearch',
          value: obj.value,
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateRecentItems: (obj) => {
      return dispatch(
        {
          type: 'updateRecentItems',
          value: obj,
        }
      )
    },
    setSidebarActiveEl: (name) => {
      return dispatch(
        {
          type: 'sidebarActiveEl',
          value: name,
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderHistory));