import React, { useEffect, useState } from "react";
import Header from './header/header';
import Sidebar from './sidebar/sidebar';
import SiteHome from './contentBody/siteHome';

const MainPage = (props) => {
    const location = props.location;
    return ( 
        <>
            <div id="wrapper">
        {location.pathname != "/activateContact" && <Sidebar />}
                <div className="contentBody">
                    <Header />
                    <SiteHome />
                </div>
            </div>
        </>
    )
}
export default MainPage;


