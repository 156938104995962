import React, { useEffect, useState, Component } from "react";
import Loadable from 'react-loadable';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
// import DashboardHome from './dashboardHome';
// import PatientDetail from './patientDetail';
import MenuButton from '../../../siteSrc/components/site/pir/menuButton';
import NewSearchCriteria from '../../../siteSrc/components/site/pir/newsearchcriteria';
import PirResults from './results'
import SiteSelectionPage from './siteselectionpage'

const PIR = (props) => {
  return (
    <>
      <div className="mt2rem">
        <Switch>
          <Route path="/account/pir/searchcriteria">
            {/* <Searchcriteria /> */}
            <NewSearchCriteria sponsor={true} Inclusion={props.formValues.Inclusion} Exclusion={props.formValues.Exclusion} Pir={props.formValues.PIRdata} sidebar={false} advancedsearch={props.formValues.advancedsearch} />
          </Route>
          <Route path="/account/pir/siteselection">
            <SiteSelectionPage />
          </Route>
          <Route path="/account/pir/results">
            <PirResults />
          </Route>
          <Redirect to="/account/pir/searchcriteria" />
        </Switch>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PIR);

