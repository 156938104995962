import React, { useEffect, useState } from "react";
//import { withRouter } from 'react-router-dom';
//import { Button } from '@material-ui/core';
import { appConfig } from '../../../../common/constants'
import PIRTextaria from './pirtextaria';
import Title from './Title';
import Entities from './entities';
//import EntityForm from './entityForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import { myresponse, pir2response } from './modaljson';
import { genericApi } from '../../../../common/apiconfig';
import { connect, useSelector, useDispatch, ReactReduxContext } from 'react-redux';
import InclusionIcon from '../../../../img/Inclusion.svg'
import ExclusionIcon from '../../../../img/Exclusion.svg'
import AddCriteriaModal from './addcriteriamodal';

const initialForm = {
    inclusion: [],
    exclusion: []
}
let responseData = '';

function SearchCriteria(props) {
    let [form, setForm] = useState(initialForm);
    const [entityData, setEntityData] = useState(null);
    const [progress, setProgress] = useState(false)
    let [showentity, setshowentity] = useState(false);
    let [isAddCriteria, setAddCriteria] = useState(false);
    let [criteriaType, setCriteriaType] = useState('inclusion');
    let [criteriaValue, setcriteriaValue] = useState('');
    let [isEdit, setEdit] = useState(false);
    let [arrayInx, setarrayInx] = useState();
    let [showall, setshowall] = useState(false);


    const updateShowAll = (flag) => {
        submitHandler('',flag);
    }

    const reduxdispatch = useDispatch();


    let reduxstateobj = useSelector(state => state);
    useEffect(() => {
        ////console.log('search criteria by redux changes')
        if (reduxstateobj.entitydata) {
            setEntityData(reduxstateobj.entitydata)
        }
    }, [reduxstateobj.entitydata])

    useEffect(() => {
        let pirobj = {
            search: true,
            result: false,
            patient: false
        }
        reduxdispatch({ type: 'pirmenu', value: pirobj })
    }, []);

    async function getApiData() {
        setshowentity(false);
        setshowall(false);
        let eventType = "I"
        let requestBody = {
            query: `query {
                  getStudyEvent(studyId: "${props.formValues.autostudy.studyId}", eventType: "B") {
                    studyId
                    eventId
                    eventType
                    type
                    description
                    effectiveDate
                    status
              }
          }`
        };
        requestBody = JSON.stringify(requestBody);
        setProgress(true);
        let res = await genericApi('getStudyEvent', requestBody);
        setProgress(false);
        if (!res) {
            // Error handling
            //console.log('Error in study details!')

        } else {
            if (res.data.getStudyEvent.length) {
                buildIEdata(res.data.getStudyEvent);
            } else {
                setForm({
                    ...form,
                    'inclusion': [],
                    'exclusion': []
                })
            }
        }
    }
    useEffect(() => {
        getApiData()
    }, [props.formValues.autostudy.studyId])

    function buildIEdata(iedata) {
        let EData = [];
        let IData = [];
        iedata.map((item, inx) => {
            if (item.eventType == 'E' && item.status == 'Active') {
                EData.push(item.description)
            } else if (item.eventType == 'I' && item.status == 'Active') {
                IData.push(item.description)
            } else {
                return ''
            }
        })

        setForm({
            ...form,
            'inclusion': IData,
            'exclusion': EData
        })

        // //console.log('idata::', IData);
        // //console.log('EData::', EData);


        // //return;
        // IData = formatIEData(IData);
        // EData = formatIEData(EData);

        // setForm({
        //     ...form,
        //     'inclusion': IData.join(''),
        //     'exclusion': EData.join('')
        // })

    }

    const formatIEData = (input) => {
        if (input.length) {
            let modifiedArray = input.map((item, inx) => {
                if (item.charAt(item.length - 1) == '.') {
                    return item.concat(' \n');
                } else {
                    return item.concat('. \n');
                }
            })
            return modifiedArray;
        } else {
            return [];
        }
    }

    const deleteHandler = (e, inx, type) => {
        e.preventDefault();

        // //console.log('delete inx', inx);
        // //console.log('delete type', type);


        let oldType = form[type];
        oldType.splice(inx, 1);
        setForm({
            ...form,
            [type]: oldType
        })
        closeAddCriteria();

        //dialogclose(false);
        //setEntitydata({ ...entityData, ...oldObj });
    }

    const edit = (e, inx, type) => {
        e.preventDefault();
        try {
            setarrayInx(inx);
            setAddCriteria(true);
            setCriteriaType(type);
            setEdit(true)
            setcriteriaValue(form[type][inx]);
        } catch (error) {

        }


        // setForm({
        //     ...form,
        //     [e.target.name]: e.target.value
        // })
    }

    const addCriteria = (e, type) => {
        e.preventDefault();
        setAddCriteria(true);
        setEdit(false);
        setcriteriaValue('');
        if (type == "inclusion") {
            setCriteriaType('inclusion');
        } else {
            setCriteriaType('exclusion');
        }

    }

    const closeAddCriteria = () => {
        setAddCriteria(false);
        setEdit(false);
    }

    const submitAddCriteria = (e, value, type, isEdit, arrInx) => {
        e.preventDefault();

        if (isEdit) {
            let newArr = form[type];
            newArr[arrInx] = value;
            setForm({
                ...form,
                [type]: newArr
            })
        } else {
            setForm({
                ...form,
                [type]: [...form[type], value]
            })
        }
        setAddCriteria(false);
    }

    const submitHandler = (e, showallFlag) => {

        let inclusionData = formatIEData(form.inclusion).join('');
        let exclusionData = formatIEData(form.exclusion).join('');
        let reqParam = {};
        reqParam.inc = inclusionData;
        reqParam.exc = exclusionData;

        try {
            e.preventDefault();
        } catch (error) {

        }

        setProgress(true);
        setshowentity(false);



        const requestOptions = {
            method: 'POST',
            //mode: "cors", // or without this line

            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(reqParam)
        };



        if (showallFlag == true || showallFlag == false) {
            formateResponseData(responseData);
            setshowall(showallFlag);
            setProgress(false)
            setshowentity(true)
        } else {
            fetch(appConfig.PIRApi, requestOptions)
                .then(response => response.json())
                .then((data) => {
                    setProgress(false)
                    setshowentity(true)
                    //let data = myresponse;
                    //let data = pir2response;
                    responseData = data;
                    formateResponseData(data);
                });
        }



        function formateResponseData(data) {
            try {
                var excKeys = Object.keys(data.response.exc);
                var ex_ent_type = [];
                excKeys.map(item => {
                    let exCategory = data.response.exc[item].map((excItem, inx) => {
                        excItem.cssClass = "exclusion";
                        return excItem.category;
                    })
                    ex_ent_type = [...ex_ent_type, ...exCategory];
                });
            } catch (error) {
                //console.log('pir err')
            }

            try {
                var incKeys = Object.keys(data.response.inc);
                var in_ent_type = []
                incKeys.map(item => {
                    let inCategory = data.response.inc[item].map((incItem, inx) => {
                        incItem.cssClass = "inclusion";

                        if (incItem.neg) {
                            incItem.cssClass = "exclusion";
                        }

                        return incItem.category;
                    })
                    in_ent_type = [...in_ent_type, ...inCategory];
                })
            } catch (error) {
                alert('pir err2')
            }

            var ent_type_data = [...ex_ent_type, ...in_ent_type];
            let uniqueEntType = [...new Set(ent_type_data)];

            let modalResponse = {};

            uniqueEntType.map((item, i) => {
                modalResponse[item] = {}

                var exItemCategory = [];
                var inItemCategory = [];
                excKeys.map(exKeyItem => {
                    let exItemCategoryfiltered = data.response.exc[exKeyItem].filter((excItem, inx) => {
                        if (showallFlag) {
                            if (item == excItem.category) return true;
                        } else {
                            if (item == excItem.category && excItem.approved == true) return true;
                        }

                    })
                    exItemCategory = [...exItemCategory, ...exItemCategoryfiltered];
                });


                incKeys.map(inKeyItem => {
                    var inItemCategoryfiltered = data.response.inc[inKeyItem].filter((incItem, inx) => {

                        if (showallFlag) {
                            if (item == incItem.category) return true;
                        } else {
                            if (item == incItem.category && incItem.approved == true) return true;
                        }

                    })
                    inItemCategory = [...inItemCategory, ...inItemCategoryfiltered];
                });

                let exItemsByName = exItemCategory.map((termitem, inx) => {
                    try {
                        if (termitem.fb_ner && termitem.fb_ner.meshentity && termitem.fb_ner.meshentity.concept) {
                            termitem.concept = true;
                            termitem.originalterm = termitem.term;
                            termitem.term = termitem.fb_ner.meshentity.concept;
                            return termitem.fb_ner.meshentity.concept;
                        } else {
                            termitem.concept = false;
                            termitem.originalterm = termitem.term;
                            return termitem.term;
                        }
                    } catch (error) {
                        return termitem.term;
                    }

                })

                let inItemsByName = inItemCategory.map((termitem, inx) => {

                    try {
                        if (termitem.fb_ner && termitem.fb_ner.meshentity && termitem.fb_ner.meshentity.concept) {
                            termitem.concept = true;
                            termitem.originalterm = termitem.term;
                            termitem.term = termitem.fb_ner.meshentity.concept;
                            return termitem.fb_ner.meshentity.concept;
                        } else {
                            termitem.concept = false;
                            termitem.originalterm = termitem.term;
                            return termitem.term;
                        }
                    } catch (error) {
                        return termitem.term;
                    }
                    return termitem.term;

                })

                ////console.log('exItemsByName', exItemsByName)
                ////console.log('inItemsByName', inItemsByName)

                let filterExcItems = exItemsByName.map((fitem, finx) => {
                    return exItemCategory.find((exitem, jj) => {
                        // if (!exitem.concept) {
                        //     if (exitem.term == fitem) {
                        //         return true;
                        //     }
                        // }
                        if (exitem.term == fitem) {
                            return true;
                        }
                    })
                })

                let filterIncItems = inItemsByName.map((fitem, finx) => {
                    return inItemCategory.find((initem, jj) => {
                        // if (!initem.concept) {
                        //     if (initem.term == fitem) {
                        //         return true;
                        //     }
                        // }

                        if (initem.term == fitem) {
                            return true;
                        }
                    })
                })
                let combinedItems = [...filterExcItems, ...filterIncItems];
                modalResponse[item].combinedItems = combinedItems;

            });

            ////console.log('modalResponse::', modalResponse);
            setEntityData(modalResponse);
        }

    }


    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}

            <div className="dashboardContainer">
                <section className="row searchcriteriacontainer align-items-stretch">
                    <section className="col-12 col-sm-4">
                        <div className="pircontent">
                            <section className="d-flex justify-content-between">
                                <div className="iconPlace">
                                    <img src={InclusionIcon} />&nbsp;
                                    <Title title="Inclusion Criteria" />
                                </div>
                                <div className="text-right">
                                    <button className="btn btn-primary-blue btn-rounded btn-small pt-1 pr-2 pl-2 pb-1" onClick={(e) => addCriteria(e, 'inclusion')}><i className="fas fa-plus"></i></button>
                                </div>
                            </section>

                            <section className="searchCriteriaPanel mt2rem">
                                <PIRTextaria textvalue={form['inclusion']} name="inclusion"   hideDelete={false} clickHandler={edit} />
                            </section>


                            <section className="d-flex justify-content-between mt-4">
                                <div className="iconPlace">
                                    <img src={ExclusionIcon} />&nbsp;
                                <Title title="Exclusion Criteria" />
                                </div>
                                <div className="text-right">
                                    <button className="btn btn-primary-blue btn-rounded btn-small pt-1 pr-2 pl-2 pb-1" onClick={(e) => addCriteria(e, 'exclusion')}><i className="fas fa-plus"></i></button>
                                </div>
                            </section>

                            <section className="searchCriteriaPanel mt2rem">
                                <PIRTextaria textvalue={form['exclusion']}   hideDelete={false} name="exclusion" clickHandler={edit} />
                            </section>
                            <div className="mt2rem text-right">
                                <button type="submit" className="btn btn-primary-blue btn-rounded btn-small" onClick={submitHandler}>Submit</button>
                            </div>
                        </div>
                    </section>
                    <section className="col-12 col-sm-8">
                        <section className="pircontent">
                            <Entities
                                data={entityData || null}
                                showentity={showentity}
                                updateShowAll={updateShowAll}
                                showall={showall}
                            />
                        </section>
                    </section>

                    <section className="">
                        <AddCriteriaModal
                            open={isAddCriteria}
                            close={closeAddCriteria}
                            submit={submitAddCriteria}
                            title={criteriaType}
                            value={criteriaValue}
                            isEdit={isEdit}
                            inx={arrayInx}
                            deleteHandler={deleteHandler}
                        />
                    </section>
                </section>

            </div>
        </>
    )

}


const mapStateToProps = (state) => {
    return {
        formValues: state.form,
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        },
        updateErrorObj: (obj) => {
            return dispatch(
                {
                    type: 'updateErrorObj',
                    flag: obj.flag,
                    name: obj.name,
                    text: obj.text
                }
            )
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchCriteria);



