import React, { useEffect, useState, Component } from "react";
import Loadable from 'react-loadable';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
//import ChangeStudy from '../changeStudy';
import { withRouter } from 'react-router-dom';
import { NavMenu } from '../../siteSrc/components/navMenu';
import { connect } from 'react-redux';
import Siteuserdetails from '../../siteSrc/components/site/siteuserdetails'
import UserAccounts from '../../siteSrc/components/site/useraccounts'
import UserStudies from '../../siteSrc/components/site/userstudies'

const menus = [
  { to: "/app/users/details/view", text: "User Details" },
  { to: "/app/users/details/accounts", text: "Related Accounts" },
  { to: "/app/users/details/studies", text: "Related Studies" },
]

const UsersHome = (props) => {

  const LoadCom = (props) => {
    let Component = Loadable({
      loader: () => import(`./${props.fileName}`),
      loading() {
        return <div className="fullScreen">
          <CircularProgress
            disableShrink
            className="progressCircle"
            value={90}
            size={100}
            thickness={1.5}
          />
          <span>UI Script loading</span>
        </div>
      },
    });
    return <Component {...props} />
  }


  return (
    <>
      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">{props.formValues.firstName} {props.formValues.lastName}</h1>
      </div>

      <section className="mb-3">
        <NavMenu menus={menus} />
      </section>

      <div className="">
        <Switch>
          <Route path="/app/users/details/accounts" component={UserAccounts}/>
          <Route path="/app/users/details/studies" component={UserStudies}/>
          <Route path="/app/users/details/view" component={Siteuserdetails}/>
          <Redirect to="/app/users/details/view" />
        </Switch>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersHome));


