// import React, { useEffect, useState } from "react";
// import CircularProgress from '@material-ui/core/CircularProgress';
// import { LinearProgress } from '@material-ui/core';

// function LinearWrapper(props) {
//     const [offset, setoffset] = useState(0)
//     const [data, setData] = useState([])
//     let [progress, setProgress] = useState(false);
//     let [valSearch, setValSearch] = useState('');

//     return (
//         <>
//             {progress && <div className="fullScreen">
//                 <CircularProgress
//                     disableShrink
//                     className="progressCircle"
//                     value={90}
//                     size={100}
//                     thickness={1.5}
//                 />
//             </div>}
//                 <section className="row labrow">
//                     {/* <div className="col-12 d-flex titlerow mb-3">
//                         <div>Result Name 1</div>
//                         <div><i className="far fa-clock"></i><span>05/27/2020 08:00 PM</span></div>
//                     </div> */}
//                     <div className="col-12 unitread">
//                         <section>
//                             <div className="reading" style={{left:'72%'}}>72 BPM</div>
//                             <div><i className="fas fa-caret-down" style={{left:'72%'}}></i></div>
//                         </section>
//                     </div>
//                     <div className="col-12">
//                         <div className="linearWrapper">
//                             <LinearProgress
//                                 variant="determinate"
//                                 value={100}
//                                 className="firstLinear"
//                             />

//                             <LinearProgress
//                                 variant="determinate"
//                                 style={{
//                                     width:'30%',
//                                     left:'50%',
//                                     backgroundColor:'#b4b936'
//                                 }}
//                                 value={50}
//                                 className="secondLinear"
//                             />
//                         </div>
//                     </div>

//                     <div className="col-12 numbers">
//                         <span>0</span>
//                         <span>20</span>
//                         <span>40</span>
//                         <span>60</span>
//                         <span>80</span>
//                         <span>100</span>
//                     </div>
//                 </section>
//         </>
//     )

// }

// export default LinearWrapper;
import React, { useEffect } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
const Chart = ({ data,data1, xaxisdata, min, max,name,unit }) => {
    useEffect(() => {
        let seriesData=[{
            name: name==="Blood Pressure" ? "Systolic Pressure" : name,
            data: data,
            color: 'green'
            // zoneAxis: 'x',
            // zones: [{value: 4}, {value: 5, color: 'red'}]
        }]
        if(data1 && data1[0]){
            seriesData.push({
            name: name==="Blood Pressure" ? "Diastolic Pressure" : "",
            data: data1,
            color: 'green'
            // zoneAxis: 'x',
            // zones: [{value: 4}, {value: 5, color: 'red'}]
        })
    }
    console.log(seriesData,"BP")
        Highcharts.chart('container', {
            chart: {
                type: 'line',
                style: {
                    fontFamily: 'Roboto, sans-serif'
                }
            },
            title: {
                text: ''
            },

            // subtitle: {
            //   text: 'Source: thesolarfoundation.com'
            // },

            yAxis: {
                softMax: max,
                softMin:min,
                // allowDecimals: true,
                // min: min - 2,
                // max:max+2,
                title: {
                    text: unit
                },
                plotLines: [{
                    value: min,
                    width: 2,
                    // stroke: 'black',
                    // 'Solid'    'ShortDash'    'ShortDot'    'ShortDashDot'    'ShortDashDotDot'    'Dot'    'Dash'    'LongDash'    'DashDot'    'LongDashDot'    'LongDashDotDot'
                    dashStyle: "Dash",
                    color:"red",
                    label: {
                        text: 'Min',
                        align: 'right',
                        color:"red",
                        y:20,
                        zIndex:9999
                        // padding:"10px"
                    }
                }, {
                    value: max,
                    width: 2,
                    color: 'red',
                    dashStyle: "Dash",
                    label: {
                        text: 'Max',
                        align: 'right',
                        color:"red",
                        y:20
                    }
                }]
            },

            xAxis: {

                accessibility: {
                    rangeDescription: 'Month'
                },
                // title: {
                //     text: 'Month'
                // },
                categories: xaxisdata
            },

            legend: {
                reversed: true
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            credits: {
                enabled: false
            },
            series: seriesData,

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        });
    }, [data])
    return (
        <div id="container" ></div>
    )
}


export default Chart

