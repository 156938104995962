import React, { useState, useEffect, Fragment } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, styled } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import moment from 'moment'
import Clock from '../img/emailtemplate/clock.svg'
import User from '../img/emailtemplate/user.svg'
import Desc from '../img/emailtemplate/content.svg'
import Meeting from '../img/videochat/meeting.svg'
import Recording from '../img/videochat/Video-call.svg'

const LightTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid skyblue"
    },
    color: theme.palette.common.white
  },
  tooltip: {
    maxWidth: "none",
    // "&:before": {
    border: "1px solid skyblue",
    // },
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
    marginLeft: "-10px"
  },
}))(Tooltip);
// const CustomWidthTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))({
//   [`& .${tooltipClasses.tooltip}`]: {
//     maxWidth: 500,
//   },
// });

const CalendarTooltip = props => {
  let [mtime, setMtime] = useState('')
  const { data } = props.info.event
  var startDate = new Date(props.info.event.start);
  var ToDate = new Date();
  var endDate = new Date(props.info.event.end)
  // console.log(props, "dddddddddd");
  useEffect(() => {
    // const interval = setInterval(() => {
    //   var timeleft = getRemaining(props.info.event.start)
    //   setMtime(timeleft);
    // }, 100);
    // return () => clearInterval(interval);
  }, [(startDate.getTime() - 60000) <= ToDate.getTime()])

  // function getRemaining(ts) {
  //   const now = moment();
  //   const then = moment(ts);
  //   const diff = then.diff(now);
  //   const dur = moment.duration(diff);

  //   let parts = [];
  //   for (const part of ['days', 'hours', 'minutes', 'seconds']) {
  //     const d = dur[part]();
  //     dur.subtract(moment.duration(d, part));
  //     parts.push(d);
  //   }
  //   return parts;
  // }



  return (
    <div className="doctooltip" style={{ overflow: 'hidden' }}>
      <LightTooltip TransitionComponent={Zoom} interactive title={
        <div className="" style={{ maxWidth: "500px" }}>
          <div className="caltooltipheader">
            <img src={User} />&nbsp;<span style={{ fontSize: "13px", marginLeft: "4px" }}>{props.info.event.title}</span>
          </div>
          <div className="" style={{ fontSize: "12px", borderBottom: "1px solid #e9e5e5" }}>

            <div style={{ display: "flex" }}><img src={Clock} alt="time" />&nbsp;<div style={{ marginBottom: "0px", marginLeft: "4px" }}>{moment(props.info.event.start).format('dddd, MMMM Do YYYY')}</div>
              &nbsp;<div>{moment(props.info.event.start).format('hh:mm a')} - {moment(props.info.event.end).format('hh:mm a')}</div></div>
          </div>
          <div className="" style={{ display: "flex", wordBreak: "break-all", borderBottom: "1px solid #e9e5e5" }}>
            <small><img src={Desc} alt="description" />&nbsp;<span style={{ marginLeft: "4px" }}>{props.info.event.extendedProps.description}</span>
              {/* {(startDate.getTime() <= ToDate.getTime()) ? <><a href={props.info.event.extendedProps.link}>JOIN THE MEETING</a></> : <span style={{ color: "#2b78d4", padding: "10px" }}>Link will be available on time</span>} */}
              {/* 1200>1000 ? available soon :  enddate>present && startdate<present ? link : link expired */}
            </small>
          </div>
          <div style={{ borderBottom: "1px solid #e9e5e5" }}>
            <small><img src={Meeting} />&nbsp;
              {((startDate.getTime() - 60000) >= ToDate.getTime())
                ?
                <span style={{ color: "#2b78d4", marginLeft: "4px" }}>Link will be available before 1minute</span> :
                (((startDate.getTime() - 60000) <= ToDate.getTime()) && (endDate.getTime() >= ToDate.getTime()))
                  ?
                  <> <a href={props.info.event.extendedProps.link} onClick={(e) => props.linkclick(e, props.info.event.extendedProps.link, props.info.event.extendedProps.metadata)}>JOIN THE MEETING</a></>
                  : <span style={{ color: "red", marginLeft: "4px" }}>Meeting Link Expired</span>}

              {/* {JSON.stringify(mtime)} */}

              {/* <br /><button type="button" className="btn btn-primary-blue btn-rounded btn-small " disabled={(startDate.getTime() >= ToDate.getTime())} >Click to join meeting</button> */}
            </small>
          </div>
          {(startDate.getTime()+120000) <= ToDate.getTime() &&
            <div className="viewrecord">
              <small onClick={(e) => props.clickRecording(e, props.info.event.extendedProps.metadata.appointmentId)} ><img src={Recording} alt="viewrecording" />&nbsp;<span style={{ marginLeft: "4px" }}>View Recording & Chat</span></small>
            </div>
          }
        </div>

      } placement="right" arrow>
        <div className="fc-event-title"
          style={{ 
            // color: ((startDate.getTime() - 360000) <= ToDate.getTime()) ? "#777" : "#2b78d4",
             fontWeight: "100",marginRight:"4px",cursor:"pointer" }}
        // style={{"backgroundColor":props.info.backgroundColor}}
        > {props.info.event.title}- {props.info.event.extendedProps.description}</div>
      </LightTooltip >
    </div >
  )
}
export default CalendarTooltip;
