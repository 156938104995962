import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../../../globalcomponents/formElements';
import { connect } from 'react-redux';
import { genericApi } from '../../../common/apiconfig';

// let isEditStudy = checkPermission('Study_Details_Edit', 'Update');
const ViewARM = (propsobj) => {
  // let isEditAE = checkPermission('AE_Edit', 'Update');
  let [data, setData] = useState(false);
  let [progress, setProgress] = useState(false);

  // const handleClick = async (event) => {
  //   event.preventDefault();
  //   propsobj.history.push('/account/studyDetails/adverseEvents/Update');
  // }

  useEffect(() => {
    async function fetchData() {

      let requestBody = {
        query: `
          query {
            getStudyArmsWithDetails(armNum: "${propsobj.formValues.armNum}") {
                arm
                {
                  armNum
                  armName
                  studyId
                  type
                  description
                  status
                  createdBy
                  createdTs
                  modifiedBy
                  modifiedTs
              
            }
            armDetails
            {
                armDetails
                armNum
                dosageName
                dosage
                strength
                units
                route
                frequency
                description
                createdBy
                createdTs
                modifiedBy
                modifiedTs
            }
          }
          }
            `

      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await genericApi('getStudyArmsWithDetails', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error')

      } else {
        if (res && res.data != null && res.data.getStudyArmsWithDetails != null) {
          setData(res.data.getStudyArmsWithDetails)
        } else {
          setData([])
        }
      }
    }

    fetchData();
  }, []);
  console.log(data,"data")
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO ADVERSE EVENTS LIST</div><br /> */}

      <div className="d-flex mt-4 justify-content-end">

        <div class="secondary-header mr-auto">View Arm</div>
        {/* {isEditAE === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
              <i className="fas fa-pencil-alt"></i>&nbsp; EDIT</button>
          </div> : ""} */}

      </div>
      <div className="">
        {data &&
          <form noValidate autoComplete="off" id="addAccountForm" className="row h-form-1">
            <div className="col-md-5">
              <div className="panel-primary-wrap">
                <div className="card">
                  <div className="card-body">

                    <div className="inputBox">

                      <ViewRow text="Arm Name" textvalue={data.arm.armName} />
                      <ViewRow text="Type" textvalue={data.arm.type} />
                      <ViewRow text="Decription" textvalue={data.arm.description} />
                      <ViewRow text="status" textvalue={data.arm.status} />


                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data.armDetails.length > 0 &&
              <div className="col-md-7">
                <div className="panel-primary-wrap">
                  <div className="card">
        
                    <div className="card-body">
                    <div className="card-page-header" >Arm Details</div> 
                      <div className="inputBox">

                        <table class="viewCourseLinks">
                          <thead className="">
                            <tr>
                              <th> S.NO</th>
                              <th>Drug Name</th>
                              <th>Dosage</th>
                              <th>Strength</th>
                              <th>Unit</th>
                              <th>Route</th>
                              <th>Frequency</th>
                            </tr>
                          </thead>
                          <tbody>
                            {

                              data.armDetails.map((item, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.dosageName}</td>
                                    <td>{item.dosage}</td>
                                    <td>{item.strength}</td>
                                    <td>{item.units}</td>
                                    <td>{item.route}</td>
                                    <td>{item.frequency}</td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </form>
        }
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewARM));



