import React, { useEffect } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
const Piechart = ({ options, highcharts }) => {
  useEffect(() => {
    Highcharts.chart('container2', {
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0
        },
        style: {
          fontFamily: 'Roboto, sans-serif'
      }
      },
      title: {
        text: 'IE Criteria'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 35,
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          }
        }
      },
      credits: {
        enabled: false,
      },
      series: [{
        type: 'pie',
        name: 'share',
        data: [
          ['Inclusion1', 45.0],
          ['Exclusion1', 26.8],
          {
            name: 'Inclusion2',
            y: 12.8,
            sliced: true,
            selected: true
          },
          ['Exclusion2', 8.5],
      
        ]
      }]
    });
  }, [])
  return (
    <div id="container2" ></div>
    // <Highcharts
    //   highcharts={Highcharts}
    //   // constructorType={'chart'}
    //   options={chartOptions}
    // />
  )
}


export default Piechart