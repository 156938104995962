import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavMenu } from '../../navMenu';
import StudyGrid from '../accountDetails/studyGrid'
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import ViewStudyDetails from './viewStudyDetails';
import SiteGrid from './sitegrid'
import AdverseEventsGrid from './adverseEventsGrid'
import IEcriteriaGrid from './IEcriteraGrid'
import RBMGrid from './RBMGrid'
import CoursesGrid from './coursesGrid'
import ResourcesGrid from './resourcesGrid'
import EventsGrid from './eventsGrid'
import ArmsGrid from './armsGrid'
import AddArm from './addArm'
import studypersonalGrid from './studyPersonalGrid'
import AddIECriteria from './addIECriteria'
import ViewIECriteria from './viewIECriteria'
import UpdateIECriteria from './updateIECriteria'
import AddAE from './addAE'
import ViewAE from './viewAE'
import UpdateAE from './updateAE'
import AddRBM from './addRBM'
import ViewRBM from './viewRBM'
import UpdateRBM from './updateRBM'
import AddCourses from './addCourses'
import ViewCourses from './viewCourses'
import UpdateCourses from './updateCourses'
import AddResources from './addResources'
import ViewResources from './viewResources'
import UpdateResources from './updateResources'
import UpdateStudy from './updateStudy'
import Contacts from './studyPercontacts'
import AddSite from './addSite'
import SiteDetails from './siteDetails/home'
import UpdateSite from './updateSite'
import AddStudyPer from './addStudyPersonal'
import AuditTrail from './auditLog'
import PAGGrid from './paggrid'
import AddPag from './addPag'
import UpdatePag from './updatePag'
import PAGDetails from './pagDetails/home'
import ViewARM from './viewArm'
import UpdateARM from './updateArm'
const menus = [
  {
    to: "/account/studyDetails",
    text: "Study Details"
  },
  {
    to: "/account/studyDetails/site",
    text: "Site"
  },
  {
    to: "/account/studyDetails/pag",
    text: "PAG"
  },
  {
    to: "/account/studyDetails/arm",
    text: "Arm"
  },
  {
    to: "/account/studyDetails/IEcriteria",
    text: "IE Criteria"
  },
  {
    to: "/account/studyDetails/adverseEvents",
    text: "Adverse Events"
  },
  {
    to: "/account/studyDetails/rbm",
    text: "RBM"
  },
  {
    to: "/account/studyDetails/courses",
    text: "Courses"
  },
  {
    to: "/account/studyDetails/resources",
    text: "Resources"
  },
  {
    to: "/account/studyDetails/events",
    text: "Events"
  },
  {
    to: "/account/studyDetails/studypersonal",
    text: "Study Personnel"
  },
  {
    to: "/account/studyDetails/auditTrail",
    text: "Audit Trail"
  }
]

const StudyDetailRouter = (props) => {

  const { sponsorAccountId, accountName, studyTitle,siteName, studyNumber } = props.formValues;
  return (
    <>
      <div className="d-flex justify-content-end">
        {/* {accountName != "" ?
          <h1 className="mr-auto primary-header">{accountName}:{sponsorAccountId}</h1> : */}
        <h1 className="mr-auto primary-header">{studyNumber}</h1>
        {/* } */}

      </div>

      <NavMenu menus={menus} />

      <div className="">
        <Route exact path="/account/studyDetails" component={ViewStudyDetails} />

        <Route exact path="/account/studyDetails/site" component={SiteGrid} />
        <Route exact path="/account/studyDetails/pag" component={PAGGrid} />
        <Route path="/account/studyDetails/pag/add" component={AddPag} />
        <Route path="/account/studyDetails/pag/update" component={UpdatePag} />
        <Route exact path='/account/studyDetails/arm' component={ArmsGrid} />
        <Route path='/account/studyDetails/arm/Add' component={AddArm} />
        <Route path="/account/studyDetails/arm/View" component={ViewARM} />
        <Route path="/account/studyDetails/arm/Update" component={UpdateARM} />
        <Route exact path="/account/studyDetails/IEcriteria" component={IEcriteriaGrid} />
        <Route path="/account/studyDetails/IEcriteria/Add" component={AddIECriteria} />
        <Route path="/account/studyDetails/IEcriteria/View" component={ViewIECriteria} />
        <Route path="/account/studyDetails/IEcriteria/update" component={UpdateIECriteria} />
        <Route exact path='/account/studyDetails/adverseEvents' component={AdverseEventsGrid} />
        <Route path="/account/studyDetails/adverseEvents/Add" component={AddAE} />
        <Route path="/account/studyDetails/adverseEvents/View" component={ViewAE} />
        <Route path="/account/studyDetails/adverseEvents/update" component={UpdateAE} />
        <Route exact path="/account/studyDetails/rbm" component={RBMGrid} />
        <Route path="/account/studyDetails/rbm/Add" component={AddRBM} />
        <Route path="/account/studyDetails/rbm/View" component={ViewRBM} />
        <Route path="/account/studyDetails/rbm/update" component={UpdateRBM} />
        <Route exact path='/account/studyDetails/courses' component={CoursesGrid} />
        <Route path="/account/studyDetails/courses/Add" component={AddCourses} />
        <Route path="/account/studyDetails/courses/View" component={ViewCourses} />
        <Route path="/account/studyDetails/courses/update" component={UpdateCourses} />
        <Route exact path='/account/studyDetails/resources' component={ResourcesGrid} />
        <Route path="/account/studyDetails/resources/Add" component={AddResources} />
        <Route path="/account/studyDetails/resources/View" component={ViewResources} />
        <Route path="/account/studyDetails/resources/update" component={UpdateResources} />
        <Route path="/account/studyDetails/events" component={EventsGrid} />
        <Route exact path="/account/studyDetails/studypersonal" component={studypersonalGrid} />
        <Route path="/account/studyDetails/studypersonal/add" component={Contacts} />
        <Route path="/account/studyDetails/studypersonal/addnew" component={AddStudyPer} />
        <Route path='/account/studyDetails/site/add' component={AddSite} />
        <Route path='/account/studyDetails/site/siteDetails' component={SiteDetails} />
        <Route path='/account/studyDetails/site/update' component={UpdateSite} />
        <Route path='/account/studyDetails/auditTrail' component={AuditTrail} />
        <Route path="/account/studyDetails/pag/pagDetails" component={PAGDetails} />
        {/* <Route path='/account/studyDetails/site/siteDetails/users' component={SiteDetails} />
        <Route path='/account/studyDetails/site/siteDetails/queries' component={SiteDetails} />
        <Route path="/account/studyDetails/site/siteDetails/tasks" component={SiteDetails} />
        <Route path="/account/studyDetails/site/siteDetails/emails" component={SiteDetails} />
        <Route path="/account/studyDetails/site/siteDetails/notes" component={SiteDetails} />
        <Route path="/account/studyDetails/site/siteDetails/activity" component={SiteDetails} />
        <Route path="/account/studyDetails/site/siteDetails/subjects" component={SiteDetails} />
        <Route path="/account/studyDetails/site/siteDetails/adverseEvents" component={SiteDetails} />
        <Route path="/account/studyDetails/site/siteDetails/addUsers" component={SiteDetails} />

        
        <Route path="/account/studyDetails/site/siteDetails/users/view" component={UpdateSite} /> */}
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudyDetailRouter));




