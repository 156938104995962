import React, { useEffect, useState } from "react";
import Title from './Title';
import EntityMutation from './entitymutations';
import { connect, useSelector, useDispatch } from 'react-redux';
import { ddlist } from '../../../../common/constants';
import SortableEntities from './sortableEntity';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import { appConfig } from '../../../../common/constants'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AddModal from './newpirmodal';
import DeleteModal from './newdeleteModal';
import EditPopover from './neweditmodal';
import uniqid from 'uniqid';
import LinkEntityModal from './newlinkentity';

const defaultCategories = {
  LabDetails: true,
  Condition: true,
  Demographic: true,
  Drug: true,
  Duration: true,
  Education: true,
  // Zipcode:true,
  Language: true,
  Race: true,
  Location:true,
  Value:true
}

function Entity(props) {
  let [open, setOpen] = useState(false)
  let [showEntity, setShowEntity] = useState(false)
  let reduxstateobj = useSelector(state => state);
  //let [data, setData] = useState(props.data || []);
  let [entityData, setEntitydata] = useState();
  const [progress, setProgress] = useState(false);
  let [incbuilthtml, setIncBuiltHTML] = useState();
  let [excbuilthtml, setExcBuiltHTML] = useState();
  let [categories, setCategories] = useState(defaultCategories);
  let [openpopover, setopenpopover] = useState(false);
  let [selectedrange, setselectedrange] = useState();
  let [listEntities, setEntities] = useState([]);
  let [keysTosend, setkeysTosend] = useState({});
  let [editpopover, seteditpopover] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let [clickedItem, setClickedItem] = useState({});
  let [linkmodal, setlinkmodal] = useState(false);

  const reduxdispatch = useDispatch();
  let [incData, setincData] = useState();
  let [excData, setexcData] = useState();

  let incmodifiedObj = {};
  let categoriesList = {};
  useEffect(() => {
    registerClickEvent();
  }, [entityData])

  let [opendeletepopover, setopendeletepopover] = useState(false);

  let selectedText = "";

  React.useEffect(() => {
    if (props.data) {
      setEntitydata(props?.data);
      let incData = Object.keys(props?.data).length>0 && buildDataNewData(props?.data['eligibility criteria']['inclusion_criteria'], "inclusion");
      let excData =Object.keys(props?.data).length>0 && buildDataNewData(props?.data['eligibility criteria']['exclusion_criteria'], "exclusion");

      setincData(incData)
      setexcData(excData)
      // console.log('incData==============>', incData)
      // console.log('excData==============>', excData)

    }
  }, [props.data]);


  useEffect(() => {
    //buildData();
  }, [incData])

  useEffect(() => {
    registerEvent();
    registerSelectionEvent();
    //registerClickEvent();
  })


  function getindex(obj, myarray) {
    let index = '';
    myarray.find((item, inx) => {
      if (obj.text == item.text) {
        index = inx;
      }
    })
    return index;
  }

  const buildDataNewData = (criteriaData, type) => {
    if (criteriaData.length) {
      incmodifiedObj[type] = {};
      let criteriaList = criteriaData.map((sentence, maininx) => {
        sentence.pid = maininx;
        if (sentence.sents.length) {
          let sentsRowObj = {};
          let rowObjDetail = {};
          sentence.sents.map((sentsItem, currentInx) => {
            let itembakup = sentsItem.text;
            sentsItem['textInx'] = currentInx;
            sentsItem['rowinx'] = maininx;
            if (sentsItem.terms && sentsItem.terms.length) {
              sentsRowObj['entities'] = sentsItem.terms;
              sentsItem.terms.map((termobj, terminx) => {
                let startInx = termobj.start_index;
                let endInx = termobj.end_index;
                let isValueThere = endInx+1 - startInx;
                categoriesList[termobj.categorey] = true;
                let linkid = uniqid();
                //console.log('linkid:::', linkid)
                try {
                  if (isValueThere > 0) {
                    let term = sentsItem.text.substr(startInx, isValueThere);
                    //console.log('linkid:::',linkid, 'terminx',terminx,'--term--',term)
                    let toTermid = '';
                    let targetTermid = '';
                    let replaceStr = '';
                    let linkclassname = (termobj.attribute && termobj.attribute.length) ? 'link' : '';
                    replaceStr = `<span class="${termobj.categorey} spanEntity ${linkclassname}" data-rowinx="${maininx}" data-terminx="${terminx}" data-entype="${type}" data-sentinx="${currentInx}" data-linkid="${linkid}">${term}</span>`;
                    itembakup = itembakup.replace(term, replaceStr)
                  }
                } catch (error) {
                  //console.log("error")
                }
                rowObjDetail[currentInx] = itembakup;

                if (termobj.attribute && termobj.attribute.length) {
                  termobj.attribute.map((attrobj, attrinx) => {
                    let startInx = attrobj.start_index;
                    let endInx = attrobj.end_index;
                    let isValueThere = endInx - startInx;
                    categoriesList[attrobj.categorey] = true;
                    //let linkid = Date.now();
                    try {
                      if (isValueThere > 0) {
                        let term = sentsItem.text.substr(startInx, isValueThere);
                        let toTermid = '';
                        let targetTermid = '';
                        let replaceStr = '';
                        replaceStr = `<span class="${attrobj.categorey} spanEntity" data-rowinx="${maininx}" data-terminx="${terminx}" data-entype="${type}" data-sentinx="${currentInx}" data-target-linkid="${linkid}">${term}</span>`;
                        itembakup = itembakup.replace(term, replaceStr)
                      }
                    } catch (error) {
                      //console.log("error")
                    }
                    rowObjDetail[currentInx] = itembakup;

                  });
                }




              })

            } else {
              rowObjDetail[currentInx] = itembakup;
            }

          })
          incmodifiedObj[type][sentence.pid] = rowObjDetail;
        }
      });
      //console.log("-------final---------", incmodifiedObj)
      setCategories({ ...categories, ...categoriesList })
      return incmodifiedObj;
    }
    return {};
  }


  const registerClickEvent = () => {
    try {
      document.querySelectorAll("span.spanEntity").forEach(element => {
        element.addEventListener("click", (e) => {

          e.stopPropagation();
          e.cancelBubble = true;
          e.preventDefault();

          let termInx = element.getAttribute("data-terminx");
          let rowInx = element.getAttribute("data-rowinx");
          let ctiteriatype = element.getAttribute("data-entype");
          let sentInx = element.getAttribute("data-sentinx");

          console.log('calling showedit entity termInx::', termInx, '-----rowInx---------', rowInx, '-------ctiteriatype----', ctiteriatype, '----------sentInx--------', sentInx);
          showEditEntity(e, rowInx, ctiteriatype, termInx, sentInx)
        })
      })
    } catch (error) {
      //console.log('hover error', error);
    }
  }

  const registerEvent = () => {

    try {
      document.querySelectorAll("span.link").forEach(element => {
        element.addEventListener("mouseover", (e) => {
          let targetid = element.getAttribute("data-linkid");
          ////console.log('in--->', targetid)
          if (document.querySelector(`span[data-target-linkid="${targetid}"]`) && document.querySelector(`span[data-target-linkid="${targetid}"]`).classList) {
            document.querySelector(`span[data-target-linkid="${targetid}"]`).classList.add('highlightvalue')
          }
        })
        element.addEventListener("mouseout", () => {
          let targetid = element.getAttribute("data-linkid");
          ////console.log('out--->', targetid)

          if (document.querySelector(`span[data-target-linkid="${targetid}"]`) && document.querySelector(`span[data-target-linkid="${targetid}"]`).classList) {
            document.querySelector(`span[data-target-linkid="${targetid}"]`).classList.remove('highlightvalue')
          }

        })
      })
    } catch (error) {
      //console.log('hover error', error);
    }
  }

  const getSelectedText = () => {
    let selectedText = '';
    // window.getSelection
    if (window.getSelection) {
      selectedText = window.getSelection();
    }
    // document.getSelection
    else if (document.getSelection) {
      selectedText = document.getSelection();
    }
    // document.selection
    else if (document.selection) {
      selectedText = document.selection.createRange().text;
    } else return;
    // To write the selected text into the textarea
    //document.testform.selectedtext.value = selectedText;
    return selectedText;
  }

  const registerSelectionEvent = () => {
    let entityLists = document.getElementById("entityLists");
    if (entityLists) {
      document.addEventListener("selectionchange", () => {
        let selection = getSelectedText();
        selectedText = selection.toString();
      })
    }
  }


  useEffect(() => {
    if (props.showentity) {
      setShowEntity(true);
    } else {
      setShowEntity(false)
    }
  }, [props.showentity])


  const getNewHtml = (incmodifiedObjData, type) => {// type -> inclusion/exclusion
    let joinedArray = []
    if (incmodifiedObjData[type]) {
      let keys = Object.keys(incmodifiedObjData[type]);
      keys.map((keyitem, inx) => {
        let sentenceObj = incmodifiedObjData[type][keyitem];
        sentenceObj['rowinx'] = keyitem;
        joinedArray.push(sentenceObj);
      })
    }

    if (joinedArray.length) {
      return joinedArray.map((sentenceItem, inx) => {
        //console.log('sentenceItemll:::',sentenceItem)
        let rowInx = sentenceItem.rowinx;
        delete sentenceItem.rowinx;
        let sentenceItemKeys = Object.keys(sentenceItem);
        return sentenceItemKeys.map((termItem, termInx) => {
          return (<>
            <section className="pirsection mb-3 d-flex" style={{
              position: 'relative'
            }}>
              <article className="flex-grow-1" dangerouslySetInnerHTML={createMarkup(sentenceItem[termItem])} />
              <div className="text-right d-flex align-items-start ml-2" style={{ gap: '0px' }}>
                <button
                  className="border-0"
                  onClick={(e) => addEntity(e, termInx, type, rowInx)} title="Select text to Add Entity"
                >
                  <i className="fas fa-plus-circle"></i>
                </button>

                <button
                  className="border-0"
                  onClick={(e) => linkEntity(e, termInx, type, rowInx)} title="Link value"
                >
                  <i className="fas fa-link"></i>
                </button>

                <button
                  className="border-0"
                  onClick={(e) => deleteEntity(e, termInx, type, rowInx)} title="Delete Entites"
                >
                  <i className="fas fa-times-circle"></i>
                </button>
              </div>
            </section>
          </>)
        })
      })
    } else {
      return []
    }
  }

  const addEntity = (e, inx, type, rowInx) => {
    e.preventDefault();

    let modifiedData = entityData;
    if (selectedText.length > 0) {
      let selectedObj = {}
      selectedObj['text'] = selectedText;
      selectedObj['entitytype'] = type;
      selectedObj['rowInx'] = rowInx;
      selectedObj['termInx'] = inx;
      selectedObj['type'] = type;
      let startInx = 0;

      if (type == 'inclusion') {
        let text = modifiedData && modifiedData["eligibility criteria"]['inclusion_criteria'][rowInx].sents[inx].text;
        startInx = text.indexOf(selectedText);
      } else {
        let text = modifiedData && modifiedData["eligibility criteria"]['exclusion_criteria'][rowInx].sents[inx].text;
        startInx = text.indexOf(selectedText);
      }

      let endInx = startInx + selectedText.length;
      selectedObj['start_index'] = startInx;
      selectedObj['end_index'] = endInx;

      setopenpopover(true);
      setselectedrange(selectedObj)
    }
  }

  const linkEntity = (e, inx, type, rowInx) => {
    e.preventDefault();
    let modifiedData = entityData;
    if (selectedText.length > 0) {
      let selectedObj = {}
      selectedObj['text'] = selectedText;
      selectedObj['entitytype'] = type;
      selectedObj['rowInx'] = rowInx;
      selectedObj['termInx'] = inx;
      selectedObj['type'] = type;
      let startInx = 0;


      console.log('selectedObjselectedObj::', selectedObj)

      let textObj;
      if (type == 'inclusion') {
        textObj = modifiedData && modifiedData["eligibility criteria"]['inclusion_criteria'][rowInx].sents[inx];

      } else {
        textObj = modifiedData && modifiedData["eligibility criteria"]['exclusion_criteria'][rowInx].sents[inx];
      }
      let text = textObj.text;
      startInx = text.indexOf(selectedText);

      let entities = textObj.terms;

      let endInx = startInx + selectedText.length;
      selectedObj['start_index'] = startInx;
      selectedObj['end_index'] = endInx;


      setEntities([...entities]);

      setlinkmodal(true)
      setselectedrange(selectedObj)
    }

  }


  const deleteEntity = (e, inx, type, rowInx) => {
    e.preventDefault();

    setopendeletepopover(true);
    try {
      let modifiedData = entityData;
      let entities = [];
      if (type == 'inclusion') {
        entities = modifiedData && modifiedData["eligibility criteria"]['inclusion_criteria'][rowInx].sents[inx].terms;
      } else {
        entities = modifiedData && modifiedData["eligibility criteria"]['exclusion_criteria'][rowInx].sents[inx].terms;
      }
      let addkeysTosend = {
        type: type, // inc, exc
        terminx: inx,
        rowInx: rowInx
      }
      setkeysTosend({ ...keysTosend, ...addkeysTosend })
      setEntities([...entities]);
    } catch (error) {
      //console.log('entity display::', error);
    }
  }



  const showEditEntity = (e, rowInx, type, entityInx, sentInx) => {
    e.preventDefault();

    //seteditpopover(true);
    //setAnchorEl(e.currentTarget)
    try {
      rowInx = Number(rowInx)
      let modifiedData = entityData;



      let entities = [];
      if (type == 'inclusion') {
        entities = modifiedData && modifiedData["eligibility criteria"]['inclusion_criteria'][rowInx].sents[sentInx].terms;
      } else {
        entities = modifiedData && modifiedData["eligibility criteria"]['exclusion_criteria'][rowInx].sents[sentInx].terms;
      }

      let clickedItem = entities[entityInx];

      console.log('entities on click::', entities);


      let addkeysTosend = {
        type: type, // inc, exc
        terminx: entityInx,
        rowInx: rowInx,
        sentInx: sentInx
      }
      seteditpopover(true);
      setEntities(entities);
      setkeysTosend({ ...keysTosend, ...addkeysTosend })
      setClickedItem({ ...clickedItem });

    } catch (error) {
      //console.log('entity edit display::', error);
    }


  }

  const closeEditPopover = () => {
    seteditpopover(false);
  }



  const closePopover = () => {
    setopenpopover(false);
    setselectedrange("")
  }

  const closedeletePopover = () => {
    setopendeletepopover(false);
  }

  const closeLinkPopover = () => {
    setlinkmodal(false);
    setselectedrange("")
  }



  const updateContent = (contentobj, linkobj) => {
    setopenpopover(false);
    setselectedrange("")

    let modifiedData = entityData ? entityData : [];

    let { type, rowInx, termInx } = contentobj;

    if (type == 'inclusion') {
      modifiedData && modifiedData["eligibility criteria"]['inclusion_criteria'][rowInx].sents[termInx].terms.push(contentobj)
    } else {
      modifiedData &&  modifiedData["eligibility criteria"]['exclusion_criteria'][rowInx].sents[termInx].terms.push(contentobj)
    }
    let incData = modifiedData && buildDataNewData(modifiedData['eligibility criteria']['inclusion_criteria'], "inclusion");
    let excData = modifiedData &&  buildDataNewData(modifiedData['eligibility criteria']['exclusion_criteria'], "exclusion");
    setincData(incData)
    setexcData(excData)
    setEntitydata({ ...modifiedData });

  }

  const updateDeletedData = (contentobj) => {
    setopendeletepopover(false);
    let incData =contentobj && buildDataNewData(contentobj['eligibility criteria']['inclusion_criteria'], "inclusion");
    let excData = contentobj && buildDataNewData(contentobj['eligibility criteria']['exclusion_criteria'], "exclusion");
    setincData(incData)
    setexcData(excData)

    setEntitydata({ ...contentobj });
  }

  const updateEditedData = (contentobj) => {
    closeEditPopover();
    let incData =contentobj &&  buildDataNewData(contentobj['eligibility criteria']['inclusion_criteria'], "inclusion");
    let excData = contentobj && buildDataNewData(contentobj['eligibility criteria']['exclusion_criteria'], "exclusion");
    setincData(incData)
    setexcData(excData)

    setEntitydata({ ...contentobj });
  }



  const updateLinkContent = (contentobj) => {
    setlinkmodal(false);
    setselectedrange("")

    console.log('596 after link contentobjcontentobjcontentobj::', contentobj)

    let incData = buildDataNewData(contentobj['eligibility criteria']['inclusion_criteria'], "inclusion");
    let excData = buildDataNewData(contentobj['eligibility criteria']['exclusion_criteria'], "exclusion");
    setincData(incData)
    setexcData(excData)
    setEntitydata({ ...contentobj });

  }

  const createMarkup = (str) => {
    return { __html: str }
  }

  const handleSubmit = () => {

    let reqObj = {}
    // reqObj.response = {};
    // reqObj.response.exclude_cond = [];
    // reqObj.response.include_cond = [];

    // Object.keys(entityData).map((item, inx, arr) => {
    //     entityData[item].combinedItems.map((obj, inx) => {
    //         if (obj.cssClass == 'inclusion') {
    //             reqObj.response.include_cond.push(obj)
    //         } else {// exclusion
    //             reqObj.response.exclude_cond.push(obj)
    //         }
    //     })
    // });

    //console.log('reqobj::', reqObj);
    if (props.app && !props.sidebar) {
      if (props.advancedsearch) {
        // props.openSiteSelectionPoup()
        // props.history.push('/account/pir/siteselection')
        props.storeIEcriteria(props.inclusionData, props.exclusionData)
      } else {
        props.history.push('/account/pir/results')
      }
    } else {
      try {
        setProgress(true)
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify(props.data)
        };
        fetch(`${process.env.REACT_APP_AIAPI}pirpreprocess`, requestOptions)
          .then(response => response.json())
          .then((newdata) => {
            // setProgress(false)
            const requestOptions = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
              },
              body: JSON.stringify(newdata)
            };
            fetch(`${process.env.REACT_APP_AIAPI}findpatients`, requestOptions)
              .then(response => response.json())
              .then((patients) => {
                setProgress(false)
                reduxdispatch({ type: 'entitypatientdata', value: patients })
                props.UpdateFormField({ name: "AIPatientsData", value: (patients != null && patients.patients?.data != null) ? patients.patients.data : [] })

                if (props.sidebar) {
                  props.history.push('/site/pir/patientdetail')
                } else {
                  props.history.push('/site/study/pir/patientdetail')
                }

                //
              })

          })

      } catch (error) {
        //console.log('Error on dnd submit::', error)
      }
    }
    //props.history.push('/site/study/pir/searchresult');

  }
  // const showPatientsMatched = async (e) => {
  //   e.preventDefault();
  //   await callFindPatientsApi()

  // }

  // const handleSubmit = async () => {
  //   await callFindPatientsApi()
  //   props.history.push('/site/study/pir/searchresult');
  // }
  return (
    <>

      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div>{selectedText}</div>

      {/* {(showEntity && Object.keys(entityData).length > 0) &&
                <div className="buttonContainer text-right d-flex align-items-center justify-content-end" style={{ marginTop: '-25px' }}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.showall}
                                    onChange={handleChange}
                                    name="showall"
                                    color="primary"
                                />
                            }
                            label="Show All"
                            className="m-0 mr-5"
                        />
                    </FormGroup>
                </div>
            } */}

      <div className="entities row justify-content-between">
        <aside className="col-3 titleClmn d-none">
          <section className="">
            <Title title="Entities" />
          </section>
        </aside>
        <aside className="col-12">
          {showEntity && <>
            <ul className="categoriesLegends">
              {Object.keys(categories).length > 0 && Object.keys(categories).map((item, inx) => {
                return <li className={item}>
                  <i className="fas fa-circle"></i>
                  <span className="ml-1">{item}</span>
                </li>
              })}
            </ul>
          </>}
        </aside>
      </div>


      {showEntity && <div className="row entityContainer" id="entityLists">
        <div className="col-sm-12">

          <section className="d-flex justify-content-between">
          <h6 className="mt-2" style={{ fontWeight: 600 }}>Inclusion Criteria</h6>

            {/* <div className="text-right">
                            <button className="btn btn-primary-blue btn-rounded btn-small pt-1 pr-2 pl-2 pb-1" onClick={(e) => addEntity(e, 'inclusion')}><i className="fas fa-plus"></i></button>
                        </div> */}
          </section>

          {incData["inclusion"] && <>
            {getNewHtml(incData, 'inclusion')}
          </>}



          <section className="d-flex justify-content-between">
            <h6 className="mt-2" style={{ fontWeight: 600 }}>Exclusion Criteria</h6>

            {/* <div className="text-right">
                            <button className="btn btn-primary-blue btn-rounded btn-small pt-1 pr-2 pl-2 pb-1" onClick={(e) => addEntity(e, 'exclusion')}><i className="fas fa-plus"></i></button>
                        </div> */}
          </section>

          {/* {excbuilthtml && <>
                        {getNewHtml(excbuilthtml, 'exc')}
                    </>} */}

          {incData["exclusion"] && <>
            {getNewHtml(incData, 'exclusion')}
          </>}

        </div>
      </div>
      }

      {props.app && !props.sidebar ?
        <div className="mt-3 text-right">
          <button type="button" className="btn btn-primary-blue btn-rounded btn-small" onClick={handleSubmit}>Next</button>
        </div>
        :

        (showEntity && Object.keys(entityData).length > 0) &&
        <div className="buttonContainer mt-3 text-right">
          <button
            type="submit"
            className="btn btn-primary-blue btn-rounded btn-small"
            onClick={handleSubmit}
          >
            Find Patients
          </button>
        </div>
      }


      {/* {open && <EntityMutation
                //inclusion={true}
                open={open}
                dialogclose={dialogclose}
                title={'Add Entity Type'}
                addform={true}
                ddlist={ddlist}
                //ddlist={Object.keys(entityData)}
                allData={entityData}
                updateEntityData={updateEntityData}
                updateEntityDataByEdit={updateEntityDataByEdit}
            >
            </EntityMutation>} */}
      {/* Add Modal */}
      {openpopover && <AddModal
        openpopover={openpopover}
        textobj={selectedrange}
        closePopover={closePopover}
        updateContent={updateContent}
        categories={categories}
        entities={listEntities}
      />}

      {linkmodal && <LinkEntityModal
        openpopover={linkmodal}
        textobj={selectedrange}
        closePopover={closeLinkPopover}
        updateContent={updateLinkContent}
        categories={categories}
        entities={listEntities}
        allData={entityData}
      />}




      {opendeletepopover && <DeleteModal
        openpopover={opendeletepopover}
        entities={listEntities}
        allData={entityData}
        closePopover={closedeletePopover}
        //updateContent={updateContent}
        categories={categories}
        refkeys={keysTosend}
        updateDeletedData={updateDeletedData}
      />}

      {editpopover && <EditPopover
        openpopover={editpopover}
        entities={listEntities}
        allData={entityData}
        anchorEl={anchorEl}
        closePopover={closeEditPopover}
        //updateContent={updateContent}
        categories={Object.keys(categories)}
        refkeys={keysTosend}
        updateEditedData={updateEditedData}
        clickedItem={clickedItem}
      />}

    </>
  )

}

const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Entity));
