import React from 'react';
import { oldgenericApi } from '../../../common/apiconfig'
import CreateSession from '../../../globalcomponents/createsession'
import { connect } from 'react-redux';

export const PatientStudyInfo = async () => {
  let requestBody = {
    query: `query {        
       getStudyProgress(patientId:"${localStorage.getItem('loginUserId')}") {
      studyID
      accountID
      siteID
      scheduleID
    }
 }`
  };
  requestBody = JSON.stringify(requestBody);
  let res = await oldgenericApi('getStudyProgress', requestBody);
  if (!res) {
    // Error handling
    //console.log('Error on getting patient data!')

  } else {
    if (res.data.getStudyProgress.length > 0) {
      console.log(res.data.getStudyProgress, "res.data.getStudyProgress")
      var data = res.data.getStudyProgress

      // for (var i = 0; i < data.length; i++) {
      if (data) {
        var sessioncreated = await CreateSession(data[0].accountID, data[0].siteID, data[0].studyID)
        // props.UpdateFormField({ name: "patientStudyInfo", value: data })
        // if (sessioncreated) {
        //   callEventsApi(data[i].siteID, data[i].studyID, localStorage.getItem('loginUserId'))
        // } else {
        //   alert('session not created')
        // }
        // }
      }
      return data

    } else {
      return []
    }
  }
}


