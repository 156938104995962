import React from 'react'
import UpdateResource from './fileupload/updateCoursecommon'

const UpdateResources=()=>{
  return(
    <>
    <UpdateResource type="Resource" plural="resources" form="updateResource"/>
    </>
  )
}
export default UpdateResources


