import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { apiCall } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Materialtable from '../../../../globalcomponents/materialtable'
import CircularProgress from '@material-ui/core/CircularProgress';
import * as R_Items from '../../recentItems';
import { checkPermission } from '../../../common/utils';



function ListGrid(props) {
  let isAddContact = checkPermission('Contact_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);


  useEffect(() => {

    async function fetchData() {
      const getContacts = `
        sponsorAccountUserId
        sponsorAccountId
        SponsorAccountName
        firstName
        lastName
        email
        phone
        role
        roleId
        position
        language
        invitedBy
        invitedDate
        activated
        primaryContactInd
        createdBy
        createdTs
        modifiedBy
        modifiedTs`;

      let requestBody = {
        query: `query {
          getAllSponsorAccountUser(sponsorAccountId: "${props.formValues.sponsorAccountId}") {
                      ${getContacts}
                }
            }`
      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getAccountUserSearch', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getAllSponsorAccountUser.length) {
          setData(res.data.getAllSponsorAccountUser)
        }
      }
    }
    fetchData();
  }, []);

  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    props.UpdateFormField({ name: "addContactFromContactSearch", value: false })
    if (props.formValues.accountType === "Site") {
      props.history.push('/account/accountDetails/selectContacts');
      props.UpdateFormField({ name: "accountContacts", value: JSON.stringify(data) })
    } else {
      props.history.push('/account/addContact');
    }
  }

  const handleRowClick = async (event, rowData) => {
    setProgress(true);
    let dataToR_Items = {
      id: rowData.sponsorAccountUserId,
      name: '',
      other: ''
    }
    //await R_Items.updateRecentItems(rowData.sponsorAccountUserId,R_Items.rI_contacts);
    //await R_Items.updateRecentItems(dataToR_Items,R_Items.rI_contacts);
    setProgress(false);
    await props.UpdateEntireForm(rowData);
    await props.UpdateFormField({ name: "siteUserHeading", value: "" });
    props.history.push('/account/accountDetails/contacts/view');
  }

  const Columns = [
    {
      title: 'ID', field: 'sponsorAccountUserId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Name', field: 'firstName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500
      },
      render: rowData => rowData.firstName + ' ' + rowData.lastName
    },
    { title: 'Role', field: 'role' },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' },
  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex justify-content-end">
        <div className="mr-auto secondary-header">{props.formValues.primaryContactSearch ? "SELECT CONTACT" : "Contacts"}</div>
        {isAddContact === "1" ?
          <div className="">
            {props.formValues.accountType === "Site" ?
              <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
                SELECT CONTACTS</button>
              : <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
                <i className="fa fa-plus-circle mr-1"></i> ADD CONTACT</button>
            }</div> : ""}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="">

            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListGrid));


