import React from 'react'
import ViewResource from './fileupload/viewCoursecommon'

const ViewResources=()=>{
  return(
    <>
    <ViewResource type="Resource" plural="resources" sponsorStudies={false}/>
    </>
  )
}
export default ViewResources