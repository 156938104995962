import React, { useEffect, useState } from "react";
var month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
function AllergiesDetailCard(props) {
    //console.log(props, "props")
    const data = props.data || {};
    return (
        <section className="mediCard vaccineCard" style={{ 'marginTop': '0px' }}>
            <div className="top d-flex justify-content-between mb-3">
                <div className="flex-grow-1 d-flex align-datas-center">
                    <span>
                        <i className="fas fa-circle ml-1" style={{ color: data.severity === "CRITL" || "CRITU" || "CRITH" ? '#b93900' : props.severity === "mild" ? "#fd9437" : "#ff4e00" }}></i>
                    </span>
                    <span className="ml-2">{props.title}</span>

                </div>
            </div>
            <div className="daterow d-flex justify-content-between">
                <div className="dates d-flex">
                    <span className="label">
                        Start Date
                    </span>
                    <span className="ml-3">
                        {month_names_short[new Date(data.sd).getMonth()] + ' ' + new Date(data.sd).getDate() + ' ' + new Date(data.sd).getFullYear()}
                        {/* {data.sd} */}
                    </span>
                </div>
                <div className="dates d-flex">
                    <span className="label">
                        Category
                    </span>
                    <span className="ml-3">
                        {data.category}
                    </span>
                </div>
            </div>
            <div className="daterow d-flex justify-content-between">
                <div className="dates d-flex">
                    <span className="label">
                        End Date
                    </span>
                    <span className="ml-3">
                        {data.ed}
                    </span>
                </div>
                <div className="dates d-flex">
                    <span className="label">
                        Severity
                    </span>
                    <span className="ml-3">
                        {data.severity}
                    </span>
                </div>
            </div>


            <section className="descDetail mt-4 pt-4">
                <p>
                    <span className="text-muted">Status</span>
                    <span className="text-danger ml-3">{data.t2level}</span>
                </p>
                <p>
                    {data.desc}
                </p>

            </section>
        </section>
    )
}

export default AllergiesDetailCard;