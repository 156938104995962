import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Route, Switch, Link, BrowserRouter as Router, Redirect, NavLink } from 'react-router-dom';
import Disease from './disease';
import Medications from './medications';
import LabData from './labData';
import PatientImmunizations from './patientimmunizations';
import PatientAllergies from './patientAllergies';
import Careplan from './careplan';
import Encounter from './encounter';
import Observation from './observation'
import Procedure from './procedure'
import VitalSigns from './vitalsigns'

import { Menus, NavSubMenu } from '../../navMenu';



const smenus = [
    {
        to: "/site/patient/medicalhistory/disease",
        text: "Medical Conditions",
    },
    {
        to: "/site/patient/medicalhistory/medications",
        text: "Medications",
    },
    {
        to: "/site/patient/medicalhistory/labdata",
        text: "Lab Report",
    },
    {
        to: "/site/patient/medicalhistory/vitalsigns",
        text: "Vital Signs",
    },
    {
        to: "/site/patient/medicalhistory/immunizations",
        text: "Immunizations",
    },
    {
        to: "/site/patient/medicalhistory/allergies",
        text: "Allergies",
    },
   
    {
        to: "/site/patient/medicalhistory/procedure",
        text: "Procedure",
    },
    // {
    //     to: "/site/patient/medicalhistory/careplan",
    //     text: "Careplan",
    // },
    // {
    //     to: "/site/patient/medicalhistory/encounter",
    //     text: "Encounter",
    // },
    // {
    //     to: "/site/patient/medicalhistory/observation",
    //     text: "Observation",
    // },
]




function MHistoryHome(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}

            <section className="mb-3">
                <NavSubMenu menus={smenus} />
            </section>
            <Switch>
            {/* {localStorage.getItem('whichApp')==="Site" ?
            <> */}
                <Route path="/site/patient/medicalhistory/disease">
                    <Disease />
                </Route>
                <Route path="/site/patient/medicalhistory/medications">
                    <Medications />
                </Route>
                <Route path="/site/patient/medicalhistory/labdata">
                    <LabData />
                </Route>
                <Route path="/site/patient/medicalhistory/vitalsigns">
                    <VitalSigns />
                </Route>
                <Route path="/site/patient/medicalhistory/immunizations">
                    <PatientImmunizations />
                </Route>
                <Route path="/site/patient/medicalhistory/allergies">
                    <PatientAllergies />
                </Route>
                <Route path="/site/patient/medicalhistory/careplan">
                    <Careplan />
                </Route>
                <Route path="/site/patient/medicalhistory/observation">
                    <Observation />
                </Route>
                <Route path="/site/patient/medicalhistory/procedure">
                    <Procedure />
                </Route>
                <Route path="/site/patient/medicalhistory/encounter">
                    <Encounter />
                </Route>
                <Redirect to="/site/patient/medicalhistory/disease" />
            </Switch>
        </>
    )

}

export default MHistoryHome;


