import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../../formElements';
import { connect } from 'react-redux';
import { apiCall } from '../../../common/apiconfig';
import { Button } from '@material-ui/core'
import BackIcon from '../../../../img/back1.svg'
import { checkPermission } from '../../../common/utils';
import FormHoc from '../../hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsMultiple } from '../../formElements';
import { ArmDrugUnits, ArmType } from '../../../common/constants';
import Delete from '../../../../img/delete.svg'
import { useConfirm } from "material-ui-confirm";
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField'
import Icon from '@material-ui/core/Icon';
import Autocomplete from '@material-ui/lab/Autocomplete';

// let isEditStudy = checkPermission('Study_Details_Edit', 'Update');
const ViewARM = (propsobj) => {
  const confirm = useConfirm();
  let [data, setData] = useState(propsobj.formValues.armsInfo);
  let [progress, setProgress] = useState(false);
  let [drugs, setDrugs] = useState([])

  useEffect(() => {
    propsobj.UpdateFormField({ name: "armName", value: data.arm.armName })
    propsobj.UpdateFormField({ name: "armType", value: data.arm.type })
    propsobj.UpdateFormField({ name: "armDescription", value: data.arm.description })
    propsobj.UpdateFormField({ name: "armStatus", value: data.arm.status })
  }, [data])

  const hocObj = FormHoc(propsobj);
  const textProps = {
    ...hocObj,
    propsobj
  }
  const autoPropstoType = {
    'options': ArmType,
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }
  const autoPropsToStatus = {
    default: {
      value: 'Active',
      label: 'Active',
    },
    'options': [{
      value: 'Active',
      label: 'Active'
    }, {
      value: 'InActive',
      label: 'InActive',
    }],
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const deleteArm = async (e, index, arm) => {
    confirm({ description: `This will permanently delete ${arm.dosageName}.` })
      .then(() => deleteArmApi(arm, index))
      .catch(() => console.log("Deletion cancelled."));
  }

  const deleteArmApi = async (arm, index) => {
    let res = await armDelfunc(arm.armName)
    if (res && res.data.deleteStudyArmDetails.code === '200') {
      calltosplicedata(arm, index)
    } else {
      //console.log('Error!')
    }
  }
  const calltosplicedata = (arm, index) => {
    console.log(arm, "arm")
    let dataa = { ...data }
    for (var i = 0; i < dataa.armDetails.length; i++) {
      if (dataa.armDetails[i].armDetails == arm.armDetails) {
        dataa.armDetails.splice(i, 1)
      }
    }
    console.log(dataa, "nfewlinks")
    setData(dataa)
  }
  const armDelfunc = async (id) => {
    let requestBody = `
    mutation
    {
      deleteStudyArmDetails(armDetails: "100") {
        code
        type
        message
        attributes
      }
    }`
      ;

    requestBody = JSON.stringify({ query: requestBody });
    let res = await apiCall('deleteStudyArmDetails', requestBody);
    return res
  }

  const addNew = () => { setDrugs([...drugs, { armDrugName: "", armDosage: "", armStrength: "", armUnit: "", armRoute: "", armFrequency: "" }]) }

  const handleChange = (event, index) => {
    console.log(index, "i")
    var products = drugs.slice() //or [...links];
    var newDrugs = products.map((item, i) => {
      if (i == index) {
        if (event.target.name === "armStrength") {
          if (!Number(event.target.value)) {
            event.preventDefault();
          } else {
            item[event.target.name] = event.target.value
          }
        } else {
          item[event.target.name] = event.target.value
        }
      }
      return item
    })
    setDrugs(newDrugs)

  }
  const getUnits = (e, value, i) => {
    console.log(e, value, i)
    var arrDrugs = [...drugs]
    arrDrugs[i]['armUnit'] = value
    setDrugs(arrDrugs)
  }
  const removeDrug = (e, index) => {
    var arr = [...drugs]
    arr.splice(index, 1);
    setDrugs(arr)
  }
  const handleCancel = (event) => {
    event.preventDefault();
    propsobj.history.goBack();
  }
  const saveArm = async (e) => {
    e.preventDefault();
    console.log(drugs, propsobj.formValues, "Save")
    let form = propsobj.formValues;
    let requestBody = `
    mutation {
      updateStudyArm(armNum:"${data.arm.armNum}",type:"${form.armType}",description:"${form.armDescription}",status:"${form.armStatus}") {
        code
        type
        message
        attributes
      }
    }
    `;
    requestBody = JSON.stringify({ query: requestBody });
    let res = await apiCall('updateStudyArm', requestBody, form)

    if (res && res.data != null && res.data.updateStudyArm.code === '200') {
      console.log('added');
      for (var i = 0; i <drugs.length; i++) {
        callArmdetailsApi(drugs[i])
      }
      propsobj.history.push('/account/studyDetails/arm');
    } else {
      console.log('Error!')
    }
  }

  const  callArmdetailsApi=async (drug)=>{
    setProgress(true)
    let requestBody = `
    mutation {
      addStudyArmDetails(armNum:"${data.arm.armNum}", dosageName:"${drug.armDrugName}", dosage:"${drug.armDosage}",strength:"${drug.armStrength}",unit:"${drug.armUnit.value}",route:"${drug.armRoute}",frequency:"${drug.armFrequency}",description:"") {
        code
        type
        message
        attributes
      }
    }
    `;
    requestBody = JSON.stringify({ query: requestBody });
    let res = await apiCall('addStudyArmDetails', requestBody);
    setProgress(false);

    if (res && res.data != null && res.data.addStudyArmDetails.code === '200') {
      console.log('added');
      
    } else {
      console.log('Error!')
    }
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO ADVERSE EVENTS LIST</div><br /> */}

      <div className="d-flex mt-4 justify-content-end">
        <div class="secondary-header mr-auto">Update Arm</div>
      </div>
      <div className="">
        {data &&
          <form noValidate autoComplete="off" id="updateStudyArm" className="row h-form-1">
            <div className="col-md-6">
              <div className="panel-primary-wrap">
                <div className="card">
                  <div className="card-body">

                    <div className="inputBox">
                      <InputField {...textProps} name="armName" label="Arm Name" disabled keyName='value' must={'true'} />
                      <AutoOptions {...autoPropstoType} name="armType" label="Arm Type" keyName='value' must={'true'} />
                      <InputField {...textProps} multiline={true} rows={3} rowsMax={3} name="armDescription" label="Description" must={'true'} type="text" />
                      <AutoOptions {...autoPropsToStatus} name="armStatus" label="Status" keyName='value' must={'false'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="panel-primary-wrap">
                <div className="card">

                  <div className="card-body">
                    {/* <div className="card-page-header" >Arm Details</div> */}
                    <div className="row">
                      <div className="card-page-header col-md-8" style={{ marginTop: "11px" }}>Arm Details</div>
                      <div className="col-md-4 card-page-header fr"><span className="action_div fr" >
                        <button onClick={addNew} type="button" className="btn btn-primary-blue btn-rounded">
                          <i className="fa fa-plus-circle mr-1"></i> Add New Drug</button>
                      </span></div>

                    </div>
                    <div className="inputBox">
                      {data.armDetails.length > 0 || drugs.length > 0 ?
                        <table class="viewCourseLinks">
                          <thead className="">
                            <tr>
                              <th> S.NO</th>
                              <th>Drug Name</th>
                              <th>Dosage</th>
                              <th>Strength</th>
                              <th>Unit</th>
                              <th>Route</th>
                              <th>Frequency</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {

                              data.armDetails.length > 0 ? data.armDetails.map((item, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.dosageName}</td>
                                    <td>{item.dosage}</td>
                                    <td>{item.strength}</td>
                                    <td>{item.units}</td>
                                    <td>{item.route}</td>
                                    <td>{item.frequency}</td>
                                    <td>  <td className="action_div delIconColor" onClick={(e) => deleteArm(e, i, item)}>
                                      <Tooltip title="Delete"><i className="far fa-trash-alt fa-lg"></i></Tooltip></td> </td>
                                  </tr>
                                )
                              }) : ""
                            }
                            {
                              drugs.length > 0 &&
                              drugs.map((item, i) => {
                                return (
                                  <tr>
                                    <td>{data.armDetails.length + i + 1}</td>
                                    <td>
                                      <TextField
                                        value={drugs[i].armDrugName}
                                        type="text"
                                        name="armDrugName"
                                        onChange={(e) => handleChange(e, i)}
                                        style={{ width: "100%" }}
                                      />
                                    </td>
                                    <td>
                                      <TextField
                                        value={drugs[i].armDosage}
                                        type="text"
                                        name="armDosage"
                                        onChange={(e) => handleChange(e, i)}
                                        style={{ width: "100%" }}
                                      />
                                    </td>
                                    <td>
                                      <TextField
                                        // multiline={true}
                                        // rows={1}
                                        // rowsMax={4}
                                        value={drugs[i].armStrength}
                                        type="text"
                                        name="armStrength"
                                        onChange={(e) => handleChange(e, i)}
                                        style={{ width: "100%" }}
                                      />

                                    </td>
                                    <td style={{ width: "15%" }}>
                                      <Autocomplete
                                        id="controlled-demo"
                                        options={ArmDrugUnits}
                                        getOptionLabel={option => option.name ? option.value : ""}
                                        value={drugs[i].armUnit}
                                        disableClearable
                                        // onChange={(e) => handleChange(e, i)}
                                        onChange={(e, value) => getUnits(e, value, i)}
                                        renderInput={(params) => <TextField {...params} margin="none" fullWidth />}
                                      />

                                    </td>
                                    <td>
                                      <TextField
                                        value={drugs[i].armRoute}
                                        type="text"
                                        name="armRoute"
                                        onChange={(e) => handleChange(e, i)}
                                        style={{ width: "100%" }}
                                      />
                                    </td>
                                    <td>
                                      <TextField
                                        value={drugs[i].armFrequency}
                                        type="text"
                                        name="armFrequency"
                                        onChange={(e) => handleChange(e, i)}
                                        style={{ width: "100%" }}
                                      />
                                    </td>
                                    <td className="td_action">
                                      <div className="action_div"  >
                                        <Tooltip title="Remove"><Icon color="error" onClick={(e) => removeDrug(e, i)} > remove_circle</Icon></Tooltip>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table> : <div>No Data Found</div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>
        }
      </div>
      <div className="buttonContainer fr mb-3">
        <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1" onClick={saveArm}>Save</button>
        <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewARM));



