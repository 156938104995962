import React, { useEffect, useState, Component } from "react";
import Loadable from 'react-loadable';
import ContactListGrid from '../account/accountDetails/contactsGrid';
import { withRouter } from 'react-router-dom';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import AddAccount from '../account/addAccount';
import UpdateContact from '../account/updateContact';
import AddContact from '../account/addContact';
import UpdateAccount from '../account/updateAccount';
import UpdateContactProfile from '../account/updateContactProfile';
import AccountSearch from '../account/accountSearch'
import AddStudy from '../account/addStudy'
import { funcDropdown, roles } from '../../common/constants'
import DynamicImport from '../dynamicImport';
import ContactGrid from '../account/contactgrid'
import AccountDetailsHome from '../account/accountDetails/accountDetailsHome'
import StudyDetailsHome from '../account/studyDetails/studyDetailsSubMenu'
import UpdateStudy from '../account/studyDetails/updateStudy'
import ContactsHome from '../account/contact/contactHome'
import CircularProgress from '@material-ui/core/CircularProgress';
import Results from '../account/resultsSearch';
import Profile from '../account/profile'
import UserGrid from '../account/users/usersGrid'
import AddUser from '../account/users/addUser'
import UsersView from '../account/users/home'
import UpdateUser from '../account/users/updateUser'
import Initialpage from '../account/1uphealth/initialpage'
import AdminFeatures from '../adminFeatures/adminFeaturesHome'
import UpdateProfile from '../account/updateprofile'
import { connect, useSelector, useDispatch } from 'react-redux';
import HookForm from "../account/hookform";
import PIR from '../marketplace/pir'
import Opensquare from '../marketplace/opensquare';
import Quicktrial from "../marketplace/quicktrial";
import Shoppingcart from '../marketplace/shoppingcart';
import PaymentScreen from '../marketplace/paymentPage';
import NRSDetailsPage from '../marketplace/NRSdeatilInfo'
import OrderHistory from '../account/orderhistory'
import OrderDetails from '../account/orderdetails'
const AccountPage = (props) => {
  const reduxdispatch = useDispatch();
  useEffect(() => {
    const location = props.location;
    if (location.pathname != "/activateContact") {
      callAPis();
      reduxdispatch({ type: 'toggleSidebar', value: true })
      reduxdispatch({ type: 'toggleHeaderSearch', value: true })
    }

  }, [])
  const callAPis = async () => {
    await funcDropdown()
    await roles()
  }
  //DON'T DELETE THIS COMMENTED ONE

  // const AddContact = (props) => {
  //   return (
  //     <DynamicImport
  //       load={() => import('../account/addContact')}
  //     >
  //       {
  //         (Component) => Component === null ? <h1>UI Script Loading...</h1> : <Component {...props} />
  //       }
  //     </DynamicImport>
  //   )
  // }

  const LoadCom = (props) => {
    console.log('props fileName::', props.fileName);
    let Component = Loadable({
      loader: () => import(`../account/${props.fileName}`),
      loading() {
        return <div className="fullScreen">
          <CircularProgress
            disableShrink
            className="progressCircle"
            value={90}
            size={100}
            thickness={1.5}
          />
          <span>UI Script loading</span>
        </div>
      },
    });
    return <Component {...props} />
  }





  return (
    <>
      {/* <!-- Content Wrapper --> */}
      <div id="content-wrapper" className="d-flex flex-column">
        {/* <!-- Main Content --> */}
        <div id="content">


          {/* <!-- Begin Page Content --> */}
          <div className="container-fluid">
            {/*  <div className="h-breadcrumb-1">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Accounts List</li>
                </ol>
              </nav>
            </div> */}

            <div className="">

              <Route exact path="/searchresults" render={() => (
                <LoadCom fileName="resultsSearch" srcPath="../account/resultsSearch" />
              )} />

              <Route exact path="/account/dashboard" render={() => (
                <LoadCom fileName="dashboard" srcPath="../account/dashboard" />
              )} />

              <Route exact path="/account" render={() => (
                <LoadCom fileName="accountGrid" srcPath="../account/accountGrid" />
              )} />

              <Route exact path="/account/contact" render={() => (
                <LoadCom fileName="contactgrid" srcPath="../account/contact" />
              )} />

              {/* <Route path="/account/addAccount" render={() => (
                <LoadCom fileName="addAccount" srcPath="../account/addAccount" />
              )} /> */}
              <Route path="/account/addAccount" component={AddAccount} />
              <Route path="/account/contactsearch" render={() => (
                <LoadCom fileName="primarycontactsearch" srcPath="../account/primarycontactsearch" />
              )} />

              <Route path="/account/search" render={() => (
                <LoadCom fileName="accountSearch" srcPath="../account/accountSearch" />
              )} />

              {/* <Route path="/account/updateAccount" render={() => (
                <LoadCom fileName="updateAccount" srcPath="../account/updateAccount" />
              )} /> */}
              <Route path="/account/updateAccount" component={UpdateAccount} />
              {/* <Route path="/account/addContact" render={() => (
                <LoadCom fileName="addContact" srcPath="../account/addContact" />
              )} /> */}
              <Route path="/account/addContact" component={AddContact} />
              {/* <Route path="/account/updateContact" render={() => (
                <LoadCom fileName="updateContact" srcPath="../account/updateContact" />
              )} /> */}
              <Route path="/account/updateContact" component={UpdateContact} />
              <Route path="/account/viewContact" render={() => (
                <LoadCom fileName="viewContactDetail" srcPath="../account/viewContactDetail" />
              )} />

              {/* <Route path="/account/addstudy" render={() => (
                <LoadCom fileName="addStudy" srcPath="../account/addStudy" />
              )} /> */}
              <Route path="/account/addStudy" component={AddStudy} />
              {/* <Route path="/account/profile" render={() => (
                <LoadCom fileName="profile" srcPath="../account/profile" />
              )} /> */}
              <Route path="/account/profile" component={Profile} />
              {/* <Route path="/account/studyDetails" render={() => (
                <LoadCom fileName="studyDetailsSubMenu" srcPath="../account/studyDetailsSubMenu" />
              )} /> */}

              {/* <Route path="/account/viewContact/relatedaccounts" render={() => (
                <LoadCom fileName="contactRelatedAccountsGrid" srcPath="../account/contactRelatedAccountsGrid" />
              )} /> */}

              <Route path="/activateContact" component={UpdateContactProfile} />
              <Route path="/account/study" render={() => (
                <LoadCom fileName="studyGrid" srcPath="../account/studygrid" />
              )} />
              <Route path='/account/orderhistory' component={OrderHistory} />
              <Route path='/account/orderdetails' component={OrderDetails} />
              <Route exact path='/account/users' component={UserGrid} />
              <Route path='/account/users/add' component={AddUser} />
              <Route path='/account/users/view' component={UsersView} />
              <Route path='/account/users/update' component={UpdateUser} />
              <Route path='/account/accountDetails' component={AccountDetailsHome} />
              <Route path='/account/studyDetails' component={StudyDetailsHome} />
              <Route path="/account/updateStudy" component={UpdateStudy} />
              <Route path="/account/contact/view" component={ContactsHome} />
              <Route path="/account/1uphealth" component={Initialpage} />
              <Route path="/account/adminfeatures" component={AdminFeatures} />
              <Route path='/account/updateprofile' component={UpdateProfile} />
              <Route path="/account/pir">
                <PIR />
              </Route>
              <Route path="/account/opensquare" component={Opensquare}/>
              <Route path="/account/quicktrial" component={Quicktrial}/>
              <Route path="/account/NRSDetails" component={NRSDetailsPage}/>
              <Route path="/account/shoppingcart" component={Shoppingcart}/>
              <Route path="/account/cartpayment" component={PaymentScreen}/>
              <Route path='/account/hookform' component={HookForm} />
            </div>
          </div>
        </div>
        {/* <!-- End of Main Content --> */}
      </div>
      {/* <!-- End of Content Wrapper --> */}
    </>
  )
}

export default withRouter(AccountPage);


