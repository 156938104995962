import React, { useEffect, useState } from 'react';
import { apiCall } from '../../../../common/apiconfig';
import CommonModal from './comonmodal'
import moment from 'moment'
const OwnerDetails = ({ open, onClose, header, filedata }) => {

  let [data, setData] = useState([])

  useEffect(() => {
    callApi(filedata)
  }, [open])
  const callApi = async (filedata) => {
    let requestBody = `
    query {
      getSupportDetailsById(id: "${filedata.ID}") {
        ID
    buArea
    buid
    type
    status
    detail1
    detail2
    detail3
    detail4
    addedById
    addedByName
    addedTs
        } 
        }`;

    requestBody = JSON.stringify({ query: requestBody });
    // setProgress(true);

    let res = await apiCall('getSupportDetails', requestBody);
    // setProgress(false);
    if (!res) {
      console.log('Error!')
    } else {
      console.log(res, "resapi")
      if (res.data.getSupportDetailsById != null) {
        setData(res.data.getSupportDetailsById)
      } else {
        setData([])
      }
    }
  }

  return (

    <CommonModal header={header} open={open} onClose={onClose}>
      {
        data.length > 0 ?
          <div style={{margin:"15px"}}>
            <div className="row" >
              <div className="col-md-6">
                Owner
        </div>
              <div className="col-md-6">
                {data[0].addedByName}
              </div>
            </div>
            {data.length > 1 &&
              <div className="row">
                <div className="col-md-6">
                  Modified
        </div>
                <div className="col-md-6">
                  {moment(data[0].addedTs).format('LLL')}
                </div>
              </div>
            }

            <div className="row">
              <div className="col-md-6">
                Created
          </div>
              <div className="col-md-6">
                {data.length > 1 ? moment(data[data.length - 1].addedTs).format('LLL') : moment(data[0].addedTs).format('LLL')}
              </div>
            </div></div> : "No data found"
      }
    </CommonModal>
  );
}

export default OwnerDetails