import React, { useEffect, useState, useContext } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as jq from 'jquery';
import QuickLinks from './quickLinks';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';


const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: "#1a3154",
        paddingLeft: "-10px"
    },
    tooltip: {
        backgroundColor: "#1A3154",
    },
}));


function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} placement="right" />;
}


const Sidebar = (props) => {
    let [activeel, setActiveEl] = useState(props.state.sidebarActEl);
    let [menus, setMenus] = useState([
        {
            to: "/admin/dashboard",
            text: "Dashboard",
            name: "dashboard",
            clsNames: "fa fa-tachometer-alt"
        },
        {
            to: "/admin/hospitalflow",
            text: "Hospital Flow",
            name: "hospitalflow",
            clsNames: "fa fa-hospital"
        // },
        // {
        //     to: "/admin/datamapping",
        //     text: "Data Mapping",
        //     name: "datamapping",
        //     clsNames: "fa fa-tachometer-alt"
        // },
        // {
        //     to: "/admin/training",
        //     text: "Training",
        //     name: "training",
        //     clsNames: "fa fa-tachometer-alt"
        }
    ])

    const linkHandler = (e) => {
        let setActive = e.currentTarget;
        let selectedEl = setActive.getAttribute('name');
        console.log(selectedEl, "selected")
        props.sidebarActiveEl(selectedEl);
    }
    return (
        <>

            {/* <!-- Sidebar --> */}
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                {/* <!-- Sidebar - Brand -->
            <!-- Sidebar Toggler (Sidebar) --> */}
                <div className="sidebar-brand d-flex align-items-center d-none d-md-inline">
                    <button className="border-0" id="sidebarToggle"></button>
                </div>

                <QuickLinks menus={menus} setActive={activeel} clickHandler={linkHandler}/>

                {/* <!-- Divider --> */}
                <hr className="sidebar-divider" />
                {/* <!-- Nav Item - Pages Collapse Menu --> */}
               
            </ul>
            {/* <!-- End of Sidebar --> */}
        </>

    )
}
//export default Sidebar;
const mapStateToProps = (state) => {
    return {
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        sidebarActiveEl: (value) => {
            return dispatch(
                {
                    type: 'sidebarActiveEl',
                    value: value,
                }
            )
        },
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        },
        updateCourseData: (obj) => {
            return dispatch(
                {
                    type: 'updateCourseData',
                    value: obj,
                }
            )
        },
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));


