import React, { useState, useEffect, Fragment } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, styled } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import moment from 'moment'
// import Clock from '../img/emailtemplate/clock.svg'
// import User from '../img/emailtemplate/user.svg'
// import Desc from '../img/emailtemplate/content.svg'
// import Meeting from '../img/videochat/meeting.svg'
// import Recording from '../img/videochat/Video-call.svg'

const LightTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid skyblue"
    },
    color: theme.palette.common.white
  },
  tooltip: {
    maxWidth: "none",
    // "&:before": {
    border: "1px solid skyblue",
    // },
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
    marginLeft: "-10px"
  },
}))(Tooltip);

const Lighttooltip = props => {
 

  return (
    <div className="doctooltip" style={{ overflow: 'hidden' }}>
      <LightTooltip TransitionComponent={Zoom} interactive title={
        <div className="" style={{ maxWidth: "500px" }}>
          <div className="" style={{textAlign:"justify",fontSize:"12px"}}>
            {props.data}
          </div>
          {/* <div className="" style={{ fontSize: "12px", borderBottom: "1px solid #e9e5e5" }}>

            <div style={{ display: "flex" }}></div>
          </div> */}
        </div>

      } placement="bottom" arrow>
        <div className="fc-event-title"
          style={{
            fontWeight: "100", marginRight: "4px", cursor: "pointer"
          }}
        > {props.children}</div>
      </LightTooltip >
    </div >
  )
}
export default Lighttooltip;
