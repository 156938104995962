import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import LoaderGif from '../img/loader.gif'
const Loader = ({ progress }) => {

  return (
    <>
      {progress &&
        <div className="fullScreen">
          <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
        {/* <div className="bg-w">
          <img src={LoaderGif} alt="datafetching" />
          </div> */}
        </div>}
    </>
  )
}

export default Loader