import React, { useEffect, useState } from 'react'
import { apiCall, getFiles } from '../../common/apiconfig';
import parse, { domToReact, htmlToDOM } from 'html-react-parser';
import { Dropdowns } from '../../common/constants';
import { AutoOptions } from '../../globalcomponents/formElements';
import { connect } from 'react-redux';
import CreateTemplateCommon from './createtemplatecommon'
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { HekmaTextSearchField } from '../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
// import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const ActiveSwitch = withStyles((theme) => ({
  switchBase: {
    color: "red",
    '&$checked': {
      color: "#52d869",
    },
    '&$checked + $track': {
      backgroundColor: "#52d869",
    },
  },
  checked: {},
  track: {
    borderRadius: 26 / 2,
    border: `red`,
    backgroundColor: "#ff6666",
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
}))(Switch);

const SavedTemplates = (propsobj) => {
  let [progress, setProgress] = useState(false);
  let [data, setData] = useState([])
  let [show, setShow] = useState(true)
  let [content, setContent] = useState([])
  let [valSearch, setValSearch] = useState("")
  let [filterValue, setFilterValue] = useState('*')
  useEffect(() => {
    propsobj.UpdateFormField({ name: "emailtemplatestatus", value: "" })
    callGetTemplatesApi("*", "*")
    setFilterValue("*")
  }, [])
  const callGetTemplatesApi = async (status, searchStr) => {

    let requestBody = `  query {
        searchEmailTemplate(templateId: "*", buArea:"*", buid:"${propsobj.formValues.autosite.sponsorAccountId}", subArea:"*", status:"${status}", name:"${searchStr}") {
            templateId
        buArea
        buId
        subArea
        status
        name
        subject_text
        body_text
          createdBy
          createdTs
          modifiedBy
          modifiedTs
          
        }
      }
        `;

    requestBody = JSON.stringify({ query: requestBody });
    setProgress(true);

    let res = await apiCall('searchEmailTemplate', requestBody);
    setProgress(false);
    if (!res) {
      console.log('Error!')
    } else {
      console.log(res, "Res")
      if (res.data.searchEmailTemplate) {
        setData(res.data.searchEmailTemplate)
      }
    }
  }
  const hideUpdate = () => {
    setShow(!show)
  }
  const editTemplate = (e, item) => {
    e.preventDefault()
    console.log(item, "editcontet")
    setShow(!show)
    setContent(item)

  }
  const autoPropstostatus = {
    'options': [{
      value: "Active",
      label: "Active"
    },
    {
      value: "Draft",
      label: "Draft"
    },
    {
      value: "Inactive",
      label: "Inactive"
    }],
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }
  const handleTemplates = (obj) => {
    console.log(obj.selected)
    setFilterValue(obj.selected.value)
    callGetTemplatesApi(obj.selected.value, valSearch)
    // setContent(obj.selected.body)
  }
  const updateTemplate = (data) => {
    var newdata = { ...content, ...data, ...{ "name": propsobj.formValues.templateName } }
    console.log(newdata, propsobj.formValues.autosite.sponsorAccountId, "data")
    callUpdateApi(newdata)
  }
  const callUpdateApi = async (data) => {
    setProgress(true);
    let requestBody = `
    mutation
          {
            updateEmailTemplate(templateId:"${data.templateId}",buArea: "MailTemplate", buId: "${data.buId}", subArea: "Site Related", status: "${data.status}", name: "${data.name}", subject_text: "", body_text: ${JSON.stringify(data.body_text)}){
              code
              type
              message
              attributes
            }
          }`
    requestBody = JSON.stringify({ query: requestBody });
    let res = await apiCall('updateEmailTemplate', requestBody);
    setProgress(false);

    if (res.data.updateEmailTemplate.code === '200') {
      setShow(true)
      callGetTemplatesApi("*", "*")
      propsobj.UpdateFormField({ name: "emailtemplatestatus", value: "" })
    } else {
      console.log('Error!')
    }
  }
  const deleteTemplate = (e, item) => {
    console.log(item, "item")
    var newdata = { ...item, ...{ "status": item.status === "Inactive" ? "Active" : "Inactive" } }
    callUpdateApi(newdata)
  }
  //search func
  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = (event) => {
    event.preventDefault();
    if (valSearch.length) {
      callGetTemplatesApi(filterValue, valSearch);
    }else{
      callGetTemplatesApi(filterValue,"*")
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    callGetTemplatesApi(filterValue, '*');
    setValSearch("")
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {show ?
        <div className="card mt-4">

          <div className="container row m-3">

            <>
              <div className="col-xs-12 col-sm-12 col-md-4">
                <form noValidate autoComplete="off" onSubmit={searchSubmit} style={{ marginTop: "17px" }}>
                  <HekmaTextSearchField
                    name="accountgridsearch"
                    placeholder="Search Templates"
                    value={valSearch}
                    handleChange={searchHandle}
                    className="gridSearchField"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                          <SearchIcon />
                        </InputAdornment>
                      ),

                      endAdornment: (
                        <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                          X
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <AutoOptions {...autoPropstostatus} name="emailtemplatestatus" label="Filter by" keyName='label' must={'false'} callback={handleTemplates} /><br />
              </div>
              {
                data.length > 0 ? data.map((item) => (
                  <div className="col-md-4 mb-3">
                    <div className="h-100 bordercssemail">
                      <div className="filehistorycss" style={{
                        display: "inline-block",
                        width: "100%"
                      }}>
                        <h6 style={{ float: "left" }}>
                          {item.name}
                        </h6>

                        <div style={{ float: "right", cursor: "pointer",marginRight: "-20px" }} >
                          <Tooltip title={item.status === "Inactive" ? "Click to make it active" : "Click to make it inactive"}>
                            
                            <FormControlLabel
                              control={
                                <ActiveSwitch
                                  checked={item.status === "Active" ? true : false}
                                  onChange={(e) => deleteTemplate(e, item)}
                                  name="checkedB"
                                  // color="primary"
                                  size="small"
                                />
                              }
                            label=""
                            // labelPlacement="start"
                            />
                          </Tooltip>
                        </div>
                        <div style={{ float: "right", cursor: "pointer", marginRight: "10px" }} onClick={(e) => editTemplate(e, item)}>
                          {/* <Tooltip title="Click to edit"> */}
                          <i class="far fa-edit"  ></i>
                          {/* </Tooltip> */}
                        </div>
                      </div>

                      <div style={{ marginLeft: "6px", marginRight: "6px" }} className="htmldata">
                        {parse(item.body_text)}
                      </div>
                    </div>
                  </div>

                )) : "No templates found"
              }</>
          </div>
        </div> :
        <div>
          <CreateTemplateCommon header="Update Template" updateContent={content} updateTemplate={updateTemplate} hideUpdate={hideUpdate} />
        </div>
      }
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedTemplates);
