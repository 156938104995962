import React, { useEffect, useState, useContext } from "react";
import MaterialTable from 'material-table';
//import { apiCall } from '../../../common/apiconfig';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//import { Paginationn } from '../../../../common/pagination'
//import { appConfig } from "../../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
// import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';

// import { genericApi } from '../../../common/apiconfig';
import { NavMenu } from '../../navMenu';
import Registered from '../accounts/registeredstudies'
import Nonregistered from '../accounts/nonRegisteredstudies'


const menus = [
  {
    to: "/site/trailmatch/registeredstudies",
    text: "Registered Studies"
  },
  {
    to: "/site/trailmatch/nonRegisteredstudies",
    text: "Non-Registered Studies"
  }
]

function Trailmatch(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  const [searchData, setSearchData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');

  // useEffect(() => {
  //   getApiData();
  // }, []);
  useEffect(() => {
    // getApiData()
  }, [])


  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex">
        {/* <div class="secondary-header mr-auto">Subjects</div> */}
      </div>
      <section className="mb-3">
        <NavMenu menus={menus} />
      </section>

      <div className="">
        <Switch>
        
              <Route exact path="/site/trailmatch/registeredstudies">
                <Registered />
              </Route>
              {/* <Route path="/site/trailmatch/registeredstudies">
                <Registered />
              </Route> */}
              <Route path="/site/trailmatch/nonRegisteredstudies">
                <Nonregistered />
              </Route>

              <Redirect to="/site/trailmatch/registeredstudies" />   
        </Switch>
      </div>
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Trailmatch);

