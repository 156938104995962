import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import ProblemDetailTable from './problemDetailTable';
import { withRouter } from 'react-router-dom';
import CustomizedProgressBars from './roundedProgress';

function ProblemDetail(props) {
    let [progress, setProgress] = useState(false);
    const navigateTo = () => {
        if (props.app === "Site") {
            if (props.header === "Allergies") {
                props.history.push('/site/patient/medicalhistory/allergies')
            }
            if (props.header === "Medical Conditions") {
                props.history.push('/site/patient/medicalhistory/disease')
            }
            if (props.header === "Medications") {
                props.history.push('/site/patient/medicalhistory/medications')
            }
            if (props.header === "Lab Reports") {
                props.history.push('/site/patient/medicalhistory/labdata')
            }
            if (props.header === "Procedures") {
                props.history.push('/site/patient/medicalhistory/procedure')
            }
        } else if (props.app != "Site" && props.app!="Patient") {
            if (props.header === "Allergies") {
                props.history.push('/app/patient/medicalhistory/allergies')
            }
            if (props.header === "Medical Conditions") {
                props.history.push('/app/patient/medicalhistory/disease')
            }
            if (props.header === "Medications") {
                props.history.push('/app/patient/medicalhistory/medications')
            }
            if (props.header === "Lab Reports") {
                props.history.push('/app/patient/medicalhistory/labdata')
            }
            if (props.header === "Procedures") {
                props.history.push('/app/patient/medicalhistory/procedure')
            }
        } else {
            if (props.header === "Allergies") {
                props.history.push('/patient/medicalhistory/allergies')
            }
            if (props.header === "Medical Conditions") {
                props.history.push('/patient/medicalhistory/disease')
            }
            if (props.header === "Medications") {
                props.history.push('/patient/medicalhistory/medications')
            }
            if (props.header === "Lab Reports") {
                props.history.push('/patient/medicalhistory/labdata')
            }
            if (props.header === "Procedures") {
                props.history.push('/patient/medicalhistory/procedure')
            }
        }


    }
    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}
            <section className="problemDetail dcard pb-0">
                <section className="row" style={{ cursor: "pointer" }} onClick={navigateTo}>
                    <div className="col-md-6 pdetail">
                        <h6 >{props.header}</h6>

                        {/* <span><i className="fas fa-ellipsis-h"></i></span> */}
                    </div>
                    <div className="col-md-6" style={{ textAlign: "right" }}>
                        {props.number != 0 &&
                            <>
                                <img className={props.iColor} src={props.icon} alt="img" /> {props.number} {props.description}
                            </>
                        }
                    </div>
                </section>
                {/* <section className="row">
                    <div className="col-12">
                        <section className="row" style={{cursor:"pointer"}} onClick={navigateTo}>
                            <div className="col-4 d-flex justify-content-center align-items-center">
                                <div className="row">
                                    <div className="col-5 fc">
                                        <span>
                                            <i className={props.icon} style={{ color: props.iColor }}></i>
                                        </span>
                                    </div>
                                    <div className="col-7 sc">
                                        <h6>{props.number}</h6>
                                        <p>{props.text}</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-8">
                                <div className="description">
                                    {props.description}
                                </div>
                            </div> 
                        </section>
                    </div>
                </section> */}
                <section className="row">
                    <div className="col-12">
                        <ProblemDetailTable {...props} navigateTo={navigateTo} />
                    </div>
                </section>
            </section>
        </>
    )

}

export default withRouter(ProblemDetail);


