
import React, { useEffect } from 'react'
import Highcharts from 'highcharts/highstock'
const NewChart = (props) => {
  console.log(props,"piechart")
  useEffect(() => {
    var pieColors = (function () {
      var colors = [],
        base = Highcharts.getOptions().colors[0],
        i;

      for (i = 0; i < 10; i += 1) {
        // Start out with a darkened base color (negative brighten), and end
        // up with a much brighter color
        colors.push(Highcharts.color(base).brighten((i - 3) / 7).get());
      }
      return colors;
    }());
    // Build the chart
    Highcharts.chart('container8', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: props.header
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}% ({point.y})'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          colors: pieColors,
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b><br>{point.percentage:.1f}% ({point.y})',
            distance: -50,
            filter: {
              property: 'percentage',
              operator: '>',
              value: 4
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Percentage Match',
        data: props.data ? props.data :[]
      }]
    });
  }, [props])
 return (
   <div id="container8" ></div>
 )
}


export default NewChart