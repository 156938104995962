import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../../common/apiconfig';
import FormHoc from '../../hoc/formHoc';
import { InputField, AutoOptions } from '../../formElements';
import { Dropdowns } from '../../../common/constants';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip';
import BackIcon from '../../../../img/back1.svg'
import Segments from './Events/segments'
import Events from './Events/events'
import ScheduleMatrix from './Events/schedulematrix'
import { checkPermission } from '../../../common/utils';
function TabContainer(props) {
  return (
    <Typography component="div" className="mt-4">
      {props.children}
    </Typography>
  );
}

const EventsGrid = (props) => {
  let isEditEvents = checkPermission('Events_Edit', 'Update');
  let [progress, setProgress] = useState(false);
  const [value, setValue] = React.useState(0);
  const [segments, setSegments] = React.useState([{ segmentName: "Screening", start: "", end: "", varience: "" },
  { segmentName: "Pre-Apheresis", start: "", end: "", varience: "" },
  { segmentName: "Mobilation", start: "", end: "", varience: "" },
  { segmentName: "Baseline", start: "", end: "", varience: "" }])
  useEffect(() => {
    fetchData()
    fetchEventsData()
  }, []);

  const fetchData = async () => {

    let requestBody = {
      query: `query
    {
      getScheduleDetails(studyId:"${props.formValues.studyId}",scheduleId:"-1")
      {
        schedule {
          scheduleID
          studyId
          scheduleDsc
          effectiveDate
          createdBy
          createdTs
          modifiedBy
          modifiedTs
        }
        scheduleEventList {
          scheduleID
          eventID
          eventType
          eventOrder
          eventName
          eventKey
        }
        scheduleSegmentList {
          scheduleID
          segmentOrder
          segmentName
          start
          end
          varience
        }
        linkRowColumn {
          scheduleID
          rowId
          columnId
        }
      }
    }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await apiCall('getScheduleDetails', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error on response material grid!')

    } else {
      if (res.data.getScheduleDetails.schedule != null && res.data.getScheduleDetails.scheduleEventList.length > 0) {
        await props.UpdateEntireForm(res.data.getScheduleDetails)
        var segmentData = []
        if (res.data.getScheduleDetails.scheduleSegmentList.length > 0) {
          for (var i = 0; i < res.data.getScheduleDetails.scheduleSegmentList.length; i++) {
            var data = res.data.getScheduleDetails.scheduleSegmentList
            var obj = { segmentName: data[i].segmentName, start: data[i].start, end: data[i].end, varience: data[i].varience }
            segmentData.push(obj)
          }
          await props.UpdateEntireForm({ "segments": segmentData })
          await props.UpdateFormField({ name: "tabvalue", value: 2 })
        } else {
          await props.UpdateEntireForm({ "segments": segments })
        }

      } else {
        await props.UpdateEntireForm({ "segments": segments })
        await props.UpdateEntireForm(res.data.getScheduleDetails)
        await props.UpdateFormField({ name: "tabvalue", value: 0 })
      }
    }
  }
  const fetchEventsData = async () => {
    // let requestBody1 = {
    //   query: ` query {
    //   getAllAssessments(searchStr:"*") {
    //     id
    //     assessmentName
    //       }
    // }`
    // };
    // requestBody1 = JSON.stringify(requestBody1);
    // setProgress(true);
    // let res1 = await apiCall('getAllAssessments', requestBody1);
    // setProgress(false);
    // if (!res1) {
    //   // Error handling
    //   console.log('Error!')

    // } else {
    //   if (res1.data.getAllAssessments.length) {
    //     console.log(res1.data.getAllAssessments,"allassements")
    //     props.UpdateEntireForm({ "allAssessments": res1.data.getAllAssessments })
    //   } else {
    //     alert('No Assessments create new')
    //   }
    // }
  }
  const handleTabsChange = (event, newValue) => {
    props.UpdateFormField({ name: "tabvalue", value: newValue })
  };
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="panel-primary-wrap">
        {isEditEvents === "1" ?
          <Tabs
            value={props.formValues.tabvalue}
            onChange={handleTabsChange}
            indicatorColor="primary"
            textColor="primary"
            className="tabs_events"

          >
            <Tab label="Segments" />
            <Tab label="Events" />
            <Tab label="Schedule Matrix" />

          </Tabs>
          :
          <Tab label="Schedule Matrix" />
        }
        {props.formValues.tabvalue === 0 && <TabContainer>
          <>
            <Segments />
          </>
        </TabContainer>
        }
        {props.formValues.tabvalue === 1 && <TabContainer>
          <>
            <Events callScheduleApi={fetchData} callEvents={fetchEventsData} />
          </>
        </TabContainer>
        }
        {props.formValues.tabvalue === 2 && <TabContainer>
          <>
            <ScheduleMatrix />
          </>
        </TabContainer>
        }

      </div>
    </>


  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventsGrid));


