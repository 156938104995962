import React from "react";
import "./css/cardpayment.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkoutform";
import { appConfig } from "../../../common/constants";

// const stripePromise = loadStripe("pk_test_51Jwza1Emk0zCaAlpjUR1A5yw49UcPhRzQWsx7D6yiJdcUoTjfh8G6cVipPt5QAz7zltRuYglwWYVyPkDdmRZyuph00WC4dSz7D");
const stripePromise = loadStripe(appConfig.stripe_publishable_key)

const CardPayment = (props) => {
  return (
    <div className="cardpayment">
      <div className="product">
        <div>
          <Elements stripe={stripePromise} goToDashboard={props.goToDashboard}>
            <CheckoutForm {...props} />
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default CardPayment;
