import React from 'react'
import Loader from '../../img/loader.gif'

const LoaderComp = () => {
  return (
    <>
      <img src={Loader} alt="Loading..." />
    </>
  )
}

export default LoaderComp