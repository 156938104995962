import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MedicalData from './medicaldata';
import Labdata from './labdata';
import MedicalLab from './medical_lab'
import Loader from '../../../common/loader';
import { appConfig } from '../../../common/constants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '80%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordionDetails: {
    display: 'block'
  }
}));

const DataMapping = (props) => {
  const [error, setError] = useState(false)
  const [nctId, setNctId] = useState("")
  const [nctList, setNctList] = useState("")
  const [hId, setHId] = useState("")
  const [hList, setHList] = useState("")
  const [conditions, setConditions] = useState("")
  const [IC, setIC] = useState("")
  const [EC, setEC] = useState("")
  const [lab, setLab] = useState("")
  const [units, setUnits] = useState("")
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [overallData, setOverallData] = useState([])
  const [demo, setDemo] = useState("")
  const optionsIC = ["I-Medical", "I-Lab", "I-Medical_Lab", "Non-Medical"]
  const optionsEC = ["E-Medical", "E-Lab", "E-Medical_Lab", "Non-Medical"]
  const relationOptions = ["and", "or", "both"]
  const genderOptions = ["Male", "Female", "All"]
  const subCriteriaOptions = ["Medical", "Lab"]
  const [message, setMessage] = useState("")
  const [err, setErr] = useState("")
  const [progress, setProgress] = useState(false)

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ "bucket-name": "trial-match-training-fhir" })
    };
    setProgress(true)
    fetch(appConfig.AIApi + 'get_hospitial_study_list', requestOptions)
      .then(response => response.json())
      .then((data) => {
        setProgress(false)
        if (data?.details.length > 0) {
          setHList(data.details)
        } else {
          setHList([])
        }

        console.log(data, "data")
      })
      props.UpdateFormField({name:"hospitalData",value:""})
  }, [])

  const handleChange = (panel) => (event, isExpanded) => {
    // [
    //   {"criteria":" Age 18-65years","type":"Inclusion","criteriaType":"I-Medical","subData":[{"relation":"","condSelected":[]},{"relation":"","condSelected":[]}]},
    //   {"criteria":" Obese BMI >30kg/m2","type":"Inclusion","criteriaType":"I-Lab","subData":[{"addData":[{"parameter":"","gender":"","unit":"","rangeFrom":"","rangeTo":""},{"parameter":"","gender":"","unit":"","rangeFrom":"","rangeTo":""}],"time":"","condition":""}]},
    //   {"criteria":" LDL cholesterol >100 mg/dl","type":"Inclusion","criteriaType":"I-Medical_Lab","subData":[{"subcriteria":"Medical","addData":[{"relation":"","condSelected":[]},{"relation":"","condSelected":[]}],"relation":""},{"subcriteria":"Lab","addData":[{"addSubData":[{"parameter":"","gender":"","unit":"","rangeFrom":"","rangeTo":""},{"parameter":"","gender":"","unit":"","rangeFrom":"","rangeTo":""}],"time":"","condition":""}]}]},
    //   {"criteria":" Written and informed consent signed and dated 5. Not on any vitamin/antioxidants","type":"Inclusion","criteriaType":"Non-Medical","subData":[]}]
    if (panel == 0) {
      setExpanded(isExpanded ? panel : false)
    } else {
      let error = callValidation(panel)
      console.log(panel, isExpanded, overallData, err, "panel")
      if (!error) {
        setExpanded(isExpanded ? panel : false)
      }
    }
  };
  const callValidation = (panel) => {
    var data = overallData[panel - 1]
    let error = false
    if (data.criteriaType === "") {
      error = true
    } else {
      if (data.criteriaType === "I-Medical" || data.criteriaType === "E-Medical") {
        for (let i = 0; i < data.subData.length; i++) {
          if (data.subData[i]["relation"] === "" || data.subData[i]["relation"] === null || data.subData[i]["condSelected"].length == 0) {
            error = true
          }
        }
      } else if (data.criteriaType === "I-Lab" || data.criteriaType === "E-Lab") {
        if (data.subData[0].time === "" || data.subData[0].condition === "" || data.subData[0].condition === null) {
          error = true
        }
        for (let i = 0; i < data.subData[0].addData.length; i++) {
          if (data.subData[0].addData[i]["parameter"] === "" || data.subData[0].addData[i]["gender"] === "" || data.subData[0].addData[i]["unit"] === "" || data.subData[0].addData[i]["parameter"] === null || data.subData[0].addData[i]["gender"] === null || data.subData[0].addData[i]["unit"] === null || data.subData[0].addData[i]["rangeFrom"] === "" || data.subData[0].addData[i]["rangeTo"] === "") {
            error = true
          }
        }

      } else if (data.criteriaType === "I-Medical_Lab" || data.criteriaType === "E-Medical_Lab") {
        // {"criteria":" LDL cholesterol >100 mg/dl","type":"Inclusion","criteriaType":"I-Medical_Lab","subData":[{"subcriteria":"Medical","addData":[{"relation":"","condSelected":[]},{"relation":"","condSelected":[]}],"relation":""},{"subcriteria":"Lab","addData":[{"addSubData":[{"parameter":"","gender":"","unit":"","rangeFrom":"","rangeTo":""},{"parameter":"","gender":"","unit":"","rangeFrom":"","rangeTo":""}],"time":"","condition":""}]}]},
        if (data?.relation === "" || data?.relation === null) {
          error = true
        }

        for (let k = 0; k < data.subData.length; k++) {
          if (data.subData[k].subcriteria === "") {
            error = true
          } else {
            if (data.subData[k].subcriteria === "Medical") {
              for (let i = 0; i < data.subData[k].addData.length; i++) {
                if (data.subData[k].addData[i]["relation"] === "" || data.subData[k].addData[i]["relation"] === null || data.subData[k].addData[i]["condSelected"].length == 0) {
                  error = true
                }
              }
            } else if (data.subData[k].subcriteria === "Lab") {
              if (data.subData[k].addData[0].time === "" || data.subData[k].addData[0].condition === "" || data.subData[k].addData[0].condition === null) {
                error = true
              }
              for (let i = 0; i < data.subData[k].addData[0].addSubData.length; i++) {
                if (data.subData[k].addData[0].addSubData[i]["parameter"] === "" || data.subData[k].addData[0].addSubData[i]["gender"] === "" || data.subData[k].addData[0].addSubData[i]["unit"] === "" || data.subData[k].addData[0].addSubData[i]["parameter"] === null || data.subData[k].addData[0].addSubData[i]["gender"] === null || data.subData[k].addData[0].addSubData[i]["unit"] === null || data.subData[k].addData[0].addSubData[i]["rangeFrom"] === "" || data.subData[k].addData[0].addSubData[i]["rangeTo"] === "") {
                  error = true
                }
              }
            }
          }
        }
      } else {
        error = false
      }
    }
    setErr(error)
    return error

  }
  const onSubmit = (e) => {
    e.preventDefault();
    // var res = {
    //   "conditions": {
    //     "120 actuat fluticasone propionate 0.044 mg/actuat metered dose inhaler": "895994",
    //     "acetaminophen 160 mg chewable": "313820",
    //     "acetaminophen 21.7 mg/ml / dextromethorphan hydrobromide 1 mg/ml / doxylamine succinate 0.417 mg/ml oral solution": "1043400",
    //     "acetaminophen 325 mg oral": "313782",
    //     "acute bronchitis": "J209",
    //     "acute myeloid leukemia, disease": "C920",
    //     "acute viral pharyngitis": "J029",
    //     "admission to orthopedic department": "305428000",
    //     "allergy screening test": "395142003",
    //     "amoxicillin 250 mg / clavulanate 125 mg oral": "562251",
    //     "amoxicillin 500 mg oral": "308192",
    //     "anticipatory guidance": "386516004",
    //     "assessment of anxiety": "710841007",
    //     "assessment of health and social care needs": "710824005",
    //     "assessment of substance use": "428211000124100",
    //     "assessment using alcohol use disorders identification test - consumption": "763302001",
    //     "assessment using car, relax, alone, forget, friends, trouble screening test": "868187001",
    //     "assessment using morse fall scale": "762993000",
    //     "asthma screening": "171231001",
    //     "atopic dermatitis": "L209",
    //     "bacteremia": "A499",
    //     "body mass index 30+ - obesity": "E669",
    //     "bone immobilization": "274474001",
    //     "cetirizine hydrochloride 5 mg oral": "1014676",
    //     "chemotherapy": "367336001",
    //     "chest x-ray": "399208008",
    //     "childhood asthma": "J45909",
    //     "chronic sinusitis": "J329",
    //     "clindamycin 300mg": "284215",
    //     "closed fracture of hip": "S7200",
    //     "concussion with no loss of consciousness": "R410",
    //     "cough": "R05",
    //     "covid-19": "P2889",
    //     "depression screening": "171207006",
    //     "depression screening using patient health questionnaire nine item score": "715252007",
    //     "depression screening using patient health questionnaire two-item score": "454711000124102",
    //     "dyspnea": "R060",
    //     "face mask (physical object)": "261352009",
    //     "fatigue": "R54",
    //     "febrile neutropenia": "R5081",
    //     "fever": "R509",
    //     "fracture of rib": "S2230",
    //     "full-time employment": "nan",
    //     "hydrochlorothiazide 25 mg oral": "310798",
    //     "hydrocortisone 10 mg/ml topical cream": "106258",
    //     "hypertension": "P292",
    //     "ibuprofen 100 mg oral": "198405",
    //     "laceration of foot": "S913",
    //     "limited social contact": "Z608",
    //     "loss of taste": "R438",
    //     "measurement of respiratory function": "23426006",
    //     "medication reconciliation": "430193006",
    //     "nda020503 200 actuat albuterol 0.09 mg/actuat metered dose inhaler": "2123111",
    //     "nda020800 0.3 ml epinephrine 1 mg/ml auto-injector": "1870230",
    //     "neutropenia": "D70",
    //     "not in labor force": "nan",
    //     "otitis media": "H669",
    //     "part-time employment": "nan",
    //     "pelvis x-ray": "268425006",
    //     "plain chest x-ray": "399208008",
    //     "received certificate of high school equivalency": "nan",
    //     "screening for domestic abuse": "866148006",
    //     "screening for drug abuse": "713106006",
    //     "sinusitis": "J329",
    //     "social isolation": "Z604",
    //     "sputum examination": "269911007",
    //     "streptococcal sore throat": "B953",
    //     "stress": "F439",
    //     "suspected covid-19": "Z20822",
    //     "suture open wound": "288086009",
    //     "throat culture": "117015009",
    //     "transfer to stepdown": "449214001",
    //     "victim of intimate partner abuse": "Y079",
    //     "viral sinusitis": "B9789",
    //     "wheezing": "R062"
    //   },
    //   "demo": [
    //     "18-",
    //     2
    //   ],
    //   "exclusions": [
    //     "Proliferative retinopathy or maculopathy",
    //     "Recurrent major hypoglycaemia",
    //     "uncontrolled hypertension or any disease or condition (such as renal, hepatic or cardiac) that according to the judgement of the Investigator makes the subject unsuitable for participation in the trial"
    //   ],
    //   "inclusions": [
    //     "Type 2 diabetes for at least 12 months",
    //     "Currently treated with Oral Antidiabetic Drugs",
    //     "BMI lesser than or equal to 40 kg/m2",
    //     "HbA1c greater than or equal 7.5 and lesser than or equal to 11%"
    //   ],
    //   "lab_parameters": {
    //     "Adenovirus A+B+C+D+E DNA [Presence] in Respiratory specimen by NAA with probe detection": "94040-3",
    //     "American house dust mite IgE Ab in Serum": "6095-4",
    //     "Body Height": "8302-2",
    //     "Body Mass Index": "39156-5",
    //     "Body Weight": "29463-7",
    //     "Body mass index (BMI) [Percentile] Per age and gender": "59576-9",
    //     "Body temperature": "8310-5",
    //     "Cat dander IgE Ab in Serum": "6833-8",
    //     "Cladosporium herbarum IgE Ab in Serum": "6075-6",
    //     "Codfish IgE Ab in Serum": "6082-2",
    //     "Common Ragweed IgE Ab in Serum": "6085-5",
    //     "Cow milk IgE Ab in Serum": "7258-7",
    //     "Diastolic Blood Pressure": "8462-4",
    //     "Egg white IgE Ab in Serum": "6106-9",
    //     "Erythrocyte distribution width [Entitic volume] by Automated count": "21000-5",
    //     "Erythrocytes [#/volume] in Blood by Automated count": "789-8",
    //     "Fall risk level [Morse Fall Scale]": "59461-4",
    //     "Fall risk total [Morse Fall Scale]": "59460-6",
    //     "Generalized anxiety disorder 7 item (GAD-7) total score [Reported.PHQ]": "70274-6",
    //     "Head Occipital-frontal circumference": "9843-4",
    //     "Head Occipital-frontal circumference Percentile": "8289-1",
    //     "Heart rate": "8867-4",
    //     "Hematocrit [Volume Fraction] of Blood by Automated count": "4544-3",
    //     "Hemoglobin [Mass/volume] in Blood": "718-7",
    //     "High Density Lipoprotein Cholesterol": "2085-9",
    //     "Honey bee IgE Ab in Serum": "6844-5",
    //     "Human metapneumovirus RNA [Presence] in Respiratory specimen by NAA with probe detection": "92134-6",
    //     "Influenza virus A Ag [Presence] in Nasopharynx by Rapid immunoassay": "80382-5",
    //     "Influenza virus A RNA [Presence] in Respiratory specimen by NAA with probe detection": "92142-9",
    //     "Influenza virus B Ag [Presence] in Nasopharynx by Rapid immunoassay": "80383-3",
    //     "Influenza virus B RNA [Presence] in Respiratory specimen by NAA with probe detection": "92141-1",
    //     "Latex IgE Ab in Serum": "6158-0",
    //     "Leukocytes [#/volume] in Blood by Automated count": "6690-2",
    //     "Low Density Lipoprotein Cholesterol": "18262-6",
    //     "MCH [Entitic mass] by Automated count": "785-6",
    //     "MCHC [Mass/volume] by Automated count": "786-4",
    //     "MCV [Entitic volume] by Automated count": "787-2",
    //     "Neutrophils [#/volume] in Blood by Automated count": "751-8",
    //     "Oxygen saturation in Arterial blood": "2708-6",
    //     "Pain severity - 0-10 verbal numeric rating [Score] - Reported": "72514-3",
    //     "Parainfluenza virus 1 RNA [Presence] in Respiratory specimen by NAA with probe detection": "92140-3",
    //     "Parainfluenza virus 2 RNA [Presence] in Respiratory specimen by NAA with probe detection": "92139-5",
    //     "Parainfluenza virus 3 RNA [Presence] in Respiratory specimen by NAA with probe detection": "92138-7",
    //     "Patient Health Questionnaire 2 item (PHQ-2) total score [Reported]": "55758-7",
    //     "Patient Health Questionnaire 9 item (PHQ-9) total score [Reported]": "44261-6",
    //     "Patient Health Questionnaire-9: Modified for Teens total score [Reported.PHQ.Teen]": "89204-2",
    //     "Peanut IgE Ab in Serum": "6206-7",
    //     "Percentage area affected by eczema Head and Neck": "66519-0",
    //     "Percentage area affected by eczema Lower extremitiy - bilateral": "66534-9",
    //     "Percentage area affected by eczema Trunk": "66529-9",
    //     "Percentage area affected by eczema Upper extremitiy - bilateral": "66524-0",
    //     "Platelet distribution width [Entitic volume] in Blood by Automated count": "32207-3",
    //     "Platelet mean volume [Entitic volume] in Blood by Automated count": "32623-1",
    //     "Platelets [#/volume] in Blood by Automated count": "777-3",
    //     "Protocol for Responding to and Assessing Patients' Assets, Risks, and Experiences [PRAPARE]": "93025-5",
    //     "Respiratory rate": "9279-1",
    //     "Respiratory syncytial virus RNA [Presence] in Respiratory specimen by NAA with probe detection": "92131-2",
    //     "Rhinovirus RNA [Presence] in Respiratory specimen by NAA with probe detection": "92130-4",
    //     "SARS-CoV-2 RNA Pnl Resp NAA+probe": "94531-1",
    //     "Shrimp IgE Ab in Serum": "6246-3",
    //     "Soybean IgE Ab in Serum": "6248-9",
    //     "Systolic Blood Pressure": "8480-6",
    //     "Tobacco smoking status": "72166-2",
    //     "Total Cholesterol": "2093-3",
    //     "Total score [AUDIT-C]": "75626-2",
    //     "Total score [DAST-10]": "82667-7",
    //     "Total score [HARK]": "76504-0",
    //     "Triglycerides": "2571-8",
    //     "Walnut IgE Ab in Serum": "6273-7",
    //     "Weight-for-length Per age and sex": "77606-2",
    //     "Wheat IgE Ab in Serum": "6276-0",
    //     "White oak IgE Ab in Serum": "6189-5"
    //   },
    //   "units": [
    //     "NA",
    //     "cm",
    //     "{score}",
    //     "kg",
    //     "%",
    //     "mm[Hg]",
    //     "/min",
    //     "10*3/uL",
    //     "10*6/uL",
    //     "g/dL",
    //     "fL",
    //     "pg",
    //     "kU/L",
    //     "kg/m2",
    //     "[degF]",
    //     "Cel",
    //     "{#}",
    //     "mg/dL"
    //   ]
    // }
    setMessage("")

    if (nctId.length > 0) {
      setError(false)
      setProgress(true)
      fetch(`${appConfig.datamapping_url}/get_nct_details`, {
        method: 'POST',
        // mode: 'no-cors',
        headers: {
          // 'Access-Control-Allow-Headers': 'Accept',
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({ "NCT": nctId, "HID": hId })

      }).then(response => response.json())
        .then(responseJson => {
          console.log(responseJson);
          setProgress(false)
          var res = responseJson
          let cond_keys = Object.keys(res.conditions)
          let cond_values = Object.values(res.conditions)
          setDemo(res.demo)
          var cond = []
          for (let i = 0; i < cond_keys.length; i++) {
            var obj = { "title": cond_keys[i], "value": cond_values[i] }
            cond.push(obj)
          }
          let lab_keys = Object.keys(res.lab_parameters)
          let lab_values = Object.values(res.lab_parameters)

          var lab_parameters = []
          for (let i = 0; i < lab_keys.length; i++) {
            var obj = { "title": lab_keys[i], "value": lab_values[i] }
            lab_parameters.push(obj)
          }
          setConditions(cond)
          setIC(res.inclusions)
          setEC(res.exclusions)
          setLab(lab_parameters)
          setUnits(res.units)
          var ICriteria = res.inclusions.map((item, i) => ({ criteria: item, type: "Inclusion", criteriaType: "", subData: [] }))
          var ECriteria = res.exclusions.map((item, i) => ({ criteria: item, type: "Exclusion", criteriaType: "", subData: [] }))
          var combinedData = [...ICriteria, ...ECriteria]
          setOverallData(combinedData)
        })
        .catch((error) => {
          setProgress(false)
          alert('failed')
        })
    } else {
      setError(true)
    }
  }
  const setCriteria = (e, value, name, index) => {
    setErr(false)
    var newdata = overallData.slice() //or [...links];
    var dataa = newdata.map((item, i) => {
      if (i == index) {
        item[name] = value
      }
      if (i == index && (value === "I-Medical" || value === "E-Medical")) {
        item["subData"] = []
        item["subData"].push({ relation: "", condSelected: [] })
      } else if (i == index && (value === "I-Lab" || value === "E-Lab")) {
        item["subData"] = []
        item["subData"].push({ "addData": [{ parameter: "", gender: "", unit: "", rangeFrom: "", rangeTo: "" }], time: "", condition: "" })
      } else if (i == index && (value === "I-Medical_Lab" || value === "E-Medical_Lab")) {
        item["relation"] = ""
        item["subData"] = []
        item["subData"].push({ "subcriteria": "", addData: [], relation: "" })
      }
      return item
    })
    setOverallData(dataa)
  }
  const handleSubChange = (event, value, name, subindex, index, jindex, medlab) => {
    setErr(false)
    var newdata = [...overallData] //or [...links];
    var dataa

    if (medlab) {
      dataa = newdata[index].subData[subindex]["addData"].map((item, i) => {
        newdata[index].subData[subindex]["addData"][jindex][name] = value
        return item
      })
      newdata[index]["subData"][subindex]["addData"] = dataa
    } else {
      dataa = newdata[index].subData.map((item, i) => {
        newdata[index].subData[subindex][name] = value
        return item
      })
      newdata["subData"] = dataa
    }

    setOverallData(newdata)
  }
  const addNewRow = (e, index, i, j, medlab) => {
    setErr(false)
    var newData = [...overallData]
    if (medlab) {
      newData[index].subData[i]["addData"].push({ relation: "", condSelected: [] })
    } else {
      newData[index].subData.push({ relation: "", condSelected: [] })
    }
    setOverallData(newData)
  }
  const removeRow = (e, index, i, j, medlab) => {
    var arr = [...overallData]
    if (medlab) {
      arr[index].subData[i]["addData"].splice(j, 1)
    } else {
      arr[index].subData.splice(i, 1);
    }
    setOverallData(arr)
  }
  const handlelabdatachange = (e, value, name, subindex, index, j, k, medlab) => {
    setErr(false)
    var newdata = [...overallData] //or [...links];
    var dataa
    if (medlab) {
      dataa = newdata[index].subData[subindex]["addData"][j]["addSubData"].map((item, i) => {
        newdata[index].subData[subindex]["addData"][j]["addSubData"][k][name] = value
        return item
      })
      newdata[index]["subData"][subindex]["addData"][j]["addSubData"] = dataa
    } else {
      dataa = newdata[index].subData[0]["addData"].map((item, i) => {
        newdata[index].subData[0]["addData"][subindex][name] = value
        return item
      })

      newdata["subData"] = dataa
    }
    setOverallData(newdata)
  }
  const addLabDataRow = (e, index, i, j, k, medlab) => {
    setErr(false)
    var newData = [...overallData]
    if (medlab) {
      newData[index].subData[i]["addData"][j]["addSubData"].push({ parameter: "", gender: "", unit: "", rangeFrom: "", rangeTo: "" })
    } else {
      newData[index].subData[0]["addData"].push({ parameter: "", gender: "", unit: "", rangeFrom: "", rangeTo: "" })
    }
    setOverallData(newData)
  }
  const removeLabDataRow = (e, index, i, j, k, medlab) => {
    var arr = [...overallData]
    if (medlab) {
      arr[index].subData[i]["addData"][j]["addSubData"].splice(k, 1)
    } else {
      arr[index].subData[0]["addData"].splice(j, 1);
    }
    setOverallData(arr)
  }
  //medical lab
  const setSubCriteria = (e, value, name, subindex, index) => {
    setErr(false)
    var newdata = [...overallData]
    newdata[index]["subData"][subindex][name] = value
    if (value === "Medical") {
      newdata[index]["subData"][subindex]["addData"] = []
      newdata[index]["subData"][subindex]["addData"].push({ relation: "", condSelected: [] })
    } else if (value === "Lab") {
      newdata[index]["subData"][subindex]["addData"] = []
      newdata[index]["subData"][subindex]["addData"].push({ "addSubData": [{ parameter: "", gender: "", unit: "", rangeFrom: "", rangeTo: "" }], time: "", condition: "" })
    }
    setOverallData(newdata)
  }
  const addSubRow = (e, index, i) => {
    setErr(false)
    var newdata = [...overallData]
    newdata[index]["subData"].push({ "subcriteria": "", addData: [] })
    setOverallData(newdata)
  }
  const removeSubRow = (e, index, i) => {
    var newdata = [...overallData]
    newdata[index]["subData"].splice(i, 1)
    setOverallData(newdata)
  }
  const handleSubmit = () => {
    let error = callValidation(overallData.length)
    // console.log(overallData, "alldata")
    // let overallData = [
    //   {
    //     "criteria": "Type 2 diabetes for at least 12 months",
    //     "type": "Inclusion",
    //     "criteriaType": "I-Medical",
    //     "subData": [
    //       {
    //         "relation": "AND",
    //         "condSelected": [
    //           {
    //             "title": "120 actuat fluticasone propionate 0.044 mg/actuat metered dose inhaler",
    //             "value": "895994"
    //           }
    //         ]
    //       },
    //       {
    //         "relation": "OR",
    //         "condSelected": [
    //           {
    //             "title": "acetaminophen 325 mg oral",
    //             "value": "313782"
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   {
    //     "criteria": "Currently treated with Oral Antidiabetic Drugs",
    //     "type": "Inclusion",
    //     "criteriaType": "I-Lab",
    //     "subData": [
    //       {
    //         "addData": [
    //           {
    //             "parameter": {
    //               "title": "Body Height",
    //               "value": "8302-2"
    //             },
    //             "gender": "Male",
    //             "unit": "cm",
    //             "rangeFrom": "100",
    //             "rangeTo": "120"
    //           },
    //           {
    //             "parameter": {
    //               "title": "Body temperature",
    //               "value": "8310-5"
    //             },
    //             "gender": "Female",
    //             "unit": "Cel",
    //             "rangeFrom": "100",
    //             "rangeTo": "102"
    //           }
    //         ],
    //         "time": "kkk",
    //         "condition": "OR"
    //       }
    //     ]
    //   },
    //   {
    //     "criteria": "BMI lesser than or equal to 40 kg/m2",
    //     "type": "Inclusion",
    //     "criteriaType": "I-Medical_Lab",
    //     "subData": [
    //       {
    //         "subcriteria": "Medical",
    //         "addData": [
    //           {
    //             "relation": "AND",
    //             "condSelected": [
    //               {
    //                 "title": "acetaminophen 325 mg oral",
    //                 "value": "313782"
    //               }
    //             ]
    //           },
    //           {
    //             "relation": "OR",
    //             "condSelected": [
    //               {
    //                 "title": "acute bronchitis",
    //                 "value": "J209"
    //               }
    //             ]
    //           }
    //         ]
    //       },
    //       {
    //         "subcriteria": "Lab",
    //         "addData": [
    //           {
    //             "addSubData": [
    //               {
    //                 "parameter": {
    //                   "title": "Body Mass Index",
    //                   "value": "39156-5"
    //                 },
    //                 "gender": "Female",
    //                 "unit": "kg",
    //                 "rangeFrom": "20",
    //                 "rangeTo": "40"
    //               },
    //               {
    //                 "parameter": {
    //                   "title": "Body Weight",
    //                   "value": "29463-7"
    //                 },
    //                 "gender": "Male",
    //                 "unit": "kg",
    //                 "rangeFrom": "70",
    //                 "rangeTo": "90"
    //               }
    //             ],
    //             "time": "11",
    //             "condition": "AND"
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   {
    //     "criteria": "HbA1c greater than or equal 7.5 and lesser than or equal to 11%",
    //     "type": "Inclusion",
    //     "criteriaType": "",
    //     "subData": []
    //   },
    //   {
    //     "criteria": "Proliferative retinopathy or maculopathy",
    //     "type": "Exclusion",
    //     "criteriaType": "E-Medical",
    //     "subData": [
    //       {
    //         "relation": "AND",
    //         "condSelected": [
    //           {
    //             "title": "acetaminophen 325 mg oral",
    //             "value": "313782"
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   {
    //     "criteria": "Recurrent major hypoglycaemia",
    //     "type": "Exclusion",
    //     "criteriaType": "E-Lab",
    //     "subData": [
    //       {
    //         "addData": [
    //           {
    //             "parameter": {
    //               "title": "American house dust mite IgE Ab in Serum",
    //               "value": "6095-4"
    //             },
    //             "gender": "Others",
    //             "unit": "{score}",
    //             "rangeFrom": "20",
    //             "rangeTo": "40"
    //           }
    //         ],
    //         "time": "hh",
    //         "condition": "BOTH"
    //       }
    //     ]
    //   },
    //   {
    //     "criteria": "uncontrolled hypertension or any disease or condition (such as renal, hepatic or cardiac) that according to the judgement of the Investigator makes the subject unsuitable for participation in the trial",
    //     "type": "Exclusion",
    //     "criteriaType": "E-Medical_Lab",
    //     "subData": [
    //       {
    //         "subcriteria": "Medical",
    //         "addData": [
    //           {
    //             "relation": "BOTH",
    //             "condSelected": [
    //               {
    //                 "title": "acetaminophen 325 mg oral",
    //                 "value": "313782"
    //               }
    //             ]
    //           }
    //         ]
    //       },
    //       {
    //         "subcriteria": "Medical",
    //         "addData": [
    //           {
    //             "relation": "OR",
    //             "condSelected": [
    //               {
    //                 "title": "acetaminophen 325 mg oral",
    //                 "value": "313782"
    //               }
    //             ]
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // ]
    setMessage("")
    if (!error) {
      var obj = {
        "NCT": nctId,
        payload: {
          "demo": demo
        }
      }

      var data = overallData.length > 0 && overallData.map((item, i) => {
        if (item.criteriaType === "I-Medical" || item.criteriaType === "E-Medical") {
          var obj = {
            "class": item.criteriaType,
            "concept": item.subData.map((item1, j) => {
              var obj1 = { "relation": item1.relation, "codes": item1.condSelected.map((item2, k) => item2.value) }
              return obj1
            })
          }
          return obj

        } else if (item.criteriaType === "I-Lab" || item.criteriaType === "E-Lab") {
          var objlab = {
            "class": item.criteriaType,
            "concept": {
              "Time": item.subData[0].time, "Condition": item.subData[0].condition, "addData": item.subData[0].addData.map((item2, k) => {
                var obj = {
                  ...item2, "parameter": item2.parameter.title
                }
                return obj
              })
            }
          }
          return objlab
        } else if (item.criteriaType === "I-Medical_Lab" || item.criteriaType === "E-Medical_Lab") {
          var objmedicallab = {
            "class": item.criteriaType,
            "concept": item.subData.map((item1, j) => {
              if (item1.subcriteria === "Medical") {
                // var obj1 = {
                //   "subclass": item1.subcriteria, "subconcept": item1.addData.map((item2, k) => {
                //     var obj2 = { "relation": item2.relation, "codes": item2.condSelected.map((item3, k) => item3.value) }
                //     return obj2

                //   })
                // }
                var obj1 = {
                  "subclass": item1.subcriteria,
                  "relation": item1.addData[0].relation, "codes": item1.addData[0].condSelected.map((item3, k) => item3.value)
                }
                return obj1
              } else {
                // var obj3 = {
                //   "subclass": item1.subcriteria, "subconcept": item1.addData.map((item4, k) => {
                //     console.log(item4, "item4")
                //     var obj4 = {
                //       "Time": item4.time, "Condition": item4.condition, "addData": item4.addSubData.map((item5, k) => {
                //         var obj5 = {
                //           ...item5, "parameter": item5.parameter.title

                //         }
                //         return obj5
                //       })
                //     }
                //     return obj4
                //   })
                // }
                var obj3 = {
                  "subclass": item1.subcriteria, "subconcept":
                  {
                    "Time": item1.addData[0].time, "Condition": item1.addData[0].condition, "addData": item1.addData[0].addSubData.map((item5, k) => {
                      var obj5 = {
                        ...item5, "parameter": item5.parameter.title

                      }
                      return obj5
                    })
                  }


                }
                return obj3
              }
            })

          }
          // console.log(objmedicallab,"objmedicallab")
          var addrelationobj = { ...objmedicallab, "concept": [...objmedicallab.concept, item.relation] }
          // console.log(addrelationobj,"addrelationobj")
          return addrelationobj
        } else {
          var objnonmedical = {
            "class": item.criteriaType,
            "concept": []
          }
          return objnonmedical
        }

      })
      console.log(data, IC, EC, "data")
      for (var i = 0; i < data.length; i++) {
        obj["payload"][overallData[i]["type"][0] + 'C' + (i + 1)] = data[i]
      }
      setProgress(true)
      fetch(`${appConfig.datamapping_url}/map_patient`, {
        method: 'POST',
        // mode: 'no-cors',
        headers: {
          // 'Access-Control-Allow-Headers': 'Accept',
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(obj)

      }).then((response) => {
        if (!response.ok) {
          // error processing
          throw 'Error';
        }
        return response.json()
      })
        .then(responseJson => {
          console.log(responseJson);
          setMessage(responseJson)
          setProgress(false)
          // alert(JSON.stringify(responseJson.message))
        })
        .catch((error) => {
          alert('failed')
          setProgress(false)
        })
      console.log(obj, "obj")
    }
  }
  const changeHId = (e, value) => {
    e.preventDefault()
    setHId(value);
    setNctId("")
  }
  // useEffect(() => {
  //   if (hId) {
  //     const requestOptions1 = {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Access-Control-Allow-Origin': '*',
  //       },
  //       body: JSON.stringify({ "bucket-name": "trial-match", "prefix": `data_mapping_payloads/${hId}/` })
  //     };
  //     setProgress(true)
  //     fetch(appConfig.AIApi + 'get_hospitial_study_list', requestOptions1)
  //       .then(response => response.json())
  //       .then((data) => {
  //         setProgress(false)
  //         console.log(data, "data1")
  //         setNctList(data.details)
  //       })
  //       // .then((err) => {
  //       //   console.log(err)
  //       //   setProgress(false)
  //       //   alert('Api Error')
  //       // })
  //   }
  // }, [hId])
  return (
    <>
      <Loader progress={progress} />
      <form onSubmit={onSubmit}>
        <div className="row mb-3">
          <div className="col-md-4">
            <Autocomplete
              id="combo-box-demo"
              value={hId}
              onChange={(event, newValue) => {
                changeHId(event, newValue)
              }}
              size='small'
              options={hList}
              style={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} label="Select Hospital" variant="outlined" />}
            />
          </div>
          <div className="col-md-4">
            {/* <Autocomplete
              id="combo-box-demo"
              value={nctId}
              onChange={(event, newValue) => {
                setNctId(newValue);
              }}
              size='small'
              options={nctList}
              style={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} label="Select NCT ID" variant="outlined" />}
            /> */}
             <TextField
              size='small'
              variant='outlined'
              error={error}
              id="outlined-error-helper-text"
              label="Enter NCT ID"
              value={nctId}
              onChange={e => { setNctId(e.target.value); setError(false) }}
              fullWidth/>
          </div>

          <div className="col-md-4">
            <div className="buttonContainer" style={{ justifyContent: "center" }}>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">SUBMIT</button>
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={() => { setNctId(""); setOverallData([]); setMessage("") }}>CLEAR</button>
            </div>
          </div>
        </div>
      </form>
      <div className={classes.root}>
        {overallData.length > 0 && overallData.map((item, i) => (

          <Accordion expanded={expanded === i} onChange={handleChange(i)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>{item.criteria}</Typography>
              <Typography className={classes.secondaryHeading}>{item.type}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <div className="row">
                <div className="col-md-4">
                  <Autocomplete
                    id="combo-box-demo"
                    value={item.criteriaType}
                    onChange={(event, newValue) => {
                      setCriteria(event, newValue, "criteriaType", i);
                    }}
                    size='small'
                    options={item.type === "Inclusion" ? optionsIC : optionsEC}
                    style={{ width: "100%" }}
                    renderInput={(params) => <TextField {...params} label="Select Criteria Type" variant="outlined" />}
                  />
                </div>
              </div><br />
              {(item.criteriaType === "I-Medical" || item.criteriaType === "E-Medical") ?
                <MedicalData overallData={overallData} handleChange={handleSubChange} addNewRow={addNewRow} removeRow={removeRow} relationOptions={relationOptions} conditions={conditions} index={i} /> : (item.criteriaType === "I-Lab" || item.criteriaType === "E-Lab") ?
                  <Labdata overallData={overallData} index={i} labdata={lab} genderOptions={genderOptions} units={units} handleChange={handleSubChange} handlelabdatachange={handlelabdatachange} addLabDataRow={addLabDataRow} removeLabDataRow={removeLabDataRow} relationOptions={relationOptions} /> : (item.criteriaType === "I-Medical_Lab" || item.criteriaType === "E-Medical_Lab") &&
                  <> <MedicalLab overallData={overallData} subCriteriaOptions={subCriteriaOptions} setSubCriteria={setSubCriteria} handleChange={handleSubChange} addNewRow={addNewRow} removeRow={removeRow} relationOptions={relationOptions} conditions={conditions} index={i} labdata={lab} genderOptions={genderOptions} units={units} handlelabdatachange={handlelabdatachange} addLabDataRow={addLabDataRow} removeLabDataRow={removeLabDataRow} addSubRow={addSubRow} removeSubRow={removeSubRow} setCriteria={setCriteria} /></>}
              {err ? <div style={{ color: "red" }}>Enter all fields</div> : ""}
            </AccordionDetails>
          </Accordion>
        ))}
        {overallData.length > 0 &&
          <button type="button" className="btn btn-primary-blue btn-rounded btn-small mr-1 mt-3 fr" onClick={handleSubmit}>SUBMIT</button>
        }
      </div><br />
      <div style={{ color: message.status === "Success" ? "green" : "red" }}>{message.message}</div>

    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataMapping));