import React, { useEffect, useState } from "react";
import moment from 'moment';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import 'rc-datetime-picker/dist/picker.min.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import { HekmaTextField } from './formElements';
import { connect } from 'react-redux';
import { ErrorLables } from '../common/lables';

//https://github.com/AllenWooooo/rc-datetime-picker  - document

const HekmaDatePicker = (propsobj) => {
    let [momentobj, setMoment] = useState(moment())
    let dateValue = propsobj.formValues[propsobj.name] || '';
    let minDate = propsobj.minDate ? moment(propsobj.minDate) : null;
    let maxDate = propsobj.maxDate ? moment(propsobj.maxDate) : null;

    const myhandle = () => {
        // this is for text field handlechange callback
        console.log('text field myhandle');
    }

    const myBlur = () => {

    }

    const getDiffrence = (date1, date2) => {
        let a = moment(date1);
        let b = moment(date2);
        let c = a.diff(b, 'days')+1;
        // return c;
        propsobj.UpdateFormField({value:c+" days",name:"studyDuration"});  
    }

    // const diff = getDiffrence(moment(), moment().subtract(3, 'days'));
    // const diff1 = getDiffrence(new Date(), new Date());


    const handleChange = (momentParam, name) => {
        if (momentParam) {
            setMoment(momentParam);
            let obj = {
                name,
                value: momentParam.format('YYYY-MM-DD')
            }
            propsobj.UpdateFormField(obj);
            if(name=='endDt'){
                getDiffrence(momentParam.format('YYYY-MM-DD'),propsobj.formValues.startDt)         
            }
            if(name=='startDt'){
                propsobj.UpdateFormField({name:"endDt",value:""});
                propsobj.UpdateFormField({name:"studyDuration",value:""});
            }
           
            if (propsobj.must === 'true') {
                let errobj = {
                    name,
                    flag: false,
                    text: '',
                    change: true
                }
                propsobj.updateErrorObj(errobj);
            }
        } else {
            // this block for when you clear the date
            setMoment(moment());
            if (propsobj.must === 'true') {
                let errobj = {
                    name,
                    flag: true,
                    text: ErrorLables[name],
                    change: true
                }
                propsobj.updateErrorObj(errobj);
            }
            let obj = {
                name,
                value: ''
            }
            propsobj.UpdateFormField(obj);
            
        }

        if (propsobj.handleChange) {
            //call back handler if any
            propsobj.handleChange(momentParam, name);
        }

    }

    const shortcuts = {
        'Today': moment(),
        'Yesterday': moment().subtract(1, 'days'),
        'Clear': ''
    };

    return (
        <DatetimePickerTrigger
            shortcuts={shortcuts}
            moment={momentobj}
            onChange={(momObj) => { handleChange(momObj, propsobj.name) }}
            showTimePicker={false}
            minDate={minDate}
            maxDate={maxDate}
            closeOnSelectDay={true}
        >
            <HekmaTextField
                {...propsobj}
                label={propsobj.label}
                value={dateValue}
                name={propsobj.name}
                must={propsobj.must}
                type="text"
                handleChange={myhandle}
                handleBlur={myBlur}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <i class="far fa-calendar-check"></i>
                        </InputAdornment>
                    )
                }}
            />
        </DatetimePickerTrigger>
    );
}

const mapStateToProps = (state) => {
    return {
        formValues: state.form,
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        },
        clearForm: () => {
            return dispatch(
                {
                    type: 'ClearForm',
                }
            )
        },
        updateErrorObj: (obj) => {
            return dispatch(
                {
                    type: 'updateErrorObj',
                    flag: obj.flag,
                    name: obj.name,
                    text: obj.text
                }
            )
        },
        updateEntireErrorObj: (errobj, errtextobj) => {
            return dispatch(
                {
                    type: 'updateEntireErrorObj',
                    errobj: errobj,
                    errtextobj: errtextobj,
                }
            )
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HekmaDatePicker);