import { rolesLovApi } from './apiconfig';
import * as yup from "yup";

export const appConfig = {
  uri: `${process.env.REACT_APP_URI}`,
  securityuri:  `${process.env.REACT_APP_SECURITYURI}`,
  ipInfoApi: `${process.env.REACT_APP_IPINFOAPI}`,
  oktaUrl: `${process.env.REACT_APP_OKTAURL}`,
  paginationLimit: 5
}
// export const oktaAuthConfig = {
//   // Note: If your app is configured to use the Implicit Flow
//   // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
//   // you will need to add `pkce: false`
//   issuer: 'https://dev-88398315.okta.com/oauth2/default',
//   clientId: '0oa4lay7sV2t7q1zT5d6',
//   redirectUri: window.location.origin + '/login/callback',
//   scopes: ['openid', 'profile', 'email'],
//   pkce: true,

// };

// export const oktaSignInConfig = {
//   baseUrl: 'https://dev-88398315.okta.com',
//   clientId: '0oa4lay7sV2t7q1zT5d6',
//   redirectUri: window.location.origin + '/login/callback',
//   authParams: {
//     // If your app is configured to use the Implicit Flow
//     // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
//     // you will need to uncomment the below line
//     pkce: true
//   }
// };


export const regExpData = {
  phoneReg: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  urlReg: /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/
}

export const addContactSchema = yup.object().shape({
  accnameshow: yup
    .string(),
  firstName: yup
    .string()
    .matches(/^([^0-9]*)$/, "First name should not contain numbers")
    .required("First name is a required field"),
  mName: yup
    .string()
    .matches(/^([^0-9]*)$/, "Middle name should not contain numbers"),
  lastName: yup
    .string()
    .matches(/^([^0-9]*)$/, "Last name should not contain numbers")
    .required("Last name is a required field"),
  accountName: yup
    .string()
    .required()
    .when("accnameshow", {
      is: true,
      otherwise: yup
        .string()
        .notRequired()
    })

});

export const initialFormState = {
  ssotokens: "",
  accountSearchText: '',
  contactSearchText: "",
  studySearchText: "",
  segments: [],
  username: "",
  userid: "",
  password: "",
  fname: "",
  lname: "",
  mname: "",
  lang: "",
  roles: "",
  phone: "",
  accountName: "",
  email: "",
  forgot_email: "",
  firstName: "",
  lastName: "",
  middleName: "",
  position: "",
  roleId: "",
  //used in update contact
  role: "",
  ///////////////////
  roleName: "",
  language: "",
  sponsorAccountId: "",
  parentAccountSelection: false,
  parentAccountId: "",
  SponsorAccountName: "",
  contactAccountName: "",
  // addAccount
  // accName: "",
  accountType: "",
  parAccount: "",
  status: "",
  duns: "",
  website: "",
  // phone:"",
  // email:"",
  primaryContact: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  country: "",
  countryCode: "US",
  zip: "",
  //editaccount
  primaryContactSearch: false,
  // primaryContact:"",
  //accountsearch
  accountsearch: "",
  //add Study
  studyId: "",
  studyNumber: "",
  parentAccountId: "",
  studyTitle: "",
  nameofIMP: "",
  devPhase: "",
  studySponsor: "",
  status: "",
  primaryEndPoint: "",
  secondaryEndPoint: "",
  expEndPoint: "",
  startDt: "",
  endDt: "",
  studyDuration: "",
  stoppingCriteria: "",
  numberOfSitesPlanned: "",
  numberOfSubPlanned: "",
  route: "",
  regiman: "",
  dosage: "",
  statMethod: "",
  protocolSynopsisActual: "",
  protocolFinalActual: "",
  fdaSubmissionActual: "",
  firstSite_OpenActual: "",
  fpiActualActual: "",
  lpoActualActual: "",
  dbLockActual: "",
  csrActualActual: "",
  protocolSynopsisReceived: "",
  protocolFinalReceived: "",
  fdaSubmissionReceived: "",
  firstSite_OpenReceived: "",
  fpiReceivedReceived: "",
  lpoReceivedReceived: "",
  dbLockReceived: "",
  csrReceivedReceived: "",
  interimAnalysis: "",
  studyFlow: "",
  datepicker: '',
  datepicker1: '',
  parentAccount: "",
  pagName: "",
  siteName: "",
  //add ie criteria
  IE_type: "",
  IE_description: "",
  IE_effectiveDate: "",
  IE_status: "",
  IE_severity: "",
  //add AE
  AE_subjectid: "",
  AE_term: "",
  AE_severity: "",
  AE_causality: "",
  AE_outcome: "",
  AE_startDate: "",
  AE_stopdate: "",
  //edit AE
  patientId: "",
  aeTerm: "",
  severity: "",
  causality: "",
  outcome: "",
  startDate: "",
  stopDate: "",
  //add rbm
  rbm_type: "",
  rbm_description: "",
  rbm_severity: "",
  rbm_status: "",
  //add site
  addsite_onboardStatus: "",
  addsite_limitization: "",
  onboardStatus: "",
  limitization: "",
  //courses
  name: "",
  summary: "",
  category: "",
  type: "",
  language: "",
  version: "",
  cpassword: "",
  //addsiteusers
  position: "",
  //events
  tabvalue: 0,
  addpag_status: "",
  newEvent: "",
  //to show all accounts in studypersonal
  AccountnamesArr: [],
  AccountidsArr: [],
  studypersonalcontacts: [],
  //user
  userfname: "", userlname: "", usermname: "", userlang: "", userrole: "", userposition: "", userphone: "", useraccountName: "", useremail: "", usersponsorAccountId: "",
  //using in selectcontacts for assigning multiple contacts to site
  accountContacts: "",

  armName: "",
  armType: "",
  armDescription: "",
  armStatus: "",
  cart: "",

  Q_title:"",
  Q_Description:"",
  Q_studyID:"",
  Q_status:"",
  Q_severity:"",
  Q_category:"",
  Q_createdby:"",
  Q_resolvedDate:"",
  Q_createddate:"",
  Q_assignedto:"",
  Q_responsefromsite:""
};

export let ErrorObj = {}
for (let i in initialFormState) {
  ErrorObj[i] = false;
}

export const ErrorTexts = { ...initialFormState }


export const develpomentPhase = [
  {
    value: "Phase1",
    label: "Phase1"
  },
  {
    value: "Phase2",
    label: "Phase2"
  },
  {
    value: "Phase3",
    label: "Phase3"
  },
  {
    value: "Phase4",
    label: "Phase4"
  },
  {
    value: "Observational Study",
    label: "Observational Study"
  }
]


export const Causality = [
  {
    label: "Certain",
    value: "Certain"
  },
  {
    label: "Probable/Likely",
    value: "Probable/Likely"
  },
  {
    label: "Possible",
    value: "Possible"
  },
  {
    label: "Unlikely",
    value: "Unlikely"
  },
  {
    label: "Conditional/Unclassified",
    value: "Conditional/Unclassified"
  },
  {
    label: "Unassessable/Unclassifiable",
    value: "Unassessable/Unclassifiable"
  },
]
export const AE_Outcome = [
  {
    label: "Recovered/Resolved",
    value: "Recovered/Resolved"
  },
  {
    label: "Recovered with sequel",
    value: "Recovered with sequel"
  },
  {
    label: "Ongoing",
    value: "Ongoing"
  },
  {
    label: "Ongoing with change",
    value: "Ongoing with change"
  },
  {
    label: "Fatal",
    value: "Fatal"
  },
  {
    label: "Unknown",
    value: "Unknown"
  },
  {
    label: "Others",
    value: "Others"
  }
]

export const Q_status=[{label:"ReOpen",value:"ReOpen"},{label:"Resolved",value:"Resolved"}]

export const Q_severity=[{label:"Mild",value:"Mild"},{label:"Moderate",value:"Moderate"},{label:"Severe",value:"Severe"}]

export const Q_category=[{label:"Informed Consent",value:"Informed Consent"},{label:"Inc/Exc Criteria",value:"Inc/Exc Criteria"},{label:"Subject Safety",value:"Subject Safety"},{label:"IP Administration",value:"IP Administration"},{label:"Blood Sample Collection and Processing",value:"Blood Sample Collection and Processing"},{label:"Others",value:"Others"}]

export const Races = [
  { name: "White/Caucasian", value: "White/Caucasian" },
  { name: "Black/African–American", value: "Black/African–American" },
  { name: "Asian", value: "Asian" },
  { name: "Native Hawaiian/Pacific Islander", value: "Native Hawaiian/Pacific Islander" },
  { name: "American–Indian/Native Alaskan", value: "American–Indian/Native Alaskan" },
  { name: "Hispanic/Latino", value: "Hispanic/Latino" },
  { name: "Other/unknown", value: "Other/unknown" }
]

export const ArmType=[
  { name: "Experimental", value: "Experimental"},
  { name: "Active Comparator", value: "Active Comparator"},
  { name: "Placebo Comparator", value: "Placebo Comparator"},
  { name: "Sham Comparator", value: "Sham Comparator"},
  { name: "No Intervention", value: "No Intervention"},
  { name: "Other", value: "Other"}
]

export const ArmDrugUnits=[
  { name: "mg", value: "mg"},
  { name: "/cmm", value: "/cmm"},
  { name: "Pg", value: "Pg"},
  { name: "X 10^12/L", value: "X 10^12/L"},
  { name: "X 10^9/L", value: "X 10^9/L"},
  { name: "bpm", value: "bpm"},
  { name: "cells/cmm", value: "cells/cmm"},
  { name: "fL", value: "fL"},
  { name: "g/dL", value: "g/dL"},
  { name: "gm", value: "gm"},
  { name: "mill/c.mm", value: "mill/c.mm"},
  { name: "ml", value: "ml"},
  { name: "percentage(%)", value: "percentage(%)"},
]

export const Dropdowns = {
  Account_Type: [],
  Account_Status: [],
  Study_Status: [],
  Contact_Role: [],
  Contact_Status: [],
  SQ: [],
  Study_Site_Onboard_Status: [],
  Study_Site_Primary_Contact_Role: [],
  Protocol_Setup_IE_Criteria_Type: [],
  Protocol_Setup_IE_Criteria_Status: [],
  Protocol_Setup_AE_Type: [],
  Protocol_Setup_RBM_Severity: [],
  Languages: [],
  Type: [{ name: "General", value: "General" }, { name: "Study-related", value: "Study-related" }],
  Category: [{ name: "Sponsor", value: "Sponsor" }, { name: "Site", value: "Site" }, { name: "Subject", value: "Subject" }],
}
export const roles = async () => {
  let requestBody = {
    query: `query {
          getRoles(roleId:"*") {
            roleId
            roleName
            createdTs
          }
        }`
  };

  requestBody = JSON.stringify(requestBody);
  let res = await rolesLovApi('getRoles', requestBody);
  if (!res) {
    // Error handling
    console.log('Error fetching roles!')

  } else {
    Dropdowns.Roles = []
    if (res.data.getRoles != null && res.data.getRoles.length > 0) {
      Dropdowns.Roles = res.data.getRoles
      //   setData(res.data.getRoles)
    } else {
      Dropdowns.Roles.push([])
    }
  }
}
export const funcDropdown = async () => {
  let requestBody = {
    query: `query {
            getAllLovs(searchStr:"*") {
                id:lovKey
                lovType
                value:value1
                name:value1
                sequence:value3
                  }
            }`
  };

  requestBody = JSON.stringify(requestBody);
  let res = await rolesLovApi('getAllLovs', requestBody);
  if (!res) {
    // Error handling
    console.log('Error!')

  } else {
    if (res.data.getAllLovs != null && res.data.getAllLovs.length) {
      var output = res.data.getAllLovs
      Dropdowns.Account_Type = []
      Dropdowns.Account_Status = []
      Dropdowns.Study_Status = []
      Dropdowns.Contact_Role = []
      Dropdowns.Contact_Status = []
      Dropdowns.SQ = []
      Dropdowns.Study_Site_Onboard_Status = []
      Dropdowns.Study_Site_Primary_Contact_Role = []
      Dropdowns.Protocol_Setup_IE_Criteria_Type = []
      Dropdowns.Protocol_Setup_IE_Criteria_Status = []
      Dropdowns.Protocol_Setup_AE_Type = []
      Dropdowns.Protocol_Setup_RBM_Severity = []
      Dropdowns.Languages = []
      for (var i = 0; i < output.length; i++) {
        if (output[i].lovType === "Account_Type") {
          Dropdowns.Account_Type.push(output[i])
        } else if (output[i].lovType === "Account_Status") {
          Dropdowns.Account_Status.push(output[i])
        } else if (output[i].lovType === "Study_Status") {
          Dropdowns.Study_Status.push(output[i])
        } else if (output[i].lovType === "Contact_Role") {
          Dropdowns.Contact_Role.push(output[i])
        } else if (output[i].lovType === "Contact_Status") {
          Dropdowns.Contact_Status.push(output[i])
        } else if (output[i].lovType === "SQ") {
          Dropdowns.SQ.push(output[i])
        } else if (output[i].lovType === "Study_Site_Onboard_Status") {
          Dropdowns.Study_Site_Onboard_Status.push(output[i])
        } else if (output[i].lovType === "Study_Site_Primary_Contact_Role") {
          Dropdowns.Study_Site_Primary_Contact_Role.push(output[i])
        } else if (output[i].lovType === "Protocol_Setup_IE_Criteria_Type") {
          Dropdowns.Protocol_Setup_IE_Criteria_Type.push(output[i])
        } else if (output[i].lovType === "Protocol_Setup_RBM_Severity") {
          Dropdowns.Protocol_Setup_RBM_Severity.push(output[i])
        } else if (output[i].lovType === "Protocol_Setup_IE_Criteria_Status") {
          Dropdowns.Protocol_Setup_IE_Criteria_Status.push(output[i])
        } else if (output[i].lovType === "Languages") {
          Dropdowns.Languages.push(output[i])
        } else if (output[i].lovType === "Protocol_Setup_AE_Type") {
          Dropdowns.Protocol_Setup_AE_Type.push(output[i])
        }
      }
    }
  }
}

