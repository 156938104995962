import React, { Component, useEffect } from 'react';

export function Critical(props) {
    return (
        <>
            <section className="critical">
                {props.text == 'critical' && <div className="">
                    <div className="row">
                        <div className="col-2 pt-2">
                            <lable className="first severe"></lable>
                            {/* <lable className="second"></lable>
                            <lable className="third"></lable> */}
                        </div>
                        <div className="col-8" style={{paddingLeft:"4px"}}>
                            <span>{props.text}</span>
                        </div>
                    </div>
                </div>
                }

                {props.text == 'mild' && <div className="">
                    <div className="row">
                        <div className="col-2 pt-2">
                            <lable className="mild"></lable>
                        </div>
                        <div className="col-8" style={{paddingLeft:"4px"}}>
                            <span>{props.text}</span>
                        </div>
                    </div>
                </div>}

                {props.text == 'moderate' && <div className="">
                    <div className="row">
                        <div className="col-2 pt-2">
                            <lable className="moderate"></lable>
                            {/* <lable className="second"></lable> */}
                        </div>
                        <div className="col-8" style={{paddingLeft:"4px"}}>
                            <span>{props.text}</span>
                        </div>
                    </div>
                </div>}
                {props.text != 'critical' &&
                    <div className="col-8" style={{paddingLeft:"0px"}}>
                        <span>{props.text}</span>
                    </div>
                }

            </section>
        </>
    );
}
