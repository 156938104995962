import React, { useEffect, useState } from "react";
import Materialtable from '../../../../globalcomponents/materialtable';
import { apiCall } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';


function ContactRelatedStudyListGrid(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);


  useEffect(() => {

    async function fetchData() {
      const getAccountStudies = `
      studyId
studyNumber
parentAccountId
studyTitle
nameofIMP
devPhase
status
primaryEndPoint
secondaryEndPoint
expEndPoint
startDt
endDt
studyDuration
stoppingCriteria
numberOfSitesPlanned
numberOfSubPlanned
route
regiman
dosage
statMethod
interimAnalysis
studyFlow
protocolSynopsisActual
protocolFinalActual
fdaSubmissionActual
firstSite_OpenActual
fpiActualActual
lpoActualActual
dbLockActual
csrActualActual
protocolSynopsisReceived
protocolFinalReceived
fdaSubmissionReceived
firstSite_OpenReceived
fpiReceivedReceived
lpoReceivedReceived
dbLockReceived
csrReceivedReceived
createdBy
createdTs
modifiedBy
modifiedTs`;

      let requestBody = {
        query: `query {
          getStudysOfUser(sponsorAccountUserId:"${props.formValues.sponsorAccountUserId}") {
                      ${getAccountStudies}
                }
            }`
      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getStudysOfUser', requestBody);
      console.log(res, "study")
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getStudysOfUser.length) {
          setData(res.data.getStudysOfUser)
        }
      }
    }
    fetchData();
  }, []);

  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    props.history.push('/account/addStudy');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    await props.UpdateEntireForm(rowData);
    props.history.push('/account/studyDetails');
  }
  String.prototype.limit = function (length) {
    return this.length > length ? (this.substring(0, length) + '...') : this;
  }
  const Columns = [
    {
      title: 'Protocol Number', field: 'studyNumber',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Protocol Title', field: 'studyTitle',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: '25%'
      },
      render: rowData => rowData.studyTitle.limit(30)
    },
    {
      title: 'Phase', field: 'devPhase'
    },
    { title: "IP", field: 'nameofIMP', render: rowData => rowData.nameofIMP.limit(30) },
    { title: 'Status', field: 'status' },
  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex justify-content-end mt-4">
        <div className="mr-auto secondary-header">Related Studies</div>
        {/* <div className="">
          <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
            <i className="fa fa-plus-circle mr-1"></i> ADD STUDY</button>
        </div> */}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="">
            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactRelatedStudyListGrid));


