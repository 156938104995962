import React, { useState, useRef } from 'react'
import Logo from '../../../../img/emailtemplate/emailLogo.jpg'
import Text from '../../../../img/emailtemplate/text.png'
import Image from '../../../../img/emailtemplate/image.png'
import JoditEditor from "jodit-react";
import { withRouter } from 'react-router-dom';
import FileUpload from '../../../../globalcomponents/admincomponents/imageUpload'
const SingleLayout = () => {
  const editor = useRef(null)
  const [content, setContent] = useState('')
  const [show, setShow] = useState(true)
  const config = {
    readonly: false // all options from https://xdsoft.net/jodit/doc/
  }
  const newhandle = (e) => {
    //console.log(e)
  }
  const onBlur = (newContent) => {
    //console.log(newContent)
    setContent(newContent);
  }
  const openLibrary = () => {

  }
  const showLibrary = () => {
    setShow(!show)
  }
  return (
    <>

      <div className="row emaillayout">
        <div className="col-md-6 mt2rem">
          <div className="card">
            <div className="container">
              <div className="m-5 center">
                <img src={Logo} width="400" />
              </div>
              <div className="mailBody">
                <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  onChange={(e) => { newhandle(e) }}
                  onBlur={(evt) => onBlur(evt.target.innerHTML)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mt2rem">

          <div className="card">
            {show ?
              <div className="row cardimages">
                <div className="col-md-3">
                  <img src={Text} />
                </div>
                <div className="col-md-3" onClick={openLibrary}>
                  <img src={Image} onClick={showLibrary} />
                </div>
              </div> :
              <div className="m-3 bb">
                <h4 style={{ float: "left" }}>LIBRARY</h4>
                <span style={{ float: "right" }}>
                  <FileUpload text="Upload Image" dataToAccept='image/*' item="" type="Document" buArea="Resource" buId="" callUpdateApi="" loading="" /> <button className="btn btn-small btn-primary-blue btn-shadow btn-rounded" onClick={showLibrary}>
                       BACK</button>
                </span>
              </div>

            }
          </div>
        </div>
      </div>
    </>
  )
}
export default withRouter(SingleLayout)