import React, { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip';
const SmartText = ({ text, length }) => {
  const [showLess, setShowLess] = React.useState(true);

  if (text.length < length) {
    return <span>{text}</span>;
  }

  return (
    <div>
      {showLess ? `${text.slice(0, length)}...` : text}
      <a
        style={{ color: "blue", cursor: "pointer" }}
        onClick={() => setShowLess(!showLess)}
      >
        &nbsp;{showLess ? <><Tooltip title="view more" arrow><span>{">>"}</span></Tooltip></> : <><Tooltip title="view less" arrow><span>{"<<"}</span></Tooltip></>}
      </a>
    </div>
  );
};
export default SmartText