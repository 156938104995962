import React, { useEffect, useState, useReducer } from "react";
import { BrowserRouter } from 'react-router-dom';
import Routing from './commonrouter';
import { ConfirmProvider } from "material-ui-confirm";
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { appConfig } from './common/constants';
import { reducer } from "./redux/reducer";
import { Provider } from 'react-redux';
import { legacy_createStore as createStore } from 'redux';
const store = createStore(reducer);

const client = new ApolloClient({
    uri: appConfig.uri
});

const App = () => {
    //const [reduxstate, dispatch] = useReducer(reducer, initialState);
    return (
        <ApolloProvider client={client}>
            <Provider store={store}>
                <BrowserRouter>
                    <ConfirmProvider defaultOptions={{
                        confirmationButtonProps: { autoFocus: true }
                    }}>
                        <Routing />
                    </ConfirmProvider>
                </BrowserRouter>
            </Provider>
        </ApolloProvider>
    )
}
export default App;





