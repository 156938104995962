import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
//import MTGrid from '../reacttableGrid';
import AccountGrid from '../account/accountGrid';
import ContactGrid from '../account/contactgrid';
import StudyGrid from '../account/studyGrid';


function ResultsSearch(props) {
  useEffect(() => {
    // return () => {
    //   if (!props.match.isExact) {
    //     props.updateHeaderSearchValue({ name: "headerSearch", value: "" })
    //   }
    // };
  }, []);
  return (
    <>
      <AccountGrid
        searchKey={props.state.headerSearchValue}
      />

      <StudyGrid
        searchKey={props.state.headerSearchValue}
      />

      <ContactGrid
        searchKey={props.state.headerSearchValue}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateHeaderSearchValue: (obj) => {
      return dispatch(
        {
          type: 'headerSearch',
          value: obj.value,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultsSearch));


