import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../../../globalcomponents/formElements';
import { connect } from 'react-redux';
import { oldgenericApi } from '../../../common/apiconfig';
import { checkPermission } from '../../../common/utilis';

const UserDetails = (propsobj) => {
  let isEditUser = checkPermission('S_Site_Users_Update', 'Update');
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [sponsorAccountUserIdd, setsponsorAccountUserId] = useState(propsobj.formValues.sponsorAccountUserId)
  useEffect(() => {
    async function getApiData() {
      fetchData();
    }
    getApiData()
  }, [sponsorAccountUserIdd]);
  async function fetchData() {
    let requestBody = {
      query: `
              query {
                  getsponsorAccountUser(sponsorAccountUserId: "${sponsorAccountUserIdd}") {
                      sponsorAccountUserId
                      sponsorAccountId
                      SponsorAccountName
                      firstName
                      middleName
                      lastName
                      email
                      phone
                      role
                      roleId
                      position
                      language
                      invitedBy
                      invitedDate
                      activated
                      primaryContactInd
                      createdBy
                      createdTs
                      modifiedBy
                      modifiedTs
              }
          }`

    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await oldgenericApi('getsponsorAccountUser', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error!')

    } else {
      if (typeof res.data.getsponsorAccountUser === 'object' && res.data.getsponsorAccountUser !== null) {
        // setdata(res.data.getsponsorAccountUser)
        //console.log('siteusershome::', res.data.getsponsorAccountUser);
        propsobj.UpdateEntireForm(res.data.getsponsorAccountUser)
      }
    }
  }
  const handleClick = async (event) => {
    event.preventDefault();
    if(localStorage.getItem('whichApp')==="Site"){
      propsobj.history.push('/site/users/edit');
    }else{
      propsobj.history.push('/app/users/edit');
    }
   
  }

  const { sponsorAccountUserId = '', middleName = '', firstName = '', lastName = '', SponsorAccountName = '', role = '', email = '', phone = '', language = '', position = '' } = propsobj.formValues;

  const handleResetPwd = () => {
    alert('work under progress')
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}


      <div className="d-flex">
        <div class="secondary-header mr-auto">User Details</div>

        <div className="userdetailscss">
          <button style={{ marginRight: "6px" }} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
            Resend Invitation</button>
          <button onClick={handleResetPwd} style={{ marginRight: "6px" }} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
            Reset Password</button>
          {isEditUser === "1" ?
            <button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
              <i className="fas fa-pencil-alt"></i>&nbsp; EDIT</button> : ""}
        </div>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="addAccountForm" className="row h-form-1">
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">NAME INFORMATION</div>
                  <div className="inputBox">
                    <ViewRow text="First Name" textvalue={firstName} />
                    <ViewRow text="Middle Name" textvalue={middleName == "" ? "- - -" : middleName} />
                    <ViewRow text="Last Name" textvalue={lastName} />
                    <ViewRow text="Account Name" textvalue={SponsorAccountName} />
                    <ViewRow text="Position" textvalue={position} />
                    <ViewRow text="Role" textvalue={role == ("" || null) ? "- - -" : role} />
                    <ViewRow text="Language" textvalue={language} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">CONTACT INFORMATION</div>
                  <div className="inputBox">
                    <ViewRow text="Phone" textvalue={phone} />
                    <ViewRow text="Email" textvalue={email} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetails));
