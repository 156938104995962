import React, { useEffect, useState } from "react";
import Materialtable from '../../../../globalcomponents/materialtable';
import { apiCall } from '../../../common/apiconfig';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Paginationn } from '../../../common/pagination'
import { appConfig, Dropdowns } from "../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ADD_MUTIPLE_CONTACTS_STUDY } from '../../queries'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { checkPermission } from '../../../common/utils';

function ContactsGrid(props) {
  let isEditStudy = checkPermission('Study_Details_Edit', 'Update');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [selectedContactsobj, setSelectedContactsObj] = useState({})
  let [emptyObjErr, setEmptyObjErr] = useState("")

  let [currentpage, setCurrentPage] = useState(1);


  useEffect(() => {

    async function fetchData() {

      let requestBody = {
        query: `query {
          getAllSponsorAccountAllUser(sponsorAccountId:"${props.formValues.parentAccountId}") {
            sponsorAccountUserId
            sponsorAccountId
            SponsorAccountName
            firstName
            lastName
            email
            phone
            role
            roleId
            position
            language
            invitedBy
            invitedDate
            activated
            primaryContactInd
                }
            }`
      }
      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getAllSponsorAccountAllUser', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getAllSponsorAccountAllUser.length) {
          // console.log(res.data.getAllSponsorAccountAllUser,"data")
          // setData(res.data.getAllSponsorAccountAllUser)
          var data = res.data.getAllSponsorAccountAllUser
          if (props.formValues.studypersonalcontacts.length > 0) {
            var data1 = props.formValues.studypersonalcontacts
            data = data.filter(function (item) {
              for (var i = 0, len = data1.length; i < len; i++) {
                if (data1[i].contactId == item.sponsorAccountUserId) {
                  return false;
                }
              }
              return true;
            });
          }
          //to add role
          const newdata = data.map(v => ({ ...v, role: "" }))
          setData(newdata)
          var accountnames = []
          var accountids = []
          //this is to show accountname in drpdown by removing duplicates
          for (var i = 0; i < res.data.getAllSponsorAccountAllUser.length; i++) {
            accountnames.push(res.data.getAllSponsorAccountAllUser[i].SponsorAccountName)
            accountids.push(res.data.getAllSponsorAccountAllUser[i].sponsorAccountId)
          }
          Array.prototype.unique = function () {
            return Array.from(new Set(this))
          }
          var accountnamess = accountnames.unique()
          var accountidss = accountids.unique()
          props.UpdateFormField({ name: "AccountnamesArr", value: accountnamess })
          props.UpdateFormField({ name: "AccountidsArr", value: accountidss })
        } else {
          props.UpdateFormField({ name: "AccountnamesArr", value: [props.formValues.parentAccountName] })
          props.UpdateFormField({ name: "AccountidsArr", value: [props.formValues.parentAccountId] })
        }
      }
    }
    fetchData();
  }, []);

  const handleClick = async (event) => {
    event.preventDefault();
    props.history.push('/account/studyDetails/studypersonal/addnew');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    await props.UpdateEntireForm(rowData);
    //props.history.push('/account/accountDetails');
  }

  const Columns = [
    {
      title: 'Sponsor ID', field: 'sponsorAccountId',
      // cellStyle: {
      //   color: '#244271',
      //   fontWeight: 500,
      //   textAlign: 'left'
      // },
    },
    {
      title: 'Contact ID', field: 'sponsorAccountUserId',
      // cellStyle: {
      //   color: '#244271',
      //   fontWeight: 500,
      //   textAlign: 'left'
      // },
    },
    {
      title: 'Name', field: 'firstName',
      // cellStyle: {
      //   color: '#244271',
      //   fontWeight: 500
      // },
      render: data => data.firstName + ' ' + data.lastName
    },
    // { title: 'Position', field: 'position' },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' },
    {
      title: 'Role', field: 'role', render: rowData => <Autocomplete
        id="controlled-demo"
        options={Dropdowns.Contact_Role}
        getOptionLabel={option => option.name ? option.name : ""}
        value={rowData.role}
        disableClearable
        onChange={(e, value) => selectRole(e, value, rowData, data)}
        renderInput={(params) => <TextField {...params} margin="none" fullWidth />}
      />
    },
  ]
  const selectRole = (e, value, rowData, data) => {
    e.preventDefault()
    setData(data.map(item => item.sponsorAccountUserId === rowData.sponsorAccountUserId ? { ...item, role: value } : item))
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    const chkObj = o => Object.values(o).every(a => a.length === 0)
    console.log(selectedContactsobj, chkObj(selectedContactsobj), "Selccontacts")
    if (chkObj(selectedContactsobj)) {
      setEmptyObjErr('Select atleast one study personnel')
    } else if (Object.keys(selectedContactsobj).length == 0 && selectedContactsobj.constructor === Object) {
      setEmptyObjErr('Select atleast one study personnel')
    } else {
      setEmptyObjErr("")
      let selectedContacts = []
      for (let i in selectedContactsobj) {
        if (selectedContactsobj[i].length > 0) {
          for (let j of selectedContactsobj[i]) {
            selectedContacts = [...selectedContacts, j]
          }
        }
      }
      //this is to get updated data from data
      var newSelected = []
      for (var i = 0; i < selectedContacts.length; i++) {
        for (var j = 0; j < data.length; j++) {
          if (selectedContacts[i].sponsorAccountUserId == data[j].sponsorAccountUserId) {
            newSelected.push(data[j])
          }
        }
      }

      console.log(newSelected, "Selccontacts")
      //to check role is empty or not
      let obj = newSelected.find(item => item.role === "");
      if (obj != undefined) {
        setEmptyObjErr("Enter role for " + obj.firstName + ' ' + obj.lastName)
      } else {
        setEmptyObjErr('')

        let contactsSelected = '';
        //here to add all selected row from redux
        newSelected.map(contact => {
          contactsSelected += `{ studyId: "${props.formValues.studyId}", contactId: "${contact.sponsorAccountUserId}", role:"${contact.role.name}" }`;
        });
        let contactss = contactsSelected.replace(/}\s*{/g, '},{');

        let requestBody = {
          query: `mutation {
        addStudyContact(studyContactRel: [${contactss}]) {
          code
          type
          message
          attributes
        }
      }`
        };
        requestBody = JSON.stringify(requestBody);
        setProgress(true);
        let res = await apiCall('addStudyContact', requestBody);
        setProgress(false);
        if (!res) {
          // Error handling
          console.log('Error on response material grid!')

        } else {
          console.log('added')
          props.history.goBack()
          // if (res.data.addStudyContact.length) {
          //   setData(res.data.addStudyContact)
          // }
        }
      }
    }
  }
  const handleCancel = (e) => {
    e.preventDefault()
    props.history.goBack()
  }

  const rowsSelected = (rows) => {
    setSelectedContactsObj({ ...selectedContactsobj, [currentpage]: rows });
  }

  const handlePageClick = (offset, pageNum) => {
    setCurrentPage(pageNum);
    setoffset(offset)
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex mt-4 justify-content-end">
        <div className="mr-auto secondary-header">Add Study Personnel</div>
        {
          isEditStudy === "1" ?
            <div className="">
              <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
                <i className="fa fa-plus-circle mr-1"></i> ADD NEW STUDY PERSONNEL</button>
            </div> : ""
        }

      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="">

            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
              rowsSelected={rowsSelected}
              selection={true}
            />
          </div>
          {<span className="multiselecterrmsg">{emptyObjErr}</span>}
          {data.length > 0 &&
            <div className="buttonContainer ml-auto">
              <button className="btn btn-primary-blue btn-rounded btn-small mr-1" onClick={handleSubmit}>Save</button>&nbsp;
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
            </div>
          }
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    UpdateEntireArr: (arr) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: arr,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    }
  }
}
export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactsGrid)));



