import { useEffect, useState } from 'react';
import {useDropzone} from 'react-dropzone';

export const BasicFileUpload=({dataToAccept,onChangeFile})=> {
//   const {acceptedFiles, getRootProps, getInputProps} = useDropzone()/\;
  // const { acceptedFiles,getRootProps, getInputProps } = useDropzone({
  //   accept: dataToAccept,
  // });
  const [files, setFiles] = useState([]);
  //   accept: 'image/*',
  const { getRootProps, getInputProps } = useDropzone({
    accept: dataToAccept,
    noKeyboard: true, onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => file));

    }, multiple: true
  });

  console.log(files,"files")
  
  // const files = acceptedFiles.map(file => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes 
  //   </li>
  // ));
  useEffect(() => {
    console.log(files, "files")
    onChangeFile(files);
  }, [files]);

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} directory="" webkitdirectory="" type='file'/>
        <span>Drag 'n' drop some files here, or click to select files</span>
      </div>
      <aside>
      {files.length > 0 && <h5><br/>Uploaded Files</h5>}
      {
                  files.map((item, i) => (
       
        <div>{item.name}</div>
                  ))}
      </aside>
    </section>
  );
}