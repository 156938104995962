import React, { useEffect, useState } from "react";
import Materialtable from '../../../../globalcomponents/materialtable';
import { apiCall } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkPermission } from '../../../common/utils';

function StudypersonalGrid(props) {

  let isAddStudyPersonal = checkPermission('StudyPersonal_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);


  useEffect(() => {

    async function fetchData() {

      let requestBody = {
        query: `query {
          getStudyContact(studyId:"${props.formValues.studyId}") {
            studyId
            contactId
            action
            name
            status
            role
            email
            phone
            altno
            createdBy
            createdTs
            modifiedBy
            modifiedTs
          }
          }`
      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getStudyContact', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getStudyContact.length) {
          setData(res.data.getStudyContact)
          props.UpdateFormField({ name: "studypersonalcontacts", value: res.data.getStudyContact })
        }
      }
    }
    fetchData();
  }, []);

  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    props.history.push('/account/studyDetails/studypersonal/add');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    await props.UpdateEntireForm(rowData);
    if (props.formValues.primaryContactSearch == true) {
      props.UpdateFormField({ value: rowData.firstName + ' ' + rowData.lastName, name: "primaryContact" })
      props.UpdateFormField({ name: "primaryContactSearch", value: false })
      props.history.goBack()
    } else {
      console.log('under progress')
      // props.history.push('/account/viewContact');
    }
  }

  const Columns = [
    {
      title: 'Contact ID', field: 'contactId',
      // cellStyle: {
      //   color: '#244271',
      //   fontWeight: 500,
      //   textAlign: 'left'
      // },
    },
    {
      title: 'Name', field: 'name',
      // cellStyle: {
      //   color: '#244271',
      //   fontWeight: 500
      // },
    },
    // { title: 'Status', field: 'status' },
    { title: 'Role', field: 'role' },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' },
  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex mt-4 justify-content-end">
        <div className="mr-auto secondary-header">Study Personnel</div>
        {isAddStudyPersonal === "1" ? <div className="">
          <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
            <i className="fa fa-plus-circle mr-1"></i> ADD STUDY PERSONNEL</button>
        </div> : ""}

      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="studyper">
            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudypersonalGrid));


