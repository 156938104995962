import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title">
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog(props) {
  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        PaperComponent={PaperComponent}
        // maxWidth='xs'
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: "move", padding: "0px 10px", fontSize: "0.9rem" }}
          id="draggable-dialog-title"
          className="filehistorycss"
        >
          {props.header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{props.children}</DialogContentText>
        </DialogContent>
        <div style={{ border: "0.1px solid lightgrey" }}></div>
        <DialogActions>
          <Button
            autoFocus
            onClick={props.handleClose}
            color="primary"
            type="button"
  
            className="btn btn-normal btn-primary-blue btn-rounded btn-small fr mr-2">
            {props.hideButtons ? "OK" : "Cancel"}
          </Button>
          {props.hideButtons ? (
            " "
          ) : (
            <Button
              onClick={props.handleDelete}
              color="primary"
              type="button"
              className="btn btn-normal btn-primary-blue btn-rounded btn-small fr mr-2"
            >
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
