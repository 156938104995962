import React from 'react';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import Hospital from './hospital';
import Dataupload from './dataupload';
import Datamapping from './datamapping'
import Training from './training'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Route, Link, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    }
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591


export default function HospitalFlow(props) {
    const handleClick = (event, url) => {
        event.preventDefault();
        props.history.push(url)
    }
    console.log(props.location.pathname, props.location.pathname == "/admin/hospitalflow/dataupload", "url")
    return (
        <div className="container-fluid">
            <div className="mt-3 mb-3" style={{ background: "white", padding: "15px" }}>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <StyledBreadcrumb
                        component={props.location.pathname == "/admin/hospitalflow/hospital" ? "" : "a"}
                        label="Select Hospital"
                        onClick={e => handleClick(e, "/admin/hospitalflow/hospital")}
                    />
                    <StyledBreadcrumb
                        component={props.location.pathname == "/admin/hospitalflow/dataupload" ? "" : "a"}
                        label="Data Upload"
                        onClick={e => handleClick(e, "/admin/hospitalflow/dataupload")} />
                    <StyledBreadcrumb
                        label="Data Mapping"
                        component={props.location.pathname == "/admin/hospitalflow/datamapping" ? "" : "a"}
                        onClick={e => handleClick(e, "/admin/hospitalflow/datamapping")}
                    />
                    <StyledBreadcrumb
                        label="Training"
                        component={props.location.pathname == "/admin/hospitalflow/training" ? "" : "a"}
                        active
                        onClick={e => handleClick(e, "/admin/hospitalflow/training")}
                    />
                </Breadcrumbs>
            </div>
            <Switch>
                <div className='mb-3'>
                    <Route path="/admin/hospitalflow/hospital" component={Hospital} />
                    <Route path="/admin/hospitalflow/dataupload" component={Dataupload} />
                    <Route path="/admin/hospitalflow/datamapping" component={Datamapping} />
                    <Route path="/admin/hospitalflow/training" component={Training} />
                    <Redirect to="/admin/hospitalflow/hospital" />
                </div>
            </Switch>
        </div>

    );
}
