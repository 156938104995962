import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { genericApi, apiCall, oldgenericApi } from '../../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import findpatientIcon from '../../../../img/Find-Patient.svg';
// import { checkPermission } from '../../../../common/utils';
import Materialtable from '../../../../globalcomponents/materialtable'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SmartText from '../../../../sponsorApp/common/smartText'
import Tooltip from '@material-ui/core/Tooltip';
import Lighttooltip from '../../../../globalcomponents/tooltipIEcriteria'
function NonRegistered(props) {
  // let isAddAccount = checkPermission('Account_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');
const [width, setWidth] = useState('')

  useEffect(() => {
    var outerContainer = document.querySelector(`#tablediv table thead tr th:last-child`);
    var width = outerContainer.offsetWidth
    if (width >= 301) {
      setWidth(55)
    } else if (width <= 300) {
      setWidth(40)
    } else {
      setWidth(30)
    }
    if(props.formValues.nonRegisteredStudiesData){
      setData(props.formValues.nonRegisteredStudiesData)
    }else{
    callSponsorsApi()
    }
    props.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, []);
  const callSponsorsApi = async () => {
    let requestBody = {
      query: `query {
        getOpenSquareLeadRequests(siteId:"${props.formValues.autosite.sponsorAccountId}",leadStatus:"*") {
          requestId
          requestNum
        accountId
        accountName
        inclusion
        exclusion
        reference
        studyId
        studyNum
        siteId
        siteName
        siteCity
        siteCountry
        studyStatus
        leadStatus
        requestDateTime
        responseDateTime
        patientCount
        priceType
        price
        totalPrice
        createdBy
        createdTs
        modifiedBy
        modifiedTs
    }
  }`
    }
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await oldgenericApi('getOpenSquareLeadRequests', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error')

    } else {
      if (res.data.getOpenSquareLeadRequests.length) {
        setData(res.data.getOpenSquareLeadRequests)
        props.UpdateFormField({ name: "nonRegisteredStudiesData", value: res.data.getOpenSquareLeadRequests })
      } else {
        setData([])
      }
    }
  }
  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  // const handleClick = async (event) => {
  //   event.preventDefault();
  //   props.history.push('/account/addAccount');
  // }

  const handleRowClick = async (event, rowData) => {
    event.preventDefault()
    // save clicked rowdata in redux, make sure the logic to empty back
    // await props.setSidebarActiveEl('accounts');
    // props.UpdateEntireForm(rowData);
    // props.history.push('/site/accountDetails');
  }

  // const searchHandle = (event) => {
  //   event.preventDefault();
  //   let value = event.target.value;
  //   setValSearch(value);
  // }
  // const searchSubmit = async (event) => {
  //   event.preventDefault();
  //   if (valSearch.length) {
  //     // fetchData(valSearch);
  //     await props.UpdateFormField({ name: "accountSearchText", value: valSearch })
  //     await props.updateHeaderSearchValue({ value: "" })
  //   }
  // }
  // const clearSearch = (event) => {
  //   event.preventDefault()
  //   fetchData();
  //   setValSearch("")
  // }
  const Columns = [
    {
      title: 'Sponsor Name', field: 'accountName'
    },

    { title: "Study No.", field: 'studyNum', render: rowData => "NR" + rowData.studyNum },
    { title: 'Inclusion Criteria.', field: 'inclusion', render: rowData =>rowData.inclusion.length>width ? <Lighttooltip data={rowData.inclusion.split('||') }>{rowData.inclusion.slice(0, width) +"..."} </Lighttooltip> : rowData.inclusion },
    {
      title: 'Exclusion Criteria', field: 'exclusion', render: rowData =>rowData.exclusion.length>width ? 
        // <SmartText text={rowData.exclusion} length={width} />  
        <Lighttooltip data={rowData.exclusion.split('||')}>{rowData.exclusion.slice(0, width) +"..."} </Lighttooltip>: rowData.exclusion
    },
    { title: "Count", field: 'patientCount' },
    { title: "Pricing Type", field: 'priceType' },
    { title: "Price($)", field: 'totalPrice' },
    {
      title: 'Actions', field: '', cellStyle: {
        // float: "right",
        // marginRight: "20px",
        cursor: "pointer",
        width: '10%'
      }, render: rowdata => rowdata.patientCount == null ? <Tooltip title="Find Patients" arrow style={{marginLeft:"18%"}}><img src={findpatientIcon} alt="find aptient" onClick={(e) => handleClick(e, rowdata)} /></Tooltip> : <div style={{ color: "green" }}><b>completed</b></div>
      // <Tooltip title="Edit Price" arrow><div onClick={(e) => editPrice(e, rowdata)}> <i className="fas fa-pencil-alt" style={{ color: "#22406E" }} /></div></Tooltip>
    }
  ]
  const handleClick = async (event, rowData) => {
    event.preventDefault();
    // console.log(JSON.parse(rowData.reference).replace(/\\\\/g,"\\").replace(/\"/g, '\"'),"ref1")
    props.UpdateFormField({ name: "Inclusion", value: rowData.inclusion.split('||') })
    props.UpdateFormField({ name: "Exclusion", value: rowData.exclusion.split('||') })
    props.UpdateFormField({ name: "nonRStudyInfo", value: rowData })
    props.UpdateFormField({ name: "PIRdata", value: JSON.parse(rowData.reference)})
    props.UpdateFormField({ name: "openSqNCTid", value: "" })
    props.UpdateFormField({ name: "nonregisterstudies", value: true })
    props.history.push('/site/pir')
  }

  const editPrice = async (event, rowData) => {
    event.preventDefault();
    props.UpdateFormField({ name: "nonRStudyInfo", value: rowData })
    props.UpdateFormField({ name: "patientscount", value: rowData.patientCount })
    props.history.push('/site/pricingInfo')
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
        {/* <Loader/> */}
      </div>}
      {/* <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">Non-Registered Studies</h1> */}
      {/* {isAddAccount === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
              <i className="fa fa-plus-circle mr-1"></i> ADD ACCOUNT</button>
          </div> : ""} */}
      {/* </div> */}
      <div className="listing-wrap hekmaGrid mt-4">
        <div className="card">
          <div className="hekmaGridBox">
            {/* <div className="col-xs-12 col-sm-12 col-md-5">
              <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                <HekmaTextSearchField
                  name="accountgridsearch"// this name you need not to add in constant file unless if we use redux
                  // label="Search Account"
                  placeholder="Search Accounts"
                  value={valSearch}
                  handleChange={searchHandle}
                  className="gridSearchField"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                       
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                        X
                       
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div> */}
            <div id="tablediv" className="studyper">
              <Materialtable
                columns={Columns}
                data={data.length > 0 ? data : []}
                handleRowClick={handleRowClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateHeaderSearchValue: (obj) => {
      return dispatch(
        {
          type: 'headerSearch',
          value: obj.value,
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateRecentItems: (obj) => {
      return dispatch(
        {
          type: 'updateRecentItems',
          value: obj,
        }
      )
    },
    setSidebarActiveEl: (name) => {
      return dispatch(
        {
          type: 'sidebarActiveEl',
          value: name,
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NonRegistered));