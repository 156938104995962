import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

function MHSearchField(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}

            <div className="mhsearchBox d-flex align-items-center justify-content-between">
                <span className="secondary-header" style={{ marginBottom: "2px" }}>{props.title}</span>
                {/* <div className="textbox">
                    <form className="flex-grow-1 mr-3">
                        <TextField
                            label={props.label}
                            type="search"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                        />
                    </form>
                    <span className="calenderIcon"><i className="far fa-calendar-minus"></i></span>
                </div> */}
                {props.app === "Patient" &&
                    <span>
                        <button className="btn btn-primary-blue btn-rounded" onClick={props.handleChange}>
                            <i className="fa fa-plus-circle mr-1"></i> {props.addBtnText}</button>
                    </span>
                }
            </div>

        </>
    )

}

export default MHSearchField;


