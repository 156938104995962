import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import OktaSignInWidget from './OktaSignInWidget';
import { useOktaAuth } from '@okta/okta-react';
import { OktaSignIn } from '@okta/okta-signin-widget';
import { withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginApi, apiCall, genericApi, securityApi } from '../../common/apiconfig';
import { appConfig } from '../../common/constants'
import ErrorIcon from '@material-ui/icons/Error';

const OktaLogin = (props) => {
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  const { oktaAuth, authState } = useOktaAuth();
  const onSuccess = (tokens) => {
    if (tokens.accessToken != undefined) {
      props.UpdateFormField({ name: "ssotokens", value: tokens })
      // props.history.push('/login')
      loginApiCall(props.formValues, partnerId, tokens)
    }
    // oktaAuth.handleLoginRedirect(tokens);
  };
  var partnerId = ""
  if (window.location.hostname === appConfig.host1) {
    partnerId = "1001"
  } else if (window.location.hostname === appConfig.host2) {
    partnerId = "1002"
  } else {
    partnerId = "1001"
  }

  const loginApiCall = async (formdata, partnerId, ssodata) => {
    console.log(ssodata, "ssodata",formdata, partnerId)
    let res = await loginApi(formdata, partnerId, ssodata);
    // setProgress(false);
    if (!res) {
      setApiError(true);
      setTimeout(function () { setApiError(false) }, 1000 * 30);
      setProgress(false);
    } else {
      //security part starts
      function ip2int(ip) {
        return ip.split('.').reduce(function (ipInt, octet) { return (ipInt << 8) + parseInt(octet, 10) }, 0) >>> 0;
      }
      async function getipData(url) {
        const response = await fetch(url);
        return response.json();
      }

      let localId = await getipData(appConfig.ipInfoApi);
      var securityBody = JSON.stringify({ "partnerId": partnerId, "localId": ip2int(localId.ip).toString() });

      let res1 = await securityApi('securityapi', securityBody);
      // setProgress(false);
      if (!res1) {
        alert('Error')
      } else {
        console.log(res1, "loginpage")


        // security part stops--------------------------------
        let requestBody = `
    query {
       getTobeActivatedAccountUser {
                 sponsorAccountUserId,
                   sponsorAccountId,
                   SponsorAccountName,
                   firstName,
                   middleName,
                   lastName,
                   email,
                   phone,
                   role,
                   language,
                   portalAccessInd,
                   invitedBy,
                   invitedDate,
                   activated,
                   primaryContactInd
           }
       }
   `;

        requestBody = JSON.stringify({ query: requestBody });
        // setProgress(true);

        let res = await apiCall('getTobeActivatedAccountUser', requestBody);
        //if token not validated allow user to get new token by showing link expired, click now to activate again
        setProgress(false);
        if (!res) {
          alert('Error')
        } else {
          let data = res.data.getTobeActivatedAccountUser;
          console.log(data, "apiuser")
          localStorage.setItem("loggedInUser", data.firstName)
          localStorage.setItem("loggedUserEmail", data.email)
          localStorage.setItem('loginUserId', data.sponsorAccountUserId)
          localStorage.setItem('sponsorrole', data.role)
          props.history.push('/account');
         

        }
      }
      //security part

      // var roles = JSON.parse(localStorage.getItem('roles'))
      // propsobj.UpdateFormField({ name: "rolePermissions", value: roles.uiRolePermissions })


    }
  }
  const onError = (err) => {
    console.log('error logging in', err);
  };

  if (authState.isPending) return null;

  return <>
    {apiError && <>
      <div className="hekError">
        <span className='icon'>
          <ErrorIcon />
        </span>
        <span className='texts'>
          Unauthorized
        </span>
      </div>
    </>}
    {authState.isAuthenticated ?
      <Redirect to={{ pathname: '/login' }} /> :
      <OktaSignInWidget
        config={props.config}
        onSuccess={onSuccess}
        onError={onError}
      />
    }
  </>
};

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OktaLogin));