import React, { useEffect, useState, Component } from "react";
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NCTPage from "./nctpage";
import {SearchCriteriaPIR} from "./searchCriteria";
import Patientspage from "./patientspage";
const NEWPIR = (props) => {
  return (
    <>
      <div className="mt2rem">
        <Switch>
          <Route path="/app/newpir/ncts"><NCTPage/></Route>
          <Route path="/app/newpir/searchcriteria">
            <SearchCriteriaPIR />
           
          </Route>

          <Route path="/app/newpir/patients"><Patientspage/></Route>
          <Redirect to="/app/newpir/ncts" />
        </Switch>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NEWPIR);

