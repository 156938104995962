// import React, { useEffect, useState } from "react";
// import { withRouter } from 'react-router-dom';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import { ViewRow } from '../../formElements';
// import { connect } from 'react-redux';
// import Icon from '@material-ui/core/Icon';
// import { Button } from '@material-ui/core'
// import BackIcon from '../../../../img/back1.svg'
// import { checkPermission } from '../../../common/utils';
// import { getFiles } from '../../../common/apiconfig';
// import Tooltip from '@material-ui/core/Tooltip';
// import VideoThumbnail from 'react-video-thumbnail';
// import VideoPlayer from 'simple-react-video-thumbnail'
// import FileViewModal from '../fileupload/fileviewmodal'
// import PdfPreview from '../fileupload/pdf-preview'

// const ViewResources = (props) => {
//   let isEditStudy = checkPermission('Study_Details_Edit', 'Update');
//   let [apiError, setApiError] = useState(false);
//   let [progress, setProgress] = useState(false);
//   let [documents, setDocuments] = useState([])
//   let [videos, setVideos] = useState([])
//   let [pdfUrl, setPdfUrl] = useState("")
//   let [imgUrl,setImgUrl]= useState("")
//   const [open, setOpen] = useState(false)

//   const handleClick = async (event) => {
//     event.preventDefault();
//     await props.UpdateEntireForm(props.state.courseData.cr)
//     props.history.push('/account/studyDetails/resources/Update');
//   }

//   const { name = '', type = '', category = '', summary = "", language = "", version = "" } = props.state.courseData.cr;

//   const goBack = (event) => {
//     event.preventDefault();
//     props.history.push('/account/studyDetails/resources')
//   }
//   const download = async (e, file) => {
//     e.preventDefault()
//     let res = await getFiles('fileupload', file, "GET");
//     if (res.code == 200) {
//       var data = JSON.parse(res.attributes)
//       window.open(data.URL)
//     }
//   }
//   useEffect(() => {
//     if (props.state.courseData.document.length > 0) {
//       callDocs(props.state.courseData.document)
//     }
//     if (props.state.courseData.video.length > 0) {
//       callVideos(props.state.courseData.video)
//     }
//   }, [])
//   const callDocs = async (data) => {
//     setProgress(true)
//     var urldata = data.map(v => ({ ...v, url: "" }))
//     for (var i = 0; i < data.length; i++) {
//       let res = await getFiles('fileupload', data[i], "GET");
//       if (res.code === 200) {
//         let data = JSON.parse(res.attributes)
//         urldata[i]["url"] = data.URL
//       }

//     }
//     setDocuments(urldata)
//     props.UpdateFormField({name:"docUrls",value:urldata})
//     setProgress(false)
//   }
//   const callVideos = async (data) => {
//     setProgress(true)
//     var urldata = data.map(v => ({ ...v, url: "" }))
//     for (var i = 0; i < data.length; i++) {
//       let res = await getFiles('fileupload', data[i], "GET");
//       if (res.code === 200) {
//         let data = JSON.parse(res.attributes)
//         urldata[i]["url"] = data.URL
//       }
//     }
//     setVideos(urldata)
//     props.UpdateFormField({name:"videoUrls",value:urldata})
//     setProgress(false)
//   }
//   const image = (url) => {
//     return <img src={url} alt="image" height="75" width="75" />
//   }

//   const onClose = () => {
//     setOpen(false)
//   }
//   const pdfClick = (e,url) => {
//     e.preventDefault()
//     setOpen(true)
//     setPdfUrl(url)
//     setImgUrl("")
//   }
//   const imgClick = (e,url) => {
//     e.preventDefault()
//     setOpen(true)
//     setImgUrl(url)
//     setPdfUrl("")
//   }
//   return (
//     <>
//       {progress && <div className="fullScreen">
//         <CircularProgress
//           disableShrink
//           className="progressCircle"
//           value={90}
//           size={100}
//           thickness={1.5}
//         />
//       </div>}

//       <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO RESOURCES LIST</div><br />
//       <div className="d-flex justify-content-end">

//         <div class="secondary-header mr-auto">Resource Details</div>
//         {
//           isEditStudy === "1" ?
//             <div className="">
//               <button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
//                 <i className="fas fa-pencil-alt"></i> EDIT</button>
//             </div> : ""}
//       </div>
//       <div className="">
//       <FileViewModal open={open} pdfUrl={pdfUrl} imgUrl={imgUrl} onClose={onClose} />
//         <form noValidate autoComplete="off" id="addAccountForm" className="row h-form-1">
//           <div className="col-md-6">
//             <div className="panel-primary-wrap">
//               <div className="card">
//                 <div className="card-body">
//                   <div className="card-page-header">RESOURCE INFORMATION</div>
//                   <div className="inputBox">
//                     <ViewRow text="Course Name" textvalue={name} />
//                     <ViewRow text="Category" textvalue={category} />
//                     <ViewRow text="Summary" textvalue={summary} />
//                     <ViewRow text="Type" textvalue={type} />
//                     <ViewRow text="Language" textvalue={language} />
//                     <ViewRow text="Version" textvalue={version} />

//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="panel-primary-wrap">
//               <div className="card">
//                 <div className="card-body">
//                   <div className="card-page-header">RESOURCE FILES</div>
//                   <div style={{ fontSize: "14px",fontWeight:"700",color:"#626262"  }}>Links</div>
//                   <div className="inputBox">
//                     {props.state.courseData.link.length > 0 ?
//                       <table className="viewCourseLinks">
//                         <thead>
//                           <tr>
//                             <th style={{ width: "12%" }}>S. NO</th>
//                             <th style={{ width: "44%" }}>LINK/URL</th>
//                             <th style={{ width: "44%" }}>DESCRIPTION</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {
//                             props.state.courseData.link.map((item, i) => (
//                               <tr>
//                                 <td>{i + 1}</td>
//                                 <td><a href={item.detail1} target="_blank">{item.detail1}</a></td>
//                                 <td>{item.detail2}</td>
//                               </tr>

//                             ))
//                           }

//                         </tbody>
//                       </table> :  <div className="nodata">No Data Found</div>}
//                       <div style={{ fontSize: "14px",fontWeight:"700",color:"#626262" }}>Documents</div>
//                     {/* <div className="inputBox"> */}
//                       {documents.length > 0 ?
//                         <table className="viewCourseLinks">
//                           <thead>
//                             <tr>
//                               <th style={{ width: "12%" }}>S. NO</th>
//                               <th style={{ width: "15%" }}>TYPE</th>
//                               <th style={{ width: "38%" }}>NAME</th>
//                               <th style={{ width: "35%" }}>PREVIEW</th>
//                             </tr>
//                           </thead>
//                           <tbody>
//                             {
//                               documents.map((item, i) => (
//                                 <tr>
//                                   <td>{i + 1}</td>
//                                   <td>{item.detail3}</td>
//                                   <td onClick={(e) => { download(e, item) }} style={{ cursor: "pointer" }}><Tooltip title="Click to download" arrow><a href="#">  {item.detail2}</a></Tooltip></td>
//                                   <td className="previewpdf">{item.detail3.includes("image") ? <span onClick={(e)=>imgClick(e,item.url)}>{image(item.url)}</span> : item.detail3.includes("pdf") && <span style={{ cursor: "pointer" }} onClick={(e)=>pdfClick(e,item.url)}><PdfPreview url={item.url} show={false}  width="75"/>
//                                   </span>}
//                                   </td>
//                                 </tr>
//                               ))
//                             }

//                           </tbody>
//                         </table> :  <div className="nodata">No Data Found</div>}
//                     {/* </div> */}
//                     <div style={{ fontSize: "14px",fontWeight:"700",color:"#626262" }}>Videos</div>
//                     {/* <div className="inputBox"> */}
//                       {videos.length > 0 ?
//                         <table className="viewCourseLinks">
//                           <thead>
//                             <tr>
//                               <th style={{ width: "12%" }}>S. NO</th>
//                               <th style={{ width: "15%" }}>TYPE</th>
//                               <th style={{ width: "38%" }}>NAME</th>
//                               <th style={{ width: "35%" }}>PREVIEW</th>
//                             </tr>
//                           </thead>
//                           <tbody>
//                             {
//                               videos.map((item, i) => (
//                                 <tr>
//                                   <td>{i + 1}</td>
//                                   <td>{item.detail3}</td>
//                                   <td onClick={(e) => { download(e, item) }} style={{ cursor: "pointer" }}><Tooltip title="Click to download" arrow><a href="#">  {item.detail2}</a></Tooltip></td>
//                                   <td>
//                                     {/* <VideoThumbnail
//                                       videoUrl={item.url}
//                                       thumbnailHandler={(thumbnail) => console.log(thumbnail, "video")}
//                                       width={75}
//                                       height={75}
//                                     /> */}
//                                     <VideoPlayer videoUrl={item.url} snapshotAt={2} />
//                                   </td>
//                                 </tr>
//                               ))
//                             }

//                           </tbody>
//                         </table> : <div className="nodata">No Data Found</div>}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//         </form>
//       </div>
//     </>
//   )
// }

// const mapStateToProps = (state) => {
//   return {
//     formValues: state.form,
//     state
//   }
// }
// const mapDispatchToProps = (dispatch) => {
//   return {
//     UpdateEntireForm: (obj) => {
//       return dispatch(
//         {
//           type: 'UpdateEntireForm',
//           value: obj,
//         }
//       )
//     },
//     UpdateFormField: (obj) => {
//       return dispatch(
//         {
//           type: 'UpdateFormField',
//           value: obj.value,
//           name: obj.name
//         }
//       )
//     },
//   }
// }
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewResources));

import React from 'react'
import ViewResource from '../fileupload/viewCoursecommon'

const ViewResources=()=>{
  return(
    <>
    <ViewResource type="Resource" plural="resources"/>
    </>
  )
}
export default ViewResources

