export const myresponse = {
  "response": {
    "exclude_cond": [
      {
        "code": -111,
        "display_term": "age",
        "ent_type": "Lab",
        "operators": [
          "="
        ],
        "preferred_term": "age",
        "units": [
          "years"
        ],
        "values": [
          18.0
        ]
      },
      {
        "code": -222,
        "display_term": "age123",
        "ent_type": "Lab",
        "operators": [
          "="
        ],
        "preferred_term": "age123",
        "units": [
          "years"
        ],
        "values": [
          18.0
        ]
      },
      {
        "code": -333,
        "display_term": "ageaaa",
        "ent_type": "Lab",
        "operators": [
          "="
        ],
        "preferred_term": "ageaaa",
        "units": [
          "years"
        ],
        "values": [
          18.0
        ]
      },
      {
        "display_term": "psychiatric illness",
        "ent_type": "Medical Condition",
        "negative_meaning": false,
        "preferred_term": "",
        "sent_num": 1,
        "snomed_code": [
          {
            "distance": 17,
            "ratio": 0.22857142857142856,
            "snomedcode": 74732009,
            "term": "mental disorder "
          }
        ]
      },
      {
        "display_term": "psychiatric illnesssss",
        "ent_type": "Medical Condition",
        "negative_meaning": false,
        "preferred_term": "",
        "sent_num": 1,
        "snomed_code": [
          {
            "distance": 17,
            "ratio": 0.22857142857142856,
            "snomedcode": 74732009,
            "term": "mental disorder "
          }
        ]
      },

    ],
    "include_cond": [
      {
        "display_term": "Race",
        "ent_type": "Demographics",
        "values": [
          "Participant"
        ]
      },
      {
        "display_term": "Race1",
        "ent_type": "Demographics",
        "values": [
          "Legal Guardians"
        ]
      },
      {
        "display_term": "anotherpsychiatric illnesssss",
        "ent_type": "Medical Condition",
        "negative_meaning": false,
        "preferred_term": "",
        "sent_num": 1,
        "snomed_code": [
          {
            "distance": 17,
            "ratio": 0.22857142857142856,
            "snomedcode": 74732009,
            "term": "mental disorder "
          }
        ]
      },
    ]
  }
};



export const response2 = {
"response": {
  "exclude_cond": [
    {
      "code": -111,
      "display_term": "age",
      "ent_type": "Demographics",
      "operators": [
        ">"
      ],
      "preferred_term": "age",
      "units": [
        "years"
      ],
      "values": [
        49.9999
      ]
    },
    {
      "display_term": "alzheimer",
      "ent_type": "Medical Condition",
      "negative_meaning": false,
      "preferred_term": "",
      "sent_num": 1,
      "snomed_code": [
        {
          "distance": 11,
          "ratio": 0.6206896551724138,
          "snomedcode": 26929004,
          "term": "alzheimer's disease "
        },
        {
          "distance": 24,
          "ratio": 0.3157894736842105,
          "snomedcode": 79358009,
          "term": "transmissible virus dementia "
        },
        {
          "distance": 23,
          "ratio": 0.22857142857142856,
          "snomedcode": 80098002,
          "term": "diffuse lewy body disease "
        },
        {
          "distance": 23,
          "ratio": 0.43902439024390244,
          "snomedcode": 192160005,
          "term": "dementia in alzheimer's disease "
        },
        {
          "distance": 43,
          "ratio": 0.29508196721311475,
          "snomedcode": 192163007,
          "term": "dementia in alzheimer's dis, atypical or mixed type "
        },
        {
          "distance": 36,
          "ratio": 0.3333333333333333,
          "snomedcode": 192164001,
          "term": "dementia in alzheimer's disease, unspecified "
        },
        {
          "distance": 17,
          "ratio": 0.5142857142857142,
          "snomedcode": 194481000,
          "term": "other alzheimer's disease "
        },
        {
          "distance": 35,
          "ratio": 0.33962264150943394,
          "snomedcode": 230265002,
          "term": "familial alzheimer's disease of early onset "
        },
        {
          "distance": 39,
          "ratio": 0.3157894736842105,
          "snomedcode": 230266001,
          "term": "non-familial alzheimer's disease of early onset "
        },
        {
          "distance": 34,
          "ratio": 0.34615384615384615,
          "snomedcode": 230267005,
          "term": "familial alzheimer's disease of late onset "
        },
        {
          "distance": 38,
          "ratio": 0.32142857142857145,
          "snomedcode": 230268000,
          "term": "non-familial alzheimer's disease of late onset "
        },
        {
          "distance": 17,
          "ratio": 0.5142857142857142,
          "snomedcode": 230269008,
          "term": "focal alzheimer's disease "
        },
        {
          "distance": 29,
          "ratio": 0.3829787234042553,
          "snomedcode": 230279005,
          "term": "non-alzheimer's progressive dysphasia "
        },
        {
          "distance": 34,
          "ratio": 0.34615384615384615,
          "snomedcode": 230280008,
          "term": "progressive aphasia in alzheimer's disease "
        },
        {
          "distance": 60,
          "ratio": 0.23076923076923078,
          "snomedcode": 416780008,
          "term": "primary degenerative dementia of the alzheimer type, presenile onset "
        },
        {
          "distance": 57,
          "ratio": 0.24,
          "snomedcode": 416975007,
          "term": "primary degenerative dementia of the alzheimer type, senile onset "
        },
        {
          "distance": 50,
          "ratio": 0.2647058823529412,
          "snomedcode": 1581000119101,
          "term": "dementia of the alzheimer type with behavioral disturbance "
        },
        {
          "distance": 27,
          "ratio": 0.4,
          "snomedcode": 142811000119104,
          "term": "dementia due to alzheimer's disease "
        },
        {
          "distance": 29,
          "ratio": 0.3829787234042553,
          "snomedcode": 142001000119106,
          "term": "depressed mood in alzheimer's disease "
        },
        {
          "distance": 31,
          "ratio": 0.3673469387755102,
          "snomedcode": 97751000119108,
          "term": "altered behavior in alzheimer's disease "
        },
        {
          "distance": 24,
          "ratio": 0.42857142857142855,
          "snomedcode": 141991000119109,
          "term": "delusions in alzheimer's disease "
        },
        {
          "distance": 70,
          "ratio": 0.20454545454545456,
          "snomedcode": 698954005,
          "term": "primary degenerative dementia of the alzheimer type, senile onset in remission "
        },
        {
          "distance": 51,
          "ratio": 0.2608695652173913,
          "snomedcode": 105421000119105,
          "term": "early onset alzheimer's disease with behavioral disturbance "
        },
        {
          "distance": 38,
          "ratio": 0.32142857142857145,
          "snomedcode": 142011000119109,
          "term": "alzheimer's disease co-occurrent with delirium "
        },
        {
          "distance": 29,
          "ratio": 0.3829787234042553,
          "snomedcode": 721219005,
          "term": "familial alzheimer-like prion disease "
        },
        {
          "distance": 22,
          "ratio": 0.45,
          "snomedcode": 722600006,
          "term": "non-amnestic alzheimer disease "
        },
        {
          "distance": 68,
          "ratio": 0.20930232558139536,
          "snomedcode": 16219201000119101,
          "term": "behavioral disturbance co-occurrent and due to late onset alzheimer dementia "
        },
        {
          "distance": 13,
          "ratio": 0.25,
          "snomedcode": 79341000119107,
          "term": "mixed dementia "
        }
      ]
    },
    {
      "display_term": "alzheimer",
      "dosage": "",
      "drug_strength": "",
      "ent_type": "Drug",
      "sent_num": 1
    }
  ],
  "include_cond": [
    {
      "code": -111,
      "display_term": "age",
      "ent_type": "Demographics",
      "operators": [
        ">", "<"
      ],
      "preferred_term": "age",
      "units": [
        "years"
      ],
      "values": [
        17.9999, 49
      ]
    },
    {
      "display_term": "cancer",
      "ent_type": "Medical Condition",
      "negative_meaning": false,
      "preferred_term": "",
      "sent_num": 1,
      "snomed_code": [
        {
          "distance": 19,
          "ratio": 0.21428571428571427,
          "snomedcode": 111030006,
          "term": "howel-evans' syndrome "
        },
        {
          "distance": 39,
          "ratio": 0.23529411764705882,
          "snomedcode": 188469005,
          "term": "secondary malignant neoplasm of cervix uteri "
        },
        {
          "distance": 25,
          "ratio": 0.22857142857142856,
          "snomedcode": 254632001,
          "term": "small cell carcinoma of lung "
        },
        {
          "distance": 21,
          "ratio": 0.36363636363636365,
          "snomedcode": 254637007,
          "term": "non-small cell lung cancer "
        },
        {
          "distance": 13,
          "ratio": 0.48,
          "snomedcode": 254841008,
          "term": "cancer en cuirasse "
        },
        {
          "distance": 20,
          "ratio": 0.375,
          "snomedcode": 254843006,
          "term": "familial cancer of breast "
        },
        {
          "distance": 19,
          "ratio": 0.27586206896551724,
          "snomedcode": 255079005,
          "term": "carcinoma of esophagus "
        },
        {
          "distance": 17,
          "ratio": 0.2962962962962963,
          "snomedcode": 255080008,
          "term": "carcinoma of stomach "
        },
        {
          "distance": 32,
          "ratio": 0.23255813953488372,
          "snomedcode": 255088001,
          "term": "malignant tumor of exocrine pancreas "
        },
        {
          "distance": 16,
          "ratio": 0.42857142857142855,
          "snomedcode": 275419009,
          "term": "primary vulval cancer "
        },
        {
          "distance": 15,
          "ratio": 0.4444444444444444,
          "snomedcode": 276809004,
          "term": "early gastric cancer "
        },
        {
          "distance": 14,
          "ratio": 0.46153846153846156,
          "snomedcode": 276810009,
          "term": "late gastric cancer "
        },
        {
          "distance": 31,
          "ratio": 0.27906976744186046,
          "snomedcode": 315058005,
          "term": "hereditary nonpolyposis colon cancer "
        },
        {
          "distance": 34,
          "ratio": 0.2222222222222222,
          "snomedcode": 128462008,
          "term": "secondary malignant neoplastic disease "
        },
        {
          "distance": 24,
          "ratio": 0.23529411764705882,
          "snomedcode": 93870000,
          "term": "malignant neoplasm of liver "
        },
        {
          "distance": 33,
          "ratio": 0.22727272727272727,
          "snomedcode": 94381002,
          "term": "secondary malignant neoplasm of liver "
        },
        {
          "distance": 33,
          "ratio": 0.22727272727272727,
          "snomedcode": 94455000,
          "term": "secondary malignant neoplasm of ovary "
        },
        {
          "distance": 21,
          "ratio": 0.25806451612903225,
          "snomedcode": 363350007,
          "term": "malignant tumor of cecum "
        },
        {
          "distance": 13,
          "ratio": 0.2727272727272727,
          "snomedcode": 363346000,
          "term": "malignant tumor "
        },
        {
          "distance": 20,
          "ratio": 0.20689655172413793,
          "snomedcode": 363348004,
          "term": "malignant tumor of lip "
        },
        {
          "distance": 21,
          "ratio": 0.3125,
          "snomedcode": 363354003,
          "term": "malignant tumor of cervix "
        },
        {
          "distance": 20,
          "ratio": 0.20689655172413793,
          "snomedcode": 363382005,
          "term": "malignant tumor of gum "
        },
        {
          "distance": 24,
          "ratio": 0.23529411764705882,
          "snomedcode": 363418001,
          "term": "malignant tumor of pancreas "
        },
        {
          "distance": 23,
          "ratio": 0.24242424242424243,
          "snomedcode": 363432004,
          "term": "malignant tumor of trachea "
        },
        {
          "distance": 22,
          "ratio": 0.25,
          "snomedcode": 363433009,
          "term": "malignant tumor of pleura "
        },
        {
          "distance": 24,
          "ratio": 0.24242424242424243,
          "snomedcode": 363454002,
          "term": "malignant tumor of scrotum "
        },
        {
          "distance": 22,
          "ratio": 0.25,
          "snomedcode": 363458004,
          "term": "malignant tumor of ureter "
        },
        {
          "distance": 23,
          "ratio": 0.24242424242424243,
          "snomedcode": 363459007,
          "term": "malignant tumor of urethra "
        },
        {
          "distance": 26,
          "ratio": 0.2222222222222222,
          "snomedcode": 363486007,
          "term": "malignant tumor of vocal cord "
        },
        {
          "distance": 26,
          "ratio": 0.2222222222222222,
          "snomedcode": 363492001,
          "term": "malignant tumor of peritoneum "
        },
        {
          "distance": 22,
          "ratio": 0.25,
          "snomedcode": 363495004,
          "term": "malignant tumor of muscle "
        },
        {
          "distance": 17,
          "ratio": 0.23076923076923078,
          "snomedcode": 363500001,
          "term": "multiple malignancy "
        },
        {
          "distance": 25,
          "ratio": 0.22857142857142856,
          "snomedcode": 371973000,
          "term": "malignant neoplasm of uterus "
        },
        {
          "distance": 33,
          "ratio": 0.22727272727272727,
          "snomedcode": 94225005,
          "term": "secondary malignant neoplasm of brain "
        },
        {
          "distance": 21,
          "ratio": 0.36363636363636365,
          "snomedcode": 403593004,
          "term": "phobic fear of skin cancer "
        },
        {
          "distance": 27,
          "ratio": 0.3076923076923077,
          "snomedcode": 312940006,
          "term": "carcinoma associated retinopathy "
        },
        {
          "distance": 29,
          "ratio": 0.2926829268292683,
          "snomedcode": 427492003,
          "term": "hormone refractory prostate cancer "
        },
        {
          "distance": 28,
          "ratio": 0.2564102564102564,
          "snomedcode": 449420002,
          "term": "malignant neoplasm of cerebellum "
        },
        {
          "distance": 27,
          "ratio": 0.21621621621621623,
          "snomedcode": 449421003,
          "term": "malignant neoplasm of placenta "
        },
        {
          "distance": 35,
          "ratio": 0.21739130434782608,
          "snomedcode": 449377002,
          "term": "malignant neoplasm of pelvic peritoneum "
        },
        {
          "distance": 37,
          "ratio": 0.20833333333333334,
          "snomedcode": 448509007,
          "term": "transglottic malignant neoplasm of larynx "
        },
        {
          "distance": 33,
          "ratio": 0.22727272727272727,
          "snomedcode": 449308006,
          "term": "malignant neoplasm of visceral pleura "
        },
        {
          "distance": 34,
          "ratio": 0.2608695652173913,
          "snomedcode": 700064004,
          "term": "familial nonpolyposis colorectal cancer "
        },
        {
          "distance": 34,
          "ratio": 0.2608695652173913,
          "snomedcode": 699346009,
          "term": "hereditary cancer-predisposing syndrome "
        },
        {
          "distance": 36,
          "ratio": 0.2127659574468085,
          "snomedcode": 93779009,
          "term": "primary malignant neoplasm of endocervix "
        },
        {
          "distance": 27,
          "ratio": 0.2631578947368421,
          "snomedcode": 372099007,
          "term": "malignant neoplasm of exocervix "
        },
        {
          "distance": 44,
          "ratio": 0.21428571428571427,
          "snomedcode": 94246001,
          "term": "secondary malignant neoplasm of cerebral meninges "
        },
        {
          "distance": 35,
          "ratio": 0.21739130434782608,
          "snomedcode": 94186002,
          "term": "secondary malignant neoplasm of bladder "
        },
        {
          "distance": 23,
          "ratio": 0.24242424242424243,
          "snomedcode": 92613002,
          "term": "carcinoma in situ of ileum "
        },
        {
          "distance": 24,
          "ratio": 0.23529411764705882,
          "snomedcode": 92769003,
          "term": "carcinoma in situ of tongue "
        },
        {
          "distance": 21,
          "ratio": 0.25806451612903225,
          "snomedcode": 92590009,
          "term": "carcinoma in situ of eye "
        },
        {
          "distance": 29,
          "ratio": 0.20512820512820512,
          "snomedcode": 403468003,
          "term": "squamous cell carcinoma of penis "
        },
        {
          "distance": 35,
          "ratio": 0.21739130434782608,
          "snomedcode": 363474009,
          "term": "malignant neoplasm of cerebral meninges "
        },
        {
          "distance": 32,
          "ratio": 0.2727272727272727,
          "snomedcode": 423973006,
          "term": "carcinoma of uterine cervix, invasive "
        },
        {
          "distance": 38,
          "ratio": 0.20408163265306123,
          "snomedcode": 94253005,
          "term": "secondary malignant neoplasm of chest wall "
        },
        {
          "distance": 26,
          "ratio": 0.2222222222222222,
          "snomedcode": 92691004,
          "term": "carcinoma in situ of prostate "
        },
        {
          "distance": 36,
          "ratio": 0.2127659574468085,
          "snomedcode": 94503003,
          "term": "secondary malignant neoplasm of prostate "
        },
        {
          "distance": 38,
          "ratio": 0.20408163265306123,
          "snomedcode": 369523007,
          "term": "secondary malignant neoplasm of left ovary "
        },
        {
          "distance": 29,
          "ratio": 0.20512820512820512,
          "snomedcode": 403891008,
          "term": "squamous cell carcinoma of scalp "
        },
        {
          "distance": 25,
          "ratio": 0.22857142857142856,
          "snomedcode": 373627005,
          "term": "bronchioloalveolar carcinoma "
        },
        {
          "distance": 33,
          "ratio": 0.22727272727272727,
          "snomedcode": 92570000,
          "term": "carcinoma in situ of common bile duct "
        },
        {
          "distance": 28,
          "ratio": 0.21052631578947367,
          "snomedcode": 422599000,
          "term": "squamous cell carcinoma of back "
        },
        {
          "distance": 24,
          "ratio": 0.23529411764705882,
          "snomedcode": 92696009,
          "term": "carcinoma in situ of rectum "
        },
        {
          "distance": 35,
          "ratio": 0.21739130434782608,
          "snomedcode": 94254004,
          "term": "secondary malignant neoplasm of choroid "
        },
        {
          "distance": 34,
          "ratio": 0.2222222222222222,
          "snomedcode": 94665001,
          "term": "secondary malignant neoplasm of uterus "
        },
        {
          "distance": 25,
          "ratio": 0.22857142857142856,
          "snomedcode": 92693001,
          "term": "carcinoma in situ of pylorus "
        },
        {
          "distance": 33,
          "ratio": 0.26666666666666666,
          "snomedcode": 424666005,
          "term": "basal cell carcinoma of auricle of ear "
        },
        {
          "distance": 26,
          "ratio": 0.2222222222222222,
          "snomedcode": 92577002,
          "term": "carcinoma in situ of duodenum "
        },
        {
          "distance": 28,
          "ratio": 0.21052631578947367,
          "snomedcode": 92668003,
          "term": "carcinoma in situ of oropharynx "
        },
        {
          "distance": 23,
          "ratio": 0.24242424242424243,
          "snomedcode": 92568009,
          "term": "carcinoma in situ of colon "
        },
        {
          "distance": 29,
          "ratio": 0.20512820512820512,
          "snomedcode": 92612007,
          "term": "carcinoma in situ of hypopharynx "
        },
        {
          "distance": 24,
          "ratio": 0.23529411764705882,
          "snomedcode": 92754004,
          "term": "carcinoma in situ of spleen "
        },
        {
          "distance": 29,
          "ratio": 0.20512820512820512,
          "snomedcode": 92574009,
          "term": "carcinoma in situ of cystic duct "
        },
        {
          "distance": 24,
          "ratio": 0.2857142857142857,
          "snomedcode": 92546004,
          "term": "carcinoma in situ of bladder "
        },
        {
          "distance": 23,
          "ratio": 0.29411764705882354,
          "snomedcode": 109355002,
          "term": "carcinoma in situ, disorder "
        },
        {
          "distance": 23,
          "ratio": 0.24242424242424243,
          "snomedcode": 92679008,
          "term": "carcinoma in situ of penis "
        },
        {
          "distance": 37,
          "ratio": 0.20833333333333334,
          "snomedcode": 425231005,
          "term": "carcinoma of urinary bladder, superficial "
        },
        {
          "distance": 27,
          "ratio": 0.21621621621621623,
          "snomedcode": 92585006,
          "term": "carcinoma in situ of esophagus "
        },
        {
          "distance": 25,
          "ratio": 0.22857142857142856,
          "snomedcode": 92681005,
          "term": "carcinoma in situ of pharynx "
        },
        {
          "distance": 28,
          "ratio": 0.21052631578947367,
          "snomedcode": 92531006,
          "term": "carcinoma in situ of anal canal "
        },
        {
          "distance": 33,
          "ratio": 0.22727272727272727,
          "snomedcode": 94327000,
          "term": "secondary malignant neoplasm of heart "
        },
        {
          "distance": 36,
          "ratio": 0.2127659574468085,
          "snomedcode": 94459006,
          "term": "secondary malignant neoplasm of pancreas "
        },
        {
          "distance": 25,
          "ratio": 0.22857142857142856,
          "snomedcode": 92621008,
          "term": "carcinoma in situ of jejunum "
        },
        {
          "distance": 35,
          "ratio": 0.21739130434782608,
          "snomedcode": 94641004,
          "term": "secondary malignant neoplasm of trachea "
        },
        {
          "distance": 34,
          "ratio": 0.2222222222222222,
          "snomedcode": 425066001,
          "term": "carcinoma of urinary bladder, invasive "
        },
        {
          "distance": 34,
          "ratio": 0.2222222222222222,
          "snomedcode": 94493005,
          "term": "secondary malignant neoplasm of pleura "
        },
        {
          "distance": 24,
          "ratio": 0.23529411764705882,
          "snomedcode": 92634009,
          "term": "carcinoma in situ of larynx "
        },
        {
          "distance": 23,
          "ratio": 0.29411764705882354,
          "snomedcode": 92788005,
          "term": "carcinoma in situ of uterus "
        },
        {
          "distance": 34,
          "ratio": 0.2222222222222222,
          "snomedcode": 94513006,
          "term": "secondary malignant neoplasm of rectum "
        },
        {
          "distance": 25,
          "ratio": 0.2777777777777778,
          "snomedcode": 92672004,
          "term": "carcinoma in situ of pancreas "
        },
        {
          "distance": 32,
          "ratio": 0.23255813953488372,
          "snomedcode": 92581002,
          "term": "carcinoma in situ of endocrine gland "
        },
        {
          "distance": 25,
          "ratio": 0.22857142857142856,
          "snomedcode": 276822007,
          "term": "malignant melanoma of rectum "
        },
        {
          "distance": 18,
          "ratio": 0.2857142857142857,
          "snomedcode": 254900004,
          "term": "carcinoma of prostate "
        },
        {
          "distance": 34,
          "ratio": 0.2222222222222222,
          "snomedcode": 269544008,
          "term": "carcinoma of the rectosigmoid junction "
        },
        {
          "distance": 30,
          "ratio": 0.24390243902439024,
          "snomedcode": 423535002,
          "term": "basal cell carcinoma of chest wall "
        },
        {
          "distance": 22,
          "ratio": 0.25,
          "snomedcode": 422758009,
          "term": "carcinoma of nasal meatus "
        },
        {
          "distance": 28,
          "ratio": 0.21052631578947367,
          "snomedcode": 443488001,
          "term": "malignant neoplasm of anorectum "
        },
        {
          "distance": 25,
          "ratio": 0.22857142857142856,
          "snomedcode": 423463003,
          "term": "basal cell carcinoma of back "
        },
        {
          "distance": 28,
          "ratio": 0.21621621621621623,
          "snomedcode": 255124004,
          "term": "metastasis to peripheral nerve "
        },
        {
          "distance": 21,
          "ratio": 0.25806451612903225,
          "snomedcode": 423349005,
          "term": "carcinoma of tip of nose "
        },
        {
          "distance": 26,
          "ratio": 0.2222222222222222,
          "snomedcode": 403915004,
          "term": "basal cell carcinoma of scalp "
        },
        {
          "distance": 35,
          "ratio": 0.21739130434782608,
          "snomedcode": 402521004,
          "term": "basal cell carcinoma of upper extremity "
        },
        {
          "distance": 25,
          "ratio": 0.22857142857142856,
          "snomedcode": 402520003,
          "term": "basal cell carcinoma of hand "
        },
        {
          "distance": 35,
          "ratio": 0.21739130434782608,
          "snomedcode": 92747005,
          "term": "carcinoma in situ of skin of upper limb "
        },
        {
          "distance": 35,
          "ratio": 0.21739130434782608,
          "snomedcode": 402522006,
          "term": "basal cell carcinoma of lower extremity "
        },
        {
          "distance": 25,
          "ratio": 0.22857142857142856,
          "snomedcode": 402509003,
          "term": "basal cell carcinoma of neck "
        },
        {
          "distance": 29,
          "ratio": 0.20512820512820512,
          "snomedcode": 403916003,
          "term": "basal cell carcinoma of forehead "
        },
        {
          "distance": 32,
          "ratio": 0.23255813953488372,
          "snomedcode": 403919005,
          "term": "basal cell carcinoma of upper eyelid "
        },
        {
          "distance": 25,
          "ratio": 0.22857142857142856,
          "snomedcode": 402508006,
          "term": "basal cell carcinoma of chin "
        },
        {
          "distance": 23,
          "ratio": 0.29411764705882354,
          "snomedcode": 402820007,
          "term": "basal cell carcinoma of ear "
        },
        {
          "distance": 25,
          "ratio": 0.22857142857142856,
          "snomedcode": 254701007,
          "term": "basal cell carcinoma of skin "
        },
        {
          "distance": 29,
          "ratio": 0.25,
          "snomedcode": 92724009,
          "term": "carcinoma in situ of skin of face "
        },
        {
          "distance": 33,
          "ratio": 0.22727272727272727,
          "snomedcode": 92547008,
          "term": "carcinoma in situ of body of pancreas "
        },
        {
          "distance": 27,
          "ratio": 0.21621621621621623,
          "snomedcode": 403917007,
          "term": "basal cell carcinoma of temple "
        },
        {
          "distance": 25,
          "ratio": 0.22857142857142856,
          "snomedcode": 402818009,
          "term": "basal cell carcinoma of nose "
        },
        {
          "distance": 34,
          "ratio": 0.2222222222222222,
          "snomedcode": 92560002,
          "term": "carcinoma in situ of cardia of stomach "
        },
        {
          "distance": 24,
          "ratio": 0.2857142857142857,
          "snomedcode": 402519009,
          "term": "basal cell carcinoma of face "
        },
        {
          "distance": 28,
          "ratio": 0.2564102564102564,
          "snomedcode": 92719006,
          "term": "carcinoma in situ of skin of ear "
        },
        {
          "distance": 22,
          "ratio": 0.25,
          "snomedcode": 408647009,
          "term": "adenocarcinoma of stomach "
        },
        {
          "distance": 38,
          "ratio": 0.20408163265306123,
          "snomedcode": 353561000119103,
          "term": "secondary malignant neoplasm of right lung "
        },
        {
          "distance": 34,
          "ratio": 0.2222222222222222,
          "snomedcode": 403894000,
          "term": "squamous cell carcinoma of skin of ear "
        },
        {
          "distance": 35,
          "ratio": 0.21739130434782608,
          "snomedcode": 403892001,
          "term": "squamous cell carcinoma of skin of face "
        },
        {
          "distance": 30,
          "ratio": 0.24390243902439024,
          "snomedcode": 363413005,
          "term": "malignant tumor of splenic flexure "
        },
        {
          "distance": 30,
          "ratio": 0.24390243902439024,
          "snomedcode": 363407001,
          "term": "malignant tumor of hepatic flexure "
        },
        {
          "distance": 38,
          "ratio": 0.20408163265306123,
          "snomedcode": 716593008,
          "term": "carcinoma of salivary gland type of breast "
        },
        {
          "distance": 40,
          "ratio": 0.23076923076923078,
          "snomedcode": 718220008,
          "term": "hereditary breast and ovarian cancer syndrome "
        },
        {
          "distance": 28,
          "ratio": 0.3,
          "snomedcode": 722103009,
          "term": "hormone sensitive prostate cancer "
        },
        {
          "distance": 40,
          "ratio": 0.23076923076923078,
          "snomedcode": 722089009,
          "term": "obstructive nephropathy due to bladder cancer "
        },
        {
          "distance": 22,
          "ratio": 0.35294117647058826,
          "snomedcode": 722712000,
          "term": "occupational cancer of skin "
        },
        {
          "distance": 30,
          "ratio": 0.2857142857142857,
          "snomedcode": 723301009,
          "term": "squamous non-small cell lung cancer "
        },
        {
          "distance": 44,
          "ratio": 0.21428571428571427,
          "snomedcode": 737058005,
          "term": "microsatellite instability-high colorectal cancer "
        },
        {
          "distance": 47,
          "ratio": 0.2033898305084746,
          "snomedcode": 762594002,
          "term": "chronic pain following surgical procedure for cancer "
        },
        {
          "distance": 43,
          "ratio": 0.21818181818181817,
          "snomedcode": 771080008,
          "term": "hereditary site-specific ovarian cancer syndrome "
        },
        {
          "distance": 41,
          "ratio": 0.22641509433962265,
          "snomedcode": 773345007,
          "term": "oligodontia and cancer predisposition syndrome "
        },
        {
          "distance": 25,
          "ratio": 0.32432432432432434,
          "snomedcode": 1082701000112100,
          "term": "locally advanced breast cancer "
        }
      ]
    },
    {
      "display_term": "Malignant Neoplasms",
      "ent_type": "Condition",
      "negative_meaning": false,
      "preferred_term": "Malignant Neoplasms",
      "sem_types": [
        "neop"
      ],
      "sent_num": 1,
      "snomed_code": [],
      "snomed_term": [
        {
          "distance": 37,
          "ratio": 0.4657534246575342,
          "snomedcode": 186725009,
          "term": "hiv disease resulting in multiple malignant neoplasms "
        },
        {
          "distance": 80,
          "ratio": 0.29310344827586204,
          "snomedcode": 187447001,
          "term": "hiv disease resulting in other malignant neoplasms of lymphoid, hematopoietic and related tissue "
        },
        {
          "distance": 34,
          "ratio": 0.4857142857142857,
          "snomedcode": 187448006,
          "term": "hiv disease resulting in other malignant neoplasms "
        },
        {
          "distance": 34,
          "ratio": 0.4857142857142857,
          "snomedcode": 188478004,
          "term": "malignant neoplasms of independent  multiple sites "
        },
        {
          "distance": 42,
          "ratio": 0.4358974358974359,
          "snomedcode": 188705003,
          "term": "malignant neoplasms of lymphoid and histiocytic tissue nos "
        },
        {
          "distance": 30,
          "ratio": 0.5151515151515151,
          "snomedcode": 190103002,
          "term": "melanoma and other malignant neoplasms of skin "
        },
        {
          "distance": 49,
          "ratio": 0.4,
          "snomedcode": 190151005,
          "term": "malignant neoplasms of lymphoid, hematopoietic and related tissue "
        },
        {
          "distance": 65,
          "ratio": 0.33663366336633666,
          "snomedcode": 190163000,
          "term": "other specified malignant neoplasms of lymphoid, hematopoietic and related tissue "
        }
      ],
      "source_text": [
        "cancer"
      ]
    },
    {
      "display_term": "Primary malignant neoplasm",
      "ent_type": "Condition",
      "negative_meaning": false,
      "preferred_term": "Primary malignant neoplasm",
      "sem_types": [
        "neop"
      ],
      "sent_num": 1,
      "snomed_code": [],
      "snomed_term": [
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 95214007,
          "term": "primary malignant neoplasm of liver "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 93755007,
          "term": "primary malignant neoplasm of choroid, primary "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 93756008,
          "term": "primary malignant neoplasm of ciliary body, primary "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93764002,
          "term": "primary malignant neoplasm of conjunctiva, primary "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93766000,
          "term": "primary malignant neoplasm of cornea, primary "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93791000,
          "term": "primary malignant neoplasm of eye, primary "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93987004,
          "term": "primary malignant neoplasm of retina, primary "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93998003,
          "term": "primary malignant neoplasm of sclera, primary "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 94128004,
          "term": "primary malignant neoplasm of uveal tract, primary "
        },
        {
          "distance": 38,
          "ratio": 0.5,
          "snomedcode": 109948008,
          "term": "overlapping malignant neoplasm of eye and adnexa, primary "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 187771009,
          "term": "primary malignant neoplasm of liver nos "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 109356001,
          "term": "primary malignant neoplasm of unspecified site "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 109357005,
          "term": "primary malignant neoplasm of ill-defined site "
        },
        {
          "distance": 32,
          "ratio": 0.6024096385542169,
          "snomedcode": 109359008,
          "term": "primary malignant neoplasm of independent multiple sites "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 109370001,
          "term": "primary malignant neoplasm of laryngeal cartilage "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 109828002,
          "term": "primary malignant neoplasm of salivary gland duct "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 109834009,
          "term": "primary malignant neoplasm of branchial cleft "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 109840002,
          "term": "primary malignant neoplasm of cloacogenic zone "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 109849001,
          "term": "primary malignant neoplasm of other parts of pancreas "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 109876001,
          "term": "primary malignant neoplasm of descended testis "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 109882003,
          "term": "primary malignant neoplasm of fundus uteri "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 109915008,
          "term": "primary malignant neoplasm of meninges "
        },
        {
          "distance": 63,
          "ratio": 0.43859649122807015,
          "snomedcode": 109918005,
          "term": "primary malignant neoplasm of peripheral nerves and peripheral autonomic nervous system "
        },
        {
          "distance": 48,
          "ratio": 0.5050505050505051,
          "snomedcode": 109921007,
          "term": "primary malignant neoplasm of peripheral nerve of head, face and/or neck "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 109923005,
          "term": "primary malignant neoplasm of peripheral nerves of head "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 109925003,
          "term": "primary malignant neoplasm of peripheral nerves of face "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 109927006,
          "term": "primary malignant neoplasm of peripheral nerves of neck "
        },
        {
          "distance": 37,
          "ratio": 0.5681818181818182,
          "snomedcode": 109931000,
          "term": "primary malignant neoplasm of peripheral nerves of upper limb "
        },
        {
          "distance": 35,
          "ratio": 0.5813953488372093,
          "snomedcode": 109933002,
          "term": "primary malignant neoplasm of peripheral nerves of shoulder "
        },
        {
          "distance": 37,
          "ratio": 0.5681818181818182,
          "snomedcode": 109937001,
          "term": "primary malignant neoplasm of peripheral nerves of lower limb "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 109939003,
          "term": "primary malignant neoplasm of peripheral nerves of hip "
        },
        {
          "distance": 33,
          "ratio": 0.5952380952380952,
          "snomedcode": 109941002,
          "term": "primary malignant neoplasm of peripheral nerves of thorax "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 109943004,
          "term": "primary malignant neoplasm of peripheral nerves of abdomen "
        },
        {
          "distance": 40,
          "ratio": 0.5494505494505495,
          "snomedcode": 109945006,
          "term": "primary malignant neoplasm of peripheral nerves of pelvis region "
        },
        {
          "distance": 32,
          "ratio": 0.6024096385542169,
          "snomedcode": 109947003,
          "term": "primary malignant neoplasm of peripheral nerves of trunk "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 310504009,
          "term": "primary malignant neoplasm of unknown site "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 128466006,
          "term": "primary malignant neoplasm of articular cartilage "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 363745004,
          "term": "primary malignant neoplasm of gastrointestinal tract "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 369487007,
          "term": "primary malignant neoplasm of seminal vesicle "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 369513003,
          "term": "primary malignant neoplasm of left fallopian tube "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 369520005,
          "term": "primary malignant neoplasm of right fallopian tube "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 369522002,
          "term": "primary malignant neoplasm of left ovary "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 369529006,
          "term": "primary malignant neoplasm of right ovary "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 93656003,
          "term": "primary malignant neoplasm of abdominal esophagus "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93657007,
          "term": "primary malignant neoplasm of abducens nerve "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93658002,
          "term": "primary malignant neoplasm of accessory nerve "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93659005,
          "term": "primary malignant neoplasm of accessory sinus "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93660000,
          "term": "primary malignant neoplasm of acoustic nerve "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 93661001,
          "term": "primary malignant neoplasm of acromion "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 93662008,
          "term": "primary malignant neoplasm of adenoid "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93663003,
          "term": "primary malignant neoplasm of adnexa of skin "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93664009,
          "term": "primary malignant neoplasm of adrenal cortex "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93665005,
          "term": "primary malignant neoplasm of adrenal gland "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93666006,
          "term": "primary malignant neoplasm of adrenal medulla "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 93667002,
          "term": "primary malignant neoplasm of alveolar ridge mucosa "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 93668007,
          "term": "primary malignant neoplasm of ampulla of vater "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 93669004,
          "term": "primary malignant neoplasm of anal canal "
        },
        {
          "distance": 35,
          "ratio": 0.5813953488372093,
          "snomedcode": 93670003,
          "term": "primary malignant neoplasm of anterior aspect of epiglottis "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93671004,
          "term": "primary malignant neoplasm of anterior mediastinum "
        },
        {
          "distance": 40,
          "ratio": 0.5494505494505495,
          "snomedcode": 93672006,
          "term": "primary malignant neoplasm of anterior portion of floor of mouth "
        },
        {
          "distance": 35,
          "ratio": 0.5813953488372093,
          "snomedcode": 93673001,
          "term": "primary malignant neoplasm of anterior two-thirds of tongue "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 93674007,
          "term": "primary malignant neoplasm of anterior wall of nasopharynx "
        },
        {
          "distance": 38,
          "ratio": 0.5617977528089888,
          "snomedcode": 93675008,
          "term": "primary malignant neoplasm of anterior wall of urinary bladder "
        },
        {
          "distance": 10,
          "ratio": 0.819672131147541,
          "snomedcode": 93676009,
          "term": "primary malignant neoplasm of anus "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93677000,
          "term": "primary malignant neoplasm of aortic body "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 93678005,
          "term": "primary malignant neoplasm of apex of urinary bladder "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 93679002,
          "term": "primary malignant neoplasm of appendix "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 93680004,
          "term": "primary malignant neoplasm of areola of female breast "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 93681000,
          "term": "primary malignant neoplasm of areola of male breast "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 93682007,
          "term": "primary malignant neoplasm of upper arm "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93683002,
          "term": "primary malignant neoplasm of ascending colon "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93684008,
          "term": "primary malignant neoplasm of axilla "
        },
        {
          "distance": 36,
          "ratio": 0.5747126436781609,
          "snomedcode": 93685009,
          "term": "primary malignant neoplasm of axillary tail of female breast "
        },
        {
          "distance": 10,
          "ratio": 0.819672131147541,
          "snomedcode": 93686005,
          "term": "primary malignant neoplasm of back "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93687001,
          "term": "primary malignant neoplasm of base of tongue "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93688006,
          "term": "primary malignant neoplasm of biliary tract "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 93689003,
          "term": "primary malignant neoplasm of bladder "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 93690007,
          "term": "primary malignant neoplasm of blood vessel of abdomen "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 93691006,
          "term": "primary malignant neoplasm of blood vessel of arm "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 93692004,
          "term": "primary malignant neoplasm of blood vessel of axilla "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 93693009,
          "term": "primary malignant neoplasm of blood vessel of buttock "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93694003,
          "term": "primary malignant neoplasm of blood vessel of face "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 93695002,
          "term": "primary malignant neoplasm of blood vessel of finger "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93696001,
          "term": "primary malignant neoplasm of blood vessel of foot "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 93697005,
          "term": "primary malignant neoplasm of blood vessel of forearm "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93698000,
          "term": "primary malignant neoplasm of blood vessel of hand "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93699008,
          "term": "primary malignant neoplasm of blood vessel of head "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 93700009,
          "term": "primary malignant neoplasm of blood vessel of hip "
        },
        {
          "distance": 37,
          "ratio": 0.5681818181818182,
          "snomedcode": 93701008,
          "term": "primary malignant neoplasm of blood vessel of inguinal region "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 93702001,
          "term": "primary malignant neoplasm of blood vessel of leg "
        },
        {
          "distance": 32,
          "ratio": 0.6024096385542169,
          "snomedcode": 93703006,
          "term": "primary malignant neoplasm of blood vessel of lower limb "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93704000,
          "term": "primary malignant neoplasm of blood vessel of neck "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 93705004,
          "term": "primary malignant neoplasm of blood vessel of pelvis "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 93706003,
          "term": "primary malignant neoplasm of blood vessel of perineum "
        },
        {
          "distance": 37,
          "ratio": 0.5681818181818182,
          "snomedcode": 93707007,
          "term": "primary malignant neoplasm of blood vessel of popliteal space "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 93708002,
          "term": "primary malignant neoplasm of blood vessel of shoulder "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 93709005,
          "term": "primary malignant neoplasm of blood vessel of thigh "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 93710000,
          "term": "primary malignant neoplasm of blood vessel of thorax "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 93711001,
          "term": "primary malignant neoplasm of blood vessel of toe "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 93712008,
          "term": "primary malignant neoplasm of blood vessel of trunk "
        },
        {
          "distance": 32,
          "ratio": 0.6024096385542169,
          "snomedcode": 93713003,
          "term": "primary malignant neoplasm of blood vessel of upper limb "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93714009,
          "term": "primary malignant neoplasm of blood vessel "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 93715005,
          "term": "primary malignant neoplasm of body of pancreas "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93716006,
          "term": "primary malignant neoplasm of body of penis "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93717002,
          "term": "primary malignant neoplasm of body of stomach "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93718007,
          "term": "primary malignant neoplasm of body of uterus "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93720005,
          "term": "primary malignant neoplasm of bone marrow "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93721009,
          "term": "primary malignant neoplasm of bone of face "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 93722002,
          "term": "primary malignant neoplasm of bone of lower limb "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93723007,
          "term": "primary malignant neoplasm of bone of skull "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 93724001,
          "term": "primary malignant neoplasm of bone of upper limb "
        },
        {
          "distance": 10,
          "ratio": 0.819672131147541,
          "snomedcode": 93725000,
          "term": "primary malignant neoplasm of bone "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 93726004,
          "term": "primary malignant neoplasm of brain stem "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 93727008,
          "term": "primary malignant neoplasm of brain "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93728003,
          "term": "primary malignant neoplasm of broad ligament "
        },
        {
          "distance": 33,
          "ratio": 0.5952380952380952,
          "snomedcode": 93729006,
          "term": "primary malignant neoplasm of bronchus of left lower lobe "
        },
        {
          "distance": 33,
          "ratio": 0.5952380952380952,
          "snomedcode": 93730001,
          "term": "primary malignant neoplasm of bronchus of left upper lobe "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 93731002,
          "term": "primary malignant neoplasm of bronchus of right lower lobe "
        },
        {
          "distance": 35,
          "ratio": 0.5813953488372093,
          "snomedcode": 93732009,
          "term": "primary malignant neoplasm of bronchus of right middle lobe "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 93733004,
          "term": "primary malignant neoplasm of bronchus of right upper lobe "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 93734005,
          "term": "primary malignant neoplasm of bronchus "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93735006,
          "term": "primary malignant neoplasm of buccal mucosa "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 93736007,
          "term": "primary malignant neoplasm of cecum "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 93737003,
          "term": "primary malignant neoplasm of calcaneus "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 93738008,
          "term": "primary malignant neoplasm of cardia of stomach "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93739000,
          "term": "primary malignant neoplasm of carina "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93740003,
          "term": "primary malignant neoplasm of carotid body "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93741004,
          "term": "primary malignant neoplasm of carpal bone "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 93742006,
          "term": "primary malignant neoplasm of cartilage of nose "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93743001,
          "term": "primary malignant neoplasm of cauda equina "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 93744007,
          "term": "primary malignant neoplasm of central nervous system "
        },
        {
          "distance": 38,
          "ratio": 0.5617977528089888,
          "snomedcode": 93745008,
          "term": "primary malignant neoplasm of central portion of female breast "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 93746009,
          "term": "primary malignant neoplasm of cerebellum "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 93747000,
          "term": "primary malignant neoplasm of cerebral meninges "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 93748005,
          "term": "primary malignant neoplasm of cerebral ventricle "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 93749002,
          "term": "primary malignant neoplasm of cerebrum "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 93750002,
          "term": "primary malignant neoplasm of cervical esophagus "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 93751003,
          "term": "primary malignant neoplasm of cervical vertebral column "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93752005,
          "term": "primary malignant neoplasm of uterine cervix "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 93753000,
          "term": "primary malignant neoplasm of cheek "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 93754006,
          "term": "primary malignant neoplasm of chest wall "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 93757004,
          "term": "primary malignant neoplasm of clavicle "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 93758009,
          "term": "primary malignant neoplasm of clitoris "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93759001,
          "term": "primary malignant neoplasm of coccygeal body "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93760006,
          "term": "primary malignant neoplasm of coccyx "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 93761005,
          "term": "primary malignant neoplasm of colon "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 93762003,
          "term": "primary malignant neoplasm of commissure of lip "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 93763008,
          "term": "primary malignant neoplasm of common bile duct "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93765001,
          "term": "primary malignant neoplasm of soft tissues "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93767009,
          "term": "primary malignant neoplasm of cranial nerve "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 93768004,
          "term": "primary malignant neoplasm of craniopharyngeal duct "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93769007,
          "term": "primary malignant neoplasm of cuboid "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93770008,
          "term": "primary malignant neoplasm of cystic duct "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 93771007,
          "term": "primary malignant neoplasm of descending colon "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 93772000,
          "term": "primary malignant neoplasm of diaphragm "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 93773005,
          "term": "primary malignant neoplasm of dorsal surface of tongue "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 93774004,
          "term": "primary malignant neoplasm of thoracic vertebral column "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 93775003,
          "term": "primary malignant neoplasm of duodenum "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 93776002,
          "term": "primary malignant neoplasm of ectopic female breast tissue "
        },
        {
          "distance": 32,
          "ratio": 0.6024096385542169,
          "snomedcode": 93777006,
          "term": "primary malignant neoplasm of ectopic male breast tissue "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93778001,
          "term": "primary malignant neoplasm of endocardium "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 93779009,
          "term": "primary malignant neoplasm of endocervix "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93780007,
          "term": "primary malignant neoplasm of endocrine gland "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93781006,
          "term": "primary malignant neoplasm of endometrium "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 93782004,
          "term": "primary malignant neoplasm of epicardium "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 93783009,
          "term": "primary malignant neoplasm of epididymis "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 93784003,
          "term": "primary malignant neoplasm of epiglottis "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 93785002,
          "term": "primary malignant neoplasm of esophagus "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93786001,
          "term": "primary malignant neoplasm of ethmoid bone "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93787005,
          "term": "primary malignant neoplasm of ethmoidal sinus "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93788000,
          "term": "primary malignant neoplasm of eustachian tube "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 93789008,
          "term": "primary malignant neoplasm of exocervix "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 93790004,
          "term": "primary malignant neoplasm of extrahepatic bile ducts "
        },
        {
          "distance": 10,
          "ratio": 0.819672131147541,
          "snomedcode": 93792007,
          "term": "primary malignant neoplasm of face "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93793002,
          "term": "primary malignant neoplasm of facial nerve "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93794008,
          "term": "primary malignant neoplasm of fallopian tube "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 93795009,
          "term": "primary malignant neoplasm of false vocal cord "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93796005,
          "term": "primary malignant neoplasm of female breast "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93797001,
          "term": "primary malignant neoplasm of female genital organ "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 93798006,
          "term": "primary malignant neoplasm of femur "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93799003,
          "term": "primary malignant neoplasm of fibula "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 93800004,
          "term": "primary malignant neoplasm of first cuneiform bone of foot "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 93801000,
          "term": "primary malignant neoplasm of flank "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93802007,
          "term": "primary malignant neoplasm of floor of mouth "
        },
        {
          "distance": 10,
          "ratio": 0.819672131147541,
          "snomedcode": 93803002,
          "term": "primary malignant neoplasm of foot "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 93804008,
          "term": "primary malignant neoplasm of forearm "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 93805009,
          "term": "primary malignant neoplasm of prepuce "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93806005,
          "term": "primary malignant neoplasm of frontal bone "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93807001,
          "term": "primary malignant neoplasm of frontal lobe "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93808006,
          "term": "primary malignant neoplasm of frontal sinus "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 93809003,
          "term": "primary malignant neoplasm of fundus of stomach "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93810008,
          "term": "primary malignant neoplasm of gallbladder "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93812000,
          "term": "primary malignant neoplasm of gingival mucosa "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93813005,
          "term": "primary malignant neoplasm of glans penis "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93814004,
          "term": "primary malignant neoplasm of glomus jugulare "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 93815003,
          "term": "primary malignant neoplasm of glossopharyngeal nerve "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 93816002,
          "term": "primary malignant neoplasm of glottis "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93817006,
          "term": "primary malignant neoplasm of great vessels "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 93818001,
          "term": "primary malignant neoplasm of greater curvature of stomach "
        },
        {
          "distance": 9,
          "ratio": 0.8333333333333334,
          "snomedcode": 93819009,
          "term": "primary malignant neoplasm of gum "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93820003,
          "term": "primary malignant neoplasm of hamate bone "
        },
        {
          "distance": 10,
          "ratio": 0.819672131147541,
          "snomedcode": 93821004,
          "term": "primary malignant neoplasm of hand "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93822006,
          "term": "primary malignant neoplasm of hard palate "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 93823001,
          "term": "primary malignant neoplasm of head of pancreas "
        },
        {
          "distance": 10,
          "ratio": 0.819672131147541,
          "snomedcode": 93824007,
          "term": "primary malignant neoplasm of head "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 93825008,
          "term": "primary malignant neoplasm of heart "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 93826009,
          "term": "primary malignant neoplasm of hepatic flexure of colon "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93827000,
          "term": "primary malignant neoplasm of hilus of lung "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 93828005,
          "term": "primary malignant neoplasm of hypoglossal nerve "
        },
        {
          "distance": 49,
          "ratio": 0.5,
          "snomedcode": 93829002,
          "term": "primary malignant neoplasm of hypopharyngeal aspect of aryepiglottic fold "
        },
        {
          "distance": 50,
          "ratio": 0.49504950495049505,
          "snomedcode": 93830007,
          "term": "primary malignant neoplasm of hypopharyngeal aspect of interarytenoid fold "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93831006,
          "term": "primary malignant neoplasm of hypopharynx "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 93832004,
          "term": "primary malignant neoplasm of ileum "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 93833009,
          "term": "primary malignant neoplasm of ilium "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93834003,
          "term": "primary malignant neoplasm of inguinal region "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 93835002,
          "term": "primary malignant neoplasm of inner aspect of lip "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 93836001,
          "term": "primary malignant neoplasm of inner aspect of lower lip "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 93837005,
          "term": "primary malignant neoplasm of inner aspect of upper lip "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 93838000,
          "term": "primary malignant neoplasm of intestinal tract "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 93839008,
          "term": "primary malignant neoplasm of intra-abdominal organs "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 93840005,
          "term": "primary malignant neoplasm of intrahepatic bile ducts "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93841009,
          "term": "primary malignant neoplasm of intrathoracic organs "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 93842002,
          "term": "primary malignant neoplasm of ischium "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93843007,
          "term": "primary malignant neoplasm of islets of langerhans "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 93844001,
          "term": "primary malignant neoplasm of isthmus of uterus "
        },
        {
          "distance": 9,
          "ratio": 0.8333333333333334,
          "snomedcode": 93845000,
          "term": "primary malignant neoplasm of jaw "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 93846004,
          "term": "primary malignant neoplasm of jejunum "
        },
        {
          "distance": 37,
          "ratio": 0.5681818181818182,
          "snomedcode": 93847008,
          "term": "primary malignant neoplasm of junctional region of epiglottis "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 93848003,
          "term": "primary malignant neoplasm of junctional zone of tongue "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93849006,
          "term": "primary malignant neoplasm of kidney "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93850006,
          "term": "primary malignant neoplasm of labia majora "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93851005,
          "term": "primary malignant neoplasm of labia minora "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93852003,
          "term": "primary malignant neoplasm of lacrimal duct "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93853008,
          "term": "primary malignant neoplasm of lacrimal gland "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93854002,
          "term": "primary malignant neoplasm of large intestine "
        },
        {
          "distance": 44,
          "ratio": 0.5263157894736842,
          "snomedcode": 93855001,
          "term": "primary malignant neoplasm of laryngeal aspect of aryepiglottic fold "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93857009,
          "term": "primary malignant neoplasm of laryngeal commissure "
        },
        {
          "distance": 37,
          "ratio": 0.5681818181818182,
          "snomedcode": 93858004,
          "term": "primary malignant neoplasm of laryngeal surface of epiglottis "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93859007,
          "term": "primary malignant neoplasm of larynx "
        },
        {
          "distance": 39,
          "ratio": 0.5555555555555556,
          "snomedcode": 93860002,
          "term": "primary malignant neoplasm of lateral portion of floor of mouth "
        },
        {
          "distance": 33,
          "ratio": 0.5952380952380952,
          "snomedcode": 93861003,
          "term": "primary malignant neoplasm of lateral wall of nasopharynx "
        },
        {
          "distance": 32,
          "ratio": 0.6024096385542169,
          "snomedcode": 93862005,
          "term": "primary malignant neoplasm of lateral wall of oropharynx "
        },
        {
          "distance": 37,
          "ratio": 0.5681818181818182,
          "snomedcode": 93863000,
          "term": "primary malignant neoplasm of lateral wall of urinary bladder "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 93864006,
          "term": "primary malignant neoplasm of left lower lobe of lung "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 93865007,
          "term": "primary malignant neoplasm of left upper lobe of lung "
        },
        {
          "distance": 9,
          "ratio": 0.8333333333333334,
          "snomedcode": 93866008,
          "term": "primary malignant neoplasm of leg "
        },
        {
          "distance": 33,
          "ratio": 0.5952380952380952,
          "snomedcode": 93867004,
          "term": "primary malignant neoplasm of lesser curvature of stomach "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93868009,
          "term": "primary malignant neoplasm of lingual tonsil "
        },
        {
          "distance": 9,
          "ratio": 0.8333333333333334,
          "snomedcode": 93869001,
          "term": "primary malignant neoplasm of lip "
        },
        {
          "distance": 18,
          "ratio": 0.6666666666666666,
          "snomedcode": 93870000,
          "term": "malignant neoplasm of liver "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 93871001,
          "term": "primary malignant neoplasm of long bone of lower limb "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 93872008,
          "term": "primary malignant neoplasm of long bone of upper limb "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 93873003,
          "term": "primary malignant neoplasm of lower gum "
        },
        {
          "distance": 43,
          "ratio": 0.5319148936170213,
          "snomedcode": 93874009,
          "term": "primary malignant neoplasm of lower inner quadrant of female breast "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 93875005,
          "term": "primary malignant neoplasm of lower limb "
        },
        {
          "distance": 43,
          "ratio": 0.5319148936170213,
          "snomedcode": 93876006,
          "term": "primary malignant neoplasm of lower outer quadrant of female breast "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 93877002,
          "term": "primary malignant neoplasm of lower third of esophagus "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 93878007,
          "term": "primary malignant neoplasm of lumbar vertebral column "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93879004,
          "term": "primary malignant neoplasm of lunate bone "
        },
        {
          "distance": 10,
          "ratio": 0.819672131147541,
          "snomedcode": 93880001,
          "term": "primary malignant neoplasm of lung "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93882009,
          "term": "primary malignant neoplasm of main bronchus "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93883004,
          "term": "primary malignant neoplasm of major salivary gland "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93884005,
          "term": "primary malignant neoplasm of male breast "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 93885006,
          "term": "primary malignant neoplasm of male genital organ "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 93886007,
          "term": "primary malignant neoplasm of mandible "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 93887003,
          "term": "primary malignant neoplasm of mastoid air cells "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 93888008,
          "term": "primary malignant neoplasm of maxilla "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93889000,
          "term": "primary malignant neoplasm of maxillary sinus "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 93890009,
          "term": "primary malignant neoplasm of meckel's diverticulum "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93891008,
          "term": "primary malignant neoplasm of mediastinum "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93892001,
          "term": "primary malignant neoplasm of metacarpal bone "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93893006,
          "term": "primary malignant neoplasm of metatarsal bone "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 93894000,
          "term": "primary malignant neoplasm of middle ear "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 93895004,
          "term": "primary malignant neoplasm of middle third of esophagus "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93896003,
          "term": "primary malignant neoplasm of minor salivary gland "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 93897007,
          "term": "primary malignant neoplasm of mouth "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 93898002,
          "term": "primary malignant neoplasm of multiple endocrine glands "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 93899005,
          "term": "primary malignant neoplasm of muscle of abdomen "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 93900000,
          "term": "primary malignant neoplasm of muscle of buttock "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93901001,
          "term": "primary malignant neoplasm of muscle of face "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93902008,
          "term": "primary malignant neoplasm of muscle of head "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93903003,
          "term": "primary malignant neoplasm of muscle of hip "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 93904009,
          "term": "primary malignant neoplasm of muscle of inguinal region "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93905005,
          "term": "primary malignant neoplasm of muscle of lower limb "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93906006,
          "term": "primary malignant neoplasm of muscle of neck "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 93907002,
          "term": "primary malignant neoplasm of muscle of pelvis "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 93908007,
          "term": "primary malignant neoplasm of muscle of perineum "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 93909004,
          "term": "primary malignant neoplasm of muscle of shoulder "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 93910009,
          "term": "primary malignant neoplasm of muscle of thorax "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93911008,
          "term": "primary malignant neoplasm of muscle of trunk "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 93912001,
          "term": "primary malignant neoplasm of muscle of upper limb "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93913006,
          "term": "primary malignant neoplasm of muscle "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 93914000,
          "term": "primary malignant neoplasm of myocardium "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 93915004,
          "term": "primary malignant neoplasm of myometrium "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 93916003,
          "term": "primary malignant neoplasm of nasal bone "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93917007,
          "term": "primary malignant neoplasm of nasal cavity "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93918002,
          "term": "primary malignant neoplasm of nasal concha "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 93920004,
          "term": "primary malignant neoplasm of navicular bone of foot "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93921000,
          "term": "primary malignant neoplasm of scaphoid bone "
        },
        {
          "distance": 10,
          "ratio": 0.819672131147541,
          "snomedcode": 93922007,
          "term": "primary malignant neoplasm of neck "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93923002,
          "term": "primary malignant neoplasm of nervous system "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 93924008,
          "term": "primary malignant neoplasm of nipple of female breast "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 93925009,
          "term": "primary malignant neoplasm of nipple of male breast "
        },
        {
          "distance": 10,
          "ratio": 0.819672131147541,
          "snomedcode": 93926005,
          "term": "primary malignant neoplasm of nose "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93927001,
          "term": "primary malignant neoplasm of occipital bone "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93928006,
          "term": "primary malignant neoplasm of occipital lobe "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 93929003,
          "term": "primary malignant neoplasm of oculomotor nerve "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93930008,
          "term": "primary malignant neoplasm of olfactory nerve "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93931007,
          "term": "primary malignant neoplasm of optic nerve "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 93932000,
          "term": "primary malignant neoplasm of orbit "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 93933005,
          "term": "primary malignant neoplasm of oropharynx "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 93934004,
          "term": "primary malignant neoplasm of ovary "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93935003,
          "term": "primary malignant neoplasm of palate "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93936002,
          "term": "primary malignant neoplasm of palatine bone "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93937006,
          "term": "primary malignant neoplasm of tonsil "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 93938001,
          "term": "primary malignant neoplasm of pancreas "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93939009,
          "term": "primary malignant neoplasm of pancreatic duct "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 93940006,
          "term": "primary malignant neoplasm of para-aortic body "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93941005,
          "term": "primary malignant neoplasm of paraganglion "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93942003,
          "term": "primary malignant neoplasm of parametrium "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 93943008,
          "term": "primary malignant neoplasm of parathyroid gland "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 93944002,
          "term": "primary malignant neoplasm of paraurethral glands "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93945001,
          "term": "primary malignant neoplasm of parietal bone "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93946000,
          "term": "primary malignant neoplasm of parietal lobe "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 93947009,
          "term": "primary malignant neoplasm of parietal peritoneum "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93948004,
          "term": "primary malignant neoplasm of parietal pleura "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93949007,
          "term": "primary malignant neoplasm of parotid gland "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 93950007,
          "term": "primary malignant neoplasm of patella "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93951006,
          "term": "primary malignant neoplasm of pelvic bone "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 93952004,
          "term": "primary malignant neoplasm of pelvic peritoneum "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93953009,
          "term": "primary malignant neoplasm of pelvis "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 93954003,
          "term": "primary malignant neoplasm of penis "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 93955002,
          "term": "primary malignant neoplasm of periadrenal tissue "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 93956001,
          "term": "primary malignant neoplasm of perianal skin "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 93957005,
          "term": "primary malignant neoplasm of pericardium "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 93958000,
          "term": "primary malignant neoplasm of perirenal tissue "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93959008,
          "term": "primary malignant neoplasm of phalanx of foot "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93960003,
          "term": "primary malignant neoplasm of phalanx of hand "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 93961004,
          "term": "primary malignant neoplasm of pharynx "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93962006,
          "term": "primary malignant neoplasm of pineal gland "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 93963001,
          "term": "primary malignant neoplasm of pisiform bone of hand "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93964007,
          "term": "primary malignant neoplasm of pituitary gland "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 93965008,
          "term": "primary malignant neoplasm of placenta "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93966009,
          "term": "primary malignant neoplasm of pleura "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 93967000,
          "term": "primary malignant neoplasm of postcricoid region "
        },
        {
          "distance": 35,
          "ratio": 0.5813953488372093,
          "snomedcode": 93968005,
          "term": "primary malignant neoplasm of posterior hypopharyngeal wall "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 93969002,
          "term": "primary malignant neoplasm of posterior mediastinum "
        },
        {
          "distance": 35,
          "ratio": 0.5813953488372093,
          "snomedcode": 93970001,
          "term": "primary malignant neoplasm of posterior wall of nasopharynx "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 93971002,
          "term": "primary malignant neoplasm of posterior wall of oropharynx "
        },
        {
          "distance": 39,
          "ratio": 0.5555555555555556,
          "snomedcode": 93972009,
          "term": "primary malignant neoplasm of posterior wall of urinary bladder "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 93973004,
          "term": "primary malignant neoplasm of presacral region "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 93974005,
          "term": "primary malignant neoplasm of prostate "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 93975006,
          "term": "primary malignant neoplasm of pubis "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93976007,
          "term": "primary malignant neoplasm of pyloric antrum "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 93977003,
          "term": "primary malignant neoplasm of pylorus "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 93978008,
          "term": "primary malignant neoplasm of pyriform sinus "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93979000,
          "term": "primary malignant neoplasm of radius "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 93980002,
          "term": "primary malignant neoplasm of rectosigmoid junction "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 93981003,
          "term": "primary malignant neoplasm of rectouterine pouch "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 93982005,
          "term": "primary malignant neoplasm of rectovaginal septum "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 93983000,
          "term": "primary malignant neoplasm of rectovesical septum "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93984006,
          "term": "primary malignant neoplasm of rectum "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 93985007,
          "term": "primary malignant neoplasm of renal pelvis "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 93986008,
          "term": "primary malignant neoplasm of respiratory tract "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 93988009,
          "term": "primary malignant neoplasm of retrocecal tissue "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 93989001,
          "term": "primary malignant neoplasm of retromolar area "
        },
        {
          "distance": 9,
          "ratio": 0.8333333333333334,
          "snomedcode": 93990005,
          "term": "primary malignant neoplasm of rib "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 93991009,
          "term": "primary malignant neoplasm of right lower lobe of lung "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 93992002,
          "term": "primary malignant neoplasm of right middle lobe of lung "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 93993007,
          "term": "primary malignant neoplasm of right upper lobe of lung "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 93994001,
          "term": "primary malignant neoplasm of round ligament of uterus "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 93995000,
          "term": "primary malignant neoplasm of sacrococcygeal region "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 93996004,
          "term": "primary malignant neoplasm of sacrum "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 93997008,
          "term": "primary malignant neoplasm of scapula "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 93999006,
          "term": "primary malignant neoplasm of scrotum "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 94000008,
          "term": "primary malignant neoplasm of sebaceous gland "
        },
        {
          "distance": 35,
          "ratio": 0.5813953488372093,
          "snomedcode": 94001007,
          "term": "primary malignant neoplasm of second cuneiform bone of foot "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 94002000,
          "term": "primary malignant neoplasm of septum of nose "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 94003005,
          "term": "primary malignant neoplasm of short bone of lower limb "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 94004004,
          "term": "primary malignant neoplasm of short bone of upper limb "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 94005003,
          "term": "primary malignant neoplasm of shoulder "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94006002,
          "term": "primary malignant neoplasm of sigmoid colon "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 94007006,
          "term": "primary malignant neoplasm of skin of abdomen "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94008001,
          "term": "primary malignant neoplasm of skin of ankle "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 94009009,
          "term": "primary malignant neoplasm of skin of arm "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 94010004,
          "term": "primary malignant neoplasm of skin of axilla "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 94011000,
          "term": "primary malignant neoplasm of skin of back "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 94012007,
          "term": "primary malignant neoplasm of skin of breast "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 94013002,
          "term": "primary malignant neoplasm of skin of buttock "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94014008,
          "term": "primary malignant neoplasm of skin of cheek "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94015009,
          "term": "primary malignant neoplasm of skin of chest "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 94016005,
          "term": "primary malignant neoplasm of skin of chin "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 94017001,
          "term": "primary malignant neoplasm of skin of ear "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94018006,
          "term": "primary malignant neoplasm of skin of elbow "
        },
        {
          "distance": 37,
          "ratio": 0.5681818181818182,
          "snomedcode": 94019003,
          "term": "primary malignant neoplasm of skin of external auditory canal "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 94020009,
          "term": "primary malignant neoplasm of skin of eyebrow "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 94021008,
          "term": "primary malignant neoplasm of skin of eyelid "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 94022001,
          "term": "primary malignant neoplasm of skin of face "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 94023006,
          "term": "primary malignant neoplasm of skin of finger "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 94024000,
          "term": "primary malignant neoplasm of skin of foot "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 94025004,
          "term": "primary malignant neoplasm of skin of forearm "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 94026003,
          "term": "primary malignant neoplasm of skin of forehead "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94027007,
          "term": "primary malignant neoplasm of skin of groin "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 94028002,
          "term": "primary malignant neoplasm of skin of hand "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 94029005,
          "term": "primary malignant neoplasm of skin of hip "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 94030000,
          "term": "primary malignant neoplasm of skin of knee "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 94031001,
          "term": "primary malignant neoplasm of skin of leg "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 94032008,
          "term": "primary malignant neoplasm of skin of lip "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 94033003,
          "term": "primary malignant neoplasm of skin of lower limb "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 94034009,
          "term": "primary malignant neoplasm of skin of neck "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 94035005,
          "term": "primary malignant neoplasm of skin of nose "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 94036006,
          "term": "primary malignant neoplasm of skin of perineum "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 94037002,
          "term": "primary malignant neoplasm of skin of popliteal area "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94038007,
          "term": "primary malignant neoplasm of skin of scalp "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 94039004,
          "term": "primary malignant neoplasm of skin of shoulder "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 94040002,
          "term": "primary malignant neoplasm of skin of temporal region "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94041003,
          "term": "primary malignant neoplasm of skin of thigh "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 94042005,
          "term": "primary malignant neoplasm of skin of toe "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94043000,
          "term": "primary malignant neoplasm of skin of trunk "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 94044006,
          "term": "primary malignant neoplasm of skin of umbilicus "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 94045007,
          "term": "primary malignant neoplasm of skin of upper limb "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94046008,
          "term": "primary malignant neoplasm of skin of wrist "
        },
        {
          "distance": 10,
          "ratio": 0.819672131147541,
          "snomedcode": 94047004,
          "term": "primary malignant neoplasm of skin "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 94048009,
          "term": "primary malignant neoplasm of small intestine "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 94049001,
          "term": "primary malignant neoplasm of soft palate "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 94050001,
          "term": "primary malignant neoplasm of soft tissues of abdomen "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 94051002,
          "term": "primary malignant neoplasm of soft tissues of axilla "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 94052009,
          "term": "primary malignant neoplasm of soft tissues of buttock "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 94053004,
          "term": "primary malignant neoplasm of soft tissues of face "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 94054005,
          "term": "primary malignant neoplasm of soft tissues of head "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 94055006,
          "term": "primary malignant neoplasm of soft tissues of hip "
        },
        {
          "distance": 37,
          "ratio": 0.5681818181818182,
          "snomedcode": 94056007,
          "term": "primary malignant neoplasm of soft tissues of inguinal region "
        },
        {
          "distance": 32,
          "ratio": 0.6024096385542169,
          "snomedcode": 94057003,
          "term": "primary malignant neoplasm of soft tissues of lower limb "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 94058008,
          "term": "primary malignant neoplasm of soft tissues of neck "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 94059000,
          "term": "primary malignant neoplasm of soft tissues of pelvis "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 94060005,
          "term": "primary malignant neoplasm of soft tissues of perineum "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 94061009,
          "term": "primary malignant neoplasm of soft tissues of shoulder "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 94062002,
          "term": "primary malignant neoplasm of soft tissues of thorax "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 94063007,
          "term": "primary malignant neoplasm of soft tissues of trunk "
        },
        {
          "distance": 32,
          "ratio": 0.6024096385542169,
          "snomedcode": 94064001,
          "term": "primary malignant neoplasm of soft tissues of upper limb "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 94065000,
          "term": "primary malignant neoplasm of spermatic cord "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94066004,
          "term": "primary malignant neoplasm of sphenoid bone "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 94067008,
          "term": "primary malignant neoplasm of sphenoidal sinus "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 94068003,
          "term": "primary malignant neoplasm of spinal cord "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 94069006,
          "term": "primary malignant neoplasm of spinal meninges "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 94070007,
          "term": "primary malignant neoplasm of vertebral column "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 94071006,
          "term": "primary malignant neoplasm of spleen "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 94072004,
          "term": "primary malignant neoplasm of splenic flexure of colon "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 94073009,
          "term": "primary malignant neoplasm of sternum "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 94074003,
          "term": "primary malignant neoplasm of stomach "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 94075002,
          "term": "primary malignant neoplasm of subglottis "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 94076001,
          "term": "primary malignant neoplasm of sublingual gland "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 94077005,
          "term": "primary malignant neoplasm of submaxillary gland "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 94078000,
          "term": "primary malignant neoplasm of superior wall of nasopharynx "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 94079008,
          "term": "primary malignant neoplasm of supraclavicular region "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 94080006,
          "term": "primary malignant neoplasm of supraglottis "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 94081005,
          "term": "primary malignant neoplasm of sweat gland "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 94082003,
          "term": "primary malignant neoplasm of tail of pancreas "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 94083008,
          "term": "primary malignant neoplasm of talus "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 94084002,
          "term": "primary malignant neoplasm of tarsal bone "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94085001,
          "term": "primary malignant neoplasm of temporal bone "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94086000,
          "term": "primary malignant neoplasm of temporal lobe "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 94087009,
          "term": "primary malignant neoplasm of testis "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94088004,
          "term": "primary malignant neoplasm of the mesentery "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94089007,
          "term": "primary malignant neoplasm of the mesocolon "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 94090003,
          "term": "primary malignant neoplasm of the omentum "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 94091004,
          "term": "primary malignant neoplasm of the peritoneum "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 94092006,
          "term": "primary malignant neoplasm of the retroperitoneum "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 94093001,
          "term": "primary malignant neoplasm of thigh "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 94094007,
          "term": "primary malignant neoplasm of third cuneiform bone of foot "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 94095008,
          "term": "primary malignant neoplasm of thoracic esophagus "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 94096009,
          "term": "primary malignant neoplasm of thymus "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 94097000,
          "term": "primary malignant neoplasm of thyroglossal duct "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 94098005,
          "term": "primary malignant neoplasm of thyroid gland "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 94099002,
          "term": "primary malignant neoplasm of tibia "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 94100005,
          "term": "primary malignant neoplasm of border of tongue "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 94101009,
          "term": "primary malignant neoplasm of tongue "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 94102002,
          "term": "primary malignant neoplasm of tonsillar fossa "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 94103007,
          "term": "primary malignant neoplasm of tonsillar pillar "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 94104001,
          "term": "primary malignant neoplasm of trachea "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 94105000,
          "term": "primary malignant neoplasm of transverse colon "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 94106004,
          "term": "primary malignant neoplasm of trapezium "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 94107008,
          "term": "primary malignant neoplasm of trapezoid bone "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 94108003,
          "term": "primary malignant neoplasm of trigeminal nerve "
        },
        {
          "distance": 32,
          "ratio": 0.6024096385542169,
          "snomedcode": 94109006,
          "term": "primary malignant neoplasm of trigone of urinary bladder "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 94110001,
          "term": "primary malignant neoplasm of trochlear nerve "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 94111002,
          "term": "primary malignant neoplasm of trunk "
        },
        {
          "distance": 10,
          "ratio": 0.819672131147541,
          "snomedcode": 94112009,
          "term": "primary malignant neoplasm of ulna "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 94113004,
          "term": "primary malignant neoplasm of undescended testis "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 94114005,
          "term": "primary malignant neoplasm of upper gum "
        },
        {
          "distance": 43,
          "ratio": 0.5319148936170213,
          "snomedcode": 94115006,
          "term": "primary malignant neoplasm of upper inner quadrant of female breast "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 94116007,
          "term": "primary malignant neoplasm of upper limb "
        },
        {
          "distance": 43,
          "ratio": 0.5319148936170213,
          "snomedcode": 94117003,
          "term": "primary malignant neoplasm of upper outer quadrant of female breast "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 94118008,
          "term": "primary malignant neoplasm of upper respiratory tract "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 94119000,
          "term": "primary malignant neoplasm of upper third of esophagus "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 94120006,
          "term": "primary malignant neoplasm of urachus "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 94121005,
          "term": "primary malignant neoplasm of ureter "
        },
        {
          "distance": 41,
          "ratio": 0.5434782608695652,
          "snomedcode": 94122003,
          "term": "primary malignant neoplasm of ureteric orifice of urinary bladder "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 94123008,
          "term": "primary malignant neoplasm of urethra "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 94124002,
          "term": "primary malignant neoplasm of urinary bladder neck "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 94125001,
          "term": "primary malignant neoplasm of urinary system "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 94126000,
          "term": "primary malignant neoplasm of uterine adnexa "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 94129007,
          "term": "primary malignant neoplasm of uvula "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 94130002,
          "term": "primary malignant neoplasm of vagina "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 94131003,
          "term": "primary malignant neoplasm of vagus nerve "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 94132005,
          "term": "primary malignant neoplasm of vallecula "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 94133000,
          "term": "primary malignant neoplasm of vas deferens "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 94134006,
          "term": "primary malignant neoplasm of ventral surface of tongue "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 94135007,
          "term": "primary malignant neoplasm of vermilion border of lip "
        },
        {
          "distance": 35,
          "ratio": 0.5813953488372093,
          "snomedcode": 94136008,
          "term": "primary malignant neoplasm of vermilion border of lower lip "
        },
        {
          "distance": 35,
          "ratio": 0.5813953488372093,
          "snomedcode": 94137004,
          "term": "primary malignant neoplasm of vermilion border of upper lip "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 94138009,
          "term": "primary malignant neoplasm of vestibule of mouth "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 94139001,
          "term": "primary malignant neoplasm of vestibule of nose "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 94140004,
          "term": "primary malignant neoplasm of visceral pleura "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 94141000,
          "term": "primary malignant neoplasm of vocal cord "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 94142007,
          "term": "primary malignant neoplasm of vomer "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 94143002,
          "term": "primary malignant neoplasm of vulva "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 94144008,
          "term": "primary malignant neoplasm of waldeyer's ring "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 94145009,
          "term": "primary malignant neoplasm of zygomatic bone "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 38807002,
          "term": "disorder: primary malignant neoplasm "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 371962007,
          "term": "primary malignant neoplasm of abdominal esophagus "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 371963002,
          "term": "primary malignant neoplasm of adrenal cortex "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 371966005,
          "term": "primary malignant neoplasm of adrenal medulla "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 371967001,
          "term": "primary malignant neoplasm of ampulla of vater "
        },
        {
          "distance": 35,
          "ratio": 0.5813953488372093,
          "snomedcode": 371968006,
          "term": "primary malignant neoplasm of anterior two-thirds of tongue "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 371969003,
          "term": "primary malignant neoplasm of apex of urinary bladder "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 371970002,
          "term": "primary malignant neoplasm of biliary tract "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 371971003,
          "term": "primary malignant neoplasm of body of uterus "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 371975007,
          "term": "primary malignant neoplasm of border of tongue "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 371976008,
          "term": "primary malignant neoplasm of buccal mucosa "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 371977004,
          "term": "primary malignant neoplasm of cecum "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 371978009,
          "term": "primary malignant neoplasm of cervical esophagus "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 371980003,
          "term": "primary malignant neoplasm of clitoris "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 371981004,
          "term": "primary malignant neoplasm of commissure of lip "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 371983001,
          "term": "primary malignant neoplasm of endocrine gland "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 371984007,
          "term": "primary malignant neoplasm of esophagus "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 371985008,
          "term": "primary malignant neoplasm of extrahepatic bile ducts "
        },
        {
          "distance": 9,
          "ratio": 0.8333333333333334,
          "snomedcode": 371986009,
          "term": "primary malignant neoplasm of eye "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 371987000,
          "term": "primary malignant neoplasm of fallopian tube "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 371988005,
          "term": "primary malignant neoplasm of false vocal cord "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 371989002,
          "term": "primary malignant neoplasm of glans penis "
        },
        {
          "distance": 9,
          "ratio": 0.8333333333333334,
          "snomedcode": 371990006,
          "term": "primary malignant neoplasm of gum "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 371991005,
          "term": "primary malignant neoplasm of hard palate "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 371992003,
          "term": "primary malignant neoplasm of intestinal tract "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 371993008,
          "term": "primary malignant neoplasm of lacrimal gland "
        },
        {
          "distance": 44,
          "ratio": 0.5263157894736842,
          "snomedcode": 371994002,
          "term": "primary malignant neoplasm of laryngeal aspect of aryepiglottic fold "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 371995001,
          "term": "primary malignant neoplasm of larynx "
        },
        {
          "distance": 9,
          "ratio": 0.8333333333333334,
          "snomedcode": 371996000,
          "term": "primary malignant neoplasm of lip "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 371997009,
          "term": "primary malignant neoplasm of lower gum "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 371998004,
          "term": "primary malignant neoplasm of lower third of esophagus "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 371999007,
          "term": "primary malignant neoplasm of middle third of esophagus "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 372000001,
          "term": "primary malignant neoplasm of minor salivary gland "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 372001002,
          "term": "primary malignant neoplasm of oral cavity "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 372002009,
          "term": "primary malignant neoplasm of palate "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 372003004,
          "term": "primary malignant neoplasm of pancreas "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 372004005,
          "term": "primary malignant neoplasm of parotid gland "
        },
        {
          "distance": 11,
          "ratio": 0.8064516129032258,
          "snomedcode": 372005006,
          "term": "primary malignant neoplasm of penis "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 372006007,
          "term": "primary malignant neoplasm of prepuce "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 372007003,
          "term": "primary malignant neoplasm of retrocecal tissue "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 372008008,
          "term": "primary malignant neoplasm of scaphoid bone "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 372009000,
          "term": "primary malignant neoplasm of scrotum "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 372010005,
          "term": "primary malignant neoplasm of soft tissues "
        },
        {
          "distance": 32,
          "ratio": 0.6024096385542169,
          "snomedcode": 372012002,
          "term": "primary malignant neoplasm of soft tissues of upper limb "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 372013007,
          "term": "primary malignant neoplasm of spermatic cord "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 372014001,
          "term": "primary malignant neoplasm of stomach "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 372015000,
          "term": "primary malignant neoplasm of the mesentery "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 372016004,
          "term": "primary malignant neoplasm of the peritoneum "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 372017008,
          "term": "primary malignant neoplasm of thoracic esophagus "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 372019006,
          "term": "primary malignant neoplasm of thoracic vertebral column "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 372020000,
          "term": "primary malignant neoplasm of tonsil "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 372021001,
          "term": "primary malignant neoplasm of trapezium "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 372022008,
          "term": "primary malignant neoplasm of upper gum "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 372023003,
          "term": "primary malignant neoplasm of upper third of esophagus "
        },
        {
          "distance": 20,
          "ratio": 0.704225352112676,
          "snomedcode": 372024009,
          "term": "primary malignant neoplasm of uterine cervix "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 372025005,
          "term": "primary malignant neoplasm of vagina "
        },
        {
          "distance": 35,
          "ratio": 0.5813953488372093,
          "snomedcode": 372026006,
          "term": "primary malignant neoplasm of vermilion border of lower lip "
        },
        {
          "distance": 35,
          "ratio": 0.5813953488372093,
          "snomedcode": 372027002,
          "term": "primary malignant neoplasm of vermilion border of upper lip "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 372028007,
          "term": "primary malignant neoplasm of vertebral column "
        },
        {
          "distance": 16,
          "ratio": 0.746268656716418,
          "snomedcode": 372030009,
          "term": "primary malignant neoplasm of vocal cord "
        },
        {
          "distance": 2,
          "ratio": 0.9433962264150944,
          "snomedcode": 372087000,
          "term": "primary malignant neoplasm "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 372092003,
          "term": "primary malignant neoplasm of axillary tail of breast "
        },
        {
          "distance": 32,
          "ratio": 0.6024096385542169,
          "snomedcode": 372107001,
          "term": "primary malignant neoplasm of ribs, sternum and clavicle "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 372110008,
          "term": "primary malignant neoplasm of lower lobe, bronchus or lung "
        },
        {
          "distance": 35,
          "ratio": 0.5813953488372093,
          "snomedcode": 372112000,
          "term": "primary malignant neoplasm of middle lobe, bronchus or lung "
        },
        {
          "distance": 37,
          "ratio": 0.5681818181818182,
          "snomedcode": 372115003,
          "term": "primary malignant neoplasm of pelvic bones, sacrum and coccyx "
        },
        {
          "distance": 36,
          "ratio": 0.5747126436781609,
          "snomedcode": 372117006,
          "term": "primary malignant neoplasm of ovary and other uterine adnexa "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 372119009,
          "term": "primary malignant neoplasm of head of pancreas "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 372123001,
          "term": "primary malignant neoplasm of skin head and neck "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 372133009,
          "term": "primary malignant neoplasm of upper limb bones and scapula "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 372135002,
          "term": "primary malignant neoplasm of upper lobe, bronchus or lung "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 372137005,
          "term": "primary malignant neoplasm of breast "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 372139008,
          "term": "primary malignant neoplasm of gallbladder "
        },
        {
          "distance": 33,
          "ratio": 0.5952380952380952,
          "snomedcode": 373088001,
          "term": "primary malignant neoplasm of breast upper outer quadrant "
        },
        {
          "distance": 33,
          "ratio": 0.5952380952380952,
          "snomedcode": 373089009,
          "term": "primary malignant neoplasm of breast upper inner quadrant "
        },
        {
          "distance": 33,
          "ratio": 0.5952380952380952,
          "snomedcode": 373090000,
          "term": "primary malignant neoplasm of breast lower inner quadrant "
        },
        {
          "distance": 33,
          "ratio": 0.5952380952380952,
          "snomedcode": 373091001,
          "term": "primary malignant neoplasm of breast lower outer quadrant "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 399884001,
          "term": "primary malignant neoplasm of blood vessel of upper arm "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 400074007,
          "term": "primary malignant neoplasm of blood vessel of lower leg "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 415177008,
          "term": "primary malignant neoplasm of ear, nose and/or throat "
        },
        {
          "distance": 33,
          "ratio": 0.5952380952380952,
          "snomedcode": 418829009,
          "term": "primary malignant neoplasm of lacrimal drainage structure "
        },
        {
          "distance": 39,
          "ratio": 0.5555555555555556,
          "snomedcode": 424887002,
          "term": "primary malignant neoplasm of thyroid gland, metastatic to bone "
        },
        {
          "distance": 47,
          "ratio": 0.5102040816326531,
          "snomedcode": 422782004,
          "term": "primary malignant neoplasm of ovary, with widespread metastatic disease "
        },
        {
          "distance": 47,
          "ratio": 0.5102040816326531,
          "snomedcode": 423987006,
          "term": "primary malignant neoplasm of vulva, with widespread metastatic disease "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 423005002,
          "term": "primary malignant neoplasm of lacrimal gland duct "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 423195009,
          "term": "primary malignant neoplasm of lacrimal drainage system "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 422736007,
          "term": "primary malignant neoplasm of periperal nerve "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 447109003,
          "term": "primary malignant neoplasm of intrahepatic bile duct "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 447416008,
          "term": "primary malignant neoplasm of distal bile duct "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 446189008,
          "term": "primary malignant neoplasm of extrahepatic bile duct "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 446807009,
          "term": "primary malignant neoplasm of perihilar bile duct "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 449634005,
          "term": "primary malignant neoplasm of skin of lower leg "
        },
        {
          "distance": 15,
          "ratio": 0.7575757575757576,
          "snomedcode": 449635006,
          "term": "primary malignant neoplasm of lower leg "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 449803009,
          "term": "primary malignant neoplasm of dome of urinary bladder "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 10708511000119108,
          "term": "primary malignant neoplasm of uterus "
        },
        {
          "distance": 67,
          "ratio": 0.423728813559322,
          "snomedcode": 703306007,
          "term": "primary hyperaldosteronism due to aldosterone-secreting malignant neoplasm of adrenal gland "
        },
        {
          "distance": 21,
          "ratio": 0.6944444444444444,
          "snomedcode": 71111000119109,
          "term": "recurrent primary malignant neoplasm of vulva "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 707674000,
          "term": "primary malignant epithelial neoplasm of trachea "
        },
        {
          "distance": 60,
          "ratio": 0.45045045045045046,
          "snomedcode": 353661000119102,
          "term": "overlapping primary malignant neoplasm of bone and articular cartilage of lower limb "
        },
        {
          "distance": 33,
          "ratio": 0.5952380952380952,
          "snomedcode": 712849003,
          "term": "primary malignant neoplasm of prostate metastatic to bone "
        },
        {
          "distance": 33,
          "ratio": 0.5952380952380952,
          "snomedcode": 107751000119102,
          "term": "primary malignant mixed mullerian neoplasm of endometrium "
        },
        {
          "distance": 60,
          "ratio": 0.45045045045045046,
          "snomedcode": 713038003,
          "term": "overlapping primary malignant neoplasm of bone and articular cartilage of upper limb "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 45221000119105,
          "term": "primary invasive malignant neoplasm of female breast "
        },
        {
          "distance": 32,
          "ratio": 0.6024096385542169,
          "snomedcode": 96291000119105,
          "term": "primary malignant inflammatory neoplasm of female breast "
        },
        {
          "distance": 44,
          "ratio": 0.5263157894736842,
          "snomedcode": 91171000119107,
          "term": "primary undifferentiated large cell malignant neoplasm of chest wall "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 107771000119106,
          "term": "primary malignant clear cell neoplasm of endometrium "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 67831000119107,
          "term": "primary small cell malignant neoplasm of lung, tnm stage 3 "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 67811000119102,
          "term": "primary small cell malignant neoplasm of lung, tnm stage 1 "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 67821000119109,
          "term": "primary small cell malignant neoplasm of lung, tnm stage 2 "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 67841000119103,
          "term": "primary small cell malignant neoplasm of lung, tnm stage 4 "
        },
        {
          "distance": 12,
          "ratio": 0.7936507936507936,
          "snomedcode": 145831000119103,
          "term": "primary malignant germ cell neoplasm "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 15635721000119108,
          "term": "primary malignant neoplasm of both ovaries "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 721548003,
          "term": "primary malignant neoplasm of lacrimal apparatus "
        },
        {
          "distance": 14,
          "ratio": 0.7692307692307693,
          "snomedcode": 721567004,
          "term": "primary malignant neoplasm of placenta "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 721626003,
          "term": "primary malignant neuroendocrine neoplasm of esophagus "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 721634009,
          "term": "primary malignant neuroendocrine neoplasm of stomach "
        },
        {
          "distance": 38,
          "ratio": 0.5617977528089888,
          "snomedcode": 721635005,
          "term": "primary malignant neuroendocrine neoplasm of cardia of stomach "
        },
        {
          "distance": 36,
          "ratio": 0.5747126436781609,
          "snomedcode": 721636006,
          "term": "primary malignant neuroendocrine neoplasm of body of stomach "
        },
        {
          "distance": 46,
          "ratio": 0.5154639175257731,
          "snomedcode": 721637002,
          "term": "primary malignant neuroendocrine neoplasm of pyloric antrum of stomach "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 721643000,
          "term": "primary malignant mesenchymal neoplasm of stomach "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 721644006,
          "term": "primary malignant neuroendocrine neoplasm of duodenum "
        },
        {
          "distance": 36,
          "ratio": 0.5747126436781609,
          "snomedcode": 721670007,
          "term": "primary malignant neuroendocrine neoplasm of small intestine "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 721673009,
          "term": "primary malignant neuroendocrine neoplasm of appendix "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 721698005,
          "term": "primary malignant neuroendocrine neoplasm of colon "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 721700001,
          "term": "primary malignant neuroendocrine neoplasm of rectum "
        },
        {
          "distance": 35,
          "ratio": 0.5813953488372093,
          "snomedcode": 1081561000119108,
          "term": "recurrent primary malignant neoplasm of right female breast "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 15635801000119106,
          "term": "primary malignant neoplasm of bilateral female breasts "
        },
        {
          "distance": 54,
          "ratio": 0.47619047619047616,
          "snomedcode": 722517009,
          "term": "primary malignant nerve sheath neoplasm of peripheral nervous system structure "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 722518004,
          "term": "primary malignant nerve sheath neoplasm of autonomic nerve "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 722527003,
          "term": "primary malignant neuroendocrine neoplasm of bronchus "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 722528008,
          "term": "primary malignant neuroendocrine neoplasm of lung "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 722529000,
          "term": "primary malignant epithelial neoplasm of nasopharynx "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 722533007,
          "term": "primary malignant neoplasm of esophagogastric junction "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 1081551000119106,
          "term": "recurrent primary malignant neoplasm of left female breast "
        },
        {
          "distance": 18,
          "ratio": 0.7246376811594203,
          "snomedcode": 354361000119107,
          "term": "primary malignant neoplasm of right kidney "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 354351000119105,
          "term": "primary malignant neoplasm of left kidney "
        },
        {
          "distance": 32,
          "ratio": 0.6024096385542169,
          "snomedcode": 724554001,
          "term": "primary malignant epithelial neoplasm of endocrine gland "
        },
        {
          "distance": 73,
          "ratio": 0.4032258064516129,
          "snomedcode": 724866001,
          "term": "primary malignant neoplasm of skin due to and following radiotherapy caused by ionizing radiation "
        },
        {
          "distance": 43,
          "ratio": 0.5319148936170213,
          "snomedcode": 724805000,
          "term": "primary malignant neuroepitheliomatous neoplasm of peripheral nerve "
        },
        {
          "distance": 51,
          "ratio": 0.49019607843137253,
          "snomedcode": 724806004,
          "term": "primary malignant neuroepitheliomatous neoplasm of autonomic nervous system "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 733162002,
          "term": "primary malignant neuroendocrine neoplasm of anus "
        },
        {
          "distance": 31,
          "ratio": 0.6097560975609756,
          "snomedcode": 733163007,
          "term": "primary malignant neuroendocrine neoplasm of anal canal "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 733190003,
          "term": "dementia due to primary malignant neoplasm of brain "
        },
        {
          "distance": 32,
          "ratio": 0.6024096385542169,
          "snomedcode": 733348001,
          "term": "primary malignant neuroendocrine neoplasm of cystic duct "
        },
        {
          "distance": 37,
          "ratio": 0.5681818181818182,
          "snomedcode": 733351008,
          "term": "primary malignant neuroendocrine neoplasm of ampulla of vater "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 733353006,
          "term": "primary malignant neuroendocrine neoplasm of biliary tract "
        },
        {
          "distance": 39,
          "ratio": 0.5555555555555556,
          "snomedcode": 735450006,
          "term": "primary malignant neuroepitheliomatous neoplasm of nasal cavity "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 735735001,
          "term": "primary malignant neuroendocrine neoplasm of pancreas "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 735916009,
          "term": "primary malignant neuroepithelial neoplasm of retina "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 735918005,
          "term": "primary malignant neoplasm of lacrimal caruncle "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 735919002,
          "term": "primary malignant neuroepithelial neoplasm of iris "
        },
        {
          "distance": 34,
          "ratio": 0.5882352941176471,
          "snomedcode": 735920008,
          "term": "primary malignant neuroepithelial neoplasm of ciliary body "
        },
        {
          "distance": 27,
          "ratio": 0.6410256410256411,
          "snomedcode": 735921007,
          "term": "primary malignant neuroepithelial neoplasm of orbit "
        },
        {
          "distance": 19,
          "ratio": 0.7142857142857143,
          "snomedcode": 735917000,
          "term": "primary malignant neoplasm of ocular adnexa "
        },
        {
          "distance": 28,
          "ratio": 0.6329113924050633,
          "snomedcode": 737312002,
          "term": "primary malignant neuroendocrine neoplasm of jejunum "
        },
        {
          "distance": 26,
          "ratio": 0.6493506493506493,
          "snomedcode": 737313007,
          "term": "primary malignant neuroendocrine neoplasm of ileum "
        },
        {
          "distance": 40,
          "ratio": 0.5494505494505495,
          "snomedcode": 762665002,
          "term": "primary malignant neuroendocrine neoplasm of perihilar bile duct "
        },
        {
          "distance": 42,
          "ratio": 0.5376344086021505,
          "snomedcode": 352301000119103,
          "term": "primary malignant neoplasm of soft tissue of right upper extremity "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 354651000119102,
          "term": "primary malignant neoplasm of bone of left lower limb "
        },
        {
          "distance": 36,
          "ratio": 0.5747126436781609,
          "snomedcode": 352321000119107,
          "term": "primary malignant neoplasm of soft tissue of left lower limb "
        },
        {
          "distance": 66,
          "ratio": 0.42735042735042733,
          "snomedcode": 353671000119108,
          "term": "overlapping primary malignant neoplasm of bone and articular cartilage of right upper limb "
        },
        {
          "distance": 41,
          "ratio": 0.5434782608695652,
          "snomedcode": 352231000119103,
          "term": "primary malignant neoplasm of soft tissue of left upper extremity "
        },
        {
          "distance": 42,
          "ratio": 0.5376344086021505,
          "snomedcode": 352311000119100,
          "term": "primary malignant neoplasm of soft tissue of right lower extremity "
        },
        {
          "distance": 65,
          "ratio": 0.43103448275862066,
          "snomedcode": 354621000119105,
          "term": "overlapping primary malignant neoplasm of bone and articular cartilage of left upper limb "
        },
        {
          "distance": 29,
          "ratio": 0.625,
          "snomedcode": 354641000119104,
          "term": "primary malignant neoplasm of bone of left upper limb "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 354671000119106,
          "term": "primary malignant neoplasm of bone of right upper limb "
        },
        {
          "distance": 30,
          "ratio": 0.6172839506172839,
          "snomedcode": 354681000119109,
          "term": "primary malignant neoplasm of bone of right lower limb "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 353431000119107,
          "term": "primary malignant neoplasm of female left breast "
        },
        {
          "distance": 44,
          "ratio": 0.5263157894736842,
          "snomedcode": 353521000119108,
          "term": "primary malignant neoplasm of central portion of female right breast "
        },
        {
          "distance": 43,
          "ratio": 0.5319148936170213,
          "snomedcode": 353441000119103,
          "term": "primary malignant neoplasm of central portion of female left breast "
        },
        {
          "distance": 13,
          "ratio": 0.78125,
          "snomedcode": 148911000119107,
          "term": "primary malignant neoplasm of abdomen "
        },
        {
          "distance": 41,
          "ratio": 0.5434782608695652,
          "snomedcode": 353421000119109,
          "term": "primary malignant neoplasm of axillary tail of left female breast "
        },
        {
          "distance": 42,
          "ratio": 0.5376344086021505,
          "snomedcode": 353501000119104,
          "term": "primary malignant neoplasm of axillary tail of right female breast "
        },
        {
          "distance": 17,
          "ratio": 0.7352941176470589,
          "snomedcode": 226521000119108,
          "term": "primary malignant neoplasm of nasopharynx "
        },
        {
          "distance": 23,
          "ratio": 0.6756756756756757,
          "snomedcode": 354591000119108,
          "term": "primary malignant neoplasm of right male breast "
        },
        {
          "distance": 22,
          "ratio": 0.684931506849315,
          "snomedcode": 354491000119109,
          "term": "primary malignant neoplasm of left male breast "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 350861000119106,
          "term": "primary malignant neoplasm of right adrenal gland "
        },
        {
          "distance": 24,
          "ratio": 0.6666666666666666,
          "snomedcode": 350921000119109,
          "term": "primary malignant neoplasm of left adrenal gland "
        },
        {
          "distance": 25,
          "ratio": 0.6578947368421053,
          "snomedcode": 353511000119101,
          "term": "primary malignant neoplasm of female right breast "
        }
      ],
      "source_text": [
        "cancer"
      ]
    }
  ]
}
}




export const output = [
{
  "patient_id": "e59f1ad6ff23",
  "score": "25",
  "id": "e59f1ad6ff23",
  "gender": "male",
  "race": "White",
  "age": "50",
  "address_city": "Boston",
  "state": "MD",
  "country": "US",
  "postal_code": 2108,
  "maritalStatus": "M",
  "com_language": "English"
},
{
  "patient_id": "d519e25375bb",
  "score": "25",
  "id": "d519e25375bb",
  "gender": "male",
  "race": "White",
  "age": "40",
  "address_city": "Westfield",
  "state": "CA",
  "country": "US",
  "postal_code": 1085,
  "maritalStatus": "S",
  "com_language": "English"
},
{
  "patient_id": "4b490096098b",
  "score": "25",
  "id": "4b490096098b",
  "gender": "male",
  "race": "White",
  "age": "30",
  "address_city": "Hubbardston",
  "state": "MA",
  "country": "US",
  "postal_code": 1452,
  "maritalStatus": "S",
  "com_language": "English"
},
{
  "patient_id": "d99b77d52e48",
  "score": "25",
  "id": "d99b77d52e48",
  "gender": "male",
  "race": "White",
  "age": "40",
  "address_city": "Lynn",
  "state": "CA",
  "country": "US",
  "postal_code": 1901,
  "maritalStatus": "S",
  "com_language": "English"
},
{
  "patient_id": "f6532a9808f4",
  "score": "25",
  "id": "f6532a9808f4",
  "gender": "male",
  "race": "White",
  "age": "50",
  "address_city": "Boston",
  "state": "CA",
  "country": "US",
  "postal_code": 2108,
  "maritalStatus": "M",
  "com_language": "English"
},
{
  "patient_id": "5bc862165d13",
  "score": "25",
  "id": "5bc862165d13",
  "gender": "male",
  "race": "Other",
  "age": "20",
  "address_city": "Newton",
  "state": "CA",
  "country": "US",
  "postal_code": 2458,
  "maritalStatus": "S",
  "com_language": "English"
},
{
  "patient_id": "69fb90d17e6d",
  "score": "25",
  "id": "69fb90d17e6d",
  "gender": "male",
  "race": "Other",
  "age": "50",
  "address_city": "Holyoke",
  "state": "MA",
  "country": "US",
  "postal_code": 1040,
  "maritalStatus": "M",
  "com_language": "English"
},
{
  "patient_id": "f782ba9d613b",
  "score": "25",
  "id": "f782ba9d613b",
  "gender": "male",
  "race": "White",
  "age": "60",
  "address_city": "Sunderland",
  "state": "MA",
  "country": "US",
  "postal_code": 1375,
  "maritalStatus": "S",
  "com_language": "English"
},
{
  "patient_id": "66056b804165",
  "score": "25",
  "id": "66056b804165",
  "gender": "male",
  "race": "White",
  "age": "60",
  "address_city": "Falmouth",
  "state": "MA",
  "country": "US",
  "postal_code": 2540,
  "maritalStatus": "S",
  "com_language": "English"
},
{
  "patient_id": "3239e0405940",
  "score": "25",
  "id": "3239e0405940",
  "gender": "male",
  "race": "White",
  "age": "50",
  "address_city": "Brewster",
  "state": "AL",
  "country": "US",
  "postal_code": 2631,
  "maritalStatus": "M",
  "com_language": "English"
},
{
  "patient_id": "ad1621d1c530",
  "score": "25",
  "id": "ad1621d1c530",
  "gender": "male",
  "race": "Black or African American",
  "age": "30",
  "address_city": "Boston",
  "state": "MA",
  "country": "US",
  "postal_code": 2108,
  "maritalStatus": "M",
  "com_language": "Spanish"
},
{
  "patient_id": "8653d901b943",
  "score": "25",
  "id": "8653d901b943",
  "gender": "male",
  "race": "White",
  "age": "50",
  "address_city": "Boston",
  "state": "AR",
  "country": "US",
  "postal_code": 2108,
  "maritalStatus": "S",
  "com_language": "Russian (Russia)"
},
{
  "patient_id": "826f685338bd",
  "score": "25",
  "id": "826f685338bd",
  "gender": "male",
  "race": "White",
  "age": "40",
  "address_city": "Quincy",
  "state": "MA",
  "country": "FL",
  "postal_code": 2169,
  "maritalStatus": "S",
  "com_language": "English"
}

]


export const pir2response = {
  "response": {
      "exc": {
          "1": [
              {
                  "category": "unknown",
                  "end_inx": 0,
                  "meshentity": null,
                  "ner_category": "age",
                  "score": 0.9992742627575173,
                  "start_inx": 1,
                  "term": "age less"
              },
              {
                  "category": "unknown",
                  "end_inx": 0,
                  "meshentity": null,
                  "ner_category": "upper_bound",
                  "score": 0.9960390433831212,
                  "start_inx": 1,
                  "term": "@NUMBER years"
              },
              {
                  "category": "Demographic",
                  "end_inx": 4,
                  "fb_ner": null,
                  "link": {
                      "from_term_id": "0.1",
                      "to_term_id": "1.1",
                      "type": "has_value"
                  },
                  "neg": false,
                  "start_inx": 1,
                  "term": "age",
                  "termid": "0.1"
              },
              // {
              //     "category": "Value",
              //     "end_inx": 23,
              //     "fb_ner": null,
              //     "link": null,
              //     "neg": false,
              //     "start_inx": 5,
              //     "term": "less than 25 years",
              //     "termid": "1.1"
              // },
              // {
              //     "operator": "<",
              //     "term": "age",
              //     "unit": "years",
              //     "value": 25
              // }
          ]
      },
      "inc": {
          "1": [
              {
                  "category": "disease",
                  "end_inx": 0,
                  "meshentity": {
                      "concept": "",
                      "score": 0,
                      "tree": ""
                  },
                  "ner_category": "chronic_disease",
                  "score": 0.5789118947836267,
                  "start_inx": 1,
                  "term": "@NUMBER"
              },
              {
                  "category": "Condition",
                  "end_inx": 21,
                  "fb_ner": {
                      "category": "disease",
                      "end_inx": 0,
                      "meshentity": {
                          "concept": "Alzheimer Disease",
                          "score": 1,
                          "tree": "C10.228.140.380.100|C10.574.945.249|F03.615.400.100"
                      },
                      "ner_category": "chronic_disease",
                      "score": 0.8523475187181806,
                      "start_inx": 1,
                      "term": "alzheimer 's disease ( cdr @NUMBER , @NUMBER ,"
                  },
                  "link": {
                      "from_term_id": "0.1",
                      "to_term_id": "1.1",
                      "type": "has_value"
                  },
                  "neg": false,
                  "start_inx": 1,
                  "term": "alzheimer 's disease",
                  "termid": "0.1"
              },
              // {
              //     "category": "Value",
              //     "end_inx": 35,
              //     "fb_ner": null,
              //     "link": null,
              //     "neg": false,
              //     "start_inx": 24,
              //     "term": "cdr 0.5 , 1",
              //     "termid": "1.1"
              // },
              // {
              //     "operator": "=",
              //     "term": "Clinical Dementia Rating (CDR)",
              //     "unit": "",
              //     "value": 0.5
              // },
              // {
              //     "operator": "=",
              //     "term": "Clinical Dementia Rating (CDR)",
              //     "unit": "",
              //     "value": 1
              // },
              // {
              //     "operator": "=",
              //     "term": "Clinical Dementia Rating (CDR)",
              //     "unit": "",
              //     "value": 2
              // }
          ],
          "2": [],
          "3": [
              {
                  "category": "unknown",
                  "end_inx": 0,
                  "meshentity": null,
                  "ner_category": "bmi",
                  "score": 0.9434465046568379,
                  "start_inx": 1,
                  "term": "bmi >"
              },
              {
                  "category": "unknown",
                  "end_inx": 0,
                  "meshentity": null,
                  "ner_category": "lower_bound",
                  "score": 0.5203356192565523,
                  "start_inx": 1,
                  "term": "@NUMBER"
              },
              {
                  "category": "Value",
                  "end_inx": 9,
                  "fb_ner": null,
                  "link": null,
                  "neg": false,
                  "start_inx": 5,
                  "term": "> 21",
                  "termid": "0.3"
              },
              // {
              //     "operator": ">",
              //     "term": "bmi",
              //     "unit": "kg/m2",
              //     "value": 21
              // }
          ],
          "4": [
              {
                  "category": "Demographic",
                  "end_inx": 0,
                  "meshentity": null,
                  "ner_category": "language_fluency",
                  "score": 0.999454517918591,
                  "start_inx": 1,
                  "term": "english speaking"
              }
          ]
      }
  }
}


export const pirres3 = {
  "response": {
      "exc": {
          "1": [
              {
                  "approved": false,
                  "category": "unknown",
                  "end_inx": 0,
                  "meshentity": {
                      "concept": "",
                      "score": 0,
                      "tree": ""
                  },
                  "ner_category": "age",
                  "score": 0.9992742627575173,
                  "start_inx": 1,
                  "term": "age less"
              },
              {
                  "approved": false,
                  "category": "unknown",
                  "end_inx": 0,
                  "meshentity": {
                      "concept": "",
                      "score": 0,
                      "tree": ""
                  },
                  "ner_category": "upper_bound",
                  "score": 0.9960390433831212,
                  "start_inx": 1,
                  "term": "@NUMBER years"
              },
              {
                  "approved": false,
                  "category": "Demographic",
                  "end_inx": 4,
                  "fb_ner": null,
                  "link": {
                      "from_term_id": "0.1",
                      "to_term_id": "1.1",
                      "type": "has_value"
                  },
                  "neg": false,
                  "start_inx": 1,
                  "term": "age",
                  "term_obj": null,
                  "termid": "0.1"
              },
              {
                  "approved": true,
                  "category": "Value",
                  "end_inx": 23,
                  "fb_ner": null,
                  "link": null,
                  "neg": false,
                  "start_inx": 5,
                  "term": "less than 25 years",
                  "term_obj": null,
                  "termid": "1.1"
              },
              {
                  "approved": true,
                  "category": "Demographics",
                  "operator": "<",
                  "term": "age",
                  "unit": "years",
                  "value": 25
              }
          ]
      },
      "inc": {
          "1": [
              {
                  "approved": false,
                  "category": "unknown",
                  "end_inx": 0,
                  "meshentity": {
                      "concept": "",
                      "score": 0,
                      "tree": ""
                  },
                  "ner_category": "age",
                  "score": 0.9396323731510496,
                  "start_inx": 1,
                  "term": "aged @NUMBER"
              },
              {
                  "approved": false,
                  "category": "Demographic",
                  "end_inx": 5,
                  "fb_ner": null,
                  "link": {
                      "from_term_id": "0.1",
                      "to_term_id": "1.1",
                      "type": "has_value"
                  },
                  "neg": false,
                  "start_inx": 1,
                  "term": "aged",
                  "term_obj": null,
                  "termid": "0.1"
              },
              {
                  "approved": true,
                  "category": "Value",
                  "end_inx": 31,
                  "fb_ner": null,
                  "link": null,
                  "neg": false,
                  "start_inx": 6,
                  "term": "18 ( inclusive ) or older",
                  "term_obj": null,
                  "termid": "1.1"
              },
              {
                  "approved": false,
                  "category": "Demographic",
                  "end_inx": -1,
                  "fb_ner": null,
                  "link": null,
                  "neg": false,
                  "start_inx": -1,
                  "term": "age",
                  "term_obj": null,
                  "termid": "2.1"
              },
              {
                  "approved": true,
                  "category": "Demographics",
                  "operator": "=",
                  "term": "age",
                  "unit": "years",
                  "value": 18
              }
          ],
          "2": [
              {
                  "approved": false,
                  "category": "diagnostics",
                  "end_inx": 0,
                  "meshentity": {
                      "concept": "",
                      "score": 0,
                      "tree": ""
                  },
                  "ner_category": "treatment",
                  "score": 0.48771614631266264,
                  "start_inx": 1,
                  "term": "revision ("
              },
              {
                  "approved": false,
                  "category": "Condition",
                  "end_inx": 54,
                  "fb_ner": {
                      "approved": true,
                      "category": "disease",
                      "end_inx": 0,
                      "meshentity": {
                          "concept": "",
                          "score": 0,
                          "tree": ""
                      },
                      "ner_category": "chronic_disease",
                      "score": 0.7368037355053868,
                      "start_inx": 1,
                      "term": "mental disorders ,"
                  },
                  "link": null,
                  "neg": false,
                  "start_inx": 38,
                  "term": "mental disorders",
                  "term_obj": null,
                  "termid": "0.2"
              }
          ],
          "3": [
              {
                  "approved": true,
                  "category": "disease",
                  "end_inx": 0,
                  "meshentity": {
                      "concept": "",
                      "score": 0,
                      "tree": ""
                  },
                  "ner_category": "chronic_disease",
                  "score": 0.982782602692228,
                  "start_inx": 1,
                  "term": "ad is"
              },
              {
                  "approved": false,
                  "category": "Condition",
                  "end_inx": 71,
                  "fb_ner": null,
                  "link": null,
                  "neg": false,
                  "start_inx": 61,
                  "term": "neurologic",
                  "term_obj": null,
                  "termid": "0.3"
              },
              {
                  "approved": false,
                  "category": "Condition",
                  "end_inx": 99,
                  "fb_ner": null,
                  "link": null,
                  "neg": false,
                  "start_inx": 76,
                  "term": "communicative disorders",
                  "term_obj": null,
                  "termid": "1.3"
              },
              {
                  "approved": false,
                  "category": "Condition",
                  "end_inx": 110,
                  "fb_ner": {
                      "approved": true,
                      "category": "disease",
                      "end_inx": 0,
                      "meshentity": {
                          "concept": "",
                          "score": 0,
                          "tree": ""
                      },
                      "ner_category": "chronic_disease",
                      "score": 0.6197122104716851,
                      "start_inx": 1,
                      "term": "stroke"
                  },
                  "link": null,
                  "neg": false,
                  "start_inx": 104,
                  "term": "stroke",
                  "term_obj": null,
                  "termid": "2.3"
              },
              {
                  "approved": false,
                  "category": "Condition",
                  "end_inx": 139,
                  "fb_ner": null,
                  "link": null,
                  "neg": false,
                  "start_inx": 119,
                  "term": "alzheimer 's disease",
                  "term_obj": null,
                  "termid": "3.3"
              },
              {
                  "approved": false,
                  "category": "Condition",
                  "end_inx": 190,
                  "fb_ner": null,
                  "link": null,
                  "neg": false,
                  "start_inx": 144,
                  "term": "related disorders association ( nincds - adrda",
                  "term_obj": null,
                  "termid": "4.3"
              },
              {
                  "approved": false,
                  "category": "Condition",
                  "end_inx": 275,
                  "fb_ner": null,
                  "link": null,
                  "neg": false,
                  "start_inx": 258,
                  "term": "nia-aa ) criteria",
                  "term_obj": null,
                  "termid": "5.3"
              }
          ],
          "4": [
              {
                  "approved": false,
                  "category": "Condition",
                  "end_inx": 41,
                  "fb_ner": {
                      "approved": true,
                      "category": "disease",
                      "end_inx": 0,
                      "meshentity": {
                          "concept": "Mild Cognitive Impairment",
                          "score": 1,
                          "tree": "F03.615.250.700"
                      },
                      "ner_category": "chronic_disease",
                      "score": 0.9823635359838813,
                      "start_inx": 1,
                      "term": "mild cognitive impairment ( mci ) is"
                  },
                  "link": null,
                  "neg": false,
                  "start_inx": 16,
                  "term": "mild cognitive impairment",
                  "term_obj": null,
                  "termid": "0.4"
              },
              {
                  "approved": true,
                  "category": "Measurement",
                  "end_inx": 92,
                  "fb_ner": null,
                  "link": null,
                  "neg": false,
                  "start_inx": 84,
                  "term": "criteria",
                  "term_obj": null,
                  "termid": "1.4"
              }
          ],
          "5": [
              {
                  "approved": true,
                  "category": "disease",
                  "end_inx": 0,
                  "meshentity": {
                      "concept": "",
                      "score": 0,
                      "tree": ""
                  },
                  "ner_category": "chronic_disease",
                  "score": 0.6088692809383887,
                  "start_inx": 1,
                  "term": "cognitive"
              },
              {
                  "approved": false,
                  "category": "Condition",
                  "end_inx": 119,
                  "fb_ner": null,
                  "link": null,
                  "neg": false,
                  "start_inx": 99,
                  "term": "cognitive impairment",
                  "term_obj": null,
                  "termid": "0.5"
              }
          ],
          "6": [
              {
                  "approved": true,
                  "category": "disease",
                  "end_inx": 0,
                  "meshentity": {
                      "concept": "Cognition|Cognitive Behavioral Therapy|Cognitive Dysfunction|Cognitive Testing",
                      "score": 1,
                      "tree": "F02.463.188|F03.615.250.700|F04.711.513|F04.754.137.350"
                  },
                  "ner_category": "chronic_disease",
                  "score": 0.9762786803641667,
                  "start_inx": 1,
                  "term": "mci or cognitive impairment"
              },
              {
                  "approved": false,
                  "category": "Condition",
                  "end_inx": 42,
                  "fb_ner": null,
                  "link": null,
                  "neg": false,
                  "start_inx": 22,
                  "term": "cognitive impairment",
                  "term_obj": null,
                  "termid": "1.6"
              }
          ],
          "7": [
              {
                  "approved": false,
                  "category": "diagnostics",
                  "end_inx": 0,
                  "meshentity": {
                      "concept": "",
                      "score": 0,
                      "tree": ""
                  },
                  "ner_category": "treatment",
                  "score": 0.7803493936790644,
                  "start_inx": 1,
                  "term": "mmse @NUMBER"
              },
              {
                  "approved": true,
                  "category": "Value",
                  "end_inx": 47,
                  "fb_ner": null,
                  "link": null,
                  "neg": false,
                  "start_inx": 40,
                  "term": "mmse 24",
                  "term_obj": null,
                  "termid": "0.7"
              },
              {
                  "approved": true,
                  "category": "Observation",
                  "operator": "=",
                  "term": "mmse",
                  "unit": "",
                  "value": 24
              }
          ],
          "8": [
              {
                  "approved": true,
                  "category": "disease",
                  "end_inx": 0,
                  "meshentity": {
                      "concept": "",
                      "score": 0,
                      "tree": ""
                  },
                  "ner_category": "chronic_disease",
                  "score": 0.7913498642061781,
                  "start_inx": 1,
                  "term": "cdr @NUMBER"
              },
              {
                  "approved": false,
                  "category": "Condition",
                  "end_inx": 44,
                  "fb_ner": null,
                  "link": {
                      "from_term_id": "1.8",
                      "to_term_id": "0.8",
                      "type": "has_value"
                  },
                  "neg": false,
                  "start_inx": 13,
                  "term": "other cognitive function scales",
                  "term_obj": null,
                  "termid": "1.8"
              },
              {
                  "approved": true,
                  "category": "Value",
                  "end_inx": 8,
                  "fb_ner": null,
                  "link": null,
                  "neg": false,
                  "start_inx": 5,
                  "term": "0.5",
                  "term_obj": null,
                  "termid": "0.8"
              },
              {
                  "approved": true,
                  "category": "Observation",
                  "operator": "=",
                  "term": "Clinical Dementia Rating (CDR)",
                  "unit": "",
                  "value": 0.5
              }
          ]
      }
  }
}


// "inclusion": ["Aged 55-75. Written informed consent obtained from participant or legal guardian prior to any study-related procedures.","In addition, normal cognition is supported by MMSE, CDR and other cognitive function scales"],                                      
// "exclusion": ["Other medical or psychiatric illness. Refused to complete a cognitive test and provide biospecimen"]

export const pirnewresponse = {
  "initial_event": [],
  "include": [
      {
          "id": 1,
          "criterion": " Aged 55-75 .  Written informed consent obtained from participant or <mark data-entity=\"observation\">legal guardian</mark>prior to any study-related procedures . ",
          "database": null,
          "patient": null,
          "ehrstatus": true
      },
      {
          "id": 2,
          "criterion": " In addition , <mark data-entity=\"condition\">normal cognition</mark>is supported by <mark data-entity=\"measurement\">MMSE</mark>, <mark data-entity=\"measurement\">CDR</mark>and <mark data-entity=\"condition\">other cognitive function</mark><mark data-entity=\"demographic\">scales</mark>",
          "database": null,
          "patient": null,
          "ehrstatus": true
      }
  ],
  "eligibility criteria": {
      "title": null,
      "timestamp": null,
      "text": null,
      "initial_event": [],
      "inclusion_criteria": [
          {
              "pid": 0,
              "sents": [
                  {
                      "text": " Aged 55-75 . ",
                      "include": false,
                      "terms": [],
                      "display": " Aged 55-75 . ",
                      "start_index": null,
                      "end_index": null,
                      "logic_groups": [],
                      "relations": [],
                      "ignore": false
                  },
                  {
                      "text": " Written informed consent obtained from participant or legal guardian prior to any study-related procedures . ",
                      "include": false,
                      "terms": [
                          {
                              "termId": 0,
                              "vocabularyId": null,
                              "text": "legal guardian",
                              "neg": false,
                              "start_index": 55,
                              "end_index": 69,
                              "relations": null,
                              "fbNer": null,
                              "meteMap": null,
                              "condition": null,
                              "rxNorm": null,
                              "loinc": "Internal Server Error",
                              "attribute": null,
                              "attribute_type": null,
                              "optional": "OR",
                              "categorey": "LabTest"
                          }
                      ],
                      "display": " Written informed consent obtained from participant or <mark data-entity=\"observation\">legal guardian</mark>prior to any study-related procedures . ",
                      "start_index": null,
                      "end_index": null,
                      "logic_groups": [
                          [
                              0
                          ]
                      ],
                      "relations": [],
                      "ignore": false
                  }
              ],
              "include": false,
              "pattern": 0
          },
          {
              "pid": 0,
              "sents": [
                  {
                      "text": " In addition , normal cognition is supported by MMSE , CDR and other cognitive function scales ",
                      "include": false,
                      "terms": [
                          {
                              "termId": 0,
                              "vocabularyId": null,
                              "text": "normal cognition",
                              "neg": false,
                              "start_index": 15,
                              "end_index": 31,
                              "relations": null,
                              "fbNer": "{\n  \"NER_Entity\": [\n    {}\n  ]\n}\n",
                              "meteMap": "{\"Meta_Map\":[]}",
                              "condition": "{\"Meta_Map\":[]}",
                              "rxNorm": null,
                              "loinc": null,
                              "attribute": null,
                              "attribute_type": null,
                              "optional": null,
                              "categorey": "Condition"
                          },
                          {
                              "termId": 1,
                              "vocabularyId": null,
                              "text": "Mini-mental state examination",
                              "neg": false,
                              "start_index": 48,
                              "end_index": 52,
                              "relations": null,
                              "fbNer": null,
                              "meteMap": null,
                              "condition": null,
                              "rxNorm": null,
                              "loinc": "Internal Server Error",
                              "attribute": null,
                              "attribute_type": null,
                              "optional": null,
                              "categorey": "LabTest"
                          },
                          {
                              "termId": 2,
                              "vocabularyId": null,
                              "text": "Deoxycytidine",
                              "neg": false,
                              "start_index": 55,
                              "end_index": 58,
                              "relations": null,
                              "fbNer": null,
                              "meteMap": null,
                              "condition": null,
                              "rxNorm": null,
                              "loinc": "Internal Server Error",
                              "attribute": null,
                              "attribute_type": null,
                              "optional": null,
                              "categorey": "LabTest"
                          },
                          {
                              "termId": 3,
                              "vocabularyId": null,
                              "text": "other cognitive function",
                              "neg": false,
                              "start_index": 63,
                              "end_index": 87,
                              "relations": null,
                              "fbNer": "{\n  \"NER_Entity\": [\n    {\n      \"word_scores:chronic_disease\": [\n        [\n          0.8837934464935795, \n          \"cognitive function\"\n        ]\n      ]\n    }\n  ]\n}\n",
                              "meteMap": null,
                              "condition": "{\n  \"NER_Entity\": [\n    {\n      \"word_scores:chronic_disease\": [\n        [\n          0.8837934464935795, \n          \"cognitive function\"\n        ]\n      ]\n    }\n  ]\n}\n",
                              "rxNorm": null,
                              "loinc": null,
                              "attribute": null,
                              "attribute_type": null,
                              "optional": null,
                              "categorey": "Condition"
                          },
                          {
                              "termId": 4,
                              "vocabularyId": null,
                              "text": "scales",
                              "neg": false,
                              "start_index": 88,
                              "end_index": 94,
                              "relations": null,
                              "fbNer": null,
                              "meteMap": null,
                              "condition": null,
                              "rxNorm": null,
                              "loinc": null,
                              "attribute": null,
                              "attribute_type": null,
                              "optional": null,
                              "categorey": "Demographic"
                          }
                      ],
                      "display": " In addition , <mark data-entity=\"condition\">normal cognition</mark>is supported by <mark data-entity=\"measurement\">MMSE</mark>, <mark data-entity=\"measurement\">CDR</mark>and <mark data-entity=\"condition\">other cognitive function</mark><mark data-entity=\"demographic\">scales</mark>",
                      "start_index": null,
                      "end_index": null,
                      "logic_groups": [],
                      "relations": [],
                      "ignore": false
                  }
              ],
              "include": false,
              "pattern": 0
          }
      ],
      "exclusion_criteria": [
          {
              "pid": 0,
              "sents": [
                  {
                      "text": " Other medical or psychiatric illness . ",
                      "include": false,
                      "terms": [
                          {
                              "termId": 0,
                              "vocabularyId": null,
                              "text": "Other medical or psychiatric illness",
                              "neg": false,
                              "start_index": 1,
                              "end_index": 37,
                              "relations": null,
                              "fbNer": "{\n  \"NER_Entity\": [\n    {\n      \"word_scores:chronic_disease\": [\n        [\n          0.9990598596271574, \n          \"psychiatric illness\"\n        ]\n      ]\n    }\n  ]\n}\n",
                              "meteMap": null,
                              "condition": "{\n  \"NER_Entity\": [\n    {\n      \"word_scores:chronic_disease\": [\n        [\n          0.9990598596271574, \n          \"psychiatric illness\"\n        ]\n      ]\n    }\n  ]\n}\n",
                              "rxNorm": null,
                              "loinc": null,
                              "attribute": null,
                              "attribute_type": null,
                              "optional": "OR",
                              "categorey": "Condition"
                          }
                      ],
                      "display": " <mark data-entity=\"condition\">Other medical or psychiatric illness</mark>. ",
                      "start_index": null,
                      "end_index": null,
                      "logic_groups": [
                          [
                              0
                          ]
                      ],
                      "relations": [],
                      "ignore": false
                  },
                  {
                      "text": " Refused to complete a cognitive test and provide biospecimen ",
                      "include": false,
                      "terms": [],
                      "display": " Refused to complete a cognitive test and provide biospecimen ",
                      "start_index": null,
                      "end_index": null,
                      "logic_groups": [],
                      "relations": [],
                      "ignore": false
                  }
              ],
              "include": false,
              "pattern": 0
          }
      ],
      "initial_event_constraint": {
          "startDate": null,
          "endDate": null,
          "daysBefore": 0,
          "daysAfter": 0,
          "limitTo": "All"
      }
  },
  "exclude": [
      {
          "id": 1,
          "criterion": " <mark data-entity=\"condition\">Other medical or psychiatric illness</mark>.  Refused to complete a cognitive test and provide biospecimen ",
          "database": null,
          "patient": null,
          "ehrstatus": true
      }
  ]
}

// ["eligibility criteria"]["inclusion_criteria"][0].sents[0].text;

// ["eligibility criteria"]["inclusion_criteria"][0].sents[1].text;

export const pirnew2 = {
  "initial_event": [],
  "include": [
      {
          "id": 1,
          "criterion": " <mark data-entity=\"condition\">Alzheimers disease</mark>",
          "database": null,
          "patient": null,
          "ehrstatus": true
      },
      {
          "id": 2,
          "criterion": " <mark data-entity=\"measurement\">MMSE</mark><mark data-entity=\"value\">> 24</mark>",
          "database": null,
          "patient": null,
          "ehrstatus": true
      },
      {
          "id": 3,
          "criterion": " <mark data-entity=\"condition\">diabetes</mark>for <mark data-entity=\"temporal\">6 months</mark>",
          "database": null,
          "patient": null,
          "ehrstatus": true
      }
  ],
  "eligibility criteria": {
      "title": null,
      "timestamp": null,
      "text": null,
      "initial_event": [],
      "inclusion_criteria": [
          {
              "pid": 0,
              "sents": [
                  {
                      "text": " Alzheimers disease ",
                      "include": false,
                      "terms": [
                          {
                              "termId": 0,
                              "vocabularyId": null,
                              "text": "Alzheimers disease",
                              "neg": false,
                              "start_index": 1,
                              "end_index": 19,
                              "relations": null,
                              "fbNer": "{\n  \"NER_Entity\": [\n    {\n      \"word_scores:chronic_disease\": [\n        [\n          0.9804138594876671, \n          \"alzheimers disease\"\n        ]\n      ]\n    }\n  ]\n}\n",
                              "meteMap": null,
                              "condition": "{\n  \"NER_Entity\": [\n    {\n      \"word_scores:chronic_disease\": [\n        [\n          0.9804138594876671, \n          \"alzheimers disease\"\n        ]\n      ]\n    }\n  ]\n}\n",
                              "rxNorm": null,
                              "loinc": null,
                              "attribute": null,
                              "attribute_type": null,
                              "optional": null,
                              "categorey": "Condition"
                          }
                      ],
                      "display": " <mark data-entity=\"condition\">Alzheimers disease</mark>",
                      "start_index": null,
                      "end_index": null,
                      "logic_groups": [],
                      "relations": [],
                      "ignore": false
                  }
              ],
              "include": false,
              "pattern": 0
          },
          {
              "pid": 0,
              "sents": [
                  {
                      "text": " MMSE > 24 ",
                      "include": false,
                      "terms": [
                          {
                              "termId": 0,
                              "vocabularyId": null,
                              "text": "Mini-mental state examination",
                              "neg": false,
                              "start_index": 1,
                              "end_index": 5,
                              "relations": null,
                              "fbNer": null,
                              "meteMap": null,
                              "condition": null,
                              "rxNorm": null,
                              "loinc": "Internal Server Error",
                              "attribute": [
                                  {
                                      "termId": 1,
                                      "vocabularyId": null,
                                      "text": "> 24",
                                      "neg": false,
                                      "start_index": 6,
                                      "end_index": 10,
                                      "relations": null,
                                      "fbNer": null,
                                      "meteMap": null,
                                      "condition": null,
                                      "rxNorm": null,
                                      "loinc": null,
                                      "attribute": null,
                                      "attribute_type": null,
                                      "optional": null,
                                      "categorey": "Value"
                                  }
                              ],
                              "attribute_type": [
                                  "has_value"
                              ],
                              "optional": null,
                              "categorey": "LabTest"
                          }
                      ],
                      "display": " <mark data-entity=\"measurement\">MMSE</mark><mark data-entity=\"value\">> 24</mark>",
                      "start_index": null,
                      "end_index": null,
                      "logic_groups": [],
                      "relations": [
                          {
                              "first": 0,
                              "second": 1,
                              "third": "has_value"
                          }
                      ],
                      "ignore": false
                  }
              ],
              "include": false,
              "pattern": 0
          },
          {
              "pid": 0,
              "sents": [
                  {
                      "text": " diabetes for 6 months ",
                      "include": false,
                      "terms": [
                          {
                              "termId": 0,
                              "vocabularyId": null,
                              "text": "diabetes",
                              "neg": false,
                              "start_index": 1,
                              "end_index": 9,
                              "relations": null,
                              "fbNer": "{\n  \"NER_Entity\": [\n    {\n      \"word_scores:chronic_disease\": [\n        [\n          0.9992905297662864, \n          \"diabetes\"\n        ]\n      ]\n    }\n  ]\n}\n",
                              "meteMap": null,
                              "condition": "{\n  \"NER_Entity\": [\n    {\n      \"word_scores:chronic_disease\": [\n        [\n          0.9992905297662864, \n          \"diabetes\"\n        ]\n      ]\n    }\n  ]\n}\n",
                              "rxNorm": null,
                              "loinc": null,
                              "attribute": [
                                  {
                                      "termId": 1,
                                      "vocabularyId": null,
                                      "text": "6 months",
                                      "neg": false,
                                      "start_index": 14,
                                      "end_index": 22,
                                      "relations": null,
                                      "fbNer": null,
                                      "meteMap": null,
                                      "condition": null,
                                      "rxNorm": null,
                                      "loinc": null,
                                      "attribute": null,
                                      "attribute_type": null,
                                      "optional": null,
                                      "categorey": "Temporal"
                                  }
                              ],
                              "attribute_type": [
                                  "has_temporal"
                              ],
                              "optional": null,
                              "categorey": "Condition"
                          }
                      ],
                      "display": " <mark data-entity=\"condition\">diabetes</mark>for <mark data-entity=\"temporal\">6 months</mark>",
                      "start_index": null,
                      "end_index": null,
                      "logic_groups": [],
                      "relations": [
                          {
                              "first": 0,
                              "second": 1,
                              "third": "has_temporal"
                          }
                      ],
                      "ignore": false
                  }
              ],
              "include": false,
              "pattern": 0
          }
      ],
      "exclusion_criteria": [
          {
              "pid": 0,
              "sents": [
                  {
                      "text": " age 20 years old ",
                      "include": false,
                      "terms": [
                          {
                              "termId": 0,
                              "vocabularyId": null,
                              "text": "age",
                              "neg": false,
                              "start_index": 1,
                              "end_index": 4,
                              "relations": null,
                              "fbNer": null,
                              "meteMap": null,
                              "condition": null,
                              "rxNorm": null,
                              "loinc": null,
                              "attribute": [
                                  {
                                      "termId": 1,
                                      "vocabularyId": null,
                                      "text": "20 years old",
                                      "neg": false,
                                      "start_index": 5,
                                      "end_index": 17,
                                      "relations": null,
                                      "fbNer": null,
                                      "meteMap": null,
                                      "condition": null,
                                      "rxNorm": null,
                                      "loinc": null,
                                      "attribute": null,
                                      "attribute_type": null,
                                      "optional": null,
                                      "categorey": "Value"
                                  }
                              ],
                              "attribute_type": [
                                  "has_value"
                              ],
                              "optional": null,
                              "categorey": "Demographic"
                          }
                      ],
                      "display": " <mark data-entity=\"demographic\">age</mark><mark data-entity=\"value\">20 years old</mark>",
                      "start_index": null,
                      "end_index": null,
                      "logic_groups": [],
                      "relations": [
                          {
                              "first": 0,
                              "second": 1,
                              "third": "has_value"
                          }
                      ],
                      "ignore": false
                  }
              ],
              "include": false,
              "pattern": 0
          },
          {
              "pid": 0,
              "sents": [
                  {
                      "text": " Methamphetamine ",
                      "include": false,
                      "terms": [
                          {
                              "termId": 0,
                              "vocabularyId": null,
                              "text": "Methamphetamine",
                              "neg": false,
                              "start_index": 1,
                              "end_index": 16,
                              "relations": null,
                              "fbNer": null,
                              "meteMap": null,
                              "condition": null,
                              "rxNorm": null,
                              "loinc": "[[\"19425-8\",\"19423-3\",\"12477-6\",\"19426-6\",\"12478-4\",\"15405-4\",\"15404-7\",\"19572-7\",\"19424-1\",\"19555-2\",\"14267-9\",\"15406-2\",\"19511-5\",\"19569-3\",\"16367-5\",\"18356-6\",\"19571-9\",\"19427-4\",\"18357-4\",\"19554-5\",\"19557-8\",\"19510-7\",\"19568-5\",\"13498-1\",\"19428-2\",\"17260-1\",\"19515-6\",\"18358-2\",\"14834-6\",\"19514-9\",\"16235-4\",\"19570-1\",\"19556-0\",\"19512-3\"]]",
                              "attribute": null,
                              "attribute_type": null,
                              "optional": null,
                              "categorey": "LabDetails"
                          }
                      ],
                      "display": " <mark data-entity=\"observation\">Methamphetamine</mark>",
                      "start_index": null,
                      "end_index": null,
                      "logic_groups": [],
                      "relations": [],
                      "ignore": false
                  }
              ],
              "include": false,
              "pattern": 0
          }
      ],
      "initial_event_constraint": {
          "startDate": null,
          "endDate": null,
          "daysBefore": 0,
          "daysAfter": 0,
          "limitTo": "All"
      }
  },
  "exclude": [
      {
          "id": 1,
          "criterion": " <mark data-entity=\"demographic\">age</mark><mark data-entity=\"value\">20 years old</mark>",
          "database": null,
          "patient": null,
          "ehrstatus": true
      },
      {
          "id": 2,
          "criterion": " <mark data-entity=\"observation\">Methamphetamine</mark>",
          "database": null,
          "patient": null,
          "ehrstatus": true
      }
  ]
}