import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavMenu } from '../../navMenu';
import Studies from './studies'
import RelatedAccounts from './relatedAccounts'
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
// import ViewContact from '../viewContactDetail';
import Contacts from './contacts';
import Details from './details';
import Billing from './billing';
import Fees from './feeschedule'



const menus = [
  {
    to: "/site/accountDetails",
    text: "Account Details"
  },
  {
    to: "/site/accountDetails/studies",
    text: "Studies"
  },
  {
    to: "/site/accountDetails/relatedAccounts",
    text: "Related Accounts"
  },
  {
    to: "/site/accountDetails/contacts",
    text: "Contacts"
  },
  {
    to: "/site/accountDetails/billing",
    text: "Billing"
  },
  {
    to: "/site/accountDetails/fees",
    text: "Fee Schedule"
  }
]

const ViewAccounttDetail = (props) => {
  useEffect(() => {
    props.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, [])
  const { sponsorAccountId = '', accountName = '' } = props.formValues;
  return (
    <>
      <div className="d-flex justify-content-end">
        <h1 className="mr-auto primary-header">{accountName}:&nbsp;{sponsorAccountId}</h1>
      </div>

      <NavMenu menus={menus} />

      <div className="">
        <Route exact path="/site/accountDetails" component={Details} />
        {/* <Route path="/site/accountDetails/contacts/view" component={ViewContact} /> */}
        <Route exact path='/site/accountDetails/studies' component={Studies} />
        <Route exact path='/site/accountDetails/relatedAccounts' component={RelatedAccounts} />
        {/* <Route exact path='/site/accountDetails/selectContacts' component={AllContactsGrid} /> */}
        <Route exact path="/site/accountDetails/contacts" component={Contacts} />
        <Route path="/site/accountDetails/fees" component={Fees} />
        <Route exact path="/site/accountDetails/billing" component={Billing} />
        {/* <Route exact path="/site/accountDetails/auditTrail" component={AuditTrail} /> */}
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAccounttDetail));




