import React from 'react';
import Resources from '../../siteSrc/components/site/resources'

const ResourcesPage =()=>{
  return(
    <>
    <Resources appName={localStorage.getItem('whichApp')}/>
    </>
  )
}
export default ResourcesPage