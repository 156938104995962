import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavSubMenu } from '../../../navMenu';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import Details from './details'
import Users from './users'
import BackIcon from '../../../../../img/back1.svg'
import AddUsers from './addUsers'
import ViewContact from '../../viewContactDetail';
import UpdateContact from '../../updateContact'



const menus = [
  {
    to: "/account/studyDetails/pag/pagDetails",
    text: "PAG Details"
  },
  {
    to: "/account/studyDetails/pag/pagDetails/users",
    text: "Users"
  },


]

const PagDetails = (props) => {

  const { pagAccountName } = props.formValues;
  const goBack = (event) => {
    event.preventDefault();
    props.history.push('/account/studyDetails/pag')
  }
  return (
    <>
      {/* <div> */}
        {/* <a href="/account/studyDetails/IEcriteria"><Icon>navigate_before</Icon>BACK TO SITE LIST</a> */}
        {/* <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO PAG LIST</div><br />
      </div>
      <div className="d-flex mb-3 justify-content-end">
        {pagAccountName &&
          <div class="secondary-header mr-auto">{pagAccountName}</div>
        }
      </div> */}

      <NavSubMenu menus={menus} />
      <br />
      <div className="">
        <Route exact path="/account/studyDetails/pag/pagDetails" component={Details} />
        <Route exact path="/account/studyDetails/pag/pagDetails/users" component={Users} />
        <Route path="/account/studyDetails/pag/pagDetails/users/view" component={ViewContact} />
        <Route path="/account/studyDetails/pag/pagDetails/users/update" component={UpdateContact} />
        <Route path="/account/studyDetails/pag/pagDetails/addUsers" component={AddUsers} />

      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PagDetails));




