import React, { useEffect, useState, Component } from "react";
import Loadable from 'react-loadable';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { funcDropdown, roles } from '../../common/constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';

import Dashboard from '../../siteSrc/components/site/dashboard/home'
import Account from '../components/accounts'
import Patients from '../../siteSrc/components/site/patient'
import Users from '../components/users'
import Resources from '../components/resources'
import TrailMatch from '../components/trailmatch'
import AddPatientHome from '../../siteSrc/components/site/addPatientHome'
import AddResources from '../../siteSrc/components/site/addResources'
import ViewResources from '../../siteSrc/components/site/viewResources'
import UpdateResources from '../../siteSrc/components/site/updateResources'
import AddUser from '../../siteSrc/components/site/adduser'
import Profile from '../components/profile'
import UpdateProfile from '../components/updateProfile'
import AccountDetailsHome from '../components/accountsHome'
import UpdateAccount from '../components/updateAccount'
import AddUsers from '../../siteSrc/components/site/adduser'
import EditUsers from '../../siteSrc/components/site/editUser'
import UserDetails from '../components/usersHome'
import PatientHome from '../components/patientHome'
import NEWPIR from '../components/newpir'
import studypatients from "../components/studypatients";
const PagHome = (props) => {

  useEffect(()=>{
    funcDropdown()
    roles()
  },[])

  // const LoadCom = (props) => {
  //   let Component = Loadable({
  //     loader: () => import(`../site/${props.fileName}`),
  //     loading() {
  //       return <div className="fullScreen">
  //         <CircularProgress
  //           disableShrink
  //           className="progressCircle"
  //           value={90}
  //           size={100}
  //           thickness={1.5}
  //         />
  //         <span>UI Script loading</span>
  //       </div>
  //     },
  //   });
  //   return <Component {...props} />
  // }


  return (
    <>
      {/* <!-- Content Wrapper --> */}
      <div id="content-wrapper" className="d-flex flex-column">
        {/* <!-- Main Content --> */}
        <div id="content" >
          {/* <!-- Begin Page Content --> */}
          <div className="container-fluid">
            <div className="mt-3 mb-3">
              <Switch>
                {/* <Route path="/activatePatient" component={PatientSignup} />
                <Route path="/activateContact" component={PatientSignup} /> */}
                <Route path="/app/dashboard" component={Dashboard} />
                <Route path="/app/account" component={Account}/>
                <Route path="/app/patients" component={Patients}/>
                <Route path="/app/patient" component={PatientHome}/>
                <Route exact path="/app/users" component={Users}/>
                <Route exact path="/app/users/add" component={AddUsers}/>
                <Route exact path="/app/users/edit" component={EditUsers}/>
                <Route path="/app/users/details" component={UserDetails}/>
                <Route path='/app/trailmatch' component={TrailMatch} />
                <Route path="/app/addPatient"><AddPatientHome newApp={true}/></Route>
                <Route exact path="/app/resources" component={Resources}/>
                <Route path='/app/resources/add' component={AddResources} />
                <Route path='/app/resources/view' component={ViewResources} />
                <Route path='/app/resources/update' component={UpdateResources} />
                <Route  path="/app/users/add" component={AddUser} />
                <Route path="/app/userprofile" component={Profile} />
                <Route path="/app/updateProfile" component={UpdateProfile} />
                <Route path='/app/accountDetails' component={AccountDetailsHome} />
                <Route path='/app/updateAccount' component={UpdateAccount} />
                <Route path="/app/studypatients" component={studypatients}/>
                <Route path="/app/newpir/">
                  <NEWPIR />
                </Route>
                {/* <Route path="/site/:channel/video">
                  <VideoHome />
                </Route>
                <Route path="/site/pir">
                  <PIRSidebarHome />
                </Route>
                <Route path="/site/opensquare">
                  <OpenSquare />
                </Route>
                <Route path="/site/patientrequests">
                  <PatientRequestList />
                </Route>
                <Route path="/site/appointments">
                  <PreCalendar />
                </Route>
                <Route exact path="/site/patients">
                  <LoadCom fileName="patient" />
                </Route>
                <Route exact path="/site/studypatients">
                  <LoadCom fileName="studypatients" />
                </Route>
               
                <Route path="/site/invitepatients">
                  <LoadCom fileName="invitepatients" />
                </Route>
                <Route exact path="/site/users">
                  <LoadCom fileName="siteusers" />
                </Route>
                <Route exact path="/site/users/edit">
                  <LoadCom fileName="editUser" />
                </Route>
                <Route exact path="/site/users/add">
                  <LoadCom fileName="adduser" />
                </Route>
                <Route path="/site/sponsorsearch">
                  <LoadCom fileName="sponsorsearch" />
                </Route>
                <Route path="/site/selectPatients">
                  <LoadCom fileName="selectPatients" />
                </Route>
                <Route path="/site/study" component={StudyHome}>
                </Route>
                <Route exact path='/site/resources' component={Resources} />
                <Route path='/site/resources/add' component={AddResources} />
                <Route path='/site/resources/view' component={ViewResources} />
                <Route path='/site/resources/update' component={UpdateResources} />
                <Route path='/site/patient' component={PatientHome} />
                <Route path='/site/patientappointments' component={DoctorCal} />
                <Route path="/site/users/details">
                  <LoadCom fileName="siteusershome" />
                </Route>
                <Route path="/site/results" component={Globalsearch} />
           
                <Route path="/site/updateProfile" component={UpdateProfile} />
                <Route path="/site/calendar"
                  component={PreCalendar} />
                <Route path="/site/patientdosage" component={PatientAssessments} />
                <Route path="/site/adminFeatures" component={AdminFeatures} />
                <Route path="/site/account" component={AccountGrid} />
               
                <Route path='/site/trailmatch' component={MarketPlaceHome} />
                <Route path='/site/pricingInfo' component={PricingInfo} /> */}
                <Redirect to="/app/account" />
              </Switch>
            </div>
          </div>
        </div>
        {/* <!-- End of Main Content --> */}
      </div>
      {/* <!-- End of Content Wrapper --> */}
    </>
  )
}

export default withRouter(PagHome);


