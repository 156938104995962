import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../../common/apiconfig';
import FormHoc from '../../hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew } from '../../formElements';
import { Dropdowns } from '../../../common/constants';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
let roleObj = {
  roleName: '',
  roleId: '',
  error: false,
  helperText: '',
}
const AddStudyPersonal = (propsobj) => {
  console.log(propsobj.formValues, "SP")
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [accnameShow, showAccName] = useState(false);
  let [portal, setPortal] = useState(false)
  let [roleField, setRoleField] = useState(roleObj);
  let [accountNames, setAccountNames] = useState([])

  useEffect(() => {
    propsobj.UpdateFormField({ value: "", name: "firstName" })
    propsobj.UpdateFormField({ value: "", name: "mname" })
    propsobj.UpdateFormField({ value: "", name: "lastName" })
    propsobj.UpdateFormField({ value: "", name: "accountName" })
    propsobj.UpdateFormField({ value: "", name: "position" })
    propsobj.UpdateFormField({ value: "", name: "roles" })
    propsobj.UpdateFormField({ value: "", name: "lang" })
    propsobj.UpdateFormField({ value: "", name: "email" })
    propsobj.UpdateFormField({ value: "", name: "phone" })
    var data = propsobj.formValues.AccountnamesArr
    var data1 = propsobj.formValues.AccountidsArr
    var arr = []
    for (var i = 0; i < data.length; i++) {
      var obj = {
        value: data1[i],
        label: data[i]
      }
      arr.push(obj)
    }
    setAccountNames(arr)

  }, []);

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsToLang = {
    default: {
      value: 'English US',
      label: 'English US',
    },
    'options': Dropdowns.Languages,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropstoposition = {
    ...autoPropsToLang,
    'options':  Dropdowns.Contact_Role,
    default: null,
  }
  const autoPropstorole = {
    'options': Dropdowns.Roles,
    propsobj,
    keys: ['roleName'],
    error: roleField.error,
    helperText: roleField.helperText
  }
  const autoPropstoaccountname = {
    ...autoPropstoposition,
    'options': accountNames,
    default: null,
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('addContactForm', event);

    if (setGo) {
      setProgress(true);
      let form = propsobj.formValues;
      var sponsorAccountId
      for (var i = 0; i < accountNames.length; i++) {
        if (accountNames[i].label == form.accountName) {
          sponsorAccountId = accountNames[i].value
        }
      }
      if (sponsorAccountId) {
        let requestBody = `
            mutation {
                createSponsorAccountUser(sponsorAccountId: "${sponsorAccountId}", 
                firstName: "${form.firstName}", 
                middleName:"${form.mname}", 
                lastName :"${form.lastName}",
                email :"${form.email}",
                roleId:"${portal ? form.roleId : ""}",
                phone:"${form.phone}",
                position:"${form.position}",
                language:"${form.lang == "" ? "English (US)" : form.lang}",
                portalAccessInd:"${portal ? "Y" : "N"}",
                invitedBy:"admin") {
                  code
                  type
                  message
                  attributes
              }
            }
          `;

        requestBody = JSON.stringify({ query: requestBody });
        let res = await apiCall('createSponsorAccountUser', requestBody, form);
        setProgress(false);

        if (res && res.data.createSponsorAccountUser.code === '200') {
          propsobj.history.goBack()
        } else {
          console.log('Error on response add account file!')
          propsobj.history.goBack()
        }
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
    propsobj.history.goBack();
  }

  const accountSearch = (event) => {
    event.preventDefault();
    propsobj.history.push('/account/search')
  }

  const dateHandleChange = (momObj, eleName) => {
    console.log('momentObj--', momObj, '---name---', eleName);
  }

  const handlePortalChange = () => {
    setPortal(!portal)
  }
  const handleRole = async (obj) => {
    console.log(obj, "obj")
    let roleObj = {
      roleName: '',
      roleId: '',
      error: false,
      helperText: '',
    }
    setRoleField(roleObj);
    if (obj) {
      let roleObj = {
        roleName: obj.value,
        roleId: obj.selected.roleId,
        error: obj.flag,
        helperText: obj.text,
      }
      setRoleField(roleObj);
      propsobj.UpdateFormField({ name: "roleId", value: obj.selected.roleId })
      propsobj.UpdateFormField({ name: "roleName", value: obj.value })
    }
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="d-flex mt-4 justify-content-end">
        <h1 className="mr-auto primary-header">ADD CONTACT</h1>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="addContactForm" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">NAME INFORMATION</div>
                  <div className="inputBox">
                    <InputField {...textProps} name="firstName" label="First Name" must={'true'} type="text" />

                    <InputField {...textProps} name="mname" label="Middle Name" must={'false'} type="text" />

                    <InputField {...textProps} name="lastName" label="Last Name" must={'true'} type="text" />
                    <AutoOptions {...autoPropstoaccountname} name="accountName" label="Account Name" keyName='label' must={'true'} />
                    {/* <InputField {...textProps} name="accountName" label="Account Name" must={'true'} type="text" disabled={!accnameShow} />
                    {accnameShow &&
                      <div className="searchButtonWidth">
                        <IconButton aria-label="Search" className="searchicon"
                          onClick={accountSearch}>
                          <SearchIcon />
                        </IconButton>
                      </div>} */}
                    <AutoOptions {...autoPropstoposition} name="position" label="Position" keyName='value' must={'true'} />

                    <AutoOptions {...autoPropsToLang} name="lang" label="Language" keyName='value' must={'true'} />
                    <div className="row">
                      <div className="col-md-5">
                        <FormGroup style={{ paddingTop: "15px" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={portal}
                                onChange={(e) => { handlePortalChange(e) }}
                                value="true"
                                name="portalaccess"
                                color="primary"
                              />
                            }
                            label="Portal access"
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-7">
                        {
                          portal &&
                          <AutoOptionsNew {...autoPropstorole} autoValue={roleField} name="roleName" label="Access level" keyName='roleName' must={'true'} callback={handleRole} />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">CONTACT INFORMATION</div>
                  <div className="inputBox">
                    <InputField {...textProps} name="phone" label="Phone" must={'true'} type="text" />
                    <InputField {...textProps} name="email" label="Email" must={'true'} type="email" />
                  </div>

                </div>
              </div>
            </div>
            <div className="buttonContainer">
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddStudyPersonal));



