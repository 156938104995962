import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { FileUpload } from './fileupload/fileupload'
import CircularProgress from '@material-ui/core/CircularProgress';
import { fileUpload,genericApi } from '../../../common/apiconfig';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const SimpleModal = ({ open, onClose,scheduleData }) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  let [progress, setProgress] = useState(false)
  let [docFiles, setDocFiles] = useState([])
  let [docsData, setDocsData] = useState([]);
  let [docErr, setDocErr] = useState('');
  let [notes, setNotes] = useState('')

  const handleClose = () => {
    onClose()
  };
  //uploaded docs or videos to save
  const fileSave = async (files) => {
    var docsInfo = []
    for (var i = 0; i < files.length; i++) {
      let requestBody = { "filename": files[i].name }
      requestBody = JSON.stringify(requestBody);
      setProgress(true)
      let res = await fileUpload('fileupload', requestBody);
      if (res.code == 200) {
        var data = JSON.parse(res.attributes)
        var fdata = {
          id: data.Id,
          fileName: files[i].name,
          type: files[i].type
        }
        docsInfo.push(fdata)
        let blob = new Blob([files[i]]);
        const response = await fetch(data.URL, {
          method: "PUT",
          body: blob,
        });
        const status = await response.status;
        if (status == 200) {
          setProgress(false)
        }

      } else {
        alert('failed to upload')
      }
    }
    setDocsData(docsInfo)
  }

  const onChangeFile = (files) => {
    var data = files.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))
    setDocFiles([...docFiles, ...data])
  }
  const removeFile = (e, name) => {
    //console.log(e, name, "name")
    var filesData = [...docFiles]
    docFiles.map(file => (file.name === name && filesData.splice(filesData.indexOf(file), 1)))
    setDocFiles(filesData)
  }

  const handleChange = (e) => {
    setNotes(e.target.value)
  }
  const onSubmit = async (e) => {
    e.preventDefault()
    console.log(notes, docsData,scheduleData, "docsData")
    // let files
    // if (docFiles.length == docsData.length) {
    //   let docsArr = ''
    //   docsData.map(doc => {
    //     docsArr += `{buArea: "Event", buid:"${propsobj.formValues.ID}",type:"Document", detail1: "${doc.id}",detail2:"${doc.fileName}",detail3:"${doc.type}"}`;
    //   })
    //   files = docsArr.replace(/}\s*{/g, '},{');
    // } else {
      
    // }
    // let requestBody = `
    // mutation {
    //   putStudySitePatientEvent(patSchedulePk:"${patientInfo.patientId}",note:"${notes}",supportDetails[${files}]){
    //     code
    //     type
    //     message
    //     attributes
    //   }}`;

    // requestBody = JSON.stringify({ query: requestBody });
    // let res = await genericApi('putStudySitePatientEvent', requestBody, form);
    // setProgress(false);
    // if (res && res.data.putStudySitePatientEvent.code === "200") {

    // } else {
    // }
    //   putStudySitePatientEvent(patSchedulePk:"123",note:"skdhdsf",completedInd:"N",
    //   supportDetails:[{buArea:"Event",buid:"123",type:"Link",detail1:"www.lnk.com",detail2:"lnk desc"}
    //   ,{buArea:"Event",buid:"123",type:"Document",detail1:"word.txt",detail2:"work desc"}
    //   ,{buArea:"Event",buid:"123",type:"Video",detail1:"movie.mp4",detail2:"video desc"}]
    // ) {

  }
  return (
    <div>

      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* <h4 id="modal-title">
            Subject detail
          </h4> */}
      <div className="m-3 dosageTable">
        <section className="row">
          <div className='d-flex flex-column col'>
            <label><strong>Subject Id :</strong> 123456 </label>
            <label><b>Protocol Id :</b> test1 </label>
            <label><b>Status :</b> test2 </label>
          </div>
          <div className='col'>
            <div className='d-flex flex-row-reverse'>

            </div>
            <div className='d-flex flex-column align-items-end'>
              <label><strong>ARM :</strong> 123456 </label>
            </div>
          </div>
        </section>
        <section className="notesText">
          Notes:
          <textarea onChange={handleChange} style={{ width: "100%", border: "1px solid #e0e0e0" }} name="notes" value={notes} />
        </section>
        {/* <section className="dropzone"> */}
        <FileUpload dataToAccept='image/*,.pdf,video/*,.xlsx,.txt,.xls,.ppt,.pptx,.doc,.docx' fileSave={fileSave} onChangeFile={onChangeFile} allFiles={docFiles} removeFile={removeFile} preview={false} />

        <div className="errMsg">{docErr}</div>
        {/* </section> */}
        <div className="buttonContainer fr mb-3">
          <button type="button" onClick={onSubmit} className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
          <button className="btn btn-primary-grey btn-rounded btn-small" onClick={onClose}>CANCEL</button>
        </div>
      </div>
    </div>

  );
}

export default SimpleModal