import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import SmallLogo from '../../img/hekma-logo.svg';
// import UserProfile from '../../../img/user-profile.png';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import UserMenu from './userMenu';
import { oldgenericApi } from '../../common/apiconfig'
const Header = (props) => {
  const [data, setData] = useState('')
  const [offset, setoffset] = useState(0)
  const [NRSData, setNRSData] = useState(0)
  const [offsetReq, setoffsetReq] = useState(0)
  const [activeNSR, setActiveNSR] = useState("")
  const [queriesData, setQueriesData] = useState("")
  const [activeApp, setActiveApp] = useState('')
  const location = props.location;

  useEffect(() => {
    callUserAccounts()

  }, []);
  const callUserAccounts = async (id) => {
    const getAllAccountsofContact = `
      sponsorAccountId
      accountName
      parentAccountId
      accountType
      status
      address1
      address2
      country
      state
      city
      zip
      email
      phone
      website
      duns
   `;
    let requestBody = {
      query: `query {
            getAccountsOfUser(sponsorAccountUserId:"${localStorage.getItem('loginUserId')}") {
                        ${getAllAccountsofContact}
                  }
              }`
    };
    requestBody = JSON.stringify(requestBody);
    // setProgress(true);
    let res = await oldgenericApi('getAccountsOfUser', requestBody);
    // setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error')

    } else {
      if (res.data.getAccountsOfUser.length>0) {
        console.log(res.data, "sponsors")
        if(res.data.getAccountsOfUser[0].sponsorAccountId && res.data.getAccountsOfUser[0].accountName==''){
          props.UpdateEntireForm(res.data.getAccountsOfUser[0])
          props.UpdateFormField({name:"autosite",value:res.data.getAccountsOfUser[0]})
          props.history.push('/app/updateAccount')
        }else{
          props.UpdateEntireForm(res.data.getAccountsOfUser[0])
          props.UpdateFormField({name:"autosite",value:res.data.getAccountsOfUser[0]})
        }
      } else {
        setData([])
      }
    }
  }
  const searchSubmit = (event) => {
    event.preventDefault();
    let value = document.querySelector("input[name='headerSearch']").value;
    if (value.length) {
      props.updateHeaderSearchValue({ value });
      props.history.push('/site/results');
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    props.history.push('/siteselection')
  }


  return (
    <>

      {/* <!-- Content Wrapper --> */}
      <div id="content-wrapper" className="d-flex flex-column">
        {/* <!-- Main Content --> */}
        <div id="content">
          {/* <!-- Topbar --> */}
          <nav className="navbar navbar-expand navbar-light bg-header-shadow topbar static-top">
            {/* <!-- Sidebar Toggle (Topbar) --> */}
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-2">
              <i className="fa fa-bars"></i>
            </button>
            <a href="#" className="ml-xs-1 ml-md-3 mr-xs-2 mr-md-5" onClick={handleClick}><img src={SmallLogo} alt="logo-small" /></a>
            {/* <!-- Topbar Navbar --> */}
            <ul className="navbar-nav ml-auto">
              {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
              <li className="nav-item dropdown no-arrow d-sm-none">
                <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fa fa-search fa-fw"></i>
                </a>
                {/* <!-- Dropdown - Messages --> */}
                <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                  <form className="form-inline mr-auto w-100 navbar-search">
                    <div className="input-group">
                      <input type="text" className="form-control bg-light border-0 small" placeholder="Search Studies" aria-label="Search" aria-describedby="basic-addon2" />
                      <div className="input-group-append">
                        <button className="btn btn-primary-blue" type="button">
                          <i className="fa fa-search fa-sm"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </li>
              {/* <!-- Nav Item - Alerts --> */}

              {/* <!-- Nav Item - User Information --> */}
              <li className="nav-item dropdown">

                <UserMenu />


              </li>

              <li> </li>
            </ul>
          </nav>
          {/* <!-- End of Topbar --> */}
        </div>
        {/* <!-- End of Main Content --> */}

      </div>
      {/* <!-- End of Content Wrapper --> */}

    </>

  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateHeaderSearchValue: (obj) => {
      return dispatch(
        {
          type: 'headerSearch',
          value: obj.value,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    setSidebarActiveEl: (name) => {
      return dispatch(
        {
          type: 'sidebarActiveEl',
          value: name,
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
          {
              type: 'UpdateEntireForm',
              value: obj,
          }
      )
  },
  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

