export function checkPermission(btnName, operation) {
  let responseObj = JSON.parse(localStorage.getItem('roles'));
  if (responseObj != null) {
      let uiRolePermissions = responseObj.uiRolePermissions;
      let permissionObj = null;
      if (uiRolePermissions.length) {
          permissionObj = uiRolePermissions.find(({ buComponent, buType, accessExist }, inx) => {
              if (buComponent == btnName && buType == operation) {
                  return accessExist;
              }
          })
      } else {
          return false;
      }
      if (permissionObj) {
          return permissionObj.accessExist;
      }
      return false;
  }
}

