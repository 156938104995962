import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loader from '../../../common/loader';
import { appConfig } from '../../../common/constants';
import Chip from '@material-ui/core/Chip';

const Training = () => {
  const [progress, setProgress] = useState(false)
  const [nctId, setNctId] = useState("")
  const [nctList, setNctList] = useState("")
  const [hId, setHId] = useState("")
  const [hList, setHList] = useState("")

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ "bucket-name": "trial-match-training-fhir" })
    };
    setProgress(true)
    fetch(appConfig.AIApi + 'get_hospitial_study_list', requestOptions)
      .then(response => response.json())
      .then((data) => {
        setProgress(false)
        if (data?.details.length > 0) {
          setHList(data.details)
        } else {
          setHList([])
        }

        console.log(data, "data")
      })
  }, [])
  const changeHId = (e, value) => {
    e.preventDefault()
    setHId(value);
    setNctId("")
  }
  useEffect(() => {
    if (hId) {
      const requestOptions1 = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ "bucket-name": "trial-match", "prefix": `data_mapping_payloads/${hId}/` })
      };
      setProgress(true)
      fetch(appConfig.AIApi + 'get_hospitial_study_list', requestOptions1)
        .then(response => response.json())
        .then((data) => {
          setProgress(false)
          let newdata = []
          data.details.map((item) => { newdata.push({ "title": item }) })
          console.log(newdata, "data1")
          setNctList(newdata)
        })
      // .then((err) => {
      //   console.log(err)
      //   setProgress(false)
      //   alert('Api Error')
      // })
    }
  }, [hId])
  const onSubmit = (e) => {
    e.preventDefault();
  }
  return (
    <>
      <Loader progress={progress} />
      <form onSubmit={onSubmit}>
        <div className="row mb-3">
          <div className="col-md-4">
            <Autocomplete
              id="combo-box-demo"
              value={hId}
              onChange={(event, newValue) => {
                changeHId(event, newValue)
              }}
              size='small'
              options={hList}
              style={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} label="Select Hospital" variant="outlined" />}
            />
          </div>
          <div className="col-md-4">
            {/* <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              value={nctId}
              onChange={(event, newValue) => {
                setNctId(newValue);
              }}
              options={nctList.length>0 ? nctList.map((option) => option.title) : []}
              fullWidth
              multiple
              style={{ width: "100%" }}
              getOptionLabel={(option) => option.title}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.title}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Select NCT ID" placeholder="Select NCT ID" />
              )}
            /> */}
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              options={nctList.length>0 ? nctList.map((option) => option.title):[]}
              freeSolo
              fullWidth
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Select NCT ID" placeholder="Select NCT ID" />
              )}
            />
          </div>

          <div className="col-md-4">
            <div className="buttonContainer" style={{ justifyContent: "center" }}>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1" disabled={!hId && !nctId}>SUBMIT</button>
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={() => { setNctId("") }}>CLEAR</button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default Training

