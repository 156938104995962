import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Card from './card'
import ScreenFailure from '../../../../img/dashboard_icons/Screen-Failure.svg'
import AE from '../../../../img/dashboard_icons/Adverse-Event.svg'
import Enrollment from '../../../../img/dashboard_icons/Enrollment.svg'
import Protocol from '../../../../img/dashboard_icons/Protocol-Deaveation.svg'
import Query from '../../../../img/dashboard_icons/query.svg'
import Withdrawl from '../../../../img/dashboard_icons/withdrawal.svg'
import { checkPermission } from '../../../../common/utilis'
import { genericApi ,oldgenericApi} from '../../../../common/apiconfig';
import Linechart from './linechart'
import Barchart from './barchart'
import Piechart from './piechart'
import Speedguage from './speedguage'
import VariablePiechart from './variablepie'
// Import Highcharts
import Highcharts from 'highcharts/highstock'
// Load Highcharts modules
require('highcharts/indicators/indicators')(Highcharts)
require('highcharts/indicators/pivot-points')(Highcharts)
require('highcharts/indicators/macd')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/map')(Highcharts)
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);
require("highcharts/modules/variable-pie")(Highcharts);
const Dashboard = (propsobj) => {
  let [progress, setProgress] = useState(false);
  let [screenFaiRate, setScreenFaiRate] = useState(0)
  let [enrollmentRate, setEnrollmentRate] = useState(0)
  let [withdrawalRate, setWithdrawalRate] = useState(0)
  let [queriesCount,setQueriesCount]= useState(0)
  // const [chartOptions, setChartOptions] = useState({


  // });
  useEffect(() => {
    // let isEditCourse = checkPermission('S_Courses_Update', 'Update');

    // if (localStorage.getItem("siterole") === "Business User") {
    //   isEditCourse = "1"
    // }

    // propsobj.UpdateFormField({ name: "hidestudytab", value: false })
    // set the dimensions and margins of the graph
    // api call
    // callapi()
    fetchQueries()
    return () => {
      // propsobj.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, [propsobj.formValues?.autostudy?.studyId])

  const fetchQueries = async () => {
    let requestBody = {
      query: `query {
          searchStudySiteQuery(queryId:"*",queryNum:"*",studyId:"${propsobj.formValues?.autostudy?.studyId}",siteId:"${propsobj.formValues.autosite.sponsorAccountId}",statuses:"Open,ReOpen",assignedTo:"*") {
            queryId
      queryNum
        }
      }`
    };

    requestBody = JSON.stringify(requestBody);
    // setProgress(true);
    let res = await oldgenericApi('searchStudySiteQuery', requestBody);
    // setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error!')

    } else {
      if (res.data.searchStudySiteQuery != null && res.data.searchStudySiteQuery.length) {
        setQueriesCount(res.data.searchStudySiteQuery.length)
      } else {
        setQueriesCount(0)
      }
    }
  }

  const callapi = async () => {
    let requestBody = {
      query: `query {
      getStudySiteDoctorDashboardMetrics(studyId: "${propsobj.formValues.autostudy.studyId}", siteId: "${propsobj.formValues.autosite.sponsorAccountId}") {
        studyId
        siteId
        limitization
        studyTitle
        siteName
        firstPatientStudySite
        lastPatientStudySite
        noOfPreScreeningPatients
        noOfScreeningPatients
        noOfOnStudyPatients
        noOfDisqualifiedPatients
        noOfDroppedPatients
        noOfCompletedPatients
        noOfWithdrawnPatients
        totalNoOfSubject
        screenFailureRate
        enrollmentRate
        withdrawalRate
      }
    }`
    };

    requestBody = JSON.stringify(requestBody);
    // setProgress(true);
    let res = await genericApi('getStudySiteDoctorDashboardMetrics', requestBody);
    // setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error on response material grid!')

    } else {
      if (res.data.getStudySiteDoctorDashboardMetrics) {
        console.log(res.data.getStudySiteDoctorDashboardMetrics)
        var data = res.data.getStudySiteDoctorDashboardMetrics
        setScreenFaiRate(data.screenFailureRate)
        setEnrollmentRate(data.enrollmentRate)
        setWithdrawalRate(data.withdrawalRate)
      }
    }
  }
  const handleRowClick = async (event, rowData) => {
    console.log(rowData)
  }
  let data = [
    { img: ScreenFailure, text: "Screen Failure Rate", info: screenFaiRate, color: "#C169F9" },
    { img: Enrollment, text: "Enrollment Rate", info: enrollmentRate, color: "red" },
    { img: Withdrawl, text: "Withdrawl  Rate", info: withdrawalRate, color: "#365F99" },
    { img: Query, text: "Open Queries", info: queriesCount, color: "#FD8480" },
    // { img: Protocol, text: "Protocol Deviation Rate", info: "0.0099", color: "#FCCE67" },
    { img: AE, text: "Adverse Event Rate", info: "0.0207", color: "#58B9FD" }
  ]

  return (
    <>
      <br /><br /><br/>
      {propsobj.formValues.autostudy &&
        <div className="row mb-3 mt-3">
          {data.length > 0 && data.map((item, i) => (
            <div className={item.text==="Adverse Event Rate" ? "col-lg-4 col-md-6 col-sm-6" : "col-lg-2 col-md-4 col-sm-6"}>
              <Card img={item.img} text={item.text} info={item.info} color={item.color} />
            </div>
          ))}
          <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
            <div className="">
              <Linechart />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
            <div className="">
              <VariablePiechart />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
            <div className="">
              <Piechart />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
            <div className="">
              <Barchart />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
            <div className="">
              <Speedguage />
             
            </div>
          </div>
        </div>
      }
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
// import React, { useState } from 'react';

// const Dashboard = () => {
//   const [password, setPassword] = useState([])
//   const [passwordLength, setPasswordLength] = useState(false)
//   const [containsNumbers, setContainsNumbers] = useState(false)
//   const [isUpperCase, setIsUpperCase] = useState(false)
//   const [containsSymbols, setContainsSymbols] = useState(false)
//   //check to see if there is any number
//   const checkForNumbers = (string) => {
//     var matches = string.match(/\d+/g);
//     setContainsNumbers(matches != null ? true : false)
//   }

//   // check for upper case
//   const checkForUpperCase = (string) => {
//     var matches = string.match(/[A-Z]/);
//     setIsUpperCase(matches != null ? true : false)
//   }

//   // check for symbols
//   const checkForSymbols = (string) => {
//     var symbols = RegExp(/[^A-Z a-z0-9]/);
//     setContainsSymbols(symbols.test(string) ? true : false)
//   }

//   // handle password
//   const handleChange = input => e => {
//     let targetValue = e.target.value.replace(/\s/g, '');
//     checkForNumbers(targetValue);
//     checkForUpperCase(targetValue);
//     checkForSymbols(targetValue);
//     setPassword(targetValue);
//     setPasswordLength(targetValue.length > 7 ? true : false)
//   }

//   // submit form
//   const submitForm = (e) => {
//     e.preventDefault();
//     alert('Form submitted!');
//   }
//   let btnStatus = passwordLength && containsNumbers && isUpperCase && containsSymbols ? false : true;
//   return(

//     <>
//       <div className="title card">
//         Pasword Checker
//       </div>
//       <div className="content card p-2">
//         <form>
//           <input type="text" onChange={handleChange('password')} value={password} placeholder="Enter Password" />
//           <ul>
//             <li className={passwordLength ? 'green' : null}>Should be More than 8 characters</li>
//             <li className={containsNumbers ? 'green' : null}>Include atleast one number</li>
//             <li className={isUpperCase ? 'green' : null}>Include atleast one UpperCase</li>
//             <li className={containsSymbols ? 'green' : null}>Include atleast one Symbols</li>
//           </ul>
//           <button className="Submit" disabled={btnStatus} onClick={submitForm}>Submit</button>
//         </form>
//       </div>
//     </>
//   )
// }

//   state = {
//     password: '',
//     passwordLength: false,
//     containsNumbers: false,
//     isUpperCase: false,
//     containsSymbols: false
//   }

//   // check to see if there is any number
//   checkForNumbers(string){
//     var matches = string.match(/\d+/g);
//     this.setState({
//       containsNumbers: matches != null ? true : false
//     });
//   }

//   // check for upper case
//   checkForUpperCase(string){
//     var matches = string.match(/[A-Z]/);
//     this.setState({
//       isUpperCase: matches != null ? true : false
//     });
//   }

//   // check for symbols
//   checkForSymbols(string){
//     var symbols = new RegExp(/[^A-Z a-z0-9]/);
//     this.setState({
//       containsSymbols: symbols.test(string) ? true : false
//     });
//   }

//   // handle password
//   handleChange = input => e => {
//     let targetValue = e.target.value.replace(/\s/g, '');
//     this.checkForNumbers(targetValue);
//     this.checkForUpperCase(targetValue);
//     this.checkForSymbols(targetValue);
//     this.setState({
//       [input]: targetValue,
//       passwordLength: targetValue.length > 7 ? true : false
//     });
//   }

//   // submit form
//   submitForm = (e) => {
//     e.preventDefault();
//     alert('Form submitted!');
//   }

//   render(){
//     let {
//       password,
//       passwordLength,
//       containsNumbers,
//       isUpperCase,
//       containsSymbols
//     } = this.state
//     let btnStatus = passwordLength && containsNumbers && isUpperCase && containsSymbols ? false : true;
//     return (
//       <>
//         <div className="title">
//           Pasword Checker
//         </div>
//         <div className="content">
//           <form>
//             <input type="text" onChange={this.handleChange('password')} value={password} placeholder="Enter Password" />
//             <div>
//               <div className={passwordLength ? 'green' : null}>Contains More than 8 characters</div>
//               <div className={containsNumbers ? 'green' : null}>Contains numbers</div>
//               <div className={isUpperCase ? 'green' : null}>Contains UpperCase</div>
//               <div className={containsSymbols ? 'green' : null}>Contains Symbols</div>
//             </div>
//             <button className="Submit" disabled={btnStatus} onClick={this.submitForm}>Submit</button>
//           </form>
//         </div>
//       </>
//     );
//   }
// }

// export default Dashboard;