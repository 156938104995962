import React from 'react'
import AddPatient from './addPatient'
import InvitePatient from './invitepatients'
const AddPatientHome = (props) => {
  return (
    <>
      <div className="h-tabs-1">
        <div className="">
          <ul className="nav nav-tabs" id="addpatient" role="tablist" style={{ borderBottom: "none",float:"right" }}>
            <li className="nav-item" >
              <a class="nav-link active font14" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">ADD PATIENT MANUALLY</a>
            </li>
            <li className="nav-item">
              <a class="nav-link font14" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">ADD PATIENT WITH EMAIL</a>
            </li>
          </ul>
          <div className="tab-content">
            <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab"><AddPatient newApp={props.newApp || false}/></div>
            <div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab"><InvitePatient newApp={props.newApp || false}/></div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AddPatientHome