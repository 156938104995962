import React from 'react'

const Emails = () => {
  return (
    <div>
      Emails
    </div>
  )
}

export default Emails
