import React, { useState, useEffect } from 'react';
import Loader from '../../../common/loader'
import { genericApi } from '../../../common/apiconfig';
import Materialtable from '../../../globalcomponents/materialtable'
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip';
// import { useConfirm } from "material-ui-confirm";
// import DeleteIcon from '../../../img/opensquare/Select-Delete.svg'
import Alert from '../../../globalcomponents/basicAlert'
// import SmallLogo from '../img/hekma-logo.svg';

const ShoppingCart = (propsobj) => {
  let reduxstateobj = useSelector(state => state);
  const [data, setData] = useState([])
  const [totalprice, setTotalPrice] = useState(0)
  const [open, setOpen] = useState(false)
  const [rowData,setRowData]=useState("")
  let [progress,setProgress]=useState(false)
  const reduxdispatch = useDispatch()

  useEffect(()=>{
    if(reduxstateobj.form.cartHeader){
      // setData(reduxstateobj.cartdata)
      var data = reduxstateobj.cartdata
        var totalprice = data.reduce(function (total, currentValue) {
          return total + parseInt(currentValue.totalPrice);
        }, 0)
        setTotalPrice(totalprice)
        setData(data)
        reduxdispatch({ type: 'Totalprice', value: totalprice * 100 })
    }else{
    callGetCartApi()
    }

  },[])

  const callGetCartApi= async()=>{
    let requestBody = {
      query: `query {
        getCartDetails(cartId:"*",accountId:"${localStorage.getItem('loginUserId')}",nctId:"*",siteId:"*")
      {
         cartId
      accountId
      nctId
      siteId
      siteName
      city
      country
      patientCount
      totalPrice
      metrics
      insights
      patientGender
      patientAgeMin
      patientAgeMax
      patientRace
      patientCity
      patientState
      patientZipCode
      patientCountry
      patientLanguage
      patientEducation
      siteNameFilter
      siteCity
      siteState
      siteZipcode
      siteCountry
      leadStatus
      }
      }`
    }
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getCartDetails', requestBody);
    setProgress(false);
    if (res) {
      if (res.data.getCartDetails.length > 0) {
        var data = res.data.getCartDetails
        var totalprice = data.reduce(function (total, currentValue) {
          return total + parseInt(currentValue.totalPrice);
        }, 0)
        setTotalPrice(totalprice)
        setData(data)
        reduxdispatch({ type: 'Totalprice', value: totalprice * 100 })
        reduxdispatch({ type: 'cartcount',  value: data.length })
      }else{
        setData([])
        reduxdispatch({ type: 'cartcount',  value: 0 }) 
      }
    }
  

  }

  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.push('/account/pir/results')
  }

  const removeFromCart = (e, item) => {
    e.preventDefault()
    setOpen(true)
    setRowData(item)
    // confirm({ description: `Removing ${item.siteId} from cart` })
    //   .then(() => removeItem(item))
    //   .catch(() => console.log("Deletion cancelled."));
  }
  const handleDelete = async () => {
    setOpen(false)
      let requestBody = `
      mutation 
      {
        deleteCarts(cartIds:"${rowData.cartId}") {
          code
          type
          message
          attributes
        }  
      }
          `;
  
            requestBody = JSON.stringify({ query: requestBody });
            setProgress(true);
            let res = await genericApi('deleteCarts', requestBody);
            setProgress(false);
            if (res && res.data.deleteCarts.code === '200') {
              callGetCartApi()
            }
    // var dataa = data.filter(x => x.requestId != rowData.requestId)
    // // dataa.splice(index, 1);
    // setData(dataa)
    // setOpen(false)
    // reduxdispatch({ type: 'UpdateFormField', name: "cart", value: dataa })
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  const Columns = [
    {
      title: 'NCT ID/Study No', field: 'nctId',
    },
    { title: 'Site ID', field: 'siteId' },
    { title: 'State', field: 'siteState' },
    { title: 'Country', field: 'siteCountry' },
    { title: 'Count', field: 'patientCount',cellStyle: {
      width:"5%"
     }, },
    // { title: 'Pricing Type', field: 'priceType' },
    { title: 'Price($)', field: 'totalPrice', sorting: false, headerStyle: {
      textAlign: 'right',
    },render: rowData => <div style={{textAlign:"right"}}>{numberWithCommas(parseInt(rowData.totalPrice))}</div> },
    { title: 'Actions', field: '',sorting: false,headerStyle: {
      textAlign:"center"
     },cellStyle: {
      textAlign:"center"
     }, render: rowData => <div colSpan="3"><Tooltip title="Remove from Cart" arrow><span style={{ color: "#0000ff", margin: "5px", fontSize: "12px", cursor: "pointer",textAlign:"center", textDecoration: "underline" }} onClick={(e) => removeFromCart(e, rowData)}>Remove</span></Tooltip></div> },
  ]
  const handleRowClick = async (event, rowData) => { }
  const rowsSelected = (rows) => {
    // setSelectedContactsobj({ ...selectedContactsobj, [currentpage]: rows });
  }
  const gotopayment = () => {
    reduxdispatch({ type: 'Cartdata', value: data })
    propsobj.history.push('/account/cartpayment')
  }
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
     <Loader progress={progress} />
      <Alert open={open} header="Alert" data={rowData} setOpen={setOpen} handleDelete={handleDelete} handleClose={handleClose}>
        <div style={{ color: "black" }}>Are you sure to remove siteID:<span style={{color: 'black'}}><b>{rowData.siteId}</b></span></div>
        </Alert>
      <div class="secondary-header mr-auto">Cart</div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
            <Materialtable
              columns={Columns}
              data={data}
              title="Total Price"
              handleRowClick={handleRowClick}
            // rowsSelected={rowsSelected}
            // selection={true}
            />
            {data.length > 0 && <div className="fr" style={{ fontWeight: "bold" }}>Total ({data.length} items) : <b>${numberWithCommas(parseInt(totalprice))}</b></div>}<br />
            {data.length > 0 && <button type="button" className="btn btn-primary-blue btn-rounded btn-small fr mt-3 mb-3" onClick={gotopayment}>Pay</button>}
            {!reduxstateobj.form.cartHeader && <button type="button" className="btn btn-primary-blue btn-rounded btn-small fr mt-3 mb-3 mr-2" onClick={e => goBack(e)}> Add More Sites </button>}&nbsp;&nbsp;
          </div>
        </div>
      </div>      {/* </div> */}
    </>
  )
}


export default withRouter(ShoppingCart)