import React from 'react';

export default class DynamicImport extends React.Component{
    constructor(props){
        super(props);
        this.state={
            Component:null
        }
    }
    componentWillMount = () => {
        this.props.load()
        .then((mod)=>{
            this.setState({
                Component:mod.default
            })
        });
    }
    render(){
        return this.props.children(this.state.Component)
    }
}