import { apiCall } from '../common/apiconfig';
const ItemsLength = 3;
export const rI_accounts = {
    User: 'Admin',
    Page: 'accountsI',
    Details: []
}

export const rI_contacts = {
    User: 'Admin',
    Page: 'contactsI',
    Details: []
}

export const rI_studies = {
    User: 'Admin',
    Page: 'studiesI',
    Details: []
}


/*

Sample data: account
let dataToR_Items = {
      id: rowData.sponsorAccountId,
      accountName: rowData.accountName,
      accountType: rowData.accountType
    }

contact

let dataToR_Items = {
      id: rowData.sponsorAccountUserId,
      sponsorAccountId: rowData.sponsorAccountId,
      firstName: rowData.firstName
    }

study

let dataToR_Items = {
      id: rowData.studyId,
      studyNumber: rowData.studyNumber,
      studyTitle: rowData.studyTitle
    }
*/

export const removedubl = (arr) => {
    var copy = [];
    for (var i = 0; i < arr.length; i++) {
        if (copy.indexOf(arr[i]) >= 0) {
            continue;
        } else {
            copy.push(arr[i]);
        }
    }
    return copy;
}

export const updateRecentArr = (searchObj, arrObj) => {
    if (arrObj) {
        let oldArray = searchObj['Details'];
        let newArray = arrObj;
        searchObj['Details'] = [...oldArray, ...newArray];
        //console.log('combined::', searchObj['Details'])
        if (searchObj['Details'].length > ItemsLength) searchObj['Details'].length = ItemsLength;
        //let uniqueArray = removedubl(searchObj['Details']);
        //searchObj['Details'] = uniqueArray;
    }

}

export const updateRecentItems = async (data, searchObj) => {
    let arr = searchObj['Details'];
    let page = searchObj['Page'];
    let found = arr.find(key => key.id === data.id);
    if (found) {
        //console.log('info already there')
        return;//the searched info already there in array
    }
    else {
        // Search string not found, then add it in array
        arr.unshift(data);
    }
    let recentItems = arr;

    let stringifyArr = recentItems.map((item, inx) => {
        let aa = JSON.stringify(item);
        aa = JSON.stringify(aa);// appending escape by \
        return aa;
    })
    recentItems = [...stringifyArr];
    let response = '';
    if (recentItems.length) {
        if (recentItems.length > ItemsLength) recentItems.length = ItemsLength;
        let userName = localStorage.getItem('loginUserId');
        let requestBody = `
		  mutation {
		    putCache(User: "${userName}",Page: "${page}",Details: [${recentItems}]) {
			    code
			    type
			    message
			    attributes
		    }
		  }
        `;
        requestBody = JSON.stringify({ query: requestBody });
        response = await apiCall('putCache', requestBody);
        return response;
    }
}

export const getRecentSearchOrItems = async (itemObj) => {
    let user = localStorage.getItem('loginUserId');
    if(user==null){
        return false
    }
    let requestBody = {
        query: `query {
                getCache(User:"${user}",Page:"${itemObj['Page']}") {
                    ID
                    Name
                    Page
                    Details
                    Details_Crunch
                    createDate
            }
        }`
    };

    requestBody = JSON.stringify(requestBody);
    let res = await apiCall('getCache', requestBody);
    try {
        if (res.data.getCache.length) {
            let data = res.data.getCache[0];
            data = data.Details;
            data = data.map((item, inx) => {
                return JSON.parse(item);
            })
            updateRecentArr(itemObj, data);
            return data;
        } else {
            return false;
        }
    } catch (e) {
        return false;
    }
}