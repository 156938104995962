import React, { useEffect, useState } from "react";
import BackIcon from '../../../img/back.svg';
import { noAuthApi, apiCall } from '../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { jwt } from '../../common/jwtAuthentication';
import FormHoc from '../hoc/formHoc';
import { InputField } from '../formElements';
import { connect } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import { ErrorLables } from '../../common/lables';

const ForgotPwdForm = (propsobj) => {
    let [progress, setProgress] = useState(false);
    let [apiError, setApiError] = useState(false);

    let hocObj = FormHoc(propsobj);

    const updateNewPwd = async (e) => {
        e.preventDefault()
        let setGo = await hocObj.handleSubmit('updateNewPwdForm', e);

        if (setGo) {

            let u_form = propsobj.formValues;

            // checking confirm password
            if (u_form.password !== u_form.cpassword) {
                let obj = {
                    name: 'cpassword',
                    flag: true,
                    text: ErrorLables['cpassword']
                }
                propsobj.updateErrorObj(obj);
                return;
            } else {
                let obj = {
                    name: 'cpassword',
                    flag: false,
                    text: ''
                }
                propsobj.updateErrorObj(obj);
            }

            setProgress(true);
            let requestBody = `
            mutation 
            {
                updatePassword(userId:"${propsobj.formValues.userid}",newPassword:"${propsobj.formValues.password}")  {
                code
                }
            }`
            requestBody = JSON.stringify({ query: requestBody });
            let res = await apiCall('updatePassword', requestBody);
            setProgress(false);
            if (!res) {
                //setApiError(true);
                //setTimeout(function () { setApiError(false) }, 1000 * 30);
            } else {
                propsobj.clearForm();
                alert('password updated - login with new password')
                propsobj.back();
            }
        }
    }

    const back = (e) => {
        e.preventDefault()
        propsobj.back();
    }



    let textProps = {
        ...hocObj,
        propsobj
    }

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}
            <div className="h-tabs-1">
                {apiError && <>
                    <div className="hekError">
                        <span className='icon'>
                            <ErrorIcon />
                        </span>
                        <span className='texts'>
                            Something went wrong!
                                    </span>
                    </div>
                </>}

                <span>Update New Password</span>

                <form noValidate autoComplete="off" id="updateNewPwdForm" className="loginForm"
                    onSubmit={updateNewPwd}>

                    {/* <InputField {...textProps} name="username" label="User Name" must={'true'} type="text" /> */}

                    <InputField {...textProps} name="password" label="New Password" must={'true'} type="password" />

                    <InputField {...textProps} name="cpassword" label="Confirm New Password" must={'true'} type="password" />

                    <div className="form-group"><br />
                        <button type="submit" className="btn btn-primary-blue btn-rounded btn-large btn-block">Update Password </button>
                    </div>
                </form>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        formValues: state.form,
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        },
        clearForm: () => {
            return dispatch(
                {
                    type: 'ClearForm',
                }
            )
        },
        updateErrorObj: (obj) => {
            return dispatch(
                {
                    type: 'updateErrorObj',
                    flag: obj.flag,
                    name: obj.name,
                    text: obj.text
                }
            )
        },
        updateEntireErrorObj: (errobj, errtextobj) => {
            return dispatch(
                {
                    type: 'updateEntireErrorObj',
                    errobj: errobj,
                    errtextobj: errtextobj,
                }
            )
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPwdForm));



