import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

function TestNameDetail(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}
            <div className="testNameDetail pcard d-flex align-items-center" onClick={props.clickHandler} style={{ borderLeft: "10px solid" + props.color, color: props.color }}>
                <span style={{ paddingRight: "10px" }}>{props.title}</span>
                <span><i className="fas fa-chevron-circle-right" style={{ color: props.color }}></i></span>
            </div>

        </>
    )

}

export default TestNameDetail;


