import React, { useEffect, useState } from "react";
import { render } from "react-dom";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsMap from "highcharts/modules/map";
import HighchartsReact from "highcharts-react-official";

import map from "@highcharts/map-collection/custom/world.geo.json";

// const mapData=[
//   {
//     "code3": "ABW",
//     "z": 105,
//     "code": "AW",
//     "color": "red"
//   },
//   {
//     "code3": "AFG",
//     "z": 34656,
//     "code": "AF"
//   },
//   {
//     "code3": "AGO",
//     "z": 28813,
//     "code": "AO"
//   },
//   {
//     "code3": "ALB",
//     "z": 2876,
//     "code": "AL"
//   },
//   {
//     "code3": "AND",
//     "z": 77,
//     "code": "AD"
//   },
//   {
//     "code3": "ARE",
//     "z": 9270,
//     "code": "AE"
//   },
//   {
//     "code3": "ARG",
//     "z": 43847,
//     "code": "AR"
//   },
//   {
//     "code3": "ARM",
//     "z": 2925,
//     "code": "AM"
//   },
//   {
//     "code3": "ASM",
//     "z": 56,
//     "code": "AS"
//   },
//   {
//     "code3": "ATG",
//     "z": 101,
//     "code": "AG"
//   },
//   {
//     "code3": "AUS",
//     "z": 24211,
//     "code": "AU"
//   },
//   {
//     "code3": "AUT",
//     "z": 8731,
//     "code": "AT"
//   },
//   {
//     "code3": "AZE",
//     "z": 9758,
//     "code": "AZ"
//   },
//   {
//     "code3": "BDI",
//     "z": 10524,
//     "code": "BI"
//   },
//   {
//     "code3": "BEL",
//     "z": 11338,
//     "code": "BE"
//   },
//   {
//     "code3": "BEN",
//     "z": 10872,
//     "code": "BJ"
//   },
//   {
//     "code3": "BFA",
//     "z": 18646,
//     "code": "BF"
//   },
//   {
//     "code3": "BGD",
//     "z": 162952,
//     "code": "BD"
//   },
//   {
//     "code3": "BGR",
//     "z": 7128,
//     "code": "BG"
//   },
//   {
//     "code3": "BHR",
//     "z": 1425,
//     "code": "BH"
//   },
//   {
//     "code3": "BHS",
//     "z": 391,
//     "code": "BS"
//   },
//   {
//     "code3": "BIH",
//     "z": 3517,
//     "code": "BA"
//   },
//   {
//     "code3": "BLR",
//     "z": 9502,
//     "code": "BY"
//   },
//   {
//     "code3": "BLZ",
//     "z": 367,
//     "code": "BZ"
//   },
//   {
//     "code3": "BMU",
//     "z": 65,
//     "code": "BM"
//   },
//   {
//     "code3": "BOL",
//     "z": 10888,
//     "code": "BO"
//   },
//   {
//     "code3": "BRA",
//     "z": 207653,
//     "code": "BR",
//     "color": "pink"
//   },
//   {
//     "code3": "BRB",
//     "z": 28555,
//     "code": "BB",
//     "color": "blue"
//   },
//   {
//     "code3": "BRN",
//     "z": 423,
//     "code": "BN",
//     "color": "green"
//   },
//   {
//     "code3": "BTN",
//     "z": 798,
//     "code": "BT"
//   },
//   {
//     "code3": "BWA",
//     "z": 2250,
//     "code": "BW",
//     "color": "red"
//   },
//   {
//     "code3": "CAF",
//     "z": 4595,
//     "code": "CF",
//     "color": "red"
//   },
//   {
//     "code3": "CAN",
//     "z": 36265,
//     "code": "CA",
//     "color": "green"
//   }
// ]

// const data = [
//   ['fo', 10], ['um', 11], ['us', 12], ['jp', 13], ['sc', 14], ['in', 15],
//   ['fr', 16], ['fm', 17], ['cn', 18], ['pt', 19], ['sw', 20], ['sh', 21],
//   ['br', 22], ['ki', 23], ['ph', 24], ['mx', 25], ['es', 26], ['bu', 27],
//   ['mv', 28], ['sp', 29], ['gb', 30], ['gr', 31], ['as', 32], ['dk', 33],
//   ['gl', 34], ['gu', 35], ['mp', 36], ['pr', 37], ['vi', 38], ['ca', 39],
//   ['st', 40], ['cv', 41], ['dm', 42], ['nl', 43], ['jm', 44], ['ws', 45],
//   ['om', 46], ['vc', 47], ['tr', 48], ['bd', 49], ['lc', 50], ['nr', 51],
//   ['no', 52], ['kn', 53], ['bh', 54], ['to', 55], ['fi', 56], ['id', 57],
//   ['mu', 58], ['se', 59], ['tt', 60], ['my', 61], ['pa', 62], ['pw', 63],
//   ['tv', 64], ['mh', 65], ['cl', 66], ['th', 67], ['gd', 68], ['ee', 69],
//   ['ag', 70], ['tw', 71], ['bb', 72], ['it', 73], ['mt', 74], ['vu', 75],
//   ['sg', 76], ['cy', 77], ['lk', 78], ['km', 79], ['fj', 80], ['ru', 81],
//   ['va', 82], ['sm', 83], ['kz', 84], ['az', 85], ['tj', 86], ['ls', 87],
//   ['uz', 88], ['ma', 89], ['co', 90], ['tl', 91], ['tz', 92], ['ar', 93],
//   ['sa', 94], ['pk', 95], ['ye', 96], ['ae', 97], ['ke', 98], ['pe', 99],
//   ['do', 100], ['ht', 101], ['pg', 102], ['ao', 103], ['kh', 104],
//   ['vn', 105], ['mz', 106], ['cr', 107], ['bj', 108], ['ng', 109],
//   ['ir', 110], ['sv', 111], ['sl', 112], ['gw', 113], ['hr', 114],
//   ['bz', 115], ['za', 116], ['cf', 117], ['sd', 118], ['cd', 119],
//   ['kw', 120], ['de', 121], ['be', 122], ['ie', 123], ['kp', 124],
//   ['kr', 125], ['gy', 126], ['hn', 127], ['mm', 128], ['ga', 129],
//   ['gq', 130], ['ni', 131], ['lv', 132], ['ug', 133], ['mw', 134],
//   ['am', 135], ['sx', 136], ['tm', 137], ['zm', 138], ['nc', 139],
//   ['mr', 140], ['dz', 141], ['lt', 142], ['et', 143], ['er', 144],
//   ['gh', 145], ['si', 146], ['gt', 147], ['ba', 148], ['jo', 149],
//   ['sy', 150], ['mc', 151], ['al', 152], ['uy', 153], ['cnm', 154],
//   ['mn', 155], ['rw', 156], ['so', 157], ['bo', 158], ['cm', 159],
//   ['cg', 160], ['eh', 161], ['rs', 162], ['me', 163], ['tg', 164],
//   ['la', 165], ['af', 166], ['ua', 167], ['sk', 168], ['jk', 169],
//   ['bg', 170], ['qa', 171], ['li', 172], ['at', 173], ['sz', 174],
//   ['hu', 175], ['ro', 176], ['ne', 177], ['lu', 178], ['ad', 179],
//   ['ci', 180], ['lr', 181], ['bn', 182], ['iq', 183], ['ge', 184],
//   ['gm', 185], ['ch', 186], ['td', 187], ['kv', 188], ['lb', 189],
//   ['dj', 190], ['bi', 191], ['sr', 192], ['il', 193], ['ml', 194],
//   ['sn', 195], ['gn', 196], ['zw', 197], ['pl', 198], ['mk', 199],
//   ['py', 200], ['by', 201], ['cz', 202], ['bf', 203], ['na', 204],
//   ['ly', 205], ['tn', 206], ['bt', 207], ['md', 208], ['ss', 209],
//   ['bw', 210], ['bs', 211], ['nz', 212], ['cu', 213], ['ec', 214],
//   ['au', 215], ['ve', 216], ['sb', 217], ['mg', 218], ['is', 219],
//   ['eg', 220], ['kg', 221], ['np', 222]
// ];

// const data=[ ["fo", 0],
// ["um", 1],
// ["us", 20],
// ["jp", 3],
// ["sc", 4],
// ["in", 5],
// ["fr", 6],
// ["fm", 7],
// ["cn", 8]]

HighchartsMap(Highcharts);


const Chart = (props) => {
  let [option, setOption] = useState("")
  let options = {
    chart: {
      // borderWidth: 1,
      map: map,
      style: {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif"
    },
    },

    title: {
      text: props.text
    },

    // subtitle: {
    //   text: "Demo of Highcharts map with bubbles"
    // },

    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle"
    },
    credits: {
      enabled: false
    },

    mapNavigation: {
      enabled: true,
      buttonOptions: {
        // verticalAlign: "bottom"
        alignTo: "spacingBox"
      }
    },
    colorAxis: {
      min: 0,
      minColor: "#E6E7E8",
      maxColor: "#005645"
    },
    series: [{
      data: props.mapdata,
      name: 'Patient data',
      states: {
        hover: {
          color: '#BADA55'
        },
        select: {
          color: '#a4edba',
          borderColor: 'black',
          dashStyle: 'shortdot'
        }
      },
      // dataLabels: {
      //   enabled: true,
      //   format: '{point.name}'
      // },
      allAreas: true
    }],
  };
  useEffect(() => {
    setOption(options)
  }, [props])
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={option}
        constructorType={"mapChart"}
      />
    </div>
  )
}

export default Chart