import React, { useEffect } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'

const Barchart = ({ options, highcharts }) => {
  useEffect(() => {
    Highcharts.chart('container11', {

      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        // height:"350px",
        // width:"400px",
        style: {
          fontFamily: 'Roboto, sans-serif'
        }
      },

      title: {
        text: 'Withdrawl Rate'
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        center: ['50%', '50%'],
        background: [{
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#FFF'],
              [1, '#333']
            ]
          },
          borderWidth: 0,
          outerRadius: '109%'
        }, {
          backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#333'],
              [1, '#FFF']
            ]
          },
          borderWidth: 1,
          outerRadius: '107%'
        }, {
          // default background
        }, {
          backgroundColor: '#DDD',
          borderWidth: 0,
          outerRadius: '105%',
          innerRadius: '103%'
        }]
      },

      // the value axis
      yAxis: {
        min: 0,
        max: 100,

        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickColor: '#666',

        tickPixelInterval: 30,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: '#666',
        labels: {
          step: 2,
          rotation: 'auto'
        },
        title: {
          text: 'subjects withdrawn'
        },
        plotBands: [{

        },
        {

          from: 0,
          to: 50,
          color: '#55BF3B' // green
        }, {

          from: 50,
          to: 70,
          color: '#DDDF0D' // yellow
        }, {

          from: 70,
          to: 100,
          color: '#DF5353' // red
        }
        ]
      },
      credits: {
        enabled: false,
      },
      series: [{
        name: 'Subjects',
        data: [40],
        dataLabels: {
          formatter: function () {
              var kmh = this.y;
              return '<span style="color:#339">'+ kmh + '%</span>';
          },
        },
        tooltip: {
          valueSuffix: '% withdrawn'
        }
      }]

    },
      // Add some life
      // function (chart) {
      //   if (!chart.renderer.forExport) {
      //     setInterval(function () {
      //       var point = chart.series[0].points[0],
      //         newVal,
      //         inc = Math.round((Math.random() - 0.5) * 20);

      //       newVal = point.y + inc;
      //       if (newVal < 0 || newVal > 200) {
      //         newVal = point.y - inc;
      //       }

      //       point.update(newVal);

      //     }, 3000);
      //   }
      // }
    );

  }, [])
  return (
    <div id="container11"></div>
  )
}


export default Barchart