import React, { useState, useEffect } from "react";
import { Picker } from "emoji-mart";
import { usePubNub } from "pubnub-react";
import TextField from '@material-ui/core/TextField';
import Filesharing from '../filesharing'
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Chat,
} from "@pubnub/react-chat-components";
import "./simple-chat.scss";
import "emoji-mart/css/emoji-mart.css";
import Tooltip from '@material-ui/core/Tooltip';
import $ from 'jquery'
import { connect } from 'react-redux';
import Attach from '../../../img/videochat/attach2.svg'
import Send from '../../../img/videochat/Send2.svg'
import Smiliy from '../../../img/videochat/smily.svg'
import InputAdornment from '@material-ui/core/InputAdornment';

const listStyles = {
  alignItems: 'flex-start',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'auto',
  padding: '10px',
};

const messageStyles = {
  backgroundColor: '#eee',
  borderRadius: '5px',
  color: '#333',
  fontSize: '1rem',
  margin: '5px',
  padding: '4px 10px',
};

const fileStyles = {
  // backgroundColor: '#eee',
  borderRadius: '5px',
  color: '#333',
  fontSize: '1.1rem',
  margin: '5px',
  padding: '8px 15px',
};

function SimpleChat(props) {
  const pubnub = usePubNub(); //usePubNub is only used here to get current user info (as it's randomly selected)
  const [theme, setTheme] = useState("light");
  const [channels] = useState([props.channel.name]);
  const [messages, addMessage] = useState([]);
  const [message, setMessage] = useState('');
  const [showEmojis, setShowEmojis] = useState(false);
  const [progress, setProgress] = useState(false)

  const showEmojisEvent = e => {
    e.preventDefault()
    setShowEmojis(!showEmojis)
  }
  const addEmoji = e => {
    let emoji = e.native;
    setMessage(message + emoji)
    setShowEmojis(!showEmojis)
    $("#outlined-textarea").focus();
  };

  const clientMessage = (publisher, message, time, clientname) => {
    // if (typeof message === 'string' || message.hasOwnProperty('text')) {
    const text = message.text || message;
    addMessage(messages => [...messages, { "publisher": publisher, "message": text, timestamp: time, clientname }]);
    // }
  };
  const addRemoteMsg = (publisher, message, time, clientname) => {
    addMessage(messages => [...messages, { "publisher": publisher, "message": message, timestamp: time, clientname }]);
  }

  useEffect(() => {
    pubnub.addListener({
      status: function (statusEvent) {
        if (statusEvent.category === "PNConnectedCategory") {
          console.log('join success with UID: ' + props.clientId);
        }
      },
      message: function (msg) {

        var msgg = JSON.parse(msg.message);
        const unixTimestamp = msg.timetoken / 10000000;
        const gmtDate = new Date(unixTimestamp * 1000);
        const localeDateTime = gmtDate.toLocaleTimeString();
        if (msg?.publisher !== (props.clientId).toString()) {
          addRemoteMsg(msg.publisher, msgg, localeDateTime, msg.userMetadata.name)
        } else {
          clientMessage(msg.publisher, msgg, localeDateTime, msg.userMetadata.name)
        }
      },
      // file: function (event) {
      //   console.log(event,"fileeeeeeeeee")
      // },
      presence: function (presenceEvent) {
        console.log('presence event: ' + JSON.stringify(presenceEvent));
      }
    })
    pubnub.subscribe({ channels });
  }, [pubnub]);

  function publishMessage(message, callback) {
    var msgConfig = {
      channel: props.channel.name,
      sendByPost: true,
      message: {
        uuid: props.clientId,
        title: "text",
        description: message
      }
    }
    pubnub.publish(msgConfig, function (status, response) {
      console.log(status, response);
      if (callback != undefined && callback instanceof Function) {
        callback();
      }
    })
  }

  const sendMessage = msg => {
    var message = JSON.stringify(msg)
    if (message.length > 0) {
      if (message) {
        pubnub
          .publish({
            channel: channels[0], message, meta: {
              name: props.channel.clientname
            }
          })
          .then(() => setMessage(''))
        // .catch(error => {  // catch the errors
        //   console.log(error);
        // });
      }
    }
  }

  const handleInput = e => {
    setMessage(e.target.value)
  }

  const image = (name, url) => {
    return <span>{name}&nbsp;<img src={url} alt="image" height="75" width="75" /></span>

  }
  const imgClick = (e, url) => {
    e.preventDefault()
    window.open(url)
  }

  const downloadImg = (e, name, url, type) => {
    e.preventDefault()
    download(name, url, type)
  };
  const downloadPdf = (e, name, url, type) => {
    e.preventDefault()
    window.open(url)
    // download(name, url, type)
  }
  const downloadVideo = (e, name, url, type) => {
    e.preventDefault()
    window.open(url)
  }

  const download = (name, url, type) => {
    fetch(url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });

  };
  const loading = (status) => {
    setProgress(status)
  }
  useEffect(() => {
    props.UpdateFormField({ name: "allchat", value: messages })
  }, [messages])

  return (
    <div className={`app-simple ${theme}`}>
      <Chat currentChannel={channels[0]} className="chat">
        <div className="card" style={{ width: "100%", borderRadius: "15px", border: "none" }}>
          <div className="card-header header" >
            CHAT
          </div>
          <div className="card-body" style={listStyles}>
            {messages.map((message, index) => {
              return (
                <div key={`message-${index}`} className="mb-3">

                  <b>{message.clientname}</b>:

                  {typeof message.message !== 'string' ?
                    <span style={fileStyles} className="cr">
                      {message.message.type.includes("image")
                        ?
                        <a href={message.message.url} download
                          onClick={(e) => downloadImg(e, message.message.name, message.message.url, message.message.type)} target="_blank">{message.message.name}
                        </a>
                        :
                        message.message.type.includes("pdf")
                          ?
                          <a href={message.message.url} download
                            onClick={(e) => downloadPdf(e, message.message.name, message.message.url, message.message.type)} target="_blank">{message.message.name}
                          </a>
                          : message.message.type.includes("video")
                            ?
                            <a href={message.message.url} download
                              onClick={(e) => downloadVideo(e, message.message.name, message.message.url, message.message.type)} target="_blank">{message.message.name}</a>
                            : ""}
                    </span>
                    : <span style={messageStyles}>{message.message}</span>}
                  <span style={{ fontSize: "10px", color: "#cccbcc" }}> {message.timestamp}</span>
                </div>
              );
            })}
          </div>
          <div className="card-footer chatfooter">
            <TextField
              id="outlined-textarea"
              // label="Multiline"
              multiline
              rows={1}
              maxRows={4}
              fullWidth
              clientName={props.channel.clientname}
              value={message}
              variant="outlined"
              onKeyPress={e => {
                if (e.key !== 'Enter') return;
                sendMessage(message);
              }}
              onChange={e => handleInput(e)}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <img src={Smiliy} alt="emojis" onClick={e => showEmojisEvent(e)} style={{ color: "#23416F", cursor: "pointer", marginRight: "5px" }} />
                    {/* <Divider style={dividerStyles} orientation="vertical" /> */}
                    {progress ? <CircularProgress color="inherit" size={25} style={{ marginTop: "9px", marginLeft: "5px" }} /> : <>
                      {/* <Tooltip title="Send Files" arrow> */}
                      <Filesharing text={
                        <img src={Attach} alt="Attachments" loading={false} style={{ color: "#23416F", cursor: "pointer" }} />
                        // <i className="fas fa-paperclip" style={{ color: "#23416F", fontSize: "15px", padding: "9px" }} loading={false}></i>
                      } dataToAccept='image/*,video/*,.pdf,.xlsx,.txt,.xls,.ppt,.pptx,.doc,.docx' type="Document" sendMessage={sendMessage} loading={loading} />
                      {/* </Tooltip> */}
                    </>}
                  </InputAdornment>,
              }}
            />
            {showEmojis && (
              <span className="emojiPicker">
                <Picker
                  onSelect={addEmoji}
                  emojiTooltip={true}
                  title="weChat"
                />
              </span>
            )}
            {/* <p className="getEmojiButton" onClick={e => showEmojisEvent(e)}>
              {String.fromCodePoint(0x1f60a)}
            </p> */}
            {/* <Tooltip title="Send" arrow> */}
            <img src={Send} alt="send" onClick={e => {
              e.preventDefault();
              sendMessage(message);
            }} style={{ margin: "5px", cursor: "pointer" }} />
            {/* <i className="fas fa-paper-plane" style={{ color: "#23416F", fontSize: "15px", padding: "9px" }} onClick={e => {
              e.preventDefault();
              sendMessage(message);
            }}></i> */}
            {/* </Tooltip> */}
          </div>
        </div>
      </Chat>

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleChat);
