import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import HekmaDatePicker from '../../components/HekmaDatePicker'
import moment from 'moment'
import BackIcon from '../../../img/back1.svg'
import { genericApi } from '../../../common/apiconfig';
import FormHoc from '../../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew } from '../../../globalcomponents/formElements';
import { Dropdowns, Causality, AE_Outcome } from '../../../common/constants';
import { connect } from 'react-redux';

const AddAdverseEvents = (propsobj) => {
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);

  useEffect(() => {

    propsobj.UpdateFormField({ value: "", name: "AE_subjectid" })
    propsobj.UpdateFormField({ value: "", name: "AE_term" })
    propsobj.UpdateFormField({ value: "", name: "AE_severity" })
    propsobj.UpdateFormField({ value: "", name: "AE_causality" })
    propsobj.UpdateFormField({ value: "", name: "AE_outcome" })
    propsobj.UpdateFormField({ value: "", name: "AE_startDate" })
    propsobj.UpdateFormField({ value: "", name: "AE_stopdate" })
  }, []);

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsTotype = {
    default: null,
    'options': Dropdowns.Protocol_Setup_AE_Type,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }


  const autoPropsTocausality = {
    ...autoPropsTotype,
    'options': Causality,
    default: null,
  }
  const autoPropsTooutcome = {
    ...autoPropsTocausality,
    'options': AE_Outcome,
    default: null,
  }


  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('addAE', event);

    if (setGo) {
      setProgress(true);
      let form = propsobj.formValues;
      console.log(form)
      let requestBody = `
      mutation {
        createStudySitePatientAdverseEvents(studyId:"${form.autostudy.studyId}",siteId:"${form.autosite.sponsorAccountId}",patientId:"${form.AE_subjectid}",aeTerm:"${form.AE_term}",severity:"${form.AE_severity}",causality:"${form.AE_causality}",outcome:"${form.AE_outcome}",startDate:"${moment(form.AE_startDate).format('YYYY-MM-DD')}",stopDate:"${moment(form.AE_stopdate).format('YYYY-MM-DD')}") {
          code
          type
          message
          attributes
        }
      }
          `;
      requestBody = JSON.stringify({ query: requestBody });
      let res = await genericApi('createStudySitePatientAdverseEvents', requestBody, form);
      setProgress(false);

      if (res && res.data.createStudySitePatientAdverseEvents.code === '200') {
        console.log('added');
        propsobj.history.push('/site/study/aevents');
      } else {
        console.log('Error!')
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    propsobj.history.goBack();
  }

  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.goBack()
  }
  console.log(autoPropsTooutcome)
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex justify-content-end mt-4">
        <div className="secondary-header mr-auto">Add Adverse Event</div>
      </div>
      <div className="">
        <form noValidate autoComplete="off" id="addAE" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  {/* <div className="card-page-header">NAME INFORMATION</div> */}
                  <div className="inputBox">

                    <InputField {...textProps} name="AE_subjectid" label="Subject ID" must={'true'} type="text" />
                    <InputField {...textProps} name="AE_term" label="AE Term" must={'true'} type="text" />
                    <AutoOptions {...autoPropsTotype} name="AE_severity" label="Severity" keyName='value' must={'true'} />
                    <AutoOptions {...autoPropsTocausality} name="AE_causality" label="Relationship to study drug" keyName='value' must={'true'} />

                    <AutoOptions {...autoPropsTooutcome} name="AE_outcome" keyName='value' label="Outcome" must={'false'} />
                    <HekmaDatePicker maxDate={new Date()} name="AE_startDate" label="Start Date" must={'true'} />
                    <HekmaDatePicker maxDate={new Date()} name="AE_stopdate" label="Stop Date" must={'true'} />


                  </div>

                </div>

              </div>
            </div>
            <div className="buttonContainer mb-3">
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small">Save</button>
            </div>
          </div>
        </form>
      </div>    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAdverseEvents));



