import React, { useEffect, useState, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHoc from '../../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions } from '../../../globalcomponents/formElements';
import { connect } from 'react-redux';
import HekmaDatePicker from '../HekmaDatePicker'
import { timeInfo } from '../../../common/constants';
import { sha256 } from 'js-sha256'
import { oldgenericApi } from '../../../common/apiconfig'
import DateTimePicker from '../../../globalcomponents/datetimepicker'
import moment from 'moment'
const ReqAppointment = (propsobj) => {
  let startTime = {
    value: '',
    label: '',
    error: false,
    helperText: '',
  }
  let endTime = {
    value: '',
    label: '',
    error: false,
    helperText: '',
  }
  let [starttime, setStarttime] = useState(startTime);
  let [endtime, setEndtime] = useState(endTime);
  let [progress, setProgress] = useState(false);
  let [options, setOptions] = useState([])
  const [startDatetime, setStartDatetime] = useState(null);
  const [endDatetime, setEndDatetime] = useState(null);
  const [startDatetimeErr, setStartDatetimeErr] = useState(false)
  const [startDatetimeText, setStartDatetimeText] = useState('')
  const [endDatetimeErr, setEndDatetimeErr] = useState(false)
  const [endDatetimeText, setEndDatetimeText] = useState('')
  let [text, setText] = useState('Add Appointment')

  useEffect(() => {
    // if (propsobj?.info && propsobj?.info.length > 0) {
    //   console.log(propsobj.info[0], "info")
    //   propsobj.UpdateFormField({ name: "app_date", value: propsobj.info[0].startDate })
    //   propsobj.UpdateFormField({ name: "app_starttime", value: propsobj.info[0].startTime })
    //   propsobj.UpdateFormField({ name: "app_endtime", value: propsobj.info[0].endTime })
    //   propsobj.UpdateFormField({ name: "app_comments", value: propsobj.info[0].note })
    //   setText("Update Appointment")
    // } else {
    propsobj.UpdateFormField({ name: "app_date", value: "" })
    // propsobj.UpdateFormField({ name: "app_starttime", value: "" })
    // propsobj.UpdateFormField({ name: "app_endtime", value: "" })
    propsobj.UpdateFormField({ name: "app_comments", value: "" })
    propsobj.UpdateFormField({ name: "app_subject", value: "" })
    setText("Add Appointment")
    // }
  }, []);

  const autoPropsToStarttime = {
    'options': timeInfo,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropsToEndtime = {
    'options': timeInfo,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }
  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('reqApp', event);
    if (startDatetime == null || "") {
      setStartDatetimeErr(true)
      setStartDatetimeText("This field is required")
    }
    if (endDatetime == null || "") {
      setEndDatetimeErr(true)
      setEndDatetimeText("This field is required")
    }
    if (startDatetime != null || "" && endDatetime != null || "") {
      if (startDatetime.getTime() >= endDatetime.getTime()) {
        setEndDatetimeErr(true)
        setEndDatetimeText("This should be greater than start date & time")
      }
    }

    if (setGo && startDatetime != (null || "") && endDatetime != (null || "")) {
      if (startDatetime.getTime() < endDatetime.getTime()) {
        let form = propsobj.formValues;
        setProgress(true);
        let requestBody = `
      mutation
      {
      createAppointment(fromKey:"${form.dataforAppointment.toKey}",fromType:"doctor",fromName:"${localStorage.getItem("loggedInUser")}",
        fromMail:"${localStorage.getItem("loggedUserEmail")}",
        toKey:"${form.dataforAppointment.fromKey}",toType:"patient",toName:"${form.dataforAppointment.refKey3}",
        toMail:"${form.dataforAppointment.email}",
        startDateTime:"${moment.utc(startDatetime).format('YYYY-MM-DD HH:mm:ss')}",
        endDateTime:"${moment.utc(endDatetime).format('YYYY-MM-DD HH:mm:ss')}",
      mailSubject:"${form.app_subject}",
        note:${JSON.stringify(form.app_comments)},
        mailText:"/patient/${sha256(localStorage.getItem("loggedInUser") + form.dataforAppointment.refKey3 + form.app_date.toString() + form.app_starttime.toString())}/videocall",
      docMailText:"/site/${sha256(localStorage.getItem("loggedInUser") + form.dataforAppointment.refKey3 + form.app_date.toString() + form.app_starttime.toString())}/video",
        reference:"${form.dataforAppointment.requestId}"
        ) {
          code
          type
          message
          attributes
        }
      }`;

        requestBody = JSON.stringify({ query: requestBody });
        let res = await oldgenericApi('createAppointment', requestBody, form);
        setProgress(false);

        if (res && res.data.createAppointment.code === '200') {
          propsobj.onClose()
          propsobj.callAppointmentsInfo()
          propsobj.UpdateFormField({ name: "app_date", value: "" })
          // propsobj.UpdateFormField({ name: "app_starttime", value: "" })
          // propsobj.UpdateFormField({ name: "app_endtime", value: "" })
          propsobj.UpdateFormField({ name: "app_comments", value: "" })
          setStartDatetime(null)
          setEndDatetime(null)
        } else {
          console.log('Error')
        }
      }
    }

  }

  const handleCancel = (event) => {
    event.preventDefault();
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
    propsobj.history.goBack();
  }

  const handleStarttime = (data) => {
    console.log(data.selected.value, "starttime")
    propsobj.UpdateFormField({ name: "app_endtime", value: '' })
    setEndtime('')
  }
  const handleEndtime = (data) => {
    console.log(data.selected.value, "endtime")
    // propsobj.UpdateFormField({ name: "app_endtime", value: data.selected.value })
  }
  const handleStartDatetimeChange = (data) => {
    setStartDatetime(data)
    setStartDatetimeErr(false)
    setStartDatetimeText("")
  }
  const handleEndDatetimeChange = (data) => {
    setEndDatetime(data)
    setEndDatetimeErr(false)
    setEndDatetimeText("")
  }
  console.log(startDatetime, endDatetime, "dddddddd")
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* 
      <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO SITE LIST</div><br/> */}


      <div className="">
        <form noValidate autoComplete="off" id="reqApp" className="row" onSubmit={formSubmit}>
          <div className="inputBox col-md-12">
            <div className="row">
              <div className="col-md-12">   <InputField {...textProps} name="app_subject" label="Subject" must={'true'} type="text" /></div>
              <div className="col-md-6 mt-2">
                {/* <HekmaDatePicker name="app_date" label="Select Date" must={'true'} minDate={new Date()} /> */}
                <DateTimePicker label="Start Date & Time" value={startDatetime} handleChange={handleStartDatetimeChange} must={'true'} name="startDatetime" error={startDatetimeErr} errortext={startDatetimeText} />
              </div>
              <div className="col-md-6 mt-2">
                {/* <HekmaDatePicker name="app_date" label="Select Date" must={'true'} minDate={new Date()} /> */}
                <DateTimePicker label="End Date & Time" value={endDatetime} handleChange={handleEndDatetimeChange} mindate={startDatetime} must={'true'} name="endDatetime" error={endDatetimeErr} errortext={endDatetimeText} />
              </div>
              {/* <div className="col-md-4">
                <AutoOptions {...autoPropsToStarttime} autoValue={starttime} name="app_starttime" label="Start Time" keyName='value' must={'true'} callback={handleStarttime} />
              </div>
              <div className="col-md-4">
                <AutoOptions {...autoPropsToEndtime} autoValue={endtime} name="app_endtime" label="End Time" keyName='value' must={'true'} callback={handleEndtime} />
              </div> */}
            </div>

            <InputField {...textProps} multiline={true} variant="outlined" rows={3} rowsMax={3} name="app_comments" placeholder="Description" must={'true'} type="text" />
          </div>
          <div className="buttonContainer col-md-12 mb-3">
            <button type="submit" className="btn btn-primary-blue btn-rounded btn-small " >{text}</button>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReqAppointment));



