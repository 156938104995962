import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import DiseaseTopBar from '../diseaseTopBar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DateDetail from '../dateDetail';
import TestNameDetail from '../testNameDetail';
import AllergiesDetailCard from '../allergiesDetailCard';
import Allergy from '../../../img/Allergies.png'
import { appConfig } from "../../../common/constants";
var month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
function Allergies(props) {
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [descData, setDescData] = useState({})
  let [progress, setProgress] = useState(false);
  let [mild, setMild] = useState(0);
  let [severe, setSevere] = useState(0);
  let [critical, setCritical] = useState(0);

  useEffect(() => {
    setProgress(true);
    var url
    if (props.app !== "Patient") {
      if(props.data?.patient360==="Y"){
        url = `${appConfig.BaseUrl}patientdetail?patientId=${props.data.ehrPatientId}&eventType=AllergyIntolerance&from=0&size=1000`
      }else if (props.data.patientId != "" || null || undefined) {
        url = `${appConfig.BaseUrl}patientdetail?hekmaMail=${props.data.email}&eventType=AllergyIntolerance&from=0&size=1000`
      } else {
        url = `${appConfig.BaseUrl}patientdetail?patientId=${props.data.subjectId}&eventType=AllergyIntolerance&from=0&size=1000`
      }

    } else {
      url = `${appConfig.BaseUrl}patientdetail?hekmaMail=${props.data.email}&eventType=AllergyIntolerance&from=0&size=1000&patientId=${props.data.subjectId}`
    }
    fetch(url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {
        //console.log(responseData, "allergies")
        setProgress(false);
        if (responseData.data.length > 0) {


          var response = responseData
          var modifiedData = []
          var criticalData=[]
          response.data.map((item, i) => {
            var obj = {
              sd: item.onset,
              ed: "",
              category: item?.category,
              severity: item?.criticality,
              t2: process.env.REACT_APP_ENVTYPE==="dev" ? item?.substance?.text : item?.reaction[0].substance?.coding[0].display,
              t2level: item?.status,
              desc:   process.env.REACT_APP_ENVTYPE==="dev" ? item?.substance?.text : item?.reaction[0].substance?.coding[0].display
            }
            if(item?.criticality!=undefined){
              var obj1 = {
                severity: item?.criticality,
              }
            }
            // console.log(obj,"objjjjjjjj")
            modifiedData.push(obj)
            if(obj1!=undefined){
            criticalData.push(obj1)
            }
          })
          setData(modifiedData)
          setCritical(criticalData.length)
        }
        else {
          //console.log('no data found')
        }
      })
  }, [])

  const clickHandler = (e, index, title) => {
    e.preventDefault();
    setDescData(data[index])
    setTitle(title)
  }
  const handleChange = (e) => {
    e.preventDefault()
    if (props.app === "Site") { }
    else {
      props.history.push('/patient/medicalhistory/allergies/add')
    }
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {data.length > 0 ?
        <>
          <section className="row">
            <div className="col-12">
              <section>
                <DiseaseTopBar
                  title="Allergies"
                  icon={Allergy}
                  icolor="#D03D65"
                  total={data.length}
                  des1="Total Allergies"
                  text1="Mild"
                  color1="#fd9437"
                  num1={mild}
                  text2="Severe"
                  color2="#ff4e00"
                  num2={severe}
                  text3="Critical"
                  color3="#b93900"
                  num3={critical}
                  active={data.filter(i => i.t2level === "active").length}
                  addBtnText="Add Allergy"
                  handleChange={handleChange}
                  app={props.app}
                />
              </section>
            </div>

            <div className="col-12">
              <section className="labDetailPage allergiesPage">
                <section className="labTableRow">
                  <div className="row">
                    <div className="col-sm-6">
                      <section className="row">
                        {/* <div className="col-12 col-sm-2">
                          <span><b>Timeline</b></span>
                        </div> */}
                        <div className="col-12 col-sm-4">
                          <span style={{ paddingLeft: "15px" }}><b>Date</b></span>
                        </div>
                        <div className="col-12 col-sm-6">
                          <span><b>Test Name</b></span>
                        </div>
                      </section>
                    </div>
                    <div className="col-sm-6">
                      <span><b>Detail</b></span>
                    </div>
                  </div>
                </section>
                <section className="">
                  <div className="row">

                    <div className="col-sm-6 datedescription">
                      {
                        data.map((item, i) => (
                          <section className="row mb-3">
                            {/* <div className="col-12 col-sm-2 d-flex align-items-center">
                              <GetTitle title="Present" />
                            </div> */}
                            <div className="col-12 col-sm-4">
                              <DateDetail
                                year={new Date(item.sd).getFullYear()}
                                mdate={month_names_short[new Date(item.sd).getMonth()] + ' ' + new Date(item.sd).getDate()}
                                icon="fas fa-allergies"
                                icolor={item.severity === "CRITL" || "CRITU" || "CRITH" ? '#b93900' : item.severity === "mild" ? "#fd9437" : "#ff4e00"}
                              />
                            </div>
                            <div className="col-12 col-sm-6">
                              <TestNameDetail
                                title={item.t2}
                                clickHandler={(e) => clickHandler(e, i, item.t2)}
                                color={item.severity === "CRITL" || "CRITU" || "CRITH" ? '#b93900' : item.severity === "mild" ? "#fd9437" : "#ff4e00"}
                              />
                            </div>
                          </section>

                        ))
                      }
                    </div>
                    <div className="col-sm-6">
                      <AllergiesDetailCard
                        data={Object.keys(descData).length > 0 ? descData : data[0]}
                        title={data.length > 0 && title == "" ? data[0].t2 : title}
                      />
                    </div>
                  </div>
                </section>
              </section>
            </div>
          </section>
        </> : "No Allergies Found"
      }
    </>
  )

}



function GetTitle(props) {
  return (<span>{props.title}</span>)
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}

export default withRouter(connect(mapStateToProps, null)(Allergies));





