import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';

function MenuButtonWithBar(props) {
    return (
        <>
            <section className={`${props.flag? 'piractive' : ''} d-flex align-items-center withbar`}>
                <div className="bar">
                </div>
                <div className="whitebtn">
                    {props.text}
                </div>
            </section>
        </>
    )

}

function MenuButton(props) {
    let [pirmenu, setPIRMenu] = useState({})
    let reduxstateobj = useSelector(state => state);
    useEffect(()=>{
        if(reduxstateobj.pirmenu){
            setPIRMenu({...reduxstateobj.pirmenu}); 
        }
    },[reduxstateobj.pirmenu])

    return (
        <>
            <section className="menucontainer d-flex pt-1">
                <div className="pirmenubtn">
                    Search Criteria
                </div>
                {/* <div className="">
                    <MenuButtonWithBar text="Dashboard" flag={pirmenu.result || false} />
                </div> */}
                <div className="">
                    <MenuButtonWithBar text="Results" flag={pirmenu.patient || false} />
                </div>
            </section>
        </>
    )

}

const mapStateToProps = (state) => {
    return {
        state
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);


