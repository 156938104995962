import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';

const MedicalLab = (props) => {
  console.log(props, "medicallab")
  return (
    <>
      {props.overallData[props.index].subData.length > 0 && props.overallData[props.index].subData.map((item, i) => {
        return (
          <div style={{ border: "1px solid gray", padding: "10px", margin: "10px", borderRadius: "10px" }}>
            <div className="row mt-2">
              <div className="col-md-4">
                <Autocomplete
                  id="combo-box-demo"
                  value={item.subcriteria}
                  onChange={(event, newValue) => {
                    props.setSubCriteria(event, newValue, "subcriteria", i, props.index);
                  }}
                  size='small'
                  options={props.subCriteriaOptions}
                  style={{ width: "100%" }}
                  renderInput={(params) => <TextField {...params} label="Select Subclass" variant="outlined" />}
                />
              </div>
              {item.subcriteria && props.overallData[props.index].subData.length <= 1 && ((i === (props.overallData[props.index].subData.length - 1)) ? <div className="col-md-2">{props.overallData[props.index].subData.length > 1 && <span style={{ color: "red", fontSize: "20px" }} onClick={(e) => props.removeSubRow(e, props.index, i)}> <i className="fa fa-minus-circle mr-1"></i></span>}<span style={{ color: "#244271", fontSize: "25px" }} onClick={(e) => props.addSubRow(e, props.index, i)}> <i className="fa fa-plus-circle mr-1"></i></span></div> : <div className="col-md-2" style={{ color: "red", fontSize: "20px" }} onClick={(e) => props.removeSubRow(e, props.index, i)}> <i className="fa fa-minus-circle mr-1"></i></div>)}
            </div><br />
            {/* newdata[index]["subData"][subindex][0]["addData"].push([{ relation: "", condSelected: [] }]) */}
            {item.subcriteria === "Lab" ? <> {
              item["addData"].length > 0 && item["addData"].map((subitem, j) => {
                return (
                  <>
                    {subitem.addSubData.map((subsubitem, k) => (
                      (
                        <>
                          <div className="row">
                            <div className="col-md-3">
                              <Autocomplete
                                id="size-small-outlined-multi"
                                size="small"
                                value={subsubitem.parameter}
                                onChange={(event, newValue) => {
                                  props.handlelabdatachange(event, newValue, "parameter", i, props.index, j, k, true);
                                }}
                                options={props.labdata.length > 0 ? props.labdata : []}
                                fullWidth
                                style={{ width: "100%" }}
                                getOptionLabel={(option) => option.title}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" label="Parameter" placeholder="Parameter" />
                                )}
                              />
                            </div>
                            <div className="col-md-2">
                              <Autocomplete
                                id="combo-box-demo"
                                value={subsubitem.gender}
                                onChange={(event, newValue) => {
                                  props.handlelabdatachange(event, newValue, "gender", i, props.index, j, k, true);
                                }}
                                size='small'
                                options={props.genderOptions}
                                style={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} variant="outlined" label="Gender" placeholder="Gender" />} />
                            </div>
                            <div className="col-md-2">
                              <Autocomplete
                                id="combo-box-demo"
                                value={subsubitem.unit}
                                onChange={(event, newValue) => {
                                  props.handlelabdatachange(event, newValue, "unit", i, props.index, j, k, true);
                                }}
                                size='small'
                                options={props.units}
                                style={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} variant="outlined" label="Units" placeholder="Units" />} />
                            </div>
                            <div className="col-md-2">
                              <TextField
                                // error={error}
                                id="outlined-error-helper-text"
                                label="Range From"
                                value={subsubitem.rangeFrom}
                                onChange={e => { props.handlelabdatachange(e, e.target.value, "rangeFrom", i, props.index, j, k, true); }}
                                fullWidth
                                size='small'
                                // helperText={error ? "Required" : ""}
                                variant="outlined"
                              />
                            </div>
                            <div className="col-md-2">
                              <TextField
                                // error={error}
                                id="outlined-error-helper-text"
                                label="Range To"
                                value={subsubitem.rangeTo}
                                onChange={e => { props.handlelabdatachange(e, e.target.value, "rangeTo", i, props.index, j, k, true); }}
                                fullWidth
                                size='small'
                                // helperText={error ? "Required" : ""}
                                variant="outlined"
                              />
                            </div>
                            {k === (subitem.addSubData.length - 1) ? <div className="col-md-1">{subitem.addSubData.length > 1 && <span style={{ color: "red", fontSize: "20px" }} onClick={(e) => props.removeLabDataRow(e, props.index, i, j, k, true)}> <i className="fa fa-minus-circle mr-1"></i></span>}<span style={{ color: "#244271", fontSize: "25px" }} onClick={(e) => props.addLabDataRow(e, props.index, i, j, k, true)}> <i className="fa fa-plus-circle mr-1"></i></span></div> : <div className="col-md-1" style={{ color: "red", fontSize: "20px" }} onClick={(e) => props.removeLabDataRow(e, props.index, i, j, k, true)}> <i className="fa fa-minus-circle mr-1"></i></div>}
                          </div>
                          <br />
                        </>
                      )
                    ))
                    }
                    <div className="row">
                      <div className="col-md-4">
                        <TextField
                          // error={error}
                          id="outlined-error-helper-text"
                          label="Time"
                          value={subitem.time}
                          onChange={e => { props.handleChange(e, e.target.value, "time", i, props.index, j, true); }}
                          fullWidth
                          size='small'
                          // helperText={error ? "Required" : ""}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-md-4">
                        <Autocomplete
                          id="combo-box-demo"
                          value={subitem.condition}
                          onChange={(event, newValue) => {
                            props.handleChange(event, newValue, "condition", i, props.index, j, true);
                          }}
                          size='small'
                          options={props.relationOptions}
                          style={{ width: "100%" }}
                          renderInput={(params) => <TextField {...params} label="Select Relation" variant="outlined" />} />
                      </div>
                    </div>


                  </>
                )
              })
            }
            </> : item.subcriteria === "Medical" ? <> {
              item["addData"].length > 0 && item["addData"].map((subitem, j) => {
                return (
                  <>
                    <div className="row" >
                      <div className="col-md-4">
                        <Autocomplete
                          id="combo-box-demo"
                          value={subitem.relation}
                          onChange={(event, newValue) => {
                            props.handleChange(event, newValue, "relation", i, props.index, j, true);
                          }}
                          size='small'
                          options={props.relationOptions}
                          style={{ width: "100%" }}
                          renderInput={(params) => <TextField {...params} label="Select Relation" variant="outlined" />} />
                      </div>
                      <div className="col-md-6"><Autocomplete
                        id="size-small-outlined-multi"
                        size="small"
                        value={subitem.condSelected}
                        onChange={(event, newValue) => {
                          props.handleChange(event, newValue, "condSelected", i, props.index, j, true);
                        }}
                        options={props.conditions.length > 0 ? props.conditions : []}
                        fullWidth
                        multiple
                        style={{ width: "100%" }}
                        getOptionLabel={(option) => option.title}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option.title}
                              size="small"
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Conditions" placeholder="Conditions" />
                        )}
                      /></div>
                      {j === (item["addData"].length - 1) ? <div className="col-md-2">{item["addData"].length > 1 && <span style={{ color: "red", fontSize: "20px" }} onClick={(e) => props.removeRow(e, props.index, i, j, true)}> <i className="fa fa-minus-circle mr-1"></i></span>}<span style={{ color: "#244271", fontSize: "25px" }} onClick={(e) => props.addNewRow(e, props.index, i, j, true)}> <i className="fa fa-plus-circle mr-1"></i></span></div> : <div className="col-md-2" style={{ color: "red", fontSize: "20px" }} onClick={(e) => props.removeRow(e, props.index, i, j, true)}> <i className="fa fa-minus-circle mr-1"></i></div>}
                    </div>
                    <br />
                  </>
                )
              })
            }
            </> : ""}
            {/* {i === (props.overallData[i]["subData"].length - 1) ? <div className="col-md-2" style={{ color: "#244271", fontSize: "25px" }} onClick={(e) => props.addNewRow(e, props.index, i, true)}> <i className="fa fa-plus-circle mr-1"></i></div> : <div className="col-md-2" style={{ color: "red", fontSize: "20px" }} onClick={(e) => props.removeRow(e, props.index, i, true)}> <i className="fa fa-minus-circle mr-1"></i></div>} */}
          </div>
        )
      })
      }
      <div className="row">
        {console.log(props.overallData[props.index].subData.length, props.overallData[props.index].subData.length == 2)}
        {props.overallData[props.index].subData.length == 2 &&
          <div className="col-md-4">
            <Autocomplete
              id="combo-box-demo"
              value={props.overallData[props.index].relation}
              onChange={(event, newValue) => {
                props.setCriteria(event, newValue, "relation", props.index);
              }}
              size='small'
              options={props.relationOptions}
              style={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} label="Relation" variant="outlined" />}
            />
          </div>
        }
      </div>
    </>
  )
}
export default MedicalLab