import React from 'react'
import { Route, Link, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import MainPage from './mainPage';
// import DataUpload from './dataupload'
// import SiteSelection from './siteselectionpage'
// import './sass/siteAppStyles.scss';

// import PatientSignup from './components/activatePatientProfile'


const Routing = () => (
  <Router basename="/admin">
    <Switch>
      <Route path="/admin" component={MainPage} />
      {/* <Route path="/dataUpload" component={DataUpload} /> */}
      {/* <Route path="/siteselection" component={SiteSelection} /> */}
    </Switch>
  </Router>

);
export default Routing;