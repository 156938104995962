import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavMenu } from '../../navMenu';
import StudyGrid from './studyGrid'
import RelatedAccountsListGrid from './relatedAccountsGrid'
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import ViewContact from '../viewContactDetail';
import ContactListGrid from './contactsGrid';
import Details from './Details';
import AuditTrail from "./audittrail";
import AllContactsGrid from './allContactsGrid'


const menus = [
  {
    to: "/account/accountDetails",
    text: "Account Details"
  },
  {
    to: "/account/accountDetails/studies",
    text: "Studies"
  },
  {
    to: "/account/accountDetails/relatedAccounts",
    text: "Related Accounts"
  },
  {
    to: "/account/accountDetails/contacts",
    text: "Contacts"
  },
  {
    to: "/account/accountDetails/auditTrail",
    text: "Audit Trail"
  }
]

const ViewAccounttDetail = (props) => {
  const { sponsorAccountId = '', accountName = '' } = props.formValues;
  return (
    <>
      <div className="d-flex justify-content-end">
        <h1 className="mr-auto primary-header">{accountName}:&nbsp;{sponsorAccountId}</h1>
      </div>

      <NavMenu menus={menus} />

      <div className="mt-4">
        <Route exact path="/account/accountDetails" component={Details} />
        <Route path="/account/accountDetails/contacts/view" component={ViewContact} />
        <Route path='/account/accountDetails/studies' component={StudyGrid} />
        <Route path='/account/accountDetails/relatedAccounts' component={RelatedAccountsListGrid} />
        <Route path='/account/accountDetails/selectContacts' component={AllContactsGrid} />
        <Route exact path="/account/accountDetails/contacts" component={ContactListGrid} />
        <Route path="/account/accountDetails/auditTrail" component={AuditTrail} />
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  console.log(state, "accDetail")
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAccounttDetail));




