import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';

const MedicalData = (props) => {
  console.log(props, "medical")
  return (
    <> {
      props.overallData[props.index].subData.length > 0 && props.overallData[props.index].subData.map((item, i) => {
        return (
          <>
            <div className="row">
              <div className="col-md-4">
                <Autocomplete
                  id="combo-box-demo"
                  value={props.overallData[props.index].subData[i].relation}
                  onChange={(event, newValue) => {
                    props.handleChange(event, newValue, "relation", i, props.index);
                  }}
                  size='small'
                  options={props.relationOptions}
                  style={{ width: "100%" }}
                  renderInput={(params) => <TextField {...params} label="Select Relation" variant="outlined" />} />
              </div>
              <div className="col-md-6"><Autocomplete
                id="size-small-outlined-multi"
                size="small"
                value={props.overallData[props.index].subData[i].condSelected}
                onChange={(event, newValue) => {
                  props.handleChange(event, newValue, "condSelected", i, props.index);
                }}
                options={props.conditions.length > 0 ? props.conditions : []}
                fullWidth
                multiple
                style={{ width: "100%" }}
                getOptionLabel={(option) => option.title}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.title}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Conditions" placeholder="Conditions" />
                )}
              /></div>
              {i === (props.overallData[props.index].subData.length - 1) ? <div className="col-md-2">{props.overallData[props.index].subData.length>1 && <span style={{ color: "red", fontSize: "20px" }} onClick={(e) => props.removeRow(e, props.index,i)}> <i className="fa fa-minus-circle mr-1"></i></span>}<span style={{ color: "#244271", fontSize: "25px" }} onClick={(e) => props.addNewRow(e, props.index,i)}> <i className="fa fa-plus-circle mr-1"></i></span></div> : <div className="col-md-2" style={{ color: "red", fontSize: "20px" }} onClick={(e) => props.removeRow(e, props.index,i)}> <i className="fa fa-minus-circle mr-1"></i></div>}
            </div>
            <br />
          </>
        )
      })
    }
    </>
  )
}
export default MedicalData