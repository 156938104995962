import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavMenu } from '../navMenu';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import DropdownConfig from './dropdownConfig'
import EmailTemplates from './emailtemplates/home'

const menus = [
  {
    to: "/site/adminfeatures",
    text: "Dropdowns Config"
  },
  {
    to: "/site/adminfeatures/emailtemplates",
    text: "Email Templates"
  }
]
const AdminFeaturesHome = (props) => {
  useEffect(()=>{
    props.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  },[])
  return (
    <>
      <NavMenu menus={menus} />
      <div className="">
        <Route exact path="/site/adminfeatures" component={DropdownConfig} />
        <Route path="/site/adminfeatures/emailtemplates" component={EmailTemplates} />
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminFeaturesHome));




