import React, { useReducer, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { connect, useSelector, useDispatch } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormHoc from '../../../../globalcomponents/hoc/formHoc';
import { InputField, AutoOptions, AutoOptionsNew } from '../../../../globalcomponents/formElements';
import { ErrorObj } from '../../../../common/constants';
import AgeSelection from './ageSelection';
import MultiSelect from '../../../../globalcomponents/multiselect';



const defaultrace = {
    options: ['American Indian or Alaska Native', 'Asian', 'Black or African American', 'Hispanic or Latino', 'Native Hawaiian or Other Pacific Islander', 'White'],
    getOptionLabel: (option) => option,
};

const defaultedns = {
    options: ['None', 'High School', 'Undergraduate', 'Post Graduate', 'Doctorate', 'Professional Degree'],
    getOptionLabel: (option) => option,
};


export default function EntityAutoForm(props) {
    const reduxdispatch = useDispatch();
    let reduxstatentitydata = useSelector(state => state.entitydata);

    const getValue = () => {
        if (props.values) {
            if (props.values.length) {
                try {
                    return props.values.join(',')
                } catch (error) {
                    return props.values
                }
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    const initialForm = {
        //intype: !props.inclusion ? 'exclusion' : 'inclusion',
        intype: props.cssClass || 'inclusion',
        display_term: props.display_term || '',
        id: props.id || '',
        values: getValue(),
        ent_type: props.ent_type || ''
    }

    const pirErrTextMsgs = {
        'display_term': 'Please enter this field',
        'ent_type': 'Please choose the value to this field',
        'demo_attr': 'Please choose the value to this field'
    }

    let [value, setValue] = React.useState(null);
    const [entype, setEnType] = React.useState(props.ent_type || '');
    const [form, setForm] = React.useState(initialForm);
    const [pirErrObj, setpirErrObj] = React.useState({});
    const [pirErrTextObj, setpirErrTextObj] = React.useState(pirErrTextMsgs);
    const [operators, setOperators] = React.useState(props.operators || ['=']);
    const resetErrorObj = {
        ent_type: false,
        display_term: false,
        demo_attr: false
    }


    const defaultProps = {
        options: props.ddlist || [],
        getOptionLabel: (option) => option,
    };

    const [invalue, setInValue] = React.useState('inclusion');

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value || event.target.checked
        })
    };

    const handleChangefromChild = (name, value) => {
        setForm({
            ...form,
            [name]: value
        })
    };

    const handleOperators = (operator) => {
        setOperators([operator]);
    }

    const resetError = () => {
        setpirErrObj({ ...pirErrObj, ...resetErrorObj })// resetting
    }


    const entityHandleChange = async (event, newValue, name) => {
        if (event) event.preventDefault();
        newValue = newValue === undefined ? value : newValue;
        try {
            if (newValue === null) {// when you select nothing
                value = '';
                newValue = '';
            }
        } catch {
            value = '';
        }
        resetError();
        setEnType(newValue);
        setForm({
            ...form,
            [name]: newValue
        })
    }

    const validateForm = (form) => {
        let { display_term = false, ent_type = false, demo_attr = false } = form;
        if (!ent_type) {
            setpirErrObj({ ...pirErrObj, ent_type: true })
            return;
        }

        // if demo
        if (ent_type == "Demographics") {
            if (!demo_attr) {
                setpirErrObj({ ...pirErrObj, demo_attr: true })
                return;
            }
        }

        if (!display_term) {
            setpirErrObj({ ...pirErrObj, display_term: true })
            return;
        }

        resetError();
        return 'true';
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (props.addform) { // Add entity type
            let isValid = validateForm(form);
            if (isValid != 'true') {
                return false;
            }
            form.operators = operators || [];
            form.cssClass = form.intype;
            let originalData = { ...props.allData };
            if (originalData[form.ent_type]) {
                originalData[form.ent_type]['combinedItems'].push(form);
            } else {
                try {
                    originalData[form.ent_type] = {}
                    originalData[form.ent_type]['combinedItems'] = [];
                    originalData[form.ent_type]['combinedItems'].push(form);
                } catch (error) {
                    alert('error in update function')
                }
            }
            props.dialogclose(false);
            props.updateEntityData(originalData);

        } else { // Edit entity type
            updateObj(form)
        }
    }

    const updateObj = (form_item) => {
        let newData = { ...props, ...form_item, cssClass: form_item.intype };
        let isTypeChanged = (props.ent_type != form_item.ent_type); // entity type changes
        if (isTypeChanged) {
            // delete from old entity type and save it in new entity type
            // true -> delete and save, false-> only save
            props.updateEntityDataByEdit(newData, true, props.ent_type, form_item.ent_type);
            //props.dialogclose(false, newData);

        } else {
            props.dialogclose(false);
            props.updateEntityDataByEdit(newData, false);
        }
    }

    return (<>
        <form className="entityAutoform" noValidate autoComplete="off" onSubmit={handleSubmit} id="pirform">
            <Autocomplete
                {...defaultProps}
                debug={true}
                value={entype}
                disableClearable
                name='ent_type'
                onChange={(event, newValue) => entityHandleChange(event, newValue, 'ent_type')}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label="Entity Type"
                        fullWidth
                        name='ent_type'
                        InputLabelProps={{
                            className: 'must'
                        }}
                        error={pirErrObj['ent_type'] || false}
                        helperText={pirErrObj['ent_type'] ? pirErrTextObj['ent_type'] : ''}
                    />
                }
            />

            <FormControl component="fieldset" className="radioBox mt-4">
                {/* <FormLabel component="legend">Inclusion / Exclusion</FormLabel> */}
                <RadioGroup aria-label="Type" name="intype" value={form.intype || 'inclusion'} onChange={handleChange}>
                    <FormControlLabel value="inclusion" control={<Radio />} label="Inclusion" />
                    <FormControlLabel value="exclusion" control={<Radio />} label="Exclusion" />
                </RadioGroup>
            </FormControl>

            {entype == 'Demographics' && <>
                <Demographics {...props} handleChange={handleChange} {...form}
                    pirErrObj={pirErrObj}
                    pirErrTextObj={pirErrTextObj}
                    handleOperators={handleOperators}
                    resetError={resetError}
                    handleChangefromChild={handleChangefromChild}
                />
            </>
            }

            {entype == 'Allergy' && <>
                <Allergy {...props} handleChange={handleChange} {...form}
                    pirErrObj={pirErrObj}
                    pirErrTextObj={pirErrTextObj}
                />
            </>
            }

            {((entype == 'Medical Condition') || (entype == 'Condition')) && <>
                <Condition {...props} handleChange={handleChange} {...form}
                    pirErrObj={pirErrObj}
                    pirErrTextObj={pirErrTextObj}
                />
            </>
            }

            {entype == 'Drug' && <>
                <Drug {...props}
                    handleChange={handleChange}
                    {...form}
                    pirErrObj={pirErrObj}
                    pirErrTextObj={pirErrTextObj}
                />
            </>
            }

            {((entype == 'Lab') || (entype == 'LabReport')) && <>
                <LabReport
                    {...props}
                    handleChange={handleChange}
                    {...form}
                    pirErrObj={pirErrObj}
                    pirErrTextObj={pirErrTextObj}
                />
            </>
            }

            {/* <TextField
                label="Value"
                value={form['values'] || ''}
                name={'values'}
                fullWidth
                onChange={handleChange}
            /> */}

            {!props.addform && <>
                <TextField
                    label="Value"
                    value={form['values'] || ''}
                    name={'values'}
                    fullWidth
                    onChange={handleChange}
                />
            </>
            }
            <div className="buttonContainer mt-4">
                <button type="submit" className="btn btn-primary-blue btn-rounded btn-small">Save</button>
            </div>
        </form>
    </>
    );
}

function LabReport(props) {
    ////console.log('pirErrObj:', props.pirErrObj)
    return (
        <>
            <TextField
                label="Test name"
                value={props.display_term || ''}
                name={'display_term'}
                fullWidth
                onChange={props.handleChange}
                InputLabelProps={{
                    className: 'must'
                }}
                error={props.pirErrObj['display_term'] || false}
                helperText={props.pirErrObj['display_term'] ? props.pirErrTextObj['display_term'] : ''}
            />
            {/* <TextField
                label="Min Value"
                name={'minvalue'}
                fullWidth
                onChange={props.handleChange}
            />
            <TextField
                label="Max Value"
                name={'maxvalue'}
                fullWidth
                onChange={props.handleChange}
            /> */}
        </>
    )
}

function Condition(props) {
    // //console.log('condition:', props)
    return (
        <>
            <TextField
                label="Condition"
                value={props.display_term || ''}
                name={'display_term'}
                fullWidth
                onChange={props.handleChange}
                InputLabelProps={{
                    className: 'must'
                }}
                error={props.pirErrObj['display_term'] || false}
                helperText={props.pirErrObj['display_term'] ? props.pirErrTextObj['display_term'] : ''}
            />
        </>
    )
}

function Allergy(props) {
    // //console.log('allergy:', props)
    return (
        <>
            <TextField
                label="Allergy"
                value={props.display_term || ''}
                name={'display_term'}
                fullWidth
                onChange={props.handleChange}
                InputLabelProps={{
                    className: 'must'
                }}
                error={props.pirErrObj['display_term'] || false}
                helperText={props.pirErrObj['display_term'] ? props.pirErrTextObj['display_term'] : ''}
            />
        </>
    )
}

function Demographics(props) {
    const [enType, setEntityType] = React.useState('');
    const handleChange = (event) => {
        //setValue(event.target.value);
        props.handleChange(event);
    };

    const Elements = {
        options: ['Age', 'Race', 'Education', 'Zip code', 'Gender', 'Language'],
        getOptionLabel: (option) => option,
    };

    const entityHandleChange = async (event, newValue) => {
        if (event) event.preventDefault();
        newValue = newValue === undefined ? null : newValue;
        try {
            if (newValue === null) {// when you select nothing
                newValue = '';
            }
        } catch {
            newValue = '';
        }
        props.handleChangefromChild('demo_attr', newValue);
        props.resetError();
        setEntityType(newValue);
    }

    return (
        <>

            <Autocomplete
                {...Elements}
                debug={true}
                value={enType}
                disableClearable
                onChange={(event, newValue) => entityHandleChange(event, newValue)}
                renderInput={(params) => <TextField
                    {...params}
                    label="Select Entity Attributes"
                    fullWidth
                    InputLabelProps={{
                        className: 'must'
                    }}
                    error={props.pirErrObj['demo_attr'] || false}
                    helperText={props.pirErrObj['demo_attr'] ? props.pirErrTextObj['demo_attr'] : ''}
                />}
            />

            {enType == 'Age' && <>
                <div className="mt-2">
                    <AgeSelection handleAge={props.handleOperators} />
                </div>

                <TextField
                    label="Age"
                    name={'display_term'}
                    value={props.display_term || ''}
                    fullWidth
                    onChange={props.handleChange}
                    InputLabelProps={{
                        className: 'must'
                    }}
                    error={props.pirErrObj['display_term'] || false}
                    helperText={props.pirErrObj['display_term'] ? props.pirErrTextObj['display_term'] : ''}
                />
            </>
            }

            {enType == 'Race' && <>
                <Autocomplete
                    {...defaultrace}
                    debug={true}
                    renderInput={(params) => <TextField {...params} label="Race" fullWidth />}
                />
            </>
            }

            {enType == 'Education' && <>
                <Autocomplete
                    debug={true}
                    {...defaultedns}
                    renderInput={(params) => <TextField {...params} label="Education" fullWidth />}
                />
            </>
            }

            {enType == 'Zip code' && <>
                <TextField
                    label="Zip Code"
                    name={'display_term'}
                    value={props.display_term || ''}
                    fullWidth
                    onChange={props.handleChange}
                    InputLabelProps={{
                        className: 'must'
                    }}
                    error={props.pirErrObj['display_term'] || false}
                    helperText={props.pirErrObj['display_term'] ? props.pirErrTextObj['display_term'] : ''}
                />
            </>
            }

            {enType == 'Gender' && <>
                <FormGroup row className="mt-4">
                    <FormLabel component="legend">Gender</FormLabel>
                    <FormControlLabel control={
                        <Checkbox
                            name="male"
                            onChange={handleChange}
                        />
                    }
                        label="Male"
                    />

                    <FormControlLabel control={
                        <Checkbox name="female"
                            onChange={handleChange}
                        />
                    }
                        label="Female"
                    />
                </FormGroup>
            </>
            }

            {enType == 'Language' && <>
                <TextField
                    label="Language"
                    name={'display_term'}
                    value={props.display_term || ''}
                    fullWidth
                    onChange={props.handleChange}
                    InputLabelProps={{
                        className: 'must'
                    }}
                    error={props.pirErrObj['display_term'] || false}
                    helperText={props.pirErrObj['display_term'] ? props.pirErrTextObj['display_term'] : ''}
                />
            </>
            }

            {/* <TextField
                label="Name"
                value={props.display_term || ''}
                name={'display_term'}
                fullWidth
                onChange={props.handleChange}
            /> */}

        </>
    )
}

function Drug(props) {
    ////console.log('drug:', props)
    return (
        <>
            <TextField
                label="Drug Name"
                value={props.display_term || ''}
                name={'display_term'}
                fullWidth
                onChange={props.handleChange}
                InputLabelProps={{
                    className: 'must'
                }}
                error={props.pirErrObj['display_term'] || false}
                helperText={props.pirErrObj['display_term'] ? props.pirErrTextObj['display_term'] : ''}
            />
            <TextField
                label="Strength"
                name={'strength'}
                fullWidth
                onChange={props.handleChange}
            />
            <TextField
                label="Dosage"
                name={'dosage'}
                fullWidth
                onChange={props.handleChange}
            />
            <MultiSelect />
        </>
    )
}