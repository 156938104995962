import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import MHSearchField from '../mhSearchField';
import Medicard from '../mediCard';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { appConfig } from "../../../common/constants";
function Encounters(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');
  useEffect(() => {
    setProgress(true);
    fetch(`${appConfig.BaseUrl}patientdetail?patientId=${props.data.subjectId}&eventType=Encounter&from=0&size=1000&patientId=${props.data.subjectId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {

        setProgress(false);
        if (responseData.data.length > 0) {
          //console.log(responseData, "medications")
          var finaldata = []
          responseData.data.map((item, i) => {
            //console.log(item?.reason,"item")
            //console.log(item.reason,"item1")
            var obj = {
              // "instruction": item.dosageInstruction[0].doseQuantity.value + '/' + item.dosageInstruction[0].timing.repeat.periodUnits,
              "instruction":"",
              "type":item.type[0].text,
              "setting":item.class,
              "startDate": moment(item.period.start).format('MMM Do YYYY'),
              "endDate":  moment(item.period.end).format('MMM Do YYYY'),
              "title": item.reason!=undefined ? item?.reason[0]?.coding[0]?.display :"",
              "status": item.status
            }
            finaldata.push(obj)
          })
          setData(finaldata)
        } else {
          //console.log('no data found')
        }
      })
  }, [])
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {
        data.length > 0 ? <>
          {/* <section>
            <MHSearchField
              title="Medication"
              label="Search Medication"
              btnName="ADD MEDICINE"
            />
          </section> */}

          <section className="row">
            {data.map((item, i) => (
              <div className="col-12 col-sm-4">
                <Medicard
                  instruction={item.instruction}
                  type={item.type}
                  setting={item.setting}
                  title={item.title}
                  sd={item.startDate}
                  ed={item.endDate}
                  status={item.status}
                />
              </div>
            ))}

          </section>
        </>
          : "No encounters found"
      }
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}

export default withRouter(connect(mapStateToProps, null)(Encounters));
