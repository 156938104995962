import React, { useEffect, useState } from "react";
import Materialtable from '../../../globalcomponents/materialtable';
import { genericApi } from '../../../common/apiconfig';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Paginationn } from '../../../common/pagination'
import { appConfig } from "../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import Loadable from 'react-loadable';
import { checkPermission } from '../../../common/utilis';
import { withRouter } from 'react-router-dom';
function AEEvents(props) {
  let isAddContact = checkPermission('S_Study_Site_Users_Create', 'Create');

  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');

  useEffect(() => {
    async function getApiData() {
      fetchData()
    }
    getApiData();
  }, []);
  useEffect(() => {
    async function getApiData() {
      // alert('2')
      fetchData()
    }
    getApiData();
  }, [props.formValues.autostudy.studyId]);

  const fetchData = async () => {
    let requestBody = {
      query: `query {
          getStudySiteAdverseEvents(studyId:"${props.formValues.autostudy.studyId}",siteId:"${props.formValues.autosite.sponsorAccountId}") {
            adverseEventId
            studyId
            siteId
            patientId
            aeTerm
            severity
            causality
            outcome
            startDate
            stopDate
            createdBy
            createdTs
            modifiedBy
            modifiedTs
          }
        }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getStudySiteAdverseEvents', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error on response material grid!')

    } else {
      if (res.data.getStudySiteAdverseEvents.length) {
        setData(res.data.getStudySiteAdverseEvents)
      }else{
        setData([])
      }
    }
  }


  const handleClick = async (event) => {
    event.preventDefault();
    props.history.push('/site/study/aevents/addAE');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    await props.UpdateEntireForm(rowData);
    props.history.push('/site/study/aevents/viewAE');
  }
  const Columns = [
    {
      title: 'Subject ID', field: 'patientId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'AE Term', field: 'aeTerm',
      cellStyle: {
        color: '#244271',
        fontWeight: 500
      },
    },
    { title: 'Severity', field: 'severity' },
    { title: 'Causality', field: 'causality' },
    { title: 'Start Date', field: 'startDate' },
    { title: 'Stop Date', field: 'stopDate' },
  ]
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex mt-4">
        <div class="secondary-header mr-auto">Adverse Events</div>
        {isAddContact === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
              <i className="fa fa-plus-circle mr-1"></i> ADD ADVERSE EVENT</button>
          </div> : ""}
      </div>

      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="">

            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AEEvents));



