import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../formElements';
import { connect } from 'react-redux';
import { apiCall } from '../../common/apiconfig';
import { checkPermission } from '../../common/utils';


const ViewContactDetail = (propsobj) => {
  let isEditContact = checkPermission('Contact_Accounts_Edit', 'Update');
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [sponsorActUserId, setsponsorAccountUserId] = useState('')

  useEffect(() => {
    let sponsorUserId = ''
    try {
      sponsorUserId = propsobj.location.state[0].id;
    } catch {
      sponsorUserId = propsobj.formValues.sponsorAccountUserId;
    }
    setsponsorAccountUserId(sponsorUserId);
  },[propsobj.location.state])

  useEffect(() => {
    async function fetchData() {
      if (sponsorActUserId != "") {
        let requestBody = {
          query: `query {
                    getsponsorAccountUser(sponsorAccountUserId: "${sponsorActUserId}") {
                        sponsorAccountUserId
                        sponsorAccountId
                        SponsorAccountName
                        firstName
                        middleName
                        lastName
                        email
                        phone
                        role
                        roleId
                        position
                        language
                        invitedBy
                        invitedDate
                        activated
                        portalAccessInd
                        primaryContactInd
                        createdBy
                        createdTs
                        modifiedBy
                        modifiedTs
                }
                getAccountsOfUser(sponsorAccountUserId:"${sponsorActUserId}") {
                        accountType
                }  
            }`

        };

        requestBody = JSON.stringify(requestBody);
        setProgress(true);
        let res = await apiCall('getsponsorAccountUser', requestBody);
        console.log('res::contact', res);
        setProgress(false);
        if (!res) {
          // Error handling
          console.log('Error on response material grid!')

        } else {
          if (res.data.getsponsorAccountUser) {
            propsobj.UpdateEntireForm(res.data.getsponsorAccountUser)
            propsobj.UpdateFormField({ name: "accountType", value: res.data.getAccountsOfUser[0].accountType })
          }
        }
      }
    }
    fetchData();
  }, [sponsorActUserId]);
  const handleClick = async (event) => {
    event.preventDefault();
    if (propsobj.formValues.siteUserHeading == "") {
      propsobj.history.push('/account/updateContact');
    } else {
      propsobj.history.push('/account/studyDetails/site/siteDetails/users/update');
    }
  }

  const { sponsorAccountUserId = '', middleName = '', firstName = '', lastName = '', SponsorAccountName = '', role = '', email = '', phone = '', language = '', position = '' } = propsobj.formValues;

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}


      <div className="d-flex mt-4">
        <div class="secondary-header mr-auto">
          {/* {propsobj.formValues.siteUserHeading == "" ? "Contact Details" : propsobj.formValues.siteUserHeading} */} Details
          </div>
        {isEditContact === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
              <i className="fas fa-pencil-alt"></i>&nbsp; EDIT</button>
          </div> : ""
        }
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="addAccountForm" className="row h-form-1">
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">NAME INFORMATION</div>
                  <div className="inputBox">
                    <ViewRow text="First Name" textvalue={firstName} />
                    <ViewRow text="Middle Name" textvalue={middleName == "" ? "- - -" : middleName} />
                    <ViewRow text="Last Name" textvalue={lastName} />
                    <ViewRow text="Account Name" textvalue={SponsorAccountName} />
                    <ViewRow text="Position" textvalue={position} />
                    <ViewRow text="User Access" textvalue={role == ("" || null) ? "- - -" : role} />
                    <ViewRow text="Language" textvalue={language} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  <div className="card-page-header">CONTACT INFORMATION</div>
                  <div className="inputBox">
                    <ViewRow text="Phone" textvalue={phone} />
                    <ViewRow text="Email" textvalue={email} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewContactDetail));



