import React, { useEffect, useState } from "react";
import Materialtable from '../../../../../globalcomponents/materialtable';
import { apiCall } from '../../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paginationn } from '../../../../common/pagination'
import { appConfig, Dropdowns } from "../../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkPermission } from '../../../../common/utils';

function AddUsers(props) {
  let isEditStudy = checkPermission('Study_Details_Edit', 'Update');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [selectedUsersobj, setSelectedUsersObj] = useState({})
  let [currentpage, setCurrentPage] = useState(1);
  let [emptyObjErr, setEmptyObjErr] = useState("")

  async function fetchData() {
    let requestBody = {
      query: `query {
        getAllSponsorAccountUser(sponsorAccountId:"${props.formValues.pagId}") {
          sponsorAccountUserId
          sponsorAccountId
          SponsorAccountName
          firstName
          lastName
          email
          phone
          role
          position
          language
          invitedBy
          invitedDate
          activated
          primaryContactInd
                }
              }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await apiCall('getAllSponsorAccountUser', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      console.log('Error on response material grid!')

    } else {
      if (res.data.getAllSponsorAccountUser.length) {
        if (props.formValues.sitecontacts == "") {
          const newFilteredContactArr = res.data.getAllSponsorAccountUser.map(v => ({ ...v, role: "" }))
          setData(newFilteredContactArr)
        } else {
          var contactsData = JSON.parse(props.formValues.sitecontacts)
          var contacts = res.data.getAllSponsorAccountUser
          contacts = contacts.filter(function (item) {
            for (var i = 0, len = contactsData.length; i < len; i++) {
              if (contactsData[i].contactId == item.sponsorAccountUserId) {
                return false;
              }
            }
            return true;
          });
          const newFilteredContactArr = contacts.map(v => ({ ...v, role: "" }))
          setData(newFilteredContactArr)
        }
      } else {
        setData([])
      }
    }
  }
  useEffect(() => {
    fetchData();
  }, []);


  const handlePageClick = (offset, pageNum) => {
    setCurrentPage(pageNum);
    setoffset(offset)
  }
  const handleCancel = (e) => {
    e.preventDefault()
    props.history.goBack()
  }
  const handleClick = async (event) => {
    event.preventDefault();
    // props.UpdateFormField({ name: "addContactFromContactSearch", value: true })
    props.UpdateFormField({ name: "contactaddformclear", value: true })
    props.UpdateFormField({ name: "sponsorAccountId", value: props.formValues.pagId })
    props.UpdateFormField({ name: "accountName", value: props.formValues.pagAccountName })
    props.history.push('/account/addContact');
  }

  const handleRowClick = async (event, rowData) => {
    event.preventDefault()
    // await props.UpdateEntireForm(rowData);
    // props.history.push('/account/contact/view');
  }
  const rowsSelected = (rows) => {
    setSelectedUsersObj({ ...selectedUsersobj, [currentpage]: rows });
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(selectedUsersobj, "console.log(selectedUsersobj)")
    const chkObj = o => Object.values(o).every(a => a.length === 0)
    if (chkObj(selectedUsersobj)) {
      setEmptyObjErr('Select atleast one user')
    } else if (Object.keys(selectedUsersobj).length == 0 && selectedUsersobj.constructor === Object) {
      setEmptyObjErr('Select atleast one user')
    } else {
      setEmptyObjErr("")
      //this to get selected users arr from different pages obj
      let selectedUsers = []
      for (let i in selectedUsersobj) {
        if (selectedUsersobj[i].length > 0) {
          for (let j of selectedUsersobj[i]) {
            selectedUsers = [...selectedUsers, j]
          }
        }
      }

      console.log(selectedUsers, "selectedUsers")
      //this is to get updated data from data
      // var newSelected = []
      // for (var i = 0; i < selectedUsers.length; i++) {
      //   for (var j = 0; j < data.length; j++) {
      //     if (selectedUsers[i].sponsorAccountUserId == data[j].sponsorAccountUserId) {
      //       newSelected.push(data[j])
      //     }
      //   }
      // }
      // console.log(data, newSelected, "new")
      let selectedUserss = '';
      selectedUsers.map(contact => {
        selectedUserss += `{ studyId: "${props.formValues.studyId}",pagId:"${contact.sponsorAccountId}", contactId: "${contact.sponsorAccountUserId}"}`;
      });
      let usersSelected = selectedUserss.replace(/}\s*{/g, '},{');

      let requestBody = {
        query: `mutation {
        addStudyPagMultiContact(studyPagContactList: [${usersSelected}]) {
        code
        type
        message
        attributes
      }
    }`
      };
      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('addStudyPagMultiContact', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error!')

      } else {
        console.log('added')
        props.history.goBack()
      }
    }
  }
  const Columns = [
    {
      title: 'Name', field: 'firstName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        fontSize: "14px"
      },
      render: rowData => rowData.firstName + ' ' + rowData.lastName
    },
    { title: 'Position', field: 'position' },
    { title: 'Email', field: 'email' },
    { title: 'Phone', field: 'phone' },
    // {
    //   title: 'Role', field: 'position', render: rowData => <Autocomplete
    //     id="controlled-demo"
    //     options={Dropdowns.Study_Site_Primary_Contact_Role}
    //     getOptionLabel={option => option.name ? option.name : ""}
    //     value={rowData.position}
    //     onChange={(e, value) => selectRole(e, value, rowData, data)}
    //     renderInput={(params) => <TextField {...params} margin="none" fullWidth />}
    //   />
    // },
  ]
  // const selectRole = (e, value, rowData, data) => {
  //   e.preventDefault()
  //   setData(data.map(item => item.sponsorAccountUserId === rowData.sponsorAccountUserId ? { ...item, position: value } : item))
  // }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex justify-content-end">
        <div className="secondary-header mr-auto">Add Users</div>
        {isEditStudy === "1" ? <div className="">
          <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
            <i className="fa fa-plus-circle mr-1"></i> ADD NEW USER</button>
        </div> : ""}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
              rowsSelected={rowsSelected}
              selection={true}
            />
          </div>
          {<span className="multiselecterrmsg">{emptyObjErr}</span>}
          {data.length > 0 &&
            <div className="buttonContainer ml-auto">
              <button className="btn btn-primary-blue btn-rounded btn-small mr-1" onClick={handleSubmit}>Save</button>&nbsp;
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
            </div>
          }
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUsers));


