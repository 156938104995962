import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

export const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center'
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
        color: '#1a90ff',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
}));

function FacebookCircularProgress(props) {
    const classes = useStylesFacebook();

    return (
        <div className={classes.root}>
            
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={props.size}
                    thickness={1}
                    {...props}
                    value={100}
                />
            
            <div className="secondCircle" style={{
                height:props.size,
                width:props.size
            }}>
                <CircularProgress
                    variant="static"
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={props.size}
                    thickness={2}
                    {...props}
                    value={props.value}
                    style={{
                        color:props.color || ''
                    }}
                >
                </CircularProgress>
                <span style={{marginLeft:"0px"}}>{props.display}</span>
            </div>

        </div>
    );
}

const useStyles = makeStyles({
    root: {
        flexGrow: 0,
    },
});

export function CustomizedProgressBars(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <FacebookCircularProgress {...props}/>
        </div>
    );
}

