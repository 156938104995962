import React, { Component } from 'react';
import matchSorter from 'match-sorter';
import { ErrorLables } from '../../common/lables';

function AutoOptions(props) {
    let defaultValue = props.defaultValue;
    const [autoValue, setValue] = React.useState(defaultValue);
    const autoHandleChange = async (event, newValue, callback) => {
        if (event) event.preventDefault();
        newValue = newValue === undefined ? value : newValue;
        if (newValue && newValue.inputValue) {
            setValue({
                [props.keyName]: newValue.inputValue,
            });
            return;
        }
        setValue(newValue);

        let name = props.name;
        let value = '';
        let flag = false;
        let text = '';

        try {
            if (newValue === null) {// when you select nothing
                value = '';
                flag = true;
                text = ErrorLables[name]
            } else {
                value = newValue[props.keyName] === undefined ? newValue : newValue[props.keyName];
                flag = false;
                text = '';
            }
        } catch{
            value = '';
        }

        let obj = {
            name,
            value,
            selected:newValue
        }

        let errobj = {
            name,
            flag,
            text
        }

        let isItMust = props.props.must;
        isItMust = (isItMust === 'true') ? true : false;
        if (isItMust) {
            props.propsobj.updateErrorObj(errobj);
        }
        callback(obj);
        props.propsobj.UpdateFormField(obj);
    }

    const filterOptions = (options, { inputValue }) => {
        let keys = props.keys;
        return matchSorter(options, inputValue, { keys: keys })
    }

    const getOptionLabel = option => {
        // e.g value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        if (option && option.inputValue) {
            return option.inputValue;
        }
        return option && option[props.keyName]
    }

    return {
        autoHandleChange,
        filterOptions,
        getOptionLabel,
        autoValue,
    }
}

export default AutoOptions;

