import React, { useEffect, useState, useReducer, useContext } from "react";
import Loadable from 'react-loadable';
//import { useReactRouter } from 'react-router-dom';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AutoOptionsNew } from '../../globalcomponents/formElements';
import { genericApi } from '../../common/apiconfig';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R_Items from '../components/recentItems';
import { checkPermission } from '../../common/utilis';
import Loader from "../../common/loader";

const ChangeStudy = (propsobj) => {
  //console.log(propsobj.formValues.autosponsor,"propsobj.formValues.autosponsor")
  let isSiteSelectionPage = checkPermission('S_Site_Selection', 'Read');
  let [studies, setStudies] = useState([]);
  let [progress,setProgress]=useState(false)
  let reduxstateobj = useSelector(state => state);
  const reduxdispatch = useDispatch();
  let [showStudy, setShowStudy] = useState({
    studyId: "", studyNumber: "", parentAccountId: "", studyTitle: ""
  })

  useEffect(()=>{
    if(isSiteSelectionPage==="0"){

    }
     else {
      getStudyList()
      
    }
  },[])

  useEffect(() => {
    if (propsobj.formValues?.autostudy) {
      setShowStudy(propsobj.formValues.autostudy)
    }
  }, [propsobj.formValues.autostudy])
  async function getStudyList() {
    let requestBody = {
      query: `query {
                getStudysOfSiteWithParticularSponsor(siteId:"${propsobj.formValues.autosite.sponsorAccountId}",sponsorAccountId:"${(propsobj.formValues.autosponsor == undefined || propsobj.formValues.autosponsor == '') ? "*" : propsobj.formValues.autosponsor.sponsorAccountId}"){
                  studyId
                  studyNumber
                  parentAccountId
                  parentAccountName
                  studyTitle
                  nameofIMP
                  devPhase
                  status
                  primaryEndPoint
                  secondaryEndPoint
                  expEndPoint
                  startDt
                  endDt
                  studyDuration
                  stoppingCriteria
                  numberOfSitesPlanned
                  numberOfSubPlanned
                  route
                  regiman
                  dosage
                  statMethod
                  interimAnalysis
                  studyFlow
                  protocolSynopsisActual
                  protocolFinalActual
                  fdaSubmissionActual
                  firstSite_OpenActual
                  fpiActualActual
                  lpoActualActual
                  dbLockActual
                  csrActualActual
                  protocolSynopsisReceived
                  protocolFinalReceived
                  fdaSubmissionReceived
                  firstSite_OpenReceived
                  fpiReceivedReceived
                  lpoReceivedReceived
                  dbLockReceived
                  csrReceivedReceived
                  createdBy
                  createdTs
                  modifiedBy
                  modifiedTs
                }
            }`
    };
    setProgress(true);
    requestBody = JSON.stringify(requestBody);
    let res = await genericApi('getStudysOfSiteWithParticularSponsor', requestBody);
    setProgress(false);
    if (!res) {
      //setApiError(true);
      //setTimeout(function () { setApiError(false) }, 1000 * 30);
    } else {
      if (res.data.getStudysOfSiteWithParticularSponsor.length > 0) {
        setStudies(res.data.getStudysOfSiteWithParticularSponsor);
        propsobj.UpdateFormField({ name: "studylist", value: res.data.getStudysOfSiteWithParticularSponsor })
        setShowStudy(res.data.getStudysOfSiteWithParticularSponsor[0]);
        var obj1 = {
          name: "autostudy",
          value: res.data.getStudysOfSiteWithParticularSponsor[0]
        }
        propsobj.UpdateFormField(obj1)
        reduxdispatch({ type: 'updateStudy', value: res.data.getStudysOfSiteWithParticularSponsor[0] || '' })
      } else {
        setStudies([])
        setShowStudy({ studyId: "", studyNumber: "", parentAccountId: "", studyTitle: ""})
        propsobj.UpdateFormField({name:"autostudy",value:""})
        reduxdispatch({ type: 'updateStudy', value: '' })
      }
    }
  }

  const autoPropstoStudies = {
    'options': studies,
    propsobj,
    keys: ['studyNumber', 'studyTitle'],
    error: '',
    helperText: ''
  }

  const handleStudies = async (obj) => {
    setShowStudy(obj.selected);
    var obj1 = {
      name: obj.name,
      value: obj.selected
    }
    propsobj.UpdateFormField(obj1)
    // dispatch({ type: 'UpdateFormField', name: obj['name'], value: obj.selected });
console.log(obj)

    let dataToR_Items = {
      id: obj.selected.studyId,
      studyNumber: obj.selected.studyNumber,
      ...obj1,
      // studyTitle: obj.selected.studyTitle,
    }
    let response = await R_Items.updateRecentItems(dataToR_Items, R_Items.rI_sitestudies);
    if (response) {
      let actItems = await R_Items.getRecentSearchOrItems(R_Items.rI_sitestudies);
      if (actItems) {
        let obj = {
          obj: R_Items.rI_sitestudies,
          value: actItems
        }
        propsobj.updateRecentItems(obj);
      }
    }

    reduxdispatch({ type: 'updateStudy', value: obj.selected || '' })
  }

  const renderOption = option => {
    return (
      <>
        {option['studyNumber'] + ' - ' + option['studyTitle']}
      </>
    )
  }

  const getOptionLabel = option => {
    // e.g value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    if (option && option.inputValue) {
      return option.inputValue;
    }

    if (option && option['studyNumber']) {
      return option && (option['studyNumber'] + '  -  ' + option['studyTitle'])
    } else {
      return '';
    }

  }

  return (
    // <div className="card" style={{ borderRadius: "25px" }}>
    //   <div className="card-body">
    //     <div className="row">
    //       <div className="col-md-6">
    <>
    <Loader progress={progress}/>
      {
        propsobj.formValues.hidestudytab &&

        <AutoOptionsNew
          {...autoPropstoStudies}
          autoValue={showStudy}
          name="autostudy"
          label="Select Study"
          keyName='studyTitle'
          renderOption={renderOption}
          getOptionLabel={getOptionLabel}
          must={'true'}
          {...propsobj.state}
          // dispatch={dispatch}
          callback={handleStudies} />

      }
    </>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateRecentItems: (obj) => {
      return dispatch(
        {
          type: 'updateRecentItems',
          value: obj,
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeStudy));

// export default React.memo();


