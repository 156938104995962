import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import ProfileData from '../../../../globalcomponents/patient360/profileData';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
function DashboardHome(props) {
  console.log(props,"props")
  let [progress, setProgress] = useState(false);

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div id="back-to-top-anchor" /> 
      <ProfileData data={props.formValues.patientdata} app={localStorage.getItem('whichApp')}/>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}

export default withRouter(connect(mapStateToProps, null)(DashboardHome));