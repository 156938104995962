import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavMenu } from '../../../siteSrc/components/navMenu';
import Studies from '../../../siteSrc/components/site/accounts/studies'
import RelatedAccounts from '../../../siteSrc/components/site/accounts/relatedAccounts'
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
// import ViewContact from '../viewContactDetail';
import Contacts from '../../../siteSrc/components/site/accounts/contacts';
import Details from '../accountsHome/details';



const menus = [
  {
    to: "/app/accountDetails",
    text: "Account Details"
  },
  {
    to: "/app/accountDetails/studies",
    text: "Studies"
  },
  {
    to: "/app/accountDetails/relatedAccounts",
    text: "Related Accounts"
  },
  {
    to: "/app/accountDetails/contacts",
    text: "Contacts"
  }
]

const ViewAccounttDetail = (props) => {

  const { sponsorAccountId = '', accountName = '' } = props.formValues;
  return (
    <>
      <div className="d-flex justify-content-end">
        <h1 className="mr-auto primary-header">{accountName}:&nbsp;{sponsorAccountId}</h1>
      </div>

      <NavMenu menus={menus} />

      <div className="">
        <Route exact path="/app/accountDetails" ><Details/></Route>
        {/* <Route path="/app/accountDetails/contacts/view" component={ViewContact} /> */}
        <Route exact path='/app/accountDetails/studies' component={Studies} />
        <Route exact path='/app/accountDetails/relatedAccounts' component={RelatedAccounts} />
        {/* <Route exact path='/app/accountDetails/selectContacts' component={AllContactsGrid} /> */}
        <Route exact path="/app/accountDetails/contacts" component={Contacts} />
        {/* <Route exact path="/site/accountDetails/auditTrail" component={AuditTrail} /> */}
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj.value,
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAccounttDetail));




