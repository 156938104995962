import React from 'react'
import { FileUpload } from '../siteSrc/components/site/fileupload/fileupload'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';

function TabContainer(props) {
  return (
    <Typography component="div" >
      {props.children}
    </Typography>
  );
}
const AllFileAttachments = (propsobj) => {

  return (
    <>
      <div className="col-md-12">
        <div className="card-page-header">{propsobj.type} FILES</div>
        <div className="inputBox">
          <Tabs
            value={propsobj.value}
            onChange={propsobj.handleTabsChange}
            indicatorColor="primary"
            textColor="primary"

          >
            <Tab label="Links" />
            <Tab label="Documents" />
            <Tab label="Videos" />
            <div style={{ display: "inline-flex" }}>
              <div className="errMsg">{propsobj.urlErr}</div>
              <div className="errMsg">{propsobj.docErr}</div>
              <div className="errMsg">{propsobj.videoErr}</div>
            </div>
          </Tabs>
          {propsobj.value === 0 && <TabContainer>
            <>
              <div className="row">
                {
                  propsobj.links.length > 0 &&
                  <div className="col-md-10">

                    <div className="table-responsive-md">
                      <table className="table table_courses">
                        <thead className="thead_color">
                          <tr>
                            <td>
                              S.NO
                            </td>
                            <td>Link/URL</td>
                            <td>Description</td>
                            <td>Actions</td>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            propsobj.links.map((item, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>
                                    <TextField
                                      value={propsobj.links[i].url}
                                      // label="Add URL"
                                      type="text"
                                      name="url"
                                      onChange={(e) => propsobj.handleLinksChange(e, i)}
                                      style={{ width: "100%" }}
                                    />
                                  </td>
                                  <td>
                                    <TextField
                                      multiline={true}
                                      rows={1}
                                      rowsMax={3}
                                      value={propsobj.links[i].description}
                                      // label="Description"
                                      type="text"
                                      name="description"
                                      onChange={(e) => propsobj.handleLinksChange(e, i)}
                                      style={{ width: "100%" }}
                                    />

                                  </td>
                                  <td className="td_action">
                                    {/* <div className="action_div"  >
                              <Tooltip title="Submit"><Icon color="primary" onSubmit={(e) => onSubmit(e, i)}
                              >check_circle</Icon></Tooltip>
                            </div> */}
                                    {/* {i >= 0 && */}
                                    <div className="action_div"  >
                                      <Tooltip title="Remove"><Icon color="error" onClick={(e) => propsobj.removeLink(e, i)} > remove_circle</Icon></Tooltip>
                                    </div>
                                    {/* } */}
                                    {/* {links.length == i + 1 && ( */}

                                    {/* )} */}

                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>

                    </div>
                  </div>
                }

                <div className="col-md-2">
                  <div className="" >
                    <button onClick={propsobj.addNew} type="button" className="btn btn-primary-blue btn-rounded" style={{ width: "100%" }} disabled={propsobj.disabled}>
                      <i className="fa fa-plus-circle mr-1"></i> Add New Url</button>
                  </div>
                </div>
              </div>
              {/* <div className="errMsg">{urlErr}</div> */}


            </>
          </TabContainer>}
          {propsobj.value === 1 && <TabContainer><FileUpload dataToAccept='image/*,.pdf,.xlsx,.txt,.xls,.ppt,.pptx,.doc,.docx' fileSave={propsobj.fileSave} onChangeFile={propsobj.onChangeFile} allFiles={propsobj.docFiles} removeFile={propsobj.removeFile} disabled={propsobj.docFiles.length == propsobj.docsData.length} disabledUpload={propsobj.disabled}/> <br /> </TabContainer>}
          {propsobj.value === 2 && <TabContainer>
            <FileUpload dataToAccept='video/*' fileSave={propsobj.fileSave} onChangeFile={propsobj.onChangeFile} allFiles={propsobj.videoFiles} removeFile={propsobj.removeFile} disabled={propsobj.videoFiles.length == propsobj.videosData.length} disabledUpload={propsobj.disabled}/><br />
          </TabContainer>}

        </div>
      </div>
    </>

  )
}

export default AllFileAttachments