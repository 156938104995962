import React, { useEffect, useState } from "react";
import { apiCall, genericApi, getFiles,fileupload } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField, ViewRow } from '../../../globalcomponents/formElements';
import { GenericTextField } from '../../../common/genericTextfield';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Materialtable from '../../../globalcomponents/materialtable'
import moment from 'moment'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ViewFileAttachments from '../../../common/viewAttachments'
import FileViewModal from './fileupload/fileviewmodal'

function Queries(propsobj) {
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');
  let [show, setShow] = useState(false);
  let [siteResponse, setSiteResponse] = useState(" ");
  let [rowData, setRowData] = useState("")
  let [siteStudyUsers, setSiteStudyUsers] = useState("")
  let [options, setOptions] = useState("")
  let [assignedName, setAssignedName] = useState("")
  let [links,setLinks] = useState("")
  let [documents,setDocuments] = useState("")
  let [videos,setVideos] = useState("")
  const [value, setValue] = React.useState(0);
  const [offset, setoffset] = useState(0)
  let [pdfUrl, setPdfUrl] = useState("")
  let [imgUrl, setImgUrl] = useState("")
  const [open, setOpen] = useState(false)

  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    getQueriesData("*")
    setSiteResponse("")
    setShow(false)
    setRowData("")
    getUsersData()
  }, [propsobj.formValues.autostudy.studyId]);
  async function getQueriesData(searchstr) {
    setProgress(true)
    let requestBody = {
      query: `query {
                  searchStudySiteQuery(queryId:"*",queryNum:"${searchstr}",studyId:"${propsobj.formValues.autostudy.studyId}",siteId:"${propsobj.formValues.autosite.sponsorAccountId}",statuses:"*",assignedTo:"${localStorage.getItem('loginUserId')}") {
                    queryId
                    queryNum
                studyId
                studyName
                siteId
                status
                assignedTo
                assignedToName
                queryTitle
                queryDescription
                resolvedDate
                category
                severity
                queryResponse
                sponsorReference1
                sponsorReference2
                siteReference1
                siteReference2
                createdBy
                createdByName
                createdTs
          }
      }`
    };
    requestBody = JSON.stringify(requestBody);
    let res = await apiCall('searchStudySiteQuery', requestBody);
    setProgress(false)
    if (!res) {


    } else {
      if (res.data != null && res.data.searchStudySiteQuery.length > 0) {
        setData(res.data.searchStudySiteQuery)
      } else {
        setData([])
      }
    }

  }
  async function getUsersData() {
    let requestBody = {
      query: `query {
        getStudySiteContact(studyId: "${propsobj.formValues.autostudy.studyId}", siteId: "${propsobj.formValues.autosite.sponsorAccountId}") {
          studyId
          siteId
          contactId
          contactName
          role
          primaryContactInd
          email
          phone
          status
          createdBy
          createdTs
          modifiedBy
          modifiedTs
                }
              }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getStudySiteContact', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error site users fetch!')

    } else {
      if (res.data != null && res.data.getStudySiteContact.length) {
        setSiteStudyUsers(res.data.getStudySiteContact)
        var optionsUser = res.data.getStudySiteContact.map((item, i) => { return { name: item.contactId, value: item.contactName } })
        setOptions(optionsUser)

      } else {
        setData([])
      }
    }
  }

  const handleRowClick = async (event, rowData) => {
    if (rowData.status === "Resolved") {
      event.preventDefault()
    } else {

      callGetQueryApi(rowData.queryId)
    }
  }

  const callGetQueryApi = async (id) => {
    let requestBody = {
      query: `query {
      getStudySiteQuery(queryId:"${id}") {
          queryDetails {
    queryId
         queryNum
     studyId
     studyName
     siteId
     status
     assignedTo
     assignedToName
     queryTitle
     queryDescription
     resolvedDate
     category
     severity
     queryResponse
     sponsorReference1
     sponsorReference2
     siteReference1
     siteReference2
     createdBy
     createdByName
     createdTs
     modifiedBy
     modifiedTs
    
     
     }
     supportDetails
     {
         ID
          buArea
          buid
          type
          status
          detail1
          detail2
          detail3
          detail4
          addedById
          addedByName
          addedTs   
     }
      } 
  }`
    };
    requestBody = JSON.stringify(requestBody);
    let res = await apiCall('getStudySiteQuery', requestBody);
    setProgress(false)
    if (!res) {


    } else {
      if (res.data != null && res.data.getStudySiteQuery.queryDetails) {
        let data = res.data.getStudySiteQuery.queryDetails
        let files=res.data.getStudySiteQuery.supportDetails
        setShow(true)
        setRowData(data)
        setLinks(files && files.length > 0 ? files.filter(item => item.type === "Link") : [])

        if(files.length>0 && files.filter(item => item.type !== "Link")){
          var urldata = files.map(v => ({ ...v, url: "" }))
          for (var i = 0; i < files.length; i++) {
            let res = await getFiles('fileupload', files[i], "GET");
            if (res.code === 200) {
              let data = JSON.parse(res.attributes)
              urldata[i]["url"] = data.URL
            }
      
          }
          setDocuments(urldata.length>0 ? urldata.filter(item => item.type === "Document") : [])
          setVideos(urldata.length>0 ? urldata.filter(item => item.type === "Video") : [])
        }
  
        if (data.assignedToName) {
          setAssignedName({ name: data.assignedToName, value: data.assignedToName })
        } else {
          setAssignedName("")
        }
        if (data.queryResponse) {
          setSiteResponse(data.queryResponse)
        } else {
          setSiteResponse("")
        }

      }
    }
  }

  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = async (event) => {
    event.preventDefault();
    if (valSearch.length) {
      getQueriesData(valSearch)
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    getQueriesData("*")
    setValSearch("")
  }

  String.prototype.limit = function (length) {
    return this.length > length ? (this.substring(0, length) + '...') : this;
  }
  
  const Columns = [
    {
      title: 'Query ID', field: 'queryNum',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: '20%'
      },
    },
    {
      title: 'Query Title', field: 'queryTitle',
      // render: data => data.firstName + ' ' + data.lastName
    },
    {
      title: 'Query Decription', field: 'queryDescription',
      render: data => data.queryDescription.limit(30)
    },
    { title: "Created Date", field: 'createdTs', render: data => moment.utc(data.createdTs + ' UTC', 'YYYY-MM-DD HH:mm:ss [UTC]').local().format('LL') },
    { title: 'Query Status', field: 'status' },
    { title: 'Resolved Date', field: 'resolvedDate', render: data => data.status === "Resolved" ? data.resolvedDate != null ? moment.utc(data.resolvedDate + ' UTC', 'YYYY-MM-DD HH:mm:ss [UTC]').local().format('LL') : "" : "" }
  ]

  const handleCancel = () => {
    setShow(false)
  }

  const formSubmit = async (event) => {
    event.preventDefault()
    if (siteResponse.length > 0) {
      let requestBody = `
      mutation 
      {
        responseStudySiteQuery(queryId: "${rowData.queryId}",queryResponse:${JSON.stringify(siteResponse)}) {
          code
          type
          message
          attributes
        }  
      }
          `;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('responseStudySiteQuery', requestBody);
      setProgress(false);

      if (res && res.data.responseStudySiteQuery.code === '200') {
        setSiteResponse("")
        setShow(false)
        getQueriesData("*")
      }

    }

    if (rowData.assignedToName != assignedName.value) {
      let requestBody = `
      mutation 
      {
        reassignStudySiteQuery(queryId: "${rowData.queryId}", assignedTo:"${assignedName.name}",assignedToName:"${assignedName.value}") {
          code
          type
          message
          attributes
        }  
      }
          `;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('reassignStudySiteQuery', requestBody);
      setProgress(false);

      if (res && res.data.reassignStudySiteQuery.code === '200') {
        setSiteResponse("")
        setShow(false)
        getQueriesData("*")
      }
    }
    if (siteResponse.length == 0 && rowData.assignedToName === assignedName.value) {
      alert('Enter response to submit')
    }
  }

  const getUser = (e, value) => {
    console.log(value, "Value")
    setAssignedName(value)

  }

  const handlePageClick = (e, offset) => {
    setoffset(offset)
  }

  const download = async (e, file) => {
    e.preventDefault()
    let res = await getFiles('fileupload', file, "GET");
    if (res.code == 200) {
      var data = JSON.parse(res.attributes)
      window.open(data.URL)
    }
  }

  const imgClick = (e, url) => {
    e.preventDefault()
    setOpen(true)
    setImgUrl(url)
    setPdfUrl("")
  }

  const pdfClick = (e, url) => {
    e.preventDefault()
    setOpen(true)
    setPdfUrl(url)
    setImgUrl("")
  }

  const image = (url) => {
    return <img src={url} alt="image" height="75" width="75" />
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
        {/* <Loader/> */}
      </div>}
      {!show &&
        <div className="listing-wrap hekmaGrid mt2rem">

          <div className="card">
            <div className="hekmaGridBox">
              <div className="col-xs-12 col-sm-12 col-md-5">
                <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                  <HekmaTextSearchField
                    name="accountgridsearch"// this name you need not to add in constant file unless if we use redux
                    // label="Search Account"
                    placeholder="Search Query"
                    value={valSearch}
                    handleChange={searchHandle}
                    className="gridSearchField"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                          {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                          <SearchIcon />
                        </InputAdornment>
                      ),

                      endAdornment: (
                        <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                          X
                          {/* <i className="fa fa-times" aria-hidden="true"></i> */}
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </div>
              <Materialtable
                columns={Columns}
                data={data.length > 0 ? data : []}
                handleRowClick={handleRowClick}
              />
            </div>
          </div>
        </div>
      }
      {show &&
        <>
          <FileViewModal open={open} pdfUrl={pdfUrl} imgUrl={imgUrl} onClose={onClose} />
          <div className="d-flex mt-4 justify-content-end mt2rem">
            <div className="mr-auto secondary-header">Update Query</div>
          </div>
          {/* <form noValidate autoComplete="off" id="addQuery" className="row" onSubmit={formSubmit}> */}
          <form noValidate autoComplete="off" id="addQuery" onSubmit={formSubmit} className="row h-form-1">
            <div className="col-md-6">

              <div className="panel-primary-wrap">
                <div className="card">
                  <div className="card-body">
                    <div className="inputBox">

                      {/* <GenericTextField name="Q_title" label="Query Title" type="text" disabled defaultValue={rowData.queryTitle} /> */}
                      <ViewRow textvalue={rowData.queryTitle} text="Query Title" />
                      <ViewRow textvalue={rowData.queryDescription} text="Description" />
                      {/* <GenericTextField multiline={true} rows={3} rowsMax={2} name="Q_Description" label="Description" type="text"  disabled defaultValue={rowData.queryDescription} /> */}
                      <div className="row">
                        <div className="col-md-12">
                          <ViewRow textvalue={moment.utc(rowData.createdTs + ' UTC', 'YYYY-MM-DD HH:mm:ss [UTC]').local().format('LLL')} text="Created Date" />

                          {/* <GenericTextField name="Q_createddate" label="Created Date" defaultValue={moment.utc(rowData.createdTs + ' UTC', 'YYYY-MM-DD HH:mm:ss [UTC]').local().format('LLL')} disabled /> */}
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <ViewRow textvalue={rowData.status} text="Status" />
                          {/* <GenericTextField name="Q_status" label="Status" defaultValue={rowData.status} disabled /> */}
                        </div>
                        <div className="col-md-6">
                          <ViewRow textvalue={rowData.severity} text="Severity" />
                          {/* <GenericTextField name="Q_severity" label="Severity" defaultValue={rowData.severity} disabled /> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <ViewRow textvalue={rowData.category} text="Category" />
                          {/* <GenericTextField name="Q_category" label="Category" defaultValue={rowData.category} disabled /> */}
                        </div>
                        <div className="col-md-6">
                          <ViewRow textvalue={rowData.createdByName} text="Created By" />
                          {/* <GenericTextField name="Q_createdby" label="Created By" type="text" defaultValue={rowData.createdby} disabled /> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <ViewRow textvalue={rowData.resolvedDate} text="Resolved Date" />
                          {/* <GenericTextField name="Q_resolvedDate" label="Resolved Date" type="text" disabled defaultValue={rowData.resolvedDate} /> */}
                        </div>
                        <div className="col-md-6">
                          {/* <ViewRow textvalue={rowData.assignedToName} text="Assigned To" /> */}
                          <Autocomplete
                            id="controlled-demo"
                            options={options}
                            getOptionLabel={option => option.name ? option.value : ""}
                            value={assignedName}
                            disableClearable
                            onChange={(e, value) => getUser(e, value, rowData, data)}
                            renderInput={(params) => <TextField {...params} margin="none" fullWidth />}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <GenericTextField multiline={true} rows={3} rowsMax={2} label="Response from Site" type="text" value={siteResponse} onChange={e => setSiteResponse(e.target.value)} />
                        </div>
                        <div className="col-md-6">

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-12">
              {console.log(rowData,"abc")}
              <ViewFileAttachments value={value} header="Query" handleTabsChange={handleTabsChange} offset={offset} handlePageClick={handlePageClick} download={download} imgClick={imgClick} pdfClick={pdfClick} image={image} links={links} documents={documents} videos={videos}/>
              <div className="buttonContainer fr">
                <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
                <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
              </div>
            </div>
          </form>


        </>
      }
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Queries));
