import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import SmallLogo from '../../../img/hekma-logo.svg';
import UserProfile from '../../../img/user-profile.png';
import { withRouter } from 'react-router-dom';
import UserMenu from './userMenu';
import { connect, useSelector, useDispatch } from 'react-redux';
import AddCartIcon from '../../../img/opensquare/Add-to-Cart.svg'
import $ from 'jquery'
import Tooltip from '@material-ui/core/Tooltip';
import { loginApi, apiCall, genericApi, securityApi } from '../../common/apiconfig';
import Alerts from './alerts'
const Header = (props) => {
    let reduxstateobj = useSelector(state => state);
    const [cart, setCart] = useState(0)
    const [queries, setQueries] = useState('')
    const [offset, setoffset] = useState(0)
    const reduxdispatch = useDispatch()
    const handlePageClick = (e, offset) => {
        e.stopPropagation()
        setoffset(offset)
    }

    useEffect(async () => {
        if (!props.noapicalls) {
            // if (props.formValues?.cart && props.formValues.cart.length > 0) {
            //     setCart(props.formValues.cart.length)
            // } else {
            //     setCart(0)
            // }
            callCartApi()
            fetchQueries()
            const interval = setInterval(() => {
                fetchQueries()
            }, 300000);
            return () => clearInterval(interval);
        }
    }, [])

    useEffect(() => {
        setCart(reduxstateobj.cartcount)
        callCartApi()
    }, [reduxstateobj.cartcount])

    const callCartApi = async () => {
        let requestBody = {
            query: `query {
              getCartDetails(cartId:"*",accountId:"${localStorage.getItem('loginUserId')}",nctId:"*",siteId:"*")
            {
               cartId
            accountId
            nctId
            siteId
            siteName
            city
            country
            patientCount
            totalPrice
            metrics
            insights
            patientGender
            patientAgeMin
            patientAgeMax
            patientRace
            patientCity
            patientState
            patientZipCode
            patientCountry
            patientLanguage
            patientEducation
            siteNameFilter
            siteCity
            siteState
            siteZipcode
            siteCountry
            leadStatus
           
            }
            }`
        }
        requestBody = JSON.stringify(requestBody);
        let res = await genericApi('getCartDetails', requestBody);
        if (res) {
            if (res.data.getCartDetails.length > 0) {
                var data = res.data.getCartDetails
                setCart(data.length)
                var totalprice = data.reduce(function (total, currentValue) {
                    return total + parseInt(currentValue.totalPrice);
                }, 0)
                reduxdispatch({ type: 'Totalprice', value: totalprice * 100 })
                reduxdispatch({ type: 'Cartdata', value: data })
                reduxdispatch({ type: 'cartcount', value: data.length })
            } else {
                setCart(0)
                reduxdispatch({ type: 'Cartdata', value: [] })
                reduxdispatch({ type: 'cartcount', value: 0 })
            }
        }
    }

    async function fetchQueries(searchstr) {
        // setProgress(true)
        let requestBody = {
            query: `query {
            searchStudySiteQueryResponse {
               queryId
               queryNum
           studyId
           studyName
           siteId
           status
           assignedTo
           assignedToName
           queryTitle
           queryDescription
           resolvedDate
           category
           severity
           queryResponse
           sponsorReference1
           sponsorReference2
           siteReference1
           siteReference2
           createdBy
           createdByName
           createdTs
           modifiedBy
           modifiedTs
            } 
          }`
        };
        requestBody = JSON.stringify(requestBody);
        let res = await apiCall('searchStudySiteQueryResponse', requestBody);
        // setProgress(false)
        if (!res) {


        } else {
            if (res.data != null && res.data.searchStudySiteQueryResponse.length > 0) {
                setQueries(res.data.searchStudySiteQueryResponse)
            } else {
                setQueries([])
            }
        }

    }

    const searchSubmit = (event) => {
        event.preventDefault();
        let value = document.querySelector("input[name='headerSearch']").value;
        if (value.length) {
            props.updateHeaderSearchValue({ value });
            props.history.push('/searchresults');
        }
    }
    const handleClick = (e) => {
        e.preventDefault()
        props.setSidebarActiveEl('accounts');
        props.history.push('/account')
    }
    const goToCart = (e) => {
        e.preventDefault()
        reduxdispatch({ type: 'UpdateFormField', name: "cartHeader", value: true })
        props.history.push('/account/shoppingcart')
        $("#cartclick").on('click', function () {
            reduxdispatch({ type: 'UpdateFormField', name: "cartHeader", value: true })
            props.history.push('/account/shoppingcart')
        })

    }

    return (
        <>

            {/* <!-- Content Wrapper --> */}
            <div id="content-wrapper" className="d-flex flex-column">
                {/* <!-- Main Content --> */}
                <div id="content">
                    {/* <!-- Topbar --> */}
                    <nav className="navbar navbar-expand navbar-light bg-header-shadow topbar mb-4 static-top">
                        {/* <!-- Sidebar Toggle (Topbar) --> */}
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-2">
                            <i className="fa fa-bars"></i>
                        </button>
                        <a href="#" className="ml-xs-1 ml-md-3 mr-xs-2 mr-md-5" onClick={handleClick}><img src={SmallLogo} alt="logo-small" /></a>
                        {/* <!-- Topbar Search --> */}
                        {localStorage.getItem("hideheaderitem") ?
                            <form noValidate autoComplete="off" onSubmit={searchSubmit} className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">

                                {reduxstateobj.showHeaderSearch && <div className="input-group globalsearch">
                                    <input type="text" className="form-control" placeholder="Search Accounts, Contacts & Studies" aria-label="Search Accounts, Contacts & Studies" aria-describedby="button-addon2" name="headerSearch" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary-blue inputSearchBtn" type="submit" id="button-addon2"><i className="fa fa-search fa-sm"></i> Search</button>
                                    </div>
                                </div>}

                            </form> : ""}
                        {/* <!-- Topbar Navbar --> */}
                        {localStorage.getItem("hideheaderitem") ?
                            <ul className="navbar-nav ml-auto">
                                {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
                                <li className="nav-item dropdown no-arrow d-sm-none">
                                    <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fa fa-search fa-fw"></i>
                                    </a>
                                    {/* <!-- Dropdown - Messages --> */}
                                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                                        <form className="form-inline mr-auto w-100 navbar-search">
                                            <div className="input-group">
                                                <input type="text" className="form-control bg-light border-0 small" placeholder="Search Accounts, Contacts & Studies" aria-label="Search" aria-describedby="basic-addon2" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary-blue" type="button">
                                                        <i className="fa fa-search fa-sm"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </li>

                                <li className="nav-item dropdown no-arrow">
                                    <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                        <Tooltip title="Alerts" arrow><i className="far fa-bell fa-fw fa-lg"></i></Tooltip>
                                        <span className="badge badge-danger badge-counter"> {queries.length}</span>
                                    </a>

                                    <form id="form" className="dropdown-menu dropdown-menu-right shadow animated--grow-in alertCss cr" aria-labelledby="alertsDropdown ">

                                        <Alerts queries={queries} handlePageClick={handlePageClick} offset={offset}
                                        />
                                    </form >
                                </li>
                                {/* <!-- Nav Item - Alerts --> */}
                                <li className="nav-item dropdown no-arrow mx-1">
                                    {cart != 0 && <a className="nav-link dropdown-toggle" href="#" id="cartclick" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={(e) => goToCart(e)}>
                                        {/* <i className="far fa-bell fa-fw fa-lg"></i>  */}
                                        <img src={AddCartIcon} alt="cart" />
                                        {/* <!-- Counter - Alerts --> */}
                                        <span className="badge badge-danger badge-counter">{cart}</span>
                                    </a>}
                                </li>

                                {/* <!-- Nav Item - User Information --> */}
                                <li className="nav-item dropdown">
                                    {/* <a className="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img className="img-profile rounded-circle mr-md-2 mr-xs-0" src={UserProfile} alt="user-profile" />
                                <span className=" d-none d-lg-inline user-text">Admin</span>
                            </a>
                            
                            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                                <a className="dropdown-item">
                                <i className="fa fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Logout
                                </a>
                            </div> */}

                                    <UserMenu />

                                </li>
                            </ul> : ""}
                    </nav>
                    {/* <!-- End of Topbar --> */}
                </div>
                {/* <!-- End of Main Content --> */}

            </div>
            {/* <!-- End of Content Wrapper --> */}

        </>

    )
}

const mapStateToProps = (state) => {
    return {
        formValues: state.form
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateHeaderSearchValue: (obj) => {
            return dispatch(
                {
                    type: 'headerSearch',
                    value: obj.value,
                }
            )
        },
        setSidebarActiveEl: (name) => {
            return dispatch(
                {
                    type: 'sidebarActiveEl',
                    value: name,
                }
            )
        },
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

