import React, { useEffect, useState } from "react";
import { apiCall } from '../../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Materialtable from '../../../../../globalcomponents/materialtable'
function OrderHistory(props) {
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');

  async function getSubjectsData() {
    setProgress(true)
    let requestBody = {
      query: `query {
              getStudySitePatientFetch(studyId: "${props.formValues.studyId}", siteId: "${props.formValues.siteId}") {
                  studyId
                  siteId
                  patientId
                  subjectStatus
                  onboardStatus
                  subjectId
                  alternateId
                  firstName
                  middleName
                  lastName
                  email
                  phone
                  address1
                  address2
                  city
                  state
                  zip
                  country
                  language
                  createdBy
                  createdTs
                  modifiedBy
                  modifiedTs
  
          }
      }`
    };
    requestBody = JSON.stringify(requestBody);
    let res = await apiCall('getStudySitePatientFetch', requestBody);
    setProgress(false)
    if (!res) {
      // Error handling
      //console.log('Error!')

    } else {
      if (res.data.getStudySitePatientFetch.length) {
        // props.UpdateFormField({ name: "studypatients", value: res.data.getStudySitePatientFetch })
        setData(res.data.getStudySitePatientFetch)
      } else {
        setData([])
        // props.UpdateFormField({ name: "studypatients", value: [] })
      }
    }

  }


  useEffect(() => {
    getSubjectsData()
  }, []);


  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
  }

  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = async (event) => {
    event.preventDefault();
    if (valSearch.length) {
      searchfunc(valSearch)
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    searchfunc("*")
    setValSearch("")
  }


  const Columns = [
    {
      title: 'Subject ID', field: 'patientId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: '20%'
      },
      render: data => data.patientId == "" || data.patientId == null ? '---' : data.patientId
    },
    // {
    //   title: 'Name', field: 'firstName',
    //   render: data => data.firstName + ' ' + data.lastName
    // },
    { title: 'Onboarding Status', field: 'onboardStatus' },
    // { title: "Email", field: 'email' },
    // { title: 'Phone', field: 'phone' },
    { title: 'City', field: 'city' },
    { title: 'Country', field: 'country' },
  ]

  async function searchfunc(searchstr) {
    let requestBody = {
      query: `query {
      getStudySitePatientStatusSearch(studyId: "${props.formValues.studyId}", siteId: "${props.formValues.siteId}",onboardStatus:"*",subjectStatus:"*",searchStr:"${searchstr}") {
                studyId
                siteId
                patientId
                subjectStatus
                onboardStatus
                subjectId
                alternateId
                firstName
                middleName
                lastName
                email
                phone
                address1
                address2
                city
                state
                zip
                country
                language
        }
    }`
    };
    requestBody = JSON.stringify(requestBody);
    let res = await apiCall('getStudySitePatientStatusSearch', requestBody);
    if (!res) {
      // Error handling
      //console.log('Error on getting patient data!')

    } else {
      if (res.data.getStudySitePatientStatusSearch.length) {
        setData(res.data.getStudySitePatientStatusSearch)
      } else {
        setData([])
      }
    }
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
        {/* <Loader/> */}
      </div>}
      <div className="secondary-header mr-auto">Subjects</div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
            <div className="col-xs-12 col-sm-12 col-md-5">
              <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                <HekmaTextSearchField
                  name="accountgridsearch"// this name you need not to add in constant file unless if we use redux
                  // label="Search Account"
                  placeholder="Search Subject"
                  value={valSearch}
                  handleChange={searchHandle}
                  className="gridSearchField"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                        {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                        X
                        {/* <i className="fa fa-times" aria-hidden="true"></i> */}
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div>
            <Materialtable
              columns={Columns}
              data={data.length > 0 ? data : []}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateHeaderSearchValue: (obj) => {
      return dispatch(
        {
          type: 'headerSearch',
          value: obj.value,
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateRecentItems: (obj) => {
      return dispatch(
        {
          type: 'updateRecentItems',
          value: obj,
        }
      )
    },
    setSidebarActiveEl: (name) => {
      return dispatch(
        {
          type: 'sidebarActiveEl',
          value: name,
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderHistory));
