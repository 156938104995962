import React, { useEffect, useState } from "react";
import Header from './header/header';
import Sidebar from './sidebar/sidebar';
import AdminHome from './AdminHome';

const MainPage = () => {
    return ( 
        <>
            <div id="wrapper">
                <Sidebar />
                <div className="contentBody">
                    <Header />
                    <AdminHome />
                </div>
            </div>
        </>
    )
}
export default MainPage;


