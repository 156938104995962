import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction' // needed for dayClick
import BackIcon from '../../../img/back1.svg'
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { genericApi } from '../../../common/apiconfig';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Calendar from '../../../img/calendar.svg'
import AddAppointmentForm from './addAppointment'

const CalendarApp = (props) => {
  //console.log(props, "Cale")
  let [progress, setProgress] = useState(false);
  let [events, setEvents] = useState([]);
  let [open, setOpen] = useState(false);
  let [scheduleId, setScheduleId] = useState("")
  useEffect(() => {
    callApi(props.formValues.patientdata)
    props.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, [props.formValues.patientdata])
  const callApi = async (data) => {
    setProgress(true)
    let requestBody = {
      query: `
          query {
            getScheduleDetailsPatient(studyId:"${data.studyId}",patientId:"${data.patientId}",siteId:"${data.siteId}") {
              schedule {
                scheduleID
                studyId
                scheduleDsc
                effectiveDate
                createdBy
                createdTs
                modifiedBy
                modifiedTs
              }
              scheduleEventList {
                scheduleID
                eventID
                eventType
                eventOrder
                eventName
                eventKey
              }
              scheduleSegmentList {
                scheduleID
                segmentOrder
                segmentName
                start
                end
                varience
              }
              linkRowColumnPatient {
                patSchedulePk
                patientID
                siteId
                scheduleID
                rowID
                columnID
                startDate
                endDate
                segmentVariance
                actualDate
                expectedDate
                statusInd
              }
          }
      }`
    };
    requestBody = JSON.stringify(requestBody);
    setProgress(true)
    let res = await genericApi('getScheduleDetailsPatient', requestBody);
    if (!res) {
      // Error handling
      //console.log('Error')

    } else {
      //console.log(res)
      setProgress(false)
      if (res.data.getScheduleDetailsPatient != null ) {
        var eventsName = res.data.getScheduleDetailsPatient.scheduleEventList
        var datesData = res.data.getScheduleDetailsPatient.linkRowColumnPatient
        var events = []
        for (var i = 0; i < eventsName.length; i++) {
          for (var j = 0; j < datesData.length; j++) {
            if (eventsName[i].eventOrder === datesData[j].rowID) {
              var obj = {
                id: i,
                title: eventsName[i].eventName,
                start: new Date(datesData[j].startDate),
                end: new Date(datesData[j].startDate),
                allDay: true
                // color: "violet"
              }
              events.push(obj)
            }
          }
        }
        setProgress(false)
        setEvents(events)
        setScheduleId(res.data.getScheduleDetailsPatient.schedule.scheduleID)
        // this.setState({ progress: false, events, scheduleId: res.data.getScheduleDetailsPatient.schedule.scheduleID, patientId, siteId, studyId })
      }
    }

  }
  const goBack = () => {
    props.history.goBack()
  }
  const handleClick = () => {
    setOpen(true)
  }
  const dialogclose = () => {
    setOpen(false)
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex mb-3 justify-content-end">
        <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
          <i className="fa fa-plus-circle mr-1"></i>ADD APPOINTMENT</button>
      </div>
      <div className="mb-3">
        <FullCalendar
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'prevYear,prev,next,nextYear today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
          }}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          // ref={calendarComponentRef}
          droppable={true}
          editable={true}
          weekends={true}
          events={events}
          contentHeight={"auto"}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
        // dateClick={this.handleDateClick}
        />
        {
          open && <AddAppointmentForm open={open} dialogclose={dialogclose} title="Add Appointment" scheduleId={scheduleId} callApi={callApi} />
        }
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CalendarApp));

// class CalendarApp extends Component {
//   constructor(props) {
//     const now = new Date();
//     this.state = {
//       name: 'React',
//       title: "",
//       startDate: "",
//       endDate: "",
//       show: false,
//       events: [],
//       progress: false,
//       scheduleId: "",
//       siteId: "",
//       patientId: "",
//       studyId: "",
//       id: "",
//       rowInfo: this.props.rowData
//     };
//     this.moveEvent = this.moveEvent.bind(this)
//     this.newEvent = this.newEvent.bind(this)
//     this.onChangeInput = this.onChangeInput.bind(this)
//     this.appointmentFormShow = this.appointmentFormShow.bind(this)
//   }
//   componentWillMount() {
//     this.setState({ progress: true });
//     //console.log()
//     // var data = this.props.rowData.original
//     // this.callGetApi(data.studyId, data.patientId, data.siteId);

//   }
//   callGetApi(studyId, patientId, siteId) {
//     const requestBody = {
//       query: `
//           query {
//             getScheduleDetailsPatient(studyId:"${studyId}",patientId:"${patientId}",siteId:"${siteId}") {
//               schedule {
//                 scheduleID
//                 studyId
//                 scheduleDsc
//                 effectiveDate
//                 createdBy
//                 createdTs
//                 modifiedBy
//                 modifiedTs
//               }
//               scheduleEventList {
//                 scheduleID
//                 eventID
//                 eventType
//                 eventOrder
//                 eventName
//                 eventKey
//               }
//               scheduleSegmentList {
//                 scheduleID
//                 segmentOrder
//                 segmentName
//                 start
//                 end
//                 varience
//               }
//               linkRowColumnPatient {
//                 patSchedulePk
//                 patientID
//                 siteId
//                 scheduleID
//                 rowID
//                 columnID
//                 startDate
//                 endDate
//                 segmentVariance
//                 actualDate
//                 expectedDate
//                 statusInd
//               }
//           }
//       }`
//     };
//     fetch(APPCONFIG.uri, {
//       method: 'POST',
//       body: JSON.stringify(requestBody),
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     })
//       .then(res => {
//         if (res.status !== 200 && res.status !== 201) {
//           throw new Error('Failed!');
//         }
//         return res.json();
//       })
//       .then(responseData => {
//         //console.log(responseData)
//         var eventsName = responseData.data.getScheduleDetailsPatient.scheduleEventList
//         var datesData = responseData.data.getScheduleDetailsPatient.linkRowColumnPatient
//         var events = []
//         for (var i = 0; i < eventsName.length; i++) {
//           for (var j = 0; j < datesData.length; j++) {
//             if (eventsName[i].eventOrder === datesData[j].rowID) {
//               var obj = {
//                 id: i,
//                 title: eventsName[i].eventName,
//                 start: new Date(datesData[j].startDate),
//                 end: new Date(datesData[j].endDate),
//                 // color: "violet"
//               }
//               events.push(obj)
//             }
//           }
//         }
//         this.setState({ progress: false, events, scheduleId: responseData.data.getScheduleDetailsPatient.schedule.scheduleID, patientId, siteId, studyId })
//       })
//   }

//   appointmentFormShow() {
//     this.setState({ show: !this.state.show })
//   }

//   // eventStyleGetter = (event, start, end, isSelected) => {
//   //   //console.log(event);
//   //   var backgroundColor = event.color;
//   //   // var style = {
//   //   //   backgroundColor: backgroundColor,
//   //   //   borderRadius: '0px',
//   //   //   opacity: 0.8,
//   //   // color: 'white',
//   //   //   border: '0px',
//   //   //   display: 'block'
//   //   // };
//   //   // return {
//   //   //   style: style
//   //   // };
//   // }

//   moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }) {
//     const { events } = this.state
//     const idx = events.indexOf(event)
//     let allDay = event.allDay
//     if (!event.allDay && droppedOnAllDaySlot) {
//       allDay = true
//     } else if (event.allDay && !droppedOnAllDaySlot) {
//       allDay = false
//     }
//     const updatedEvent = { ...event, start, end, allDay }
//     const nextEvents = [...events]
//     nextEvents.splice(idx, 1, updatedEvent)

//     this.setState({
//       events: nextEvents,
//     })

//     // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
//   }

//   resizeEvent = ({ event, start, end }) => {
//     const { events } = this.state

//     const nextEvents = events.map(existingEvent => {
//       return existingEvent.id == event.id
//         ? { ...existingEvent, start, end }
//         : existingEvent
//     })

//     this.setState({
//       events: nextEvents,
//     })
//   }
//   onChangeInput = (e) => {
//     this.setState({ [e.target.name]: e.target.value })

//   }
//   newEvent(event) {
//     // let idList = this.state.events.map(a => a.id)
//     // let newId = Math.max(...idList) + 1
//     // let hour = {
//     //   id: newId,
//     //   title: 'New Event',
//     //   allDay: event.slots.length == 1,
//     //   start: event.start,
//     //   end: event.end,
//     // }
//     // this.setState({
//     //   events: this.state.events.concat([hour]),
//     // })
//     const { scheduleId, siteId, patientId, title, startDate, endDate, variance } = this.state
//     this.props.Add_Appointment({
//       variables: {
//         scheduleID: scheduleId,
//         patientId: patientId,
//         siteId: siteId,
//         eventName: title,
//         startDate: startDate,
//         endDate: endDate,
//         segmentVariance: variance
//       }
//     })
//       .then(response => {
//         //console.log(response)
//         if (response.data.addPatientAdhocSchedule.code === "200") {
//           this.callGetApi(this.state.studyId, this.state.patientId, this.state.siteId)
//           this.setState({ title: "", startDate: "", endDate: "" })
//         }
//       });

//     // this.setState({
//     //   events: [
//     //     ...this.state.events,
//     //     {
//     //       start,
//     //       end,
//     //       title,
//     //     },
//     //   ], show: !this.state.show
//     // })
//   }
//   handleSelect = ({ start, end }) => {
//     const title = window.prompt('New Event name')
//     if (title)
//       this.setState({
//         events: [
//           ...this.state.events,
//           {
//             start,
//             end,
//             title,
//           },
//         ],
//       })
//   }
//   eventDetails = (e) => {
//     //console.log(e)
//     this.setState({ show: true, id: e.id, title: e.title, startDate: moment(e.start).format('YYYY-MM-DD'), endDate: moment(e.end).format('YYYY-MM-DD') })
//   }
//   render() {
//     let { rowInfo, events, progress } = this.state;
//     //console.log('rowInfo', rowInfo);
//     //console.log('events', events);

//     return (
//       <div className="row">
//         {progress && <div className="fullScreen">
//           <CircularProgress disableShrink className="progressCircle" />
//         </div>}

//         <div className="col-md-9">
//           <div style={{ height: '500pt' }}>
//             <FullCalendar
//               defaultView="dayGridMonth"
//               header={{
//                 left: 'prevYear,prev,next,nextYear today',
//                 center: 'title',
//                 right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
//               }}
//               // customButtons={{
//               //   myCustomButton: {
//               //     text: 'prevYear',
//               //     click: function () {

//               //     }
//               //   }
//               // }}
//               plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
//               ref={this.calendarComponentRef}
//               droppable={true}
//               editable={true}
//               weekends={true}
//               events={this.state.events}
//               dateClick={this.handleDateClick}
//             />

//             {/* <Calendar
//               selectable
//               localizer={localizer}
//               events={this.state.events}
//               startAccessor="start"
//               endAccessor="end"
//               defaultDate={moment().toDate()}
//               onEventDrop={this.moveEvent}
//               resizable
//               onEventResize={this.resizeEvent}
//               onSelectEvent={(event) => this.eventDetails(event)}
//               onSelectSlot={this.handleSelect}
//               onDragStart={//console.log}
//             // eventPropGetter={(this.eventStyleGetter)}
//             /> */}
//             {/* <Calendar
//             events={this.state.events}
//             startAccessor="start"
//             endAccessor="end"
//             defaultDate={moment().toDate()}
//             localizer={localizer}
//             onSelectSlot={(this.slotSelected)}
//             onSelectEvent={(this.eventSelected)}
//             eventPropGetter={(this.eventStyleGetter)}
//           /> */}
//           </div>
//         </div>
//         <div className="col-md-3">

//           <Button color="primary" variant="outlined" onClick={this.appointmentFormShow}>
//             Add Appointment
//       </Button><br />
//           {/* {this.state.show && <>
//             <div className="row">
//               <div className="col-md-12">
//                 <Inputfield
//                   field="title"
//                   onChange={(e) => { this.onChangeInput(e) }}
//                   label="Title"
//                   name="title"
//                   // asterik="*"
//                   type="text"
//                   // error={errors.title}
//                   value={this.state.title} />
//               </div>
//               <div className="col-md-12">
//                 <TextField
//                   field="startDate"
//                   onChange={(e) => { this.onChangeInput(e) }}
//                   label="Start Date"
//                   asterik="*"
//                   type="date"
//                   name="startDate"
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   // error={errors.startDate}
//                   value={this.state.startDate}
//                   margin="normal"
//                   style={{ width: "100%" }}
//                 />
//               </div>
//               <div className="col-md-12">
//                 <TextField
//                   field="endDate"
//                   onChange={(e) => { this.onChangeInput(e) }}
//                   label="End Date"
//                   asterik="*"
//                   type="date"
//                   name="endDate"
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   // error={errors.endDate}
//                   value={this.state.endDate}
//                   margin="normal"
//                   style={{ width: "100%" }}
//                 />
//               </div>
//             </div>
//             <Button variant="contained" color="danger" className="saveBtn pull-left" type="submit">
//               Close</Button>
//             <Button variant="contained" color="primary" className="saveBtn pull-right" type="submit" onClick={this.newEvent}>
//               SUBMIT</Button>
//           </>
//           } */}
//         </div>
//       </div>
//     );
//   }
// }

// export default withRouter(CalendarApp)
