import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../../common/apiconfig';
import FormHoc from '../../hoc/formHoc';
import { InputField, AutoOptions } from '../../formElements';
import { Dropdowns } from '../../../common/constants';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import BackIcon from '../../../../img/back1.svg'
import AuditTrailFunc from '../../../common/AuditTrailFunc'

const UpdateSite = (propsobj) => {
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [options, setOptions] = useState([])
  let [fullSite, setfullSite] = useState("")
  let [imp, setimp] = useState(false)
  let [errMsg, seterrMsg] = useState(false)
  let [errMsgText, seterrMsgText] = useState("")
  let [siteId, setsiteId] = useState("")
  let [siteName, setsiteName] = useState("")
  let [oldData, setOldData] = useState({});
  useEffect(() => {
    setOldData(propsobj.formValues)
  }, [oldData])
  useEffect(() => {
    if (propsobj.formValues.impReceived === 'true') {
      setimp(true)
    } else {
      setimp(false)
    }
  }, []);

  const hocObj = FormHoc(propsobj);


  const autoPropstostatus = {
    'options': Dropdowns.Study_Site_Onboard_Status,
    default: null,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const handleImpChange = () => {
    setimp(!imp)
  }
  const getSite = (event, value) => {
    console.log(event, value)
    setsiteId(value.sponsorAccountId)
    setfullSite(value)
    setsiteName(value.accountName)
    seterrMsg(false)
    seterrMsgText("")
  }
  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('updateSite', event);

    if (siteId == "") {
      seterrMsg(true)
      seterrMsgText("This field is required")
    }
    if (setGo) {
      setProgress(true);
      let form = propsobj.formValues;
      console.log(form)
      let requestBody = `
        mutation 
        {
          updateStudyPag(studyId: "${form.studyId}", pagId: "${form.pagId}" ,status:"${form.status}") {
            code
            type
            message
            attributes
          }  
        }
            `;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('updateStudyPag', requestBody, form);
      setProgress(false);

      if (res && res.data.updateStudyPag.code === '200') {
        AuditTrailFunc("Study", propsobj.formValues.studyId, "PAG Update", "PAG ID: "+propsobj.formValues.pagId, oldData, propsobj.formValues)
        propsobj.history.push('/account/studyDetails/pag');
      } else {
        console.log('Error on response add account file!')
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
    propsobj.history.goBack();
  }

  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.goBack()
  }
  const backtolist=(event)=>{
    event.preventDefault();
    propsobj.history.push('/account/studyDetails/pag')
  }
  console.log(fullSite, "fsite")
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* <div onClick={backtolist} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO PAG LIST</div><br /> */}

      <div className="d-flex mt-4 justify-content-end">
        <div className="mr-auto secondary-header">Edit PAG Information</div>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="updateSite" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  {/* <div className="card-page-header">NAME INFORMATION</div> */}
                  <div className="inputBox">

                    <div>PAG Name: <span className="sitenamecss" onClick={handleCancel}>{propsobj.formValues.pagAccountName}</span></div>
                    <AutoOptions {...autoPropstostatus} name="status" label="Status" keyName='value' must={'true'} />
                  </div>
                </div>
              </div>
            </div>
            <div className="buttonContainer">
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={backtolist}>CANCEL</button>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateSite));