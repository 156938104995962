
import React, { useState, useEffect } from 'react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { oldgenericApi, logOut } from '../../../../common/apiconfig';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import '../../../siteSrc/components/site/accounts/billing.scss'
import { withRouter } from 'react-router-dom';
import { appConfig } from '../../../../common/constants';
import { useDispatch, useSelector } from 'react-redux'
import { connect } from 'react-redux';
import ImgEHR from '../../../../img/EHR.jpg'
const InitialPage = (props) => {
  const [accessToken, setAccessToken] = useState('')
  const [selectedValue, setSelectedValue] = React.useState('a')
  useEffect(() => {
    if (props?.formValues?.patientdata) { callApi() }
  }, [selectedValue])
  const radioHandle = (e) => {
    e.preventDefault();
    setSelectedValue(e.target.value)
    if (e.target.value === "cms") {
      // window.open(`https://auth.1updemohealthplan.com/oauth2/authorize/test?client_id=f4fc333cfc54d065fd128f24de11e650&scope=user/*.read&state=tmp&redirect_uri=${appConfig.devurl}/patient/dashboard`, "_self")
     window.open(`${process.env.REACT_APP_CMSURL}&redirect_uri=${appConfig.devurl}/patient/dashboard`, "_self")
    }
  }

  const callApi = async () => {
    let requestBody = `
    mutation {
      generateAccessToken(
        hekmaPatientId: "${props.formValues.patientdata.patientId}",ehrSytemIntegrator: "1uphealth",isBulkUseCase: false
          ) {
          code
          type
          message
          attributes
      }
    }
    `;
    // this.setState({ loading: true })
    requestBody = JSON.stringify({ query: requestBody });
    let res = await oldgenericApi('generateAccessToken', requestBody);
    if (!res) {
      console.log('Error on update profile');
      //setApiError(true);
      //setTimeout(function () { setApiError(false) }, 1000 * 30);
    } else {
      if (res.data.generateAccessToken.code == "200") {
        var data = JSON.parse(res.data.generateAccessToken.attributes)
        setAccessToken(data.access_token)
      }
    }
  }
  return (
    <>
      <div className="row mt-10 mr-0 siteselectioncss">
        <div className="col-md-6">
          <h1 className="mr-auto primary-header mb-3 mt-3">Connect to your Health System</h1>
          <div className="card" style={{ marginTop: "10px", marginLeft: "0px" }}>
            <div className="card-body">
              {/* <img alt="ff" height="8%" width="8%" /> */}
              <Radio
                checked={selectedValue==='cms'}
                onChange={radioHandle}
                value="cms"
                name="radio-button-demo"
                color="primary"
                style={{ paddingRight: "15px", borderRight: "10px solid #576E8D" }} 
                inputProps={{ 'aria-label': 'A' }}
              />
              <div><b><span style={{ color: "#203F68",paddingLeft:"20px" }}>Medicare/Medicaid</span></b></div>
            </div>
          </div>
          <div className="card" style={{ marginTop: "30px", marginLeft: "0px" }}>
            <div className="card-body ">
              {/* <img alt="mmed" height="8%" width="8%" /> */}
              <Radio
                checked={selectedValue==='oneuphealth'}
                onChange={radioHandle}
                value="oneuphealth"
                color="primary"
                name="radio-button-demo"
                style={{ paddingRight: "15px", borderRight: "10px solid #576E8D" }} 
                inputProps={{ 'aria-label': 'A' }}
              />
              <div><b><span style={{ color: "#203F68",paddingLeft:"20px" }}>Others</span></b></div>
            </div></div>
           
        </div>
        <div className="col-md-6 siteImg">
          {/* <img src={ImgEHR} alt="img"/> */}
          {selectedValue === "oneuphealth" &&
                accessToken &&
                <div className="mt-3 mb-3">
                <iframe
                  title="Search"
                  style={{ border: '0px solid #fff' }}
                  src={`${process.env.REACT_APP_1UPHEALTH}/search/?access_token=${accessToken}`}
                  height={500}
                  width="100%"
                />
                </div>
              }
          </div>
      </div>
      
      {/* <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header bg-w" style={{ display: "inline-block" }}>
              <h1 className="mr-auto primary-header fl">Select </h1>
            </div>
            <div className="card-body">
              <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                  <FormControlLabel control={<Radio color="primary" name="oneuphealth" value="oneuphealth" checked={paymentmethod === "oneuphealth"} onChange={(e) => { radioHandle(e) }} />} label="Medicaid" />
                </RadioGroup>
              </FormControl>
              
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
export default withRouter(connect(mapStateToProps, null)(InitialPage));