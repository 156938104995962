import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction' // needed for dayClick
// import BackIcon from '../../../img/back1.svg'
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { oldgenericApi, getFileUrl } from '../../../common/apiconfig';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
// import Calendar from '../../../img/calendar.svg'
import Popper from '../../../globalcomponents/popper'
import SimpleModal from '../../../globalcomponents/simplemodal'
import AppointmentForm from './appointmentform'
import SampleModal from '../../../videochat/modal/modelvideo'
import UpdateAppointment from './updateAppointmentForm'
import { useConfirm } from "material-ui-confirm";
import MultiAppointmentForm from './multiappointment'
import RecordingsChats from './RecordingsChats'

const DoctorAppointments = (props) => {
  const confirm = useConfirm();
  let [anchorEl, setAnchor] = useState(null);
  //console.log(props, "Cale")
  let [progress, setProgress] = useState(false);
  let [events, setEvents] = useState([]);
  let [data, setData] = useState('')
  let [open, setOpen] = useState(false);
  let [scheduleId, setScheduleId] = useState("")
  const [addAppointment, setAddAppointment] = useState(false)
  const [showAppointment, setShowAppointment] = useState(false)
  const [appInfo, setAppInfo] = useState('')
  const [updateFormOpen, setUpdateFormOpen] = useState(false)
  const [openRecording, setOpenRecording] = useState(false)
  const [editApp, setEditApp] = useState(false)
  const [deleteApp, setDeleteApp] = useState(false)
  const [datefilter, setDatefilter] = useState([])
  const [recordChatData, setRecordChatData] = useState('')

  useEffect(() => {
    callApi()
    props.UpdateFormField({ name: "hidestudytab", value: false })
    return () => {
      props.UpdateFormField({ name: "hidestudytab", value: true })
    }
  }, [props.formValues.patientdata])
  const callApi = async () => {
    setProgress(true)
    let requestBody = {
      query: `
      query
      {
        searchAppointment(fromKey:"${localStorage.getItem('loginUserId')}",fromType:"doctor",          toKey:"*",toType:"*",  status:"*", startDateTime:"*",endDateTime:"*"  
        ) {
        appointmentId
        fromKey
        fromType
        fromName
        fromMail
        toKey
        toType
        toName
        toMail
        mailSubject
        startDateTime
        endDateTime
        note
        mailText
        docMailText
        reference
        status
        createdBy
        createdTs
        }
      }`
    };
    requestBody = JSON.stringify(requestBody);
    setProgress(true)
    let res = await oldgenericApi('searchAppointment', requestBody);
    if (!res) {
      // Error handling
      //console.log('Error')

    } else {
      setProgress(false)
      var data
      if (res.data.searchAppointment != null && res.data.searchAppointment.length > 0) {
        data = res.data.searchAppointment

        var events = []
        for (var i = 0; i < data.length; i++) {
          // var recordedVideo = callRecordApi(data[i].appointmentId)
          var obj = {
            id: data[i].appointmentId,
            title: data[i].toName,
            description: data[i].note,
            // start: new Date(data[i].startDate + ' ' + data[i].startTime),
            // end: new Date(data[i].startDate + ' ' + data[i].endTime),
            start: moment.utc(data[i].startDateTime + ' UTC', 'YYYY-MM-DD HH:mm:ss [UTC]').local().format('YYYY-MM-DD HH:mm'),
            end: moment.utc(data[i].endDateTime + ' UTC', 'YYYY-MM-DD HH:mm:ss [UTC]').local().format('YYYY-MM-DD HH:mm'),
            link: data[i].docMailText,
            metadata: data[i]
          }
          console.log(data[i].startDateTime, data[i].startDateTime + ' UTC', obj, "obj")
          events.push(obj)
        }
      }
      setEvents(events)
      setData(data)
    }

  }

  const callRecordApi = async (id) => {
    let requestBody = {
      query: `query {
        getArtifactWithSupport(artifactoryId:"${id}") 
       {
        af {
          artifactoryId
          name
          summary
          type
          category
        }
         video {
           ID
           buArea
           buid
           type
           status
           detail1
           detail2
           detail3
           detail4
           addedById
           addedByName
           addedTs
         }
         
       }
     }
      `
    }
    requestBody = JSON.stringify(requestBody);
    let res = await oldgenericApi('getArtifactWithSupport', requestBody);
    if (!res) {
    } else {
      if (res?.data?.getArtifactWithSupport != null && res?.data?.getArtifactWithSupport.length > 0) {
        var url = ''
        if (res?.data?.getArtifactWithSupport[0].video.length > 0) {
          let resurl = await getFileUrl('fileurl', res?.data?.getArtifactWithSupport[0].video[0], "GET");
          url = JSON.parse(resurl.attributes).URL;
          console.log(url, "videourl");
        }
        var chat = []
        if (JSON.parse(res.data.getArtifactWithSupport[0].af.summary.length > 0)){
          chat = JSON.parse(res.data.getArtifactWithSupport[0].af.summary)
        }
        setRecordChatData({ "chat": chat, "url": url })
      } else {
        setRecordChatData({ "chat": [], "url": "" })
      }
    }
  }

  const goBack = () => {
    props.history.goBack()
  }
  const handleClick = () => {
    setOpen(true)
  }
  const dialogclose = () => {
    setOpen(false)
  }
  const eventClick = (info) => {
    var patientAppId = info.event.id;
    var getApp = data.filter(item => item.appointmentId === patientAppId)
    setAppInfo(getApp)
    // setShowAppointment(true)
    setUpdateFormOpen(true)
  }
  const dateClick = (info) => {
    // setAddAppointment(true)
    var datefilter
    if (data != undefined) {
      datefilter = data.filter(item => item.startDate === info.dateStr)
    }
    var UserDate = new Date(info.date);
    var ToDate = new Date();
    ToDate.setHours(0, 0, 0, 0);
    if (UserDate.getTime() < ToDate.getTime()) {
      alert("The Date must be Bigger or Equal to today date");
      return false;
    } else {
      setAddAppointment(true)
      setDatefilter(datefilter || [])
      props.UpdateFormField({ name: "app_date", value: info.dateStr })
    }
    // change the day's background color just for fun
    // info.dayEl.style.backgroundColor = 'red';

  }
  const onClose = () => {
    setAddAppointment(false)
    setShowAppointment(false)
    setUpdateFormOpen(false)
    setEditApp(false)
    setOpenRecording(false)
  }
  const editAppointment = () => {
    setEditApp(true)
  }
  const deleteAppointment = () => {
    confirm({ description: `This will permanently delete ${appInfo[0].toName} appointment` })
      .then(() => deleteApi(appInfo[0]))
      .catch(() => console.log("Deletion cancelled."));
  }

  const deleteApi = async (info) => {

    let requestBody = `
      mutation
      {
        deleteAppointment(appointmentId:"${info.appointmentId}") {
          code
          type
          message
          attributes
        }
      }`;

    requestBody = JSON.stringify({ query: requestBody });
    let res = await oldgenericApi('deleteAppointment', requestBody);
    setProgress(false);

    if (res && res.data.deleteAppointment.code === '200') {
      onClose()
      callApi()
    } else {
      console.log('Error')
    }

  }

  const joincall = (e, url, metadata) => {
    e.preventDefault()
    props.UpdateFormField({ name: "appointmentData", value: metadata })
    props.history.push(url)
  }

  const clickRecording = (e, appointmentid) => {
    e.preventDefault()
    callRecordApi(appointmentid)
    setOpenRecording(true)
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      {/* <div className="d-flex mb-3 justify-content-end">
        <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
          <i className="fa fa-plus-circle mr-1"></i>ADD APPOINTMENT</button>
      </div> */}
      <div className="mt-3" style={{ marginBottom: "40px" }}>
        <SimpleModal header={appInfo.length > 0 && appInfo[0].fromName} open={showAppointment} onClose={onClose} update={true} editAppointment={editAppointment} deleteAppointment={deleteAppointment} width="50%">
          <div className="col-md-12">
            <AppointmentForm onClose={onClose} callAppointmentsInfo={callApi} info={appInfo} edit={editApp} delete={deleteApp} />
          </div>
        </SimpleModal>
        <SimpleModal header="Appointment Details" open={updateFormOpen} onClose={onClose} update={true} deleteAppointment={deleteAppointment} width="50%">
          <div className="col-md-12">
            <UpdateAppointment
              onClose={onClose} callAppointmentsInfo={callApi} info={appInfo}
            />
          </div>
        </SimpleModal>
        <SimpleModal header="Appointment Form" open={addAppointment} onClose={onClose} update={false} width="50%">
          <div className="col-md-12">
            <MultiAppointmentForm onClose={onClose} callAppointmentsInfo={callApi} datefilter={datefilter} />
            {/* <AppointmentForm onClose={onClose} callAppointmentsInfo={callApi} /> */}
          </div>
        </SimpleModal>
        <SimpleModal header="Recording & Chat" open={openRecording} onClose={onClose} update={false} width="80%">
          <div className="col-md-12">
            <RecordingsChats onClose={onClose} data={recordChatData} />
            {/* <AppointmentForm onClose={onClose} callAppointmentsInfo={callApi} /> */}
          </div>
        </SimpleModal>
        <FullCalendar
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'prevYear,prev,next,nextYear today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
          }}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          // buttonRef={node => {
          //   setAnchor(node);
          // }}
          droppable={false}
          editable={false}
          weekends={true}
          events={events}
          eventClick={eventClick}
          contentHeight={"auto"}
          selectable={false}
          dateClick={dateClick}
          // eventMouseEnter={openPopper}
          selectMirror={true}
          dayMaxEvents={true}
          eventContent={(info) =>
            <>
              <Popper info={info} linkclick={joincall} clickRecording={clickRecording} />
            </>
          }
        />
        {/* {
          open && <AddAppointmentForm open={open} dialogclose={dialogclose} title="Add Appointment" scheduleId={scheduleId} callApi={callApi}/>
        } */}
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DoctorAppointments));

