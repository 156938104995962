import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { genericApi } from '../../../common/apiconfig';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Paginationn } from '../../../common/pagination'
import { appConfig } from "../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import Loadable from 'react-loadable';
import { withRouter } from 'react-router-dom';
import { NavSubMenu } from '../navMenu';
//import PreScreening from './prescreening'

const menus = [
  {
    to: "/site/study/assessment/segments",
    text: "Segments"
  },
  {
    to: "/site/study/assessment/scheduleMatrix",
    text: "Schedule Matrix"
  },
]


function Assessment(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');


  useEffect(() => {
    async function getApiData() {
      fetchData()
    }
    getApiData();
  }, []);
  useEffect(() => {
    async function getApiData() {
      // alert('2')
      fetchData()
    }
    getApiData();
  }, [props.formValues.autostudy.studyId]);
  const fetchData = async () => {

    let requestBody = {
      query: `query
    {
      getScheduleDetails(studyId:"${props.formValues.autostudy.studyId}",scheduleId:"-1")
      {
        schedule {
          scheduleID
          studyId
          scheduleDsc
          effectiveDate
          createdBy
          createdTs
          modifiedBy
          modifiedTs
        }
        scheduleEventList {
          scheduleID
          eventID
          eventType
          eventOrder
          eventName
          eventKey
        }
        scheduleSegmentList {
          scheduleID
          segmentOrder
          segmentName
          start
          end
          varience
        }
        linkRowColumn {
          scheduleID
          rowId
          columnId
        }
      }
    }`
    };

    requestBody = JSON.stringify(requestBody);
    // setProgress(true);
    let res = await genericApi('getScheduleDetails', requestBody);
    // setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error on response material grid!')

    } else {
      if (res.data.getScheduleDetails.schedule != null) {
        await props.UpdateFormField({ name: "assessments", value: res.data.getScheduleDetails })
      }else{
        props.UpdateFormField({ name: "assessments", value: {} })
      }
    }
  }
  const LoadCom = (props) => {
    let Component = Loadable({
      loader: () => import(`./${props.fileName}`),
      loading() {
        return <div className="fullScreen">
          <CircularProgress
            disableShrink
            className="progressCircle"
            value={90}
            size={100}
            thickness={1.5}
          />
          <span>UI Script loading</span>
        </div>
      },
    });
    return <Component {...props} />
  }




  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div className="d-flex">
      {/* <div class="secondary-header mr-auto">Events</div> */}
      </div>

      <section className="mb-3">
        <NavSubMenu menus={menus} />
      </section>

      <div className="">
        <Switch>
          <Route path="/site/study/assessment/segments">
            <LoadCom fileName="segments" />
          </Route>
          <Route path="/site/study/assessment/scheduleMatrix">
            <LoadCom fileName="scheduleMatrix" />
          </Route>
          <Redirect to="/site/study/assessment/segments" />
        </Switch>
      </div>
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Assessment));



