import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

function WorkoutReading(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}
            <section className="row">
                <div className="col-12 d-flex justify-content-between">
                    <h6>{props.text}</h6>
                    {/* <span><i className="fas fa-ellipsis-h"></i></span> */}
                </div>
            </section>
            <div className="row body">
                <div className="col-6 d-flex justify-content-center align-items-center">
                    {props.icon ? <i className={props.icon}></i>: <img src={props.src} alt={props.text} />}
                </div>
                <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                    <h3>{props.reading}</h3>
                    <p>{props.unit}</p>
                </div>
            </div>
        </>
    )

}


export default WorkoutReading;


