import { oldgenericApi, securityApi } from '../common/apiconfig';
import { appConfig } from '../common/constants'

export default async function CreateSession(accountID, siteID, studyID) {
  let requestBody = {
    query: `query {
getPartnerId(accountId:"${accountID}")
}`
  }
  requestBody = JSON.stringify(requestBody);
  let res = await oldgenericApi('getPartnerId', requestBody);
  if (!res) {

  } else {
    let partnerId = res.data.getPartnerId
    function ip2int(ip) {
      return ip.split('.').reduce(function (ipInt, octet) { return (ipInt << 8) + parseInt(octet, 10) }, 0) >>> 0;
    }
    let url = appConfig.ipInfoApi
    async function getipData(url) {
      const response = await fetch(url);
      return response.json();
    }

    let localId = await getipData(url);
    var securityBody = JSON.stringify({ "partnerId": partnerId, "localId": ip2int(localId.ip).toString() });

    let res1 = await securityApi('securityapi', securityBody);
    // setProgress(false);
    if (!res1) {
      alert('Error')
    } else {
      // setProgress(false)
      return true
    }
  }
}