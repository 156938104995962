import React from 'react'
import CreateTemplateCommon from '../../../../globalcomponents/admincomponents/createtemplatecommon'

const CreateTemplate = () => {
  return (
    <>
      <CreateTemplateCommon />
    </>
  )
}
export default CreateTemplate