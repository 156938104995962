import React, { useEffect, useState } from "react";
import './styles.scss';

function Slider(props) {
    let a_innerDiv = '', liElement = '', listyle = '', limarginLeft = '', limarginRight = '', liWidth = '', liCount = '', ulElement = '', totalNoOfLi = '';
    const id = props.id;

    let leftFlag = false, rightFlag = false;

    useEffect(() => {
        setWidth();
    })

    useEffect(() => {
        window.addEventListener('resize', setWidth);
        setInitialWidth();
        enableDisable();
    }, [])


    const enableDisable = () => {
        //check left icon
        try {
            let uiMarginLeft = parseInt(ulElement.style.marginLeft) || 0;
            if (uiMarginLeft == 0) {
                disableLeft()
            }
            if (uiMarginLeft < 0) {
                enableLeft();
            }

            //check right icon, how many are in right
            let uistyle = getComputedStyle(ulElement);
            let uiCurrentML = parseInt(uistyle.marginLeft);
            let howmanymoved = uiCurrentML < 0 ? (-uiCurrentML) / liWidth : 0;
            let howManyCanMove = totalNoOfLi - (liCount + howmanymoved);
            howManyCanMove = (howManyCanMove < liCount) ? howManyCanMove : liCount;

            if (howManyCanMove == 0) {
                disableRight();
            }
            if (howManyCanMove > 0) {
                enableRight();
            }
        } catch (error) {
            console.log('error at enableDisable', error);
        }
    }

    const disableLeft = () => {
        try {
            document.querySelector(`#${id} span.left`).classList.add("disable");
            leftFlag = true;
        } catch (error) {
            console.log('error at disableLeft')
        }
    }

    const disableRight = () => {
        try {
            document.querySelector(`#${id} span.right`).classList.add("disable");
            rightFlag = true;
        } catch (error) {
            console.log('error at disableRight')
        }
    }

    const enableLeft = () => {
        try {
            document.querySelector(`#${id} span.left`).classList.remove("disable");
            leftFlag = false;
        } catch (error) {
            console.log('error at enableLeft')
        }
    }

    const enableRight = () => {
        try {
            document.querySelector(`#${id} span.right`).classList.remove("disable");
            rightFlag = false;
        } catch (error) {
            console.log('error at enableRight')
        }
    }

    const setInitialWidth = () => {
        try {
            if (ulElement.clientWidth <= a_innerDiv.clientWidth) {
                ulElement.classList.add('w-100');
                disableRight();
            } else {
                ulElement.classList.remove('w-100')
            }
        } catch (error) {
            console.log('error at setInitialWidth')
        }
    }

    const setWidth = () => {
        try {
            a_innerDiv = document.querySelector(`#${id} .s_innerWidth`);
            let outerContainer = document.querySelector(`#${id}`);

            let icons = document.querySelector(`#${id} span.left`);

            let computedStyle_outerElement = getComputedStyle(outerContainer);

            let setinnerWidth = outerContainer.offsetWidth - (parseInt(computedStyle_outerElement.paddingLeft) + parseInt(computedStyle_outerElement.paddingRight) + (icons.offsetWidth * 2));

            ulElement = document.querySelector(`#${id} .s_innerWidth ul`);
            liElement = document.querySelector(`#${id} .s_innerWidth li`);
            totalNoOfLi = document.querySelectorAll(`#${id} .s_innerWidth li`).length;
            listyle = getComputedStyle(liElement);
            limarginLeft = parseInt(listyle.marginLeft);
            limarginRight = parseInt(listyle.marginRight);
            liWidth = liElement.offsetWidth + limarginLeft + limarginRight;
            liCount = Math.floor(setinnerWidth / liWidth);
            a_innerDiv.style.width = (liCount * liWidth) + 'px';

            // let tobeActiveLiElement = document.querySelector(`.liimgList li:nth-child(1)`);
            // tobeActiveLiElement.classList.add("liActive");
            setTimeout(() => {
                enableDisable();
            }, 400);
        } catch (error) {
            console.log('error found at setWidth!', error)
        }
    }

    const rightHandler = (e) => {
        try {
            if (rightFlag) {
                return;
            }
            let uistyle = getComputedStyle(ulElement);
            let uiCurrentML = parseInt(uistyle.marginLeft);
            let howmanymoved = uiCurrentML < 0 ? (-uiCurrentML) / liWidth : 0;
            let howManyCanMove = totalNoOfLi - (liCount + howmanymoved);
            howManyCanMove = (howManyCanMove < liCount) ? howManyCanMove : liCount;
            let howmuchToMove = howManyCanMove * liWidth;
            let mltoMove = uiCurrentML - howmuchToMove;
            ulElement.style.marginLeft = mltoMove + 'px';

            setTimeout(() => {
                enableDisable();
            }, 400);

            //checkWhichLiInDisplay(howmanymoved, 'right');
        } catch (error) {
            console.log('rightHandler', error)
        }
    }

    const leftHandler = (e) => {

        try {
            if (leftFlag) {
                return;
            }

            let uistyle = getComputedStyle(ulElement);
            let uiCurrentML = parseInt(uistyle.marginLeft);
            let howmanymoved = uiCurrentML < 0 ? (-uiCurrentML) / liWidth : 0;
            //console.log('out howmanymoved::', howmanymoved);
            if (howmanymoved) {
                let howmanyCanMove = howmanymoved > liCount ? liCount : howmanymoved;
                //howManyCanMove = (howManyCanMove<liCount)? howManyCanMove : liCount;
                //console.log('howmanymoved::', howmanymoved, 'howManyCanMove::', howmanyCanMove, 'uiCurrentML::', uiCurrentML)
                let howmuchToMove = howmanyCanMove * liWidth;
                let mltoMove = uiCurrentML + howmuchToMove;
                ulElement.style.marginLeft = mltoMove + 'px';

                setTimeout(() => {
                    enableDisable();
                }, 400);
            }
            // checkWhichLiInDisplay(howmanymoved, 'left');
        } catch (error) {
            console.log('leftHandler', error)
        }
    }

    const checkWhichLiInDisplay = (howmanymoved, direction) => {
        //console.log('howmanymoved;',howmanymoved);
        let allLiEls = document.querySelectorAll('.liimgList li');
        for (let i = 0; i < allLiEls.length; i++) {
            allLiEls[i].classList.remove("liActive");
        }
        if (direction === 'left') {
            try {
                let tobeActiveLiElement = document.querySelector(`.liimgList li:nth-child(${howmanymoved})`);
                tobeActiveLiElement.classList.add("liActive");

            } catch {
                let tobeActiveLiElement = document.querySelector(`.liimgList li:nth-child(1)`);
                tobeActiveLiElement.classList.add("liActive");
            }
        } else {
            try {
                let tobeActiveLiElement = document.querySelector(`.liimgList li:nth-child(${howmanymoved + 2})`);
                tobeActiveLiElement.classList.add("liActive");

            } catch {
                let tobeActiveLiElement = document.querySelector(`.liimgList li:nth-child(${allLiEls.length})`);
                tobeActiveLiElement.classList.add("liActive");
            }
        }

    }

    const moveLiByIndex = (e, inx) => {
        //console.log('inx', inx)

        let allLiEls = document.querySelectorAll('.liimgList li');
        for (let i = 0; i < allLiEls.length; i++) {
            allLiEls[i].classList.remove("liActive");
        }

        let tobeActiveLiElement = document.querySelector(`.liimgList li:nth-child(${inx + 1})`);
        tobeActiveLiElement.classList.add("liActive");

        let ulElement = document.querySelector(`#${id} .s_innerWidth ul`);
        let uiCurrentML = 0;
        let howmuchToMove = parseInt(inx) * liWidth;
        let mltoMove = uiCurrentML - howmuchToMove;
        ulElement.style.marginLeft = mltoMove + 'px';

    }

    return (
        <>
            <section className="s_outerWidth_common" id={props.id}>
                <span className="left" onClick={leftHandler}>
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                </span>
                <div className="s_innerWidth">
                    <ul className="">
                        {props.children}
                    </ul>
                </div>
                <span className="right" onClick={rightHandler}>
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </span>
            </section>
        </>
    )
}

export default React.memo(Slider);