import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../../common/apiconfig';
import FormHoc from '../../hoc/formHoc';
import { InputField, AutoOptions } from '../../formElements';
import { Dropdowns } from '../../../common/constants';
import { connect } from 'react-redux';
import HekmaDatePicker from '../../HekmaDatePicker'
import moment from 'moment'
import BackIcon from '../../../../img/back1.svg'
import AuditTrailFunc from '../../../common/AuditTrailFunc'
const UpdateIECriteria = (propsobj) => {
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [oldData, setOldData] = useState({});

  useEffect(() => {
    setOldData(propsobj.formValues)
  }, [oldData])

  useEffect(() => {
    // propsobj.clearForm();
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
  }, []);

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsTotype = {
    default: null,
    'options': Dropdowns.Protocol_Setup_IE_Criteria_Type,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropstostatus = {
    ...autoPropsTotype,
    'options': Dropdowns.Account_Status,
    default: null,
  }


  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('updateIECriteria', event);

    if (setGo) {
      setProgress(true);
      let form = propsobj.formValues;
      console.log(form)
      let requestBody = `
      mutation 
      {
        updateStudyEvent(effectiveDate: "${moment(form.IE_effectiveDate).format('YYYY-MM-DD')}}", studyId: "${form.studyId}" ,eventId:"${form.eventId}",eventType: "${form.IE_type[0]}",type: "${form.IE_type}", description: ${JSON.stringify(form.IE_description)},status: "${form.IE_status}", severity:"") {
          code
          type
          message
          attributes
        }  
      }
          `;

      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('updateStudyEvent', requestBody, form);
      setProgress(false);

      if (res && res.data.updateStudyEvent.code === '200') {
        // propsobj.clearForm();
        AuditTrailFunc("Study", propsobj.formValues.studyId, "Study IE Criteria", "IE Criteria ID:" + propsobj.formValues.eventId, oldData, propsobj.formValues)
        propsobj.history.push('/account/studyDetails/IEcriteria');
      } else {
        console.log('Error on response add account file!')
        //props.history.push('/account/updateContact'); // temp enabling to check update sponsor
      }
    }
  }

  const backtolist = (event) => {
    event.preventDefault();
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
    propsobj.history.push('/account/studyDetails/IEcriteria');
  }

  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.goBack()
  }
 
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* <div onClick={backtolist} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO I-E CRITERIA LIST</div><br/> */}
      <div className="d-flex mt-4 justify-content-end">
        <div className="mr-auto secondary-header">Edit Inclusion/Exclusion Criteria</div>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="updateIECriteria" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  {/* <div className="card-page-header">NAME INFORMATION</div> */}
                  <div className="inputBox">
                    <AutoOptions {...autoPropsTotype} name="IE_type" label="Type" keyName='value' must={'true'} />
                    <InputField {...textProps} multiline={true} row={1} rowsMax={2} name="IE_description" label="Description" must={'true'} type="text" />
                    <AutoOptions {...autoPropstostatus} name="IE_status" label="Status" keyName='value' must={'true'} />
                    <HekmaDatePicker name="IE_effectiveDate" label="Effective Date" must={'true'} minDate={propsobj.formValues.startDt}/>

                  </div>

                </div>

              </div>
            </div>
            <div className="buttonContainer">
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={backtolist}>CANCEL</button>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateIECriteria));



