import * as constObj from '../common/constants';

const initialState = {
    form: constObj.initialFormState,
    formerrobj: constObj.ErrorObj,
    formerrtextObj: constObj.ErrorTexts,
    showHeaderSearch: false,
    showSidebar: false,
    cartcount:0
}

const reducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case 'UpdateFormField':
            newState.form = { ...newState.form, [action.name]: action.value }
            break;
        case 'UpdateEntireForm':
            newState.form = { ...newState.form, ...action.value }
            break;
        case 'ClearForm':
            newState.form = { ...newState.form, ...initialState.form }
            newState.formerrobj = { ...newState.formerrobj, ...initialState.formerrobj }
            break;
        case 'updateErrorObj':
            let name = action.name;
            newState.formerrobj = { ...newState.formerrobj, [name]: action.flag };
            newState.formerrtextObj = { ...newState.formerrtextObj, [name]: action.text }
            break;
        case 'updateEntireErrorObj':
            newState.formerrobj = { ...newState.formerrobj, ...action.errobj };
            newState.formerrtextObj = { ...newState.formerrtextObj, ...action.errtextobj }
            break;
        case 'headerSearch':
            newState.headerSearchValue = action.value;
            break;
        case 'updateCourseData':
            newState.courseData = action.value;
            break;
        case 'updateRecentItems':
            let page = action.value.obj.Page;
            newState[page] = { ...newState[page], ...action.value.value }
            break;
        case 'updateStudy':
            newState.selectedStudy = action.value;
            break;
        case 'pirmenu':
            newState.pirmenu = { ...newState.pirmenu, ...action.value }
            break;
        case 'entitydata':
            newState.entitydata = action.value;
            break;
        case 'entitypatientdata':
            newState.entitypatientdata = action.value;
            break;
        case 'sidebarActiveEl':
            newState.sidebarActEl = action.value;
            break;
        case 'toggleSidebar':
            newState.showSidebar = action.value;
            break;
        case 'toggleHeaderSearch':
            newState.showHeaderSearch = action.value;
            break;
        case 'videoRecReq':
            newState.videoRecReqPer = action.value;
            break;
        case 'Totalprice':
            newState.totalprice=action.value;
            break
        case 'Cartdata':
            newState.cartdata=action.value;
            break
        case 'cartcount':
            newState.cartcount=action.value;
            break
    }
    return newState;
}

export {
    reducer,
    initialState
}