import React from 'react'
import { Route, Link, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import MainPage from './components/mainPage';
import SiteSelection from './components/siteselectionpage'
import IEApicall from './components/IE_AI_APIcall'
import './sass/siteAppStyles.scss';

// import PatientSignup from './components/activatePatientProfile'


const Routing = () => (
  <Router basename="/site">
    <Switch>
      <Route path="/site" component={MainPage} />
      <Route path="/activatePatient" component={MainPage} />
      <Route path="/siteselection" component={SiteSelection} />
      <Route path="/IEApicall" component={IEApicall} />
      <Route path="/activateContact" component={MainPage} />
    </Switch>
  </Router>

);
export default Routing;