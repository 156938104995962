
// import differenceBy from 'lodash/differenceBy'
import { genericApi } from './apiconfig';

var arrObj = []

export default async function AuditTrailFunc(account_type, parentId, type, id, prevState, presentState) {
  console.log(account_type, parentId, type, id, prevState, presentState, "AT")
  var keyValues = Object.keys(presentState)
  arrObj = []
  for (var i = 0; i < keyValues.length; i++) {
    if (Array.isArray(prevState[keyValues[i]]) == true) {
      console.log(prevState[keyValues[i]], "AT1")
      // console.log(prevState[keyValues[i]], Object.keys(prevState[keyValues[i]]))
      // for (var j = 0; j < keyValues.length; j++) {
      //   let arr = Object.keys(prevState[keyValues]);
      //   var arrObj = []
      //   console.log(arr)
      //   // for(var i=0;i<arr.length;i++){
      //   // 	const myDifferences = differenceBy((prevState[keyValues[i]], presentState[keyValues[i]], ))
      //   // 	arrObj.push(myDifferences)
      //   // }
      // }
      // console.log("myDifferences", arrObj)
    } else
      if (Array.isArray(prevState[keyValues[i]]) == false && typeof prevState[keyValues[i]] === 'object' && prevState[keyValues[i]] !== null) {
        callObjFun(prevState[keyValues[i]], presentState[keyValues[i]])
      } else {
        if (prevState[keyValues[i]] != null) {
         
          if (prevState[keyValues[i]].toString() !== presentState[keyValues[i]].toString()) {
            console.log(keyValues[i] + ' changed from ' + prevState[keyValues[i]] + ' To ' + presentState[keyValues[i]], "err")
            let text 
            if(presentState[keyValues[i]].length > 0){
              text = keyValues[i] + ' changed from ' + prevState[keyValues[i]] + ' To ' + presentState[keyValues[i]]
            }else{
              text = keyValues[i] + ' changed from ' + prevState[keyValues[i]] + ' To null'
            }
           
            arrObj.push(text)
          }
        }
      }
  }
  console.log(arrObj)
  if (arrObj.length > 0) {
    var changedObj = arrObj.toString()
    var str = id + ' ' + changedObj.replace(/,/g, ' || ')
    let requestBody = {
      query: `mutation
          {
            putAudit(buArea:"${account_type}",buId:"${parentId}",type:"${type}",user:"${ localStorage.getItem('loginUserId')+":"+localStorage.getItem("loggedInUser")}",summary:"${str}") {
              code
              type
              message
              attributes
            }
          }`
    };

    requestBody = JSON.stringify(requestBody);
    let res = await genericApi('putAudit', requestBody);
    if (!res) {
      // Error handling
      console.log('Error')

    } else {
      console.log('updated audit trail')
    }



  }
}


function callObjFun(exactData, newData) {
  let diff = {}
  diff = Object.keys(newData).reduce((diff, key) => {
    if (newData[key] === exactData[key]) return diff
    return {
      ...diff,
      [key]: exactData[key]
    }
  }, {})

  Object.keys(diff).forEach((key, index) => {
    if (diff[key].toString() !== newData[key].toString()) {
      arrObj.push(exactData, 'Obj Changed from', diff[key], 'To', newData[key] == "" ? 'null' : newData[key])
    }
  })

}

