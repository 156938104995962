import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Route, Switch, Link, BrowserRouter as Router, Redirect, NavLink } from 'react-router-dom';
import MHistoryHome from './dashboard/mHistoryHome';

import { Menus, NavSubMenu } from '../navMenu';
import AuditTrail from './auditTrail';

import PatientDetail from './patientDetail';


const menus = [
    {
        to: "/patient/detail",
        text: "Patient Details",
    },
    {
        to: "/patient/medicalhistory",
        text: "Medical History",
    },
    {
        to: "/patient/audittrail",
        text: "Audit Trail",
    },
]




function DetailHome(props) {
    const [offset, setoffset] = useState(0)
    const [data, setData] = useState([])
    let [progress, setProgress] = useState(false);
    let [valSearch, setValSearch] = useState('');

    return (
        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}

            <section className="mb-3 mt-3">
                <Menus menus={menus} />
            </section>

            <Switch>
                <Route path="/patient/detail">
                    <PatientDetail />
                </Route>
                <Route path="/patient/medicalhistory">
                    <MHistoryHome />
                </Route>
                <Route path="/patient/audittrail">
                    <AuditTrail />
                </Route>
                <Redirect to="/patient/detail" />
            </Switch>
        </>
    )

}

export default DetailHome;


