import React, { useEffect, useState } from "react";
import Materialtable from '../../../../globalcomponents/materialtable';
import { apiCall } from '../../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkPermission } from '../../../common/utils';


function IECriteriaGrid(props) {
  let isAddRBM = checkPermission('RBM_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);


  useEffect(() => {

    async function fetchData() {

      let requestBody = {
        query: `query {
          getStudyEvent(studyId:"${props.formValues.studyId}", eventType:"R") {
          studyId
          eventId
          eventType
          type
          severity
          description
          status
          effectiveDate
          createdBy
          createdTs
          modifiedBy
          modifiedTs
          }
          }`
      };

      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await apiCall('getStudyEvent', requestBody);
      setProgress(false);
      if (!res) {
        // Error handling
        console.log('Error on response material grid!')

      } else {
        if (res.data.getStudyEvent.length) {
          setData(res.data.getStudyEvent)
        }
      }
    }
    fetchData();
  }, []);

  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    props.history.push('/account/studyDetails/rbm/Add');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    console.log(rowData, "rowData")
    var data = { ...rowData, "rbm_type": rowData.type, "rbm_description": rowData.description, "rbm_severity": rowData.severity, "rbm_status": rowData.status }
    await props.UpdateEntireForm(data);
    props.history.push('/account/studyDetails/rbm/View');

  }

  const Columns = [
    {
      title: 'Risk Name', field: 'type',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Description', field: 'description',
      cellStyle: {
        color: '#244271',
        fontWeight: 500
      },
    },
    { title: 'Severity', field: 'severity' },
    { title: 'Status', field: 'status' },

  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <div className="d-flex mt-4 justify-content-end">
        <div className="mr-auto secondary-header">Risk Based Monitoring</div>
        {
          isAddRBM === "1" ? <div className="">
            <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
              <i className="fa fa-plus-circle mr-1"></i> ADD RISK</button>
          </div> : ""
        }

      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="">
            <Materialtable
              columns={Columns}
              data={data}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IECriteriaGrid));


