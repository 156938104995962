import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import { Paginationn } from '../../../sponsorApp/common/pagination'
import moment from 'moment'

function TabContainer(props) {
  return (
    <>
      {props.children}
    </>
  );
}
export default function CenteredTabs({ data, openPatient, NRSData, openNRSItem,openQueryPage, activeApp, handlePageClick, handlePageClickReq, offset, offsetReq, activeNSR, queriesData }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      // callView()
    }
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
         <Tab label={<span style={{display:'flex'}}><span style={{position:"relative" , 'z-index':"10",'margin-top':'3px','marginRight':'1px'}}>Appointments</span><Badge badgeContent={activeApp.length} color="secondary" ></Badge></span>} />
         <Tab label={<span style={{display:'flex'}}><span style={{position:"relative" , 'z-index':"11",'margin-top':'2px','marginRight':'1px'}}>NRS Requests</span><Badge badgeContent={activeNSR.length} color="secondary" style={{'padding':'2.5px'}}></Badge></span>} />
         <Tab label={<span style={{display:'flex'}}><span style={{position:"relative" , 'z-index':"12",'margin-top':'3px','marginRight':'1px'}}>Queries</span><Badge badgeContent={queriesData.length} color="secondary" ></Badge></span>} />
        {/* <Tab label={<Badge badgeContent={activeApp.length} color="secondary">Appointments</Badge>} />
        <Tab label={<Badge badgeContent={activeNSR.length} color="secondary">NRS Requests</Badge>} />
        <Tab label={<Badge badgeContent={queriesData.length} color="secondary">Queries</Badge>} /> */}
      </Tabs>
      {value === 0 &&
        <form>
          <TabContainer>
            {data.length > 0 ? (data.slice(offset, 5 + offset)).map((item, i) => (
              <a className="dropdown-item" id={item.id} onClick={(e) => openPatient(e, item)} ><div className={item.requestStatus === "Created" ? "createdcss" : "viewedcss"}>{item.refKey3}({item.requestDescription}) - Requested for an appointment  -
                <span><small>{moment(item.requestTimestamp).format('YYYY-MM-DD')}</small></span>
              </div></a>
            )) : <div className="ml-3">No alerts found </div>}
            {data.length > 5 &&
              <Paginationn
                handlePageClick={handlePageClick}
                data={data.length}
                offset={offset}
              />
            }

          </TabContainer></form>}

      {value === 1 &&
        <form>
          <TabContainer>
            {NRSData.length > 0 ? (NRSData.slice(offsetReq, 5 + offsetReq)).map((item, i) => (
              <a className="dropdown-item" id={item.id} onClick={(e) => openNRSItem(e, item)} ><div className={item.leadStatus === "New" ? "createdcss" : "viewedcss"}>{"NR" + item.studyNum} - {item.
                accountName}({item.accountId}) - Requested a study  -
                <span><small>{ moment(item.requestDateTime).format('YYYY-MM-DD')}</small></span>
              </div></a>
            )) : <div className="ml-3">No alerts found </div>}
            {NRSData.length > 5 &&
              <Paginationn
                handlePageClick={handlePageClickReq}
                data={NRSData.length}
                offset={offsetReq}
              />
            }
          </TabContainer></form>}
          {value === 2 &&
        <form>
          <TabContainer>
            {queriesData.length > 0 ? (queriesData.slice(offsetReq, 5 + offsetReq)).map((item, i) => (
              <a className="dropdown-item" id={item.id} onClick={(e) => openQueryPage(e, item)} ><div className="createdcss">{item.queryNum} - {item.
                queryTitle} - ({item.studyId})
                <span><small>&nbsp;&nbsp;{  moment(item.createdTs).format('YYYY-MM-DD')}</small></span>
              </div></a>
            )) : <div className="ml-3">No alerts found </div>}
            {queriesData.length > 5 &&
              <Paginationn
                handlePageClick={handlePageClickReq}
                data={queriesData.length}
                offset={offsetReq}
              />
            }
          </TabContainer></form>}
    </>
  );
}