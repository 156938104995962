import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(0),
        top: theme.spacing(0),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}style={{
            backgroundColor: "#1c3b62",
            color: "white",
            padding: "10px",
          }} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function DeleteEntity(props) {
    const [open, setOpen] = React.useState(false);
    let [textobj, setTextobj] = useState(props.textobj);
    let selectedCheckboxes = {}

    useEffect(() => {
        setTextobj(props.textobj)
    }, [props.textobj])

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        //setOpen(false);
        props.closePopover();
    };

    const defaultProps = {
        options: props.categories,
    };

    const getOptionLabel = option => {
        // e.g value selected with enter, right from the input
        try {
            if (props.getOptionLabel) {
                return props.getOptionLabel(option)
            } else {
                if (typeof option === 'string') {
                    return option;
                }
                if (option && option.inputValue) {
                    return option.inputValue;
                }
                return option && option[props.keyName]
            }
        } catch (error) {

        }
    }

    const deleteEntities = (e) => {
        e.preventDefault();

        let entities = props.entities;
        Object.keys(selectedCheckboxes).map((item, i) => {
            if (selectedCheckboxes[item] == true) {
                let inx = item;
                //delete entities[inx]
                entities.splice(inx,1);
            }
        })

        let allData = props.allData;
        let refKeys = props.refkeys;

        if(refKeys.type == 'inclusion'){
            allData['eligibility criteria']['inclusion_criteria'][refKeys.rowInx].sents[refKeys.terminx].terms = entities;
        }else{
            allData['eligibility criteria']['exclusion_criteria'][refKeys.rowInx].sents[refKeys.terminx].terms = entities;
        }

        props.updateDeletedData(allData)
    }



    const handleChange = (event, enInx) => {
        selectedCheckboxes = { ...selectedCheckboxes, [enInx]: event.target.checked }
    }

    console.log('129 delete modal props.entities:::',props.entities)

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.openpopover}>
                <DialogTitle id="customized-dialog-title"
                // style={{
                //     backgroundColor: "#1c3b62",
                //     color: "white",
                //     padding: "10px",
                //   }}
                onClose={handleClose}>
                    DELETE ENTITY
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {/* <form className={""} noValidate autoComplete="off">
                            {props.entities.map((entity, enInx) => {
                                return <div key={enInx}>{entity.term}</div>
                            })}
                        </form> */}


                        <FormGroup row className="deleteEntities">

                            {props.entities.map((entity, enInx) => {
                                if(entity){
                                    return <FormControlLabel
                                    control={<Checkbox
                                        onChange={(e) => handleChange(e, enInx)}
                                        name={entity.text || ''} />}
                                    label={entity.text || ''}
                                />
                                }

                               


                            })}
                        </FormGroup>

                    </Typography>
                </DialogContent>
                <DialogActions>
                    <div className="text-right">
                        <button className="btn btn-primary-blue btn-rounded" onClick={(e) => deleteEntities(e)}>DELETE</button>
                    </div>

                </DialogActions>
            </Dialog>
        </div>
    );
}
