import React, { useEffect, useState, useCallback, useContext, useMemo } from "react";
import MaterialTable from 'material-table';
import { apiCall } from '../../../common/apiconfig';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Paginationn } from '../../../common/pagination'
import { appConfig } from "../../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../../../globalcomponents/formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import Loadable from 'react-loadable';
import { genericApi } from '../../../common/apiconfig';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginLeft: "37%"

  },
  role_css: {
    //  backgroundColor: '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6),
    // backgroundColor: bgColor[Math.floor(Math.random() * bgColor.length)],
    padding: "6px",
    color: "white",
    fontSize: "14px",
    borderRadius: "5px",
  }
}));
var bgColor = ["lightgreen", "skyblue", "teal"]
function StudyPersonal(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');
  const classes = useStyles();
  useEffect(() => {
    getApiData();
  }, []);
  useEffect(() => {
    getApiData()
  }, [props.formValues.autostudy.studyId])
  async function getApiData() {
    let requestBody = {
      query: `query {
        getStudyContact(studyId:"${props.formValues.autostudy.studyId}") {
          studyId
          contactId
          action
          name
          status
          role
          email
          phone
          altno
          createdBy
          createdTs
          modifiedBy
          modifiedTs
        }
        }`
    };

    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getStudyContact', requestBody);
    setProgress(false);
    if (!res) {
      // Error handling
      //console.log('Error')

    } else {
      if (res.data.getStudyContact.length) {
        setData(res.data.getStudyContact)
      }else{
        setData([])
      }
    }
  }
  useEffect(() => {
    getApiData()
  }, [props.formValues.autostudy.studyId])
  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const Columns = [
    {
      title: 'Contact ID', field: 'contactId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        textAlign: 'left'
      },
    },
    {
      title: 'Name', field: 'name',
      cellStyle: {
        color: '#244271',
        fontWeight: 500
      },
    },
    // { title: 'Status', field: 'status' },
    { title: 'Position', field: 'role' },
    { title: 'Email', field: 'email' },
  ]
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      {/* <div className="d-flex">
        <div class="secondary-header mr-auto">Study Personnel</div>
      </div> */}
      <div className="listing-wrap hekmaGrid mt2rem">
        <div className="card">
          <div className="hekmaGridBox">
            <div className="row">
              {
                data.map((item, i) => (
                  <div className="col-md-4 col-lg-4 col-xl-3">
                    <Card variant="outlined" style={{ marginBottom: "20px" }}>
                      <CardContent style={{ textAlign: "center" }}>
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className={classes.large} />
                        <Typography color="textPrimary" gutterBottom>
                          {item.name}
                        </Typography>
                        {item.role &&
                          <div color="textSecondary" gutterBottom className={classes.role_css} style={{ background: bgColor[Math.floor(Math.random() * bgColor.length)] }}>
                            {item.role}
                          </div>}
                        {item.email &&
                          <Typography color="textSecondary">
                            <i className="fa fa-envelope-open-text mr-1"></i>{item.email}
                          </Typography>
                        }{item.phone &&
                          <Typography variant="body2" component="p">
                            <i className="fa fa-phone mr-1"></i>{item.phone}
                            <br />
                          </Typography>
                        }
                      </CardContent>
                    </Card>
                  </div>
                ))
              }
              {
                data.length == 0 && <div className="col-md-12" style={{textAlign:"center"}}><p>No Records</p></div>
              }
            </div>

          </div>
        </div>
      </div>
    </>
  )

}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudyPersonal);
// export default StudyPersonal;


