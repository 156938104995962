import React, { useEffect, useState } from 'react';
import { AutoOptionsWV, InputField } from '../../../globalcomponents/formElements'
import { genericApi, apiCall, oldgenericApi } from '../../../common/apiconfig';
import { getCountry, getState } from '../../common/apiconfig'
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { appConfig } from '../../../common/constants';
import { useSelector, useDispatch } from 'react-redux';
import Materialtable from '../../../globalcomponents/materialtable'
import FormHoc from '../../../globalcomponents/hoc/formHoc';
import Circle from '../../../img/Circle03.svg'
import AddCartIcon from '../../../img/opensquare/Add-to-Cart.svg'
import BuyIcon from '../../../img/opensquare/Buy.svg'
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import LightTooltipPatient from '../../../globalcomponents/tooltipPatientmatch';
import Loader from '../../../common/loader'
import { Dropdowns, Races } from '../../common/constants'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dashboard from './dashboard'
import Tunnel from '../../../siteSrc/components/site/pir/dashboard/3dTunnel'
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography'
import CustomizedSlider from '../../../globalcomponents/sliderrange'
import DragandDrop from '../../../globalcomponents/draganddrop';
import Logo from '../../../img/hekmasmall.png'
import FunnelOrder from '../../../globalcomponents/funnelorderpage'
import * as jq from 'jquery';
import '../../../siteSrc/sass/siteAppStyles.scss'

let countryObj = {
  value: '',
  label: '',
  error: false,
  helperText: '',
}
let stateObj = {
  value: '',
  label: '',
  error: false,
  helperText: '',
}
const useStyles = makeStyles((theme) => ({
  radio: {
    '&$checked': {
      color: '#214B7D'
    }
  },
  checked: {},
  checkboxStyle: {
    margin: 0,
    padding: 0,
    cursor: "pointer"
  },
}));

function valuetext(value) {
  return `${value}Y`;
}
const Results = (propsobj) => {
  const classes = useStyles();
  let [data, setData] = useState([])
  let [progress, setProgress] = useState(false)
  let [ageSelected, setAgeSelected] = useState([0, 100])
  let [age, setAge] = useState("")
  let [country, setCountry] = useState([]);
  let [state, setState] = useState([])
  let [countryField, setCountryField] = useState(null);
  let [stateField, setStateField] = useState("");
  let [city, setCity] = useState("");
  let [education, setEducation] = useState("");
  let [zip, setZip] = useState("")
  let [genderField, setGenderField] = useState("");
  let [raceField, setRaceField] = useState("");
  let [language, setLanguage] = useState("");
  let [show, setShow] = useState(false)
  let [dashboardData, setDashboardData] = useState("")
  const [funnelData, setFunnelData] = useState("")
  const [funnelCount, setFunnelCount] = useState("")
  const [showFunnel, setShowFunnel] = useState(false)
  let [selectedContactsobj, setSelectedContactsobj] = useState({})
  let [currentpage, setCurrentPage] = useState(1);
  let [order, setOrder] = useState("*")
  let [insightsData, setInsightsData] = useState("")
  let [submit, setSubmit] = useState(false) // used for tooltip to restrict variables to pass
  let [existingCart, setExistingCart] = useState([])
  const reduxdispatch = useDispatch()
  let reduxstateobj = useSelector(state => state);

  useEffect(() => {
    callTrailmatch(reduxstateobj.form.openSqNCTid)
    callComposeApi("*", "*", "*", "*", "*", "*", "*", "*", "*", "*")
    loadCountry();
    loadStates();
    setSubmit(true)
    collapseLeftPanel()
  }, [])

  useEffect(() => {
    collapseLeftPanel()
  }, [show, showFunnel])
  //get records which are already added to cart
  const callGetCartApi = async () => {
    let requestBody = {
      query: `query {
      getCartDetails(cartId:"*",accountId:"${localStorage.getItem('loginUserId')}",nctId:"${reduxstateobj.form.openSqNCTid}",siteId:"*")
    {
    cartId
    accountId
    nctId
    siteId
    siteName
    }
    }`
    }
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getCartDetails', requestBody);
    setProgress(false);
    if (res) {
      if (res.data.getCartDetails.length > 0) {
        var data = res.data.getCartDetails
        return data
      } else {
        return []
      }
    }


  }
  const callComposeApi = async (genderField, ageMin, ageMax, raceField, language, education, city, stateField, zip, countryField) => {
    setProgress(true);
    let requestBody = {
      query: `query {
        getComposeDetailsPercent(siteId:"*", studyId:"${propsobj.formValues.openSqNCTid}", patientGender:"${genderField}", patientAgeMin:"${ageMin}",patientAgeMax:"${ageMax}",patientRace:"${raceField}",  patientCity:"*", patientState:"*", patientZipCode:"*", patientCountry:"*", patientLanguage:"${language}", patientEducation:"${education}", siteName:"*", siteCity:"${city}", siteState:"${stateField}", siteZipcode:"${zip}", siteCountry:"${countryField}",leadStatus:"*",highbelow:"*",highend:"*",midbelow:"*",midend:"*", lowbelow:"*", lowend:"*")  
        {
             studyId
              siteId
              siteName
              siteCity
              siteState
              siteCountry
            patientCount
            requestId
            leadStatus
            priceType
            price
            totalPrice
            percentCount {
              siteId
              bucket
              count
          }
        }
      }`
    }
    requestBody = JSON.stringify(requestBody);

    let res = await genericApi('getComposeDetailsPercent', requestBody);
    setProgress(false);
    if (res) {
      if (res.data.getComposeDetailsPercent.length > 0) {
        var data = res.data.getComposeDetailsPercent
        var newData = data.map(v => ({ ...v, "addCart": false }))
        var olddata = await callGetCartApi()
        if (olddata.length > 0) {
          //need to change condition once compose api is available
          let dupData = newData.filter(o1 => olddata.some(o2 => o1.siteId === o2.siteId))
          if (dupData.length > 0) {
            for (let i = 0; i < dupData.length; i++) {
              for (let j = 0; j < newData.length; j++) {
                if (dupData[i].siteId == newData[j].siteId) {
                  newData[j]["addCart"] = true
                }
              }
            }
          }
          setData(newData)
        } else {
          setData(newData)
        }
      } else {
        setData([])
      }
    }
  }



  const handleAge = (event, newValue) => {
    setSubmit(false)
    setAgeSelected(newValue);
    setAge(newValue)
  };
  const callTrailmatch = async (nctid) => {
    await fetch(appConfig.BaseUrl + 'compose?nctId=' + nctid,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {
        if (responseData.length > 0) {
          // var lfmatch = responseData.filter(i => parseInt(i.percentage_match) <= 50)
          // var gfmatch = responseData.filter(i => (parseInt(i.percentage_match) > 50 && parseInt(i.percentage_match) <= 70))
          // var gsmatch = responseData.filter(i => parseInt(i.percentage_match) >= 70)
          var lfmatch = responseData.filter(i => (parseInt(i.percentage_match) >= 50 && parseInt(i.percentage_match) <= 80))
          var gfmatch = responseData.filter(i => (parseInt(i.percentage_match) > 80 && parseInt(i.percentage_match) <= 99))
          var gsmatch = responseData.filter(i => parseInt(i.percentage_match) == 100)
          // setLfmatch(lfmatch)
          // setGfmatch(gfmatch)
          // setGsmatch(gsmatch)
        } else {
          // setLfmatch([])
          // setGfmatch([])
          // setGsmatch([])
        }
      })
  }
  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }
  async function loadCountry() {
    // setProgress(true);
    let countries = await getCountry();
    // setProgress(false);
    setCountry(countries);
  }

  async function loadStates(id) {
    // setProgress(true);
    let states = await getState(id);
    // setProgress(false);
    setState(states);
  }
  const autoPropstoCountry = {
    'options': country,
    key: ['value'],
  }
  const autoPropstoGender = {
    'options': [{ name: "Male", value: "male" }, { name: "Female", value: "female" }, { name: "Others", value: "others" }],
    key: ['value'],
  }

  const autoPropstoRace = {
    'options': Races,
    key: ['value'],
  }

  const autoPropstoState = {
    'options': state,
    key: ['value'],
  }

  const autoPropstoLang = {
    'options': Dropdowns.Languages,
    key: ['value'],
  }

  const handleLanguage = async (obj) => {
    setSubmit(false)
    setLanguage(obj)
  }


  const handleRace = async (obj) => {
    setSubmit(false)
    setRaceField(obj)
  }

  const handleGender = async (obj) => {
    setSubmit(false)
    setGenderField(obj)
  }
  const handleCountry = async (obj) => {
    setSubmit(false)
    setCountryField(obj);
    setProgress(true);
    let states = await getState(obj.id);
    setProgress(false);
    setStateField("")
    setState(states);


  }

  const handleState = async (obj) => {
    setSubmit(false)
    setStateField(obj);
  }

  const onNext = () => {
    // var addedToCardList = data.filter(v => (v.addCart == true))
    // reduxdispatch({ type: 'UpdateFormField', name: "cart", value: addedToCardList })
    propsobj.history.push('/account/shoppingcart')
    reduxdispatch({ type: 'UpdateFormField', name: "cartHeader", value: false })
  }

  const showPatients = (e) => {
    e.preventDefault()
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const Columns = [
    // {title:'Request ID', field:'reqId'},
    {
      title: 'Site ID', field: 'siteId',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: '15%'
      },
      render: rowData => rowData.siteId

    },
    { title: 'State', field: 'siteState' },
    {
      title: 'Country', field: 'siteCountry', cellStyle: {

        width: '15%'
      },
    },
    {
      title: 'Count', field: 'patientCount', cellStyle: {
        color: '#244271',
        fontWeight: 500,
        // textAlign: 'center'
      }, 
      // render: rowData => parseInt(rowData.patientCount) > 0 ? <LightTooltipPatient data={JSON.stringify(rowData)} showPatients={showPatients} genderField={genderField} age={age} raceField={raceField} language={language} education={education} city={city} stateField={stateField} zip={zip} countryField={countryField} submit={submit} percentData={rowData.percentCount} enableonclick={false}>{rowData.patientCount} </LightTooltipPatient> : rowData.patientCount
    },
    { title: '100% Match', field: '', render: rowData => rowData.percentCount.length>0 ? callGetPatientCount(rowData.percentCount,"1"): "0" },
    { title: '(81 - 99)% Match', field: '',cellStyle: {
      width: '15%'
    }, render: rowData => rowData.percentCount.length>0 ? callGetPatientCount(rowData.percentCount,"2"): "0" },
    { title: '(50-80)% Match', field: '',cellStyle: {
      width: '15%'
    }, render: rowData => rowData.percentCount.length>0 ? callGetPatientCount(rowData.percentCount,"3"): "0" },
    // { title: '(50-70)%', field: 'match2' },
    // { title: 'Pricing Type', field: 'priceType' },
    {
      title: 'Price($)', field: 'totalPrice',
      // cellStyle: {
      //   textAlign: 'right',
      //   width:"30px"
      //   }, 
      render: rowData => rowData.totalPrice ? numberWithCommas(parseInt(rowData.totalPrice)) : '--'
    },
    {
      title: "Cart", field: "", render: rowData => (rowData.totalPrice != null) ? rowData.addCart ? <img src={Circle} alt="added to cart" style={{ marginLeft: "8%" }} /> : <Tooltip title="Add to Cart" arrow><img src={AddCartIcon} alt="add to cart" onClick={(e) => addToCart(e, rowData)} style={{ marginLeft: "8%" }} /></Tooltip> : ""
      // <button type="button" className="btn btn-primary-blue btn-rounded btn-small" >Add to Cart</ button>
    }, {
      title: "Buy", field: "", render: rowData => (rowData.totalPrice != null) ? <Tooltip title="Buy"><img src={BuyIcon} alt="Buy" onClick={(e) => buyItem(e, rowData)} /></Tooltip> : ""
    },
    {
      title: "Metrics", field: "", render: rowData => <Tooltip title="Dashboard" arrow><i className="fa fa-solid fa-chart-line" style={{ color: "#23416F", marginLeft: "25%" }} alt="Dashboard" onClick={(e) => showDashboard(e, rowData)} /></Tooltip>
    },
    {
      title: "Insights", field: "", cellStyle: {
        color: '#244271',
        fontWeight: 500,
        // marginRight:"50px"
        // textAlign: 'center'
      }, render: rowData => <Tooltip title="Insights" arrow><i className="fa fa-solid fa-filter" style={{ color: "#23416F", marginLeft: "25%" }} alt="Insights" onClick={(e) => showInsights(e, rowData)} /></Tooltip>
    }
  ]
  const callGetPatientCount=(percentData,bucket)=>{
    var data = percentData.find(o => o.bucket === bucket)
    if(data){
      return data?.count
    }else{
      return 0
    }
   
  }
  const showDashboard = (e, rowData) => {
    e.preventDefault();
    setDashboardData(rowData)
    setShow(true)
  }
  const addToCart = async (e, rowData) => {
    e.preventDefault();
    var newData = data.map(item => item.siteName === rowData.siteName ? { ...item, addCart: true } : item)
    var addedToCardList = newData.filter(v => (v.addCart == true))

    // var cartdata = addedToCardList.map(item => ({
    //   siteId: item.siteId, state: item.siteState, country: item.siteCountry, pcount: item.patientCount, priceType: item.priceType, totalPrice: item.totalPrice, id: propsobj.formValues.openSqNCTid, requestId: item.requestId, addCart: true
    // }))
    let requestBody = `
    mutation 
    {
      addCartDetails(accountId:"${localStorage.getItem('loginUserId')}",nctId:"${propsobj.formValues.openSqNCTid}",  siteId:"${rowData.siteId}", siteName: "${rowData.siteName}", city:"*",country:"*",patientCount:"${rowData.patientCount}",totalPrice:"${rowData.totalPrice}",metrics:"",insights:"ins",patientGender:"${genderField != "" ? genderField.value : "*"}", patientAgeMin:"${age != "" ? age[0] : "*"}",patientAgeMax:"${age != "" ? age[1] : "*"}",patientRace:"${raceField != "" ? raceField.value : "*"}",  patientCity:"*", patientState:"*", patientZipCode:"*", patientCountry:"*", patientLanguage:"${language != "" ? language.value : "*"}", patientEducation:"${education != "" ? education : "*"}", siteNameFilter:"*", siteCity:"${rowData.siteCity}", siteState:"${rowData.siteState}", siteZipcode:"*", siteCountry:"${rowData.siteCountry}",leadStatus:"*",highbelow:"*",highend:"*",midbelow:"*",midend:"*", lowbelow:"*", lowend:"*") {
        code
        type
        message
        attributes
      }  
    }
        `;

    requestBody = JSON.stringify({ query: requestBody });
    setProgress(true);
    let res = await genericApi('addCartDetails', requestBody);
    setProgress(false);
    if (res && res.data.addCartDetails.code === '200') {
      // alert('success')
      reduxdispatch({ type: 'cartcount', value: reduxstateobj.cartcount + 1 })
      reduxdispatch({ type: 'UpdateFormField', name: "cartHeader", value: false })
    }

    // var newcart
    // if (propsobj.formValues.cart.length > 0) {
    //   var updatedCart = [...propsobj.formValues.cart, ...cartdata]
    //   newcart = updatedCart.filter((v, i, a) => a.findIndex(v2 => (v2.requestId === v.requestId)) === i)

    // } else {
    //   newcart = cartdata
    // }

    setData(newData)
  }

  const buyItem = (e, rowData) => {
    e.preventDefault();
    var addedToCart = []
    addedToCart.push({ ...rowData, nctId: reduxstateobj.form.openSqNCTid })
    reduxdispatch({ type: 'Cartdata', value: addedToCart })
    // addedToCardList.push(rowData)
    // reduxdispatch({ type: 'UpdateFormField', name: "cart", value: addedToCardList })
    reduxdispatch({ type: 'Totalprice', value: rowData.totalPrice * 100 })
    // propsobj.history.push('/account/shoppingcart')
    propsobj.history.push('/account/cartpayment')
  }

  const handleRowClick = async (event, rowData) => { }
  const rowsSelected = (rows) => {
    setSelectedContactsobj({ ...selectedContactsobj, [currentpage]: rows });
  }

  const clearAll = async (e) => {
    await setAge("")
    await setAgeSelected([0, 100])
    await setCity("")
    await setEducation("")
    await setZip("")
    await setCountryField(null)
    await setStateField("")
    await setGenderField("")
    await setRaceField("")
    await setLanguage("")
    await setSubmit(false)
    await callComposeApi("*", "*", "*", "*", "*", "*", "*", "*", "*", "*")
  }

  useEffect(() => {
    showFunnel && showFunnelGraph()
  }, [order])

  const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
  }
  const showFunnelGraph = async () => {
    console.log(insightsData, "insights")

    if (typeof insightsData === 'object' && insightsData !== null) {
      callFunnelApi(insightsData)
    } else {
      let requestBody = {
        query: `query{
        getComposeFunnelDetailwithFilter(studyId:"${propsobj.formValues.openSqNCTid}",
        patientGender:"${genderField != "" ? genderField.value : "*"}", patientAgeMin:"${age != "" ? age[0] : "*"}",patientAgeMax:"${age != "" ? age[1] : "*"}",patientRace:"${raceField != "" ? raceField.value : "*"}",  patientCity:"*", patientState:"*", patientZipCode:"*", patientCountry:"*", patientLanguage:"${language != "" ? language.value : "*"}", patientEducation:"${education != "" ? education : "*"}", siteName:"*", siteCity:"${city != "" ? city : "*"}", siteState:"${stateField != "" ? stateField.value : "*"}", siteZipcode:"${zip != "" ? zip : "*"}", siteCountry:"${countryField != null ? countryField.value : "*"}",order:"${order}"){
               nctId
                criteriaOrder
        criteriaBaseOrder
                criteriaText
                ieType
                criteriaIndex
                funnelCount
                matchCount
                totalPatientCount
          }
        }`
      }
      requestBody = JSON.stringify(requestBody);
      setProgress(true);
      let res = await genericApi('getComposeFunnelDetailwithFilter', requestBody);
      setProgress(false);
      if (res) {
        if (res.data && res.data.getComposeFunnelDetailwithFilter.length > 0) {
          var data = res.data.getComposeFunnelDetailwithFilter
          var data1 = data.map((item, i) => { return [capitalize(item.criteriaText) + ' ( <b>' + item.ieType.toUpperCase() + '</b> )', parseInt(item.funnelCount), "#ff0000", item.criteriaBaseOrder, item.ieType.toUpperCase()] })
          // data1.unshift(['<b>Total Patients</b>', parseInt(data[0].totalPatientCount), "#ff0000", "0"])
          setFunnelData(data1)
          setFunnelCount(data[0].totalPatientCount)
          setShowFunnel(true)
        } else {
          alert('failed to fetch data')
        }
      }
    }
  }
  const submitCriteria = () => {
    setSubmit(true)
    callComposeApi(genderField != "" ? genderField.value : "*", age != "" ? age[0] : "*", age != "" ? age[1] : "*", raceField ? raceField.value : "*", language ? language.value : "*", education != "" ? education : "*", city != "" ? city : "*", stateField != "" ? stateField.value : "*", zip != "" ? zip : "*", countryField != null ? countryField.value : "*")
  }

  const handleSubmitFunnelOrder = (e, orderdata) => {
    var order = orderdata.map((item) => { return item[3] })
    // order.shift()
    setOrder(order.toString())
    setFunnelData("")
    setFunnelCount("")
  }

  // column insights
  const showInsights = async (e, rowData) => {
    e.preventDefault();
    console.log(rowData, "rowdata")
    callFunnelApi(rowData)
  }

  const callFunnelApi = async (rowData) => {
    let requestBody = {
      query: `query{
        getComposeFunnelDetailforSitewithFilter(studyId:"${propsobj.formValues.openSqNCTid}",siteId:"${rowData.siteId}",   patientGender:"${genderField != "" ? genderField.value : "*"}", patientAgeMin:"${age != "" ? age[0] : "*"}",patientAgeMax:"${age != "" ? age[1] : "*"}",patientRace:"${raceField != "" ? raceField.value : "*"}",  patientCity:"*", patientState:"*", patientZipCode:"*", patientCountry:"*", patientLanguage:"${language != "" ? language.value : "*"}", patientEducation:"${education != "" ? education : "*"}", siteName:"*", siteCity:"${city != "" ? city : "*"}", siteState:"${stateField != "" ? stateField.value : "*"}", siteZipcode:"${zip != "" ? zip : "*"}", siteCountry:"${countryField != null ? countryField.value : "*"}",order:"${order}")
          {
               nctId
                criteriaOrder
                criteriaText
                criteriaBaseOrder
                ieType
                criteriaIndex
                funnelCount
                matchCount
                totalPatientCount
          }
        }`
    }
    requestBody = JSON.stringify(requestBody);
    setProgress(true);
    let res = await genericApi('getComposeFunnelDetailforSitewithFilter', requestBody);
    setProgress(false);
    if (res) {
      if (res.data.getComposeFunnelDetailforSitewithFilter.length > 0) {
        var data = res.data.getComposeFunnelDetailforSitewithFilter
        var data1 = data.map((item, i) => { return [item.criteriaText + '(<b>' + item.ieType.toUpperCase() + '</b>)', parseInt(item.funnelCount), "#ff0000", item.criteriaBaseOrder, item.ieType.toUpperCase()] })
        // data1.unshift(['<b>Total Patients</b>', parseInt(data[0].totalPatientCount), "#ff0000", "0"])
        setFunnelData(data1)
        var count = data.reduce((acc, cv) => {
          return acc + parseInt(cv.funnelCount);
        }, 0)
        setFunnelCount(data[0].totalPatientCount)
        setInsightsData(rowData)
        setShowFunnel(true)
      }
    }
  }
  const collapseLeftPanel = () => {
    try {
      jq("#pirleftpanel").animate({ width: "0px" }, 300, () => {
        jq("#pirleftcontent").animate({ width: "0px" }, 300, () => {
          jq("#pirleftpanel").removeClass("col-12 col-sm-3");
          jq("#pirrightpanel").removeClass('col-sm-9');
          jq("#addBtnRightPanel").show();

          jq("#expandClmn").addClass("showexpand");
          jq("#pirleftpanel").hide();
        })
      });
    } catch (error) {

    }
  }
  const expandLeftPanel = () => {
    try {
      jq("#pirleftpanel").animate({ width: "30%" }, 300, () => {
        jq("#pirleftcontent").animate({ width: "100%" }, 300, () => {
          jq("#pirleftpanel").addClass("col-12 col-sm-3");
          jq("#pirrightpanel").addClass('col-sm-9');
          jq("#addBtnRightPanel").hide();

          jq("#expandClmn").removeClass("showexpand");
          jq("#pirleftpanel").show();
        })
      });
    } catch (error) {

    }
  }
  return (
    <>
      <div className="dashboardContainer mb-3">
        {(show || showFunnel) ? "" : <section className="row searchcriteriacontainer align-items-stretch">
          <section className="col-12 col-sm-3" id="pirleftpanel" >
            <div className="ex-collapse" id="ex-collapse" onClick={() => collapseLeftPanel()} style={{ backgroundColor: "#234170" }}>
              <i className="fas fa-chevron-left" style={{ color: "white" }}></i>
            </div>

            <div className="pircontent" id="pirleftcontent">
              <div class="secondary-header mr-auto">Criteria <span className="fr cr" style={{
                fontSize: "12px", color: "#23416F", marginTop: "14px",
                fontWeight: "500"
              }} onClick={e => clearAll(e)}>Clear All</span></div>
              <div className="psearch mb-3" style={{ paddingBottom: "17px", backgroundColor: "white" }}>
                <div className="row" style={{ marginLeft: "1px", marginRight: "1px" }}>
                  <div className="col-md-12"><span style={{ fontSize: "1rem", fontWeight: "bold" }}>Site </span></div>
                  <div className="col-md-12">
                    {/* <AutoOptionsWV {...autoPropstoCountry} autoValue={countryField} name="country" label="Country" keyName="value" callback={handleCountry} /> */}
                    <Autocomplete
                      id="asynchronous-demo"
                      options={country}
                      getOptionLabel={option => option.value ? option.value : ""}
                      value={countryField}
                      fullWidth
                      clearOnEscape={true}
                      filterSelectedOptions
                      autoHighlight={true}
                      debug={true}
                      disableClearable
                      onChange={(event, newValue) => handleCountry(newValue)}
                      renderInput={(params) => <TextField {...params} margin="none" fullWidth className="inputfield mb-3"
                        placeholder="Country" />}
                    />
                  </div>
                  <div className="col-md-12">
                    {/* <AutoOptionsWV {...autoPropstoState} autoValue={stateField} name="state" label="State" keyName="value" callback={handleState} /> */}
                    <Autocomplete
                      options={state}
                      getOptionLabel={option => option.value ? option.value : ""}
                      value={stateField}
                      id="asynchronous-demo"
                      fullWidth
                      // getOptionSelected={(option) => option.value}
                      clearOnEscape={true}
                      filterSelectedOptions
                      disabled={countryField == null}
                      autoHighlight={true}
                      debug={true}
                      disableClearable
                      onChange={(event, newValue) => handleState(newValue)}
                      renderInput={(params) => <TextField {...params} margin="none" fullWidth className="inputfield mb-3"
                        placeholder="State" />}
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField id="standard-required" label="City" name="city" value={city} onChange={(e) => { setCity(e.target.value); setSubmit(false) }} />
                  </div>
                  <div className="col-md-6">
                    <TextField id="standard-required" label="Zipcode" name="zip" value={zip} onChange={(e) => { setZip(e.target.value); setSubmit(false) }} />
                  </div>


                  {/* </div>
          </div>
          <div class="secondary-header mr-auto">Criteria <span style={{ fontSize: "1rem", color: "#666" }}>(Patient) </span>
          </div>
          <div className="psearch pb-3" >
            <div className="row" style={{ marginLeft: "1px", marginRight: "1px" }}> */}
                  <div className="col-md-12" style={{ marginTop: "2rem" }}><span style={{ fontSize: "1rem", fontWeight: "bold" }}>Patient </span></div>
                  <div className="col-md-12" >
                    {/* <InputField {...textProps} name="age" label="Age" must={'false'} type="number" /> */}
                    <div className="row" style={{width:'110%'}}>
                      <div className="col-md-2" style={{ marginTop: "1.2rem", paddingLeft: "10px" }}>
                        <Typography id="range-slider" gutterBottom className="mr-auto">
                          Age

                        </Typography>
                      </div>
                      <div className="col-md-10" style={{ marginTop: "1rem" }}>
                        <CustomizedSlider
                          value={ageSelected}
                          onChange={handleAge} />
                      </div>
                    </div>
                    {/* <TextField id="standard-required" inputProps={{ pattern: "[0-9]{0,2}$" }} label="Age" name="age" value={age} onChange={(e) => setAge(e.target.value)} /> */}
                  </div>
                  <div className="col-md-12">
                    {/* <AutoOptionsWV {...autoPropstoGender} autoValue={genderField} name="gender" label="Gender" keyName="value" callback={handleGender} /> */}
                    <Autocomplete
                      id="asynchronous-demo"
                      options={[{ name: "Male", value: "male" }, { name: "Female", value: "female" }, { name: "Others", value: "others" }]}
                      getOptionLabel={option => option.value ? option.value : ""}
                      value={genderField}
                      fullWidth
                      clearOnEscape={true}
                      filterSelectedOptions
                      autoHighlight={true}
                      debug={true}
                      disableClearable
                      onChange={(event, newValue) => handleGender(newValue)}
                      renderInput={(params) => <TextField {...params} margin="none" fullWidth className="inputfield mb-3"
                        placeholder="Gender" />}
                    />
                  </div>

                  <div className="col-md-12">
                    {/* <AutoOptionsWV {...autoPropstoRace} autoValue={raceField} name="race" label="Race" keyName="value" callback={handleRace} /> */}
                    <Autocomplete
                      id="asynchronous-demo"
                      options={Races}
                      getOptionLabel={option => option.value ? option.value : ""}
                      value={raceField}
                      fullWidth
                      clearOnEscape={true}
                      filterSelectedOptions
                      autoHighlight={true}
                      debug={true}
                      disableClearable
                      onChange={(event, newValue) => handleRace(newValue)}
                      renderInput={(params) => <TextField {...params} margin="none" fullWidth className="inputfield mb-3"
                        placeholder="Race" />}
                    />
                  </div>

                  <div className="col-md-12">
                    {/* <AutoOptionsWV {...autoPropstoLang} autoValue={language} name="language" label="Language" keyName="value" callback={handleLanguage} /> */}
                    <Autocomplete
                      id="asynchronous-demo"
                      options={Dropdowns.Languages}
                      getOptionLabel={option => option.value ? option.value : ""}
                      value={language}
                      fullWidth
                      clearOnEscape={true}
                      filterSelectedOptions
                      autoHighlight={true}
                      debug={true}
                      disableClearable
                      onChange={(event, newValue) => handleLanguage(newValue)}
                      renderInput={(params) => <TextField {...params} margin="none" fullWidth className="inputfield mb-3"
                        placeholder="Language" />}
                    />
                  </div>
                  {/* <div className="col-md-6">
                <TextField id="standard-required" label="Education" name="education" value={education} onChange={(e) => { setEducation(e.target.value); setSubmit(false) }} />
              </div> */}
                </div>
              </div>
              <button type="button" onClick={submitCriteria} className="btn btn-primary-blue btn-rounded btn-small fr mb-2" >Submit</button>

            </div>
          </section>
          <section className="middle-clmn-to-expand" id="expandClmn">
            <div className="expandleft-collapse">
              <i className="far fa-file-alt"></i>
              <p className="mt-1">Criteria</p>
            </div>

            <div className="ex-collapse to-right" id="expand-collapse" onClick={() => expandLeftPanel()} style={{ backgroundColor: "#234170" }}>
              <i className="fas fa-chevron-right" style={{ color: "white" }}></i>
            </div>
          </section>
          <section className="col-11 col-sm-9" id="pirrightpanel">
            <section className="pircontent pirpanel-2">
              <div class="secondary-header mr-auto">Patients Matched <span className="fr cr" style={{
                fontSize: "12px", color: "#23416F", marginTop: "12px",
                fontWeight: "500"
              }} onClick={showFunnelGraph}><img src={Logo} alt="" /> &nbsp;Insights</span></div>
              <div className="listing-wrap hekmaGrid">
                <div className="card">
                  <div className="hekmaGridBox">
                    <Materialtable
                      columns={Columns}
                      data={data}
                      handleRowClick={handleRowClick}
                      rowsSelected={rowsSelected}
                      showPadding={true}
                    // selection={true}
                    />
                  </div>
                </div>
              </div>
              <button type="button" onClick={onNext} className="btn btn-primary-blue btn-rounded btn-small fr mb-3 mt-3" >Next</button>
            </section>
          </section>
        </section>}
      </div>
      <Loader progress={progress} />
      {/* {(show || showFunnel) ? "" : <div className="row marketpir">
        <div className="col-md-3">
         
        </div>
        <div className="col-md-9">
          
        </div>

      </div>
      } */}
      {showFunnel && <div>
        <FunnelOrder funnelCount={funnelCount} progress={progress} funnelData={funnelData} handleSubmitFunnelOrder={handleSubmitFunnelOrder} setShowFunnel={setShowFunnel} setOrder={setOrder} setInsightsData={setInsightsData} />
      </div>}
      {
        show &&
        <div>
          <div className="row mt-0">
            <div className="col-md-12 mb-2 justify-content-end" >
              <h1 className="mr-auto primary-header" style={{ float: "left" }}>{"Patients Count:" + dashboardData.patientCount}</h1>

              <button className="btn btn-small btn-primary-blue btn-shadow btn-rounded" onClick={() => { setShow(false) }} style={{ float: "right", marginTop: "5px" }}>
                BACK</button>
            </div>
          </div>
          <Dashboard data={dashboardData} genderField={genderField} age={age} raceField={raceField} language={language} education={education} city={city} stateField={stateField} zip={zip} countryField={countryField} />
        </div>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Results));
