import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: "-5px",
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}style={{fontSize:"12px",padding:"3px 24px"}} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function AddCriteriaModal(props) {
    let [textValue, setTextValue] = useState(props.value || '');

    useEffect(() => {
        setTextValue(props.value || '')
    }, [props])

    const handleClose = () => {
        props.close();
    };

    const changeHandler = (event) => {
        setTextValue(event.target.value);
    }

    return (
        <div>
            <Dialog
                onClose={handleClose}
                disableBackdropClick={true}
                aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose} className="text-uppercase filehistorycss">
                    ADD {props.title}
                </DialogTitle>
                {/* style={{fontSize:"12px",padding:"3px 24px"}} */}
                <DialogContent>
                    <form className="add-criteria-modal">
                        {props.isEdit && <>
                            <button className="delete btn btn-primary-blue btn-rounded btn-small pt-1 pr-2 pl-2 pb-1" onClick={(e) => props.deleteHandler(e, props.inx, props.title)}>
                                <i className="far fa-trash-alt"></i>
                            </button>
                        </>}
                        <section>
                            <TextField
                                className="criteriaTextfield"
                                multiline
                                label={`Enter ${props.title} data`}
                                rows={3}
                                value={textValue}
                                variant="outlined"
                                name={props.title}
                                // id="addIeField"
                                autoFocus={true}
                                onChange={changeHandler}
                                fullWidth
                            />
                        </section>
                    </form>
                </DialogContent>

                <DialogActions>
                    <div className="buttonContainer">
                        <button
                            type="submit"
                            className="btn btn-primary-blue btn-rounded btn-small"
                            onClick={(e) => props.submit(e, textValue, props.title, props.isEdit, props.inx)}
                        >
                            Submit
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
