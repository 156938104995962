import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import MedicalConditions from '../../../../globalcomponents/patient360/medicalhistory/medicalconditions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
const Disease = (props) => {
  console.log(props,"props")
  return (
    <>
      <MedicalConditions data={props.formValues.patientdata} app="Site" />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}

export default withRouter(connect(mapStateToProps, null)(Disease));






