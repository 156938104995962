import React, { useEffect, useState } from "react";
import SmallLogo from '../../../img/hekma-logo.svg';
import BackIcon from '../../../img/back.svg';
import { loginApi, apiCall, genericApi, securityApi } from '../../common/apiconfig';
import { appConfig } from '../../common/constants'
import { withRouter, useLocation } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { jwt } from '../../common/jwtAuthentication';
import FormHoc from '../hoc/formHoc';
import { InputField } from '../formElements';
import { connect } from 'react-redux';
import ForgotPwd from './forgotPwd';
import UpdateForgotNewPwd from './updateForgotNewPwd';
import { GoogleLogin } from 'react-google-login';

const LoginForm = (propsobj) => {
  localStorage.setItem("hideheaderitem", false)
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);
  let [showLogin, setShowLogin] = useState(true);
  let [showforgotPwd, setShowforgotPwd] = useState(false);
  let location = useLocation();
  let [showPage, setShowPage] = useState({
    login: true,
    forgotPwd: false,
    updatePwd: false
  })
  const showPages = {
    login: false,
    forgotPwd: false,
    updatePwd: false
  }

  let hocObj = FormHoc(propsobj);
  var partnerId = ""
  if (window.location.hostname === appConfig.host1) {
    partnerId = "1001"
  } else if (window.location.hostname === appConfig.host2) {
    partnerId = "1002"
  } else {
    partnerId = "1001"
  }
  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('loginForm', event);
    if (setGo) {
      // setProgress(true);
      propsobj.history.push('/securelogin')
      // loginApiCall(propsobj.formValues, partnerId)
    }
  }
  const loginApiCall = async (formdata, partnerId, ssodata) => {
    console.log(ssodata, "ssodata")
    let res = await loginApi(formdata, partnerId, ssodata);
    // setProgress(false);
    if (!res) {
      setApiError(true);
      setTimeout(function () { setApiError(false) }, 1000 * 30);
      setProgress(false);
    } else {
      //security part starts
      function ip2int(ip) {
        return ip.split('.').reduce(function (ipInt, octet) { return (ipInt << 8) + parseInt(octet, 10) }, 0) >>> 0;
      }
      async function getipData(url) {
        const response = await fetch(url);
        return response.json();
      }

      let localId = await getipData(appConfig.ipInfoApi);
      var securityBody = JSON.stringify({ "partnerId": partnerId, "localId": ip2int(localId.ip).toString() });

      let res1 = await securityApi('securityapi', securityBody);
      // setProgress(false);
      if (!res1) {
        alert('Error')
      } else {
        console.log(res1, "loginpage")


        // security part stops--------------------------------
        let requestBody = `
    query {
       getTobeActivatedAccountUser {
                 sponsorAccountUserId,
                   sponsorAccountId,
                   SponsorAccountName,
                   firstName,
                   middleName,
                   lastName,
                   email,
                   phone,
                   role,
                   language,
                   portalAccessInd,
                   invitedBy,
                   invitedDate,
                   activated,
                   primaryContactInd
           }
       }
   `;

        requestBody = JSON.stringify({ query: requestBody });
        // setProgress(true);

        let res = await apiCall('getTobeActivatedAccountUser', requestBody);
        //if token not validated allow user to get new token by showing link expired, click now to activate again
        setProgress(false);
        if (!res) {
          alert('Error')
        } else {
          let data = res.data.getTobeActivatedAccountUser;
          console.log(data, "apiuser")

          propsobj.history.push('/account');
          localStorage.setItem("loggedInUser", data.firstName)
          localStorage.setItem("loggedUserEmail", data.email)
          localStorage.setItem('loginUserId', data.sponsorAccountUserId)
          localStorage.setItem('sponsorrole', data.role)

        }
      }
      //security part

      // var roles = JSON.parse(localStorage.getItem('roles'))
      // propsobj.UpdateFormField({ name: "rolePermissions", value: roles.uiRolePermissions })


    }
  }
  let textProps = {
    ...hocObj,
    propsobj
  }

  useEffect(() => {
    jwt.setCookie('jwtToken', '', 0);//delete jwt token if any
    propsobj.UpdateFormField({name:"ssotokens",value:""})
    // localStorage.removeItem("roles");
    propsobj.history.push('/securelogin')
  }, []);

  const forgotpwd = (e) => {
    e.preventDefault()
    setShowPage({
      ...showPage,
      ...showPages,
      forgotPwd: true
    })
  }

  const back = () => {
    setShowPage({
      ...showPage,
      ...showPages,
      login: true
    })
  }

  useEffect(() => {
    async function getUserDetail() {
      let queryParam = new URLSearchParams(location.search);
      let accessToken = queryParam.get('token');
      if (accessToken && accessToken.length) {
        jwt.setCookie('jwtToken', accessToken, 1);
        let requestBody = `query {
        getUserDetails {
              id,
              userName,
              email,
              role         
          }
        }
      `;
        requestBody = JSON.stringify({ query: requestBody });
        setProgress(true);
        let res = await apiCall('getUserDetails', requestBody);
        setProgress(false);
        if (res) {
          propsobj.UpdateFormField({
            name: 'userid',
            value: res.data.getUserDetails.id || ''
          })
        }
        setShowPage({
          ...showPage,
          ...showPages,
          updatePwd: true
        })
      }
    }
    getUserDetail();
    if (propsobj.formValues.ssotokens != "") {
      setProgress(true);
      loginApiCall(propsobj.formValues, partnerId, propsobj.formValues.ssotokens)
    }
  }, []);

  const responseGoogle = async (response) => {
    console.log(response.tokenObj, "google");
    alert('work under progress')
    // setProgress(true);
    // if (response) {
    //   let res = await loginApi(propsobj.formValues, "Google", response.tokenObj.id_token);
    //   if (!res) {
    //     setApiError(true);
    //     setTimeout(function () { setApiError(false) }, 1000 * 30);
    //   } else {
    //     callGetUserDetails()
    //   }
    // }
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}

        />
      </div>}
      <div className="card">
        <div className="card-body">
          <div className="logo-primary mt-2 mb-4">
            <img src={SmallLogo} alt="logo-small" />
          </div>

          <div className="h-tabs-1">
            {showPage.login &&
              <>
                <ul className="nav nav-tabs nav-fill" id="login-register" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id="login-tab" data-toggle="tab" href="#login" role="tab" aria-controls="login" aria-selected="true">Single Sign-On</a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link disabled" id="signup-tab" data-toggle="tab" href="#signup" role="tab" aria-controls="signup" aria-selected="false">Sign up</a>
                  </li> */}
                </ul>
                <div className="tab-content pt-4" id="login-register-tab">
                  <div className="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">

                    {apiError && <>
                      <div className="hekError">
                        <span className='icon'>
                          <ErrorIcon />
                        </span>
                        <span className='texts'>
                          Unauthorized
                                    </span>
                      </div>
                    </>}
                    <form noValidate autoComplete="off" id="loginForm" className="loginForm" onSubmit={formSubmit}>

                      <InputField {...textProps} name="username" label="Email" must={'true'} type="text" /><br /><br />

                      {/* <InputField {...textProps} name="password" label="Password" must={'true'} type="password" /> */}

                      {/* <div className="text-right mb-3">
                        <a href="#" className="form-link" onClick={forgotpwd}>Forgot password?</a>
                      </div> */}
                      <div className="form-group form-check">
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                        <label className="form-check-label" htmlFor="exampleCheck1">Keep me logged in</label>
                      </div>
                      <div className="form-group">
                        <button type="submit" className="btn btn-primary-blue btn-rounded btn-large btn-block">Log In </button>
                      </div>
                    </form>

                    <div className="social-login">
                      {/* <ul className="list-inline d-flex justify-content-between align-items-center">
                        <li className="list-inline-item static-link-txt">or Sign in using</li>
                        <li className="list-inline-item"> */}
                      {/* <a href="#" className="social-link"><span className="social-icon mr-1 facebook"></span>Facebook</a> */}
                      {/* <button type="button" className="btn btn-primary-blue btn-rounded btn-small btn-block" onClick={SSOClick}>SSO </button> */}
                      {/* {!authState.isPending && !authState.isAuthenticated && <li as="a" onClick={login}>SSO</li>} */}
                      {/* <li onClick={ssologin}>SSO Login</li>
                        </li>
                        <li className="list-inline-item google"> */}
                      {/* <a href="#" className="social-link"><span className="social-icon mr-1 google"></span>Google</a> */}
                      {/* <GoogleLogin
                            clientId="188642766391-dpunmqp1q1uohi1ri3o9r9dns6lhl7f4.apps.googleusercontent.com"
                            buttonText="Login"
                            onSuccess={responseGoogle}
                            // onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                          />
                        </li>
                      </ul> */}
                    </div>
                  </div>
                  <div className="tab-pane fade" id="signup" role="tabpanel" aria-labelledby="signup-tab">...</div>
                </div>
              </>
            }
            {
              showPage.forgotPwd &&
              <>
                <ForgotPwd back={back} />
              </>
            }

            {
              showPage.updatePwd &&
              <>
                <UpdateForgotNewPwd back={back} />
              </>
            }


          </div>


        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));



