import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { genericApi, apiCall } from '../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Paginationn } from '../../common/pagination'
import { appConfig } from "../../common/constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import { HekmaTextSearchField } from '../formElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as R_Items from '../recentItems';
import SearchIcon from '@material-ui/icons/Search';
import Loader from '../../common/loaderComp'
import { checkPermission } from '../../common/utils';
import Materialtable from '../../../globalcomponents/materialtable'
function AccountListGrid(props) {
  let isAddAccount = checkPermission('Account_Create', 'Create');
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');

  async function fetchData(searchKey) {
    try {
      if (searchKey.length) {
        const getAllAccounts = `
      sponsorAccountId
      accountName
      parentAccountId
      parentAccountName
      accountType
      status
      address1
      address2
      country
      countryCode
      state
      city
      zip
      email
      phone
      website
      duns
      createdBy
      createdTs
      modifiedBy
      modifiedTs`;
        let requestBody = {
          query: `query {
          getAccountSearch(parentAccountId:"*",searchStr:"${searchKey}",type:"B") {
                      ${getAllAccounts}
                }
            }`
        };

        requestBody = JSON.stringify(requestBody);
        setProgress(true);
        let res = await apiCall('getAccountSearch', requestBody);
        setProgress(false);
        if (!res) {
          // Error handling
          console.log('Error on response material grid!')

        } else {
          if (res.data.getAccountSearch.length) {
            setData(res.data.getAccountSearch)
          } else {
            setData([])
          }
        }
      }
    } catch (error) {

    }
  }


  useEffect(() => {
    if (props.state.headerSearchValue) {
      fetchData(props.state.headerSearchValue)
      // setValSearch(props.state.headerSearchValue)
    } else {
      fetchData(props.formValues.accountSearchText != "" ? props.formValues.accountSearchText : "");
      setValSearch(props.formValues.accountSearchText != "" ? props.formValues.accountSearchText : "")
    }

    // props.clearForm()
  }, []);

  const handlePageClick = (offset) => {
    setoffset(offset)
  }

  const handleClick = async (event) => {
    event.preventDefault();
    props.history.push('/account/addAccount');
  }

  const handleRowClick = async (event, rowData) => {
    // save clicked rowdata in redux, make sure the logic to empty back
    await props.setSidebarActiveEl('accounts');
    let dataToR_Items = {
      id: rowData.sponsorAccountId,
      accountName: rowData.accountName,
      accountType: rowData.accountType
    }

    // setProgress(true);
    let response = await R_Items.updateRecentItems(dataToR_Items, R_Items.rI_accounts);
    if (response) {
      let actItems = await R_Items.getRecentSearchOrItems(R_Items.rI_accounts);
      if (actItems) {
        let obj = {
          obj: R_Items.rI_accounts,
          value: actItems
        }
        props.updateRecentItems(obj);
      }
    }
    //  setProgress(false);
    props.UpdateEntireForm(rowData);
    props.history.push('/account/accountDetails');
  }

  const searchHandle = (event) => {
    event.preventDefault();
    let value = event.target.value;
    setValSearch(value);
  }
  const searchSubmit = async (event) => {
    event.preventDefault();
    if (valSearch.length) {
      fetchData(valSearch);
      await props.UpdateFormField({ name: "accountSearchText", value: valSearch })
      await props.updateHeaderSearchValue({ value: "" })
    }
  }
  const clearSearch = (event) => {
    event.preventDefault()
    fetchData();
    setValSearch("")
  }
  const handleDelete = async (event, data) => {
    event.preventDefault();
    event.stopPropagation()
    let requestBody = `
            mutation {
                updatesponsorAccount(
                    sponsorAccountId: "${data.sponsorAccountId}",
                    accountName:"${data.accountName}",parentAccountId:"${data.parentAccountId}",accountType:"${data.accountType}",status:"Inactive",address1:"${data.address1}"
        ,address2:"${data.address2}",countryCode:"${data.countryCode}",city:"${data.city}",state:"${data.state}",zip:"${data.zip}",email:"${data.email}",phone:"${data.phone}",website:"${data.website}",duns:"${data.duns}") {
                  code
                  type
                  message
                  attributes
              }
            }
          `;

    setProgress(true);
    requestBody = JSON.stringify({ query: requestBody });
    let res = await apiCall('updatesponsorAccount', requestBody);
    setProgress(false);
    if (!res) {
      console.log('Error on response update contact')
    } else {
      alert(data.accountName + ' deleted');
      if (props.state.headerSearchValue) {
        fetchData(props.state.headerSearchValue)
        // setValSearch(props.state.headerSearchValue)
      } else {
        fetchData(props.formValues.accountSearchText != "" ? props.formValues.accountSearchText : "");
        setValSearch(props.formValues.accountSearchText != "" ? props.formValues.accountSearchText : "")
      }
    }


  }

  const Columns = [
    // {
    //   title: 'ID', field: 'sponsorAccountId',
    //   cellStyle: {
    //     color: '#244271',
    //     fontWeight: 500,
    //     textAlign: 'left'
    //   },
    // },
    {
      title: 'Name', field: 'accountName',
      cellStyle: {
        color: '#244271',
        fontWeight: 500,
        width: '20%'
      },
    },
    {
      title: 'Type', field: 'accountType'
    },

    // { title: "Parent ID", field: 'parentAccountId' },
    { title: 'Address', field: 'address1' },
    { title: 'City', field: 'city' },
    { title: 'Country', field: 'country' },
    // {title:"Status", field:'status'}
  ]

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
        {/* <Loader/> */}
      </div>}
      <div className="d-flex mb-3 justify-content-end">
        <h1 className="mr-auto primary-header">ACCOUNTS</h1>
        {isAddAccount === "1" ?
          <div className="">
            <button onClick={handleClick} className="btn btn-primary-blue btn-rounded">
              <i className="fa fa-plus-circle mr-1"></i> ADD ACCOUNT</button>
          </div> : ""}
      </div>
      <div className="listing-wrap hekmaGrid">
        <div className="card">
          <div className="hekmaGridBox">
            <div className="col-xs-12 col-sm-12 col-md-5">
              <form noValidate autoComplete="off" onSubmit={searchSubmit}>
                <HekmaTextSearchField
                  name="accountgridsearch"// this name you need not to add in constant file unless if we use redux
                  // label="Search Account"
                  placeholder="Search Accounts"
                  value={valSearch}
                  handleChange={searchHandle}
                  className="gridSearchField"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" onClick={searchSubmit} style={{ cursor: "pointer" }}>
                        {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" onClick={clearSearch} style={{ cursor: "pointer" }}>
                        X
                        {/* <i className="fa fa-times" aria-hidden="true"></i> */}
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </div>
            <Materialtable
              columns={Columns}
              data={data.length > 0 ? data : []}
              handleRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateEntireForm: (obj) => {
      return dispatch(
        {
          type: 'UpdateEntireForm',
          value: obj,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    updateHeaderSearchValue: (obj) => {
      return dispatch(
        {
          type: 'headerSearch',
          value: obj.value,
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateRecentItems: (obj) => {
      return dispatch(
        {
          type: 'updateRecentItems',
          value: obj,
        }
      )
    },
    setSidebarActiveEl: (name) => {
      return dispatch(
        {
          type: 'sidebarActiveEl',
          value: name,
        }
      )
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountListGrid));