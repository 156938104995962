import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ViewRow } from '../../formElements';
import { connect } from 'react-redux';
import { apiCall } from '../../../common/apiconfig';
import { checkPermission } from '../../../common/utils';



const ViewSponsorAccount = (propsobj) => {
    let isEditAccount = checkPermission('Account_Details_Edit', 'Update');
    let [apiError, setApiError] = useState(false);
    let [progress, setProgress] = useState(false);
    let [accountId, setaccountId] = useState('')

    const handleClick = async (event) => {
        event.preventDefault();
        propsobj.history.push('/account/updateAccount');
    }

    useEffect(() => {
        let acntId = ''
        try {
            acntId = propsobj.location.state[0].id;
        } catch {
            acntId = propsobj.formValues.sponsorAccountId;
        }
        setaccountId(acntId);
    })

    useEffect(() => {
        async function fetchData() {
            if (accountId != "") {
                let requestBody = {
                    query: `query{
                    getsponsorAccount(id: "${accountId}") {
                        sponsorAccountId
                        accountName
                        parentAccountId
                        parentAccountName
                        accountType
                        status
                        address1
                        address2
                        country
                        state
                        city
                        zip
                        email
                        phone
                        website
                        duns
                        createdBy
                        createdTs
                        modifiedBy
                        modifiedTs
                }
                    getsponsorAccountParentUser(sponsorAccountId:"${accountId}") {
                      sponsorAccountUserId
                      sponsorAccountId
                      SponsorAccountName
                      firstName
                      middleName
                      lastName
                  }
            }`

                };

                requestBody = JSON.stringify(requestBody);
                setProgress(true);
                let res = await apiCall('getsponsorAccountParentUser', requestBody);
                setProgress(false);
                if (!res) {
                    // Error handling
                    console.log('Error on response material grid!')

                } else {
                    propsobj.UpdateEntireForm(res.data.getsponsorAccount)
                    if (res.data.getsponsorAccountParentUser != null) {


                        propsobj.UpdateFormField({ value: res.data.getsponsorAccountParentUser.firstName + ' ' + res.data.getsponsorAccountParentUser.lastName, name: "primaryContact" })

                    } else {

                        propsobj.UpdateFormField({ value: "", name: "primaryContact" })
                    }
                }
            }
        }
        fetchData();
    }, [accountId]);
    const { sponsorAccountId = '', accountName = '', parentAccountName= '', primaryContact = '', accountType = '', status = '', address1 = '', address2 = '', country = '', state = '', city = '', zip = '', email = '', phone = '', website = '', duns = '' } = propsobj.formValues;
    return (

        <>
            {progress && <div className="fullScreen">
                <CircularProgress
                    disableShrink
                    className="progressCircle"
                    value={90}
                    size={100}
                    thickness={1.5}
                />
            </div>}

            <div className="d-flex">
                <div class="secondary-header mr-auto">Account Details</div>
                {isEditAccount === "1" ?
                    <div className="">
                        <button onClick={handleClick} className="btn btn-small btn-primary-blue btn-shadow btn-rounded">
                            <i className="fas fa-pencil-alt"></i>&nbsp; EDIT</button>
                    </div> : ""}
            </div>

            <div className="">
                <form noValidate autoComplete="off" id="addAccountForm" className="row h-form-1">
                    <div className="col-md-6">
                        <div className="panel-primary-wrap">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-page-header">Account INFORMATION</div>
                                    <div className="inputBox">
                                        {/* <ViewRow text="Sponsor Account ID" textvalue={sponsorAccountId} /> */}
                                        <ViewRow text="Account Name" textvalue={accountName} />
                                        <ViewRow text="Account Type" textvalue={accountType} />
                                        <ViewRow text="Parent Account Name" textvalue={parentAccountName == null ? "- - -" : parentAccountName} />
                                        <ViewRow text="Status" textvalue={status} />
                                        <ViewRow text="Primary Contact" textvalue={primaryContact == null ? "- - -" : primaryContact} />


                                        <ViewRow text="Duns#" textvalue={duns == "" ? "- - -" : duns} />
                                        <ViewRow text="Website" textvalue={website == "" ? "- - -" : website} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="panel-primary-wrap">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-page-header">ADDRESS INFORMATION</div>
                                    <div className="inputBox">
                                        <ViewRow text="Phone" textvalue={phone} />
                                        <ViewRow text="Email" textvalue={email == "" ? "- - -" : email} />
                                        <ViewRow text="Address Line1" textvalue={address1} />
                                        <ViewRow text="Address Line2" textvalue={address2 == "" ? "- - -" : address2} />
                                        <ViewRow text="Country" textvalue={country} />
                                        <ViewRow text="State" textvalue={state} />
                                        <ViewRow text="City" textvalue={city == "" ? "- - -" : city} />
                                        <ViewRow text="Zipcode" textvalue={zip} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        formValues: state.form,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        UpdateEntireForm: (obj) => {
            return dispatch(
                {
                    type: 'UpdateEntireForm',
                    value: obj,
                }
            )
        },
        UpdateFormField: (obj) => {
            return dispatch(
                {
                    type: 'UpdateFormField',
                    value: obj.value,
                    name: obj.name
                }
            )
        },
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewSponsorAccount));



