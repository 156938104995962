import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Route, Switch, Link, BrowserRouter as Router, Redirect, NavLink } from 'react-router-dom';
import Disease from './disease';
import Medications from './medications';
import LabData from './labData';
import PatientImmunizations from './patientimmunizations';
import PatientAllergies from './patientAllergies';
import Careplan from './careplan';
import Encounter from './encounter';
import Observation from './observation'
import Procedure from './procedure'
import VitalSigns from './vitalsigns'
import { Menus, NavSubMenu } from '../../navMenu';

const appsmenus = [
  {
    to: "/app/patient/medicalhistory/disease",
    text: "Medical Conditions",
  },
  {
    to: "/app/patient/medicalhistory/medications",
    text: "Medications",
  },
  {
    to: "/app/patient/medicalhistory/labdata",
    text: "Lab Report",
  },
  {
    to: "/app/patient/medicalhistory/vitalsigns",
    text: "Vital Signs",
  },
  {
    to: "/app/patient/medicalhistory/immunizations",
    text: "Immunizations",
  },
  {
    to: "/app/patient/medicalhistory/allergies",
    text: "Allergies",
  },

  {
    to: "/app/patient/medicalhistory/procedure",
    text: "Procedure",
  },

  // {
  //     to: "/app/patient/medicalhistory/encounter",
  //     text: "Encounter",
  // },
  // {
  //     to: "/app/patient/medicalhistory/observation",
  //     text: "Observation",
  // },
]



function MHistoryHome(props) {
  const [offset, setoffset] = useState(0)
  const [data, setData] = useState([])
  let [progress, setProgress] = useState(false);
  let [valSearch, setValSearch] = useState('');

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <section className="mb-3">
        <NavSubMenu menus={appsmenus} />
      </section>
      <Switch>
        <Route path="/app/patient/medicalhistory/disease">
          <Disease />
        </Route>
        <Route path="/app/patient/medicalhistory/medications">
          <Medications />
        </Route>
        <Route path="/app/patient/medicalhistory/labdata">
          <LabData />
        </Route>
        <Route path="/app/patient/medicalhistory/vitalsigns">
          <VitalSigns />
        </Route>
        <Route path="/app/patient/medicalhistory/immunizations">
          <PatientImmunizations />
        </Route>
        <Route path="/app/patient/medicalhistory/allergies">
          <PatientAllergies />
        </Route>
        <Route path="/app/patient/medicalhistory/careplan">
          <Careplan />
        </Route>
        <Route path="/app/patient/medicalhistory/observation">
          <Observation />
        </Route>
        <Route path="/app/patient/medicalhistory/procedure">
          <Procedure />
        </Route>
        <Route path="/app/patient/medicalhistory/encounter">
          <Encounter />
        </Route>
        <Redirect to="/app/patient/medicalhistory/disease" />
      </Switch>
    </>
  )

}

export default MHistoryHome;


