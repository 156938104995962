import React, { useEffect, useState, Component } from "react";
import Loadable from 'react-loadable';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { funcDropdown, roles } from '../../../common/constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import ChangeStudy from '../changeStudy';
import AddResources from '../site/addResources';
import ViewResources from '../site/viewResources';
import UpdateResources from '../site/updateResources'
import PatientHome from '../site/patientHome'
import Globalsearch from '../globalSearch'
import { NavMenu } from '../navMenu';
import Profile from '../profile'
import PatientSignup from '../activatePatientProfile'
import ContactSignup from '../activateContactProfile'
import UpdateProfile from '../updateProfile'
import Resources from '../site/resources'
import PreCalendar from '../site/precalendar'
import { withRouter } from 'react-router-dom';
import StudyHome from "../site/studyHome";
import AdminFeatures from '../../components/adminFeatures/adminFeaturesHome'
import Dashboard from '../site/dashboard/home'
import PatientAssessments from '../site/patientAssessments'
import VideoHome from '../../../videochat/basicVideoCall';
import PatientRequestList from '../header/patientslist'
import PIRSidebarHome from '../site/pir/PIRSidebarHome'
import DoctorCal from '../header/doctorcalendar'
import AccountGrid from '../site/accounts/accountGrid'
import AccountDetailsHome from '../site/accounts/accountDetailsHome'
import MarketPlaceHome from '../site/marketplace/trailmatch'
import OpenSquare from '../site/pir/pirOpensquare'
import PricingInfo from '../site/accounts/pricingInfo'
import OrderHistory from '../header/orderhistory'
import OrderDetails from "../../../sponsorApp/components/account/orderdetails";
import NEWPIR from '../site/newpir'


const SiteHome = (props) => {
  let [show, setShow] = useState(false)
  let [bg, setBg] = useState("#F5F5F5")
  const location = props.location;
  useEffect(() => {
    if (location.pathname == "/activateContact" || location.pathname == "/activatePatient") {
    } else {
      funcDropdown()
      roles()
      setShow(true)
    }
    if (location.pathname == "/site/orderdetails") {
      setShow(false)
    }
    // if (location.pathname.includes("video")) {
    //   setBg("#D9C6E5")
    // } else {
    //   setBg("#F5F5F5")
    // }
  }, [location.pathname])

  const LoadCom = (props) => {
    let Component = Loadable({
      loader: () => import(`../site/${props.fileName}`),
      loading() {
        return <div className="fullScreen">
          <CircularProgress
            disableShrink
            className="progressCircle"
            value={90}
            size={100}
            thickness={1.5}
          />
          <span>UI Script loading</span>
        </div>
      },
    });
    return <Component {...props} />
  }


  return (
    <>
      {/* <!-- Content Wrapper --> */}
      <div id="content-wrapper" className="d-flex flex-column">
        {/* <!-- Main Content --> */}
        <div id="content" style={{ backgroundColor: bg }}>
          {/* <!-- Begin Page Content --> */}
          <div className="container-fluid">
            {
              show &&
              <div className="d-flex mb-3" style={{ justifyContent: "flex-end" }}>
                <div className="c_study_component mt-3 mb-3" >
                  <ChangeStudy />
                </div>
              </div>
            }
            <div className="">
              <Switch>
                <Route path="/activatePatient" component={PatientSignup} />
                <Route path="/activateContact" component={ContactSignup} />
                <Route path="/site/dashboard" component={Dashboard} />
                <Route path="/site/:channel/video">
                  <VideoHome />
                </Route>
                <Route path="/site/pir">
                  <PIRSidebarHome />
                </Route>
                <Route path="/site/opensquare">
                  <OpenSquare />
                </Route>
                <Route path="/site/newpir/">
                  <NEWPIR />
                </Route>
                <Route path="/site/patientrequests">
                  <PatientRequestList />
                </Route>
                <Route path="/site/appointments">
                  <PreCalendar />
                </Route>
                <Route exact path="/site/patients">
                  <LoadCom fileName="patient" />
                </Route>
                <Route exact path="/site/studypatients">
                  <LoadCom fileName="studypatients" />
                </Route>
                <Route path="/site/addPatient">
                  <LoadCom fileName="addPatientHome" />
                </Route>
                <Route path="/site/invitepatients">
                  <LoadCom fileName="invitepatients" />
                </Route>
                <Route exact path="/site/users">
                  <LoadCom fileName="siteusers" />
                </Route>
                <Route exact path="/site/users/edit">
                  <LoadCom fileName="editUser" />
                </Route>
                <Route exact path="/site/users/add">
                  <LoadCom fileName="adduser" />
                </Route>
                <Route path="/site/sponsorsearch">
                  <LoadCom fileName="sponsorsearch" />
                </Route>
                <Route path="/site/selectPatients">
                  <LoadCom fileName="selectPatients" />
                </Route>
                <Route path="/site/study" component={StudyHome}>
                  {/* <LoadCom fileName="studyHome" /> */}
                </Route>
                <Route exact path='/site/resources' component={Resources} />
                <Route path='/site/resources/add' component={AddResources} />
                <Route path='/site/resources/view' component={ViewResources} />
                <Route path='/site/resources/update' component={UpdateResources} />
                <Route path='/site/patient' component={PatientHome} />
                <Route path='/site/patientappointments' component={DoctorCal} />
                {/* <Route path="/site/courses">
                  <LoadCom fileName="courseHome" />
                </Route> */}
                <Route path="/site/users/details">
                  <LoadCom fileName="siteusershome" />
                </Route>
                <Route path="/site/results" component={Globalsearch} />
                <Route path="/site/userprofile" component={Profile} />
                <Route path="/site/updateProfile" component={UpdateProfile} />
                <Route path="/site/calendar"
                  component={PreCalendar} />
                <Route path="/site/patientdosage" component={PatientAssessments} />
                <Route path="/site/adminFeatures" component={AdminFeatures} />
                <Route path="/site/account" component={AccountGrid} />
                <Route path='/site/accountDetails' component={AccountDetailsHome} />
                <Route path='/site/trailmatch' component={MarketPlaceHome} />
                <Route path='/site/pricingInfo' component={PricingInfo} />
                <Route path='/site/orderHistory' component={OrderHistory} />
                <Route path='/site/orderdetails' component={OrderDetails} />
                <Redirect to="/site/dashboard" />
              </Switch>
            </div>
          </div>
        </div>
        {/* <!-- End of Main Content --> */}
      </div>
      {/* <!-- End of Content Wrapper --> */}
    </>
  )
}

export default withRouter(SiteHome);


