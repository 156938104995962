import React, { useEffect } from 'react'
import Highcharts from 'highcharts/highstock'
const Chart = (props) => {
    useEffect(() => {
        Highcharts.chart('container5', {
            chart: {
                type: 'variablepie',
                style: {
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
                },
            },
            title: {
                text: props.header
            },
            // tooltip: {
            //     headerFormat: '',
            //     pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
            //         'Area (square km): <b>{point.y}</b><br/>' +
            //         'Population density (people per square km): <b>{point.z}</b><br/>'
            // },
            credits: {
                enabled: false
              },
            series: [{
                minPointSize: 10,
                innerSize: '20%',
                zMin: 0,
                name: 'Conditions',
                data: [{
                    name: 'Diabetes',
                    y: 505370,
                    z: 92.9
                }, {
                    name: 'Coronary artery disease',
                    y: 551500,
                    z: 118.7
                }, {
                    name: "Alzheimer's disease",
                    y: 312685,
                    z: 124.6
                }, {
                    name: "Parkinson's disease",
                    y: 78867,
                    z: 137.5
                }, {
                    name: 'Cardiac arrest ',
                    y: 301340,
                    z: 201.8
                }, {
                    name: 'Asthma',
                    y: 41277,
                    z: 214.5
                }, {
                    name: 'Stroke',
                    y: 357022,
                    z: 235.6
                }]
            }]
        });
    }, [props])
    return (
      <div id="container5" ></div>
    )
  }
  
  
  export default Chart
