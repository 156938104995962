import React, { useEffect, useState } from "react";
import Vitalsigns from '../../../../globalcomponents/patient360/medicalhistory/vitalsigns'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
const PatientVitalsigns = (props) => {
  return (
    <>
      <Vitalsigns data={props.formValues.patientdata} app="Patient" />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}

export default withRouter(connect(mapStateToProps, null)(PatientVitalsigns));

