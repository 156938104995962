import { useState, useEffect } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import $ from 'jquery'
export default function useAgora(client) {
    const [localVideoTrack, setLocalVideoTrack] = useState(undefined);
    const [localAudioTrack, setLocalAudioTrack] = useState(undefined);
    const [joinState, setJoinState] = useState(false);
    const [remoteUsers, setRemoteUsers] = useState([]);
    async function createLocalTracks(audioConfig, videoConfig) {
        const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks(audioConfig, videoConfig);
        setLocalAudioTrack(microphoneTrack);
        setLocalVideoTrack(cameraTrack);
        return [microphoneTrack, cameraTrack];
    }
    async function join(appid, channel, token, uid) {
        if (!client)
            return;
        const [microphoneTrack, cameraTrack] = await createLocalTracks();
        await client.join(appid, channel, token || null, uid, localStorage.getItem("loggedInUser"));
        await client.publish([microphoneTrack, cameraTrack]);
        window.client = client;
        window.videoTrack = cameraTrack;
        setJoinState(true);
    }
    async function screenshare(appid, channel, token, uid) {
        setLocalVideoTrack(undefined);
        await (client === null || client === void 0 ? void 0 : client.leave());
        // const [microphoneT0rack, cameraTrack] = await createLocalTracks();
        var isFirefox = typeof InstallTrigger !== 'undefined';
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        var screenShareTrack
        if (isFirefox) {
            const screenTrack = await AgoraRTC.createScreenVideoTrack({
                // encoderConfig: "1080p_1",
                screenSourceType: 'screen'
            })
            screenShareTrack = screenTrack
        } else if (isSafari) {
            const screenTrack = await AgoraRTC.createScreenVideoTrack({
                encoderConfig: "1080p_1",
            })
            screenShareTrack = screenTrack
        } else {
            const screenTrack = await AgoraRTC.createScreenVideoTrack({
                encoderConfig: "1080p_1",
            }, "enable")
            screenShareTrack = screenTrack[0]
        }
        await client.join(appid, channel, token || null, uid, localStorage.getItem("loggedInUser"));
        var audiotrack = await AgoraRTC.createMicrophoneAudioTrack()
        await client.publish(audiotrack)
        setLocalAudioTrack(audiotrack)
        await client.publish(screenShareTrack);
        screenShareTrack.on('track-ended', async () => {
            await client.unpublish(screenShareTrack);
            await (client === null || client === void 0 ? void 0 : client.leave());
            if (localAudioTrack) {
                localAudioTrack.stop();
                localAudioTrack.close();
            }
            if (localVideoTrack) {
                localVideoTrack.stop();
                localVideoTrack.close();
            }
            join(appid, channel, token, uid, localStorage.getItem("loggedInUser"))
        })
    }

    async function leave() {
        if (localAudioTrack) {
            localAudioTrack.stop();
            localAudioTrack.close();
        }
        if (localVideoTrack) {
            localVideoTrack.stop();
            localVideoTrack.close();
        }
        setRemoteUsers([]);
        setJoinState(false);
        $("#local-player-text").text("");
        await (client === null || client === void 0 ? void 0 : client.leave());
    }
    useEffect(() => {
        if (!client)
            return;
        setRemoteUsers(client.remoteUsers);
        const handleUserPublished = async (user, mediaType) => {
            await client.subscribe(user, mediaType);
            // toggle rerender while state of remoteUsers changed.
            setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        };
        const handleUserUnpublished = (user) => {
            setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        };
        const handleUserJoined = (user) => {
            setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        };
        const handleUserLeft = (user) => {
            setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
        };
        client.on('user-published', handleUserPublished);
        client.on('user-unpublished', handleUserUnpublished);
        client.on('user-joined', handleUserJoined);
        client.on('user-left', handleUserLeft);
        return () => {
            client.off('user-published', handleUserPublished);
            client.off('user-unpublished', handleUserUnpublished);
            client.off('user-joined', handleUserJoined);
            client.off('user-left', handleUserLeft);
        };
    }, [client]);
    return {
        localAudioTrack,
        localVideoTrack,
        joinState,
        leave,
        join,
        screenshare,
        remoteUsers,
    };
}


