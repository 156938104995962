import React, { useState, useEffect } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHoc from '../../../globalcomponents/hoc/formHoc';
import { withRouter } from 'react-router-dom';
import { InputField, AutoOptions } from '../../../globalcomponents/formElements';
import { connect } from 'react-redux';
import HekmaDatePicker from '../HekmaDatePicker'
import { timeInfo } from '../../../common/constants';
import { oldgenericApi } from '../../../common/apiconfig'
import CircularProgress from '@material-ui/core/CircularProgress';
import DateTimePicker from '../../../globalcomponents/datetimepicker'
import moment from 'moment'

const UpdateAppointmentForm = (propsobj) => {
  console.log(propsobj.info, moment.utc(propsobj.info[0].startDateTime + ' UTC', 'YYYY-MM-DD HH:mm:ss [UTC]').local().format('YYYY-MM-DD hh:mm a'), "hiii")
  // let startTime = {
  //   value: propsobj.info[0].startTime.slice(0, (propsobj.info[0].startTime.length - 3)) || '',
  //   label: propsobj.info[0].startTime.slice(0, (propsobj.info[0].startTime.length - 3)) || '',
  //   error: false,
  //   helperText: '',
  // }
  // let endTime = {
  //   value: propsobj.info[0].endTime.slice(0, (propsobj.info[0].endTime.length - 3)) || '',
  //   label: propsobj.info[0].endTime.slice(0, (propsobj.info[0].endTime.length - 3)) || '',
  //   error: false,
  //   helperText: '',
  // }
  // let [starttime, setStarttime] = useState(startTime);
  // let [endtime, setEndtime] = useState(endTime);
  let [progress, setProgress] = useState(false);
  const [startDatetime, setStartDatetime] = useState(moment.utc(propsobj.info[0].startDateTime + ' UTC', 'YYYY-MM-DD HH:mm:ss [UTC]').local().format());
  const [endDatetime, setEndDatetime] = useState(moment.utc(propsobj.info[0].endDateTime + ' UTC', 'YYYY-MM-DD HH:mm:ss [UTC]').local().format());
  const [startDatetimeErr, setStartDatetimeErr] = useState(false)
  const [startDatetimeText, setStartDatetimeText] = useState('')
  const [endDatetimeErr, setEndDatetimeErr] = useState(false)
  const [endDatetimeText, setEndDatetimeText] = useState('')
  useEffect(() => {
    propsobj.UpdateFormField({ name: "app_doctor", value: propsobj.info[0].fromName })
    propsobj.UpdateFormField({ name: "app_doctor_mail", value: propsobj.info[0].fromMail })
    propsobj.UpdateFormField({ name: "app_patient", value: propsobj.info[0].toName })
    propsobj.UpdateFormField({ name: "app_patient_mail", value: propsobj.info[0].toMail })
    propsobj.UpdateFormField({ name: "app_note", value: propsobj.info[0].note })
    propsobj.UpdateFormField({ name: "app_date", value: propsobj.info[0].startDate })
    propsobj.UpdateFormField({ name: "app_subject", value: propsobj.info[0].mailSubject })
    // propsobj.UpdateFormField({ name: "app_starttime", value: propsobj.info[0].startDateTime.slice(0, (propsobj.info[0].startTime.length - 3)) })
    // propsobj.UpdateFormField({ name: "app_endtime", value: propsobj.info[0].endTime.slice(0, (propsobj.info[0].endTime.length - 3)) })
  }, [])

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }
  const autoPropsToStarttime = {
    default: null,
    'options': timeInfo,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropsToEndtime = {
    default: null,
    'options': timeInfo,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }
  const formSubmit = async (event) => {
    event.preventDefault();
    let startDatetimeUtc = new Date(startDatetime)
    let endDatetimeutc = new Date(endDatetime)
    let setGo = await hocObj.handleSubmit('reqApp', event);
    if (startDatetimeUtc == null || "") {
      setStartDatetimeErr(true)
      setStartDatetimeText("This field is required")
    }
    if (endDatetimeutc == null || "") {
      setEndDatetimeErr(true)
      setEndDatetimeText("This field is required")
    }
    if (startDatetimeUtc != null || "" && endDatetimeutc != null || "") {
      if (startDatetimeUtc.getTime() >= endDatetimeutc.getTime()) {
        setEndDatetimeErr(true)
        setEndDatetimeText("This should be greater than start date & time")
      }
    }

    if (setGo && startDatetimeUtc != (null || "") && endDatetimeutc != (null || "")) {
      if (startDatetimeUtc.getTime() < endDatetimeutc.getTime()) {
        let form = propsobj.formValues;
        setProgress(true);
        let requestBody = `
      mutation
      {
        updateAppointmentDetails( appointmentId:"${propsobj.info[0].appointmentId}",fromKey:"${propsobj.info[0].fromKey}",fromType:"doctor",fromName:"${propsobj.info[0].fromName}",
  fromMail:"${propsobj.info[0].fromMail}",
  toKey:"${propsobj.info[0].toKey}",toType:"patient",toName:"${propsobj.info[0].toName}",toMail:"${propsobj.info[0].toMail}",
  mailSubject:"${form.app_subject}",
  startDateTime:"${moment.utc(startDatetimeUtc).format('YYYY-MM-DD HH:mm:ss')}",
  endDateTime:"${moment.utc(endDatetimeutc).format('YYYY-MM-DD HH:mm:ss')}",
  note:${JSON.stringify(form.app_note)},
  mailText:"${propsobj.info[0].mailText}",
  docMailText:"${propsobj.info[0].docMailText}"
  reference:"${propsobj.info[0].reference}"
        ) {
          code
          type
          message
          attributes
        }
      }`;

        requestBody = JSON.stringify({ query: requestBody });
        let res = await oldgenericApi('updateAppointmentDetails', requestBody, form);
        setProgress(false);

        if (res && res.data.updateAppointmentDetails.code === '200') {
          propsobj.onClose()
          propsobj.callAppointmentsInfo()
          propsobj.UpdateFormField({ name: "app_date", value: "" })
          // propsobj.UpdateFormField({ name: "app_starttime", value: "" })
          // propsobj.UpdateFormField({ name: "app_endtime", value: "" })
          propsobj.UpdateFormField({ name: "app_note", value: "" })
          setStartDatetime(null)
          setEndDatetime(null)
        } else {
          console.log('Error')
        }
      }
    }
  }

  const updateStarttime = (obj) => {
    propsobj.UpdateFormField({ name: "app_endtime", value: "" })
  }
  const handleStartDatetimeChange = (data) => {
    setStartDatetime(data)
    setStartDatetimeErr(false)
    setStartDatetimeText("")
    setEndDatetime(null)
  }
  const handleEndDatetimeChange = (data) => {
    setEndDatetime(data)
    setEndDatetimeErr(false)
    setEndDatetimeText("")
  }
  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}
      <form noValidate autoComplete="off" id="reqApp" className="row" onSubmit={formSubmit}>
        <div className="inputBox col-md-12 mb-2">
          <InputField className="mb-3" {...textProps} name="app_doctor_mail" must={'true'} disabled type="text" InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <div style={{ color: "#C0C0C0" }} className="font14">From</div>
              </InputAdornment>
            )
          }} />
          <InputField className="mb-3" {...textProps} name="app_patient_mail" must={'true'} disabled type="text" InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <div style={{ color: "#C0C0C0" }} className="font14">To</div>
              </InputAdornment>
            )
          }} />
          <InputField className="mb-3" {...textProps} name="app_subject" must={'true'} type="text" InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <div style={{ color: "#C0C0C0" }} className="font14">Subject</div>
              </InputAdornment>
            )
          }} />
          {/* <InputField {...textProps} name="app_time" must={'true'} type="text" InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <div style={{ color: "#C0C0C0" }} className="font14">Time</div>
              </InputAdornment>
            )
          }} /> */}
          <div className="row mb-3">
            {/* <div className="col-md-4"> <HekmaDatePicker name="app_date" label="Select Date" must={'true'} minDate={new Date()} /></div>
            <div className="col-md-4">
              <AutoOptions {...autoPropsToStarttime} name="app_starttime" label="Start Time" keyName='value' must={'true'} callback={updateStarttime} />
            </div>
            <div className="col-md-4">
              <AutoOptions {...autoPropsToEndtime} name="app_endtime" label="End Time" keyName='value' must={'true'} />
            </div> */}
            <div className="col-md-6 mt-2">
              {/* <HekmaDatePicker name="app_date" label="Select Date" must={'true'} minDate={new Date()} /> */}
              <DateTimePicker label="Start Date & Time" value={startDatetime} handleChange={handleStartDatetimeChange} must={'true'} name="startDatetime" error={startDatetimeErr} errortext={startDatetimeText} minDate={new Date()} />
            </div>
            <div className="col-md-6 mt-2">
              {/* <HekmaDatePicker name="app_date" label="Select Date" must={'true'} minDate={new Date()} /> */}
              <DateTimePicker label="End Date & Time" value={endDatetime} handleChange={handleEndDatetimeChange} mindate={startDatetime} must={'true'} name="endDatetime" error={endDatetimeErr} errortext={endDatetimeText} />
            </div>
          </div>
          <InputField {...textProps} multiline={true} rows={3} minRows={3} variant="outlined" name="app_note" placeholder="Description" must={'false'} type="text" />
        </div>
        <div className="buttonContainer col-md-12 mb-3">
          <button type="submit" className="btn btn-primary-blue btn-rounded btn-small ">Update Appointment</button>
        </div>
      </form>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateAppointmentForm));