import React, { useEffect, useState, Component } from "react";
import Loadable from 'react-loadable';
import { withRouter } from 'react-router-dom';
import { Route, Switch, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { funcDropdown } from '../../../common/constants'
import CircularProgress from '@material-ui/core/CircularProgress';
import DashboardHome from '../patient/dashboard/dashboardHome';
import DetailHome from '../patient/detailHome';
import Initialpage from '../patient/1uphealth/initialpage'
import Profile from '../profile'
import UpdateProfile from '../updateProfile'
import Calendar from '../header/patientCalendar'
import Videocall from '../videochat/basicVideoCall'
import Resources from '../patient/resources'
import { PatientStudyInfo } from '../common/createSessionPatient'
import { connect, useSelector, useDispatch } from 'react-redux';
import ViewResources from '../patient/viewResources'

const AccountPage = (props) => {
  let [bg, setBg] = useState("#F5F5F5")
  const location = props.location;
  const reduxdispatch = useDispatch();
  useEffect(() => {
    funcDropdown()
    // if (location.pathname.includes("videocall")) {
    //   setBg("#D9C6E5")
    // } else {
    //   setBg("#F5F5F5")
    // }
  }, [location.pathname])
  useEffect(async () => {
    var data = await PatientStudyInfo()
    if(data.length>0){
    reduxdispatch({ type: 'UpdateFormField', name: "patientStudyInfo", value: data[0] })
    }else{
      reduxdispatch({ type: 'UpdateFormField', name: "patientStudyInfo", value: '' })
    }
  }, [localStorage.getItem('loginUserId')])

  return (
    <>
      {/* <!-- Content Wrapper --> */}
      <div id="content-wrapper" className="d-flex flex-column">
        {/* <!-- Main Content --> */}
        <div id="content" style={{ backgroundColor: bg }}>
          {/* <!-- Begin Page Content --> */}
          <div className="container-fluid">
            <div className="">
              <Switch>
                <Route path="/patient/1uphealth">
                  <Initialpage />
                </Route>
                <Route path="/patient/detail">
                  <DetailHome />
                </Route>
                <Route path="/patient/dashboard">
                  <DashboardHome />
                </Route>
                <Route path="/patient/medicalhistory">
                  <DetailHome />
                </Route>
                <Route path="/patient/resources">
                  <Resources />
                </Route>
                <Route path="/patient/viewResource">
                  <ViewResources />
                </Route>
                <Route path="/patient/audittrail">
                  <DetailHome />
                </Route>
                <Route path="/patient/profile">
                  <Profile />
                </Route>
                <Route path="/patient/updateProfile">
                  <UpdateProfile />
                </Route>
                <Route path="/patient/doctorappointments">
                  <Calendar />
                </Route>
                <Route path="/patient/:channel/videocall">
                  <Videocall />
                </Route>
                <Redirect to="/patient/dashboard" />
              </Switch>
            </div>
          </div>
        </div>
        {/* <!-- End of Main Content --> */}
      </div>
      {/* <!-- End of Content Wrapper --> */}
    </>
  )
}

export default withRouter(AccountPage);


