import React, { useState, useEffect } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import useAgora from './hooks/useAgora';
import MediaPlayer from './components/MediaPlayer';
import './styles.scss';
import $ from 'jquery'
import CallIcon from '../../../img/videochat/call.svg'
import Record from '../../../img/videochat/rec.png'
import RecordStop from '../../../img/videochat/rec2b.png'
// import VideoIcon from '../../../img/videochat/video.svg'
import CallCut from '../../../img/videochat/call-cut.svg'
import ShareIcon from '../../../img/videochat/share2.svg'
import Tooltip from '@material-ui/core/Tooltip';
import Chat from './chat'
import { appConfig } from "../../../common/constants";
import {
  useParams
} from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect, useSelector, useDispatch } from 'react-redux';

const client = AgoraRTC.createClient({ codec: 'vp8', mode: 'rtc' });

var localTrackState = {
  videoTrackMuted: false,
  audioTrackMuted: false
}

function Call(props) {
  console.log(props.state, "pvideo")
  let reduxstateobj = useSelector(state => state);
  console.log(reduxstateobj.videoRecReqPer,"patientapp")
  const [height, setHeight] = useState("200px")
  const [width, setWidth] = useState("180px")
  const [fullScreen, setFullScreen] = useState(false)
  let [v_panel_width, setVwidth] = useState('');
  let [v_panel_height, setVheight] = useState('65vh');
  const [startData, setStartData] = useState('')
  const [appId, setAppId] = useState("")
  const [token, setToken] = useState("")

  useEffect(() => {
    callAgoraCred()
    getDimension();
  }, [])
  // useEffect(() => {
  //   alert(props.formValues.videoRecReqPer)
  // }, [props.formValues.videoRecReqPer])
  let { channel } = useParams()
  const {
    localAudioTrack, localVideoTrack, leave, join, screenshare, joinState, remoteUsers
  } = useAgora(client);

  const getDimension = () => {
    let video_panel = document.querySelector("#video-panel")
    let v_width = video_panel.clientWidth - 38;
    setVwidth(v_width);

  }

  const callAgoraCred = async () => {
    var data = await fetchData("agora?env="+process.env.REACT_APP_ENVTYPE)
    if (data) {
      setAppId(data.appID)
      callGetToken(data.appID)
    }
  }
  const callGetToken = async (appId) => {
    var data1 = await fetchData("agora?env="+process.env.REACT_APP_ENVTYPE+"&channel=" + channel)
    if (data1) {
      setToken(data1.token)
      join(appId, channel, data1.token)
    }
  }

  const fetchData = (queryUrl) => {
    return fetch(`${appConfig.BaseUrl + queryUrl}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(responseData => {
        return responseData
      })
  }
  const muteaudio = (e) => {
    if (!localTrackState.audioTrackMuted) {
      muteAudio();
    } else {
      unmuteAudio();
    }
  };

  const mutevideo = (e) => {
    if (!localTrackState.videoTrackMuted) {
      muteVideo();
    } else {
      unmuteVideo();
    }
  }
  // $('#category-tabs li a').click(function(){
  //     $(this).next('ul').slideToggle('500');
  //     $(this).find('i').toggleClass('fa-plus-circle fa-minus-circle')
  // })
  async function muteAudio() {
    if (!localAudioTrack) return;
    await localAudioTrack.setMuted(true);
    localTrackState.audioTrackMuted = true;
    console.log($("#mute-audio").find('i'), " $")
    $("#mute-audio").find('i').toggleClass('fa-microphone').toggleClass('fa-microphone-alt-slash');
    // $(this).find('i').toggleClass('fas fa-microphone-alt-slash');
  }

  async function muteVideo() {
    if (!localVideoTrack) return;
    await localVideoTrack.setMuted(true);
    localTrackState.videoTrackMuted = true;
    $("#mute-video").find('i').toggleClass('fa-video').toggleClass("fa-video-slash");
  }

  async function unmuteAudio() {
    if (!localAudioTrack) return;
    await localAudioTrack.setMuted(false);
    localTrackState.audioTrackMuted = false;
    $("#mute-audio").find('i').toggleClass('fa-microphone-alt-slash').toggleClass('fa-microphone');
  }

  async function unmuteVideo() {
    if (!localVideoTrack) return;
    await localVideoTrack.setMuted(false);
    localTrackState.videoTrackMuted = false;
    $("#mute-video").find('i').toggleClass('fa-video-slash').toggleClass("fa-video");
  }
  
  const leavecall = () => {
    leave()
    props.history.goBack()
  }
  return (
    <div className='call mt-2 pb-3 mb-3' style={{ background: "#f5f5f5" }}>
      <h1 className="mr-auto primary-header mb-3">Welcome to Hekma Meeting</h1>

      <div className="videocontainer row mb-3">

        <div className="video-container col-md-8 col-xs-7" id="video-panel">
          {joinState && <>
            <div className="header" >
              <div className="text-left">
                <span style={{ color: "#fff" }}>
                  <img src={CallIcon} alt="icon" height="22px" />
                </span>
                {/* <span cla
                ssName="headertext">Ongoing call</span> */}
                 <Tooltip title="Share screen" arrow>
                <span className="fr" onClick={() => { screenshare(appId, channel, token, client.uid) }}>
                  <i className="fas fa-share-square"></i>
                  {/* <img src={ShareIcon} alt="sharescreen" /> */}
                </span>
                </Tooltip>
              </div>
            </div>

            <div className="video">
              {remoteUsers.length > 1 ?
                <>
                  <div className='local-player-wrapper' style={{ marginRight: "12px" }}>
                    <div className='local-player-text'>{joinState && localVideoTrack ? client._joinInfo?.optionalInfo + '(You)' : ''}</div>
                    <MediaPlayer videoTrack={localVideoTrack} audioTrack={undefined} width="100px" height="100px" local={true} id="local"></MediaPlayer>
                  </div>
                  <section className="remote-people">
                  {remoteUsers.map(user => (<div className='remote-player-wrapper' key={user.uid}>
                    {/* <div className='remote-player-text'>{client._joinInfo?.optionalInfo}</div> */}
                    <MediaPlayer videoTrack={user.videoTrack} audioTrack={user.audioTrack} width={width} height={height} id="remote"></MediaPlayer>
                  </div>))}
                  </section>
                </> :
                <>
                  <div className='local-player-wrapper' className={remoteUsers.length == 1 ? 'localcss' : ""} >
                    {/* <div className='local-player-text'>{localVideoTrack && "Host"}{joinState && localVideoTrack ? `(${ client.uid })` : ''}</div> */}
                    <MediaPlayer videoTrack={localVideoTrack} audioTrack={undefined} width={remoteUsers.length == 1 ? "80px" : v_panel_width + 'px'} height={remoteUsers.length == 1 ? "100px" : v_panel_height} local={true} id="local-2" ></MediaPlayer>
                  </div>
                  {remoteUsers.map(user => (<div className='remote-player-wrapper' key={user.uid}>
                    {/* <div className='remote-player-text'>{`remoteVideo(${ user.uid })`}</div> */}
                    <MediaPlayer videoTrack={user.videoTrack} audioTrack={user.audioTrack} width={v_panel_width} height="66vh" id="remote"></MediaPlayer>
                  </div>))}
                </>
              }
            </div>

            <div className="footer">
              <span style={{ color: 'red', cursor: "pointer" }} onClick={() => { leavecall() }}>

                <img src={CallCut} alt="end call" height="22px" />
              </span>
              {/* <span className="fl mr-3" onClick={() => record()}><img src={Record} alt="Record" height="23px" /></span>
            <span className="fl" onClick={() => recordstop()}><img src={RecordStop} alt="stop" height="23px"/></span> */}
              <span className="fr" id="mute-video" onClick={() => mutevideo()}><i className="fas fa-video"></i></span>
              <span className="fr" id="mute-audio" onClick={() => muteaudio()}><i className="fas fa-microphone"></i></span>
            </div>
          </>}
        </div>
        {joinState &&
          <div className="chat-container col-md-4 col-xs-5" id="chat-panel" style={{paddingLeft:"0px"}}>
            <Chat clientId={client.uid} channel={{
              "name": channel,
              "clientname": localStorage.getItem("loggedInUser")
            }} />
          </div>
        }
      </div>
    </div>
  );
}


export default withRouter(Call);


// <div className='player-container row'>
// {joinState &&
//     <div className='local-player-wrapper col-md-6'>
//         <div className='local-player-text'>{localVideoTrack && `Host`}{joinState && localVideoTrack ? `(${ client.uid })` : ''}</div>
//         <div className="header" >
//             <span style={{ color: "#fff"}}>
//                 {/* <i className="fas fa-phone-alt"></i> */}
//                 <img src={CallIcon} alt="icon" height="22px"/>
//             </span>
//             <span className="headertext">Ongoing call</span>
//             <span className="fr" onClick={() => { screenshare(appId, channel, token, uid) }}>
//                 <i className="fas fa-share-square"></i>
//                 {/* <img src={ShareIcon} alt="sharescreen" /> */}
//             </span>
//         </div>
//         <div className="footer">
//             <span style={{ color: 'red',cursor:"pointer" }} onClick={() => { leave() }}>
//                 {/* <i className="fas fa-phone-slash"></i> */}
//                 <img src={CallCut} alt="end call" height="22px"/>
//                 </span>
//             <span className="fr" id="mute-video" onClick={() => mutevideo()}><i className="fas fa-video"></i></span>
//             <span className="fr" id="mute-audio" onClick={() => muteaudio()}><i className="fas fa-microphone"></i></span>
//         </div>
//         <MediaPlayer videoTrack={localVideoTrack} audioTrack={undefined} width="550px" height="420px" position="relative" local={true}></MediaPlayer>
//     </div>
// }
// <div className="col-md-6">
//     <div className="row">
//         {remoteUsers.map(user => (<div className='remote-player-wrapper col-md-6' key={user.uid}>
//             <div className='remote-player-text'>{`remoteVideo(${ user.uid })`}</div>
//             <MediaPlayer videoTrack={user.videoTrack} audioTrack={user.audioTrack} width="270px" height="200px"></MediaPlayer>
//         </div>))}
//     </div>