import React from 'react';
import UpdateProfile from '../../siteSrc/components/updateProfile';

const updateProfilePage =()=>{
  return(
    <>
    <UpdateProfile appName={localStorage.getItem('whichApp')}/>
    </>
  )
}
export default updateProfilePage