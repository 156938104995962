import React, { useEffect, useState } from "react";
import TextField from '@material-ui/core/TextField';

export function GenericTextField(props) {
  return (
      <>
          <TextField
              label={props.label}
              name={props.name}
              type={props.type}
              onChange={props.onChange}
              placeholder={props.placeholder}
              multiline={props.multiline || false}
              rows={props.rows}
              variant={props.variant ? props.variant :"standard"}
              rowsMax={props.rowsMax}
              fullWidth
              disabled={props.disabled}
              defaultValue={props.defaultValue}
              value={props.value || ''}
              // helperText={props.propsobj.state.formerrtextObj[props.name]}
             
          />
      </>
  );
}