import React, { } from 'react'
import VideoPlayer from 'simple-react-video-thumbnail'
import ReactPlayer from 'react-player'
const listStyles = {
  alignItems: 'flex-start',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'auto',
  padding: '10px',
  maxHeight:'300px',
};

const messageStyles = {
  backgroundColor: '#eee',
  borderRadius: '5px',
  color: '#333',
  fontSize: '1rem',
  margin: '5px',
  padding: '4px 10px',
};

const fileStyles = {
  // backgroundColor: '#eee',
  borderRadius: '5px',
  color: '#333',
  fontSize: '1rem',
  margin: '5px',
  padding: '4px 10px',
};
const RecordingsChats = ({ data }) => {
  console.log(data, "dataaaaaaaa")
  const downloadImg = (e, name, url, type) => {
    e.preventDefault()
    download(name, url, type)
  };
  const downloadPdf = (e, name, url, type) => {
    e.preventDefault()
    window.open(url)
    // download(name, url, type)
  }
  const downloadVideo = (e, name, url, type) => {
    e.preventDefault()
    window.open(url)
  }

  const download = (name, url, type) => {
    fetch(url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });

  };
  return (
    <>
      <div className="row mb-3">

        <div className="col-md-9">
          {data.url != "" ?
            <ReactPlayer
              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              onContextMenu={e => e.preventDefault()}
              url={data.url}
              className="react-player"
              controls
              width="100%"
              height="100%"
            />
            : "No Recording Found"}
        </div>

        <div className="col-md-3" style={{paddingLeft:"0px"}}>
          {data && data?.chat.length > 0 ?
            <div className="card" style={{ width: "100%", borderRadius: "15px", border: "none" }}>
              <div className="card-header header" >
                CHAT
              </div>
              <div className="card-body" style={listStyles}>
                {data?.chat.map((message, index) => {
                  return (
                    <div key={`message-${index}`} className="mb-3">

                      <b>{message.clientname}</b>:

                      {typeof message.message !== 'string' ?
                        <span style={fileStyles} className="cr">
                          {message.message.type.includes("image")
                            ?
                            <a href={message.message.url} download
                              onClick={(e) => downloadImg(e, message.message.name, message.message.url, message.message.type)} target="_blank">{message.message.name}
                            </a>
                            :
                            message.message.type.includes("pdf")
                              ?
                              <a href={message.message.url} download
                                onClick={(e) => downloadPdf(e, message.message.name, message.message.url, message.message.type)} target="_blank">{message.message.name}
                              </a>
                              : message.message.type.includes("video")
                                ?
                                <a href={message.message.url} download
                                  onClick={(e) => downloadVideo(e, message.message.name, message.message.url, message.message.type)} target="_blank">{message.message.name}</a>
                                : ""}
                        </span>
                        : <span style={messageStyles}>{message.message}</span>}
                      <span style={{ fontSize: "10px", color: "#cccbcc" }}> {message.timestamp}</span>
                    </div>
                  );
                })}
              </div>
            </div> : "No Chat Found"}
        </div>
      </div>
    </>
  )
}

export default RecordingsChats