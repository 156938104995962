import React, { Component, useEffect } from 'react';
import { withRouter } from "react-router";
import { Route, Switch, Link, BrowserRouter as Router, Redirect, NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import $ from 'jquery';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: "#1A3154",
    },
    tooltip: {
        backgroundColor: "#1A3154",
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}
export function NavMenu(props) {
    const { menus } = props;
    useEffect(() => {
        let activeclasses = document.querySelectorAll("ul.siteNavbar a");
        var activeElements = {}
        for (let i = 0; i < activeclasses.length; i++) {
            if (activeclasses[i].className.indexOf('active') >= 0) {
                activeElements[i] = true
            }
        }
        if (Object.keys(activeElements).length > 1) {
            activeclasses[0].classList.remove("active")
        } else {
            if (Object.keys(activeElements).length == 0) {
                activeclasses[0].classList.add("active")
            }
        }
        // if(isEditStudy ==="0"){
        //     menus.splice(8,1);
        // }
    })
    const menuItems = menus.map((item, i) =>
        <li className="nav-item" key={i}>
            <NavLink to={item.to} className="nav-link" activeClassName={"active"}>
                <i className={item.icon}></i>
                <span>{item.text}</span>
            </NavLink>
        </li>
    );
    return (
        <>
            <section className="">
                <div className="">
                    <ul className="nav nav-pills siteNavbar">
                        {menuItems}
                    </ul>
                </div>
            </section>
        </>
    );
}


export function Menus(props) {
    const { menus } = props;

    const menuItems = menus.map((item, i) =>
        <li className="nav-item" key={i}>
            <NavLink to={item.to} className="nav-link" activeClassName={"active"}>
                {item.text}
            </NavLink>
        </li>
    );
    return (
        <>
            <section className="card card-rounded">
                <div className="h-tabs-2">
                    <ul className="nav nav-pills siteNavbar">
                        {menuItems}
                    </ul>
                </div>
            </section>
        </>
    );
}


export function NavSubMenu(props) {
    const { menus } = props;
    const menuItems = menus.map((item, i) =>
        <li className="nav-item" key={i}>
            <NavLink to={item.to} exact className="nav-link" activeClassName={"active"}>
                {item.text}
            </NavLink>
        </li>
    );
    return (
        <>
            <section className="card cardSubmenu">
                <ul className="nav nav-bar siteNavsubbar">
                    {menuItems}
                </ul>
            </section>
        </>

    );
}

export function SidebarMenus(props) {
    const { menus, setActive } = props;
    useEffect(() => {
        try {
            $("#accordionSidebar li").removeClass("active");
            let activeElement = document.querySelector(`#accordionSidebar li[name="${setActive}"]`);
            activeElement.classList.add("active");
        } catch{
        }
    });
    const menuItems = menus.map((item, i) =>
        <li className="nav-item sponsormenu" key={i}>
            {/* <NavLink to={item.to} exact className="nav-link" activeClassName={"active"}>
                <i className={item.clsNames}></i>
                <span>{item.text}</span>
            </NavLink> */}
             <NavLink exact to={item.to} className="nav-link">
                <BootstrapTooltip title={item.text}>
                <i className={item.clsNames}></i>
                </BootstrapTooltip>
                <span>{item.text}</span>
            </NavLink>
        </li>
    );
    return (
        <>
            {menuItems}
        </>
    );
}