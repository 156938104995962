import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiCall, logOut } from '../../../common/apiconfig';
import FormHoc from '../../hoc/formHoc';
import { InputField, AutoOptions } from '../../formElements';
import { Dropdowns } from '../../../common/constants';
import { connect } from 'react-redux';
import BackIcon from '../../../../img/back1.svg'
// import * as moment from 'moment'

const AddRBM = (propsobj) => {
  let [apiError, setApiError] = useState(false);
  let [progress, setProgress] = useState(false);

  useEffect(() => {

    propsobj.UpdateFormField({ value: "", name: "rbm_type" })
    propsobj.UpdateFormField({ value: "", name: "rbm_description" })
    propsobj.UpdateFormField({ value: "", name: "rbm_effectiveDate" })
    propsobj.UpdateFormField({ value: "", name: "rbm_severity" })
    propsobj.UpdateFormField({ value: "Active", name: "rbm_status" })
  }, []);

  const hocObj = FormHoc(propsobj);

  const textProps = {
    ...hocObj,
    propsobj
  }

  const autoPropsToseverity = {
    default: null,
    'options': Dropdowns.Protocol_Setup_RBM_Severity,
    freeSolo: false,
    propsobj,
    keys: ['value']
  }

  const autoPropstostatus = {
    ...autoPropsToseverity,
    default: {
      value: 'Active',
      label: 'Active',
    },
    'options': Dropdowns.Account_Status,
  }


  const formSubmit = async (event) => {
    event.preventDefault();
    let setGo = await hocObj.handleSubmit('addRBM', event);

    if (setGo) {
      setProgress(true);
      let form = propsobj.formValues;
      console.log(form)
      let requestBody = `
      mutation 
      {
        createStudyEvent(effectiveDate: "2020-05-14", studyId: "${form.studyId}" ,eventType: "R",type: "${form.rbm_type}", description: ${JSON.stringify(form.rbm_description)},status:"${form.rbm_status == "" ? "Active" : form.rbm_status}", severity:"${form.rbm_severity}") {
          code
          type
          message
          attributes
        }  
      }
          `;
      requestBody = JSON.stringify({ query: requestBody });
      let res = await apiCall('createStudyEvent', requestBody, form);
      setProgress(false);

      if (res && res.data.createStudyEvent.code === '200') {
        console.log('added');

        // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
        // propsobj.clearForm();
        propsobj.history.push('/account/studyDetails/rbm');
      } else {
        console.log('Error')
        //props.history.push('/account/updateContact'); // temp enabling to check update sponsor
      }
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    // propsobj.UpdateFormField({value:propsobj.formValues.accountName,name:"accountName"})
    propsobj.history.goBack();
  }

  const goBack = (event) => {
    event.preventDefault();
    propsobj.history.goBack()
  }

  return (
    <>
      {progress && <div className="fullScreen">
        <CircularProgress
          disableShrink
          className="progressCircle"
          value={90}
          size={100}
          thickness={1.5}
        />
      </div>}

      <div>
      {/* <div onClick={goBack} className="subNavText"><img src={BackIcon} alt="goback" />  BACK TO RBM LIST</div><br/> */}
      </div>
      <div className="d-flex mt-4 justify-content-end">
        <div className="mr-auto secondary-header">Add Risk</div>
      </div>

      <div className="">
        <form noValidate autoComplete="off" id="addRBM" className="row" onSubmit={formSubmit}>
          <div className="col-md-6">
            <div className="panel-primary-wrap">
              <div className="card">
                <div className="card-body">
                  {/* <div className="card-page-header">NAME INFORMATION</div> */}
                  <div className="inputBox">
                    <InputField {...textProps} name="rbm_type" label="Risk Name" keyName='value' must={'true'} />
                    <InputField {...textProps} multiline={true} row={1} rowsMax={2} name="rbm_description" label="Description" must={'true'} type="text" />
                    <AutoOptions {...autoPropsToseverity} name="rbm_severity" label="Severity" keyName='value' must={'true'} />
                    <AutoOptions {...autoPropstostatus} name="rbm_status" label="Status" keyName='value' must={'true'} />
                  </div>
                </div>
              </div>
            </div>
            <div className="buttonContainer">
              <button className="btn btn-primary-grey btn-rounded btn-small" onClick={handleCancel}>CANCEL</button>
              <button type="submit" className="btn btn-primary-blue btn-rounded btn-small mr-1">Save</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    formValues: state.form,
    state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
    clearForm: () => {
      return dispatch(
        {
          type: 'ClearForm',
        }
      )
    },
    updateErrorObj: (obj) => {
      return dispatch(
        {
          type: 'updateErrorObj',
          flag: obj.flag,
          name: obj.name,
          text: obj.text
        }
      )
    },
    updateEntireErrorObj: (errobj, errtextobj) => {
      return dispatch(
        {
          type: 'updateEntireErrorObj',
          errobj: errobj,
          errtextobj: errtextobj,
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddRBM));



