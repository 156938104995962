import { appConfig } from './constants';
import axios from 'axios';
import { jwt } from './jwtAuthentication';
import { sha256 } from 'js-sha256'
import moment from 'moment';

export async function loginApi(req, social, token) {
	var provider = ""
	var socialToken = "false"
	if (social == "Google") {
		provider = "1"
		socialToken = "true"
	} else if (social == "Facebook") {
		provider = "2"
		socialToken = "true"
	} else {
		provider = "0"
		socialToken = "false"
	}
	return axios({
		method: 'post',
		url: `${appConfig.uri}/login`,
		headers: {
			'Access-Control-Allow-Headers': 'Accept',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			'social': socialToken,
			'socialdata': JSON.stringify({ "provider": provider, "providerToken": token })
		},
		auth: {
			username: req.username,
			password: req.password
		},
	}).then(function (response) {
		let resHeader = response['headers'];
		if (resHeader) {
			let jwtToken = resHeader['jwt-token'];
			jwt.setCookie('jwtToken', jwtToken, 1);//update token
			let roles = resHeader['role-permissions'];
			localStorage.setItem('roles', roles);
			localStorage.setItem('UserLoggedInName', req.username);

			let whichApp = resHeader['logged-in-user-type'];
			localStorage.setItem('whichApp', whichApp);

			return true;

		} else {
			throw 'error';
		}
	})
		.catch(function (error) {
			// handle error
			if (error.response) {
				return error.response.data;
			}
			return { error: "Something went wrong!" };
		})
		.finally(function () {
			// always executed
		});
}

// this method will check the roles (eligible to call the method)
export async function apiCall(apiName, requestBody) {

	let token = jwt.getCookie('jwtToken');
	let roles = localStorage.getItem('roles');
	let rolesObj1 = roles;
	let rolesObj = JSON.parse(rolesObj1);

	if (rolesObj === null) {// roles might have been cleared
		logOut();
	}

	// let graphQlApis = rolesObj['rolePermissions'].map((item, inx) => {
	// 	return item.graphqlMethodName;
	// });

	//if (graphQlApis.indexOf(apiName) >= 0) {
	if (true) {
		let opts = {
			method: 'POST',
			body: requestBody,
			headers: {
				'Access-Control-Allow-Headers': 'Accept',
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			}
		};

		return fetch(`${appConfig.uri}/graphql`, opts)
			.then(res => res.json())
			.then(response => {
				//console.log('main response at apiconfig js 74::', response);
				if (response.status == '401' || response.status == '403') {//unauthorized
					// should redirect to login
					logOut();
					return;
				}
				return response;
			})
			.catch(function (error) {
				console.log('main error at apiconfig js 83::', error);
				return false;
			})
	} else {
		console.log('no permisson');
	}
}

export async function rolesLovApi(apiName, requestBody) {
	let token = jwt.getCookie('jwtToken');
	let opts = {
		method: 'POST',
		body: requestBody,
		headers: {
			'Access-Control-Allow-Headers': 'Accept',
			'Content-Type': 'application/json',
			'Authorization': `Token ${token}`,
			'Access-Control-Allow-Origin': '*'
		}
	};

	return fetch(`${appConfig.uri}/graphql`, opts)
		.then(res => res.json())
		.then(response => {
			//console.log('main response at apiconfig js 74::', response);
			if (response.status == '401' || response.status == '403') {//unauthorized
				// should redirect to login
				// logOut();
				return;
			}
			return response;
		})
		.catch(function (error) {
			console.log('main error at apiconfig js 83::', error);
			return false;
		})
}

export async function genericApi(apiName, requestBody) {

	let token = jwt.getCookie('jwtToken');
	let roles = localStorage.getItem('roles');
	let rolesObj1 = roles;
	let rolesObj = JSON.parse(rolesObj1);

	if (rolesObj === null) {// roles might have been cleared
		logOut();
	}
	var sessionData = JSON.parse(localStorage.getItem("securityInfo"))
	var sessionInput = JSON.parse(localStorage.getItem("securityInput"))
	//console.log(sessionInput, "sessionInput")
	var date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
	var partnerId = sha256(sessionInput.partnerId + '~' + date)
	var sessionSecret = sha256(sessionData.sessionSecret + '~' + date)
	var localId = sha256(sessionInput.localId + '~' + date)
	//console.log(sessionInput.partnerId + '~' + date, sessionInput.localId + '~' + date, sessionData.sessionSecret + '~' + date)
	//console.log(date, requestBody, partnerId, sessionSecret, localId, "session")
	if (true) {
		let opts = {
			method: 'POST',
			body: requestBody,
			headers: {
				'TIMESTAMP': date,
				'partnerId': partnerId,
				'sessionKey': sessionData.sessionKey,
				'sessionSecret': sessionSecret,
				'localId': localId,
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json',
			}
		};

		return fetch(`${appConfig.uri}/graphql`, opts)
			.then(res => res.json())
			.then(response => {
				//console.log('main response at apiconfig js 74::', response);
				if (response.status == '401' || response.status == '403') {//unauthorized
					// should redirect to login
					logOut();
					return;
				}
				if (response?.errors?.length > 0) {
					// alert(response.errors[0].message)
					// logOut();
					return
				}
				return response;
			})
			.catch(function (error) {
				console.log('main error at apiconfig js 83::', error);
				return false;
			})
	} else {
		alert('no permisson');
	}
}
// this method will not check the roles (eligible to call the method)
export async function oldgenericApi(apiName, requestBody) {
	let token = jwt.getCookie('jwtToken');
	let opts = {
		method: "POST",
		headers: {
			'Access-Control-Allow-Headers': 'Accept',
			'Authorization': `Token ${token}`,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		},
		body: requestBody
	};
	return fetch(`${appConfig.uri}/graphql`, opts)
		.then(res => res.json())
		.then(response => {
			if (response.status == '401' || response.status == '403' || response.status == '500') {//unauthorized
				// should redirect to login
				logOut();
			} else {
				return response;
			}
		})
		.catch(console.error);
}

export async function logOut() {
	jwt.setCookie('jwtToken', '', 0);
	localStorage.removeItem("roles");
	localStorage.removeItem("UserLoggedInName");
	localStorage.removeItem("loggedInUser")
	localStorage.removeItem("loggedUserEmail")
	localStorage.removeItem('loginUserId');
	localStorage.removeItem('whichApp');
	localStorage.removeItem("securityInput");
	localStorage.removeItem("nonRegStudyId")

	window.history.pushState("", "", "/login");
	window.location.reload();
}

export async function getCountry() {
	let requestBody = `query {
		getSrcCountrySearch(searchStr:"*") {
		   CountryCode
		   Name
		   PhoneCode
		}
	  }`;

	requestBody = JSON.stringify({ query: requestBody });
	let res = await oldgenericApi('getSrcCountrySearch', requestBody);
	let countries = res.data.getSrcCountrySearch.map((item, i) => {
		let obj = {};
		obj['value'] = item.Name;
		obj['label'] = item.Name;
		obj['id'] = item.CountryCode;
		return obj;
	})
	return countries;
}

export async function getState(countryCode) {
	let requestBody = `query {
        getSrcStatesSearch(countryCode:"${countryCode}",searchStr:"*") {
           ID
		   Name
		   CountryCode
        }
      }`;

	requestBody = JSON.stringify({ query: requestBody });
	let res = await oldgenericApi('getSrcStatesSearch', requestBody);
	let states = res.data.getSrcStatesSearch.map((item, i) => {
		let obj = {};
		obj['value'] = item.Name;
		obj['label'] = item.Name;
		obj['id'] = item.ID;
		return obj;
	});
	return states;
}

//create security api

export async function securityApi(apiName, requestBody) {
	let token = jwt.getCookie('jwtToken');
	let opts = {
		method: "POST",
		headers: {
			'Access-Control-Allow-Headers': 'Accept',
			'Authorization': `Token ${token}`,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		},
		body: requestBody
	};
	return fetch(`${appConfig.securityuri}/security`, opts)
		.then(res => res.json())
		.then(response => {
			if (response.status === "success") {
				localStorage.setItem("securityInfo", JSON.stringify(response));
				localStorage.setItem("securityInput", requestBody);
				return response;
			} else {
				console.log("session creation error")
			}
		})
		.catch(console.error);
}

//file upload
export async function fileUpload(apiName, requestBody) {
	let token = jwt.getCookie('jwtToken');
	let opts = {
		method: "POST",
		headers: {
			'Access-Control-Allow-Headers': 'Accept',
			'Authorization': `Token ${token}`,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		},
		body: requestBody
	};
	return fetch(`${appConfig.uri}/file-upload`, opts)
		.then(res => res.json())
		.then(response => {
			if (response.status == '401' || response.status == '403' || response.status == '500') {//unauthorized
				// should redirect to login
				alert(`No Success response - ${apiName}`)
				// logOut();
			} else {
				return response;
			}
		})
		.catch(console.error);
}

//get file upload
export async function getFiles(apiName, data, type) {
	let token = jwt.getCookie('jwtToken');
	let opts = {
		method: type,
		headers: {
			'Access-Control-Allow-Headers': 'Accept',
			'Authorization': `Token ${token}`,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		},
		data

	};
	return fetch(`${appConfig.uri}/file-upload?Id=${data.detail1}&filename=${data.detail2}`, opts)
		.then(res => res.json())
		.then(response => {
			if (response.status == '401' || response.status == '403' || response.status == '500') {//unauthorized
				// should redirect to login
				alert(`No Success response - ${apiName}`)
			} else {
				return response;
			}
		})
		.catch(console.error);
}



/* sponsor common apis */

export async function sponsorLoginApi(req, patnerId, ssoInfo) {

	return axios({
		method: 'post',
		url: `${appConfig.uri}/login`,
		headers: {
			'Access-Control-Allow-Headers': 'Accept',
			'Content-Type': 'application/json',
			'partnerId': patnerId,
			'Access-Control-Allow-Origin': '*',
			'token': ssoInfo?.sessionToken,
			'email': req.ssousername,
			'sso': ssoInfo == undefined ? false : true

		},

		auth: {
			username: req.username,
			password: req.password
		},
	}).then(function (response) {
		let resHeader = response['headers'];
		if (resHeader) {
			let jwtToken = resHeader['jwt-token'];
			jwt.setCookie('jwtToken', jwtToken, 1);//update token

			let roles = resHeader['role-permissions'];
			localStorage.setItem('roles', roles);
			localStorage.setItem('UserLoggedInName', req.username);
			return true;

		} else {
			throw 'error';
		}
	})
		.catch(function (error) {
			// handle error
			console.log('login api error', error);
			return false;
		})
		.finally(function () {
			// always executed
		});
}

// this method will check the roles (eligible to call the method)
export async function sponsorApiCall(apiName, requestBody) {

	let token = jwt.getCookie('jwtToken');
	let roles = localStorage.getItem('roles');
	let rolesObj1 = roles;
	let rolesObj = JSON.parse(rolesObj1);

	if (rolesObj === null) {// roles might have been cleared
		logOut();
	}
	var sessionData = JSON.parse(localStorage.getItem("securityInfo"))
	var sessionInput = JSON.parse(localStorage.getItem("securityInput"))
	var date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
	var partnerId = sha256(sessionInput.partnerId + '~' + date)
	var sessionSecret = sha256(sessionData.sessionSecret + '~' + date)
	var localId = sha256(sessionInput.localId + '~' + date)
	//console.log(sessionInput.partnerId + '~' + date, sessionInput.localId + '~' + date, sessionData.sessionSecret + '~' + date)
	//console.log(date, requestBody, partnerId, sessionSecret, localId, "session")
	if (true) {
		let opts = {
			method: 'POST',
			body: requestBody,
			headers: {
				'TIMESTAMP': date,
				'partnerId': partnerId,
				'sessionKey': sessionData.sessionKey,
				'sessionSecret': sessionSecret,
				'localId': localId,
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json',
			}
		};

		return fetch(`${appConfig.uri}/graphql`, opts)
			.then(res => res.json())
			.then(response => {
				//console.log('main response at apiconfig js 74::', response);
				if (response.status == '401' || response.status == '403') {//unauthorized
					// should redirect to login
					logOut();
					return;
				}
				if (response?.errors?.length > 0) {
					alert(response.errors[0].message)
					// logOut();
					return
				}
				return response;
			})
			.catch(function (error) {
				console.log('main error at apiconfig js 83::', error);
				return false;
			})
	} else {
		alert('no permisson');
	}
}

export async function oldapiCall(apiName, requestBody) {

	let token = jwt.getCookie('jwtToken');
	let roles = localStorage.getItem('roles');
	let rolesObj1 = roles;
	let rolesObj = JSON.parse(rolesObj1);

	if (rolesObj === null) {// roles might have been cleared
		logOut();
	}

	// let graphQlApis = rolesObj['rolePermissions'].map((item, inx) => {
	// 	return item.graphqlMethodName;
	// });

	//if (graphQlApis.indexOf(apiName) >= 0) {
	if (true) {
		let opts = {
			method: 'POST',
			body: requestBody,
			headers: {
				'Access-Control-Allow-Headers': 'Accept',
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			}
		};

		return fetch(`${appConfig.uri}/graphql`, opts)
			.then(res => res.json())
			.then(response => {
				//console.log('main response at apiconfig js 74::', response);
				if (response.status == '401' || response.status == '403') {//unauthorized
					// should redirect to login
					logOut();
					return;
				}
				return response;
			})
			.catch(function (error) {
				console.log('main error at apiconfig js 83::', error);
				return false;
			})
	} else {
		alert('no permisson');
	}
}

// this method will not check the roles (eligible to call the method)
export async function sponsorGenericApi(apiName, requestBody) {
	let token = jwt.getCookie('jwtToken');
	let opts = {
		method: "POST",
		headers: {
			'Access-Control-Allow-Headers': 'Accept',
			'Authorization': `Token ${token}`,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		},
		body: requestBody
	};
	return fetch(`${appConfig.uri}/graphql`, opts)
		.then(res => res.json())
		.then(response => {
			if (response.status == '401' || response.status == '403' || response.status == '500') {//unauthorized
				// should redirect to login
				alert(`No Success response - ${apiName}`)
				logOut();
			} else {
				return response;
			}
		})
		.catch(console.error);
}

// this method is used to call api without token
export async function noAuthApi(apiName, auth, requestBody) {
	return axios({
		method: 'post',
		url: `${appConfig.uri}/${apiName}?email=${auth.email}`,
		headers: {
			'Access-Control-Allow-Headers': 'Accept',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		}
	}).then(function (response) {
		if (response.status == '500') {
			console.log('internal server error')
			return false;
		}
		if (response.status == '200') {
			return true;
		} else {
			return false;
		}
	})
		.catch(function (error) {
			// handle error
			console.log('noAuthApi api error', error);
			return false;
		})
		.finally(function () {
			// always executed
		});
}


//signup for patient
export async function SignupPatient(apiName, requestBody) {
	if (true) {
		let opts = {
			method: 'POST',
			body: requestBody,
			headers: {
				'Access-Control-Allow-Headers': 'Accept',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			}
		};
		return fetch(`${appConfig.uri}/${apiName}`, opts)
			.then(res => res.json())
			.then(function (res) {
				if (res) {
					return res
				} else {
					throw 'error';
				}
			})
			.catch(function (error) {
				// handle error
				if (error.response) {
					return error.response.data;
				}
				return { error: "Something went wrong!" };
			})
			.finally(function () {
				// always executed
			});
	}
}

export async function getFileUrl(apiName, data, type) {
	let token = jwt.getCookie('jwtToken');
	let opts = {
		method: type,
		headers: {
			'Access-Control-Allow-Headers': 'Accept',
			'Authorization': `Token ${token}`,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		},
		data

	};
	return fetch(`${appConfig.uri}//hekma-artifact?bucket=hekma-patient-videos&Id=${data?.detail1}&filename=${data?.detail2}`, opts)
		.then(res => res.json())
		.then(response => {
			if (response.status == '401' || response.status == '403' || response.status == '500') {//unauthorized
				// should redirect to login
				alert(`No Success response - ${apiName}`)
			} else {
				return response;
			}
		})
		.catch(console.error);
}

