import React, { useRef, useEffect } from "react";
const MediaPlayer = (props) => {
  const container = useRef(null);
  useEffect(() => {
    var _a;
    if (!container.current)
      return;
    (_a = props.videoTrack) === null || _a === void 0 ? void 0 : _a.play(container.current);
    return () => {
      var _a;
      (_a = props.videoTrack) === null || _a === void 0 ? void 0 : _a.stop();
    };
  }, [container, props.videoTrack]);
  useEffect(() => {
    var _a;
    if (props.audioTrack) {
      (_a = props.audioTrack) === null || _a === void 0 ? void 0 : _a.play();
    }
    return () => {
      var _a;
      (_a = props.audioTrack) === null || _a === void 0 ? void 0 : _a.stop();
    };
  }, [props.audioTrack]);
  return (
    <div style={{ textAlign: 'center' }}>
      <div ref={container} className="video-player"
        style={{ width: props.width, height: props.height}}
        id={props.id} />
    </div>
  );
};
export default MediaPlayer;
