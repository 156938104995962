import React from 'react'
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import {appConfig} from './constants'
import { Paginationn } from './pagination'
import PdfPreview from '../siteSrc/components/site/fileupload/pdf-preview'
import VideoPlayer from 'simple-react-video-thumbnail'
function TabContainer(props) {
  return (
    <Typography component="div" >
      {props.children}
    </Typography>
  );
}
const ViewFileAttachments = (props) => {

  return (
    <>
        <div className="panel-primary-wrap">
          <div className="card">
            <div className="card-body">
              <div className="card-page-header">{props.header} FILES</div>
              <div className="inputBox">
                <Tabs
                  value={props.value}
                  onChange={props.handleTabsChange}
                  indicatorColor="primary"
                  textColor="primary"

                >
                  <Tab label="Links" />
                  <Tab label="Documents" />
                  <Tab label="Videos" />
                </Tabs>
                {props.value === 0 && <TabContainer>

                  <>
                    {props.links.length > 0 ?
                      <table className="viewCourseLinks">
                        <thead>
                          <tr>
                            <th style={{ width: "7%" }}>S. NO</th>
                            <th style={{ width: "35%" }}>LINK/URL</th>
                            <th style={{ width: "40%" }}>DESCRIPTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            props.links.slice(props.offset, appConfig.paginationLimit + props.offset).map((item, i) => (
                              <tr>
                                <td>{i + 1}</td>
                                <td><a href={item.detail1} target="_blank">{item.detail1.limit(40)}</a></td>
                                <td>{item.detail2 == ("" || null || undefined) ? '---' : item.detail2.limit(40)}</td>
                              </tr>

                            ))
                          }
                          <tr>
                            <td colSpan="5">
                              {props.links.length > appConfig.paginationLimit &&
                                <Paginationn handlePageClick={props.handlePageClick} data={props.links.length} offset={props.offset} />
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table> : <div className="nodata">No Data Found</div>}
                  </>
                </TabContainer>
                }
                {props.value === 1 && <TabContainer>

                  <>
                    {props.documents.length > 0 ?
                      <table className="viewCourseLinks">
                        <thead>
                          <tr>
                            <th style={{ width: "7%" }}>S. NO</th>
                            <th style={{ width: "15%" }}>TYPE</th>
                            <th style={{ width: "28%" }}>NAME</th>
                            <th style={{ width: "20%" }}>PREVIEW</th>
                         
                          </tr>
                        </thead>
                        <tbody>
                          {
                            props.documents.slice(props.offset, appConfig.paginationLimit + props.offset).map((item, i) => (
                              <tr>
                                <td>{i + 1}</td>
                                <td>{item.detail3}</td>
                                <td onClick={(e) => { props.download(e, item) }} style={{ cursor: "pointer" }}><Tooltip title="Click to download" arrow><a href="#">  {item.detail2}</a></Tooltip></td>
                                <td className="previewpdf">{item.detail3.includes("image") ? <span onClick={(e) => props.imgClick(e, item.url)}>{props.image(item.url)}</span> : item.detail3.includes("pdf") && <span style={{ cursor: "pointer" }} onClick={(e) => props.pdfClick(e, item.url)}><PdfPreview url={item.url} show={false} width="75" />
                                </span>}
                                </td>
                               
                              
                              </tr>
                            ))
                          }
                          <tr>
                            <td colSpan="6">
                              {props.documents.length > appConfig.paginationLimit &&
                                <Paginationn handlePageClick={props.handlePageClick} data={props.documents.length} offset={props.offset} />
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table> : <div className="nodata">No Data Found</div>}

                  </>
                </TabContainer>
                }
                {props.value === 2 && <TabContainer>
                  <>
                    {props.videos.length > 0 ?
                      <table className="viewCourseLinks">
                        <thead>
                          <tr>
                            <th style={{ width: "7%" }}>S. NO</th>
                            <th style={{ width: "10%" }}>TYPE</th>
                            <th style={{ width: "38%" }}>NAME</th>
                            <th style={{ width: "20%" }}>PREVIEW</th>
                            {/* <th style={{ width: "15%" }}>STATUS</th>
                            <th>Actions</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            props.videos.slice(props.offset, appConfig.paginationLimit + props.offset).map((item, i) => (
                              <tr>
                                <td>{i + 1}</td>
                                <td>{item.detail3}</td>
                                <td onClick={(e) => { props.download(e, item) }} style={{ cursor: "pointer" }}><Tooltip title="Click to download" arrow><a href="#">  {item.detail2}</a></Tooltip></td>
                                <td className="center">
                                  <React.Fragment key={item.url}>
                                    <VideoPlayer videoUrl={item.url} snapshotAt={2} />
                                  </React.Fragment>

                                </td>
                                                            
                              </tr>
                            ))
                          }
                          <tr>
                            <td colSpan="6">
                              {props.videos.length > appConfig.paginationLimit &&
                                <Paginationn handlePageClick={props.handlePageClick} data={props.videos.length} offset={props.offset} />
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table> : <div className="nodata">No Data Found</div>}
                  </>
                </TabContainer>
                }

              </div>
            </div>
          </div>
        </div>
    </>

  )
}

export default ViewFileAttachments