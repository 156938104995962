import React, { useEffect } from 'react'
import Highcharts from 'highcharts/highstock'
const NewChart = (props) => {
  console.log(props, "columnchart")
  useEffect(() => {
    const chart = new Highcharts.Chart({
      chart: {
        renderTo: 'container7',
        type: 'column',
        options3d: {
          enabled: true,
          // alpha: 15,
          // beta: 15,
          // depth: 20,
          // viewDistance: 25
        }
      },
      title: {
        text: props.header
      },
      xAxis: [
        {
          title: {
            text: 'AGE',
          },
        }],
      yAxis: [
        {
          title: {
            text: 'Count',
          },
        }],
        tooltip: {
          headerFormat: '<b>Age:{point.key}</b><br>',
          pointFormat: 'Count: {point.y}'
      },
      // subtitle: {
      //   text: 'Test options by dragging the sliders below'
      // },
      plotOptions: {
        column: {
          depth: 25
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        data: [...props.data],
        colorByPoint: true
      }]
    });



  }, [props])
  // function showValues() {
  //   document.getElementById('alpha-value').innerHTML = chart.options.chart.options3d.alpha;
  //   document.getElementById('beta-value').innerHTML = chart.options.chart.options3d.beta;
  //   document.getElementById('depth-value').innerHTML = chart.options.chart.options3d.depth;
  // }

  // // Activate the sliders
  // document.querySelectorAll('#sliders input').forEach(input => input.addEventListener('input', e => {
  //   chart.options.chart.options3d[e.target.id] = parseFloat(e.target.value);
  //   showValues();
  //   chart.redraw(false);
  // }));

  // showValues();
  return (
    <div id="container7" ></div>
  )
}


export default NewChart