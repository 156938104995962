import { initialFormState } from './constants';

export let regxObj = {}
for (let i in initialFormState) {
    regxObj[i] = /\w/;  // default pattern for all the fields, overwriting can be done in below
}

regxObj = {
    ...regxObj,
    email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    useremail:/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    user_email:/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    //userphone:/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    // phone: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    profile_password:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    // password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    fromtext:/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
}
export const regX = {
    us: {
        zip: /\w/
    },
    in: {
        zip: /[1-6]/
    },
    gb: {
        zip: /\w/
    },
    default: {
        email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        isEmpty: '/^\w+$/',
        phone: /\w/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        username: /\w/,
        currency: /\w/,
        fname: /\w/,
        lname: /\w/,
        mname: /\w/,
        lang: /\w/,
        roles: /\w/,
        accountName: /\w/,
    }
}
