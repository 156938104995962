// import React, { useEffect, useState } from "react";
// import { withRouter } from 'react-router-dom';
// import { Button } from '@material-ui/core';
// //import PatientRetention from './dashboard/patientRetention';
// import { connect, useSelector, useDispatch } from 'react-redux';
// import * as d3 from "d3";
// import * as crossfilter from 'crossfilter';
// import { barChart } from './dashboard/barchart';
// import { donutChart } from './dashboard/donutchart';
// import { USChoroplethByState } from './dashboard/uschoroplethbystate';
// //import { output as graphData } from './modaljson';

// function RaceDistribution(props) {
//   return (
//     <>
//       <div id="race"><span className="nodatafound">No Data Found </span></div>
//     </>
//   )
// }

// function BreakdownbyState(props) {
//   return (
//     <>
//       <div id="breakdownlocation"><span className="nodatafound">No Data Found</span></div>
//     </>
//   )
// }

// function Breakdownbyscore(props) {
//   return (
//     <>
//       <div id="score"><span className="nodatafound">No Data Found</span></div>
//     </>
//   )
// }


// function Breakdownbyage(props) {
//   return (
//     <>
//       <div id="breakdownage"><span className="nodatafound">No Data Found</span></div>
//     </>
//   )
// }

// function Breakdownbygender(props) {

//   useEffect(() => {

//     /*
//     setTimeout(() => {

//       let layout = document.querySelector('#gender svg');
//       let layoutWidth = layout.clientWidth;
//       let layoutHeight = layout.clientHeight;

//       let glayout = document.querySelector('#gender svg g');
//       let glayoutRect = glayout.getBoundingClientRect();
//       let glayoutWidth = glayoutRect.width;
//       let glayoutHeight = glayoutRect.height;

//       //console.log('layoutWidth::', layoutWidth);
//       //console.log('layoutHeight::', layoutHeight);

//       //console.log('glayoutWidth::', glayoutWidth);
//       //console.log('glayoutHeight::', glayoutHeight);

//       //console.log('g rect:::', glayoutRect);
//       //console.log('svg rect:::', layout.getBoundingClientRect());

//       let leftPos = (layoutWidth / 2) - (glayoutWidth / 2);
//       let topPos = (layoutHeight / 2) - (glayoutHeight / 2);

//       //console.log('leftPos::', leftPos);
//       //console.log('topPos::', topPos);
//       // let svgLayoutRect = layout.getBoundingClientRect();
//       // //console.log('leftPos::', (glayoutHeight / 2) + (glayoutWidth / 2) + 15 + leftPos);
//       // //console.log('topPos::', (glayoutHeight / 2) + (glayoutWidth / 2) + 15 + topPos);


//     }, 1000);
//     */



//   }, [])

//   return (
//     <>
//       <div id="gender"><span className="nodatafound">No Data Found</span></div>
//     </>
//   )
// }

// function Breakdownbymatitalstatus(props) {
//   return (
//     <>
//       <div id="maritalstatus"><span className="nodatafound">No Data Found</span></div>
//     </>
//   )
// }

// function Breakdownbylanguage(props) {
//   return (
//     <>
//       <div id="language"><span className="nodatafound">No Data Found</span></div>
//     </>
//   )

// }

// var stateCodes = {
//   "AL": "01",
//   "AK": "02",
//   "AZ": "04",
//   "AR": "05",
//   "CA": "06",
//   "CO": "08",
//   "CT": "09",
//   "DE": "10",
//   "DC": "11",
//   "FL": "12",
//   "GA": "13",
//   "HI": "15",
//   "ID": "16",
//   "IL": "17",
//   "IN": "18",
//   "IA": "19",
//   "KS": "20",
//   "KY": "21",
//   "LA": "22",
//   "ME": "23",
//   "MD": "24",
//   "MA": "25",
//   "MI": "26",
//   "MN": "27",
//   "MS": "28",
//   "MO": "29",
//   "MT": "30",
//   "NE": "31",
//   "NV": "32",
//   "NH": "33",
//   "NJ": "34",
//   "NM": "35",
//   "NY": "36",
//   "NC": "37",
//   "ND": "38",
//   "OH": "39",
//   "OK": "40",
//   "OR": "41",
//   "PA": "42",
//   "RI": "44",
//   "SC": "45",
//   "SD": "46",
//   "TN": "47",
//   "TX": "48",
//   "UT": "49",
//   "VT": "50",
//   "VA": "51",
//   "WA": "53",
//   "WV": "54",
//   "WI": "55",
//   "WY": "56"
// };


// function Dashboard(props) {
//   let reduxstatentitydata = useSelector(state => state.entitypatientdata);
//   let [graphData, setGraphData] = useState(reduxstatentitydata);
//   // let [data, setData] = useState([])

//   const reduxdispatch = useDispatch();
//   // useEffect(() => {
//   //   setData(props.formValues.autostudy)

//   // }, [props.formValues.autostudy.studyId])
//   var ageBarChart = barChart()
//     .x(function (d) {
//       return d.key;
//     })
//     .y(function (d) {
//       return d.value;
//     });


//   var scoreBarChart = barChart()
//     .x(function (d) {
//       return d.key;
//     })
//     .y(function (d) {
//       return d.value;
//     });


//   // var genderBarChart = barChart()
//   //   .x(function (d) {
//   //     return d.key;
//   //   })
//   //   .y(function (d) {
//   //     return d.value;
//   //   });

//   var genderDonutChart = donutChart()
//     .cornerRadius(13) // sets how rounded the corners are on each slice
//     .padAngle(0.015) // effectively dictates the gap between slices
//     .variable("value")
//     .category("key");


//   var maritalStatusDonutChart = donutChart()
//     .cornerRadius(13) // sets how rounded the corners are on each slice
//     .padAngle(0.015) // effectively dictates the gap between slices
//     .variable("value")
//     .category("key");


//   var languageBarChart = barChart()
//     .x(function (d) {
//       return d.key;
//     })
//     .y(function (d) {
//       return d.value;
//     })


//   var locationChart = USChoroplethByState()
//     .width(350)
//     .height(350)
//     // What attribute to use to identify the states by FIPS code
//     .id(function (d) {
//       return d.id;
//     })
//     // What attribute for the color values
//     .color(function (d) {
//       return d.value;
//     });

//   var raceDonutChart = donutChart()
//     .width(600)
//     .height(600)
//     .cornerRadius(13) // sets how rounded the corners are on each slice
//     .padAngle(0.015) // effectively dictates the gap between slices
//     .variable("value")
//     .category("key");


//   useEffect(() => {
//     // if (data.length>0 && data.studyId != props.formValues.autostudy.studyId) {
//     //   alert('change')
//     // }
//     var csData = crossfilter(graphData);

//     d3.select("#resetRace").selectAll(".btn").on("click", function () {
//       csData.dimScore.filterAll();
//       csData.dimAge.filterAll();
//       csData.dimRace.filterAll();
//       csData.dimGender.filterAll();
//       csData.dimMaritalStatus.filterAll();
//       csData.dimLanguage.filterAll();
//       csData.dimLocation.filterAll();
//       update();
//     });


//     csData.dimAge = csData.dimension(function (d) {
//       return d["age"];
//     });
//     csData.age = csData.dimAge.group();
//     ageBarChart.onMouseOver(function (d) {
//       csData.dimAge.filter(d.key);
//       update();
//     }).onMouseOut(function (d) {
//       csData.dimAge.filterAll();
//       update();
//     });



//     csData.dimScore = csData.dimension(function (d) {
//       return d["score"];
//     });
//     csData.score = csData.dimScore.group();
//     scoreBarChart.onMouseOver(function (d) {
//       csData.dimScore.filter(d.key);
//       update();
//       ////console.log("mouse fired at bar chart", d.key)
//     }).onMouseOut(function (d) {
//       csData.dimScore.filterAll();
//       update();
//     });


//     csData.dimGender = csData.dimension(function (d) {
//       return d["gender"];
//     });
//     csData.gender = csData.dimGender.group();
//     genderDonutChart.onMouseOver(function (d) {
//       csData.dimGender.filter(d.key);
//       update();
//     }).onMouseOut(function (d) {
//       csData.dimGender.filterAll();
//       update();
//     });



//     csData.dimMaritalStatus = csData.dimension(function (d) {
//       return d["maritalStatus"];
//     });
//     csData.maritalStatus = csData.dimMaritalStatus.group();
//     maritalStatusDonutChart.onMouseOver(function (d) {
//       csData.dimMaritalStatus.filter(d.key);
//       update();
//       ////console.log("mouse fired at bar chart", d.key)
//     }).onMouseOut(function (d) {
//       csData.dimMaritalStatus.filterAll();
//       update();
//     });


//     csData.dimLanguage = csData.dimension(function (d) {
//       return d["com_language"];
//     });
//     csData.language = csData.dimLanguage.group();
//     languageBarChart.onMouseOver(function (d) {
//       csData.dimLanguage.filter(d.key);
//       update();
//       ////console.log("mouse fired at bar chart", d.key)
//     }).onMouseOut(function (d) {
//       csData.dimLanguage.filterAll();
//       update();
//     });


//     if (graphData && graphData.forEach) {
//       graphData.forEach(function (d) {
//         d.id2 = d.id;
//         d.id = stateCodes[d.state];
//       })
//     }
//     csData.dimLocation = csData.dimension(function (d) {
//       return d["id"];
//     });
//     csData.location = csData.dimLocation.group();
//     locationChart.onMouseOver(function (d) {
//       csData.dimLocation.filter(d.id);
//       update();
//     }).onMouseOut(function (d) {
//       csData.dimLocation.filterAll();
//       update();
//     });


//     csData.dimRace = csData.dimension(function (d) {
//       return d["race"];
//     });
//     csData.race = csData.dimRace.group();
//     raceDonutChart.onMouseOver(function (d) {
//       csData.dimRace.filter(d["data"]["key"]);
//       update();
//     }).onMouseOut(function (d) {
//       csData.dimRace.filterAll();
//       update();
//     });



//     function update() {

//       if (csData.age.all().length) {
//         filteredData(csData);
//       }

//       if (csData.score.all().length) {
//         filteredData(csData);
//       }

//       if (csData.gender.all().length) {
//         filteredData(csData);
//       }

//       if (csData.maritalStatus.all().length) {
//         filteredData(csData);
//       }

//       if (csData.language.all().length) {
//         filteredData(csData);
//       }

//       if (csData.location.all().length) {
//         filteredData(csData);
//       }

//       if (csData.race.all().length) {
//         filteredData(csData);
//       }
//     }

//     update();

//   }, []);

//   useEffect(() => {
//     if (!graphData.length) {
//       try {
//         let findPatientBtn = document.querySelector('#findPatientBtn');
//         findPatientBtn.style.display = 'none';
//       } catch (error) {
//         //console.log('find button not found!');
//       }
//     }
//     let pirobj = {
//       search: true,
//       result: true,
//       patient: false
//     }
//     reduxdispatch({ type: 'pirmenu', value: pirobj })
//   }, []);



//   const handleClick = () => {
//     props.history.push('/site/study/pir/patientdetail');
//   }

//   const filteredData = (csData) => {

//     var removeDonut = function () {
//       d3.select('.slices').selectAll('path').exit().remove();
//       d3.select('.lines').selectAll('polyline').exit().remove();
//       d3.select('.labelName').selectAll('text').exit().remove();
//       d3.select('.label').selectAll('text').exit().remove();
//     }

//     document.getElementById("breakdownage").innerHTML = "";
//     document.getElementById("score").innerHTML = "";
//     document.getElementById("gender").innerHTML = "";
//     document.getElementById("maritalstatus").innerHTML = "";
//     document.getElementById("language").innerHTML = "";
//     document.getElementById("breakdownlocation").innerHTML = "";
//     document.getElementById("race").innerHTML = "";


//     d3.select("#breakdownage")
//       .datum(csData.age.all()) // bind data to the div
//       .call(ageBarChart); // draw chart in div

//     d3.select("#score")
//       .datum(csData.score.all()) // bind data to the div
//       .call(scoreBarChart); // draw chart in div

//     d3.select("#gender")
//       .datum(csData.gender.all())
//       .call(genderDonutChart);

//     d3.select("#maritalstatus")
//       .datum(csData.maritalStatus.all())
//       .call(maritalStatusDonutChart);

//     d3.select("#language")
//       .datum(csData.language.all()) // bind data to the div
//       .call(languageBarChart)
//       .select(".x.axis").selectAll(".tick text")
//       .attr("transform", "rotate(-45) translate(-10,-5)")
//       .select('.label').selectAll('text').remove(); // draw chart in div

//     d3.select("#breakdownlocation")
//       .datum(csData.location.all())
//       .call(locationChart);

//     d3.select("#race")
//       .datum(csData.race.all()) // bind data to the div
//       .call(raceDonutChart) // draw chart in div
//       .call(removeDonut);
//   }

//   return (
//     <>
//       <div className="dashboardContainer pirdashboards">
//         <section className="row mb-4">
//           <div className="col-6">
//             <div id="resetRace">
//               <button id="resetButton" type="button"
//                 className="btn btn-primary-blue btn-rounded btn-small">Reset</button>
//             </div>
//           </div>
//           <div className="col-6 text-right" id="findPatientBtn">
//             <button
//               type="submit"
//               className="btn btn-primary-blue btn-rounded btn-small"
//               onClick={handleClick}
//             >
//               Find Patient
//           </button>
//           </div>
//         </section>



//         <section className="row">
//           <div className="col-12 col-sm-4">
//             <section className="dashboardTile">
//               <p className="dtitle">Breakdown by Race</p>
//               <RaceDistribution data={graphData} />
//             </section>
//           </div>
//           <div className="col-12 col-sm-4">
//             <section className="dashboardTile">
//               <p className="dtitle">Breakdown by State</p>
//               <BreakdownbyState data={graphData} />
//             </section>
//           </div>
//           <div className="col-12 col-sm-4">
//             <section className="dashboardTile">
//               <p className="dtitle">Breakdown by Language</p>
//               <Breakdownbylanguage data={graphData} />
//             </section>
//           </div>
//         </section>



//         <section className="row mt-5">
//           <div className="col-12 col-sm-4">
//             <section className="dashboardTile">
//               <p className="dtitle">Breakdown by Age</p>
//               <Breakdownbyage data={graphData} />
//             </section>
//           </div>
//           <div className="col-12 col-sm-4">
//             <section className="dashboardTile">
//               <p className="dtitle">Breakdown by Marital Status</p>
//               <Breakdownbymatitalstatus data={graphData} />
//             </section>
//           </div>
//           <div className="col-12 col-sm-4">
//             <section className="dashboardTile">
//               <p className="dtitle">Breakdown by Gender</p>
//               <Breakdownbygender data={graphData} />
//             </section>
//           </div>
//         </section>

//         <section className="row mt-5">
//           <div className="col-12 col-sm-4">
//             <section className="dashboardTile">
//               <p className="dtitle">Breakdown by Score</p>
//               <Breakdownbyscore data={graphData} />
//             </section>
//           </div>
//         </section>
//       </div>
//     </>
//   )

// }

// const mapStateToProps = (state) => {
//   return {
//     formValues: state.form
//   }
// }
// const mapDispatchToProps = (dispatch) => {
//   return {
//     UpdateFormField: (obj) => {
//       return dispatch(
//         {
//           type: 'UpdateFormField',
//           value: obj.value,
//           name: obj.name
//         }
//       )
//     },
//     UpdateEntireForm: (obj) => {
//       return dispatch(
//         {
//           type: 'UpdateEntireForm',
//           value: obj,
//         }
//       )
//     },
//   }
// }
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));


import React from 'react'
import { withRouter } from 'react-router-dom';
import VariablePiechart from './dashboard/variablepiechart'
import Donut from './dashboard/3ddonut'
import Columnchart from './dashboard/3dColumn'
import Piechart from './dashboard/piechart'
import BulletChart from './dashboard/bulletgraph'
import Bubblechart from './dashboard/bubblechart'
import Maps from './dashboard/map'
const Dashboard = (props) => {
  const handleSubmit = () => {
    if (props.sidebar) {
      props.history.push('/site/pir/patientdetail')
      // props.history.push('/site/pir/searchresult')
    } else {
      props.history.push('/site/study/pir/patientdetail')
      // props.history.push('/site/study/pir/searchresult')
    }
  }
  return (
    <>
     
      <div className="row">
      
        <div className="col-md-6"><Donut header="No of Subjects v/s Gender"/></div>

        <div className="col-md-6 ">
          <Columnchart header="No of Subjects v/s Age"/>
        </div>
        <div className="col-md-6 mt-3">
          <Piechart header="No of Subjects v/s Percentage Match"/>
        </div>
        <div className="col-md-6 mt-3">
          <VariablePiechart header="Subjects Match v/s Ongoing Conditions"/>
        </div>
        {/* <div className="col-md-6 mt-3">
          <BulletChart/>
        </div> */}
        <div className="col-md-6 mt-3">
          <Bubblechart header="Conditions v/s Status(Mild,Moderate,Severe)"/>
        </div>
        {/* <div className="col-md-6 mt-3">
          <Maps />
        </div> */}
      </div>


      {/* {props.app ?
        <div className="mt-3 text-right">
          <button type="button" className="btn btn-primary-blue btn-rounded btn-small" onClick={handleSubmit}>Next</button>
        </div>
        :

        (showEntity && Object.keys(entityData).length > 0) && */}
      <div className="buttonContainer mt-3 text-right">
        <button
          type="submit"
          className="btn btn-primary-blue btn-rounded btn-small"
          onClick={handleSubmit}
        >
          Find Patients
        </button>
      </div>
      {/* } */}
    </>
  )
}
export default withRouter(Dashboard)