import React, { useEffect, useState } from 'react';
// import UserProfile from '../../../img/user-profile.png';
import { logOut } from '../../common/apiconfig';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
function UserMenu(props) {
  const [role, setRole] = useState('')
  const [user, setUser] = useState('')
  useEffect(() => {
    let responseObj = JSON.parse(localStorage.getItem('roles'));
    //console.log(responseObj)
    if (responseObj?.role.length) {
      setRole(responseObj.role)
    }
    let user = localStorage.getItem("loggedInUser")
    setUser(user)
  }, [localStorage.getItem("loggedInUser")])
  const handleClick = (event) => {
    logOut();
  };
  const handleChange = (event) => {
    props.history.push('/app/userprofile')
  }
  const adminChanges = (e) => {
    props.history.push('/app/adminfeatures')
    props.setSidebarActiveEl('');
  }
  return (
    <>
      <a className="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {/* <img className="img-profile rounded-circle mr-md-2 mr-xs-0" src={UserProfile} alt="user-profile" /> */}
        <span className=" d-none d-lg-inline user-text">Hello, {user}</span>
      </a>

      <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
        <a className="dropdown-item" onClick={handleChange}>
          <i className="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
          Profile
        </a>
        {(role === "Administrator" || role === "Business Admin") &&
          <a className="dropdown-item" onClick={adminChanges}>
            <i className="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
          Admin Features
        </a>}
        <a className="dropdown-item" onClick={handleClick}>
          <i className="fa fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Logout
        </a>

      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarActiveEl: (name) => {
      return dispatch(
        {
          type: 'sidebarActiveEl',
          value: name,
        }
      )
    },
    UpdateFormField: (obj) => {
      return dispatch(
        {
          type: 'UpdateFormField',
          value: obj.value,
          name: obj.name
        }
      )
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserMenu));
